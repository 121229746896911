<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { backendOld } from "~/api";
import AdminDropdownBackendSummary from "~/components/AdminDropdownBackendSummary.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import GenericSubdropdown from "~/components/dumb/GenericSubdropdown.vue";
import { copyAndNotify } from "~/components/notifications";
import { colorsByTheme } from "~/constants/style";
import { DropdownMenuItemKind, Placement, TutorialName } from "~/shared/enums";
import { useDataStore, useEnvironmentStore, usePageStore, useUserStore } from "~/stores";
import { randomSample } from "~/utils/common";

const router = useRouter();
const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const transactionBarrage = async () => {
  const task = randomSample(dataStore.getTaskList())[0];
  await actions.visualization.navigateToTask(task.duid);
  const tagProperty = dataStore.defaultTagsProperty;
  const tags = dataStore.getOptionList(tagProperty);
  tags.forEach((tag, i) => {
    setTimeout(async () => {
      const result = await dataStore.addOptions(tagProperty, [task.duid], [tag.duid], { awaitBackend: true });
      // eslint-disable-next-line no-console
      console.log(new Date().toISOString(), tag.title, result);
    }, i * 50);
  });
};

const makeTeammateLoginToken = async () => {
  const loginToken = (await backendOld.auth.makeTeammateLoginTokenAdmin()).data;
  copyAndNotify("Teammate login token", loginToken);
};

const completeAllTutorials = () => {
  userStore.updateTutorialStatuses(
    Object.values(TutorialName)
      .filter((e): e is number => typeof e === "number")
      .map((e) => ({ name: e, status: 1000 }))
  );
};

const resetAllTutorials = () => {
  userStore.updateTutorialStatuses(
    Object.values(TutorialName)
      .filter((e): e is number => typeof e === "number")
      .map((e) => ({ name: e, status: 1 })),
    true
  );
};

const toggleDebugInfoShown = () => {
  pageStore.setShowTiming(!pageStore.showDebugInfo);
};

const toggleTouchCapabilities = () => {
  pageStore.setIsTouch(!pageStore.hasTouch);
};

const testSentryFrontend = () => {
  // TODO figure out how to get this past the dedupe filter
  Sentry.captureMessage("Intentional Sentry message");
};

const testSentryBackend = () => {
  backendOld.createTestError();
};

const sendConsoleToSentry = () => {
  Sentry.captureMessage(JSON.stringify(pageStore.consoleData));
};

const adminNavigation = computed(() =>
  [
    { title: "Local", base: "http://localhost:5173", disabled: environmentStore.isLocalStandard },
    { title: "Local build", base: "http://localhost:8000", disabled: environmentStore.isLocalBuild },
    { title: "Proxy", base: "https://dart.ngrok.app", disabled: environmentStore.isProxy },
    { title: "Preview 1", base: "https://preview-1.itsdart.com", disabled: environmentStore.isPreview1 },
    { title: "Preview 2", base: "https://preview-2.itsdart.com", disabled: environmentStore.isPreview2 },
    { title: "Preview 3", base: "https://preview-3.itsdart.com", disabled: environmentStore.isPreview3 },
    { title: "Staging", base: "https://stag.itsdart.com", disabled: environmentStore.isStag },
    { title: "Production", base: "https://app.itsdart.com", disabled: environmentStore.isProd },
  ].map((e) => ({
    ...e,
    navigate: {
      to: e.base + router.currentRoute.value.fullPath,
      newTab: !pageStore.isNativeApp,
    },
    kind: DropdownMenuItemKind.EXTERNAL_LINK,
  }))
);

const toggleMobileItem = computed(() => {
  let [curr, alt] = ["desktop", "mobile"];
  if (pageStore.isMobile) {
    [curr, alt] = [alt, curr];
  }
  return {
    title: `Switch from ${curr} to ${alt}`,
    kind: DropdownMenuItemKind.BUTTON,
    onClick: () => {
      if (pageStore.isDesktop) {
        pageStore.isIos = pageStore.isMac;
        pageStore.isMac = false;
        pageStore.isAndroid = pageStore.isWindows;
        pageStore.isWindows = false;
        pageStore.isUbuntu = false;
        pageStore.isDesktop = false;
        pageStore.isMobileApp = pageStore.isDesktopApp;
        pageStore.isDesktopApp = false;
        pageStore.isMobile = true;
        pageStore.isNativeApp = pageStore.isMobileApp;
      } else {
        pageStore.isMac = pageStore.isIos;
        pageStore.isWindows = pageStore.isAndroid;
        pageStore.isUbuntu = false;
        pageStore.isDesktop = true;
        pageStore.isDesktopApp = pageStore.isMobileApp;
        pageStore.isIos = false;
        pageStore.isAndroid = false;
        pageStore.isMobile = false;
        pageStore.isMobileApp = false;
        pageStore.isNativeApp = pageStore.isDesktopApp;
      }
    },
  };
});
const toggleAppItem = computed(() => {
  let [curr, alt] = ["browser", "app"];
  if (pageStore.isNativeApp) {
    [curr, alt] = [alt, curr];
  }
  return {
    title: `Switch from ${curr} to ${alt}`,
    kind: DropdownMenuItemKind.BUTTON,
    onClick: () => {
      if (pageStore.isNativeApp) {
        pageStore.isDesktopApp = false;
        pageStore.isMobileApp = false;
        pageStore.isNativeApp = false;
      } else {
        if (pageStore.isDesktop) {
          pageStore.isDesktopApp = true;
          pageStore.isMobileApp = false;
        } else {
          pageStore.isDesktopApp = false;
          pageStore.isMobileApp = true;
        }
        pageStore.isNativeApp = true;
      }
    },
  };
});
const toggleOsItem = computed(() => {
  let curr;
  let alt;
  if (pageStore.isDesktop) {
    [curr, alt] = ["MacOS", "Windows"];
    if (pageStore.isWindows) {
      [curr, alt] = [alt, curr];
    }
  } else {
    [curr, alt] = ["iOS", "Android"];
    if (pageStore.isAndroid) {
      [curr, alt] = [alt, curr];
    }
  }
  return {
    title: `Switch from ${curr} to ${alt}`,
    kind: DropdownMenuItemKind.BUTTON,
    onClick: () => {
      if (pageStore.isDesktop) {
        pageStore.isIos = false;
        pageStore.isAndroid = false;
        if (pageStore.isMac) {
          pageStore.isMac = false;
          pageStore.isWindows = true;
        } else {
          pageStore.isMac = true;
          pageStore.isWindows = false;
        }
      } else {
        if (pageStore.isIos) {
          pageStore.isIos = false;
          pageStore.isAndroid = true;
        } else {
          pageStore.isIos = true;
          pageStore.isAndroid = false;
        }
        pageStore.isMac = false;
        pageStore.isWindows = false;
      }
    },
  };
});

const dropdownSections = computed(() => [
  {
    title: "Links",
    items: [
      {
        title: "Superadmin links",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Superadmin links",
          sections: [
            {
              title: "Superadmin links",
              items: [
                {
                  title: "Database admin",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  hidden: !userStore.isAdmin,
                  navigate: {
                    to: "/api/admin/",
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "Argo CD",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  hidden: !userStore.isAdmin,
                  disabled: !(environmentStore.isProd || environmentStore.isStag),
                  navigate: {
                    to: `https://argocd.${environmentStore.isStag ? "stag." : ""}itsdart.com`,
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "Flower",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  hidden: !userStore.isAdmin,
                  disabled: !(environmentStore.isProd || environmentStore.isStag),
                  navigate: {
                    to: `https://flower.${environmentStore.isStag ? "stag." : ""}itsdart.com`,
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "Make teammate login token",
                  kind: DropdownMenuItemKind.BUTTON,
                  hidden: !userStore.isAdmin,
                  onClick: makeTeammateLoginToken,
                },
                {
                  title: "Superuser dashboard",
                  // TODO open in new tab
                  kind: DropdownMenuItemKind.INTERNAL_LINK,
                  hidden: !userStore.isAdmin,
                  navigate: { to: { name: "superuser_dashboard" } },
                },
              ],
            },
          ],
        },
      },
      {
        title: "Admin links",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Admin links",
          sections: [
            {
              title: "Admin links",
              items: [
                {
                  title: "API docs",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  navigate: {
                    to: "/api/v0/docs/",
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "API schema",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  navigate: {
                    to: "/api/v0/schema/",
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "Storybook",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  navigate: {
                    to: !environmentStore.isLocal ? "/static/dist/storybook/index.html" : "http://localhost:6006/",
                    newTab: !pageStore.isMobileApp,
                  },
                  disabled: environmentStore.isProd,
                },
                {
                  title: "Brandbook",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  navigate: {
                    to: "https://live.standards.site/dart",
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "Internal status page",
                  kind: DropdownMenuItemKind.EXTERNAL_LINK,
                  navigate: {
                    to: "https://dart-internal.betteruptime.com",
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "Reset password",
                  kind: DropdownMenuItemKind.INTERNAL_LINK,
                  navigate: {
                    to: { name: "reset_password" },
                    newTab: !pageStore.isMobileApp,
                  },
                },
                {
                  title: "Console",
                  kind: DropdownMenuItemKind.INTERNAL_LINK,
                  navigate: { to: { name: "console" } },
                },
                {
                  title: "Latency report",
                  kind: DropdownMenuItemKind.INTERNAL_LINK,
                  navigate: { to: { name: "latency" } },
                },
              ],
            },
          ],
        },
      },
      {
        title: "Other environments",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Other environments",
          widthPixels: 115,
          sections: [
            {
              title: "Other environments",
              items: adminNavigation.value,
            },
          ],
        },
      },
    ],
  },
  {
    title: "Configuration",
    items: [
      {
        title: "Debug",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Debug",
          sections: [
            {
              title: "Debug",
              items: [
                {
                  title: `${pageStore.showDebugInfo ? "Hide" : "Show"} debug info`,
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: toggleDebugInfoShown,
                },
                {
                  title: "Clear all local data",
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: () => actions.app.clearAllLocalData(),
                },
                {
                  title: `${pageStore.isOnline ? "Disable" : "Enable"} internet connection`,
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: () => {
                    pageStore.isOnline = !pageStore.isOnline;
                  },
                },
                {
                  title: "Transaction barrage",
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: transactionBarrage,
                },
              ],
            },
          ],
        },
      },
      {
        title: "Appearance",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Appearance",
          sections: [
            {
              title: "Appearance",
              items: [
                {
                  title: "Hide admin",
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: () => {
                    pageStore.adminHidden = true;
                  },
                },
                {
                  title: "Complete all tutorials",
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: completeAllTutorials,
                },
                {
                  title: "Reset all tutorials",
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: resetAllTutorials,
                },
              ],
            },
          ],
        },
      },
      {
        title: "Platform",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Platform",
          widthPixels: 280,
          sections: [
            {
              title: "Platform",
              items: [
                toggleMobileItem.value,
                toggleAppItem.value,
                toggleOsItem.value,
                {
                  title: `Turn touch ${pageStore.hasTouch ? "off" : "on"}`,
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: toggleTouchCapabilities,
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    title: "Third party tools",
    items: [
      {
        title: "Sentry",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Sentry",
          sections: [
            {
              title: "Sentry",
              items: [
                {
                  title: "Test Sentry frontend",
                  kind: DropdownMenuItemKind.BUTTON,
                  disabled: !pageStore.runTrackingTools,
                  onClick: testSentryFrontend,
                },
                {
                  title: "Test Sentry backend",
                  kind: DropdownMenuItemKind.BUTTON,
                  disabled: !pageStore.runTrackingTools,
                  onClick: testSentryBackend,
                },
                {
                  title: "Send console to Sentry",
                  kind: DropdownMenuItemKind.BUTTON,
                  disabled: !pageStore.runTrackingTools,
                  onClick: sendConsoleToSentry,
                },
              ],
            },
          ],
        },
      },
      {
        title: "Stripe",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Stripe",
          sections: [
            {
              title: "Stripe",
              items: [
                {
                  title: "Reset Stripe account",
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: backendOld.billing.resetAccount,
                },
                {
                  title: "Delete Stripe subscriptions",
                  kind: DropdownMenuItemKind.BUTTON,
                  onClick: backendOld.billing.deleteSubscriptions,
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    title: "Deployment info",
    items: [
      {
        title: "Deployment info",
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: "Deployment info",
          sections: [
            {
              title: "Deployment info",
              items: [
                {
                  title: "Deployment info",
                  kind: DropdownMenuItemKind.COMPONENT,
                  component: AdminDropdownBackendSummary,
                  noFocus: true,
                },
              ],
            },
          ],
        },
      },
    ],
  },
]);
</script>

<template>
  <div :style="{ '--background': colors.bgStd, '--highlight': colors.highlight }">
    <DropdownMenu :sections="dropdownSections" :placement="Placement.RIGHT_BOTTOM" :distance="0">
      <slot />
    </DropdownMenu>
  </div>
</template>
