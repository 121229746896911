<script setup lang="ts">
import type { SerializedEditorState } from "lexical";
import { useLexicalComposer } from "lexical-vue";

import { areLexicalStatesSame } from "~/utils/common";

const editor = useLexicalComposer();

const setEditorState = (state: SerializedEditorState) => {
  if (areLexicalStatesSame(state, editor.getEditorState().toJSON())) {
    return;
  }

  editor.setEditorState(editor.parseEditorState(state));
};

defineExpose({
  setEditorState,
});
</script>

<template>
  <slot />
</template>
