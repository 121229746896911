<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import {
  BrainstormIcon,
  PlanProjectIcon,
  PremiumFeatureIcon,
  RecommendationIcon,
  RecommendWithAiIcon,
  ReportIcon,
} from "~/icons";
import { ButtonStyle, DropdownMenuItemKind, IconSize, PageKind, Placement } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const working = ref(false);
const dropdownButton = ref<InstanceType<typeof Button> | null>(null);

const modifyingTaskCount = computed(() =>
  appStore.currentDartboardDuid ? dataStore.getTasksByDartboardDuidOrdered(appStore.currentDartboardDuid).length : 0
);
const space = computed(() => {
  const { currentPage } = appStore;
  if (!currentPage || currentPage.pageKind !== PageKind.DARTBOARD) {
    return undefined;
  }
  return dataStore.getSpaceByDuid(currentPage.spaceDuid);
});
const dropdownSections = computed(() => [
  {
    title: "Project AI",
    items: [
      {
        title: "Create a project",
        kind: DropdownMenuItemKind.BUTTON,
        icon: RecommendWithAiIcon,
        hidden: !pageStore.showAlpha,
        onClick: () => {
          if (tenantStore.isPremium) {
            appStore.setAgentDemoModalOpen(true);
          } else {
            appStore.setUpgradeConfirmationModalOpen("create a project with AI");
          }
        },
      },
      {
        title: "Brainstorm tasks",
        kind: DropdownMenuItemKind.BUTTON,
        icon: BrainstormIcon,
        onClick: () => {
          if (tenantStore.isPremium) {
            appStore.setBrainstormModalOpen(true);
          } else {
            appStore.setUpgradeConfirmationModalOpen("brainstorm tasks with AI");
          }
        },
      },
      {
        title: "Plan this project",
        kind: DropdownMenuItemKind.BUTTON,
        icon: PlanProjectIcon,
        disabled: modifyingTaskCount.value === 0,
        disabledReason: "Add tasks to this page first",
        onClick: () => {
          if (tenantStore.isPremium) {
            appStore.setPlanProjectModalOpen(true);
          } else {
            appStore.setUpgradeConfirmationModalOpen("plan a project with AI");
          }
        },
      },
      {
        title: "Generate a report",
        kind: DropdownMenuItemKind.BUTTON,
        icon: ReportIcon,
        onClick: () => {
          if (tenantStore.isPremium) {
            appStore.setSpaceOpenInReportCreationModal(space.value ?? null);
          } else {
            appStore.setUpgradeConfirmationModalOpen("generate a report with AI");
          }
        },
      },
    ],
  },
]);

const setWorking = (newWorking: boolean) => {
  working.value = newWorking;
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.projectAiDropdown = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.projectAiDropdown = null;
});

defineExpose({
  button: computed(() => dropdownButton.value?.$el ?? null),
  setWorking,
});
</script>

<template>
  <DropdownMenu
    :sections="dropdownSections"
    :placement="Placement.BOTTOM_RIGHT"
    :distance="2"
    is-contrast
    :disabled="working"
    :width-pixels="240">
    <Tooltip text="Use AI for project management">
      <Button
        ref="dropdownButton"
        class="!px-1.5 !py-0.5 leading-[18px]"
        text="Project AI"
        :icon="tenantStore.isPremium ? RecommendationIcon : PremiumFeatureIcon"
        :icon-args="{ class: '-mr-1' }"
        :working="working"
        :icon-size="IconSize.XS"
        :btn-style="ButtonStyle.SECONDARY_RECOMMENDATION" />
    </Tooltip>
  </DropdownMenu>
</template>
