<script setup lang="ts">
import { computed } from "vue";

import { KeyboardShortcutStrokeKind } from "~/shared/enums";
import type { KeyboardShortcutStroke } from "~/shared/types";
import { usePageStore } from "~/stores";

const props = defineProps<{
  strokes?: KeyboardShortcutStroke[];
  disabled?: boolean;
  inline?: boolean;
  outlineOnly?: boolean;
}>();

const pageStore = usePageStore();

const formattedStrokes = computed(() =>
  props.strokes?.map((stroke, i) => ({
    id: i,
    text: typeof stroke === "string" ? stroke : stroke.content,
    kind: typeof stroke === "string" ? KeyboardShortcutStrokeKind.KEY : stroke.kind,
  }))
);
</script>

<template>
  <div
    v-if="!pageStore.isPublic && !pageStore.isMobile && formattedStrokes"
    :class="inline ? 'inline-flex' : 'flex'"
    class="select-none items-center gap-1">
    <span
      v-for="stroke in formattedStrokes"
      :key="stroke.id"
      :class="{
        'opacity-50': disabled,
        'h-[18px] min-w-[18px] rounded px-1 text-center text-xs/[18px] font-medium text-md':
          stroke.kind === KeyboardShortcutStrokeKind.KEY,
        'border border-gray-400 dark:border-zinc-500': stroke.kind === KeyboardShortcutStrokeKind.KEY && outlineOnly,
        'group-hover/strokes:bg-hvy': stroke.kind === KeyboardShortcutStrokeKind.KEY && !disabled,
        'bg-md': stroke.kind === KeyboardShortcutStrokeKind.KEY && !outlineOnly,
        'text-center': stroke.kind !== KeyboardShortcutStrokeKind.KEY,
        'text-xs': stroke.kind === KeyboardShortcutStrokeKind.SEPARATOR,
        'text-sm': stroke.kind === KeyboardShortcutStrokeKind.TEXT,
        'text-lt':
          stroke.kind === KeyboardShortcutStrokeKind.SEPARATOR || stroke.kind === KeyboardShortcutStrokeKind.TEXT,
        '-mx-1 font-mono text-sm text-md': stroke.kind === KeyboardShortcutStrokeKind.MARKDOWN,
      }">
      {{ stroke.text }}
    </span>
  </div>
</template>
