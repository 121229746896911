<script setup lang="ts">
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ReadAllIcon, SettingsIcon } from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import { ButtonStyle, IconSize } from "~/shared/enums";
import type InboxView from "~/views/InboxView.vue";

defineProps<{
  inbox: InstanceType<typeof InboxView>;
}>();

const router = useRouter();

const openSettings = () => router.push(makeLinkToSettingsRef("notifications").value);
</script>

<template>
  <div class="flex gap-1 app-drag-none">
    <Tooltip :disabled="inbox.areUnreads" text="Mark all unread notifications as read">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :icon="ReadAllIcon"
        :icon-args="{
          class: 'text-primary-base',
        }"
        :icon-size="IconSize.S"
        text="Mark all read"
        :disabled="inbox.areUnreads"
        class="h-6 pl-1 pr-1.5"
        @click="inbox.markAllAsRead" />
    </Tooltip>
    <Tooltip text="Open notification settings">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :icon="SettingsIcon"
        :icon-size="IconSize.S"
        class="icon-lg"
        a11y-label="Open notification settings"
        @click="openSettings" />
    </Tooltip>
  </div>
</template>
