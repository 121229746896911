<script setup lang="ts">
import { $getNodeByKey, $isTextNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed } from "vue";

import { getPropertyTypeaheadOption } from "~/common/properties";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { XIcon } from "~/icons";
import { EditorMode } from "~/shared/enums";
import type { TypeaheadOption } from "~/shared/types";
import { usePageStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";

type Value = TypeaheadOption["value"];

const props = defineProps<{
  nodeKey: string;
  propertyDuid: string;
  value: Value;
}>();

const pageStore = usePageStore();
const editor = useLexicalComposer();

const isDoc = editor._config.namespace.startsWith("doc");
const isRemovable = editor._config.namespace.startsWith("title");
const editorMode = isDoc
  ? EditorMode.DOC
  : EditorMode[editor._config.namespace.split("-")[1].toUpperCase() as keyof typeof EditorMode];
const isBig = editorMode === EditorMode.DETAIL || editorMode === EditorMode.TCM;

const propertyAndOption = computed(() => getPropertyTypeaheadOption(props.propertyDuid, props.value));
const property = computed(() => propertyAndOption.value?.[0]);
const option = computed(() => propertyAndOption.value?.[1]);
// TODO make more robust and use a proper tooltip
const title = computed(() => `${property.value?.title}: ${option.value?.label}`);

const colorSet = computed(() => getChipColorsHex(option.value?.chipColorHex ?? DEFAULT_CHIP_COLOR, pageStore.theme));

const removeSelf = () => {
  editor.update(() => {
    const self = $getNodeByKey(props.nodeKey);
    if (!self) {
      return;
    }
    const prev = self.getPreviousSibling();
    const next = self.getNextSibling();
    if (prev && next && $isTextNode(prev) && $isTextNode(next)) {
      prev.setTextContent(`${prev.getTextContent().trimEnd()} `);
      next.setTextContent(`${next.getTextContent().trimStart()}`);
    }
    self.remove();
  });
};
</script>

<template>
  <div
    :title="title"
    class="group/entity relative inline-flex items-center align-middle"
    :class="isBig ? 'mt-[-3px]' : '-mt-0.5'">
    <div
      class="flex items-center justify-center rounded border px-1 py-px text-xs font-normal"
      :style="{ color: colorSet.fore, backgroundColor: colorSet.back, borderColor: colorSet.border }">
      <div v-if="option?.chipIcon" class="mr-1 icon-sm">
        <component :is="option.chipIcon" v-bind="{ ...option.chipIconArgs, class: 'icon-sm' }" />
      </div>
      <div :class="option?.chipIcon && 'mr-0.5'">
        {{ option?.label ?? "(Unknown)" }}
      </div>
    </div>

    <template v-if="isRemovable">
      <!-- dummy elements to help avoid overshooting the x -->
      <div class="absolute -top-1.5 right-0 hidden h-1.5 w-3 group-hover/entity:flex" />
      <div class="absolute -right-1.5 top-0 hidden h-3 w-1.5 group-hover/entity:flex" />
      <button
        type="button"
        class="absolute -right-1.5 -top-1.5 z-10 hidden cursor-pointer items-center justify-center rounded-full border bg-lt border-md icon-xs group-hover/entity:flex"
        aria-label="Remove"
        @click="removeSelf"
        @keydown.enter="removeSelf">
        <XIcon class="size-full text-lt focus:outline-none" />
      </button>
    </template>
  </div>
</template>
