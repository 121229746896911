<script setup lang="ts">
import "splitpanes/dist/splitpanes.css";

import { clamp, useElementSize, useMouseInElement, useParentElement } from "@vueuse/core";
import { Pane, Splitpanes } from "splitpanes";
import { computed, onUnmounted, ref, watch } from "vue";

import { THROTTLE_MS } from "~/constants/app";
import { ThrottleManager } from "~/utils/throttleManager";

const props = defineProps<{
  paneWidthPx: number;
  paneMinPx?: number;
  paneMaxPx?: number;
  contentMinPx?: number;
  styles?: string;
  left?: boolean;
  hidePane?: boolean;
}>();

const emit = defineEmits<{
  afterResize: [];
  save: [width: number];
  edge: [left: boolean];
}>();

const parent = useParentElement();
const { elementX: mouseXInParent } = useMouseInElement(parent);
const { width: parentWidth } = useElementSize(parent);

const paneMinPctg = computed(() => ((props.paneMinPx ?? 0) / parentWidth.value) * 100);
const contentMinPctg = computed(() => ((props.contentMinPx ?? 0) / parentWidth.value) * 100);
const paneMaxPctg = computed(() =>
  Math.min(((props.paneMaxPx ?? Infinity) / parentWidth.value) * 100, 100 - contentMinPctg.value)
);

const innerPaneWidthPx = ref(props.paneWidthPx);
const innerPaneWidthPctg = ref(0);

const updatePctg = () => {
  innerPaneWidthPctg.value = clamp(
    (innerPaneWidthPx.value / parentWidth.value) * 100,
    paneMinPctg.value,
    paneMaxPctg.value
  );
};

updatePctg();
watch(() => parentWidth.value, updatePctg);
watch(() => props.hidePane, updatePctg);

const saveManager = new ThrottleManager((newPaneWidthPx: number) => {
  emit("save", newPaneWidthPx);
}, THROTTLE_MS);

watch(
  () => props.paneWidthPx,
  (newPaneWidthPx) => {
    if (newPaneWidthPx === innerPaneWidthPx.value) {
      return;
    }

    innerPaneWidthPx.value = newPaneWidthPx;
    updatePctg();
    saveManager.cancel();
  }
);

const onAfterPaneResize = () => {
  emit("afterResize");
};

const onSavePaneSize = (e: { size: number }[]) => {
  if (e.length !== 2) {
    return;
  }

  innerPaneWidthPx.value = (parentWidth.value * e[props.left ? 0 : 1].size) / 100;
  saveManager.run(innerPaneWidthPx.value);

  if (mouseXInParent.value < 50) {
    emit("edge", true);
  } else if (mouseXInParent.value > parentWidth.value - 50) {
    emit("edge", false);
  }

  onAfterPaneResize();
};

onUnmounted(() => {
  saveManager.destroy();
});
</script>

<template>
  <Splitpanes
    class="flex size-full overflow-hidden"
    :dbl-click-splitter="false"
    @resized="onAfterPaneResize"
    @resize="onSavePaneSize">
    <Pane
      v-if="left && !hidePane"
      key="pane-left"
      :min-size="paneMinPctg"
      :max-size="paneMaxPctg"
      :size="innerPaneWidthPctg">
      <slot name="pane" />
    </Pane>
    <Pane key="content" :class="styles" :min-size="contentMinPctg" :size="hidePane ? 100 : 100 - innerPaneWidthPctg">
      <slot name="default" />
    </Pane>
    <Pane
      v-if="!left && !hidePane"
      key="pane-right"
      :min-size="paneMinPctg"
      :max-size="paneMaxPctg"
      :size="innerPaneWidthPctg">
      <slot name="pane" />
    </Pane>
  </Splitpanes>
</template>

<style>
.splitpanes__splitter {
  @apply relative -mr-px cursor-col-resize;
}
.splitpanes--vertical > .splitpanes__pane {
  @apply !transition-none;
}
.splitpanes__splitter:before,
.splitpanes__splitter:after {
  /* TODO The z-[1] is needed temporarily because in the Roadmap the current date cuts the divider line off by a pixel. This makes sense because the divider is a fake element. We can shift the date by a pixel to avoid this. */
  @apply absolute z-[1] transition-colors content-[""];
}
.splitpanes--vertical > .splitpanes__splitter:before {
  @apply -left-1.5 -right-1 h-full;
}
.splitpanes--vertical > .splitpanes__splitter:after {
  @apply -left-[2px] right-0 h-full hover:bg-hvy;
}
</style>
