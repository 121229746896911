<script setup lang="ts">
import { Switch } from "@headlessui/vue";
import equal from "deep-equal";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TutorialMessage from "~/components/dumb/TutorialMessage.vue";
import { createNewTaskNotification } from "~/components/notifications/notification";
import { ButtonStyle, CommandId, Placement, TutorialName } from "~/shared/enums";
import type { Dartboard, Task, TaskUpdate } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { makeEmptyLexicalState } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

const props = defineProps<{
  task?: Task;
  subtasks: Task[];
  draftCanBeFinished?: boolean;
}>();

const emit = defineEmits<{
  enter: [event: KeyboardEvent];
  finish: [];
  close: [];
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const getOrderForNextTask = (dartboard: Dartboard) => {
  const topTaskOrder = dataStore.getTasksByDartboardDuidOrdered(dartboard.duid ?? "", {
    includeTrashed: true,
    includeDraft: true,
  })?.[0]?.order;
  return getOrdersBetween(undefined, topTaskOrder)[0];
};

const finish = async (createMoreOverride = false) => {
  const { task, draftCanBeFinished } = props;
  if (!task || !draftCanBeFinished) {
    return;
  }
  const dartboard = dataStore.getDartboardByDuid(task.dartboardDuid);
  if (!dartboard) {
    return;
  }

  const title = task.title.trim();

  const taskUpdate: TaskUpdate = {
    duid: task.duid,
    order: getOrderForNextTask(dartboard),
    drafterDuid: null,
  };
  if (title !== undefined) {
    taskUpdate.title = title;
  }

  dataStore.updateAndDeleteTasks(
    [
      taskUpdate,
      ...props.subtasks.map((e) => {
        const res: TaskUpdate = {
          duid: e.duid,
          drafterDuid: null,
          ...(tenantStore.copyParentFieldsOnCreate && {
            ...(e.dartboardDuid !== task.dartboardDuid && { dartboardDuid: task.dartboardDuid }),
            ...(!equal(e.tagDuids, task.tagDuids, { strict: true }) && { tagDuids: [...task.tagDuids] }),
            ...(e.priority !== task.priority && { priority: task.priority }),
            ...(e.size !== task.size && { size: task.size }),
            ...(e.dueAt !== task.dueAt && { dueAt: task.dueAt }),
            ...(e.remindAt !== task.remindAt && { remindAt: task.remindAt }),
          }),
        };
        return res;
      }),
    ],
    [],
    {
      makeDescription: (isActive, updateDescriptionContent) =>
        `${isActive ? "creating" : "created"} ${updateDescriptionContent}`,
      overrideFilters: true,
    }
  );

  if (dartboard.duid !== appStore.currentPage?.duid) {
    createNewTaskNotification(
      [task],
      dartboard,
      dataStore.getSpaceByDuid,
      actions.visualization.navigateToTask,
      router
    );
  }

  userStore.updateTutorialStatuses([{ name: TutorialName.CREATE_TASK_WITH_SUBTASK_RECS, status: 6 }]);

  const createMore = createMoreOverride || appStore.createTaskCreateMore;

  if (!createMore) {
    emit("close");
    return;
  }

  const order = getOrderForNextTask(dartboard);
  await dataStore.replicateTasks(
    [
      {
        task,
        order,
        partialTask: { drafterDuid: userStore.duid, title: "", description: makeEmptyLexicalState() },
      },
    ],
    { noUndo: true, propertiesOnly: true }
  );

  emit("finish");
};

const createMoreEnabled = ref(appStore.createTaskCreateMore);
watch(
  () => createMoreEnabled.value,
  (newValue) => {
    appStore.setCreateTaskCreateMore(newValue);
  }
);

defineExpose({
  finish,
});
</script>

<template>
  <div
    class="flex items-center gap-2 px-4 pb-5 sm:pb-4"
    @click.stop
    @keydown.enter.stop="(event) => emit('enter', event)">
    <div class="flex items-center gap-5">
      <div v-if="!pageStore.isMobile" class="flex gap-2">
        <Switch
          v-model="createMoreEnabled"
          :class="appStore.createTaskCreateMore ? 'bg-primary-base' : 'bg-md'"
          class="relative inline-flex h-5 w-8 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std"
          aria-label="Create more toggle">
          <span
            aria-hidden="true"
            :class="appStore.createTaskCreateMore ? 'translate-x-3' : 'translate-x-0'"
            class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-sm" />
        </Switch>
        <span class="select-none text-sm text-lt">Create more</span>
      </div>

      <TutorialMessage :name="TutorialName.CREATE_TASK_WITH_SUBTASK_RECS" :step="4" :placement="Placement.BOTTOM_RIGHT">
        <Tooltip
          :command-ids="[
            CommandId.SAVE_TASK_OPEN_IN_CREATION_MODAL,
            CommandId.SAVE_TASK_OPEN_IN_CREATION_MODAL_AND_CREATE_ANOTHER,
          ]"
          :disabled="!draftCanBeFinished">
          <Button
           
            :btn-style="ButtonStyle.PRIMARY"
            text="Create task"
            :disabled="!draftCanBeFinished"
            @click="() => finish()" />
        </Tooltip>
      </TutorialMessage>
    </div>
  </div>
</template>
