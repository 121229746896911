<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import { useRouter } from "vue-router";

import DocPreview from "~/components/docs/DocPreview.vue";
import EntityDropdownItem from "~/components/dumb/EntityDropdownItem.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import { EditorMode } from "~/shared/enums";
import type { Doc, Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getDocLink, validateIsNotEmpty } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  open: [isOpen: boolean];
}>();

const router = useRouter();
const dataStore = useDataStore();

const menu = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const editing = ref(false);

const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);

const relatedDocs = computed(() => dataStore.getDocsRelatedToTaskOrdered(props.task.duid));

const onSelect = (docDuid: string) => {
  const doc = dataStore.getDocByDuid(docDuid);
  if (!doc) {
    return;
  }

  dataStore.createTaskDocRelationship(props.task.duid, doc.duid);
};

const onCreate = (title: string) => {
  const folder = dataStore.defaultFolder;
  const docs = dataStore.getDocsByFolderDuidOrdered(folder.duid);
  const topDocOrder = docs.length === 0 ? undefined : docs[0].order;
  const order = getOrdersBetween(undefined, topDocOrder)[0];
  dataStore.createDoc(title, folder.duid, order, {}, { relatedTaskDuids: [props.task.duid] });
};

const openMenu = () => {
  emit("open", true);
  editing.value = true;
  nextTick(() => {
    const element = menu.value;
    if (element) {
      element.$el.scrollIntoView();
      element.open();
    }
  });
};

const onAfterClose = () => {
  emit("open", false);
  editing.value = false;
};

const removeDoc = (docDuid: string) => {
  const doc = dataStore.getDocByDuid(docDuid);
  if (!doc) {
    return;
  }

  dataStore.deleteTaskDocRelationship(props.task.duid, doc.duid);
};

const openDoc = (doc: Doc) => {
  if (!isTaskDetailMode.value) {
    return;
  }
  router.push(getDocLink(doc));
};

const docOptions = computed(() =>
  dataStore.getDocList().map((doc) => ({
    value: doc.duid,
    label: doc.title,
    selected: relatedDocs.value.some((e) => e.duid === doc.duid),
    disabled: false,
    component: EntityDropdownItem,
  }))
);

defineExpose({
  openMenu,
});
</script>

<template>
  <div v-if="editing || relatedDocs.length > 0" class="mx-5 mt-1">
    <MultiselectDropdownMenu
      ref="menu"
      :container="isChipMode ? '#dart-task-creation-modal-wrapper' : undefined"
      :items="docOptions"
      placeholder="Link a doc..."
      :validate="validateIsNotEmpty"
      :block="isTaskDetailMode"
      cover
      :disabled="task.inTrash"
      :width-pixels="isChipMode ? 448 : undefined"
      :on-after-close="onAfterClose"
      @add="onSelect"
      @remove="removeDoc"
      @create="onCreate">
      <div class="grid w-full grid-cols-2 gap-2 text-left">
        <div
          v-for="target in relatedDocs"
          :key="target.duid"
          @click.stop="openDoc(target)"
          @keydown.enter.stop="openDoc(target)">
          <DocPreview :doc="target" :hide-remove="task.inTrash" @remove="removeDoc(target.duid)" />
        </div>
      </div>
    </MultiselectDropdownMenu>
  </div>
</template>
