import { notify } from "~/components/notifications";
import { BrainstormState, EventActor, NotificationType, TutorialName } from "~/shared/enums";
import type { Brainstorm, BrainstormUpdate } from "~/shared/types";
import { getItemCountText, makeDuid } from "~/utils/common";
import { makeStringComparator } from "~/utils/comparator";
import { afterBrainstorm } from "~/utils/fun";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  brainstormList: Brainstorm[];
  getBrainstormByDuid: (duid: string) => Brainstorm | undefined;
  getBrainstormByDartboardDuid: (dartboardDuid: string) => Brainstorm | undefined;
};

export type Actions = {
  /* Create a new brainstorm. */
  createBrainstorm: (
    dartboardDuid: string,
    subject: string,
    ai: boolean,
    totalSessionMs: number,
    options?: { awaitBackend?: boolean }
  ) => Promise<Brainstorm | undefined>;
  /** Update an existing brainstorm. */
  updateBrainstorm: (update: BrainstormUpdate, options?: { awaitBackend?: boolean }) => Promise<Brainstorm | undefined>;
  /* Pause a brainstorm. */
  pauseBrainstorm: (brainstorm: Brainstorm) => void;
  /* Resume a brainstorm. */
  resumeBrainstorm: (brainstorm: Brainstorm) => void;
  /* Stop a brainstorm. */
  stopBrainstorm: (brainstorm: Brainstorm) => void;
  /** Sort brainstorms.
   * @PRIVATE */
  $sortBrainstorms: () => void;
  /** Set brainstorms internally.
   * @PRIVATE */
  $setBrainstorms: (brainstorms: Brainstorm[]) => void;
  /** Create or update brainstorm from WS.
   * @PRIVATE */
  $createOrUpdateBrainstorm: (brainstorm: Brainstorm) => void;
  /** Delete brainstorm from WS.
   * @PRIVATE */
  $deleteBrainstorm: (brainstorm: Brainstorm) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  brainstormList() {
    return Array.from(this._duidsToBrainstorms.values());
  },
  getBrainstormByDuid() {
    return (duid) => this._duidsToBrainstorms.get(duid);
  },
  getBrainstormByDartboardDuid() {
    return (dartboardDuid) => this.brainstormList.find((e) => e.dartboardDuid === dartboardDuid);
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createBrainstorm(dartboardDuid, subject, ai, totalSessionMs, options = {}) {
    const { awaitBackend = false } = options;
    const userStore = this.$useUserStore();
    userStore.updateTutorialStatuses([{ name: TutorialName.BRAINSTORM_WITH_AI, status: 2 }]);

    const activeBrainstorm = this.getBrainstormByDartboardDuid(dartboardDuid);
    if (activeBrainstorm) {
      return undefined;
    }

    const now = new Date().toISOString();

    const brainstorm: Brainstorm = {
      duid: makeDuid(),
      dartboardDuid,
      subject,
      ai,
      createdTasksDuids: [],
      startedAt: now,
      totalSessionMs,
      afterStartMs: totalSessionMs,
      state: BrainstormState.RUNNING,
    };

    this.$createOrUpdateBrainstorm(brainstorm);

    const backendAction = this.$backend.brainstorm.create(brainstorm);
    if (awaitBackend) {
      await backendAction;
    }
    return brainstorm;
  },
  async updateBrainstorm(update, options = {}) {
    const { awaitBackend = false } = options;
    const brainstorm = this.getBrainstormByDuid(update.duid);
    if (!brainstorm) {
      return undefined;
    }

    Object.assign(brainstorm, update);
    this.$sortBrainstorms();

    const backendAction = this.$backend.brainstorm.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return brainstorm;
  },
  pauseBrainstorm(brainstorm) {
    if (brainstorm.state !== BrainstormState.RUNNING) {
      return;
    }

    const remainingTime = brainstorm.afterStartMs - (new Date().getTime() - new Date(brainstorm.startedAt).getTime());
    this.updateBrainstorm({
      duid: brainstorm.duid,
      state: BrainstormState.PAUSED,
      afterStartMs: remainingTime,
    });
  },
  resumeBrainstorm(brainstorm) {
    if (brainstorm.state !== BrainstormState.PAUSED) {
      return;
    }

    this.updateBrainstorm({
      duid: brainstorm.duid,
      state: BrainstormState.RUNNING,
      startedAt: new Date().toISOString(),
    });
  },
  stopBrainstorm(brainstorm) {
    this.updateBrainstorm({ duid: brainstorm.duid, state: BrainstormState.STOPPED });
  },
  $sortBrainstorms() {
    this._duidsToBrainstorms = new Map([...this._duidsToBrainstorms].sort(makeStringComparator((e) => e[1].startedAt)));
  },
  $setBrainstorms(brainstorms) {
    this._duidsToBrainstorms = new Map(brainstorms.map((e) => [e.duid, e]));

    this.$sortBrainstorms();
  },
  $createOrUpdateBrainstorm(brainstorm) {
    const currentBrainstorm = this.getBrainstormByDuid(brainstorm.duid);

    if (!currentBrainstorm) {
      this._duidsToBrainstorms.set(brainstorm.duid, brainstorm);
    } else {
      Object.assign(currentBrainstorm, brainstorm);
    }

    /* If brainstorm is stopped, clean it up. */
    if (brainstorm.state === BrainstormState.STOPPED) {
      if (this.$useAppStore().currentDartboardDuid !== brainstorm.dartboardDuid) {
        return;
      }

      afterBrainstorm();
      notify({
        message: `The brainstorm is finished! Your team${
          brainstorm.ai ? `, including ${EventActor.DART_AI},` : ""
        } came up with ${getItemCountText(brainstorm.createdTasksDuids.length, "task")}`,
        type: NotificationType.SUCCESS,
      });

      this.$deleteBrainstorm(brainstorm);
    }

    this.$sortBrainstorms();
  },
  $deleteBrainstorm(brainstorm) {
    this._duidsToBrainstorms.delete(brainstorm.duid);
  },
};

export { actions, getters };
