import type { SmartMatchToken } from "~/shared/types";
import { escapeRegex } from "~/utils/common";

export type MenuTextMatch = {
  leadOffset: number;
  matchingString: string;
  replaceableString: string;
};

const makeFullSmartMatchRegex = (options: RegExp, blacklistRegex?: RegExp) =>
  new RegExp(
    "(?<whitespace>^|\\s)" + // Match space or start of string
      `${blacklistRegex ? `(?!${blacklistRegex.source})` : ""}` + // Blacklist options
      `(?<query>${options.source})` + // Match possible options
      "(?<end>\\s|$)", // Match space or end of string
    "i"
  );

const getSmartMatchRegex = (options: SmartMatchToken[], blacklist: Set<string>) =>
  makeFullSmartMatchRegex(
    new RegExp([...new Set(options.map((e) => e.match))].join("|")),
    blacklist.size > 0 ? new RegExp([...blacklist].map((e) => escapeRegex(e)).join("|")) : undefined
  );

export default getSmartMatchRegex;
