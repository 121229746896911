<script setup lang="ts">
import { ref } from "vue";

import { makeUuid } from "~/utils/common";

defineProps<{
  value: boolean;
  label: string;
  locked?: boolean;
  aiMode?: boolean;
  radio?: boolean;
}>();

const emit = defineEmits<{
  change: [newValue: boolean];
  click: [event: MouseEvent];
}>();

const inputRef = ref<HTMLInputElement | null>(null);

const change = () => {
  emit("change", !!inputRef.value?.checked);
};

const onClick = (e: MouseEvent) => {
  inputRef.value?.blur();
  emit("click", e);
};

const id = ref(`checkbox-${makeUuid()}`);
</script>

<template>
  <div class="relative flex w-fit">
    <label :for="id" class="sr-only">{{ label }}</label>
    <input
      :id="id"
      ref="inputRef"
      :disabled="locked"
      :class="[
        aiMode
          ? 'border-recommendation-base text-recommendation-base'
          : 'text-primary-base border-hvy focus:border-hvy',
        value
          ? aiMode
            ? 'hover:text-recommendation-hover-light dark:hover:text-recommendation-hover-dark'
            : 'hover:text-primary-hover-light dark:hover:text-primary-hover-dark'
          : 'hover:bg-opposite/10',
        locked ? 'cursor-default' : 'cursor-pointer',
        radio ? 'rounded-full' : 'rounded',
      ]"
      class="border bg-transparent focus-ring-none"
      :type="radio ? 'radio' : 'checkbox'"
      :checked="value"
      @click="onClick"
      @change="change" />
  </div>
</template>
