<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import { TrashIcon } from "~/icons";
import { DialogMode } from "~/shared/enums";
import { useAppStore } from "~/stores";

const appStore = useAppStore();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const config = computed(() => appStore.deleteModalConfig);

const onDelete = () => {
  config.value?.onDelete();
  appStore.setDeleteConfirmationModalOpen(null);
};

watch(
  () => config.value,
  (open) => {
    if (!open) {
      return;
    }
    nextTick(() => {
      dialog.value?.openModal();
    });
  }
);
</script>

<template>
  <ConfirmationDialog
    v-if="config"
    ref="dialog"
    :mode="DialogMode.DELETE"
    :title="config.title"
    :description="config.description"
    confirm-text="Delete"
    cancel-text="Keep"
    :icon="TrashIcon"
    @confirm="onDelete" />
</template>
