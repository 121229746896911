<script setup lang="ts">
import moment from "moment";

import RecurrenceEditor from "~/components/dumb/RecurrenceEditor.vue";
import type { Recurrence, Space } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getRecursNextAtStr, normalizeRecursNextAt } from "~/utils/time";

const props = defineProps<{
  space: Space;
}>();

const dataStore = useDataStore();

const updateField = (spacePartial: Partial<Space>) => {
  dataStore.updateSpace({ duid: props.space.duid, ...spacePartial });
};

const updateRecurrence = (newRecurrence: Recurrence | null, isStandup: boolean) => {
  const recursNextAtStr = getRecursNextAtStr(
    newRecurrence,
    isStandup ? props.space.standupRecursNextAt : props.space.changelogRecursNextAt
  );

  if (isStandup) {
    updateField({
      standupRecurrence: newRecurrence,
      standupRecursNextAt: recursNextAtStr,
    });
  } else {
    updateField({
      changelogRecurrence: newRecurrence,
      changelogRecursNextAt: recursNextAtStr,
    });
  }
};

const updateRecursNextAt = (newRecursNextAt: string | null, isStandup: boolean) => {
  const recurrence = isStandup ? props.space.standupRecurrence : props.space.changelogRecurrence;
  if (!recurrence) {
    return;
  }

  const recursNextAt = newRecursNextAt
    ? normalizeRecursNextAt(moment(newRecursNextAt), recurrence).toISOString()
    : null;
  if (isStandup) {
    updateField({
      standupRecursNextAt: recursNextAt,
    });
  } else {
    updateField({
      changelogRecursNextAt: recursNextAt,
    });
  }
};
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="font-medium text-md">Automatic reports</div>
    <div class="flex flex-col gap-4">
      <div class="flex justify-between gap-2">
        <span class="pt-2 text-sm text-md">Standup reports</span>
        <div class="flex w-72 flex-col gap-2">
          <RecurrenceEditor
            :recurrence="space.standupRecurrence"
            :recurs-next-at="space.standupRecursNextAt"
            show-time
            @change-recurrence="(e) => updateRecurrence(e, true)"
            @change-recurs-next-at="(e) => updateRecursNextAt(e, true)" />
        </div>
      </div>
      <div class="flex justify-between gap-2">
        <span class="pt-2 text-sm text-md">Changelog reports</span>
        <div class="flex w-72 flex-col gap-2">
          <RecurrenceEditor
            :recurrence="space.changelogRecurrence"
            :recurs-next-at="space.changelogRecursNextAt"
            show-time
            @change-recurrence="(e) => updateRecurrence(e, false)"
            @change-recurs-next-at="(e) => updateRecursNextAt(e, false)" />
        </div>
      </div>
    </div>
  </div>
</template>
