<script setup lang="ts">
import { computed } from "vue";

import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { NUMBER_SIZE_TO_PRETTY_LABEL, NUMBER_SIZE_TO_TSHIRT_SIZE } from "~/constants/size";
import { SizeFieldIcon, XIcon } from "~/icons";
import { EditorMode } from "~/shared/enums";
import type { Property } from "~/shared/types";

const props = defineProps<{
  value: number | null;
  showIfEmpty?: boolean;
  showBorder?: boolean;
  showIcon?: boolean;
  hoverable?: boolean;
  property?: Property;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  accept: [];
  reject: [];
}>();

const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isBaseChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isChipMode = computed(() => isBaseChipMode.value || isChipRecommendationMode.value);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL || isDetailRecommendationMode.value);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isRecommendationMode = computed(() => isChipRecommendationMode.value || isDetailRecommendationMode.value);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const label = computed(() => {
  if (props.value) {
    if (isTaskDetailMode.value || isFormMode.value) {
      return NUMBER_SIZE_TO_PRETTY_LABEL.get(props.value);
    }
    return NUMBER_SIZE_TO_TSHIRT_SIZE.get(props.value);
  }
  if (isChipMode.value) {
    return "Size";
  }
  if (isFormMode.value) {
    return "Set size";
  }
  return "None";
});

const rejectRecommendation = () => {
  emit("reject");
};
</script>

<template>
  <component
    :is="isBoardMode ? Tooltip : Template"
    v-if="value || showIfEmpty"
    :text="isBoardMode ? property?.title : undefined">
    <div
      class="flex select-none items-center justify-center rounded text-md"
      :class="{
        'border border-recommendation-base/50 bg-recommendation-base/30 hover:bg-recommendation-base/40 dark:hover:bg-recommendation-base/40':
          isChipRecommendationMode,
        'hover:bg-opposite/10': isBaseChipMode,
        'border border-oncolor': showBorder && !isChipRecommendationMode,
        'h-[26px] gap-1 py-0.5 text-sm': isChipMode,
        'gap-2': !isChipMode && !isBoardMode,
        'h-5 text-xs': !isChipMode && !isTaskDetailMode && !isFormMode,
        'px-2 hover:bg-lt': isFormMode || isTaskDetailMode,
        'px-1': !isFormMode && !isTaskDetailMode,
        'gap-1 pl-1 pr-1.5': isBoardMode,
      }">
      <SizeFieldIcon
        v-if="showIcon || isChipMode || (isTaskDetailMode && value) || (isFormMode && value)"
        :class="[
          isChipMode || isFormMode ? 'icon-sm' : 'icon-xs',
          isChipRecommendationMode ? 'text-recommendation-base' : isChipMode && value && 'text-primary-base',
        ]" />
      <span :class="(isTaskDetailMode || isFormMode) && !value && 'text-vlt'">
        {{ label }}
      </span>
      <Tooltip v-if="isRecommendationMode" text="Clear AI recommendation">
        <span
          class="rounded hover:bg-recommendation-base/30 dark:hover:bg-recommendation-base/40"
          @click.stop="rejectRecommendation"
          @keydown.enter.stop="rejectRecommendation">
          <XIcon class="icon-xs" />
        </span>
      </Tooltip>
    </div>
  </component>
</template>
