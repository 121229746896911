<script setup lang="ts">
import { $createRangeSelection, $getRoot, $isParagraphNode, $isTextNode, $setSelection } from "lexical";
import { useLexicalComposer } from "lexical-vue";

const editor = useLexicalComposer();

const select = (characterPosition: number) =>
  editor.update(() => {
    const root = $getRoot();
    const paragraph = root.getFirstChild();
    if (!$isParagraphNode(paragraph)) {
      return;
    }
    const textNode = paragraph.getFirstChild();
    if (!$isTextNode(textNode)) {
      return;
    }

    const rangeSelection = $createRangeSelection();
    rangeSelection.setTextNodeRange(textNode, characterPosition, textNode, characterPosition);
    $setSelection(rangeSelection);
  });

defineExpose({
  select,
});
</script>

<template>
  <slot />
</template>
