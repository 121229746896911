<script setup lang="ts">
import { $getNodeByKey, type LexicalNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed } from "vue";

import AttachmentImage from "~/components/dumb/AttachmentImage.vue";
import { AttachmentFieldIcon, AudioIcon, ImageIcon, VideoIcon } from "~/icons";
import { EditorMode } from "~/shared/enums";
import { useDataStore } from "~/stores";
import { downloadFile } from "~/utils/api";

import PageLink from "../plugins/PageLink.vue";
import type { AttachmentNode } from "./AttachmentNode";

// TODO inline this type when possible
type IsAttachmentNode = (node?: LexicalNode) => node is AttachmentNode;

const props = defineProps<{
  attachmentDuid: string;
  collapsed: boolean;
  nodeKey: string;
  isAttachmentNode: IsAttachmentNode;
}>();

const dataStore = useDataStore();
const editor = useLexicalComposer();

const notEditable = editor._config.namespace.startsWith("comment-false");
const attachment = computed(() => dataStore.getAttachmentByDuid(props.attachmentDuid));
const isImage = computed(
  () => attachment.value?.kind.startsWith("image") && attachment.value?.kind !== "image/svg+xml"
);
const isVideo = computed(() => attachment.value?.kind.startsWith("video"));
const isAudio = computed(() => attachment.value?.kind.startsWith("audio"));
const isMedia = computed(() => isImage.value || isVideo.value || isAudio.value);

const collapseNode = (collapse: boolean) => {
  editor.update(() => {
    const node = $getNodeByKey(props.nodeKey);
    if (node === null || !props.isAttachmentNode(node)) {
      return;
    }

    node.setCollapsed(collapse);
  });
};

const removeAttachment = () => {
  editor.update(() => {
    const node = $getNodeByKey(props.nodeKey);
    if (!node) {
      return;
    }

    node.remove();
  });
};
</script>

<template>
  <div
    v-if="attachment"
    class="!inline-flex"
    :class="[!isMedia || collapsed ? '-mt-0.5 align-middle' : 'px-1', isAudio && 'w-full']">
    <AttachmentImage
      v-if="isMedia && !collapsed"
      :class="[isAudio && 'w-full']"
      :attachment="attachment"
      :editor-mode="EditorMode.DOC"
      :not-editable="notEditable"
      @remove="removeAttachment"
      @collapse="collapseNode(true)" />
    <PageLink
      v-else
      :show-expand-icon="isMedia && collapsed"
      :icon="isImage ? ImageIcon : isVideo ? VideoIcon : isAudio ? AudioIcon : AttachmentFieldIcon"
      :title="attachment.name"
      class="!inline-flex align-middle"
      @click="downloadFile(attachment.fileUrl, attachment.name)"
      @expand="collapseNode(false)" />
  </div>
</template>
