<script setup lang="ts">
import { ref } from "vue";

import PropertiesContent from "./PropertiesContent.vue";
import PropertiesSidebar from "./PropertiesSidebar.vue";

const content = ref<InstanceType<typeof PropertiesContent> | null>(null);

const focusNewProperty = () => {
  content.value?.focusTitle();
};
</script>

<template>
  <div class="!m-0 flex size-full overflow-hidden !p-0">
    <PropertiesSidebar @create-property="focusNewProperty" />
    <PropertiesContent ref="content" />
  </div>
</template>
