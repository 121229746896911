<script lang="ts" setup>
import { computed } from "vue";

import { getShownPropertyWithConfigList } from "~/common/properties";
import DartboardEditor from "~/components/dumb/DartboardEditor.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import { ReminderIcon, TaskIcon } from "~/icons";
import { CommandId, DropdownMenuItemKind, EditorMode, Placement } from "~/shared/enums";
import type { Property, PropertyConfig, PropertyConfigContextMenu, Task } from "~/shared/types";
import { useAppStore } from "~/stores";

const props = defineProps<{
  targetTask: Task;
  selectedTasks: Task[];
}>();

const appStore = useAppStore();

const sections = computed(() => [
  {
    title: "Change task",
    items: [
      {
        title: "Dartboard",
        component: DartboardEditor,
        kind: DropdownMenuItemKind.COMPONENT,
        componentArgs: {
          tasks: props.selectedTasks,
          editorMode: EditorMode.CONTEXT_MENU,
        },
      },
      ...getShownPropertyWithConfigList()
        .filter(
          (e): e is [Property, PropertyConfig & { contextMenu: PropertyConfigContextMenu<Property["kind"]> }] =>
            !!e[1].contextMenu
        )
        .map(([property, propertyConfig]) => {
          const contextMenu = propertyConfig.contextMenu();
          return {
            title: property.title.toLowerCase(),
            component: contextMenu.component,
            kind: DropdownMenuItemKind.COMPONENT,
            componentArgs: contextMenu.getValues(property, props.targetTask, props.selectedTasks),
          };
        }),
      {
        title: "Set reminder",
        kind: DropdownMenuItemKind.BUTTON,
        icon: ReminderIcon,
        commandId: CommandId.SET_REMINDER,
        onClick: () => appStore.setTasksOpenInReminderModal(props.selectedTasks),
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu :sections="sections" :placement="Placement.RIGHT_TOP" show-on-hover>
    <DropdownMenuItemContent :icon="TaskIcon" title="Change task" is-submenu />
  </DropdownMenu>
</template>
