<script setup lang="ts">
import moment from "moment";
import { computed } from "vue";

import { ChartAggregation } from "~/shared/enums";
import type { StatisticConfigTable, Task } from "~/shared/types";
import { getDurationText } from "~/utils/time";

import { getSeries, TIME_TRACKING_KINDS } from "../common";
import StatisticNoData from "../StatisticNoData.vue";

const props = defineProps<{
  tasks: Task[];
  width: number;
  height: number;
  config: StatisticConfigTable;
}>();

const hasRowProperty = computed(() => props.config.adtl.rowPropertyDuid !== null);
const args = computed(() => ({
  tasks: props.tasks,
  primaryPropertyDuid: props.config.adtl.columnPropertyDuid,
  secondaryPropertyDuid: props.config.adtl.rowPropertyDuid,
  aggregationPropertyDuid: props.config.adtl.aggregationPropertyDuid,
  aggregation: props.config.adtl.aggregation,
}));
const {
  series,
  totals,
  hasData,
  primaryPropertyOptions: columns,
  secondaryPropertyOptions: rows,
  aggregationProperty,
} = getSeries(args);
const isAggregationTimeTracking = computed(
  () => aggregationProperty.value && TIME_TRACKING_KINDS.has(aggregationProperty.value.kind)
);

const textAlign = computed(() => (hasRowProperty.value ? "right" : "center"));

const normalizeValue = (value: number) => {
  if (isAggregationTimeTracking.value && args.value.aggregation !== ChartAggregation.COUNT) {
    return getDurationText(moment.duration(value, "seconds"));
  }

  return value;
};
</script>

<template>
  <StatisticNoData :has-data="hasData" class="overflow-hidden">
    <div class="relative size-full select-none overflow-auto">
      <component :is="'table'" class="size-full border-separate border-spacing-0">
        <!-- Header -->
        <thead>
          <tr class="text-sm font-semibold text-lt">
            <th v-if="hasRowProperty" class="border-b border-r px-4 py-2 border-hvy" />
            <th
              v-for="(col, colIndex) in columns"
              :key="col.id"
              class="hyphens-auto break-words border-b px-4 py-2 text-center border-hvy border-x-lt"
              :class="{ 'border-l': colIndex !== 0 }">
              {{ col.title }}
            </th>
          </tr>
        </thead>

        <!-- Body -->
        <tbody class="text-md">
          <!-- Rows -->
          <tr v-for="(row, rowIndex) in rows" :key="row.id">
            <th
              v-if="hasRowProperty"
              class="hyphens-auto break-words border-r px-4 py-2 text-sm font-semibold text-lt border-hvy border-y-lt"
              :class="{ 'border-t': rowIndex !== 0 }">
              {{ row.title }}
            </th>
            <td
              v-for="(col, colIndex) in columns"
              :key="col.id"
              class="px-4 py-2 border-lt"
              :class="{
                'border-t': rowIndex !== 0,
                'border-l': colIndex !== 0,
                'text-center': textAlign === 'center',
                'text-right': textAlign === 'right',
              }">
              {{ normalizeValue(series[rowIndex].data[colIndex]) }}
            </td>
          </tr>

          <!-- Totals -->
          <tr>
            <th
              v-if="hasRowProperty"
              class="hyphens-auto break-words border-r border-t px-4 py-2 text-sm font-semibold text-lt border-hvy">
              Total
            </th>
            <td
              v-for="(col, colIndex) in columns"
              :key="col.id"
              class="px-4 py-2 border-hvy border-x-lt"
              :class="{
                'border-t': hasRowProperty,
                'border-l': colIndex !== 0,
                'text-center': textAlign === 'center',
                'text-right': textAlign === 'right',
              }">
              {{ normalizeValue(totals[colIndex]) }}
            </td>
          </tr>
        </tbody>
      </component>
    </div>
  </StatisticNoData>
</template>

<style scoped>
thead tr {
  @apply sticky top-0 bg-std;
  z-index: v-bind("hasData ? '1' : 'auto'");
}

tbody th {
  @apply sticky left-0 bg-std;
}
</style>
