import type { SerializedEditorState } from "lexical";
import moment from "moment";

import { FORM_USER_PSEUDO_USER_KEY } from "~/constants/user";
import { Priority, PropertyKind } from "~/shared/enums";
import type { DateRange, Form, FormField, Property, PropertyValue, TaskCreate, TimeTracking } from "~/shared/types";
import { useDataStore } from "~/stores";
import { isLexicalStateEmpty, makeDuid } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

export const isFieldInvalid = (field: FormField, value?: PropertyValue): boolean => {
  if (!field.required || field.hidden) {
    return false;
  }

  const dataStore = useDataStore();
  const property = dataStore.getPropertyByDuid(field.propertyDuid);
  if (!property || property.kind === PropertyKind.CHECKBOX) {
    return false;
  }

  // TODO these checks are brittle, particularly for date ranges
  const isEmptyValue = Array.isArray(value) ? value.length === 0 : !value;
  const isEmptyDateRange =
    (property.kind === PropertyKind.DEFAULT_DATES || property.kind === PropertyKind.DATES) &&
    Array.isArray(value) &&
    value.length === 2 &&
    value.every((v) => v === null);
  const isEmptyDescription =
    property.kind === PropertyKind.DEFAULT_DESCRIPTION && isLexicalStateEmpty(value as SerializedEditorState);
  return isEmptyValue || isEmptyDateRange || isEmptyDescription;
};

export const convertFormValuesToTask = (
  form: Form,
  fieldDuidToValueMap: Map<string, PropertyValue>,
  isExternalForm?: boolean
): TaskCreate => {
  const dataStore = useDataStore();

  // We also want hidden fields to be populated
  const fields = dataStore.getFormFieldRelatedToFormOrdered(form.duid);
  const fieldsWithProperties: [FormField, Property][] = fields
    .map((field) => [field, dataStore.getPropertyByDuid(field.propertyDuid)])
    .filter((e): e is [FormField, Property] => !!e[1]);

  const dartboardFieldDuid = fieldsWithProperties.find(
    (fieldAndProperty) => fieldAndProperty[1].kind === PropertyKind.DEFAULT_DARTBOARD
  )?.[0]?.duid;
  if (!dartboardFieldDuid) {
    throw new Error("Missing dartboard field");
  }
  const dartboardDuid = fieldDuidToValueMap.get(dartboardFieldDuid)?.toString();
  if (!dartboardDuid) {
    throw new Error("Missing dartboard value");
  }

  const topTaskOrder = dataStore.getTasksByDartboardDuidOrdered(dartboardDuid, {
    includeTrashed: true,
    includeDraft: true,
  })?.[0]?.order;
  const order = getOrdersBetween(undefined, topTaskOrder)[0];

  const task: TaskCreate & { properties: Record<string, PropertyValue> } = {
    duid: makeDuid(),
    order,
    sourceFormDuid: form.duid,
    assigneeDuids: [],
    title: "",
    statusDuid: "",
    dartboardDuid: "",
    properties: {},
  };

  fields.forEach((field) => {
    const property = dataStore.getPropertyByDuid(field.propertyDuid);
    if (!property) {
      return;
    }

    const value = fieldDuidToValueMap.get(field.duid) ?? field.default.value;

    switch (property.kind) {
      case PropertyKind.DEFAULT_TITLE: {
        task.title = value?.toString() ?? "";
        break;
      }
      case PropertyKind.DEFAULT_DESCRIPTION: {
        task.description = value as SerializedEditorState;
        break;
      }
      case PropertyKind.DEFAULT_DARTBOARD: {
        task.dartboardDuid = value?.toString() ?? "";
        break;
      }
      case PropertyKind.DEFAULT_KIND: {
        task.kindDuid = value?.toString() ?? "";
        break;
      }
      case PropertyKind.DEFAULT_STATUS: {
        task.statusDuid = value?.toString() ?? "";
        break;
      }
      case PropertyKind.DEFAULT_ASSIGNEES: {
        task.assigneeDuids = value as string[];
        break;
      }
      case PropertyKind.DEFAULT_DATES: {
        task.startAt = (value as DateRange)?.at(0);
        task.dueAt = (value as DateRange)?.at(1);
        break;
      }
      case PropertyKind.DEFAULT_PRIORITY: {
        task.priority = value as Priority;
        break;
      }
      case PropertyKind.DEFAULT_TAGS: {
        task.tagDuids = value as string[];
        break;
      }
      case PropertyKind.DEFAULT_SIZE: {
        task.size = value as number | null;
        break;
      }
      case PropertyKind.DEFAULT_ATTACHMENTS: {
        task.attachmentDuids = value as string[];
        break;
      }
      case PropertyKind.DEFAULT_TIME_TRACKING: {
        const timeTracking = value as TimeTracking;
        task.timeTracking = timeTracking.map((entry) => ({
          ...entry,
          userDuid: isExternalForm ? FORM_USER_PSEUDO_USER_KEY : entry.userDuid,
          finishedAt: entry.finishedAt === null && isExternalForm ? moment().toISOString() : entry.finishedAt,
        }));
        break;
      }
      default: {
        task.properties[property.duid] = value;
        break;
      }
    }
  });

  return task;
};
