import type { Actions } from "..";
import commands from "./commands";
import drag from "./drag";
import getters from "./getters";
import selection from "./selection";
import taskEdit from "./taskEdit";

export default (actions: Actions) => ({
  ...commands(actions),
  ...drag(actions),
  ...getters(actions),
  ...selection(actions),
  ...taskEdit(actions),
});
