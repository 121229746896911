<template>
  <svg width="289" height="289" viewBox="0 0 289 289" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="M132.553 193.424L143.861 212.171C143.933 212.29 143.957 212.432 143.927 212.568C143.897 212.704 143.815 212.823 143.699 212.9L142.588 213.646C142.276 213.855 141.899 213.945 141.527 213.9C141.154 213.855 140.81 213.678 140.557 213.401L137.539 210.103L138.382 214.135C138.516 214.779 138.469 215.446 138.246 216.064C138.022 216.682 137.632 217.226 137.118 217.635L125.203 227.093C125.032 227.229 124.82 227.304 124.602 227.306C124.383 227.309 124.17 227.239 123.996 227.107C123.822 226.975 123.696 226.789 123.64 226.578C123.583 226.367 123.598 226.143 123.682 225.941C125.79 220.953 127.449 211.909 120.854 199.637"
      fill="#FFA97F" />
    <path
      d="M134.232 196.202L132.403 194.607L123.187 198.394L120.857 199.63C123.265 204.108 124.569 208.158 125.157 211.726C127.3 210.52 134.949 206.211 138.852 203.852L134.232 196.202Z"
      fill="#F79F84" />
    <path
      d="M124.491 215.345C124.779 215.25 128.432 214.406 128.432 214.406"
      stroke="#4F8AE0"
      stroke-width="1.44008"
      stroke-miterlimit="10" />
    <path
      d="M124.491 218.95C124.779 218.855 128.432 218.011 128.432 218.011"
      stroke="#4F8AE0"
      stroke-width="1.44008"
      stroke-miterlimit="10" />
    <path
      d="M199.068 233.31L212.743 250.41C212.829 250.519 212.87 250.656 212.858 250.795C212.846 250.933 212.781 251.061 212.676 251.153L211.674 252.04C211.392 252.288 211.031 252.427 210.655 252.432C210.279 252.437 209.914 252.307 209.626 252.066L206.202 249.186L207.576 253.083C207.793 253.703 207.834 254.371 207.693 255.013C207.553 255.655 207.237 256.245 206.781 256.717L196.213 267.662C196.061 267.82 195.861 267.923 195.644 267.955C195.426 267.986 195.205 267.944 195.014 267.835C194.824 267.726 194.675 267.557 194.592 267.354C194.509 267.151 194.495 266.926 194.554 266.714C195.994 261.493 196.444 252.314 188.296 241.009"
      fill="#FFA97F" />
    <path
      d="M201.098 235.848L199.082 234.506L190.442 239.468L188.279 241C191.254 245.125 193.08 248.967 194.131 252.44C196.098 250.963 203.106 245.686 206.671 242.835L201.098 235.848Z"
      fill="#F79F84" />
    <path
      d="M193.955 256.102C194.223 255.973 197.74 254.662 197.74 254.662"
      stroke="#4F8AE0"
      stroke-width="1.44008"
      stroke-miterlimit="10" />
    <path
      d="M194.431 259.675C194.696 259.546 198.212 258.235 198.212 258.235"
      stroke="#4F8AE0"
      stroke-width="1.44008"
      stroke-miterlimit="10" />
    <path
      d="M177.181 65.4663C177.181 65.4663 174.802 96.0537 164.661 106.788"
      stroke="#3EB7B2"
      stroke-width="11.5207"
      stroke-miterlimit="10" />
    <path
      d="M107.732 74.0198C107.732 74.0198 120.505 101.312 131.882 110.716"
      stroke="#3EB7B2"
      stroke-width="11.5207"
      stroke-miterlimit="10" />
    <path
      d="M157.305 146.969C157.305 146.969 158.238 192.525 196.764 242.821"
      stroke="#3E83D6"
      stroke-width="23.0413"
      stroke-miterlimit="10" />
    <path
      d="M141.208 85.2527C141.208 85.2527 136.41 87.9946 137.196 90.3506C137.982 92.7066 142.711 91.2694 142.711 91.2694"
      fill="#3A3144" />
    <path
      d="M152.282 153.697C160.002 153.697 166.26 147.439 166.26 139.72C166.26 132 160.002 125.742 152.282 125.742C144.563 125.742 138.305 132 138.305 139.72C138.305 147.439 144.563 153.697 152.282 153.697Z"
      fill="#4F8AE0" />
    <path
      d="M145.652 97.0623C146.882 99.7667 150.171 106.503 151.542 109.13"
      stroke="#3EB7B2"
      stroke-width="8.64051"
      stroke-miterlimit="10" />
    <path
      d="M143.023 101.67L143.809 103.292C143.809 103.292 147.078 102.975 148.803 99.3201C147.23 100.786 145.172 101.623 143.023 101.67Z"
      fill="#3A3144" />
    <path
      d="M148.32 84.6133L149.868 85.1677C150.634 85.4419 151.339 85.8643 151.942 86.4108C152.544 86.9573 153.033 87.6172 153.381 88.3527C153.729 89.0882 153.928 89.8851 153.968 90.6977C154.008 91.5103 153.887 92.3227 153.613 93.0887L152.245 96.9093C151.531 98.9014 150.056 100.529 148.143 101.433C146.23 102.337 144.036 102.444 142.044 101.731L141.57 101.561C140.182 101.064 139.048 100.036 138.418 98.7033C137.788 97.3705 137.713 95.8419 138.21 94.454L140.393 88.3583C140.947 86.8114 142.092 85.5479 143.578 84.8457C145.063 84.1435 146.767 84.0602 148.314 84.614L148.32 84.6133Z"
      fill="#3EB7B2" />
    <path
      d="M141.355 92.6007C141.635 92.6007 141.862 92.3738 141.862 92.0938C141.862 91.8139 141.635 91.5869 141.355 91.5869C141.075 91.5869 140.848 91.8139 140.848 92.0938C140.848 92.3738 141.075 92.6007 141.355 92.6007Z"
      fill="#3A3144" />
    <path
      d="M141.433 90.3505C141.694 90.3403 141.956 90.3665 142.21 90.4282C142.284 90.4485 142.363 90.4386 142.429 90.4008C142.496 90.363 142.544 90.3003 142.565 90.2266C142.585 90.1529 142.575 90.0742 142.537 90.0077C142.499 89.9413 142.437 89.8926 142.363 89.8724C142.059 89.7967 141.746 89.7637 141.433 89.7744C141.061 89.7744 141.061 90.3505 141.433 90.3505Z"
      fill="#3A3144" />
    <path
      d="M137.997 93.3172C137.838 94.0372 138.351 95.1835 139.238 95.2066L139.526 93.1904C139.546 93.0551 138.913 92.7699 138.8 92.7555C138.71 92.7356 138.617 92.7345 138.527 92.7525C138.436 92.7704 138.351 92.8069 138.275 92.8597C138.199 92.9125 138.136 92.9805 138.088 93.0592C138.04 93.138 138.009 93.2258 137.997 93.3172Z"
      fill="#3EB7B2" />
    <path
      d="M162.835 103.07L137.726 107.603C136.642 107.798 135.61 108.213 134.692 108.822C133.774 109.43 132.99 110.22 132.389 111.142C131.787 112.065 131.38 113.1 131.193 114.186C131.006 115.271 131.042 116.383 131.3 117.453L135.572 135.132C135.734 135.804 136.131 136.397 136.691 136.803C137.251 137.209 137.938 137.402 138.627 137.347L168.08 134.991C168.821 134.931 169.511 134.589 170.008 134.035C170.504 133.482 170.77 132.759 170.75 132.015L170.116 108.974C170.092 108.084 169.875 107.21 169.481 106.411C169.087 105.613 168.525 104.909 167.833 104.348C167.141 103.787 166.337 103.383 165.474 103.162C164.611 102.941 163.711 102.91 162.835 103.07Z"
      fill="#FFA97F" />
    <path
      d="M128.305 204.187C128.305 204.187 98.6736 161.592 100.655 150.616C102.637 139.639 153.44 150.97 154.802 150.054C154.802 150.054 160.041 153.387 155.427 137.358"
      stroke="#4F8AE0"
      stroke-width="23.0413"
      stroke-miterlimit="10" />
    <path
      d="M140.24 94.9679L141.626 94.6597C141.671 94.6491 141.718 94.6496 141.763 94.6612C141.808 94.6728 141.85 94.6951 141.884 94.7262C141.919 94.7573 141.945 94.7964 141.961 94.84C141.977 94.8835 141.983 94.9304 141.977 94.9765C141.902 95.5842 141.66 96.6902 140.868 96.7881C140.076 96.8861 139.929 95.9241 139.909 95.3941C139.906 95.2958 139.938 95.1996 139.998 95.1219C140.059 95.0442 140.144 94.9898 140.24 94.9679Z"
      fill="#3A3144" />
    <path
      d="M140.868 96.7885C141.407 96.7223 141.692 96.1924 141.842 95.6682C141.234 95.504 140.574 96.0541 140.18 96.4717C140.25 96.5875 140.352 96.6796 140.475 96.736C140.598 96.7925 140.735 96.8108 140.868 96.7885Z"
      fill="#8AD6CC" />
    <path
      d="M137.968 87.9829C137.812 88.1961 143.938 88.5907 143.938 88.5907L145.255 87.6056V88.919L147.13 89.6707V92.741C147.13 92.741 147.225 90.6557 148.63 91.2663C150.036 91.8769 149.615 93.9103 148.256 94.6534L148.38 103.006C148.382 103.141 148.414 103.273 148.473 103.394C148.533 103.515 148.618 103.621 148.723 103.706C148.828 103.79 148.95 103.85 149.081 103.882C149.212 103.914 149.348 103.917 149.48 103.89L156.038 102.536C156.213 102.501 156.372 102.409 156.49 102.275C156.608 102.141 156.679 101.972 156.692 101.793C156.908 98.8469 157.357 86.3412 149.702 84.5123C149.702 84.521 142.861 81.2722 137.968 87.9829Z"
      fill="#3A3144" />
    <path
      d="M128.97 106.788C128.97 106.788 134.345 113.449 141.51 115.607"
      stroke="#FFA97F"
      stroke-width="17.281"
      stroke-miterlimit="10" />
    <path
      d="M169.704 99.3059C168.76 102.474 166.326 105.948 162.011 114.205"
      stroke="#FFA97F"
      stroke-width="17.281"
      stroke-miterlimit="10" />
    <path
      d="M143.613 136.946C143.613 136.946 165.715 147.421 168.719 146.952C168.719 146.952 169.007 141.84 166.623 134.204C165.747 131.39 145.485 135.382 143.613 136.946Z"
      fill="#3E83D6" />
    <path
      d="M155.33 139.867C156.58 141.488 157.881 143.067 159.275 144.564C159.529 144.838 159.938 144.429 159.684 144.158C158.322 142.695 157.046 141.157 155.828 139.576C155.603 139.288 155.105 139.576 155.33 139.864V139.867Z"
      fill="#3A3144" />
    <path
      d="M182.867 66.4911L171.372 66.203C171.372 66.203 171.504 55.7624 177.541 56.2693C184.275 56.8252 182.867 66.4911 182.867 66.4911Z"
      fill="#3EB7B2" />
    <path
      d="M170.26 56.1926C170.522 57.9802 170.88 59.7522 171.335 61.5008C171.654 62.7335 172.104 66.0313 174.088 65.2738C174.756 65.0175 175.332 64.1851 175.623 63.5659C175.962 62.8238 176.049 61.9915 175.871 61.1955C175.717 60.4201 175.366 59.6974 174.852 59.0974C174.337 58.4975 173.676 58.0406 172.933 57.771C171.525 57.3015 170.917 59.525 172.322 59.9916C172.947 60.2018 173.385 60.8844 173.57 61.4864C173.754 62.0883 173.59 62.9438 173.063 63.2145L173.639 63.0561H173.486L174.301 63.3931C174.321 63.5774 174.301 63.5227 174.226 63.2289C174.172 63.059 174.117 62.8861 174.065 62.7133C173.958 62.3677 173.858 62.0192 173.777 61.6736C173.593 61.0198 173.426 60.3631 173.27 59.7036C172.953 58.3412 172.694 56.9645 172.492 55.5792C172.4 54.9628 171.628 54.6258 171.075 54.7756C170.413 54.957 170.18 55.5734 170.269 56.1926H170.26Z"
      fill="#3EB7B2" />
    <path
      d="M175.203 52.2726C175.278 54.5906 174.862 56.8984 173.981 59.0439C173.564 60.0721 175.234 60.5214 175.649 59.5018C176.579 57.2046 177.015 54.7378 176.931 52.2611C176.896 51.1522 175.165 51.1464 175.203 52.2611V52.2726Z"
      fill="#3EB7B2" />
    <path
      d="M178.091 51.8061C178.111 54.1097 177.801 56.4044 177.173 58.6206C176.867 59.692 178.535 60.15 178.837 59.0814C179.509 56.7153 179.84 54.2656 179.82 51.8061C179.82 50.6973 178.091 50.6944 178.091 51.8061Z"
      fill="#3EB7B2" />
    <path
      d="M180.989 52.5255C180.953 55.0673 180.506 57.5865 179.664 59.9851C179.298 61.0364 180.966 61.4885 181.332 60.4459C182.215 57.8972 182.683 55.2228 182.717 52.5255C182.717 51.4137 180.989 51.4137 180.989 52.5255Z"
      fill="#3EB7B2" />
    <path
      d="M182.985 56.7389C182.697 59.2245 181.833 61.6553 181.018 64.0113C180.655 65.0655 182.325 65.5176 182.685 64.4693C183.549 61.9549 184.413 59.3915 184.701 56.7389C184.828 55.6358 183.1 55.6444 182.973 56.7389H182.985Z"
      fill="#3EB7B2" />
    <path
      d="M173.999 58.986C174.538 59.6323 174.989 60.3482 175.338 61.1144C175.491 61.4514 175.989 61.1576 175.833 60.8264C175.459 60.016 174.974 59.261 174.393 58.5828C174.154 58.2947 173.748 58.7066 173.987 58.9889L173.999 58.986Z"
      fill="#3A3144" />
    <path
      d="M80.0907 97.5086L137.271 37.362C137.271 37.362 137.798 34.2255 118.095 28.5948C115.982 28.0047 114.01 26.9935 112.298 25.622C110.586 24.2504 109.169 22.5469 108.132 20.6138C108.055 20.471 107.947 20.348 107.814 20.2546C107.682 20.1612 107.53 20.0999 107.37 20.0755C107.209 20.0512 107.046 20.0646 106.892 20.1145C106.738 20.1644 106.597 20.2496 106.482 20.3633L94.143 32.6673C93.9833 32.8267 93.8795 33.0335 93.8473 33.2568C93.8151 33.4801 93.8562 33.7079 93.9645 33.9058C95.2058 36.21 99.8775 43.3297 110.618 44.9541C123.452 46.8925 124.48 50.8239 124.48 50.8239"
      fill="#F99192" />
    <path
      d="M102.884 77.2429L113.541 72.9428C113.541 72.9428 109.762 63.2107 104.284 65.8C98.1812 68.683 102.884 77.2429 102.884 77.2429Z"
      fill="#3EB7B2" />
    <path
      d="M103.44 61.7738C104.251 64.1917 105.563 66.4109 107.291 68.2859C108.046 69.1039 109.267 67.8798 108.512 67.0618C106.983 65.4036 105.823 63.4404 105.108 61.3015C104.754 60.2531 103.092 60.7024 103.44 61.7594V61.7738Z"
      fill="#3EB7B2" />
    <path
      d="M100.572 62.3496C101.409 64.6605 102.573 66.8398 104.028 68.8213C104.679 69.7055 106.179 68.8444 105.52 67.9573C104.148 66.1072 103.047 64.0705 102.251 61.9089C101.874 60.8721 100.203 61.3329 100.583 62.3697L100.572 62.3496Z"
      fill="#3EB7B2" />
    <path
      d="M98.112 64.0379C99.1111 66.6168 100.524 69.0157 102.294 71.1404C103.005 72.0044 104.221 70.7689 103.515 69.9192C101.935 68.0218 100.671 65.8823 99.771 63.5828C99.3735 62.5546 97.703 63.0068 98.1063 64.0408L98.112 64.0379Z"
      fill="#3EB7B2" />
    <path
      d="M97.798 68.8764C99.1056 71.4225 101.067 73.6604 102.879 75.858C103.587 76.722 104.803 75.4893 104.1 74.6368C102.372 72.5486 100.534 70.426 99.29 68.0124C98.783 67.0216 97.2911 67.8943 97.798 68.8764Z"
      fill="#3EB7B2" />
    <path
      d="M94.1345 32.6675C93.9748 32.8268 93.871 33.0336 93.8388 33.257C93.8066 33.4803 93.8477 33.708 93.956 33.906C95.1973 36.2101 99.8689 43.3299 110.609 44.9543C123.443 46.8926 124.471 50.8241 124.471 50.8241"
      stroke="#3A3144"
      stroke-width="0.576034"
      stroke-miterlimit="10" />
    <path d="M80.0907 97.5079L137.271 37.3613" stroke="#3A3144" stroke-width="0.576034" stroke-miterlimit="10" />
    <path
      d="M108.826 63.5917C109.114 64.905 109.348 66.2299 109.517 67.5634C109.598 68.2143 109.666 68.8653 109.722 69.5162C109.748 69.8416 109.774 70.1671 109.794 70.4926C109.794 70.6798 109.817 70.867 109.826 71.0542C109.808 71.3624 109.826 71.4229 109.869 71.2385L110.284 70.8267L110.145 70.8929L110.721 70.7345L110.589 70.7172L111.165 70.8756C110.436 70.5415 109.889 70.1671 109.826 69.3203C109.787 68.9519 109.838 68.5796 109.975 68.2353C110.112 67.8911 110.33 67.5854 110.612 67.3445C111.096 66.9557 111.018 66.1233 110.612 65.7172C110.134 65.2362 109.46 65.3227 108.982 65.7172C107.807 66.6648 107.366 68.3843 107.579 69.833C107.675 70.5699 107.988 71.2617 108.478 71.8203C108.97 72.3618 109.892 73.0127 110.649 73.03C112.766 73.0789 112.064 69.9252 111.937 68.6781C111.74 66.7631 111.439 64.8603 111.035 62.9782C110.724 61.5381 108.504 62.1429 108.815 63.5917H108.826Z"
      fill="#3EB7B2" />
    <path
      d="M108.023 66.9118C107.726 67.7535 107.544 68.6311 107.481 69.5212C107.455 69.8899 108.031 69.887 108.057 69.5212C108.121 68.6828 108.296 67.8565 108.579 67.0645C108.702 66.716 108.147 66.5633 108.023 66.9118Z"
      fill="#3A3144" />
    <path
      d="M142.599 106.721C144.444 108.173 146.729 108.952 149.077 108.93C152.979 108.93 156.329 106.894 157.806 103.977L142.599 106.721Z"
      fill="#3EB7B2" />
    <path
      d="M132.115 114.509C131.404 116.487 131.261 118.624 131.703 120.679C131.778 121.039 132.334 120.886 132.259 120.523C131.85 118.568 132 116.536 132.691 114.662C132.818 114.314 132.262 114.164 132.135 114.509H132.115Z"
      fill="#3A3144" />
    <path
      d="M167.472 121.125L169.917 117.752C170.133 117.464 169.629 117.176 169.419 117.464L166.965 120.834C166.746 121.122 167.253 121.41 167.461 121.122L167.472 121.125Z"
      fill="#3A3144" />
    <path
      d="M153.904 64.6085C153.904 63.9576 152.677 63.3787 150.773 63.01C149.967 62.8527 149.226 62.4584 148.645 61.8776C148.064 61.2967 147.67 60.5558 147.513 59.7496C147.144 57.8458 146.565 56.6189 145.911 56.6189C145.258 56.6189 144.681 57.8458 144.313 59.7496C144.155 60.557 143.759 61.2987 143.177 61.8797C142.594 62.4606 141.852 62.8542 141.044 63.01C139.14 63.3787 137.913 63.9576 137.913 64.6085C137.913 65.2594 139.14 65.8412 141.044 66.2099C141.85 66.3672 142.591 66.7615 143.172 67.3423C143.753 67.9231 144.147 68.664 144.304 69.4702C144.673 71.374 145.252 72.601 145.903 72.601C146.554 72.601 147.135 71.374 147.504 69.4702C147.661 68.664 148.056 67.9231 148.636 67.3423C149.217 66.7615 149.958 66.3672 150.764 66.2099C152.677 65.8412 153.904 65.2623 153.904 64.6085Z"
      fill="#FFD8C5" />
    <path
      d="M194.183 139.069C194.413 138.752 194.019 138.038 193.224 137.191C192.886 136.831 192.664 136.379 192.585 135.892C192.507 135.405 192.576 134.905 192.783 134.458C193.273 133.404 193.423 132.603 193.106 132.373C192.789 132.142 192.075 132.537 191.228 133.335C190.868 133.672 190.415 133.893 189.928 133.971C189.441 134.049 188.942 133.98 188.495 133.772C187.438 133.283 186.64 133.133 186.409 133.453C186.179 133.772 186.573 134.481 187.368 135.33C187.706 135.69 187.928 136.142 188.006 136.628C188.085 137.115 188.016 137.614 187.809 138.061C187.319 139.118 187.17 139.919 187.486 140.146C187.803 140.374 188.518 139.982 189.364 139.187C189.724 138.85 190.177 138.628 190.664 138.55C191.151 138.471 191.65 138.54 192.098 138.746C193.152 139.236 193.952 139.4 194.183 139.069Z"
      fill="#FFD8C5" />
    <path
      d="M83.9792 100.316C84.2067 99.9994 83.815 99.2852 83.0172 98.4384C82.6801 98.0783 82.4583 97.6257 82.3803 97.1387C82.3023 96.6517 82.3716 96.1524 82.5794 95.7051C83.069 94.651 83.2188 93.8503 82.8991 93.6199C82.5794 93.3894 81.8709 93.784 81.0212 94.5818C80.6618 94.9191 80.2097 95.141 79.7231 95.219C79.2365 95.297 78.7376 95.2276 78.2908 95.0196C77.2338 94.53 76.4331 94.3831 76.2056 94.6999C75.9781 95.0167 76.3698 95.7281 77.1647 96.5778C77.5023 96.9375 77.7246 97.39 77.8031 97.877C77.8817 98.3641 77.8128 98.8635 77.6053 99.3111C77.1157 100.365 76.966 101.166 77.2828 101.393C77.5996 101.621 78.3139 101.232 79.1606 100.434C79.5208 100.097 79.9732 99.8756 80.4601 99.7971C80.9469 99.7186 81.4462 99.787 81.8939 99.9937C82.9481 100.483 83.7488 100.633 83.9792 100.316Z"
      fill="#FFD8C5" />
    <path
      d="M101.934 119.855C102.395 119.395 101.934 118.127 100.851 116.512C100.393 115.831 100.148 115.028 100.148 114.207C100.148 113.387 100.393 112.584 100.851 111.903C101.937 110.296 102.395 109.023 101.934 108.559C101.473 108.096 100.206 108.559 98.5902 109.64C97.9094 110.099 97.1071 110.344 96.286 110.344C95.465 110.344 94.6627 110.099 93.9819 109.64C92.3776 108.554 91.1017 108.099 90.638 108.559C90.1743 109.02 90.638 110.288 91.721 111.903C92.1792 112.584 92.424 113.387 92.424 114.207C92.424 115.028 92.1792 115.831 91.721 116.512C90.6351 118.119 90.1772 119.392 90.638 119.855C91.0988 120.319 92.3661 119.855 93.9819 118.775C94.6627 118.316 95.465 118.071 96.286 118.071C97.1071 118.071 97.9094 118.316 98.5902 118.775C100.194 119.858 101.473 120.316 101.934 119.855Z"
      fill="#FFD8C5" />
    <path
      d="M204.408 108.655C204.65 108.413 204.408 107.742 203.832 106.901C203.591 106.543 203.462 106.122 203.462 105.691C203.462 105.26 203.591 104.839 203.832 104.482C204.408 103.638 204.641 102.969 204.408 102.727C204.174 102.486 203.497 102.727 202.654 103.304C202.296 103.544 201.875 103.673 201.444 103.673C201.013 103.673 200.592 103.544 200.234 103.304C199.393 102.727 198.722 102.494 198.48 102.727C198.238 102.961 198.48 103.638 199.056 104.482C199.298 104.838 199.428 105.26 199.428 105.691C199.428 106.123 199.298 106.544 199.056 106.901C198.48 107.742 198.247 108.413 198.48 108.655C198.713 108.897 199.393 108.655 200.234 108.079C200.592 107.838 201.013 107.71 201.444 107.71C201.875 107.71 202.296 107.838 202.654 108.079C203.489 108.655 204.157 108.897 204.408 108.655Z"
      fill="#FFD8C5" />
    <path
      d="M129.394 78.1711C129.65 78.1711 129.857 77.9635 129.857 77.7074C129.857 77.4513 129.65 77.2437 129.394 77.2437C129.137 77.2437 128.93 77.4513 128.93 77.7074C128.93 77.9635 129.137 78.1711 129.394 78.1711Z"
      fill="#FFD8C5" />
    <path
      d="M115.681 119.293C116.147 119.293 116.525 118.915 116.525 118.449C116.525 117.983 116.147 117.605 115.681 117.605C115.215 117.605 114.837 117.983 114.837 118.449C114.837 118.915 115.215 119.293 115.681 119.293Z"
      fill="#FFD8C5" />
    <path
      d="M102.801 100.683C103.181 100.683 103.489 100.374 103.489 99.9943C103.489 99.6141 103.181 99.3059 102.801 99.3059C102.421 99.3059 102.113 99.6141 102.113 99.9943C102.113 100.374 102.421 100.683 102.801 100.683Z"
      fill="#FFD8C5" />
    <path
      d="M79.5235 119.293C80.5415 119.293 81.3668 118.468 81.3668 117.45C81.3668 116.432 80.5415 115.607 79.5235 115.607C78.5055 115.607 77.6802 116.432 77.6802 117.45C77.6802 118.468 78.5055 119.293 79.5235 119.293Z"
      fill="#FFD8C5" />
    <path
      d="M94.1488 128.093C94.3827 128.093 94.5722 127.903 94.5722 127.669C94.5722 127.435 94.3827 127.246 94.1488 127.246C93.915 127.246 93.7255 127.435 93.7255 127.669C93.7255 127.903 93.915 128.093 94.1488 128.093Z"
      fill="#FFD8C5" />
    <path
      d="M195.516 85.1125C195.936 85.1125 196.277 84.7721 196.277 84.3522C196.277 83.9322 195.936 83.5918 195.516 83.5918C195.097 83.5918 194.756 83.9322 194.756 84.3522C194.756 84.7721 195.097 85.1125 195.516 85.1125Z"
      fill="#FFD8C5" />
    <path
      d="M191.15 95.495C191.623 95.495 192.005 95.112 192.005 94.6396C192.005 94.1672 191.623 93.7842 191.15 93.7842C190.678 93.7842 190.295 94.1672 190.295 94.6396C190.295 95.112 190.678 95.495 191.15 95.495Z"
      fill="#FFD8C5" />
    <path
      d="M209.053 96.5664C209.944 96.5664 210.666 95.8442 210.666 94.9535C210.666 94.0627 209.944 93.3406 209.053 93.3406C208.162 93.3406 207.44 94.0627 207.44 94.9535C207.44 95.8442 208.162 96.5664 209.053 96.5664Z"
      fill="#FFD8C5" />
    <path
      d="M187.386 111.197C187.96 111.197 188.426 110.732 188.426 110.158C188.426 109.583 187.96 109.118 187.386 109.118C186.812 109.118 186.346 109.583 186.346 110.158C186.346 110.732 186.812 111.197 187.386 111.197Z"
      fill="#FFD8C5" />
    <path
      d="M197.328 121.396C197.909 121.396 198.379 120.925 198.379 120.345C198.379 119.764 197.909 119.293 197.328 119.293C196.748 119.293 196.277 119.764 196.277 120.345C196.277 120.925 196.748 121.396 197.328 121.396Z"
      fill="#FFD8C5" />
    <path
      d="M156.196 75.2474C156.537 75.2474 156.813 74.9714 156.813 74.631C156.813 74.2906 156.537 74.0146 156.196 74.0146C155.856 74.0146 155.58 74.2906 155.58 74.631C155.58 74.9714 155.856 75.2474 156.196 75.2474Z"
      fill="#FFD8C5" />
    <path
      d="M160.027 83.5909C160.28 83.5909 160.485 83.3859 160.485 83.133C160.485 82.8801 160.28 82.675 160.027 82.675C159.774 82.675 159.569 82.8801 159.569 83.133C159.569 83.3859 159.774 83.5909 160.027 83.5909Z"
      fill="#FFD8C5" />
    <path
      d="M127.513 66.1173C128.295 66.1173 128.93 65.4829 128.93 64.7002C128.93 63.9176 128.295 63.2832 127.513 63.2832C126.73 63.2832 126.096 63.9176 126.096 64.7002C126.096 65.4829 126.73 66.1173 127.513 66.1173Z"
      fill="#FFD8C5" />
    <path
      d="M161.556 58.6805C162.703 58.6805 163.633 57.7508 163.633 56.6039C163.633 55.4571 162.703 54.5273 161.556 54.5273C160.409 54.5273 159.48 55.4571 159.48 56.6039C159.48 57.7508 160.409 58.6805 161.556 58.6805Z"
      fill="#FFD8C5" />
  </svg>
</template>
