<script setup lang="ts">
import { type Component, computed, onUnmounted, ref } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Input from "~/components/dumb/Input.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { THROTTLE_MS } from "~/constants/app";
import { colorsByTheme } from "~/constants/style";
import { DotsHorizontalIcon, TrashIcon, VerticalDragHandleIcon } from "~/icons";
import { DropdownMenuItemKind, StatisticType } from "~/shared/enums";
import type { StatisticConfig, Task } from "~/shared/types";
import { usePageStore } from "~/stores";
import { ThrottleManager } from "~/utils/throttleManager";

import { getStatisticDefinition } from "./constants";
import Bar from "./elements/Bar.vue";
import BurnUp from "./elements/BurnUp";
import Line from "./elements/Line.vue";
import Number from "./elements/Number.vue";
import Pie from "./elements/Pie.vue";
import Table from "./elements/Table.vue";
import TextBlock from "./elements/TextBlock.vue";

const props = defineProps<{
  tasks: Task[];
  config: StatisticConfig;
}>();

const emit = defineEmits<{
  update: [config: StatisticConfig];
  delete: [config: StatisticConfig];
}>();

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const definition = computed(() => getStatisticDefinition(props.config.type));

const title = ref(props.config.title);
const titleInput = ref<InstanceType<typeof Input> | null>(null);
const saveTitleManager = new ThrottleManager(() => {
  emit("update", { ...props.config, title: title.value.trim() });
}, THROTTLE_MS);

const updateTitle = (newValue: string) => {
  if (newValue === title.value || newValue === "") {
    return;
  }
  title.value = newValue;
  saveTitleManager.run();
};

const onBlur = () => {
  if (title.value.trim() === "") {
    title.value = props.config.title;
    if (titleInput.value) {
      titleInput.value.value = props.config.title;
    }
    saveTitleManager.execute();
  }
};

const isTextBlock = computed(() => props.config.type === StatisticType.TEXT_BLOCK);
const typeToComponent: Record<StatisticType, Component> = {
  [StatisticType.BAR]: Bar,
  [StatisticType.BURN_UP]: BurnUp,
  [StatisticType.LINE]: Line,
  [StatisticType.NUMBER]: Number,
  [StatisticType.PIE]: Pie,
  [StatisticType.TABLE]: Table,
  [StatisticType.TEXT_BLOCK]: TextBlock,
};
const dropdownItems = computed(() => [
  {
    title: "Edit configuration",
    items:
      definition.value?.configOptions(props.config, (e) =>
        emit("update", { ...e, title: definition.value?.getDefaultTitle(e) ?? "" })
      ) ?? [],
  },
  {
    title: "Actions",
    items: [
      {
        title: "Remove",
        kind: DropdownMenuItemKind.BUTTON,
        icon: TrashIcon,
        onClick: () => emit("delete", props.config),
      },
    ],
  },
]);

onUnmounted(() => {
  saveTitleManager.destroy();
});
</script>

<template>
  <div
    class="group/statistic flex size-full flex-1 flex-col gap-2 rounded-lg border p-2 bg-std border-md"
    :style="{ '--background': colors.bgStd, '--highlight': colors.highlight }">
    <div class="relative flex gap-2" :class="isTextBlock && 'h-full'">
      <VerticalDragHandleIcon
        class="dart-drag-handle cursor-move opacity-0 text-md icon-sm group-hover/statistic:opacity-100" />
      <Input
        ref="titleInput"
        :init-value="config.title"
        placeholder="Chart title"
        label="Title"
        hide-label
        class="w-full"
        required
        show-error-always
        :scale-with-height="isTextBlock"
        :input-classes="`!py-0 [&:not(:hover)]:border-transparent !text-lg font-medium text-center truncate${isTextBlock ? ' h-full' : ''}`"
        @change="updateTitle"
        @blur="onBlur" />
      <DropdownMenu :sections="dropdownItems" class="h-fit opacity-0 group-hover/statistic:opacity-100" :distance="2">
        <Tooltip text="Edit configuration" class="h-fit">
          <button
            type="button"
            class="flex size-5 items-center rounded p-0.5 text-base text-lt focus-ring-std hover:bg-md">
            <span class="sr-only">Edit configuration</span>
            <DotsHorizontalIcon class="icon-sm" />
          </button>
        </Tooltip>
      </DropdownMenu>
    </div>

    <component
      :is="typeToComponent[config.type]"
      :tasks="tasks"
      :width="config.width"
      :height="config.height"
      :config="config"
      @update="(e: StatisticConfig) => emit('update', e)" />
  </div>
</template>

<style scoped>
:deep(.apexcharts-canvas ::-webkit-scrollbar) {
  background: transparent;
  width: 6px;
}
:deep(.apexcharts-canvas ::-webkit-scrollbar-thumb) {
  border: 4px solid var(--highlight);
  background: var(--background) !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}
:deep(.apexcharts-canvas ::-webkit-scrollbar-corner) {
  background: transparent;
}
:deep(input:not(:focus)::selection) {
  background: transparent;
}
</style>
