import type { User } from "@sentry/browser";

import { backendOld } from "~/api";
import { notify } from "~/components/notifications";
import { Entitlement, NotificationType, TutorialName, UserRole, UserStatus } from "~/shared/enums";
import { useAppStore, useTenantStore, useUserStore } from "~/stores";
import { getItemCountText, isValidEmail, prettyFormatList } from "~/utils/common";

import type { Actions } from ".";

/** Local app related actions */
export default (actions: Actions) => ({
  inviteUsers: async (role: UserRole, emails: string[]) => {
    const appStore = useAppStore();
    const tenantStore = useTenantStore();
    const userStore = useUserStore();

    const validEmails = emails.filter((e) => isValidEmail(e));
    if (validEmails.length === 0) {
      return [];
    }

    const inviteResult = await backendOld.workspace.inviteUsers(role, emails);
    const { items, errorPreexistingUserEmails } = inviteResult.data;

    const sentInviteEmails = items
      .filter((e: User) => e.status !== UserStatus.PENDING_SUBSCRIPTION_UPGRADE)
      .map((e: User) => e.email);

    if (sentInviteEmails.length > 0) {
      notify({
        message: `Invitation${sentInviteEmails.length > 1 ? "s" : ""} sent to ${prettyFormatList(sentInviteEmails)}`,
        type: NotificationType.SUCCESS,
        duration: 15000,
      });

      userStore.updateTutorialStatuses([{ name: TutorialName.INVITE_TEAMMATES, status: 2 }]);
    }

    if (errorPreexistingUserEmails.length > 0) {
      notify({
        message: `Invitation${errorPreexistingUserEmails.length > 1 ? "s" : ""} sent to ${prettyFormatList(
          errorPreexistingUserEmails
        )}. However, they already have${errorPreexistingUserEmails.length > 1 ? "" : " a"} Dart account${
          errorPreexistingUserEmails.length > 1 ? "s" : ""
        } that they will need to delete in order to switch to your workspace`,
        type: NotificationType.WARNING,
        duration: 15000,
      });
    }

    if (sentInviteEmails.length !== items.length) {
      appStore.setUpgradeConfirmationModalOpen(
        `invite more than ${getItemCountText(
          tenantStore.getEntitlementValue(Entitlement.MAX_USERS),
          "user"
        )} to your workspace`
      );
    }

    return sentInviteEmails;
  },
});
