import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  CHECK_LIST,
  TEXT_MATCH_TRANSFORMERS,
  type Transformer,
  UNORDERED_LIST,
} from "@lexical/markdown";
import htmlToPdfmake from "html-to-pdfmake";
import { $getRoot, createEditor, ElementNode, type Klass, type LexicalNode, type SerializedEditorState } from "lexical";
import { marked } from "marked";
import pdfMake from "pdfmake/build/pdfmake";
import type { TDocumentDefinitions } from "pdfmake/interfaces";

import { getShownPropertyWithConfigList } from "~/common/properties";
import { PropertyKind } from "~/shared/enums";
import type { Doc, Page, Task } from "~/shared/types";
import { saveFile } from "~/utils/api";

import {
  BOLD_STAR_SINGLE,
  CODE_BACKTICK,
  CODE_BLOCK,
  HEADING,
  ITALICS_HYPHEN,
  ORDERED_LIST,
  QUOTE,
  STRIKE_THROUGH_TILDE,
  UNDERLINE_UNDERSCORE,
} from "./markdownRules";
import { HORIZONTAL_RULE_TRANSFORMER } from "./nodes/HorizontalRuleNode";
import { TOGGLE_BLOCK_TRANSFORMER } from "./nodes/ToggleWrapperNode";

// code should go first as it prevents any transformations inside
// then longer tags match (e.g. ** or __ should go before * or _)
export const TRANSFORMERS: Transformer[] = [
  CODE_BLOCK,
  // element
  HEADING,
  QUOTE,
  CHECK_LIST,
  ORDERED_LIST,
  UNORDERED_LIST,
  TOGGLE_BLOCK_TRANSFORMER,
  HORIZONTAL_RULE_TRANSFORMER,
  // text format
  BOLD_STAR_SINGLE,
  ITALICS_HYPHEN,
  UNDERLINE_UNDERSCORE,
  STRIKE_THROUGH_TILDE,
  CODE_BACKTICK,
  // link only
  ...TEXT_MATCH_TRANSFORMERS,
];

let FULL_DART_EDITOR_NODES: Klass<LexicalNode>[] = [];

export const init = (fullDartEditorNodes: Klass<LexicalNode>[]) => {
  FULL_DART_EDITOR_NODES = fullDartEditorNodes;
};

export const fillNodeWithMarkdown = (node: ElementNode, markdown: string) => {
  $convertFromMarkdownString(markdown, TRANSFORMERS, node);
};

const escapeCsvField = (field: string) => {
  let res = field.replaceAll('"', '""');
  if (res.includes(",") || res.includes("\n") || res.includes('"')) {
    res = `"${res}"`;
  }
  return res;
};

const escapeMarkdownField = (field: string) => field.replace(/([\\|])/g, "\\$1");

export const convertTableToCsv = (table: string[][]) =>
  table.map((row) => row.map(escapeCsvField).join(",")).join("\n");

export const convertTableToMarkdown = (table: string[][]) => {
  if (table.length === 1) {
    return "No tasks";
  }
  const headers = table[0].map(escapeMarkdownField).join(" | ");
  const separator = table[0].map(() => "-").join(" | ");
  const rows = table
    .slice(1)
    .map((row) => row.map(escapeMarkdownField).join(" | "))
    .join("\n");
  return `${headers}\n${separator}\n${rows}`;
};

export const convertToMarkdown = (text: SerializedEditorState) => {
  const editor = createEditor({ nodes: FULL_DART_EDITOR_NODES });
  editor.setEditorState(editor.parseEditorState(text));

  let res = "";
  editor.update(() => {
    res = $convertToMarkdownString(TRANSFORMERS, $getRoot());
  });
  return res;
};

export const convertTaskToMarkdown = (task: Task) => {
  const propertiesTable = getShownPropertyWithConfigList()
    .map(([property, config]) => `${property.title} | ${config.getValuePretty(property, task)}`)
    .join("\n");
  return `# ${task.title}

---

Property | Value
 - | - 
${propertiesTable}
 
---

${convertToMarkdown(task.description)}`;
};

export const convertDocToMarkdown = (doc: Doc) => `# ${doc.title}

---

${convertToMarkdown(doc.text)}`;

export const convertPageToTable = (tasks: Task[]) => {
  const properties = getShownPropertyWithConfigList([PropertyKind.DEFAULT_TITLE, PropertyKind.DEFAULT_DESCRIPTION]);
  return [
    properties.map(([property]) => property.title),
    ...tasks.map((task) => properties.map(([property, config]) => config.getValuePretty(property, task))),
  ];
};

export const convertPageToMarkdown = (page: Page, groupedTasks: { title: string; tasks: Task[] }[]) => {
  const tasksStr = groupedTasks
    .map(
      ({ title, tasks }) => `## ${title}
${convertTableToMarkdown(convertPageToTable(tasks))}`
    )
    .join("\n\n");
  return `# ${page.title}

---

${tasksStr}`;
};

export const convertToHtml = (markdownStr: string) => marked.parse(markdownStr) as string;

export const convertToPlainText = (markdownStr: string) => {
  const htmlString = marked.parse(markdownStr) as string;
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent;
};

export const saveAsPdf = (
  markdownStr: string,
  fileSlug: string,
  options?: { postprocessHtml?: (html: string) => string; pdfOptions: Omit<TDocumentDefinitions, "content"> }
) => {
  const { postprocessHtml, pdfOptions } = options ?? {};
  let html = convertToHtml(markdownStr);
  if (postprocessHtml) {
    html = postprocessHtml(html);
  }
  const content = htmlToPdfmake(html, { tableAutoSize: true });
  const pdf = pdfMake.createPdf({ content, ...pdfOptions });
  pdf.download(`${fileSlug}.pdf`);
};

export const saveAsCsv = (csvStr: string, fileSlug: string) => {
  saveFile(new Blob([csvStr], { type: "text/csv" }), `${fileSlug}.csv`);
};

export const saveAsHtml = (
  markdownStr: string,
  fileSlug: string,
  options?: { postprocessHtml?: (html: string) => string }
) => {
  const { postprocessHtml } = options ?? {};
  let html = convertToHtml(markdownStr);
  if (postprocessHtml) {
    html = postprocessHtml(html);
  }
  saveFile(new Blob([html], { type: "text/plain" }), `${fileSlug}.html`);
};

export const saveAsMarkdown = (markdownStr: string, fileSlug: string) => {
  saveFile(new Blob([markdownStr], { type: "text/plain" }), `${fileSlug}.md`);
};
