export const listenDesktop = async <T>(hook: () => Promise<T>, callback: (t: T) => void) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      callback(await hook());
    } catch (error) {
      // ignore
    }
  }
};

export const callDesktop = (callback: () => void) => {
  try {
    if (DESKTOP === undefined) {
      return;
    }
  } catch (error) {
    if (error instanceof ReferenceError) {
      return;
    }
    throw error;
  }

  callback();
};
