import { computed, type ComputedRef } from "vue";

import { getGroupByDefinitionList } from "~/common/groupBy";
import { getPropertyValueFromTask, getPropertyWithConfigList } from "~/common/properties";
import { ChartAggregation, PropertyKind } from "~/shared/enums";
import type { GroupByGroup, Property, Task, TimeTrackingEntry } from "~/shared/types";
import { useDataStore } from "~/stores";
import { isString } from "~/utils/common";
import { getTimeTrackingDuration } from "~/utils/time";

type SimplePropertyValue = string[] | string | number | boolean | null;

const makeKey = (primaryId: string, secondaryId: string) => JSON.stringify([primaryId, secondaryId]);

export const getAllGroupByDefinitionList = () => getGroupByDefinitionList(getPropertyWithConfigList());

export const getOptionsForProperty = (propertyDuid: string): GroupByGroup[] =>
  getAllGroupByDefinitionList().find((e) => e.property.duid === propertyDuid)?.groups ?? [];

export const TIME_TRACKING_KINDS = new Set([PropertyKind.DEFAULT_TIME_TRACKING, PropertyKind.TIME_TRACKING]);

const USER_PROPERTY_KINDS = new Set([
  PropertyKind.USER,
  PropertyKind.DEFAULT_ASSIGNEES,
  PropertyKind.DEFAULT_CREATED_BY,
  PropertyKind.DEFAULT_UPDATED_BY,
]);
const isUserProperty = (property: Property) => USER_PROPERTY_KINDS.has(property.kind);

export const getTotals = (
  args: ComputedRef<{
    tasks: Task[];
    primaryPropertyDuid: string;
    aggregationPropertyDuid?: string | null;
    aggregation?: ChartAggregation;
  }>
) => {
  const propertyOptions = computed(() => getOptionsForProperty(args.value.primaryPropertyDuid));
  const valuesToIds = computed(() => new Map(propertyOptions.value.map((e) => [e.value, e.id])));
  const property = computed(() => useDataStore().getPropertyByDuid(args.value.primaryPropertyDuid));
  const nullValue = computed(() => propertyOptions.value.find((e) => e.value === null));
  const aggregationProperty = computed(() =>
    args.value.aggregationPropertyDuid
      ? useDataStore().getPropertyByDuid(args.value.aggregationPropertyDuid)
      : undefined
  );

  const totals = computed(() => {
    const values = new Map<string, number>();
    args.value.tasks.forEach((task) => {
      if (!property.value) {
        return;
      }

      const updateValues = (primaryValue: SimplePropertyValue, value: SimplePropertyValue) => {
        const primaryIds = (
          Array.isArray(primaryValue)
            ? primaryValue.length === 0
              ? [nullValue.value?.id]
              : primaryValue.map((e) => valuesToIds.value.get(e))
            : [valuesToIds.value.get(primaryValue)]
        ).filter(isString);

        primaryIds.forEach((primaryId) => {
          if (!values.has(primaryId)) {
            values.set(primaryId, 0);
          }

          values.set(
            primaryId,
            values.get(primaryId)! +
              (aggregationProperty.value && args.value.aggregation !== ChartAggregation.COUNT ? (value as number) : 1)
          );
        });
      };

      const primaryValue = getPropertyValueFromTask(property.value, task) as SimplePropertyValue;

      const aggregationIsTimeTracking =
        aggregationProperty.value && TIME_TRACKING_KINDS.has(aggregationProperty.value.kind);
      let aggregationValue: SimplePropertyValue = null;
      if (aggregationIsTimeTracking) {
        const timeTrackingValue = getPropertyValueFromTask(aggregationProperty.value, task) as TimeTrackingEntry[];
        timeTrackingValue.forEach((e) => {
          updateValues(e.userDuid, getTimeTrackingDuration([e]).asSeconds());
        });
      } else if (aggregationProperty.value) {
        aggregationValue = getPropertyValueFromTask(aggregationProperty.value, task) as SimplePropertyValue;
      }

      updateValues(primaryValue, aggregationValue);
    });

    return propertyOptions.value.map((e) => {
      const value = values.get(e.id) ?? 0;
      if (aggregationProperty.value) {
        return args.value.aggregation === ChartAggregation.AVG ? Math.round(value / args.value.tasks.length) : value;
      }

      return value;
    });
  });
  const hasData = computed(() => totals.value.some((e) => e > 0));

  return { totals, hasData, property, propertyOptions, valuesToIds, aggregationProperty };
};

export const getSeries = (
  args: ComputedRef<{
    tasks: Task[];
    primaryPropertyDuid: string;
    secondaryPropertyDuid: string | null;
    aggregationPropertyDuid?: string | null;
    aggregation?: ChartAggregation;
  }>
) => {
  const {
    totals,
    hasData,
    property: primaryProperty,
    propertyOptions: primaryPropertyOptions,
    valuesToIds: primaryValuesToIds,
    aggregationProperty,
  } = getTotals(args);
  const primaryNullValue = computed(() => primaryPropertyOptions.value.find((e) => e.value === null));
  const secondaryPropertyOptions = computed(() =>
    args.value.secondaryPropertyDuid ? getOptionsForProperty(args.value.secondaryPropertyDuid) : []
  );
  const secondaryProperty = computed(() =>
    args.value.secondaryPropertyDuid ? useDataStore().getPropertyByDuid(args.value.secondaryPropertyDuid) : undefined
  );
  const secondaryValuesToIds = computed(() => new Map(secondaryPropertyOptions.value.map((e) => [e.value, e.id])));
  const secondaryNullValue = computed(() => secondaryPropertyOptions.value.find((e) => e.value === null));

  const series = computed(() => {
    if (!secondaryProperty.value) {
      return [{ name: "Total", data: totals.value }];
    }

    const values = new Map<string, number>();
    args.value.tasks.forEach((task) => {
      if (!primaryProperty.value || !secondaryProperty.value) {
        return;
      }

      const updateValues = (
        primaryValue: SimplePropertyValue,
        secondaryValue: SimplePropertyValue,
        value: SimplePropertyValue
      ) => {
        const primaryIds = (
          Array.isArray(primaryValue)
            ? primaryValue.length === 0
              ? [primaryNullValue.value?.id]
              : primaryValue.map((e) => primaryValuesToIds.value.get(e))
            : [primaryValuesToIds.value.get(primaryValue)]
        ).filter(isString);

        const secondaryIds = (
          Array.isArray(secondaryValue)
            ? secondaryValue.length === 0
              ? [secondaryNullValue.value?.id]
              : secondaryValue.map((e) => secondaryValuesToIds.value.get(e))
            : [secondaryValuesToIds.value.get(secondaryValue)]
        ).filter(isString);

        primaryIds.forEach((primaryId) => {
          secondaryIds.forEach((secondaryId) => {
            const key = makeKey(primaryId, secondaryId);
            if (!values.has(key)) {
              values.set(key, 0);
            }
            values.set(
              key,
              values.get(key)! +
                (aggregationProperty.value && args.value.aggregation !== ChartAggregation.COUNT ? (value as number) : 1)
            );
          });
        });
      };

      const primaryValue = getPropertyValueFromTask(primaryProperty.value, task) as SimplePropertyValue;
      const secondaryValue = getPropertyValueFromTask(secondaryProperty.value, task) as SimplePropertyValue;

      const aggregationIsTimeTracking =
        aggregationProperty.value &&
        TIME_TRACKING_KINDS.has(aggregationProperty.value.kind) &&
        args.value.aggregation !== ChartAggregation.COUNT;
      let aggregationValue: SimplePropertyValue = null;
      if (aggregationIsTimeTracking) {
        const timeTrackingValue = getPropertyValueFromTask(aggregationProperty.value, task) as TimeTrackingEntry[];
        timeTrackingValue.forEach((e) => {
          updateValues(
            primaryProperty.value && isUserProperty(primaryProperty.value) ? e.userDuid : primaryValue,
            secondaryProperty.value && isUserProperty(secondaryProperty.value) ? e.userDuid : secondaryValue,
            getTimeTrackingDuration([e]).asSeconds()
          );
        });
      } else if (aggregationProperty.value) {
        aggregationValue = getPropertyValueFromTask(aggregationProperty.value, task) as SimplePropertyValue;
      }

      updateValues(primaryValue, secondaryValue, aggregationValue);
    });

    return secondaryPropertyOptions.value.map((secondary) => ({
      name: secondary.title,
      data: primaryPropertyOptions.value.map((primary) => {
        const value = values.get(makeKey(primary.id, secondary.id)) ?? 0;
        if (aggregationProperty.value) {
          return args.value.aggregation === ChartAggregation.AVG ? Math.round(value / args.value.tasks.length) : value;
        }

        return value;
      }),
    }));
  });

  return {
    series,
    totals,
    hasData,
    primaryProperty,
    primaryPropertyOptions,
    secondaryProperty,
    secondaryPropertyOptions,
    aggregationProperty,
  };
};
