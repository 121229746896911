<script setup lang="ts">
import { computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { DotsHorizontalIcon, HideIcon, PropertiesIcon } from "~/icons";
import { makeLinkToPropertySettingsRef } from "~/router/common";
import { ButtonStyle, DropdownMenuItemKind, IconSize, PropertyKind, UserRole, ViewKind } from "~/shared/enums";
import type { Property } from "~/shared/types";
import { useAppStore, usePageStore, useTenantStore, useUserStore } from "~/stores";

const props = defineProps<{
  property: Property;
}>();

const appStore = useAppStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const isStatus = computed(() => props.property.kind === PropertyKind.DEFAULT_STATUS);
const isDartboard = computed(() => props.property.kind === PropertyKind.DEFAULT_DARTBOARD);

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const title = computed(() =>
  isDartboard.value ? (isTrash.value ? "Former dartboard" : "Dartboard") : props.property.title
);

const sections = computed(() => [
  {
    title: "Options",
    items: [
      {
        title: "Hide property",
        kind: DropdownMenuItemKind.BUTTON,
        icon: HideIcon,
        hidden: isStatus.value,
        onClick: () => {
          appStore.setPropertyDuidVisibility(props.property.duid, false);
        },
      },
      {
        title: "Property settings",
        kind: DropdownMenuItemKind.INTERNAL_LINK,
        icon: PropertiesIcon,
        hidden: !userStore.isRoleGreaterOrEqual(UserRole.ADMIN),
        navigate: { to: makeLinkToPropertySettingsRef(props.property.duid).value },
      },
    ],
  },
]);

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, sections.value);
};
</script>

<template>
  <div class="group/chip-label ml-2 flex w-fit max-w-full items-center gap-2" @contextmenu="onContextMenu">
    <span :title="title" class="select-none truncate text-sm font-medium">
      {{ title }}
    </span>
    <DropdownMenu
      v-if="!isDartboard"
      ref="dropdownMenu"
      :sections="sections"
      :width-pixels="180"
      :distance="2"
      :class="dropdownMenu?.isOpen ? 'flex' : 'hidden group-hover/chip-label:flex'">
      <Tooltip text="Property options">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :icon="DotsHorizontalIcon"
          :icon-size="IconSize.XS"
          borderless
          a11y-label="Property options"
          class="!p-0.5" />
      </Tooltip>
    </DropdownMenu>
  </div>
</template>
