<script setup lang="ts">
import { AxiosError } from "axios";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import DartBranding from "~/components/dumb/DartBranding.vue";
import EmptyState from "~/components/dumb/EmptyState.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import TaskListAndDetail from "~/components/dumb/TaskListAndDetail.vue";
import FullscreenMediaModal from "~/components/FullscreenMediaModal.vue";
import TaskList from "~/components/visualization/TaskList.vue";
import { colorsByTheme } from "~/constants/style";
import { ReplicateIcon, UnknownPageIcon } from "~/icons";
import { ButtonSize, ButtonStyle, IconSize, PageKind, StoreDataPreservationMode, ViewKind } from "~/shared/enums";
import type { View } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getPageDisplayName, getViewLink, makeDuid } from "~/utils/common";

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const view = ref<View | undefined>(undefined);
const useTemplateLink = computed(
  () =>
    router.resolve({
      name: "home",
      query: { template: view.value?.duid },
    }).href
);

const setPageInfo = () => {
  pageStore.pageLoaded = true;
  const title = view.value?.title ?? "Unknown view";
  pageStore.pageTitle = title;
  document.title = title;
};

const setUserData = async () => {
  const viewDuid = router.currentRoute.value.params.viewDuid.toString();
  let res;
  try {
    res = await backendOld.views.getData(viewDuid, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.code !== AxiosError.ERR_BAD_REQUEST) {
      throw error;
    }
    setPageInfo();
    return;
  }
  const { data } = res;

  appStore.init();
  appStore.setPageIconOptions(data.pageIconOptions);

  userStore.setFirstDayOfWeek(1);

  // this groups must happen in this order for subsequent comparisons
  dataStore.$setRelationshipKinds(data.relationshipKinds);
  dataStore.$setTasks(data.tasks, StoreDataPreservationMode.NONE);

  // then these are just alphabetical
  dataStore.$setAttachments(data.attachments);
  dataStore.$setLayouts([data.layout], StoreDataPreservationMode.NONE);
  dataStore.$setUsers(data.users);
  dataStore.$setOptions(data.options);
  dataStore.$setProperties(data.properties);
  dataStore.$setStatuses(data.statuses);
  dataStore.$setTaskKinds(data.taskKinds);
  dataStore.$setViews([
    data.view,
    { kind: ViewKind.TRASH, duid: makeDuid(), favoritedByUserDuids: [], pageKind: PageKind.VIEW },
  ]);

  view.value = dataStore.getViewByDuid(data.view.duid);
  if (view.value) {
    appStore.setCurrentPage(view.value);
  }

  setPageInfo();

  setTimeout(() => {
    if (!view.value) {
      return;
    }
    router.replace({
      params: { slugSep: "-", slug: getViewLink(view.value).params?.slug },
    });
  });
};

onMounted(setUserData);
</script>

<template>
  <FullscreenMediaModal />
  <div
    id="view"
    class="relative flex size-full flex-col overflow-hidden bg-std"
    :style="{ '--background': colors.bgStd, '--highlight': colors.highlight }"
    @click.stop>
    <div v-if="!view" class="flex w-full grow flex-col justify-evenly">
      <EmptyState
        title="View not found"
        description="This view does not exist or you do not have access to it."
        :main-icon="UnknownPageIcon" />
    </div>

    <template v-else>
      <!-- Topbar -->
      <header
        class="relative flex h-[50px] w-full shrink-0 items-center gap-6 overflow-hidden border-b px-4 border-md"
        aria-label="Top navigation">
        <div class="ml-1 mr-0.5 flex w-1/3 items-center gap-2 rounded px-1 py-0.5 text-sm text-md">
          <PageIcon :page="view" />
          <span class="truncate">{{ getPageDisplayName(view, dataStore.getSpaceByDuid) }}</span>
        </div>
        <DartBranding class="w-1/3 justify-center" />
        <div class="flex w-1/3 justify-end">
          <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
          <a :href="useTemplateLink">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.SMALL"
              :icon="ReplicateIcon"
              :icon-size="IconSize.S"
              class="h-6 pl-1 pr-1.5 print:hidden"
              text="Use template" />
          </a>
        </div>
      </header>

      <TaskListAndDetail class="size-full">
        <TaskList />
      </TaskListAndDetail>
    </template>
  </div>
</template>

<style>
#view .ag-root .ag-header,
#view .ag-root .ag-cell,
#view .dart-board-task-card,
#view .dart-large-chips,
#view .group\/text-editor,
#view .group\/title {
  @apply !pointer-events-none !cursor-default !select-none;
}

#view .ag-root .ag-cell {
  @apply !cursor-pointer;
}

#view .dart-open-rightbar,
#view .dart-subtask-expand {
  @apply !pointer-events-auto;
}
</style>
