import { ElementNode, type LexicalNode, type SerializedElementNode } from "lexical";

type SerializedToggleDetailsNode = SerializedElementNode;

export class ToggleDetailsNode extends ElementNode {
  static getType(): string {
    return "toggle-details";
  }

  static clone(node: ToggleDetailsNode): ToggleDetailsNode {
    return new ToggleDetailsNode(node.__key);
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.classList.add("contents");
    return div;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(): LexicalNode {
    return new ToggleDetailsNode();
  }

  // eslint-disable-next-line class-methods-use-this
  exportJSON(): SerializedToggleDetailsNode {
    return {
      type: ToggleDetailsNode.getType(),
      indent: 0,
      direction: "ltr",
      format: "",
      children: [],
      version: 1,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  isShadowRoot(): true {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  canBeEmpty(): false {
    return false;
  }
}

export function $createToggleDetailsNode(): ToggleDetailsNode {
  return new ToggleDetailsNode();
}

export function $isToggleDetailsNode(node?: LexicalNode | null): node is ToggleDetailsNode {
  return node instanceof ToggleDetailsNode;
}
