<script setup lang="ts">
import { computed } from "vue";

import { backendOld } from "~/api";
import Toggle from "~/components/dumb/Toggle.vue";
import { useTenantStore } from "~/stores";

const tenantStore = useTenantStore();

const emailEnabled = computed(() => tenantStore.emailIntegrationEnabled);

const toggleEnabled = (newValue: boolean) => {
  if (newValue === emailEnabled.value) {
    return;
  }

  tenantStore.emailIntegrationEnabled = newValue;
  backendOld.workspace.edit("emailIntegrationEnabled", newValue);
};
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex justify-between">
          <div class="flex gap-x-4">
            <h2 class="flex select-none items-center text-xl text-md">Email integration</h2>
          </div>
        </div>
        <div class="select-none text-sm/relaxed text-lt">
          <p>
            Integrate your email with Dart to create a tasks from email messages. You can also send new emails to Dart
            to make new tasks! Dart will automatically pull in the subject, content, and attachments to make a new task.
          </p>
          <br />
          <p>
            Choose which dartboard your new tasks will go to, then go into that dartboard's options and choose 'Copy
            dartboard email'. Then go to your email application and set this email as a contact for Dart.
          </p>
          <br />
          <p>
            Now you can send or forward emails to this contact to make tasks! Be sure to send these emails from the same
            address you use for your Dart account.
          </p>
          <br />
          <p>
            In most email applications you can even set up automations so that certain kinds of emails always get
            forwarded straight to Dart and skip your inbox!
          </p>
        </div>
      </div>

      <Toggle
        :value="emailEnabled"
        label="Enable"
        description="Allow task creation from email"
        @update="toggleEnabled" />
    </div>
  </div>
</template>
