<script setup lang="ts">
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, ListNode, type ListType } from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  type RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import GenericSubdropdown from "~/components/dumb/GenericSubdropdown.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import {
  EVENT_CONVERT_TO_TASKS,
  EVENT_GENERATE_RECOMMENDATION,
  EVENT_SPLIT_TASK,
  LEXICAL_OUTSIDE_CLASS,
} from "~/components/text/const";
import TextFormatOptionsDropdown from "~/components/text/plugins/TextFormatOptionsDropdown.vue";
import {
  formatBulletList,
  formatCheckList,
  formatNumberedList,
  getRecommendationTypeaheadOptions,
  getSelectedNode,
} from "~/components/text/utils";
import {
  BoldIcon,
  ChecklistIcon,
  CodeSnippetIcon,
  ItalicIcon,
  LinkIcon,
  NumberedListIcon,
  SlashSquareIcon,
  StrikethroughIcon,
  UnderlineIcon,
  UnorderedListIcon,
} from "~/icons";
import { CommandId, DropdownMenuItemKind, Placement, RecommendationKind } from "~/shared/enums";
import type { TextBlockType, TypeaheadOption } from "~/shared/types";

defineProps<{
  hideAiActionButton?: boolean;
}>();

const editor = useLexicalComposer();

const blockType = ref("paragraph");
const selectedElementKey = ref<string | null>(null);

const isLink = ref(false);
const isBold = ref(false);
const isItalic = ref(false);
const isUnderline = ref(false);
const isStrikethrough = ref(false);
const isCode = ref(false);
const listType = ref<ListType | null>(null);

// Copied from https://github.com/wobsoriano/lexical-vue/blob/main/playground/src/components/ToolbarPlugin.vue
const updateToolbar = () => {
  const selection = $getSelection() as RangeSelection;
  if (!$isRangeSelection(selection)) {
    return;
  }

  const anchorNode = selection.anchor.getNode();
  const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
  const elementKey = element.getKey();
  const elementDOM = editor.getElementByKey(elementKey);
  if (elementDOM !== null) {
    selectedElementKey.value = elementKey;

    if ($isListNode(element)) {
      const parentList = $getNearestNodeOfType(anchorNode, ListNode) as ListNode;
      listType.value = element.getListType();
      blockType.value = parentList ? parentList.getTag() : element.getTag();
    } else {
      listType.value = null;
      blockType.value = $isHeadingNode(element) ? element.getTag() : element.getType();
    }
  }
  // Update text format
  isBold.value = selection.hasFormat("bold");
  isItalic.value = selection.hasFormat("italic");
  isUnderline.value = selection.hasFormat("underline");
  isStrikethrough.value = selection.hasFormat("strikethrough");
  isCode.value = selection.hasFormat("code");

  // Update links
  const node = getSelectedNode(selection);
  const parent = node.getParent();
  if ($isLinkNode(parent) || $isLinkNode(node)) {
    isLink.value = true;
  } else {
    isLink.value = false;
  }
};

const insertLink = () => {
  if (!isLink.value) {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, "");
  } else {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
  }
};

const processOption = (option: TypeaheadOption) => ({
  title: option.label,
  kind: DropdownMenuItemKind.BUTTON,
  icon: option.icon,
  iconArgs: option.iconArgs,
  onClick: () => {
    if (option.value === RecommendationKind.SPLIT) {
      editor.dispatchCommand(EVENT_SPLIT_TASK, undefined);
      return;
    }
    if (option.value === RecommendationKind.CONVERT_TO_TASKS) {
      editor.dispatchCommand(EVENT_CONVERT_TO_TASKS, undefined);
      return;
    }
    editor.dispatchCommand(EVENT_GENERATE_RECOMMENDATION, {
      kind: option.value,
      parentKey: null,
    });
  },
});

const slashCommandsMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);
const slashButtonDropdownOptions = computed(() => [
  {
    title: "AI actions",
    items: getRecommendationTypeaheadOptions(editor._config.namespace, false).map((option: TypeaheadOption) => {
      if (option.options) {
        return {
          title: option.label,
          kind: DropdownMenuItemKind.COMPONENT,
          component: GenericSubdropdown,
          componentArgs: {
            title: option.label,
            icon: option.icon,
            iconArgs: option.iconArgs,
            widthPixels: 320,
            sections: [
              {
                title: "Options",
                items: option.options.map(processOption),
              },
            ],
          },
        };
      }
      return processOption(option);
    }),
  },
]);

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar();
      });
    }),
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_LOW
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <div
    class="flex items-center gap-1 divide-x p-1 text-lt divide-lt"
    @click.stop="editor.focus()"
    @keydown.enter.stop="editor.focus()">
    <TextFormatOptionsDropdown ref="dropdown" :block-type="blockType as TextBlockType" />

    <div v-if="!hideAiActionButton" class="flex gap-1 pl-1">
      <DropdownMenu
        ref="slashCommandsMenu"
        :popper-class="LEXICAL_OUTSIDE_CLASS"
        :sections="slashButtonDropdownOptions"
        :placement="Placement.BOTTOM"
        :distance="8">
        <button
          type="button"
          class="rounded p-1 text-recommendation-base"
          :class="slashCommandsMenu?.isOpen ? 'bg-md hover:bg-hvy' : 'hover:bg-md'">
          <span class="sr-only">AI actions</span>
          <SlashSquareIcon class="icon-sm" />
        </button>
      </DropdownMenu>
    </div>

    <div class="flex gap-1 pl-1">
      <Tooltip :command-id="CommandId.EDITOR_BOLD">
        <button
          type="button"
          class="rounded p-1"
          :class="isBold ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Format Bold"
          @click="editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')">
          <BoldIcon class="icon-sm" />
        </button>
      </Tooltip>

      <Tooltip :command-id="CommandId.EDITOR_ITALICIZE">
        <button
          type="button"
          class="rounded p-1"
          :class="isItalic ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Format Italics"
          @click="editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')">
          <ItalicIcon class="icon-sm" />
        </button>
      </Tooltip>

      <Tooltip :command-id="CommandId.EDITOR_UNDERLINE">
        <button
          type="button"
          class="rounded p-1"
          :class="isUnderline ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Format Underline"
          @click="editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')">
          <UnderlineIcon class="icon-sm" />
        </button>
      </Tooltip>

      <Tooltip :command-id="CommandId.EDITOR_STRIKETHROUGH">
        <button
          type="button"
          class="rounded p-1"
          :class="isStrikethrough ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Format Strikethrough"
          @click="editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')">
          <StrikethroughIcon class="icon-sm" />
        </button>
      </Tooltip>

      <Tooltip :command-id="CommandId.EDITOR_SET_CODE_SNIPPET">
        <button
          type="button"
          class="rounded p-1"
          :class="isCode ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Insert Code"
          @click="editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')">
          <CodeSnippetIcon class="icon-sm" />
        </button>
      </Tooltip>

      <Tooltip :command-id="CommandId.EDITOR_ADD_LINK">
        <button
          type="button"
          class="rounded p-1"
          :class="isLink ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Insert Link"
          @click="insertLink">
          <LinkIcon class="icon-sm" />
        </button>
      </Tooltip>
    </div>

    <div class="flex gap-1 pl-1">
      <Tooltip :command-id="CommandId.EDITOR_SET_CHECKLIST">
        <button
          type="button"
          class="rounded p-0.5"
          :class="listType === 'check' ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Set checklist"
          @click="formatCheckList(listType, editor)">
          <ChecklistIcon class="icon-md" />
        </button>
      </Tooltip>
      <Tooltip :command-id="CommandId.EDITOR_SET_NUMBERED_LIST">
        <button
          type="button"
          class="rounded p-0.5"
          :class="listType === 'number' ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Set numbered list"
          @click="formatNumberedList(listType, editor)">
          <NumberedListIcon class="icon-md" />
        </button>
      </Tooltip>
      <Tooltip :command-id="CommandId.EDITOR_SET_BULLETED_LIST">
        <button
          type="button"
          class="rounded p-0.5"
          :class="listType === 'bullet' ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
          aria-label="Set bulleted list"
          @click="formatBulletList(listType, editor)">
          <UnorderedListIcon class="icon-md" />
        </button>
      </Tooltip>
    </div>
  </div>
</template>
