<script setup lang="ts">
import { $isListNode } from "@lexical/list";
import { mergeRegister } from "@lexical/utils";
import type { AxiosProgressEvent } from "axios";
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $isElementNode,
  COMMAND_PRIORITY_EDITOR,
  type ElementNode,
  type LexicalNode,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import actions from "~/actions";
import { backendOld } from "~/api";
import { LANGUAGE_SET } from "~/constants/language";
import { FeedbackRating, Language, RecommendationAction, RecommendationKind } from "~/shared/enums";

import { EVENT_BLUR_OVERALL, EVENT_CLEANUP_RECOMMENDATION_NODE, EVENT_GENERATE_RECOMMENDATION } from "../const";
import { $createAiCursorNode } from "../nodes/AiCursorNode";
import {
  $createRecommendationButtonsNode,
  $isRecommendationButtonsNode,
  RecommendationButtonsNode,
} from "../nodes/RecommendationButtonsNode";
import { $createRecommendationLoadingNode, RecommendationLoadingNode } from "../nodes/RecommendationLoadingNode";
import {
  $createRecommendationWrapperNode,
  $isRecommendationWrapperNode,
  RecommendationWrapperNode,
} from "../nodes/RecommendationWrapperNode";
import { fillNodeWithMarkdown } from "../transformers";
import { isEmpty } from "../utils";

const props = defineProps<{
  duid: string;
}>();

const editor = useLexicalComposer();

const removeNodeIfEmpty = (key: string) => {
  const node = $getNodeByKey(key);
  if (node === null || node.getTextContent().trim() !== "") {
    return;
  }

  node.remove();
};

const fillInRecommendation = async (wrapperNode: ElementNode, kind: RecommendationKind, language?: Language) => {
  let recommendationDuid: string | null = null;
  let ended = false;
  const setText = (text: string | undefined, end: boolean) => {
    if (text === undefined || ended) {
      return;
    }
    if (end) {
      ended = true;
    }

    if (!recommendationDuid) {
      recommendationDuid = text.substring(0, 12);
    }

    const normalizedText = text.substring(12);
    if (normalizedText === "") {
      // Remove wrapper if text is empty at the end
      if (end) {
        editor.update(() => {
          wrapperNode.remove();
        });
      }
      return;
    }

    editor.update(() => {
      // User deleted the node before the request finished
      if ($getNodeByKey(wrapperNode.getKey()) === null) {
        return;
      }

      fillNodeWithMarkdown(wrapperNode, normalizedText);

      if (!end) {
        // AI cursor
        const lastChild = wrapperNode.getLastChild();
        const targetNode = $isListNode(lastChild) ? lastChild.getLastChild() : lastChild;
        if (!targetNode || !$isElementNode(targetNode)) {
          return;
        }
        targetNode.append($createAiCursorNode());
      } else {
        // Add buttons
        wrapperNode.append($createRecommendationButtonsNode(kind, recommendationDuid ?? ""));
      }
    });
  };
  const handlePartialResponse = (e: AxiosProgressEvent) => {
    const { currentTarget } = e.event;
    if (!currentTarget || currentTarget.status < 200 || currentTarget.status >= 300) {
      return;
    }
    setText(currentTarget.response, false);
  };

  const request = language
    ? backendOld.recommendations.streamTranslation(props.duid, language, handlePartialResponse)
    : backendOld.recommendations.streamContent(props.duid, kind, handlePartialResponse);
  try {
    const response = await request;
    setText(response.data, true);
  } catch (error) {
    editor.update(() => {
      wrapperNode.remove();
    });
  }
};

const recommend = (kind: RecommendationKind | Language, parentKey: string | null): boolean => {
  if (kind === RecommendationKind.PROPERTIES) {
    actions.visualization.recommendProperties();
    return true;
  }
  if (kind === RecommendationKind.SUBTASKS) {
    actions.visualization.recommendSubtasks();
    return true;
  }

  if (parentKey !== null) {
    removeNodeIfEmpty(parentKey);
  }

  let kindNorm: RecommendationKind;
  let language: Language | undefined;
  const kindAsLanguage = kind as Language;
  if (LANGUAGE_SET.has(kindAsLanguage)) {
    kindNorm = RecommendationKind.TRANSLATE;
    language = kindAsLanguage;
  } else {
    kindNorm = kind as RecommendationKind;
  }

  const rootNode = $getRoot();
  const wrapperNode = $createRecommendationWrapperNode(kindNorm);
  fillInRecommendation(wrapperNode, kindNorm, language);
  const loadingNode = $createRecommendationLoadingNode(kindNorm);
  wrapperNode.append(loadingNode);
  rootNode.append(wrapperNode);
  return true;
};

const cleanupRecommendationNode = (
  childNodeKey: string,
  action: RecommendationAction,
  recommendationDuid: string | undefined
): boolean => {
  const childNode = $getNodeByKey(childNodeKey);
  if (childNode === null) {
    return false;
  }
  const wrapperNode = childNode.getParent();
  if (wrapperNode === null || !$isRecommendationWrapperNode(wrapperNode)) {
    return false;
  }

  if (action === RecommendationAction.REPLACE) {
    wrapperNode.getPreviousSiblings().forEach((e) => e.remove());
  }
  if (action === RecommendationAction.KEEP || action === RecommendationAction.REPLACE) {
    const children: LexicalNode[] = wrapperNode.getChildren();
    children.filter((e) => !$isRecommendationButtonsNode(e)).forEach((e) => wrapperNode.insertBefore(e));
  }

  wrapperNode.remove();
  editor.focus();

  if (recommendationDuid === undefined) {
    return true;
  }

  backendOld.recommendations.provideFeedback(
    recommendationDuid,
    action === RecommendationAction.DISCARD ? FeedbackRating.BAD : FeedbackRating.GOOD
  );
  return true;
};

const keepAllRecommendations = (): boolean =>
  // TODO enable this when focus is tracked completely (i.e. dropdowns and buttons count as focus)
  // const rootNode = $getRoot();
  // rootNode
  //   .getChildren()
  //   .filter((e) => $isRecommendationWrapperNode(e))
  //   .forEach((wrapper) => {
  //     const buttonsNode = wrapper.getChildren().find((e: LexicalNode) => $isRecommendationButtonsNode(e));
  //     if (buttonsNode === undefined) return;
  //     cleanupRecommendationNode(buttonsNode.getKey(), RecommendationAction.KEEP);
  //   });
  true;
const openRecommendations = () => {
  editor.update(() => {
    const rootNode = $getRoot();
    const lastChild = rootNode.getLastChild();
    if (lastChild && isEmpty(lastChild)) {
      lastChild.remove();
    }
    const paragraphNode = $createParagraphNode();
    paragraphNode.append($createTextNode("/"));
    rootNode.append(paragraphNode);
    paragraphNode.select();
  });
  return true;
};

let unregisterListeners: () => void;

onMounted(() => {
  if (!editor.hasNodes([RecommendationButtonsNode, RecommendationLoadingNode, RecommendationWrapperNode])) {
    throw new Error(
      "RecommendationPlugin: some of [RecommendationButtonsNode, RecommendationLoadingNode, RecommendationWrapperNode] not registered on editor"
    );
  }

  unregisterListeners = mergeRegister(
    editor.registerCommand(EVENT_BLUR_OVERALL, keepAllRecommendations, COMMAND_PRIORITY_EDITOR),
    editor.registerCommand(
      EVENT_GENERATE_RECOMMENDATION,
      (payload: { kind: RecommendationKind | Language; parentKey: string | null }) =>
        recommend(payload.kind, payload.parentKey),
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      EVENT_CLEANUP_RECOMMENDATION_NODE,
      (payload: { key: string; action: RecommendationAction; recommendationDuid?: string }) =>
        cleanupRecommendationNode(payload.key, payload.action, payload.recommendationDuid),
      COMMAND_PRIORITY_EDITOR
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});

defineExpose({
  openRecommendations,
});
</script>

<template>
  <slot />
</template>
