<script setup lang="ts">
import { useKeyModifier } from "@vueuse/core";
import { type Component, computed, onUnmounted, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import { ButtonSize, ButtonStyle, DialogMode, IconMode, ModalWidth } from "~/shared/enums";

const props = defineProps<{
  title: string;
  description: string;
  confirmText?: string;
  hideCancel?: boolean;
  cancelText?: string;
  mode?: DialogMode;
  disabled?: boolean;
  icon?: Component;
  mainActionIcon?: Component;
  block?: boolean;
}>();

const emit = defineEmits<{
  confirm: [];
  secondaryAction: [];
  cancel: [];
}>();

const isOpen = ref(false);

const mode = computed(() => props.mode ?? DialogMode.STANDARD);
const isDeleteMode = computed(() => mode.value === DialogMode.DELETE);
const isSecondaryActionMode = computed(() => mode.value === DialogMode.SECONDARY_ACTION);

const altState = useKeyModifier("Alt");

const confirmAction = () => {
  emit("confirm");
  isOpen.value = false;
};

const cancelAction = () => {
  emit("cancel");
  isOpen.value = false;
};

const secondaryAction = () => {
  emit("secondaryAction");
  isOpen.value = false;
};

const onEscKeydown = (event: KeyboardEvent) => {
  if (event.key !== "Escape" || !isOpen.value) {
    return;
  }
  event.stopPropagation();
  cancelAction();
};

const openModal = () => {
  if (props.disabled) {
    return;
  }

  if (altState.value) {
    confirmAction();
    return;
  }

  isOpen.value = true;
  // Without this, esc will close parent modals - because the event is not captured by the modal
  document.addEventListener("keydown", onEscKeydown);
};

onUnmounted(() => {
  document.removeEventListener("keydown", onEscKeydown);
});

defineExpose({
  openModal,
});
</script>

<template>
  <div
    :class="block && 'w-full max-w-full grow'"
    class="flex items-center justify-center rounded text-sm"
    @click="openModal"
    @keydown.enter="openModal">
    <slot />
  </div>
  <Modal
    :entity="isOpen"
    :title="title"
    :width="ModalWidth.S"
    :description="description"
    :title-icon="icon"
    :icon-mode="isDeleteMode ? IconMode.DELETE : IconMode.PRIMARY"
   
    @close="cancelAction"
    @keydown.esc.stop="cancelAction">
    <template v-if="isSecondaryActionMode" #footerLeft>
      <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" @click="cancelAction" />
    </template>

    <template #actions>
      <div class="flex items-center justify-end gap-2">
        <Button
          v-if="!hideCancel"
          :btn-style="ButtonStyle.SECONDARY"
          :text="cancelText ?? 'Cancel'"
          :size="isSecondaryActionMode ? ButtonSize.SMALL : ButtonSize.LARGE"
          :borderless="isSecondaryActionMode"
          @click="isSecondaryActionMode ? secondaryAction() : cancelAction()" />

        <Button
          :btn-style="isDeleteMode ? ButtonStyle.DANGER : ButtonStyle.PRIMARY"
          :text="confirmText ?? 'Confirm'"
          :icon="mainActionIcon"
          :size="isSecondaryActionMode ? ButtonSize.SMALL : ButtonSize.LARGE"
         
          @click="confirmAction" />
      </div>
    </template>
  </Modal>
</template>
