<script setup lang="ts">
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListItemNode, $isListNode, type ListType } from "@lexical/list";
import { $findMatchingParent, mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isElementNode,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  FORMAT_TEXT_COMMAND,
  KEY_MODIFIER_COMMAND,
  type RangeSelection,
  TextNode,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import { usePageStore } from "~/stores";

import { EVENT_INSERT_HORIZONTAL_RULE, EVENT_INSERT_TOGGLE_COMMAND, TEXT_REPLACEMENT_RULES } from "../const";
import {
  createTextBlockNode,
  formatBulletList,
  formatCheckList,
  formatNumberedList,
  getSelectedNode,
  getUpdateDetails,
  isLineLevelNode,
} from "../utils";

const emit = defineEmits<{
  editLast: [];
}>();

const pageStore = usePageStore();
const editor = useLexicalComposer();

const insertLink = (selection: RangeSelection) => {
  const node = getSelectedNode(selection);
  const parent = node.getParent();

  if ($isLinkNode(parent) || $isLinkNode(node)) {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    return;
  }

  editor.dispatchCommand(TOGGLE_LINK_COMMAND, "");
};

const handleTextReplacements = (node: TextNode, offset: number) => {
  editor.update(() => {
    const content = node.getTextContent();
    const lastContent = content.slice(0, offset);
    TEXT_REPLACEMENT_RULES.forEach(([orig, replacement]) => {
      if (!lastContent.endsWith(orig)) {
        return;
      }

      node.setTextContent(`${content.slice(0, offset - orig.length)}${replacement}${content.slice(offset)}`);
      const newSelect = offset - orig.length + replacement.length;
      node.select(newSelect, newSelect);
    });
  });
};

// TODO should these all be commands?
const handleKeyboardShortcuts = (event: KeyboardEvent) => {
  const selection = $getSelection() as RangeSelection;
  if (selection === null) {
    return;
  }

  const { key, code } = event;
  const ctrl = pageStore.isMac ? event.metaKey : event.ctrlKey;
  const alt = event.altKey;
  const ctrlAndShift = ctrl && event.shiftKey;
  const ctrlAndAlt = ctrl && alt;

  const anchorNode = selection.anchor.getNode();
  const topLevelElement = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
  const nearestLineLevelParent = $findMatchingParent(anchorNode, isLineLevelNode);
  const listType: ListType | null = $isListNode(topLevelElement) ? topLevelElement.getListType() : null;

  if (ctrl && key === "ArrowUp") {
    event.preventDefault();
    emit("editLast");
    return;
  }

  editor.update(() => {
    if (ctrlAndShift) {
      switch (key) {
        case "6": {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
          break;
        }
        case "7": {
          event.preventDefault();
          formatNumberedList(listType, editor);
          break;
        }
        case "8": {
          event.preventDefault();
          formatBulletList(listType, editor);
          break;
        }
        case "9": {
          event.preventDefault();
          formatCheckList(listType, editor);
          break;
        }
        default: {
          break;
        }
      }
      return;
    }

    if (ctrlAndAlt) {
      switch (code) {
        case "Digit0": {
          event.preventDefault();
          createTextBlockNode("paragraph");
          break;
        }
        case "Digit1": {
          event.preventDefault();
          createTextBlockNode("h1");
          break;
        }
        case "Digit2": {
          event.preventDefault();
          createTextBlockNode("h2");
          break;
        }
        case "Digit3": {
          event.preventDefault();
          createTextBlockNode("h3");
          break;
        }
        case "Digit4": {
          event.preventDefault();
          createTextBlockNode("code");
          break;
        }
        case "Digit5": {
          event.preventDefault();
          createTextBlockNode("quote");
          break;
        }
        case "Digit6": {
          event.preventDefault();
          editor.dispatchCommand(EVENT_INSERT_TOGGLE_COMMAND, null);
          break;
        }
        case "Digit7": {
          event.preventDefault();
          editor.dispatchCommand(EVENT_INSERT_HORIZONTAL_RULE, null);
          break;
        }
        default: {
          break;
        }
      }
      return;
    }

    if (ctrl) {
      switch (key) {
        case "l": {
          event.preventDefault();
          insertLink(selection);
          break;
        }
        case "s": {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
          break;
        }
        default: {
          break;
        }
      }
      return;
    }

    if (alt) {
      switch (code) {
        case "KeyX": {
          event.preventDefault();
          if (listType !== "check") {
            break;
          }
          const selectedNodes = selection.getNodes();
          let checkListNodes = selectedNodes.filter($isListItemNode);
          if (
            checkListNodes.length === 0 &&
            nearestLineLevelParent !== null &&
            $isListItemNode(nearestLineLevelParent)
          ) {
            checkListNodes = [nearestLineLevelParent];
          }
          checkListNodes.forEach((e) => e.setChecked(!e.getChecked()));
          break;
        }
        case "ArrowUp": {
          event.preventDefault();
          const base = nearestLineLevelParent;
          if (base === null) {
            break;
          }
          const prev = base.getPreviousSibling();
          if (prev === null || !$isElementNode(prev)) {
            break;
          }
          const prevBaseMaybe = prev.getPreviousSibling();
          const next = base.getNextSibling();
          const last = next !== null && $isElementNode(next) && $isListNode(next.getChildren()[0]) ? next : base;
          last.insertAfter(prev);
          if (!$isListNode(prev.getChildren()[0])) {
            break;
          }
          if (prevBaseMaybe === null) {
            break;
          }
          last.insertAfter(prevBaseMaybe);
          break;
        }
        case "ArrowDown": {
          event.preventDefault();
          const base = nearestLineLevelParent;
          if (base === null) {
            break;
          }
          const next = base.getNextSibling();
          if (next === null) {
            break;
          }
          const nextBase = !$isElementNode(next) || !$isListNode(next.getChildren()[0]) ? next : next.getNextSibling();
          if (nextBase === null) {
            break;
          }
          const nextChildMaybe = nextBase.getNextSibling();
          base.insertBefore(nextBase);
          if (
            nextChildMaybe === null ||
            !$isElementNode(nextChildMaybe) ||
            !$isListNode(nextChildMaybe.getChildren()[0])
          ) {
            break;
          }
          base.insertBefore(nextChildMaybe);
          break;
        }
        default: {
          break;
        }
      }
    }
  });
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerUpdateListener((payload) => {
      const { normal, anchorNode, anchorOffset } = getUpdateDetails(payload);
      if (!normal || anchorNode === undefined || !$isTextNode(anchorNode) || anchorOffset === undefined) {
        return;
      }
      handleTextReplacements(anchorNode, anchorOffset);
    }),
    editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (event: KeyboardEvent) => {
        handleKeyboardShortcuts(event);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
