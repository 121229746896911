<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import { RelationshipKindKind, TypeaheadSpecialEntity } from "~/shared/enums";
import type { Doc, Task } from "~/shared/types";
import { useDataStore } from "~/stores";

import {
  EVENT_CREATE_DOC_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP,
} from "../const";

const props = defineProps<{
  task?: Task;
  doc?: Doc;
}>();

const dataStore = useDataStore();
const editor = useLexicalComposer();

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(
      EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP,
      (targetTaskDuid: string) => {
        const { task } = props;

        if (!task) {
          return false;
        }
        const relatesToRelationshipKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.RELATES_TO).duid;
        if (task.duid === targetTaskDuid) {
          return false;
        }

        dataStore.createRelationship(task.duid, targetTaskDuid, relatesToRelationshipKindDuid);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      EVENT_CREATE_TASK_DOC_RELATIONSHIP,
      (payload) => {
        const { doc, task } = props;
        const { entityDuid, specialEntity } = payload;

        if (specialEntity === TypeaheadSpecialEntity.TASK && doc) {
          dataStore.createTaskDocRelationship(entityDuid, doc.duid);
          return true;
        }

        if (specialEntity === TypeaheadSpecialEntity.DOC && task) {
          dataStore.createTaskDocRelationship(task.duid, entityDuid);
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand(
      EVENT_CREATE_DOC_DOC_RELATIONSHIP,
      (targetDocDuid: string) => {
        const { doc } = props;

        if (!doc) {
          return false;
        }
        if (doc.duid === targetDocDuid) {
          return false;
        }

        // TODO create this relationship

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
