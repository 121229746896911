<script setup lang="ts">
import { computed } from "vue";

import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import TaskStatisticsChip from "~/components/dumb/TaskStatisticsChip.vue";
import { ChevronDownIcon, ChevronUpIcon } from "~/icons";
import { SummaryStatisticKind, ViewKind } from "~/shared/enums";
import { useAppStore, usePageStore, useUserStore } from "~/stores";

const STATISTIC_CONFIGURATIONS = [
  {
    title: "Count tasks",
    kind: SummaryStatisticKind.TOTAL_COUNT,
  },
  {
    title: "Count incomplete tasks",
    kind: SummaryStatisticKind.INCOMPLETE_COUNT,
  },
  {
    title: "Percent finished tasks",
    kind: SummaryStatisticKind.COMPLETED_PERCENT,
  },
  {
    title: "Points",
    kind: SummaryStatisticKind.TOTAL_COUNT_POINTS,
  },
  {
    title: "Incomplete points",
    kind: SummaryStatisticKind.INCOMPLETE_COUNT_POINTS,
  },
  {
    title: "Percent finished points",
    kind: SummaryStatisticKind.COMPLETED_PERCENT_POINTS,
  },
];

const appStore = useAppStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const onSearch = computed(() => appStore.currentPage?.kind === ViewKind.SEARCH);
const showFooter = computed(() => !pageStore.isMobile && !onSearch.value && userStore.getShowFooter());

const onCollapseFooter = () => userStore.setShowFooter(false);
const onExpandFooter = () => userStore.setShowFooter(true);

const dropdownOptions = computed(() =>
  STATISTIC_CONFIGURATIONS.map(({ title, kind }) => ({
    title,
    selected: kind === appStore.summaryStatisticKind,
    onClick: () => appStore.setSummaryStatisticKind(kind),
  }))
);
</script>

<template>
  <template v-if="!pageStore.isMobile">
    <div v-if="showFooter" class="relative flex w-full select-none items-center border-t px-6 py-2 bg-std border-md">
      <DropdownMenuWithSelection :options="dropdownOptions" class="h-6">
        <TaskStatisticsChip :statistics="appStore.summaryStatistics" detailed />
      </DropdownMenuWithSelection>

      <button
        type="button"
        aria-label="Collapse footer"
        class="absolute right-8 top-0 flex w-6 items-center justify-center rounded-b-full border-x border-b text-lt border-md focus-ring-std hover:bg-md print:hidden"
        @click="onCollapseFooter">
        <ChevronDownIcon class="text-md icon-sm" />
      </button>
    </div>
    <button
      v-else
      type="button"
      aria-label="Expand footer"
      class="absolute bottom-0 right-8 flex w-6 items-center justify-center rounded-t-full border-x border-t border-md focus-ring-std hover:bg-md print:hidden"
      @click="onExpandFooter">
      <ChevronUpIcon class="text-md icon-sm" />
    </button>
  </template>
</template>
