<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_NORMAL, KEY_DOWN_COMMAND, KEY_ENTER_COMMAND, type LexicalEditor } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const props = defineProps<{
  filter?: (event: KeyboardEvent) => boolean;
}>();

const emit = defineEmits<{
  enter: [event: KeyboardEvent, editor: LexicalEditor];
}>();

const editor = useLexicalComposer();

let unregisterListeners: () => void;

const handleEvent = (event: KeyboardEvent) => {
  if (props.filter?.(event) === false) {
    return false;
  }
  event.preventDefault();
  event.stopPropagation();
  emit("enter", event, editor);
  return true;
};

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(KEY_ENTER_COMMAND, handleEvent, COMMAND_PRIORITY_NORMAL),
    editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event: KeyboardEvent) => (event.code === "NumpadEnter" ? handleEvent(event) : false),
      COMMAND_PRIORITY_NORMAL
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
