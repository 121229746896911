<script setup lang="ts">
import { computed } from "vue";

import { getFilterDefinitionList } from "~/common/filter";
import { getPropertyWithConfigList } from "~/common/properties";
import Tooltip from "~/components/dumb/Tooltip.vue";
import Filter from "~/components/filters/Filter.vue";
import { RecentIcon, SearchIcon, SearchRecommendationIcon, XIcon } from "~/icons";
import type { Filter as FilterType } from "~/shared/types";
import { useAppStore } from "~/stores";

const props = defineProps<{
  filters: FilterType[];
  query: string;
  isAi?: boolean;
  isRecentSearchMode?: boolean;
  selected?: boolean;
}>();

const emit = defineEmits<{
  save: [];
  remove: [];
  mouseover: [];
  mouseleave: [];
  blur: [];
  focus: [];
}>();

const appStore = useAppStore();

const nonDefaultFilters = computed(() => appStore.getNonDefaultSearchFilters(props.filters));
const definitions = computed(() => getFilterDefinitionList(getPropertyWithConfigList()));
</script>

<template>
  <button
    type="button"
    class="group/search-item flex min-h-7 items-center gap-2 py-1 pl-5 pr-3 text-md focus-ring-none"
    :class="selected && 'bg-md'"
    @mouseover="emit('mouseover')"
    @mouseleave="emit('mouseleave')"
    @blur="emit('blur')"
    @focus="emit('focus')"
    @click="emit('save')">
    <component
      :is="isRecentSearchMode ? RecentIcon : isAi ? SearchRecommendationIcon : SearchIcon"
      class="icon-sm"
      :class="isAi && 'text-recommendation-base'" />
    <div class="flex flex-wrap items-center gap-2 truncate">
      <span v-if="query?.length > 0" class="select-none truncate text-sm">
        {{ query }}
      </span>
      <span v-if="query.length > 0 && nonDefaultFilters.length > 0" class="select-none text-xs text-lt">and</span>
      <div v-for="(filter, idx) in nonDefaultFilters" :key="filter.id" class="flex items-center gap-1 truncate">
        <Filter
          class="pointer-events-none shadow"
          read-only
          :filter="filter"
          :definition="definitions.find((d) => d.propertyDuid === filter.propertyDuid)"
          :open-new-filter="() => ''" />
        <span v-if="idx !== nonDefaultFilters.length - 1 && nonDefaultFilters.length > 1" class="text-xs text-lt">
          and
        </span>
      </div>
    </div>
    <Tooltip
      v-if="isRecentSearchMode"
      text="Remove from search history"
      class="ml-auto opacity-0"
      :class="selected && 'opacity-100'">
      <button type="button" class="rounded p-0.5 focus-ring-none hover:bg-md" @click.stop="emit('remove')">
        <XIcon class="icon-xs" />
      </button>
    </Tooltip>
  </button>
</template>
