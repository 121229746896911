<script setup lang="ts">
import type { SerializedEditorState } from "lexical";
import { computed, ref } from "vue";

import Description from "~/components/task/description/Description.vue";
import Properties from "~/components/task/properties/Properties.vue";
import { EditorMode } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { usePageStore } from "~/stores";
import { isLexicalStateEmpty } from "~/utils/common";

const props = defineProps<{
  task: Task;
}>();

const emit = defineEmits<{
  addAttachments: [fileList: File[]];
}>();

const pageStore = usePageStore();

const properties = ref<InstanceType<typeof Properties> | null>(null);
const descriptionEditor = ref<InstanceType<typeof Description> | null>(null);

const publicAndEmpty = computed(() => pageStore.isPublicView && isLexicalStateEmpty(props.task.description));

const generatingRecommendations = computed(() => properties.value?.generatingPropertyRecommendations);

const generatePropertyRecommendations = (button: HTMLElement) =>
  properties.value?.generatePropertyRecommendations(button);

const jumpToDescription = () => descriptionEditor.value?.focus();

const improveDescription = () => descriptionEditor.value?.improveDescription();

const setDescription = (newDescription: SerializedEditorState) =>
  descriptionEditor.value?.setLexicalContent(newDescription);

const trimDescription = () => descriptionEditor.value?.trim();

defineExpose({
  generatePropertyRecommendations,
  generatingRecommendations,
  jumpToDescription,
  isDescriptionReady: computed(() => descriptionEditor.value?.isReady ?? false),
  improveDescription,
  setDescription,
  trimDescription,
});
</script>

<template>
  <div class="flex w-full flex-col gap-6">
    <Properties ref="properties" :task="task" :editor-mode="EditorMode.DETAIL" />
    <Description
      v-if="!publicAndEmpty"
      ref="descriptionEditor"
      :task="task"
      :editor-mode="EditorMode.DETAIL"
      @add-attachments="(files) => emit('addAttachments', files)" />
  </div>
</template>
