<script setup lang="ts">
import type { Component } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { SwapIcon, XIcon } from "~/icons";
import { CommandId } from "~/shared/enums";

defineProps<{
  label: string;
  selected: boolean;
  replaceEnabled: boolean;
  icon: Component;
  iconArgs: object;
}>();

const emit = defineEmits<{
  remove: [];
  replace: [];
}>();
</script>

<template>
  <div class="flex grow items-center justify-between overflow-hidden text-sm">
    <div class="flex items-center overflow-hidden">
      <component :is="icon" v-bind="iconArgs" class="mr-1 shrink-0" />
      <div class="flex items-center overflow-hidden rounded py-0.5 pl-1.5 pr-1" :class="selected && 'bg-md'">
        <span :title="label" class="select-none hyphens-auto break-words pr-0.5 text-md">
          {{ label }}
        </span>
        <div
          v-if="selected"
          class="flex items-center justify-center rounded hover:bg-hvy"
          @click.stop="() => emit('remove')"
          @keydown.enter.stop="() => emit('remove')">
          <XIcon class="cursor-pointer text-md icon-xs" />
        </div>
      </div>
    </div>

    <Tooltip v-if="replaceEnabled && !selected" :command-id="CommandId.SWAP_ASSIGNEE">
      <div
        class="flex items-center justify-center rounded icon-md hover:bg-hvy"
        @click.stop="emit('replace')"
        @keydown.enter.stop="emit('replace')">
        <SwapIcon class="cursor-pointer text-md icon-xs" />
      </div>
    </Tooltip>
  </div>
</template>
