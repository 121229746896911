<script setup lang="ts">
import { nextTick } from "vue";
import { type RouteLocationRaw, useRouter } from "vue-router";

import { useUserStore } from "~/stores";

const router = useRouter();
const userStore = useUserStore();

const { lastUrlPath } = userStore;
const newRoute: RouteLocationRaw = lastUrlPath ? { path: lastUrlPath } : { name: "home" };

nextTick(() => {
  router.push({ ...newRoute, query: router.currentRoute.value.query });
});
</script>

<template>
  <slot />
</template>
