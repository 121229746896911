<script setup lang="ts">
import { useLexicalComposer, useTableOfContents } from "lexical-vue";
import { computed, onMounted, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import { colorsByTheme } from "~/constants/style";
import { ButtonStyle } from "~/shared/enums";
import { usePageStore } from "~/stores";

const TAG_TO_WIDTH = new Map([
  ["h1", "w-4"],
  ["h2", "w-3"],
  ["h3", "w-2"],
]);
const TAG_TO_MARGIN = new Map([
  ["h2", "ml-3"],
  ["h3", "ml-6"],
]);

const editor = useLexicalComposer();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const display = ref(false);
const toc = useTableOfContents(editor);

const scrollTo = (key: string) => {
  const scroller = document.getElementsByClassName("dart-doc-wrapper")[0];
  const parent = scroller?.parentElement;
  const element = editor.getElementByKey(key);
  if (scroller === undefined || !parent || element === null) {
    return;
  }
  const parentRect = parent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  const elementTop = elementRect.top - parentRect.top;
  const elementBottom = elementRect.bottom - parentRect.top;

  const parentHeight = parent.clientHeight;

  const maxVisible = parentHeight - 300;

  if (elementTop < 0) {
    scroller.scrollBy({
      top: elementTop,
      behavior: "smooth",
    });
  } else if (elementBottom > maxVisible) {
    scroller.scrollBy({
      top: elementBottom - maxVisible,
      behavior: "smooth",
    });
  }
};

onMounted(() => {
  display.value = true;
});
</script>

<template>
  <Teleport v-if="display" to=".dart-doc-wrapper">
    <div class="absolute inset-y-0 right-4 flex max-h-full flex-col justify-around overflow-y-hidden">
      <div class="group/toc max-h-full">
        <div class="flex w-6 flex-col items-end gap-2 py-2 pl-2 group-hover/toc:hidden">
          <div
            v-for="tocElem in toc"
            :key="tocElem[0]"
            class="h-0.5 rounded bg-hvy"
            :class="TAG_TO_WIDTH.get(tocElem[2])" />
        </div>
        <div
          class="hidden max-h-full w-60 flex-col overflow-y-scroll rounded-lg py-4 pl-4 pr-px bg-lt group-hover/toc:flex"
          :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }">
          <Button
            v-for="tocElem in toc"
            :key="tocElem[0]"
            :text="tocElem[1]"
            :btn-style="ButtonStyle.SECONDARY"
            break-words
            borderless
            a11y-label="Header"
            class="min-h-[30px] !justify-start text-left hover:bg-md"
            :class="TAG_TO_MARGIN.get(tocElem[2])"
            @click="scrollTo(tocElem[0])" />
        </div>
      </div>
      <div />
    </div>
  </Teleport>
</template>
