import { createRouter, createWebHistory, type Router } from "vue-router";

import { usePageStore } from "~/stores";

import { checkNavigationRules } from "./common";
import ROUTES from "./routes";

const router: Router = createRouter({
  history: createWebHistory(""),
  routes: ROUTES,
});

router.beforeEach((to, from) => {
  if (!usePageStore().userDataLoaded) {
    return true;
  }
  return checkNavigationRules(to, from);
});

export default router;
