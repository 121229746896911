<script setup lang="ts">
import { computed } from "vue";

import { colorsByTheme } from "~/constants/style";
import Dart from "~/icons/Dart.vue";
import { usePageStore } from "~/stores";

const pageStore = usePageStore();

const show = computed(() => pageStore.redirectedToNative);

const continueInBrowser = () => {
  pageStore.redirectedToNative = false;
};

const colors = computed(() => colorsByTheme[pageStore.theme]);
</script>

<template>
  <Teleport to="body">
    <div
      v-if="show"
      class="fixed inset-0 z-[10030] flex flex-col items-center justify-evenly"
      :style="{ background: colors.bgStd }">
      <div class="flex flex-col items-center gap-1">
        <Dart class="mb-4 icon-xl" />
        <span class="text-md">Opening in the Dart app</span>
        <button type="button" class="text-sm underline text-vlt hover:text-lt" @click="continueInBrowser">
          Open here
        </button>
      </div>
      <div />
    </div>
  </Teleport>
</template>
