import type { StoreDefinition } from "pinia";

import pinia from "./pinia";
import HotModuleReload from "./plugins/HotReloadPlugin";
import StorePlugin from "./plugins/StorePlugin";
import UndoRedoPlugin from "./plugins/UndoRedoPlugin";
import * as allStores from "./stores";

export { default as pinia } from "./pinia";
export * from "./stores";

// Enable plugins.
Object.values(allStores).forEach((store) => HotModuleReload(store as StoreDefinition));
pinia.use(StorePlugin);
pinia.use(UndoRedoPlugin);
