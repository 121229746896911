<script setup lang="ts">
import { type Component, computed } from "vue";

import PageIconFromSvg from "~/components/dumb/PageIconFromSvg.vue";
import { normalizeEmoji } from "~/constants/iconAndEmoji";
import { colorsByTheme } from "~/constants/style";
import {
  DashboardIcon,
  HomeIcon,
  InboxIcon,
  MyTasksIcon,
  SearchIcon,
  TaskTypeFieldIcon,
  TrashIcon,
  UnsetDartboardIcon,
  UnsetDashboardIcon,
  UnsetDocIcon,
  UnsetFolderIcon,
  UnsetFormIcon,
  UnsetSpaceIcon,
  UnsetViewIcon,
  ViewIcon,
} from "~/icons";
import { IconKind, IconSize, PageKind, PagePseudoKind, ViewKind } from "~/shared/enums";
import type { IconConfig } from "~/shared/types";
import { usePageStore } from "~/stores";

const PAGE_KINDS_TO_UNSET_ICONS = new Map([
  [PageKind.DARTBOARD, UnsetDartboardIcon],
  [PageKind.DASHBOARD, UnsetDashboardIcon],
  [PageKind.DOC, UnsetDocIcon],
  [PageKind.FORM, UnsetFormIcon],
  [PageKind.FOLDER, UnsetFolderIcon],
  [PageKind.SPACE, UnsetSpaceIcon],
  [PageKind.VIEW, UnsetViewIcon],
]);

const DEFAULT_PAGE_KINDS_TO_ICONS = new Map<PagePseudoKind | ViewKind, Component>([
  [ViewKind.SEARCH, SearchIcon],
  [ViewKind.MY_TASKS, MyTasksIcon],
  [ViewKind.TRASH, TrashIcon],
  [PagePseudoKind.DASHBOARDS, DashboardIcon],
  [PagePseudoKind.HOME, HomeIcon],
  [PagePseudoKind.INBOX, InboxIcon],
  [PagePseudoKind.VIEWS, ViewIcon],
]);

const props = defineProps<{
  page: IconConfig;
  size?: IconSize;
}>();

const pageStore = usePageStore();

const isDefaultPage = computed(() => DEFAULT_PAGE_KINDS_TO_ICONS.has(props.page.kind as ViewKind | PagePseudoKind));
const defaultPageIcon = computed(() => DEFAULT_PAGE_KINDS_TO_ICONS.get(props.page.kind as ViewKind | PagePseudoKind));
const nonePageIcon = computed(() =>
  props.page.iconKind === IconKind.NONE
    ? props.page.pageKind
      ? PAGE_KINDS_TO_UNSET_ICONS.get(props.page.pageKind)
      : TaskTypeFieldIcon
    : undefined
);

const colors = computed(() => colorsByTheme[pageStore.theme]);
const color = computed(() => (isDefaultPage.value ? colors.value.textLt : props.page.colorHex));

const sizeStyle = computed(() =>
  props.size === IconSize.S ? "icon-sm" : props.size === IconSize.L ? "icon-lg" : "icon-md"
);
</script>

<template>
  <component :is="defaultPageIcon" v-if="isDefaultPage && defaultPageIcon" :class="sizeStyle" :style="{ color }" />
  <component :is="nonePageIcon" v-else-if="nonePageIcon" :class="sizeStyle" class="select-none" :style="{ color }" />
  <PageIconFromSvg
    v-else-if="page.iconKind === IconKind.ICON"
    :name="page.iconNameOrEmoji"
    :class="sizeStyle"
    :style="{ color }" />
  <div
    v-else
    class="shrink-0 select-none text-center font-emoji"
    :class="[sizeStyle, size === IconSize.S ? 'text-sm/4' : size === IconSize.L ? 'text-2xl/6' : 'text-xl/5']">
    {{ normalizeEmoji(page.iconNameOrEmoji) }}
  </div>
</template>
