<script setup lang="ts">
import Button from "~/components/dumb/Button.vue";
import { GoogleIcon, OutlookIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";

defineProps<{
  email: string | undefined;
}>();

const openGmail = () => {
  window.open("https://mail.google.com");
};

const openOutlook = () => {
  window.open("ms-outlook://emails");
};
</script>

<template>
  <!-- TODO emphasize one of the options more if it is more likely correct -->
  <div class="flex gap-2">
    <Button :btn-style="ButtonStyle.SECONDARY" text="Open Gmail" :icon="GoogleIcon" borderless @click="openGmail" />
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      text="Open Outlook"
      :icon="OutlookIcon"
      borderless
      @click="openOutlook" />
  </div>
</template>
