<script setup lang="ts">
import { nextTick } from "vue";
import type { RouteLocationNamedRaw } from "vue-router";
import { useRouter } from "vue-router";

import { useDataStore } from "~/stores";
import { getCommentLink, getDocLink, getTaskLink, getUserLink } from "~/utils/common";

type EntityKindAbbrev = "t" | "o" | "u" | "c";

const HOME = { name: "home" };

const props = defineProps<{
  entityKindAbbrev: EntityKindAbbrev;
  entityDuid: string;
  slugSep?: string;
  slug?: string;
}>();

const router = useRouter();
const dataStore = useDataStore();

const makeTaskLink = () => {
  const task = dataStore.getTaskByDuid(props.entityDuid);
  if (!task) {
    return HOME;
  }

  return getTaskLink(task);
};

const makeCommentLink = () => {
  const comment = dataStore.getCommentByDuid(props.entityDuid);
  if (!comment) {
    return HOME;
  }
  const task = dataStore.getTaskByDuid(comment.taskDuid);
  if (!task) {
    return HOME;
  }

  return getCommentLink(task, comment);
};

const makeDocLink = () => {
  const doc = dataStore.getDocByDuid(props.entityDuid);
  if (!doc) {
    return HOME;
  }

  return getDocLink(doc);
};

const makeUserLink = () => {
  const user = dataStore.getUserByDuid(props.entityDuid);
  if (!user) {
    return HOME;
  }

  return getUserLink(user);
};

const entityKindAbbrevToFunc: Record<EntityKindAbbrev, () => RouteLocationNamedRaw> = {
  t: makeTaskLink,
  c: makeCommentLink,
  o: makeDocLink,
  u: makeUserLink,
};

nextTick(() => {
  const newRoute = entityKindAbbrevToFunc[props.entityKindAbbrev]();
  newRoute.query = { ...router.currentRoute.value.query, ...newRoute.query };
  router.replace(newRoute);
});
</script>

<template>
  <slot />
</template>
