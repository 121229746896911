<script setup lang="ts">
import { computed } from "vue";

import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { EditorMode } from "~/shared/enums";
import type { PropertyUser } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  value: string[];
  ai?: boolean;
  isMultiple: boolean;
  property?: PropertyUser;
  editorMode: EditorMode;
  unsetLabel?: string;
}>();

const dataStore = useDataStore();
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const assigneeLabel = computed(() => {
  const user = dataStore.getUserByDuid(props.value[0]);
  if (props.ai || props.value.length !== 1 || !props.property?.adtl.isNameShown || !user) {
    return undefined;
  }

  return user.name || user.email;
});
</script>

<template>
  <component
    :is="isBoardMode ? Tooltip : Template"
    v-if="value.length !== 0"
    :text="isBoardMode ? property?.title : undefined">
    <div
      class="flex h-5 items-center justify-center gap-1 rounded border text-xs text-md border-oncolor"
      :class="isBoardMode && assigneeLabel ? 'pl-1 pr-1.5' : 'px-1'">
      <AvatarGroup
        :ai="ai"
        :duids="value"
        :editor-mode="editorMode"
        :limit="3"
        :first-user-only="!isMultiple"
        small
        :unset-label="unsetLabel" />

      <span v-if="assigneeLabel" class="truncate" :title="assigneeLabel">
        {{ assigneeLabel }}
      </span>
    </div>
  </component>
</template>
