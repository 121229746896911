import { DecoratorNode, type LexicalNode, type SerializedLexicalNode } from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import type { RecommendationKind } from "~/shared/enums";

import RecommendationLoading from "./RecommendationLoading.vue";

type SerializedRecommendationLoadingNode = SerializedLexicalNode & { kind: RecommendationKind };

export class RecommendationLoadingNode extends DecoratorNode<Component> {
  __kind: RecommendationKind;

  static getType(): string {
    return "recommendation-loading";
  }

  static clone(node: RecommendationLoadingNode): RecommendationLoadingNode {
    return new RecommendationLoadingNode(node.__kind, node.__key);
  }

  constructor(kind: RecommendationKind, key?: string) {
    super(key);
    this.__kind = kind;
  }

  getKind(): RecommendationKind {
    return this.__kind;
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.classList.add("contents");
    return div;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedRecommendationLoadingNode): LexicalNode {
    return new RecommendationLoadingNode(serializedNode.kind);
  }

  exportJSON(): SerializedRecommendationLoadingNode {
    return {
      type: RecommendationLoadingNode.getType(),
      kind: this.getKind(),
      version: 1,
    };
  }

  decorate(): Component {
    return h(RecommendationLoading, {
      nodeKey: this.getKey(),
      kind: this.getKind(),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): false {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }
}

export function $createRecommendationLoadingNode(kind: RecommendationKind): RecommendationLoadingNode {
  return new RecommendationLoadingNode(kind);
}

export function $isRecommendationLoadingNode(node?: LexicalNode): node is RecommendationLoadingNode {
  return node instanceof RecommendationLoadingNode;
}
