<script setup lang="ts">
import { computed, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { MagicWandIcon, MinusIcon, PlusIcon, UsersFieldIcon } from "~/icons";
import { BrainstormState, ButtonSize, ButtonStyle, ModalWidth } from "~/shared/enums";
import { useAppStore, useDataStore } from "~/stores";

const LENGTH_5 = "5";
const LENGTH_10 = "10";
const LENGTH_CUSTOM = "custom";

const appStore = useAppStore();
const dataStore = useDataStore();

const subject = ref("");
const ai = ref(true);
const sessionLengthType = ref(LENGTH_10);
const sessionLength = ref(parseInt(LENGTH_10, 10));

const actionDisabled = computed(() => subject.value === "");
const isEditing = computed(() => !!appStore.currentActiveBrainstorm);

const sessionTypes = computed(() =>
  [
    {
      title: "AI",
      description: "Set a timer and collaborate with teammates and AI to brainstorm",
      value: true,
      icon: MagicWandIcon,
    },
    {
      title: "Teammates",
      description: "Set a timer and collaborate with just teammates to brainstorm",
      value: false,
      icon: UsersFieldIcon,
    },
  ].map((option) => ({
    ...option,
    selected: ai.value === option.value,
  }))
);

const sessionLengthTypes = computed(() =>
  [
    {
      title: "5 min",
      value: LENGTH_5,
    },
    {
      title: "10 min",
      value: LENGTH_10,
    },
    {
      title: "Custom",
      value: LENGTH_CUSTOM,
    },
  ].map((option) => ({
    ...option,
    selected: sessionLengthType.value === option.value,
  }))
);

const selectLengthType = (value: string) => {
  sessionLengthType.value = value;
  switch (value) {
    case LENGTH_5: {
      sessionLength.value = 5;
      break;
    }
    case LENGTH_10: {
      sessionLength.value = 10;
      break;
    }
    default: {
      sessionLength.value = 10;
      break;
    }
  }
};

const decrement = () => {
  if (sessionLength.value === 1) {
    return;
  }
  sessionLength.value -= 1;
};

const increment = () => {
  sessionLength.value += 1;
};

watch(
  () => appStore.brainstormModalOpen,
  (open) => {
    if (!open || !appStore.currentDartboardDuid) {
      return;
    }

    const dartboard = dataStore.getDartboardByDuid(appStore.currentDartboardDuid);
    if (!dartboard) {
      return;
    }

    /* Editing current brainstorm */
    if (appStore.currentActiveBrainstorm) {
      subject.value = appStore.currentActiveBrainstorm.subject;
      ai.value = appStore.currentActiveBrainstorm.ai;
      sessionLengthType.value = LENGTH_CUSTOM;
      sessionLength.value = Math.ceil(appStore.currentActiveBrainstorm.afterStartMs / 60000);
      return;
    }

    subject.value = "";
    ai.value = true;
    sessionLengthType.value = LENGTH_10;
    sessionLength.value = 10;
  }
);

const onStart = () => {
  if (!appStore.currentDartboardDuid) {
    return;
  }

  dataStore.createBrainstorm(appStore.currentDartboardDuid, subject.value, ai.value, sessionLength.value * 60 * 1000);

  appStore.setPropertyDuidVisibility(dataStore.defaultCreatedByProperty.duid, true);

  appStore.setBrainstormModalOpen(false);
};
const onRestart = () => {
  if (!appStore.currentActiveBrainstorm) {
    return;
  }

  appStore.setBrainstormModalOpen(false);

  const sessionMs = sessionLength.value * 60 * 1000;
  dataStore.updateBrainstorm({
    duid: appStore.currentActiveBrainstorm.duid,
    subject: subject.value,
    ai: ai.value,
    totalSessionMs: sessionMs,
    afterStartMs: sessionMs,
    state: BrainstormState.RUNNING,
  });
};

const onClose = () => {
  appStore.setBrainstormModalOpen(false);
};
</script>

<template>
  <Modal
    :entity="appStore.brainstormModalOpen"
    title="Brainstorm tasks"
    :width="ModalWidth.M"
    description="Set a timer and brainstorm tasks for this project with your teammates and AI"
    @close="onClose">
    <template #default>
      <div class="mt-6 flex flex-col gap-6">
        <div class="flex flex-col gap-2">
          <div class="font-medium text-md">Subject</div>
          <textarea
            v-model="subject"
            aria-label="Subject"
            class="min-h-20 w-full rounded border text-sm bg-std text-md border-md placeholder:text-vlt hover:border-hvy"
            placeholder="Describe the project that you're brainstorming about" />
        </div>

        <div class="flex flex-col gap-2">
          <div class="font-medium text-md">Use AI</div>
          <RadioCardGroup vertical show-radios :items="sessionTypes" @select="(value) => (ai = value)" />
        </div>

        <div class="flex flex-col gap-2">
          <div class="font-medium text-md">Time limit</div>
          <RadioCardGroup :items="sessionLengthTypes" show-radios small-gaps @select="selectLengthType" />
          <div v-if="sessionLengthType === LENGTH_CUSTOM" class="flex gap-1">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.LARGE"
              :icon="MinusIcon"
              :disabled="sessionLength === 1"
              a11y-label="Decrement"
              @click="decrement" />
            <div
              class="flex flex-1 select-none items-center justify-center rounded border p-[7px] text-sm font-normal text-lt border-hvy">
              {{ `${sessionLength} min` }}
            </div>
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.LARGE"
              :icon="PlusIcon"
              a11y-label="Increment"
              @click="increment" />
          </div>
          <div v-else class="min-h-[36px]" />
        </div>
      </div>
    </template>

    <template #actions>
      <div class="mt-4 flex gap-2">
        <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" is-contrast @click="onClose" />
        <Tooltip :disabled="!actionDisabled" text="Enter the brainstorm subject">
          <Button
            :btn-style="ButtonStyle.RECOMMENDATION"
            :text="isEditing ? 'Resume brainstorming' : 'Start brainstorming'"
            is-contrast
            :disabled="actionDisabled"
            @click="() => (isEditing ? onRestart() : onStart())" />
        </Tooltip>
      </div>
    </template>
  </Modal>
</template>
