<script setup lang="ts">
import { computed, ref } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import { GITHUB_LINK_KINDS } from "~/constants/link";
import {
  AlertIcon,
  CopyIcon,
  DotsHorizontalIcon,
  EditIcon,
  GithubIcon,
  LinkFieldIcon,
  NotionIcon,
  SlackIcon,
  TrashIcon,
} from "~/icons";
import { DropdownMenuItemKind, Placement, TaskLinkKind, ViewKind } from "~/shared/enums";
import type { Task, TaskLink } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getNotionPageId } from "~/utils/common";

const NOTION_ERROR_LINK_KIND_MAP = new Map([
  [
    TaskLinkKind.NOTION_DOCUMENT_DOESNT_EXIST,
    "This Notion document link was set as the description for this task but it either doesn't exist or Dart doesn't have access to it yet. Check that the link is correct, then check that the Dart integration has access to it by clicking the three dots in the top right of the page in Notion then clicking 'Add connections' and choosing Dart!",
  ],
  [
    TaskLinkKind.NOTION_DOCUMENT_PARSE_FAILED,
    "This Notion document link was set as the description for this task but Dart can't display this kind of content yet. Click here to convert this to a standard link, or get in touch with us at support@itsdart.com to request improvements!",
  ],
]);

const props = defineProps<{
  task: Task;
  link: TaskLink;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);

const copyLink = () => copyAndNotify("Link URL", props.link.url, props.link.title || undefined);

const generateNormalDropdownSections = (link: TaskLink) => [
  {
    title: "Link actions",
    items: [
      {
        title: "Convert to description",
        kind: DropdownMenuItemKind.BUTTON,
        hidden:
          !tenantStore.notionIntegration?.enabled || !!props.task.notionDocument || getNotionPageId(link.url) === null,
        icon: NotionIcon,
        onClick: () => {
          const pageId = getNotionPageId(link.url);
          if (!pageId) {
            return;
          }
          dataStore.deleteLink(props.task.duid, link.duid);
          dataStore.addNotionDocument(props.task.duid, pageId);
        },
      },
      {
        title: "Edit link",
        kind: DropdownMenuItemKind.BUTTON,
        hidden: GITHUB_LINK_KINDS.has(link.kind),
        icon: EditIcon,
        onClick: () => appStore.setLinkOpenInModal({ taskDuid: props.task.duid, link }),
      },
      {
        title: "Remove link",
        kind: DropdownMenuItemKind.BUTTON,
        hidden: GITHUB_LINK_KINDS.has(link.kind),
        icon: TrashIcon,
        onClick: () => dataStore.deleteLink(props.task.duid, link.duid),
      },
    ],
  },
];

const generateAlertDropdownSections = (link: TaskLink) => [
  {
    title: "Warning actions",
    items: [
      {
        title: "Remove warning",
        kind: DropdownMenuItemKind.BUTTON,
        icon: LinkFieldIcon,
        onClick: () => {
          dataStore.updateLink(props.task.duid, { duid: link.duid, kind: TaskLinkKind.STANDARD });
        },
      },
    ],
  },
];

const iconLoadError = ref(false);

const onIconLoadError = () => {
  iconLoadError.value = true;
};

const sections = [
  ...(!pageStore.isPublicView && !isTrash.value ? generateNormalDropdownSections(props.link) : []),
  ...(!pageStore.isPublicView && NOTION_ERROR_LINK_KIND_MAP.has(props.link.kind)
    ? generateAlertDropdownSections(props.link)
    : []),
];

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, sections);
};
</script>

<template>
  <Tooltip :text="link.url" block>
    <a
      :href="link.url"
      target="_blank"
      rel="noopener noreferrer"
      class="flex min-h-[36px] w-full cursor-pointer items-center justify-between gap-2 rounded border py-1.5 pl-2 pr-1.5 text-sm text-md border-lt hover:bg-lt hover:border-md"
      @contextmenu="onContextMenu">
      <div class="flex w-11/12 flex-col gap-1">
        <div class="flex items-center gap-2 overflow-hidden">
          <GithubIcon v-if="GITHUB_LINK_KINDS.has(link.kind)" class="select-none icon-lg" />
          <NotionIcon v-else-if="link.kind === TaskLinkKind.NOTION_EXPANSION" class="select-none icon-lg" />
          <SlackIcon v-else-if="link.kind === TaskLinkKind.SLACK_EXPANSION" class="select-none icon-lg" />
          <img
            v-else-if="link.iconUrl && !iconLoadError"
            :src="link.iconUrl"
            alt="Link favicon"
            class="select-none rounded-sm border-none object-cover icon-lg"
            @error="onIconLoadError" />
          <LinkFieldIcon v-else class="text-lt icon-lg" />
          <span class="select-none overflow-hidden hyphens-auto break-words">{{ link.title || link.url }}</span>
        </div>
        <span v-if="link.adtl.description" class="truncate text-lt">
          {{ link.adtl.description }}
        </span>
      </div>

      <div class="flex items-center gap-1">
        <DropdownMenu
          v-if="!pageStore.isPublicView && NOTION_ERROR_LINK_KIND_MAP.has(link.kind)"
          :sections="generateAlertDropdownSections(link)"
          :placement="Placement.BOTTOM_RIGHT"
          @click.prevent
          @keydown.enter.prevent>
          <Tooltip :text="NOTION_ERROR_LINK_KIND_MAP.get(link.kind)">
            <button
              type="button"
              class="flex items-center rounded p-0.5 text-warning-base focus-ring-warning hover:bg-md print:hidden">
              <span class="sr-only">Notion invalid link</span>
              <AlertIcon class="icon-sm" />
            </button>
          </Tooltip>
        </DropdownMenu>
        <Tooltip text="Copy link">
          <button
            type="button"
            class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md print:hidden"
            @click.prevent="copyLink"
            @keydown.enter.prevent="copyLink">
            <span class="sr-only">Copy link</span>
            <CopyIcon class="icon-sm" />
          </button>
        </Tooltip>
        <DropdownMenu
          v-if="!pageStore.isPublicView && !isTrash"
          :sections="generateNormalDropdownSections(link)"
          :placement="Placement.BOTTOM_RIGHT"
          :distance="2"
          @click.stop.prevent
          @keydown.enter.stop.prevent>
          <Tooltip text="Modify link">
            <button
              type="button"
              class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md print:hidden">
              <span class="sr-only">Modify link</span>
              <DotsHorizontalIcon class="icon-sm" />
            </button>
          </Tooltip>
        </DropdownMenu>
      </div>
    </a>
  </Tooltip>
</template>
