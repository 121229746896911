import type { Option, OptionUpdate, Property } from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";
import { optionComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  optionList: Option[];
  getOptionList: (property: Property) => Option[];
  getOptionByDuid: (duid: string) => Option | undefined;
  getOptionsByDuidsOrdered: (duids: string[]) => Option[];
  getOptionByTitle: (title: string, property: Property, parentDuid?: string | null) => Option | undefined;
};

export type Actions = {
  /* Create a new option. */
  createOption: (
    title: string,
    property: Property,
    partialOption?: Partial<Option>,
    options?: { awaitBackend?: boolean }
  ) => Promise<Option | undefined>;
  /* Update an existing option. */
  updateOption: (update: OptionUpdate, options?: { awaitBackend?: boolean }) => Promise<Option | undefined>;
  /** Delete a option. */
  deleteOption: (option: Option, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Create a option without changing the backend.
   * @PRIVATE */
  $createOptionNoBackend: (title: string, property: Property, partialOption?: Partial<Option>) => Option | undefined;
  /** Sort options.
   * @PRIVATE */
  $sortOptions: () => void;
  /** Set options internally.
   * @PRIVATE */
  $setOptions: (options: Option[]) => void;
  /** Create or update option from WS.
   * @PRIVATE */
  $createOrUpdateOption: (option: Option) => void;
  /** Delete option from WS.
   * @PRIVATE */
  $deleteOption: (option: Option) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  optionList() {
    return Array.from(this._duidsToOptions.values());
  },
  getOptionList() {
    return (property) => this.optionList.filter((e) => e.propertyDuid === property.duid);
  },
  getOptionByDuid() {
    return (duid) => this._duidsToOptions.get(duid);
  },
  getOptionsByDuidsOrdered() {
    return (duids) => this.optionList.filter((e) => duids.includes(e.duid));
  },
  getOptionByTitle() {
    return (title, property, parentDuid) => {
      const normTitle = title.trim().toLowerCase();
      return this.getOptionList(property).find(
        (e: Option) => (!parentDuid || parentDuid === e.parentDuid) && e.title.toLowerCase() === normTitle
      );
    };
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createOption(title, property, partialOption = {}, options = {}) {
    const { awaitBackend = false } = options;
    const option = this.$createOptionNoBackend(title, property, partialOption);
    if (!option) {
      return undefined;
    }

    const backendAction = this.$backend.option.create(option);
    if (awaitBackend) {
      await backendAction;
    }
    return option;
  },
  async updateOption(update, options = {}) {
    const { awaitBackend = false } = options;
    const option = this.getOptionByDuid(update.duid);
    if (!option) {
      return undefined;
    }

    Object.assign(option, update);
    this.$sortOptions();

    const backendAction = this.$backend.option.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return option;
  },
  async deleteOption(option, options = {}) {
    const { awaitBackend = false } = options;

    this.$deleteOption(option);
    const layoutUpdates = this.$updateLayoutsRemoveEntityDuidOrFiltersNoBackend([option.duid], [option.propertyDuid]);

    const backendAction = this.$backend.option.delete(option.duid, layoutUpdates);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $createOptionNoBackend(title, property, partialOption = {}) {
    const { parentDuid } = partialOption;
    if (title === "" || this.getOptionByTitle(title, property, parentDuid) !== undefined) {
      return undefined;
    }

    const option: Option = {
      duid: makeDuid(),
      propertyDuid: property.duid,
      parentDuid: null,
      title: title.trim(),
      colorHex: makeRandomColorHex(),
      ...partialOption,
    };

    this.$createOrUpdateOption(option);

    return option;
  },
  $sortOptions() {
    this._duidsToOptions = new Map([...this._duidsToOptions].sort((a, b) => optionComparator(a[1], b[1])));
  },
  $setOptions(options) {
    this._duidsToOptions = new Map(options.map((e) => [e.duid, e]));
    this.$sortOptions();
  },
  $createOrUpdateOption(option) {
    const currentOption = this.getOptionByDuid(option.duid);

    if (!currentOption) {
      this._duidsToOptions.set(option.duid, option);
    } else {
      Object.assign(currentOption, option);
    }

    this.$sortOptions();
  },
  $deleteOption(option) {
    this._duidsToOptions.delete(option.duid);
  },
};

export { actions, getters };
