import { Language } from "~/shared/enums";

export const LANGUAGE_SET = new Set(Object.values(Language));

// spell-checker:disable
export const LANGUAGE_TO_DISPLAY_NAME = {
  [Language.ENGLISH]: "English",
  [Language.MANDARIN_CHINESE]: "普通话",
  [Language.HINDI]: "हिंदी",
  [Language.SPANISH]: "Español",
  [Language.ARABIC]: "العربية",
  [Language.FRENCH]: "Français",
  [Language.BENGALI]: "বাংলা",
  [Language.RUSSIAN]: "Русский",
  [Language.PORTUGUESE]: "Português",
  [Language.SWAHILI]: "Kiswahili",
  [Language.INDONESIAN]: "Bahasa Indonesia",
  [Language.URDU]: "اُردُو",
  [Language.JAPANESE]: "日本語",
  [Language.GERMAN]: "Deutsch",
  [Language.PUNJABI]: "ਪੰਜਾਬੀ",
  [Language.FARSI_PERSIAN]: "فارسی",
  [Language.JAVANESE]: "Basa Jawa",
  [Language.WU_CHINESE]: "吴语",
  [Language.VIETNAMESE]: "Tiếng Việt",
  [Language.ITALIAN]: "Italiano",
  [Language.TURKISH]: "Türkçe",
  [Language.MARATHI]: "मराठी",
  [Language.TELUGU]: "తెలుగు",
  [Language.TAMIL]: "தமிழ்",
  [Language.KOREAN]: "한국어",
  [Language.JIN_CHINESE]: "晋语",
  [Language.HAUSA]: "هَوُسَ",
  [Language.THAI]: "ไทย",
  [Language.YUE_CHINESE_CANTONESE]: "粤语",
  [Language.GUJARATI]: "ગુજરાતી",
  [Language.BHOJPURI]: "भोजपुरी",
};
// spell-checker:enable
