<script setup lang="ts">
import { computed, ref } from "vue";

import AttachmentEditor from "~/components/dumb/AttachmentEditor.vue";
import AttachmentInputButton from "~/components/dumb/AttachmentInputButton.vue";
import { EditorMode } from "~/shared/enums";
import type { Attachment } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

const props = defineProps<{
  attachmentDuids: string[];
  isSubmitMode?: boolean;
}>();

const emit = defineEmits<{
  update: [value: string[]];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const createdAttachments = ref<Attachment[]>([]);
const attachments = computed(() => [
  ...dataStore.getAttachmentsByDuidsOrdered(props.attachmentDuids),
  ...createdAttachments.value,
]);

const addAttachments = async (fileList: File[]) => {
  const orders = getOrdersBetween(
    dataStore.attachmentList[dataStore.attachmentList.length - 1]?.order,
    undefined,
    fileList.length
  );
  const options: { formDuid?: string; noBackend?: boolean } = {};
  if (pageStore.isPublicForm) {
    options.formDuid = dataStore.formList[0].duid;
    options.noBackend = true;
  }
  const res = await dataStore.createAttachments(
    fileList.map((file, index) => ({ file, order: orders[index] })),
    options
  );
  const newAttachments = res.map(({ attachment }) => attachment);
  if (pageStore.isPublicForm) {
    createdAttachments.value = [...createdAttachments.value, ...newAttachments];
    appStore.pendingAttachmentCreates = [...appStore.pendingAttachmentCreates, ...res.map(({ create }) => create)];
  }
  const updatedDuidSet = new Set([...newAttachments.map(({ duid }) => duid), ...props.attachmentDuids]);
  emit("update", [...updatedDuidSet]);
};

const removeAttachment = (attachment: Attachment) => {
  if (pageStore.isPublicForm) {
    createdAttachments.value = createdAttachments.value.filter((a) => a.duid !== attachment.duid);
    appStore.pendingAttachmentCreates = appStore.pendingAttachmentCreates.filter((e) => e.duid !== attachment.duid);
  } else {
    dataStore.deleteAttachment(attachment);
  }
  emit(
    "update",
    props.attachmentDuids.filter((duid) => duid !== attachment.duid)
  );
};
</script>

<template>
  <div
    class="flex w-full flex-col rounded"
    :class="{
      'border border-md hover:bg-lt': attachments.length === 0 || !isSubmitMode,
    }">
    <AttachmentInputButton
      v-if="!props.isSubmitMode || attachments.length === 0"
      :editor-mode="EditorMode.FORM"
      :num-of-attachments="attachments.length"
      @input="addAttachments" />

    <AttachmentEditor
      v-else
      class="-ml-3"
      :attachments="attachments"
      @add="addAttachments"
      @remove="removeAttachment" />
  </div>
</template>
