<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import DatePicker from "~/components/dumb/DatePicker.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { filterHasApplicability } from "~/shared/common";
import { CommandId, EditorMode, FilterApplicability } from "~/shared/enums";
import type { DateRange, Filter, FilterDefinition, FilterValue } from "~/shared/types";

import Chip from "./Chip.vue";

const props = defineProps<{
  filter: Filter;
  definition: FilterDefinition | undefined;
  enabled: boolean;
  readOnly?: boolean;
}>();

const emit = defineEmits<{
  select: [values: FilterValue[]];
  afterClose: [];
  removeFilter: [];
  replaceFilter: [];
}>();

const picker = ref<InstanceType<typeof DatePicker> | null>(null);

const isRange = computed(
  () => filterHasApplicability(props.filter) && props.filter.applicability === FilterApplicability.IS_BETWEEN
);
const values = computed(() => {
  const current = props.filter.values ?? [];
  if (current.length === 0) {
    return [null, null];
  }
  return current[0] as DateRange;
});
const selection = computed(() =>
  isRange.value
    ? {
        start: values.value.length > 0 ? (values.value[0] as string) : null,
        end: values.value.length > 1 ? (values.value[1] as string) : null,
      }
    : values.value.length > 0
      ? (values.value[0] as string)
      : null
);

// Open picker if date unspecified on applicability change.
watch(
  () => isRange.value,
  (range) => {
    if (range && (values.value.length !== 2 || values.value.some((v) => v === null))) {
      picker.value?.open();
    }
  }
);

const onSelect = (value: string | null) => {
  // Close on clear.
  if (value === null) {
    emit("select", []);

    return;
  }

  emit("select", [[value, null]]);
};

const onSelectRange = (range: { start?: string | null; end?: string | null }) => {
  // Close on clear.
  if (!range.start && !range.end) {
    emit("select", []);

    return;
  }

  emit("select", [[range.start ?? null, range.end ?? null]]);
};

const onAfterClose = () => {
  // Close if range not set fully.
  if (isRange.value && (values.value.length !== 2 || values.value.some((v) => v === null))) {
    emit("select", []);

    nextTick(() => {
      emit("afterClose");
    });
    return;
  }

  emit("afterClose");
};

const open = () => {
  if (props.readOnly) {
    return;
  }
  picker.value?.open();
};

defineExpose({
  open,
});
</script>

<template>
  <DatePicker
    ref="picker"
    :value="selection"
    :editor-mode="EditorMode.FILTER"
    :disabled="!enabled || filter.locked"
    :distance="-1"
    :skidding="-1"
    cover
    :propagate-click-classes="['dart-filter-applicability', 'dart-filter-field']"
    class="flex h-full items-center justify-center"
    @select="onSelect"
    @select-range="onSelectRange"
    @after-close="onAfterClose">
    <div class="flex h-full flex-nowrap items-center justify-center gap-1">
      <Tooltip :command-id="CommandId.CHANGE_FILTER_VALUES" :disabled="filter.locked">
        <Chip
          v-if="selection"
          :values="
            isRange && selection && typeof selection !== 'string'
              ? [selection.start, selection.end]
              : [selection as string | null]
          " />
      </Tooltip>
    </div>
  </DatePicker>
</template>
