<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";
import { computed } from "vue";
import { RouterView } from "vue-router";

import AppMetaManager from "~/components/dumb/AppMetaManager.vue";
import ContextMenu from "~/components/dumb/ContextMenu.vue";
import FeedbackTooltip from "~/components/dumb/FeedbackTooltip.vue";
import Loading from "~/components/dumb/Loading.vue";
import TaskPreviewTooltip from "~/components/dumb/TaskPreviewTooltip.vue";
import NativeRedirectOverlay from "~/components/NativeRedirectOverlay.vue";
import NotificationList from "~/components/notifications/NotificationList.vue";
import { colorsByTheme } from "~/constants/style";
import { useEnvironmentStore, usePageStore } from "~/stores";

const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();

const showOfflineBanner = computed(() => !pageStore.isOnline && !environmentStore.isLocal);
const colors = computed(() => colorsByTheme[pageStore.theme]);
const transparentBg = pageStore.isDesktopApp && pageStore.isMac && pageStore.isVersionGreaterOrEqual("1.0.2");

// TODO popper remove this once we remove floating-vue
document.addEventListener("scroll", () => {
  if (document.documentElement.scrollTop !== 0) {
    document.documentElement.scrollTop = 0;
  }
});

// Resize page when keyboard is shown on mobile
if (pageStore.isMobile) {
  window.visualViewport?.addEventListener("resize", () => {
    document.documentElement.style.height = `${window.visualViewport?.height}px`;
  });
}
</script>

<template>
  <div
    class="flex h-full flex-col subpixel-antialiased bg-std selection:bg-indigo-200 dark:selection:bg-indigo-900"
    :style="{ '--background': colors.bgStd, '--highlight': colors.highlight }">
    <TransitionRoot
      :show="!(pageStore.userDataLoaded && pageStore.pageLoaded)"
      class="absolute left-0 top-0 z-50 h-screen w-screen"
      leave="ease-in duration-300"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <Loading :show-timing="pageStore.showDebugInfo" :transparent-bg="transparentBg" />
    </TransitionRoot>
    <RouterView v-if="pageStore.userDataLoaded" :class="showOfflineBanner && 'min-h-0'" />
    <div
      v-if="showOfflineBanner"
      class="flex h-7 w-full items-center justify-center gap-6 bg-primary-base px-4 text-sm text-oncolor">
      <span class="font-bold uppercase">offline</span>
      Changes will sync when Dart reconnects
    </div>
    <AppMetaManager />
    <ContextMenu />
    <FeedbackTooltip />
    <NotificationList />
    <TaskPreviewTooltip />
    <!-- Native overlay should be last so it's on top -->
    <NativeRedirectOverlay />
  </div>
</template>

<style>
/* Scrollbar styles */
::-webkit-scrollbar {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border: 4px solid var(--background);
  background: var(--highlight);
  border-radius: 10px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* Textarea styles */
textarea.resize-none {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
textarea.resize-none::-webkit-scrollbar {
  display: none;
}

#commandbar-wrapper * {
  font-family: "Inter var" !important;
}
/* Remove commandbar branding */
.commandbar-content-container > div:last-child {
  display: none;
}
#commandbar-menu__wrapper {
  border-radius: 0 0 10px 10px;
}
/* Dark mode colors */
.dark #commandbar-wrapper {
  --content-primary: red;
}
.dark #commandbar__control_wrapper,
.dark .commandbar-content-container {
  background-color: #1e1e21;
  border-color: #27272a;
}
.dark
  #commandbar-menu__wrapper
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child {
  background-color: #1e1e21 !important;
}
.dark
  #commandbar-menu__wrapper
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child
  > div:first-child,
.dark #commandbar_menu_list div[aria-label^="category"] div:first-child {
  background-color: unset !important;
}
</style>
