import { DecoratorNode, type LexicalNode, type SerializedLexicalNode } from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import ToggleButton from "./ToggleButton.vue";
import type { ToggleWrapperNode } from "./ToggleWrapperNode";

export type SerializedToggleButtonNode = SerializedLexicalNode;

// TODO bigger refactor to hoist more business logic out into a plugin so that these nodes don't need to import each other
function $isToggleWrapperNode(node: LexicalNode | null): node is ToggleWrapperNode {
  return node?.getType() === "toggle-wrapper";
}

export class ToggleButtonNode extends DecoratorNode<Component> {
  __initialized = false;

  __open = true;

  __hasContent = false;

  static getType(): string {
    return "toggle-button";
  }

  static clone(node: ToggleButtonNode): ToggleButtonNode {
    return new ToggleButtonNode(node.__key);
  }

  constructor(key?: string) {
    super(key);

    const wrapper = this.getParent();
    if (!wrapper || !$isToggleWrapperNode(wrapper)) {
      return;
    }

    this.__initialized = true;
    this.__open = wrapper.getOpen();
    this.__hasContent = wrapper.getHasContent();
  }

  getOpen(): boolean {
    return this.__open;
  }

  setOpen(open: boolean): void {
    const writable = this.getWritable();
    writable.__open = open;
  }

  getHasContent(): boolean {
    return this.__hasContent;
  }

  setHasContent(hasContent: boolean): void {
    const writable = this.getWritable();
    writable.__hasContent = hasContent;
  }

  initialize(): void {
    if (this.__initialized) {
      return;
    }
    const wrapper = this.getParent();
    if (!wrapper || !$isToggleWrapperNode(wrapper)) {
      return;
    }

    this.__initialized = true;
    const writable = this.getWritable();
    writable.__open = wrapper.getOpen();
    writable.__hasContent = wrapper.getHasContent();
  }

  createDOM(): HTMLElement {
    this.initialize();
    const element = document.createElement("div");
    element.classList.add("contents");
    return element;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(): LexicalNode {
    return new ToggleButtonNode();
  }

  exportJSON(): SerializedToggleButtonNode {
    return {
      type: this.getType(),
      version: 1,
    };
  }

  decorate(): Component {
    return h(ToggleButton, {
      open: this.getOpen(),
      hasContent: this.getHasContent(),
      nodeKey: this.getKey(),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): true {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }
}

export function $createToggleButton(): ToggleButtonNode {
  return new ToggleButtonNode();
}

export function $isToggleButton(node: LexicalNode | null): node is ToggleButtonNode {
  return node instanceof ToggleButtonNode;
}
