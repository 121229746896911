/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Other` - OTHER
 * * `Default` - DEFAULT
 * * `Reports` - REPORTS
 */
export enum FolderKind {
  OTHER = 'Other',
  DEFAULT = 'Default',
  REPORTS = 'Reports',
}
