/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `sum` - SUM
 * * `avg` - AVG
 * * `count` - COUNT
 */
export enum ChartAggregation {
  SUM = 'sum',
  AVG = 'avg',
  COUNT = 'count',
}
