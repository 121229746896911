<script setup lang="ts">
import { computed } from "vue";

import { LayoutKind, ViewKind } from "~/shared/enums";
import { useAppStore } from "~/stores";

import Board from "./board/Board.vue";
import Calendar from "./calendar/Calendar.vue";
import List from "./list/List.vue";
import Roadmap from "./roadmap/Roadmap.vue";
import TaskListFooter from "./TaskListFooter.vue";

defineProps<{
  id?: string;
}>();

const appStore = useAppStore();

const isSearch = computed(() => appStore.currentPage?.kind === ViewKind.SEARCH);
const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
</script>

<template>
  <div :id="id" class="relative flex size-full flex-col">
    <div class="relative flex w-full grow flex-col">
      <List />
      <Board v-if="appStore.layout?.kind === LayoutKind.BOARD && !isSearch && !isTrash" />
      <Calendar v-if="appStore.layout?.kind === LayoutKind.CALENDAR && !isSearch && !isTrash" />
      <Roadmap v-if="appStore.layout?.kind === LayoutKind.ROADMAP && !isSearch && !isTrash" />
    </div>
    <TaskListFooter />
  </div>
</template>
