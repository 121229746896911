<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import { SprintRolloverIcon } from "~/icons";
import { makeLinkToSpaceSettingsPageRef } from "~/router/common";
import type { Space } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const props = defineProps<{
  space: Space;
}>();

const emit = defineEmits<{
  rollover: [];
  editSpace: [space: Space];
}>();

const active = computed(() => props.space.duid === router.currentRoute.value.query.space);
const link = computed(() => makeLinkToSpaceSettingsPageRef(props.space.duid, "basics").value);

const rolloverDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const dialogTitle = computed(() => "Start next sprint");
const dialogDescription = computed(
  () =>
    "Starting the next sprint will move next tasks to active and active tasks into archive, but tasks that are not finished or canceled stay in active. This can't be undone."
);

const openRolloverDialog = () => {
  rolloverDialog.value?.openModal();
};
const rollover = () => {
  dataStore.rollover(props.space.duid);
};

const spaceMetaDropdownSections = () =>
  computed(() =>
    actions.context.page(props.space, false, (eventKind: string) => {
      switch (eventKind) {
        case "startNextSprint": {
          openRolloverDialog();
          break;
        }
        case "startEditingSpace": {
          emit("editSpace", props.space);
          router.replace(makeLinkToSpaceSettingsPageRef(props.space.duid, "basics").value);
          break;
        }
        default: {
          throw new Error(`Unknown event kind: ${eventKind}`);
        }
      }
    })
  );

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, spaceMetaDropdownSections().value);
};
</script>

<template>
  <ConfirmationDialog
    ref="rolloverDialog"
    cancel-text="Cancel"
    confirm-text="Proceed"
    :icon="SprintRolloverIcon"
    :title="dialogTitle"
    :description="dialogDescription"
    @confirm="rollover" />
  <RouterLink
    :key="space.duid"
    :to="link"
   
    :class="active ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
    class="flex select-none items-center rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt"
    @contextmenu="onContextMenu">
    <PageIcon :page="space" class="mr-1.5 icon-sm" />
    <span class="truncate">{{ space.title }}</span>
  </RouterLink>
</template>
