import { DEFAULT_BLOCK_COLOR } from "~/constants/style";
import {
  DartboardKind,
  DashboardKind,
  FolderKind,
  IconKind,
  PageKind,
  PagePseudoKind,
  SpaceKind,
  ViewKind,
} from "~/shared/enums";
import type { DashboardsRoot, Home, Inbox, NotFound, SpecificPageKind, ViewsRoot } from "~/shared/types";

export const PAGES_PARTIALLY_EDITABLE = new Set<SpecificPageKind>([
  DartboardKind.FINISHED,
  DartboardKind.CUSTOM,
  FolderKind.DEFAULT,
  FolderKind.OTHER,
  FolderKind.REPORTS,
  SpaceKind.WORKSPACE,
  SpaceKind.OTHER,
  SpaceKind.PERSONAL,
  ViewKind.CUSTOM,
  DashboardKind.DASHBOARD,
]);

export const EXCLUDED_VIEW_KINDS = new Set([ViewKind.SEARCH, ViewKind.TRASH]);

export const FILTERS_PSEUDO_SECTION_KEY = "@filters";
export const FAVORITES_PSEUDO_SECTION_KEY = "@favorites";
export const SPACES_PSEUDO_SECTION_KEY = "@spaces";
export const ARCHIVE_PSEUDO_SECTION_KEY_SUFFIX = "/@archive";
export const FOOTER_PSUEDO_SECTION_KEY = "@footer";
export const INTELLIGENCE_PSEUDO_SECTION_KEY = "@intelligence";

export const NOT_FOUND_PSEUDO_PAGE_DUID = "@not-found";
export const NOT_FOUND_PSEUDO_PAGE: NotFound = {
  duid: NOT_FOUND_PSEUDO_PAGE_DUID,
  kind: PagePseudoKind.NOT_FOUND,
  pageKind: PageKind.NOT_FOUND,
  order: "",
  title: "Not found",
  description: "",
  iconKind: IconKind.ICON,
  iconNameOrEmoji: "notfound",
  colorHex: DEFAULT_BLOCK_COLOR,
};

export const HOME_PSEUDO_PAGE_DUID = "@home";
export const HOME_PSEUDO_PAGE: Home = {
  duid: HOME_PSEUDO_PAGE_DUID,
  kind: PagePseudoKind.HOME,
  pageKind: PageKind.HOME,
  order: "",
  title: "Home",
  description: "",
  iconKind: IconKind.ICON,
  iconNameOrEmoji: "home",
  colorHex: DEFAULT_BLOCK_COLOR,
};

export const INBOX_PSEUDO_PAGE_DUID = "@inbox";
export const INBOX_PSEUDO_PAGE: Inbox = {
  duid: INBOX_PSEUDO_PAGE_DUID,
  kind: PagePseudoKind.INBOX,
  pageKind: PageKind.INBOX,
  order: "",
  title: "Inbox",
  description: "",
  iconKind: IconKind.ICON,
  iconNameOrEmoji: "inbox",
  colorHex: DEFAULT_BLOCK_COLOR,
};

export const DASHBOARDS_PSEUDO_PAGE_DUID = "@dashboards";
export const DASHBOARDS_PSEUDO_PAGE: DashboardsRoot = {
  duid: DASHBOARDS_PSEUDO_PAGE_DUID,
  kind: PagePseudoKind.DASHBOARDS,
  pageKind: PageKind.DASHBOARDS_ROOT,
  order: "",
  title: "Dashboards",
  description: "",
  iconKind: IconKind.ICON,
  iconNameOrEmoji: "dashboard",
  colorHex: DEFAULT_BLOCK_COLOR,
};

export const VIEWS_PSEUDO_PAGE_DUID = "@views";
export const VIEWS_PSEUDO_PAGE: ViewsRoot = {
  duid: VIEWS_PSEUDO_PAGE_DUID,
  kind: PagePseudoKind.VIEWS,
  pageKind: PageKind.VIEWS_ROOT,
  order: "",
  title: "Views",
  description: "",
  iconKind: IconKind.ICON,
  iconNameOrEmoji: "view",
  colorHex: DEFAULT_BLOCK_COLOR,
};
