<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import { XIcon } from "~/icons";
import { NotificationType } from "~/shared/enums";
import type { INotification } from "~/shared/types";

const NOTIFICATION_TYPE_TO_COLOR_MAP = {
  [NotificationType.INFO]: "bg-primary-base",
  [NotificationType.SUCCESS]: "bg-success-base",
  [NotificationType.WARNING]: "bg-warning-base",
  [NotificationType.ERROR]: "bg-danger-base",
};

const props = defineProps<{
  notification: INotification;
  dismiss: () => void;
  hovering: boolean;
}>();

const type = computed(() => props.notification.type ?? NotificationType.INFO);
const actions = computed(() => props.notification.actions ?? []);

// If forcibly dismissed, don't animate exit.
const forciblyDismissed = ref(false);
const onForceDismissal = () => {
  forciblyDismissed.value = true;
  nextTick(() => props.dismiss());
};

// Remove after timeout.
const delay = props.notification.duration ?? 10000;
const setTimer = (time: number) => (props.notification.indefinite ? null : setTimeout(() => props.dismiss(), time));

let remainingTime = delay;
let timeout = setTimer(delay);
const timerEnd = new Date().getTime() + delay;

// Stop timer on hover.
watch(
  () => props.hovering,
  () => {
    if (props.hovering) {
      remainingTime = timerEnd - new Date().getTime();
      if (timeout) {
        clearTimeout(timeout);
      }
    } else {
      // Reset timer with remaining time.
      timeout = setTimer(remainingTime > 1000 ? remainingTime : 1000);
    }
  }
);

const color = NOTIFICATION_TYPE_TO_COLOR_MAP[type.value];
</script>

<template>
  <div
    class="flex w-full flex-col rounded shadow-lg bg-std dark:shadow-zinc-50/10"
    :class="[forciblyDismissed && 'transition-none']">
    <div class="h-2 rounded-t" :class="color" />
    <div class="flex flex-col gap-2 rounded-b border-x border-b px-3 py-2 border-md">
      <div class="flex gap-2">
        <span class="w-full flex-1 select-none hyphens-auto break-words text-sm font-normal text-md">
          {{ props.notification.message }}
        </span>
        <div v-if="props.notification.isDismissible !== false" class="p-1">
          <XIcon class="cursor-pointer rounded text-lt icon-sm hover:bg-lt" @click="onForceDismissal()" />
        </div>
      </div>
      <div v-if="actions.length" class="flex justify-end">
        <component
          :is="action.component ?? 'button'"
          v-bind="action.componentArgs"
          v-for="(action, index) in actions"
          :key="index"
          class="flex select-none items-center rounded border px-2 py-1 text-center text-sm shadow-sm bg-std text-md border-hvy focus-ring-lt hover:bg-lt"
          @click="() => action.onClick?.(onForceDismissal)">
          {{ action.label }}
        </component>
      </div>
    </div>
  </div>
</template>
