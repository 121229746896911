<script setup lang="ts">
import type { RangeSelection } from "lexical";
import { computed } from "vue";

import { EMOJI_ORDERED_LIST, EMOJI_TO_KEYWORD_MAP, getEmojiWithSkinTone } from "~/constants/iconAndEmoji";
import type { TypeaheadOption, UnrealizedTypeaheadOptions } from "~/shared/types";
import { useAppStore } from "~/stores";

import TypeaheadMenuPlugin from "./typeahead/TypeaheadMenuPlugin.vue";

const appStore = useAppStore();

const options = computed<UnrealizedTypeaheadOptions>(() =>
  EMOJI_ORDERED_LIST.map((emoji) => ({
    value: emoji,
    label: getEmojiWithSkinTone(emoji, appStore.skinTone),
    adtlSearchTerms: EMOJI_TO_KEYWORD_MAP.get(emoji)?.split(" ") ?? [],
  }))
);

const onSelect = (selection: RangeSelection, option: TypeaheadOption) => {
  if (!selection) {
    return;
  }
  selection.insertRawText(`${option.label} `);
};
</script>

<template>
  <TypeaheadMenuPlugin trigger=":" :options="options" emoji-grid @select="onSelect" />
</template>
