<script setup lang="ts">
import { computed, nextTick, ref, watchEffect } from "vue";

import { APPLICABILITY_REMAP, NO_VALUE_APPLICABILITIES } from "~/common/filter";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { CommandId, DropdownMenuItemKind, FilterApplicability } from "~/shared/enums";
import type { FilterDefinition } from "~/shared/types";

const props = defineProps<{
  definition: FilterDefinition;
  applicability: FilterApplicability;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  select: [value: FilterApplicability];
  afterClose: [];
  remove: [];
}>();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const applicabilities = computed(() => props.definition.config.applicabilities);

// Update applicability if current one is not available.
watchEffect(() => {
  if (applicabilities.value.length === 0 || applicabilities.value.includes(props.applicability)) {
    return;
  }
  const newApplicability = APPLICABILITY_REMAP.get(props.applicability);
  if (newApplicability === undefined || !applicabilities.value.includes(newApplicability)) {
    emit("remove");
    return;
  }
  emit("select", newApplicability);
});

const dropdownSections = computed(() => [
  {
    title: "Applicability",
    items: applicabilities.value.map((applicability) => ({
      title: applicability,
      kind: DropdownMenuItemKind.BUTTON,
      iconArgs: { class: "icon-md" },
      disabled: applicability === props.applicability,
      onClick: () => nextTick(() => emit("select", applicability)),
    })),
  },
]);

defineExpose({
  isOpen: computed(() => !!dropdownMenu.value?.isOpen),
});
</script>

<template>
  <DropdownMenu
    ref="dropdownMenu"
    :sections="dropdownSections"
    :distance="-1"
    cover
    height-block
    :width-pixels="120"
    @after-close="emit('afterClose')">
    <Tooltip :disabled="disabled" :command-id="CommandId.CHANGE_FILTER_APPLICABILITY" height-block>
      <button
        type="button"
        :disabled="disabled"
        class="dart-filter-applicability flex h-full select-none items-center px-[3px] text-xs text-md border-md"
        :class="[
          disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-md',
          NO_VALUE_APPLICABILITIES.has(props.applicability) ? 'border-l' : 'border-x',
        ]">
        {{ props.applicability }}
      </button>
    </Tooltip>
  </DropdownMenu>
</template>
