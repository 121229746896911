import { type Component } from "vue";

import {
  BrainstormIcon,
  MobileAppIcon,
  PlusCircleIcon,
  TaskCreateIcon,
  UnassignedIcon,
  UsersInviteIcon,
} from "~/icons";
import { PageKind, TutorialName } from "~/shared/enums";
import type { DataStore } from "~/stores/DataStore";
import { getPageLink, timeout } from "~/utils/common";

import { MAC_APP_DOWNLOAD_LINK, WINDOWS_APP_DOWNLOAD_LINK } from "./link";

const CREATE_TASK_WITH_SUBTASK_STEPS = [
  {
    title: "Welcome to Dart!",
    content:
      "Follow these steps to see some of Dart's features, especially AI ones. Click here or press C to create a new task.",
    alternateContent: [
      "Follow these steps to see some of Dart's features, especially AI ones. Click here to get started.",
      "Click here or press C to create a new task.",
    ],
  },
  {
    title: "Give your task a title",
    content: "Fill this out with something specific, like Build a landing page for notifications by next Wednesday.",
  },
  {
    title: "Let Dart handle the details",
    content:
      "Open Task AI and click Break into subtasks to have Dart automatically fill out the steps to do your work.",
  },
  {
    title: "Save your task",
    content: "Delete any subtasks if you want, then fill out any other info. When you're done, create the task.",
  },
];

export const TUTORIAL_NAMES_TO_STEPS = new Map([
  [TutorialName.CREATE_TASK_WITH_SUBTASK_RECS, CREATE_TASK_WITH_SUBTASK_STEPS],
]);

export type TutorialDefinition = {
  title: string;
  icon: Component;
  onClick: (dataStore: DataStore) => void;
};

export const CREATE_YOUR_WORKSPACE: TutorialDefinition = {
  title: "Create the workspace",
  icon: PlusCircleIcon,
  onClick: () => {},
};

const MAKE_A_TASK: TutorialDefinition = {
  title: "Make a task",
  icon: TaskCreateIcon,
  onClick: (dataStore: DataStore) => {
    dataStore.$useAppStore().setTcmOpen(true);
  },
};

const SET_A_PROFILE_PICTURE: TutorialDefinition = {
  title: "Set a profile picture",
  icon: UnassignedIcon,
  onClick: (dataStore: DataStore) => {
    dataStore.$router.push(dataStore.$routerUtils.makeLinkToSettingsRef("account").value);
  },
};

const BRAINSTORM_WITH_AI: TutorialDefinition = {
  title: "Brainstorm with AI",
  icon: BrainstormIcon,
  onClick: async (dataStore) => {
    const appStore = dataStore.$useAppStore();
    if (appStore.currentPage?.pageKind !== PageKind.DARTBOARD) {
      const { defaultDartboard } = dataStore;
      if (!defaultDartboard) {
        return;
      }
      await dataStore.$router.push(getPageLink(defaultDartboard, dataStore.getSpaceByDuid));
      await timeout(100);
    }
    appStore.setBrainstormModalOpen(true);
  },
};

const DOWNLOAD_THE_APPS: TutorialDefinition = {
  title: "Download Dart apps",
  icon: MobileAppIcon,
  onClick: (dataStore) => {
    const router = dataStore.$router;
    const appStore = dataStore.$useAppStore();
    const pageStore = dataStore.$usePageStore();
    const userStore = dataStore.$useUserStore();

    userStore.updateTutorialStatuses([{ name: TutorialName.DOWNLOAD_THE_APPS, status: 2 }]);

    if (pageStore.isMobile) {
      if (!pageStore.isMobileApp) {
        window.open(router.resolve({ name: "redirect_mobile_app" }).fullPath);
      }
    } else {
      appStore.setAppDownloadModalOpen(true);
    }

    // TODO use redirect_desktop_app when it works better
    if (pageStore.isDesktop && !pageStore.isDesktopApp) {
      window.open(pageStore.isMac ? MAC_APP_DOWNLOAD_LINK : WINDOWS_APP_DOWNLOAD_LINK, "_blank");
      window.close();
    }
  },
};

const INVITE_TEAMMATES: TutorialDefinition = {
  title: "Invite teammates",
  icon: UsersInviteIcon,
  onClick: (dataStore) => {
    dataStore.$router.push(dataStore.$routerUtils.makeLinkToSettingsRef("teammates").value);
  },
};

export const ONBOARDING_TUTORIAL_NAMES_TO_DEFINITIONS = new Map<TutorialName, TutorialDefinition>([
  [TutorialName.MAKE_A_TASK, MAKE_A_TASK],
  [TutorialName.SET_A_PROFILE_PICTURE, SET_A_PROFILE_PICTURE],
  [TutorialName.BRAINSTORM_WITH_AI, BRAINSTORM_WITH_AI],
  [TutorialName.DOWNLOAD_THE_APPS, DOWNLOAD_THE_APPS],
  [TutorialName.INVITE_TEAMMATES, INVITE_TEAMMATES],
]);
