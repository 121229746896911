import EMOJI_KEYWORD_DATA from "emojilib";
import EMOJI_GENERAL_DATA from "unicode-emoji-json/data-by-emoji.json";
import EMOJI_ORDERED_LIST_RAW from "unicode-emoji-json/data-ordered-emoji.json";

import { EmojiSkinTone } from "~/shared/enums";

export const ICON_NAME_ALTERNATES = new Map([
  ["DirectNormalIcon", ["active"]],
  ["NextIcon", ["next"]],
  ["LayerIcon", ["backlog"]],
  ["ArchiveIcon", ["archive"]],
]);

const EMOJI_DELIMITER = "\u200D";
const CHARACTER_MAX_WIDTH_PX = 22;
const [EXCLUDED_EMOJI_SET, EXCLUDED_EMOJI_WITH_SKIN_TONE_SET] = (() => {
  const span = document.createElement("span");
  span.style.visibility = "hidden";
  document.body.appendChild(span);
  const excludedEmoji = new Set();
  const excludedEmojiWithSkinTone = new Set();
  Object.entries(EMOJI_GENERAL_DATA).forEach(([emoji, emojiData]) => {
    span.innerHTML = emoji;
    if (span.offsetWidth > CHARACTER_MAX_WIDTH_PX) {
      excludedEmoji.add(emoji);
    }
    if (!emojiData.skin_tone_support) {
      return;
    }

    span.innerHTML = emoji + EmojiSkinTone.LIGHT;
    if (span.offsetWidth > CHARACTER_MAX_WIDTH_PX) {
      excludedEmojiWithSkinTone.add(emoji);
    }
  });
  document.body.removeChild(span);
  return [excludedEmoji, excludedEmojiWithSkinTone];
})();

export const EMOJI_ORDERED_LIST = EMOJI_ORDERED_LIST_RAW.filter((emoji) => !EXCLUDED_EMOJI_SET.has(emoji));
export const EMOJI_SET = new Set(EMOJI_ORDERED_LIST);
export const EMOJI_TO_KEYWORD_MAP = new Map(
  Object.entries(EMOJI_KEYWORD_DATA).map(([emoji, keywords]) => [emoji, keywords.join(" ")])
);
export const DEFAULT_SKIN_TONE_EMOJI = "👋";
export const EMOJI_WITH_SKIN_TONE_SET = new Set(
  Object.entries(EMOJI_GENERAL_DATA)
    .filter((e) => e[1].skin_tone_support && !EXCLUDED_EMOJI_WITH_SKIN_TONE_SET.has(e[0]))
    .map((e) => e[0])
);
const EMOJI_TO_DATA_MAP = new Map(Object.entries(EMOJI_GENERAL_DATA));

export const formatIconName = (iconName: string) =>
  iconName
    .slice(0, -4)
    .replace(/([A-Z]|\d+)/g, " $1")
    .trim()
    .toLowerCase();

export const formatEmojiName = (emoji: string) => EMOJI_TO_DATA_MAP.get(emoji)?.name ?? "";

export const removeSkinTone = (emoji: string) => emoji.replace(/🏻|🏼|🏽|🏾|🏿/g, "");

export const extractSkinTone = (emoji: string): EmojiSkinTone =>
  Object.values(EmojiSkinTone).find((e) => e !== EmojiSkinTone.NONE && emoji.endsWith(e)) ?? EmojiSkinTone.NONE;

export const emojiAllowsSkinTone = (emoji: string) => EMOJI_WITH_SKIN_TONE_SET.has(removeSkinTone(emoji));

export const getEmojiWithSkinTone = (emoji: string, skinTone: EmojiSkinTone) =>
  emojiAllowsSkinTone(emoji) ? removeSkinTone(emoji) + skinTone : emoji;

export const normalizeEmoji = (emoji: string): string => {
  if (emojiAllowsSkinTone(emoji) || !emoji.includes(EMOJI_DELIMITER)) {
    return emoji;
  }

  const individualEmoji = emoji.split(EMOJI_DELIMITER);
  let result = individualEmoji.join(EMOJI_DELIMITER);
  while (result !== "" && !EMOJI_SET.has(result)) {
    individualEmoji.pop();
    result = individualEmoji.join(EMOJI_DELIMITER);
  }
  return result;
};
