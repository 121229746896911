<script setup lang="ts">
import { backend } from "~/api";
import { OpenExternalLinkIcon, PipedreamIcon } from "~/icons";
import { EventKind } from "~/shared/enums";

const onClick = () => {
  backend.event.create(EventKind.HELP_RESOURCE_CLICK, { resource: "cli" });
};
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex justify-between">
          <div class="flex gap-x-4">
            <h2 class="flex select-none items-center text-xl text-md">Pipedream integration</h2>
          </div>
        </div>
        <p class="select-none text-sm/relaxed text-lt">
          Integrate Pipedream with Dart to automate your workflows and connect Dart with thousands of other apps.
        </p>
      </div>

      <a
        href="https://pipedream.com/apps/dart"
        target="_blank"
        rel="noopener noreferrer"
        class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center shadow-sm text-md border-md focus-ring-std hover:bg-lt"
        @click="onClick"
        @keydown.enter="onClick">
        <PipedreamIcon class="icon-md" />
        Link Dart and Pipedream
        <OpenExternalLinkIcon class="icon-md" />
      </a>
    </div>
  </div>
</template>
