/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Custom` - CUSTOM
 * * `Search` - SEARCH
 * * `Trash` - TRASH
 * * `My tasks` - MY_TASKS
 */
export enum ViewKind {
  CUSTOM = 'Custom',
  SEARCH = 'Search',
  TRASH = 'Trash',
  MY_TASKS = 'My tasks',
}
