export const IMPORT_SOURCES = [
  "Asana",
  "ClickUp",
  "Dart",
  "Jira",
  "Linear",
  "Microsoft Project",
  "Trello",
  "Spreadsheet",
];

export const SOURCE_TO_INSTRUCTIONS_MAP = new Map([
  [
    "Asana",
    [
      "Log in to Asana",
      "Open the the project that you want to import to Dart",
      "Click the down arrow next to the title of the project",
      "Select 'Export/Print > CSV'",
      "Upload the CSV file below",
    ],
  ],
  [
    "ClickUp",
    [
      "Log in to ClickUp",
      "On the main page, click your profile picture in the bottom left corner, then select 'Settings'",
      "Select 'Import/Export'",
      "Click 'Start export', then 'Start export' again, then wait for the download (it will take a while)",
      "Click 'Download Export'",
      "Upload the CSV file below",
    ],
  ],
  [
    "Dart",
    [
      "Select the 'Export' tab at left",
      "Click 'Export data'",
      "Open the ZIP file and upload the 'tasks.csv' file below",
    ],
  ],
  [
    "Jira",
    [
      "Log in to Jira",
      "Find or make a filter that contains all of the tasks you want to import to Dart",
      "Open the filter page",
      "Click 'Export' in the top right, then select 'Export Excel CSV (all fields)'",
      "Upload the CSV file below",
    ],
  ],
  [
    "Linear",
    [
      "Log in to Linear",
      "Click your profile picture in the top left, then select 'Settings'",
      "Select 'Import / Export'",
      "Click 'Export CSV'",
      "Open your email, find the email from Linear with the subject 'Your Linear export is ready', then click 'Download'",
      "Upload the CSV file below",
    ],
  ],
  [
    "Microsoft Project",
    [
      "Open Microsoft Project",
      "Export the project as an Excel file",
      "Open the file",
      "Remove the top eight header lines so the top row is just the header of the sheet",
      "Save the file as a CSV",
      "Upload the CSV file below",
    ],
  ],
  [
    "Trello",
    [
      "Log in to Trello",
      "Open the the board that you want to import to Dart",
      "Click the '...' in the top right and then select 'More'",
      "Select 'Print and export' > 'Export as CSV'",
      "Upload the CSV file below",
    ],
  ],
  [
    "Spreadsheet",
    [
      "Open your spreadsheet",
      "Make sure that there are no extra rows before the column headers",
      [
        "Make sure that the column headers exactly match the required fields",
        "'ID'",
        "'Title'",
        "'Dartboard'",
        "'Status'",
      ],
      [
        "Make sure that the other column headers exactly match any optional fields you want to import",
        "'Description' (empty or the description in markdown format)",
        "'Parent ID' (empty or the ID of the parent task)",
        "'Assignee Emails' (empty or a comma-separated list of emails)",
        "'Tags' (empty or a comma-separated list of tags)",
        "'Priority' (empty or one of 'Critical', 'High', 'Medium', or 'Low')",
        "'Start date' (empty or a date in a standard format)",
        "'Due date' (empty or a date in a standard format)",
        "'Size' (empty or one of 'XS', 'S', 'M', 'L', or 'XL')",
      ],
      "Save or export the sheet as a CSV file",
      "Upload the CSV file below",
    ],
  ],
]);
