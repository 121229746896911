import { DecoratorNode, type LexicalNode, type SerializedLexicalNode } from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import AiCursor from "./AiCursor.vue";

export class AiCursorNode extends DecoratorNode<Component> {
  static getType(): string {
    return "ai-cursor";
  }

  static clone(node: AiCursorNode): AiCursorNode {
    return new AiCursorNode(node.__key);
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.classList.add("contents");
    return div;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  decorate(): Component {
    return h(AiCursor, {
      nodeKey: this.getKey(),
    });
  }

  static importJSON(): LexicalNode {
    return new AiCursorNode();
  }

  // eslint-disable-next-line class-methods-use-this
  exportJSON(): SerializedLexicalNode {
    return {
      type: AiCursorNode.getType(),
      version: 1,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): true {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }
}

export function $createAiCursorNode(): AiCursorNode {
  return new AiCursorNode();
}

export function $isAiCursorNode(node?: LexicalNode): node is AiCursorNode {
  return node instanceof AiCursorNode;
}
