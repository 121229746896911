import { type EventBusKey, useEventBus } from "@vueuse/core";
import type { AxiosProgressEvent } from "axios";
import { reactive } from "vue";

import type { StreamCallback } from "~/shared/types";

export const EVENT_BUS_FILE_UPLOAD_PROGRESS: EventBusKey<
  {
    duid: string;
  } & AxiosProgressEvent
> = Symbol("EVENT_BUS_FILE_UPLOAD_PROGRESS");

export const filePreviews = reactive(new Map<string, string>());
export const startFileUpload = (duid: string, file: File) => {
  filePreviews.set(duid, URL.createObjectURL(file));
};
export const finishFileUpload = (duid: string) => {
  filePreviews.delete(duid);
};

export const makeFileUploadProgressTracker =
  (duid: string): StreamCallback =>
  (event) => {
    if (event.progress === 1) {
      finishFileUpload(duid);
    }
    const eventBus = useEventBus(EVENT_BUS_FILE_UPLOAD_PROGRESS);
    eventBus.emit({ duid, ...event });
  };
