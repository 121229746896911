<script setup lang="ts">
import { ChatgptIcon, OpenExternalLinkIcon } from "~/icons";
import { useEnvironmentStore } from "~/stores";

const environmentStore = useEnvironmentStore();
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex justify-between">
          <div class="flex gap-x-4">
            <h2 class="flex select-none items-center text-xl text-md">ChatGPT integration</h2>
          </div>
        </div>
        <div class="select-none text-sm/relaxed text-lt">
          <p>
            Integrate ChatGPT with Dart to look through, create, and update tasks and docs without having to leave
            ChatGPT.
          </p>
        </div>
        <ol class="ml-6 select-none list-decimal text-sm/relaxed text-lt">
          <li>Log in to ChatGPT</li>
          <li>Click the link below or find Dart in the GPT store</li>
        </ol>
      </div>

      <a
        :href="
          environmentStore.isProd
            ? 'https://chat.openai.com/g/g-eKfjMUXwr-dart'
            : 'https://chat.openai.com/g/g-GGblTEDrV-dart-dev'
        "
        target="_blank"
        rel="noopener noreferrer"
        class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center shadow-sm text-md border-md focus-ring-std hover:bg-lt">
        <ChatgptIcon class="icon-md" />
        Start using the GPT
        <OpenExternalLinkIcon class="icon-md" />
      </a>
    </div>
  </div>
</template>
