/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Rightbar` - RIGHTBAR
 * * `Fullscreen` - FULLSCREEN
 * * `Overlay` - OVERLAY
 */
export enum TaskDetailMode {
  RIGHTBAR = 'Rightbar',
  FULLSCREEN = 'Fullscreen',
  OVERLAY = 'Overlay',
}
