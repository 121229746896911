<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";

import Input from "~/components/dumb/Input.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import { CopyIcon, OpenExternalLinkIcon } from "~/icons";
import { validateUrl } from "~/utils/common";

const props = defineProps<{
  url: string;
  nodeKey: string;
}>();

const emit = defineEmits<{
  save: [nodeKey: string, text: string | null];
}>();

const urlInput = ref<InstanceType<typeof Input> | null>(null);
const nodeKeyCopy = props.nodeKey;

const stopEditing = () => {
  if (!urlInput.value || !urlInput.value.isValid) {
    return;
  }
  urlInput.value.blur();
  emit("save", nodeKeyCopy, urlInput.value.value);
};

onMounted(() => {
  if (props.url !== "") {
    return;
  }
  urlInput.value?.focus();
});

onBeforeUnmount(() => {
  if (!urlInput.value) {
    return;
  }

  const { isValid, value } = urlInput.value;
  if (isValid) {
    if (value !== props.url) {
      emit("save", nodeKeyCopy, value);
    }
    return;
  }

  emit("save", nodeKeyCopy, null);
});
</script>

<template>
  <div class="flex size-full items-center gap-1 p-1 text-lt">
    <Input
      ref="urlInput"
      :init-value="url"
      label="Link URL"
      class="w-full"
      input-classes="!px-1 !py-0.5"
      hide-label
      hide-error
      :validate="validateUrl"
      @finalize="stopEditing" />
    <Tooltip text="Copy link">
      <button
        type="button"
        class="rounded p-1 hover:bg-md"
        aria-label="Copy link"
        @click="copyAndNotify('Link URL', url)">
        <CopyIcon class="icon-sm" />
      </button>
    </Tooltip>

    <Tooltip text="Open link">
      <a :href="url" target="_blank" rel="noopener noreferrer" class="rounded p-1 hover:bg-md">
        <span class="sr-only">Open link</span>
        <OpenExternalLinkIcon class="icon-sm" />
      </a>
    </Tooltip>
  </div>
</template>
