<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { onMounted } from "vue";

import TaskKindEditor from "~/components/dumb/TaskKindEditor.vue";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { EditorMode } from "~/shared/enums";
import type { PropertyDefaultKind, TaskAbsenteeMaybe } from "~/shared/types";
import { usePageStore } from "~/stores";

const props = defineProps<{
  params: ICellRendererParams<TaskAbsenteeMaybe> & {
    property: PropertyDefaultKind;
    editorMode: EditorMode;
  };
}>();

const pageStore = usePageStore();

onMounted(() => {
  if (props.params.editorMode !== EditorMode.LIST) {
    return;
  }
  pageStore.incrementCellRenderCount();
});
</script>

<template>
  <TaskClickWrapper v-if="params.data" :task="params.data" :editor-mode="params.editorMode">
    <template #default="args">
      <TaskKindEditor
        v-if="!params.data.absentee"
        v-bind="args"
        :property="params.property"
        :tasks="[params.data]"
        :editor-mode="params.editorMode"
        :value="params.data.kindDuid"
        class="h-full" />
    </template>
  </TaskClickWrapper>
</template>
