import { randomSample } from "~/utils/common";

export const MIN_ORD = 1;
export const MAX_ORD = 256;

export const chr = (n: number) => String.fromCharCode(n);
const ord = (c: string) => c.charCodeAt(0);

const ORDER_CHARS = Array.from(Array(MAX_ORD - MIN_ORD), (_, i) => chr(i + MIN_ORD));

const makeOrderSuffix = () => randomSample(ORDER_CHARS, 4).join("");

export const getOrdersBetweenRecursive = (start: string, end: string, count: number): string[] => {
  const maxLen = Math.max(start.length, end.length) + 1;
  for (let i = 0; i < maxLen; i += 1) {
    const startOrd = i < start.length ? ord(start[i]) : MIN_ORD;
    const endOrd = i < end.length ? ord(end[i]) : MAX_ORD;
    const diff = endOrd - startOrd;
    if (diff <= 1) {
      continue;
    }
    const prefix = start.substring(0, i).padEnd(i, chr(MIN_ORD));
    if (diff > count) {
      const frac = diff / (count + 1);
      return Array(count)
        .fill(0)
        .map((_, j) => prefix + chr(startOrd + Math.round(frac * (j + 1))));
    }
    const segments = [start.substring(i)]
      .concat(
        Array(diff - 1)
          .fill(0)
          .map((_, j) => chr(startOrd + j + 1))
      )
      .concat(end.substring(i));
    let totAmount = 0;
    const amounts = [];
    for (let j = 0; j < diff; j += 1) {
      const nextAmount = Math.round((count * (j + 1)) / diff) - totAmount;
      amounts.push(nextAmount);
      totAmount += nextAmount;
    }
    let res: string[] = [];
    for (let j = 0; j < diff; j += 1) {
      res = res.concat(getOrdersBetweenRecursive(segments[j], segments[j + 1], amounts[j]).map((e) => prefix + e));
    }
    return res;
  }
  throw new Error(`failed to get ${count} values between ${start} and ${end}`);
};

export const getOrdersBetween = (start: string | undefined, end: string | undefined, count = 1): string[] => {
  if (count <= 0) {
    return [];
  }
  if (start && end && start >= end) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid request for ${count} between ${start} and ${end}`);
    return Array(count).fill(start);
  }
  return getOrdersBetweenRecursive(start ?? "", end ?? "", count).map((e) => e + makeOrderSuffix());
};

export const prettifyOrder = (order: string) =>
  Array.from(order)
    .map((e) => e.charCodeAt(0).toString().padStart(3, "0"))
    .join(" ");
