<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { onMounted } from "vue";

import { copyAndNotify } from "~/components/notifications";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { EditorMode } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";
import { usePageStore } from "~/stores";

const props = defineProps<{
  params: ICellRendererParams<TaskAbsenteeMaybe>;
}>();

const pageStore = usePageStore();

const copy = () => {
  if (!props.params.value) {
    return;
  }
  copyAndNotify("DUID", props.params.value);
};

onMounted(() => {
  pageStore.incrementCellRenderCount();
});
</script>

<template>
  <TaskClickWrapper v-if="params.data" :task="params.data" :editor-mode="EditorMode.LIST">
    <template #default="args">
      <div
        v-if="!params.data.absentee"
        v-bind="args"
        class="flex size-full items-center font-mono text-[10px] tracking-tighter"
        @click="copy"
        @keydown.enter="copy">
        {{ params.value }}
      </div>
    </template>
  </TaskClickWrapper>
</template>
