<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_HIGH, FORMAT_TEXT_COMMAND, TextNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const editor = useLexicalComposer();

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(FORMAT_TEXT_COMMAND, () => false, COMMAND_PRIORITY_HIGH),
    editor.registerNodeTransform(TextNode, (node) => {
      if (node.getFormat() !== 0) {
        node.setFormat(0);
      }
      if (node.getStyle() !== "") {
        node.setStyle("");
      }
      return node;
    })
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
