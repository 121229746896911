import type { PiniaPluginContext } from "pinia";
import { markRaw } from "vue";

import * as routerUtils from "~/router/common";
import router from "~/router/router";

/* Make router available inside stores. */
export default ({ store }: PiniaPluginContext) => {
  store.$router = markRaw(router);
  store.$routerUtils = routerUtils;
};
