<script setup lang="ts">
import { useDocumentVisibility, useWindowFocus } from "@vueuse/core";
import { nextTick, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import { EventKind, NotificationType } from "~/shared/enums";
import { useAppStore, useEnvironmentStore, usePageStore } from "~/stores";

import { dismissNotification, notify } from "../notifications";

const NOTIFICATION_ID = "update";
const UPDATE_CHECK_INTERVAL = 1000 * 60 * 10;
const IDLE_TIMEOUT = 1000 * 60;

const router = useRouter();
const appStore = useAppStore();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();

const visibility = useDocumentVisibility();
const windowFocus = useWindowFocus();

// Show success message after upgrading
watch(
  () => router.currentRoute.value.query.updated,
  (updated) => {
    if (updated !== "1") {
      return;
    }
    notify({
      message: "You updated to the latest version of Dart 🎉",
      type: NotificationType.SUCCESS,
      actions: [
        {
          label: "See what changed",
          component: "a",
          componentArgs: {
            href: "https://changelog.itsdart.com",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      ],
    });
    const query = { ...router.currentRoute.value.query };
    delete query.updated;
    nextTick(() => {
      router.replace({ query });
    });
  }
);

const refresh = () => {
  const url = new URL(window.location.href);
  url.searchParams.set("updated", "1");
  window.location.assign(url);
};

watch(
  () => environmentStore.needsUpdate,
  (needsUpdate) => {
    if (!needsUpdate) {
      return;
    }
    dismissNotification(NOTIFICATION_ID);
    notify({
      id: NOTIFICATION_ID,
      message: "Dart has an update available 🎁",
      indefinite: true,
      isDismissible: false,
      actions: [
        {
          label: "Update",
          onClick: refresh,
        },
      ],
    });
  }
);

const updateCheckInterval = setInterval(() => {
  if (pageStore.isOnline) {
    environmentStore.checkForUpdate();
  }
}, UPDATE_CHECK_INTERVAL);

let timeout: ReturnType<typeof setTimeout> | undefined;

const onIdle = () => {
  if (!environmentStore.needsUpdate || timeout) {
    return;
  }

  timeout = setTimeout(() => {
    if (appStore.getWorking()) {
      timeout = undefined;
      return;
    }
    window.location.reload();
  }, IDLE_TIMEOUT);
};

const onUnidle = () => {
  backendOld.createEvent(EventKind.UNIDLE, undefined, true);

  if (!timeout) {
    return;
  }
  clearTimeout(timeout);
  timeout = undefined;
};

watch(
  () => visibility.value,
  (newVisibility) => {
    if (newVisibility === "visible") {
      onUnidle();
      return;
    }
    onIdle();
  }
);
watch(
  () => windowFocus.value,
  (newFocus) => {
    if (newFocus) {
      onUnidle();
      return;
    }
    onIdle();
  }
);

onUnmounted(() => {
  clearInterval(updateCheckInterval);
});
</script>

<template>
  <slot />
</template>
