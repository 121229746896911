import { ElementNode, type LexicalNode, type SerializedElementNode } from "lexical";

import type { ToggleWrapperNode } from "./ToggleWrapperNode";

type SerializedToggleContentNode = SerializedElementNode;

// TODO bigger refactor to hoist more business logic out into a plugin so that these nodes don't need to import each other
function $isToggleWrapperNode(node: LexicalNode | null): node is ToggleWrapperNode {
  return node?.getType() === "toggle-wrapper";
}

export class ToggleContentNode extends ElementNode {
  __initialized = false;

  __open = true;

  static getType(): string {
    return "toggle-content";
  }

  static clone(node: ToggleContentNode): ToggleContentNode {
    return new ToggleContentNode(node.__key);
  }

  constructor(key?: string) {
    super(key);

    const wrapper = this.getParent();
    if (!wrapper || !$isToggleWrapperNode(wrapper)) {
      return;
    }

    this.__initialized = true;
    this.__open = wrapper.getOpen();
  }

  getOpen(): boolean {
    return this.__open;
  }

  setOpen(open: boolean): void {
    const writable = this.getWritable();
    writable.__open = open;
  }

  initialize(): void {
    if (this.__initialized) {
      return;
    }
    const wrapper = this.getParent();
    if (!wrapper || !$isToggleWrapperNode(wrapper)) {
      return;
    }

    this.__initialized = true;
    const writable = this.getWritable();
    writable.__open = wrapper.getOpen();
  }

  createDOM(): HTMLDetailsElement {
    this.initialize();
    const details = document.createElement("details");
    details.classList.add("grow");
    details.open = this.getOpen();
    return details;
  }

  updateDOM(prevNode: ToggleContentNode, dom: HTMLDetailsElement): false {
    if (prevNode.__open !== this.__open) {
      // eslint-disable-next-line no-param-reassign
      dom.open = this.__open;
    }
    return false;
  }

  static importJSON(): LexicalNode {
    return new ToggleContentNode();
  }

  // eslint-disable-next-line class-methods-use-this
  exportJSON(): SerializedToggleContentNode {
    return {
      type: ToggleContentNode.getType(),
      indent: 0,
      direction: "ltr",
      format: "",
      children: [],
      version: 1,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  canBeEmpty(): false {
    return false;
  }
}

export function $createToggleContentNode(): ToggleContentNode {
  return new ToggleContentNode();
}

export function $isToggleContentNode(node?: LexicalNode | null): node is ToggleContentNode {
  return node instanceof ToggleContentNode;
}
