<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import { DropdownMenuItemKind, Placement } from "~/shared/enums";
import type { TypeaheadOption } from "~/shared/types";

const props = defineProps<{
  item: TypeaheadOption;
}>();

const emit = defineEmits<{
  select: [option: TypeaheadOption];
}>();

const dropdownOptions = computed(() => {
  if (!props.item.options) {
    return [];
  }

  return [
    {
      title: "Options",
      items: props.item.options.map((option) => ({
        title: option.label,
        kind: DropdownMenuItemKind.BUTTON,
        icon: option.icon,
        iconArgs: option.iconArgs,
        onClick: () => emit("select", option),
      })),
    },
  ];
});
</script>

<template>
  <DropdownMenu
    :sections="dropdownOptions"
    :placement="Placement.RIGHT"
    :width-pixels="320"
    show-on-hover
    class="w-full"
    @keydown.enter.stop
    @click.stop>
    <DropdownMenuItemContent
      :title="item.label"
      :icon="item.icon"
      :icon-args="item.iconArgs"
      is-submenu
      class="w-full" />
  </DropdownMenu>
</template>
