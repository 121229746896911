<script setup lang="ts">
import { computed, ref } from "vue";

import AttachmentImage from "~/components/dumb/AttachmentImage.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { PlusIcon } from "~/icons";
import { CommandId, EditorMode, ViewKind } from "~/shared/enums";
import type { Attachment } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { checkFileSize } from "~/utils/api";
import { makeUuid } from "~/utils/common";
import { makeStringComparator } from "~/utils/comparator";

import DragArea from "./DragArea.vue";

const props = defineProps<{
  attachments: Attachment[];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const emit = defineEmits<{
  add: [value: File[]];
  remove: [attachment: Attachment];
}>();

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);

const onFileAdd = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (!input.files) {
    return;
  }
  const filteredFiles = Array.from(input.files).filter((file) => checkFileSize(file));

  emit("add", filteredFiles);
  input.value = "";
};

const inputId = ref(`input-${makeUuid()}`);

const items = computed(() =>
  props.attachments
    .map((attachment) => ({
      ...attachment,
      title: attachment.name,
      undraggable: isTrash.value,
    }))
    .sort(makeStringComparator((e) => e.order))
);

const getComponentProps = (attachment: Attachment) => ({
  attachment,
  editorMode: EditorMode.DETAIL,
  onRemove: () => emit("remove", attachment),
});

const moveAttachment = (_: string, attachment: Attachment) => {
  const { duid, order } = attachment;
  dataStore.updateAttachment({ duid, order });
};
</script>

<template>
  <DragArea
    group="attachment-editor"
    category="attachments"
    horizontal
    class="!h-auto w-full gap-2 px-5 py-1"
    :items="items"
    :component="AttachmentImage"
    :get-component-props="getComponentProps"
    @change="moveAttachment">
    <template #after>
      <Tooltip v-if="!pageStore.isPublicView && !isTrash" :command-id="CommandId.ADD_ATTACHMENT">
        <div>
          <label
            :for="inputId"
            class="flex size-20 cursor-pointer items-center justify-center rounded border border-dashed p-1 text-sm text-vlt border-hvy focus-ring-std hover:bg-md print:hidden">
            <span class="sr-only">Add attachment</span>
            <input :id="inputId" type="file" class="sr-only" accept="*" multiple @input="onFileAdd" />
            <PlusIcon class="icon-md" />
          </label>
        </div>
      </Tooltip>
    </template>
  </DragArea>
</template>
