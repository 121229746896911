/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Standup` - STANDUP
 * * `Changelog` - CHANGELOG
 */
export enum ReportKind {
  STANDUP = 'Standup',
  CHANGELOG = 'Changelog',
}
