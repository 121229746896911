import { computed } from "vue";

import { AvailabilityFilterType, PageKind, SubtaskDisplayMode } from "~/shared/enums";
import { useAppStore, usePageStore, useTenantStore } from "~/stores";

// TODO improve this system
// eslint-disable-next-line import/prefer-default-export
export const getAvailabilityFilterMap = () => {
  const appStore = useAppStore();
  const pageStore = usePageStore();
  const tenantStore = useTenantStore();

  return computed(() => {
    const pageIsView = appStore.currentPage?.pageKind === PageKind.VIEW;
    const layoutSubtasksNotFlat = appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT;
    return new Map([
      [AvailabilityFilterType.IS_ONLINE, pageStore.isOnline],
      [AvailabilityFilterType.TENANT_IS_DART, tenantStore.isDart],
      [AvailabilityFilterType.TENANT_HAS_BACKLOG_ENABLED, tenantStore.backlogEnabled],
      [AvailabilityFilterType.TENANT_HAS_MULTIPLE_ASSIGNEES_DISABLED, !tenantStore.multipleAssigneesEnabled],
      [AvailabilityFilterType.TENANT_HAS_MULTIPLE_ASSIGNEES_ENABLED, tenantStore.multipleAssigneesEnabled],
      [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED, tenantStore.priorityEnabled],
      [AvailabilityFilterType.TENANT_HAS_START_DATE_ENABLED, tenantStore.startDateEnabled],
      [AvailabilityFilterType.TENANT_HAS_DUE_DATE_ENABLED, tenantStore.dueDateEnabled],
      [AvailabilityFilterType.TENANT_HAS_SIZE_ENABLED, tenantStore.sizeEnabled],
      [AvailabilityFilterType.PAGE_IS_VIEW, pageIsView],
      [AvailabilityFilterType.LAYOUT_SUBTASKS_NOT_FLAT, layoutSubtasksNotFlat],
    ]);
  });
};
