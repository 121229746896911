<script setup lang="ts">
import { useLexicalComposer } from "lexical-vue";

import Button from "~/components/dumb/Button.vue";
import { ToggleSectionIcon } from "~/icons";
import { ButtonStyle, IconSize } from "~/shared/enums";

import { EVENT_TOGGLE_TOGGLE_COMMAND } from "../const";

defineProps<{
  nodeKey: string;
  open: boolean;
  hasContent: boolean;
}>();

const editor = useLexicalComposer();
</script>

<template>
  <div class="mr-1 mt-[5px] !inline-flex self-start align-middle">
    <Button
      :btn-style="ButtonStyle.CHIP"
      :icon-size="IconSize.XS"
      :icon-args="{
        class: ['transition-transform !h-3 !w-3', open ? 'rotate-90' : 'rotate-0', hasContent ? 'text-md' : 'text-vlt'],
      }"
      borderless
      :icon="ToggleSectionIcon"
      a11y-label="Toggle section"
      class="hover:!bg-lt"
      @click="editor.dispatchCommand(EVENT_TOGGLE_TOGGLE_COMMAND, nodeKey)" />
  </div>
</template>
