import type { ElementTransformer } from "@lexical/markdown";
import {
  $createParagraphNode,
  ElementNode,
  type LexicalNode,
  ParagraphNode,
  type SerializedElementNode,
} from "lexical";

import { $createToggleButton, $isToggleButton, ToggleButtonNode } from "./ToggleButtonNode";
import { $createToggleContentNode, $isToggleContentNode, ToggleContentNode } from "./ToggleContentNode";
import { $createToggleDetailsNode, $isToggleDetailsNode, ToggleDetailsNode } from "./ToggleDetailsNode";
import { $createToggleTitleNode, $isToggleTitleNode, ToggleTitleNode } from "./ToggleTitleNode";

type SerializedToggleWrapperNode = SerializedElementNode & { open: boolean };

export class ToggleWrapperNode extends ElementNode {
  __open: boolean;

  __hasContent = false;

  static getType(): string {
    return "toggle-wrapper";
  }

  static clone(node: ToggleWrapperNode): ToggleWrapperNode {
    return new ToggleWrapperNode(node.__open, node.__key);
  }

  constructor(open: boolean, key?: string) {
    super(key);
    this.__open = open;
  }

  getOpen(): boolean {
    return this.__open;
  }

  setOpen(open: boolean): void {
    if (this.__open === open) {
      return;
    }

    const writable = this.getWritable();
    writable.__open = open;

    const button = this.getFirstChild();
    if ($isToggleButton(button)) {
      button.setOpen(open);
    }
    const content = this.getLastChild();
    if ($isToggleContentNode(content)) {
      content.setOpen(open);
    }
  }

  getHasContent(): boolean {
    return this.__hasContent;
  }

  setHasContent(hasContent: boolean): void {
    if (this.__hasContent === hasContent) {
      return;
    }

    const writable = this.getWritable();
    writable.__hasContent = hasContent;

    const button = this.getFirstChild();
    if ($isToggleButton(button)) {
      button.setHasContent(hasContent);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.classList.add(..."flex items-center".split(" "));
    return div;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedToggleWrapperNode): LexicalNode {
    return new ToggleWrapperNode(serializedNode.open);
  }

  exportJSON(): SerializedToggleWrapperNode {
    return {
      type: ToggleWrapperNode.getType(),
      indent: 0,
      direction: "ltr",
      format: "",
      children: [],
      open: this.getOpen(),
      version: 1,
    };
  }

  toggleOpen(): void {
    this.setOpen(!this.getOpen());
  }

  // eslint-disable-next-line class-methods-use-this
  canBeEmpty(): false {
    return false;
  }
}

export function $createToggleWrapperNode(open: boolean): ToggleWrapperNode {
  return new ToggleWrapperNode(open);
}

export function $isToggleWrapperNode(node: LexicalNode | null): node is ToggleWrapperNode {
  return node instanceof ToggleWrapperNode;
}

export const createToggleBlock = (open = true): [ToggleWrapperNode, ParagraphNode] => {
  const wrapper = $createToggleWrapperNode(open);
  const button = $createToggleButton();
  wrapper.append(button);

  const content = $createToggleContentNode();

  const title = $createToggleTitleNode();
  const titleParagraph = $createParagraphNode();
  title.append(titleParagraph);
  content.append(title);

  const details = $createToggleDetailsNode();
  details.append($createParagraphNode());

  content.append(details);
  wrapper.append(content);

  return [wrapper, titleParagraph];
};

export const TOGGLE_BLOCK_TRANSFORMER: ElementTransformer = {
  dependencies: [ToggleWrapperNode, ToggleButtonNode, ToggleContentNode, ToggleTitleNode, ToggleDetailsNode],
  export: (node: LexicalNode, exportChildren: (node: ElementNode) => string) => {
    if (!$isToggleWrapperNode(node)) {
      return null;
    }
    const content = node.getLastChild();
    if (!$isToggleContentNode(content)) {
      return null;
    }
    const title = content.getFirstChild();
    const details = content.getLastChild();
    if (!$isToggleTitleNode(title) || !$isToggleDetailsNode(details)) {
      return null;
    }
    return `<details open="${node.getOpen()}"><summary>${exportChildren(title)}</summary>${exportChildren(details)}</details>`;
  },
  regExp: /^>\s/,
  replace: (parentNode: ElementNode, children: LexicalNode[]) => {
    const [node, title] = createToggleBlock();
    title.append(...children);
    parentNode.replace(node);
    title.selectStart();
  },
  type: "element",
};
