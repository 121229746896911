<script setup lang="ts">
import { computed, ref } from "vue";

import { backendOld } from "~/api";
import AuthToken from "~/components/AuthToken.vue";
import Button from "~/components/dumb/Button.vue";
import FormError from "~/components/dumb/FormError.vue";
import Input from "~/components/dumb/Input.vue";
import SettingSection from "~/components/dumb/SettingSection.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import { CopyIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import { useEnvironmentStore, useTenantStore } from "~/stores";
import { validateUrl, validateXml } from "~/utils/common";

const environmentStore = useEnvironmentStore();
const tenantStore = useTenantStore();

const urlInput = ref<InstanceType<typeof Input> | null>(null);
const xmlTextarea = ref<HTMLTextAreaElement | null>(null);

const toggleSamlEnabled = (value: boolean) => {
  if (value) {
    backendOld.workspace.enableSamlSso();
    return;
  }
  backendOld.workspace.disableSamlSso();
};

const newIdpXml = ref(tenantStore.samlConfig?.idpXml ?? "");
const xmlTouched = ref(false);
const xmlError = computed(() => {
  const res = validateXml(newIdpXml.value);
  if (res.isValid) {
    return undefined;
  }
  return res.error;
});
const xmlTouchedAndError = computed(() => xmlTouched.value && !!xmlError.value);

const idpDetailsOnUrl = ref(true);
const setIdpDetailsOnUrl = (value: boolean) => {
  idpDetailsOnUrl.value = value;
  newIdpXml.value = tenantStore.samlConfig?.idpXml ?? "";
  xmlTouched.value = false;
};

const changeSamlDetails = () => {
  if (idpDetailsOnUrl.value) {
    const url = urlInput.value?.value ?? "";
    if (tenantStore.samlConfig) {
      tenantStore.samlConfig.idpUrl = url;
    }
    backendOld.workspace.configureSamlSso({ url });
    return;
  }
  const xml = newIdpXml.value;
  if (tenantStore.samlConfig) {
    tenantStore.samlConfig.idpXml = xml;
  }
  backendOld.workspace.configureSamlSso({ xml });
};

const copyEntityId = () => copyAndNotify("Entity ID", environmentStore.samlEntityId);

const copyAcsUrl = () => copyAndNotify("ACS URL", environmentStore.samlAcsUrl);

const copyScimUrl = () => copyAndNotify("SCIM URL", environmentStore.scimUrl);
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex justify-between">
          <div class="flex gap-x-4">
            <h2 class="flex select-none items-center text-xl text-md">Identity</h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <h3 class="mb-2 flex select-none items-center text-lg text-md">SAML SSO</h3>
        <div class="select-none text-sm/relaxed text-lt">
          <p>Allow users in your organization to use SAML single sign-on (SSO) to log in.</p>
          <br />
          <p>
            Read
            <a href="https://help.itsdart.com/articles/9857197-saml-sso" target="_blank" rel="noopener noreferrer">
              <span class="underline text-vlt">this help center article</span>
            </a>
            for more information on how to get this configured.
          </p>
        </div>
        <div class="my-4 flex flex-col">
          <Toggle :value="tenantStore.samlEnabled" label="Enable SAML SSO" @update="toggleSamlEnabled" />
        </div>
        <template v-if="tenantStore.samlEnabled && tenantStore.samlConfig">
          <SettingSection title="Entity ID" class="!mx-0 !my-4">
            <div class="flex gap-1 text-lt">
              <pre
                class="grow rounded px-3 py-[7px] text-xs bg-lt"
                @click="copyEntityId"
                @keydown.enter="copyEntityId"
                >{{ environmentStore.samlEntityId }}</pre
              >
              <Tooltip text="Copy Entity ID">
                <Button
                  :btn-style="ButtonStyle.CHIP"
                  :icon="CopyIcon"
                  a11y-label="Copy Entity ID"
                  @click="copyEntityId" />
              </Tooltip>
            </div>
          </SettingSection>
          <SettingSection title="ACS URL" class="!mx-0 !my-4">
            <div class="flex gap-1 text-lt">
              <pre class="grow rounded px-3 py-[7px] text-xs bg-lt" @click="copyAcsUrl" @keydown.enter="copyAcsUrl">{{
                environmentStore.samlAcsUrl
              }}</pre>
              <Tooltip text="Copy ACS URL">
                <Button :btn-style="ButtonStyle.CHIP" :icon="CopyIcon" a11y-label="Copy ACS URL" @click="copyAcsUrl" />
              </Tooltip>
            </div>
          </SettingSection>
          <div class="my-4 flex flex-col">
            <span class="select-none font-semibold text-md">IdP Details</span>
            <div class="mb-2 flex items-center">
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                text="URL"
                :text-style="idpDetailsOnUrl ? undefined : 'text-vlt'"
                borderless
                class="mt-[9px] rounded-b-none border-b-2 !px-2 pb-1"
                :class="idpDetailsOnUrl && 'border-b-primary-base'"
                @click="setIdpDetailsOnUrl(true)" />
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                text="XML"
                borderless
                :text-style="!idpDetailsOnUrl ? undefined : 'text-vlt'"
                class="mt-[9px] rounded-b-none border-b-2 !px-2 pb-1"
                :class="!idpDetailsOnUrl && 'border-b-primary-base'"
                @click="setIdpDetailsOnUrl(false)" />
            </div>
            <Input
              v-if="idpDetailsOnUrl"
              ref="urlInput"
              :init-value="tenantStore.samlConfig.idpUrl"
              input-type="url"
              hide-label
              label="Link URL"
              placeholder="Type or paste in the URL of the IdP metadata"
              :validate="validateUrl" />
            <template v-else>
              <textarea
                ref="xmlTextarea"
                v-model="newIdpXml"
                aria-label="XML"
                class="min-h-20 w-full rounded border text-sm bg-std text-md focus-ring-none placeholder:text-vlt focus:border-primary-base dark:focus:border-primary-base"
                :class="{
                  'border-danger-base hover:border-danger-hover-light dark:hover:border-danger-hover-dark':
                    xmlTouchedAndError,
                  'border-md hover:border-hvy': !xmlTouchedAndError,
                }"
                placeholder="Type or paste in XML describing the IdP metadata"
                @focus="xmlTouched = false"
                @focusout="xmlTouched = true" />
              <FormError :msg="xmlError" :show="xmlTouchedAndError" />
            </template>
            <Button
              :btn-style="ButtonStyle.PRIMARY"
              text="Save"
              :disabled="
                idpDetailsOnUrl
                  ? !urlInput?.isValid || urlInput?.value === tenantStore.samlConfig.idpUrl
                  : newIdpXml === tenantStore.samlConfig.idpXml || !!xmlError
              "
              class="self-end"
              @click="changeSamlDetails" />
          </div>
        </template>
      </div>
      <div class="flex flex-col">
        <h3 class="mb-2 flex select-none items-center text-lg text-md">SCIM</h3>
        <div class="select-none text-sm/relaxed text-lt">
          <p>Use SCIM provisioning to automatically create, update, and delete users in your organization.</p>
          <br />
          <p>
            Read
            <a href="https://help.itsdart.com/articles/9855051-scim" target="_blank" rel="noopener noreferrer">
              <span class="underline text-vlt">this help center article</span>
            </a>
            for more information on how to get this configured.
          </p>
        </div>
        <SettingSection title="SCIM URL" class="!mx-0 !my-4">
          <div class="flex gap-1 text-lt">
            <pre class="grow rounded px-3 py-[7px] text-xs bg-lt" @click="copyScimUrl" @keydown.enter="copyScimUrl">{{
              environmentStore.scimUrl
            }}</pre>
            <Tooltip text="Copy SCIM URL">
              <Button :btn-style="ButtonStyle.CHIP" :icon="CopyIcon" a11y-label="Copy SCIM URL" @click="copyScimUrl" />
            </Tooltip>
          </div>
        </SettingSection>
        <SettingSection title="Authentication token" class="!mx-0 !my-4">
          <AuthToken />
        </SettingSection>
      </div>
    </div>
  </div>
</template>
