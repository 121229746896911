<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import Input from "~/components/dumb/Input.vue";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "~/icons";
import { callDesktop, listenDesktop } from "~/utils/desktop";

/* do not import anything complex (e.g. components that rely on stores) here because they won't work */

const searchInput = ref<InstanceType<typeof Input> | null>(null);

const dark = ref(false);
const activeMatch = ref(0);
const matches = ref(0);

const disabled = computed(() => matches.value === 0);

/* Get matches */
listenDesktop(DESKTOP.onSearchResult, (obj) => {
  activeMatch.value = obj.activeMatch;
  matches.value = obj.matches;
});

/* Focus and set theme */
listenDesktop(DESKTOP.onToggleSearch, (obj) => {
  searchInput.value?.focus();
  dark.value = obj.dark;
});

/* Highlight next match */
const next = () => {
  if (searchInput.value) {
    DESKTOP.onSearch(searchInput.value.value, true, activeMatch.value !== matches.value);
  }
};

/* Highlight previous match */
const prev = () => {
  if (searchInput.value) {
    DESKTOP.onSearch(searchInput.value.value, true, activeMatch.value !== 1);
  }
};

/* Close */
const close = () => {
  DESKTOP.toggleSearch(false, dark.value);
  searchInput.value?.clear();
  activeMatch.value = 0;
  matches.value = 0;
};

const focus = () => {
  searchInput.value?.focus();
};

/* Search */
const onKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    close();
    return;
  }
  if (event.key === "Enter") {
    if (event.shiftKey) {
      prev();
    } else {
      next();
    }
  }
};

const onSearch = (newValue: string) => {
  callDesktop(() => DESKTOP.onSearch(newValue, true, true));
};

onMounted(() => {
  searchInput.value?.focus();
});
</script>

<template>
  <div class="size-full p-1.5 app-drag-none" :class="dark && 'dark'" @click="focus" @keydown.enter="focus">
    <div
      class="flex grow select-none items-center justify-center gap-3 rounded-lg border px-3 py-2 shadow-md bg-lt border-hvy dark:shadow-zinc-50/10">
      <Input
        ref="searchInput"
        label="Search"
        hide-label
        class="size-full"
        input-classes="border-none !p-0"
        @enter="onKeydown"
        @change="onSearch" />
      <div v-if="searchInput?.value" class="text-xs text-hvy">{{ `${activeMatch}/${matches}` }}</div>
      <div class="h-6 w-px bg-hvy" />
      <div class="flex gap-2" @click.stop @keydown.enter.stop>
        <button
          type="button"
          :disabled="disabled"
          class="rounded p-0.5"
          :class="disabled ? 'opacity-50' : 'hover:bg-md'"
          aria-label="Previous"
          @click="prev">
          <ChevronUpIcon class="text-hvy icon-md" />
        </button>
        <button
          type="button"
          :disabled="disabled"
          class="rounded p-0.5"
          :class="disabled ? 'opacity-50' : 'hover:bg-md'"
          aria-label="Next"
          @click="next">
          <ChevronDownIcon class="text-hvy icon-md" />
        </button>
        <button type="button" class="rounded p-0.5 hover:bg-md" aria-label="Close" @click="close">
          <XIcon class="text-hvy icon-md" />
        </button>
      </div>
    </div>
  </div>
</template>
