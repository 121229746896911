import { backendOld } from "~/api";
import { BillingPeriod } from "~/shared/enums";

export const checkout = async (returnPath: string, period?: BillingPeriod) => {
  const { data } = await backendOld.billing.createCheckoutSession(period ?? BillingPeriod.MONTHLY, returnPath);
  window.location.href = data.redirectUrl;
};

export const manageBilling = async () => {
  const { data } = await backendOld.billing.createPortalSession();
  window.location.href = data.redirectUrl;
};
