import { dismiss } from "~/components/notifications/notification";

export type Action = {
  id: string;
  message: { 0: string; 1: string };
  modelType: string;
  duid?: string | string[];
  field?: string | string[];
  undo: () => void;
  redo: () => void;
};

export default () => {
  let undo: Action[] = [];
  let redo: Action[] = [];

  return {
    clearStack: () => {
      undo = [];
      redo = [];
    },
    push: (value: Action) => {
      undo.push(value);
      redo = [];
    },
    undoById: (id: string) => {
      const action = undo.find((a) => a.id === id);
      if (!action) {
        return false;
      }

      undo = undo.filter((a) => a.id !== id);
      action.undo();
      redo.push(action);
      return true;
    },
    undo: () => {
      if (undo.length === 0) {
        return null;
      }

      const action = undo.pop() as Action;
      redo.push(action);
      return action;
    },
    redo: () => {
      if (redo.length === 0) {
        return null;
      }

      const action = redo.pop() as Action;
      undo.push(action);
      return action;
    },
    /** Invalidate actions by modelType, duid, field or any combination of those.
        @example invalidateAction("task", "random-duid", "title") // invalidate any past title changes.
        @example invalidateAction("task", "random-duid") // invalidate any past changes to any field of the task.
        @example invalidateAction("task") // invalidate any past changes to any task.
      */
    invalidateActions: (modelType: string, duid?: string, field?: string) => {
      const filterFn = (action: Action) => {
        let verdict = true;

        if (action.modelType === modelType && !duid) {
          verdict = false;
        }
        if (
          action.modelType === modelType &&
          (Array.isArray(duid) ? action.duid?.includes(duid) : action.duid === duid) &&
          !field
        ) {
          verdict = false;
        }
        if (
          action.modelType === modelType &&
          (Array.isArray(duid) ? action.duid?.includes(duid) : action.duid === duid) &&
          (Array.isArray(field) ? action.field?.includes(field) : action.field === field)
        ) {
          verdict = false;
        }

        // Remove notification if the action got invalidated.
        if (!verdict) {
          dismiss(action.id);
        }
        return verdict;
      };

      undo = undo.filter(filterFn);
      redo = redo.filter(filterFn);
    },
  };
};
