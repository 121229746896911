<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpdateTime from "~/components/dumb/UpdateTime.vue";
import { UNKNOWN_USER_LABEL } from "~/components/visualization/constants";
import { DotsHorizontalIcon } from "~/icons";
import { ButtonStyle, EditorMode, IconSize, Placement } from "~/shared/enums";
import type { Doc } from "~/shared/types";
import { usePageStore } from "~/stores";

const props = defineProps<{
  doc: Doc;
}>();
const pageStore = usePageStore();

const optionsDropdownSections = computed(() => actions.context.doc(props.doc));
</script>

<template>
  <div class="flex items-center gap-3 app-drag-none">
    <UpdateTime v-if="!pageStore.isMobile" :at="doc.updatedAt" prefix="Updated" />
    <AvatarGroup
      v-if="doc.editedByAi || doc.editorDuids.length > 0"
      :ai="doc.editedByAi"
      :duids="doc.editorDuids"
      :editor-mode="EditorMode.FOLDER"
      tooltip-bottom
      :unset-label="UNKNOWN_USER_LABEL" />
    <DropdownMenu :sections="optionsDropdownSections" :placement="Placement.BOTTOM_RIGHT" :distance="2">
      <Tooltip text="More options">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :icon="DotsHorizontalIcon"
          :icon-size="IconSize.S"
          borderless
          class="!p-px"
          a11y-label="More options" />
      </Tooltip>
    </DropdownMenu>
  </div>
</template>
