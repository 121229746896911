<script setup lang="ts">
import { useMediaQuery, useResizeObserver } from "@vueuse/core";
import { type Component, computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ChevronDownIcon } from "~/icons";
import { type ButtonStyle, type CommandId, type IconSize, Placement } from "~/shared/enums";
import type { DropdownMenuSection } from "~/shared/types";

type IButton = InstanceType<typeof Button>;

defineProps<{
  dropdownSections: DropdownMenuSection[];
  btnStyle?: ButtonStyle;
  text?: string;
  icon?: Component;
  dropdownIcon?: Component;
  iconSize?: IconSize;
  right?: boolean;
  commandId?: CommandId;
  borderless?: boolean;
}>();

const emit = defineEmits<{
  click: [];
}>();

const buttonRef = ref<IButton | null>(null);
const buttonEl = computed(() => buttonRef.value?.$el);

const menuSkidding = ref(0);

const buttonResize = () => {
  if (!buttonEl.value) {
    return;
  }
  const bound = buttonEl.value.getBoundingClientRect();
  menuSkidding.value = -bound.width;
};

const assignButtonRef = (elem: IButton | null) => {
  buttonRef.value = elem;
  buttonResize();
};

const isPrinting = useMediaQuery("print");

useResizeObserver(buttonEl, buttonResize);
</script>

<template>
  <div v-if="!isPrinting" class="flex items-center" @click.stop>
    <Tooltip :command-id="commandId">
      <Button
        :ref="(elem) => assignButtonRef(elem as IButton | null)"
        :btn-style="btnStyle"
        :text="text"
        :icon="icon"
        :icon-size="iconSize"
        :borderless="borderless"
        :class="{ 'rounded-r-none py-0.5 pr-1.5': !borderless, 'border-0': borderless }"
        a11y-label="Action"
        @click="emit('click')" />
    </Tooltip>
    <DropdownMenu
      :sections="dropdownSections"
      :distance="0"
      :skidding="right ? 0 : menuSkidding"
      :placement="right ? Placement.BOTTOM_RIGHT : undefined"
      @click.stop>
      <Button
        :btn-style="btnStyle"
        :icon="dropdownIcon ?? ChevronDownIcon"
        :icon-size="iconSize"
        a11y-label="Dropdown menu"
        :borderless="borderless"
        :class="{ 'rounded-l-none border-l-0 py-0.5 pr-1': !borderless, 'border-0': borderless }" />
    </DropdownMenu>
  </div>
</template>
