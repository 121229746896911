<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, ref } from "vue";

import type { Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";
import { getMsUntilNext, getRelativeTimeForDatesDate } from "~/utils/time";

import HomeTaskRow from "./HomeTaskRow.vue";

const dataStore = useDataStore();

const tasks = computed(() => dataStore.getTaskList());

// Refresh mechanism
const now = ref(new Date());
let timeout: ReturnType<typeof setTimeout> | undefined;
const resetValuesAndTimeout = () => {
  now.value = new Date();
  clearTimeout(timeout);
  timeout = setTimeout(resetValuesAndTimeout, getMsUntilNext("day"));
};
resetValuesAndTimeout();
onUnmounted(() => {
  clearTimeout(timeout);
});

const upcomingTasks = computed(() => {
  const today = now.value;
  const nextWeek = moment().add(7, "days");

  const tasksByDay: Map<string, [string, Task[]]> = new Map();
  tasks.value.forEach((task: Task) => {
    if (!task.dueAt) {
      return;
    }

    if (moment(task.dueAt).isBetween(today, nextWeek, "day", "[]")) {
      const label = getRelativeTimeForDatesDate(task.dueAt);
      if (!tasksByDay.has(label)) {
        tasksByDay.set(label, [task.dueAt, []]);
      }

      tasksByDay.get(label)?.[1]?.push(task);
    }
  });

  return Array.from(tasksByDay.entries())
    .sort(makeStringComparator((e) => e[1][0]))
    .map(([k, v]) => [k, v[1]] satisfies [string, Task[]]);
});
</script>

<template>
  <div class="flex h-80 w-full flex-col gap-4 rounded-xl py-4 pl-4 bg-lt">
    <div v-if="upcomingTasks.length > 0" class="flex w-full flex-col gap-5 overflow-y-scroll pr-px">
      <div v-for="[label, tasksGroup] in upcomingTasks" :key="label" class="flex flex-col gap-3">
        <div class="flex h-6 items-center">
          <span class="select-none truncate text-sm/[18px] text-lt">{{ label }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <HomeTaskRow v-for="task in tasksGroup" :key="task.duid" :task="task" />
        </div>
      </div>
      <div class="min-h-12 w-full" />
    </div>
    <div v-else class="flex h-full select-none items-center justify-center px-4 pb-6 text-center text-lg text-vlt">
      There are no tasks due in the coming week
    </div>
  </div>
</template>
