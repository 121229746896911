<script setup lang="ts">
import { hideAllPoppers } from "floating-vue";
import { computed, nextTick, ref, watch } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import { Placement } from "~/shared/enums";
import { useAppStore } from "~/stores";
import { makeDuid } from "~/utils/common";

const appStore = useAppStore();

const dropdown = ref<InstanceType<typeof DropdownMenu> | null>(null);
const key = ref(makeDuid());

watch(
  () => appStore.contextMenu,
  (contextMenu) => {
    if (contextMenu) {
      key.value = makeDuid();
      hideAllPoppers();
      nextTick(() => {
        dropdown.value?.open();
      });
    }
  }
);

const offsets = computed(() => {
  let distance = 0;
  let skidding = 0;

  if (!appStore.contextMenu) {
    return { distance, skidding };
  }

  if (appStore.contextMenu.offsetX) {
    distance += appStore.contextMenu.offsetX - appStore.contextMenu.node.clientWidth;
  }

  if (appStore.contextMenu.offsetY) {
    skidding = appStore.contextMenu.offsetY;
  }

  return { distance, skidding };
});
</script>

<template>
  <DropdownMenu
    ref="dropdown"
    :key="key"
    :reference-node="appStore.contextMenu?.node"
    :sections="appStore.contextMenu?.sections ?? []"
    :distance="offsets.distance"
    :skidding="offsets.skidding"
    :placement="Placement.RIGHT_TOP"
    :width-pixels="appStore.contextMenu?.widthPixels"
    :max-height-pixels="appStore.contextMenu?.maxHeightPixels" />
</template>
