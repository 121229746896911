<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import {
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_ENTER_COMMAND,
  type LexicalCommand,
  type RangeSelection,
  TextNode,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import { TEXT_FORMATS } from "../const";

const editor = useLexicalComposer();

const stripAllTextFormats = (selectionOrTextNode: RangeSelection | TextNode) =>
  TEXT_FORMATS.forEach((format) => {
    if (selectionOrTextNode.hasFormat(format)) {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
    }
  });

const handleEnter = () => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return;
  }

  stripAllTextFormats(selection);
};

const handleBackspace = () => {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return;
  }

  if (selection.anchor.getNode().getTextContent().length > 1) {
    return;
  }

  stripAllTextFormats(selection);
};

const handleArrowRight = () => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || selection.getTextContent() !== "") {
    return;
  }

  const node = selection.anchor.getNode();

  if (!$isTextNode(node) || node.getTextContentSize() === 0 || node.getFormat() === 0) {
    return;
  }

  const textContent = node.getTextContent();
  const indexOfCursor = selection.anchor.offset;

  // Not at the end of the line
  if (indexOfCursor !== textContent.length) {
    return;
  }
  const nextSibling = node.getNextSibling();

  if (nextSibling) {
    node.selectNext(0, 0);
    return;
  }

  const textNode = $createTextNode(" ");
  selection.insertNodes([textNode]);
  stripAllTextFormats(selection);
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    ...(
      [
        [KEY_ENTER_COMMAND, handleEnter],
        [KEY_BACKSPACE_COMMAND, handleBackspace],
        [KEY_ARROW_RIGHT_COMMAND, handleArrowRight],
      ] satisfies [LexicalCommand<never>, () => void][]
    ).map(([command, handler]) =>
      editor.registerCommand(
        command,
        () => {
          handler();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
