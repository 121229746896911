import moment from "moment";
import { defineStore } from "pinia";

import {
  Entitlement,
  Feature,
  GithubIntegrationStatus,
  NotionIntegrationStatus,
  SamlConfigStatus,
  SlackIntegrationStatus,
  Subscription,
} from "~/shared/enums";
import type { Tenant, TenantEntitlements } from "~/shared/types";
import { getFeatureOption } from "~/utils/common";

type State = Tenant & { _internalNow: string };

const DEFAULT_PERSONAL_ENTITLEMENTS: TenantEntitlements = {
  [Entitlement.TRIAL_DAYS]: 0,
  [Entitlement.MAX_USERS]: 4,
  [Entitlement.ROLES]: false,
  [Entitlement.STATUSES]: false,
  [Entitlement.MAX_CUSTOM_PROPERTIES]: 1,
  [Entitlement.MAX_FORMS]: 1,
  [Entitlement.MAX_TASK_KINDS]: 5,
  [Entitlement.DISCORD]: false,
  [Entitlement.GITHUB]: false,
  [Entitlement.SLACK]: false,
  [Entitlement.WEBHOOKS]: false,
  [Entitlement.ZAPIER]: false,
};
const DEFAULT_PREMIUM_ENTITLEMENTS: TenantEntitlements = {
  [Entitlement.TRIAL_DAYS]: Infinity,
  [Entitlement.MAX_USERS]: Infinity,
  [Entitlement.ROLES]: true,
  [Entitlement.STATUSES]: true,
  [Entitlement.MAX_CUSTOM_PROPERTIES]: Infinity,
  [Entitlement.MAX_FORMS]: Infinity,
  [Entitlement.MAX_TASK_KINDS]: Infinity,
  [Entitlement.DISCORD]: true,
  [Entitlement.GITHUB]: true,
  [Entitlement.SLACK]: true,
  [Entitlement.WEBHOOKS]: true,
  [Entitlement.ZAPIER]: true,
};

const useTenantStore = defineStore("TenantStore", {
  // TODO initializing all of these is a type hack that should be fixed
  state: (): State =>
    ({
      duid: "",
      isDart: false,
      name: "",
      createdAt: "",
      _internalNow: "",
      timezone: "",
      subscription: Subscription.PERSONAL,
      entitlementOverrides: {},
      imageUrl: null,
      backlogEnabled: true,
      aiAssignmentEnabled: true,
      emailIntegrationEnabled: false,
      closeParentOnCloseAllSubtasks: true,
      moveSubtasksOnMoveParent: true,
      updateSubtasksStatusOnUpdateParentStatus: false,
      copyParentFieldsOnCreate: true,
      updateBlockeeDatesOnUpdateBlockerDueDate: true,
      webhookEnabled: false,
      webhookSecret: "",
      samlConfig: null,
      notionIntegration: null,
      slackIntegration: null,
      discordIntegration: null,
      githubIntegration: null,
      zapierIntegration: null,
    }) as State,
  getters: {
    assigneeEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultAssigneesProperty);
    },
    multipleAssigneesEnabled(): boolean {
      return this.$useDataStore().defaultAssigneesProperty.adtl.isMultiple;
    },
    tagsEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultTagsProperty);
    },
    priorityEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultPriorityProperty);
    },
    dueDateEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultDatesProperty);
    },
    startDateEnabled(): boolean {
      const datesProperty = this.$useDataStore().defaultDatesProperty;
      return this.$useAppStore().isPropertyShown(datesProperty) && datesProperty.adtl.isRange;
    },
    sizeEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultSizeProperty);
    },
    createdAtEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultCreatedAtProperty);
    },
    createdByEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultCreatedByProperty);
    },
    updatedAtEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultUpdatedAtProperty);
    },
    updatedByEnabled(): boolean {
      return this.$useAppStore().isPropertyShown(this.$useDataStore().defaultUpdatedByProperty);
    },
    trialActive(): boolean {
      return (
        moment(this._internalNow).diff(moment(this.createdAt), "days", true) <
        this.getEntitlementValue(Entitlement.TRIAL_DAYS)
      );
    },
    isPremiumWithoutTrial(): boolean {
      return this.isDart || this.subscription === Subscription.PREMIUM;
    },
    isPremium(): boolean {
      return this.isPremiumWithoutTrial || this.trialActive;
    },
    getEntitlementValue() {
      return <T extends Entitlement>(entitlement: T) => {
        let isPremium = this.isPremiumWithoutTrial;
        if (!isPremium && entitlement !== Entitlement.TRIAL_DAYS) {
          isPremium = this.trialActive;
        }

        if (isPremium) {
          return DEFAULT_PREMIUM_ENTITLEMENTS[entitlement] as Exclude<TenantEntitlements[T], undefined>;
        }

        if (entitlement in this.entitlementOverrides) {
          const res = this.entitlementOverrides[entitlement];
          return (res !== null ? res : Infinity) as Exclude<TenantEntitlements[T], undefined>;
        }

        if (entitlement === Entitlement.TRIAL_DAYS && getFeatureOption(Feature.REVERSE_TRIAL, this.duid, 2) === 1) {
          return 14;
        }
        return DEFAULT_PERSONAL_ENTITLEMENTS[entitlement] as Exclude<TenantEntitlements[T], undefined>;
      };
    },
    samlEnabled(): boolean {
      return this.samlConfig?.status === SamlConfigStatus.ENABLED;
    },
    githubIntegrationEnabled(): boolean {
      return this.githubIntegration?.status === GithubIntegrationStatus.ENABLED;
    },
    slackIntegrationEnabled(): boolean {
      return this.slackIntegration?.status === SlackIntegrationStatus.ENABLED;
    },
  },
  actions: {
    setTenant(tenant: Tenant) {
      this.duid = tenant.duid;
      this.isDart = tenant.isDart;
      this.name = tenant.name;
      this.createdAt = tenant.createdAt;
      this.timezone = tenant.timezone;
      this.subscription = tenant.subscription;
      this.entitlementOverrides = tenant.entitlementOverrides;
      this.imageUrl = tenant.imageUrl;
      this.backlogEnabled = tenant.backlogEnabled;
      this.aiAssignmentEnabled = tenant.aiAssignmentEnabled;
      this.emailIntegrationEnabled = tenant.emailIntegrationEnabled;
      this.closeParentOnCloseAllSubtasks = tenant.closeParentOnCloseAllSubtasks;
      this.moveSubtasksOnMoveParent = tenant.moveSubtasksOnMoveParent;
      this.updateSubtasksStatusOnUpdateParentStatus = tenant.updateSubtasksStatusOnUpdateParentStatus;
      this.copyParentFieldsOnCreate = tenant.copyParentFieldsOnCreate;
      this.updateBlockeeDatesOnUpdateBlockerDueDate = tenant.updateBlockeeDatesOnUpdateBlockerDueDate;
      this.webhookEnabled = tenant.webhookEnabled;
      this.webhookSecret = tenant.webhookSecret;
      this.samlConfig = tenant.samlConfig;
      this.notionIntegration = tenant.notionIntegration;
      if (this.notionIntegration) {
        this.notionIntegration.enabled = tenant.notionIntegration?.status === NotionIntegrationStatus.ENABLED;
      }
      this.slackIntegration = tenant.slackIntegration;
      this.discordIntegration = tenant.discordIntegration;
      this.githubIntegration = tenant.githubIntegration;
      this.zapierIntegration = tenant.zapierIntegration;

      this._internalNow = moment().toISOString();
    },
    updateClock() {
      this._internalNow = moment().toISOString();
    },
    deleteTenant() {
      const userStore = this.$useUserStore();
      userStore.forceLogout();
    },
  },
});

export default useTenantStore;
