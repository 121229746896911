<script setup lang="ts" generic="Item extends ItemBase, T extends Constructable">
import { useAutoAnimate } from "@formkit/auto-animate/vue";
import {
  type Component,
  computed,
  getCurrentInstance,
  nextTick,
  onMounted,
  onUnmounted,
  type Ref,
  ref,
  watch,
} from "vue";
import { VueDraggableNext as Draggable } from "vue-draggable-next";

import { colorsByTheme } from "~/constants/style";
import { AnimationDuration } from "~/shared/enums";
import type { DragChangeEvent } from "~/shared/types";
import type { Constructable } from "~/shared/typeUtils";
import { useAppStore, usePageStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

export type ItemBase = {
  duid: string;
  title: string;
  order: string;
  // Disable dragging of item.
  undraggable?: boolean;
};

const props = defineProps<{
  disabled?: boolean;
  noReorder?: boolean;
  // Group of areas. Items can be dragged between areas with the same group name.
  group: string;
  // Category of items. Used to identify the area.
  category: string;
  items: Item[];
  component: T;
  getComponentProps: (item: Item, index: number) => InstanceType<T>["$props"];
  // Classes for the drop area.
  dropAreaClasses?: string;
  // Color for the element in the preview position.
  placeholderColor?: string;
  horizontal?: boolean;
  // Can only drag by elements with "dart-handle" class.
  dragHandle?: boolean;
}>();

const emit = defineEmits<{
  change: [category: string, item: Item];
  dataTransfer: [dataTransfer: DataTransfer, element: HTMLElement];
}>();

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const pageStore = usePageStore();

const disabledNorm = computed(() => props.disabled || pageStore.isMobile || pageStore.isPublicView);

// Make a copy of items, so that the original array is not mutated.
const innerItems = ref<Item[]>(props.items ?? []) as Ref<Item[]>;
watch(
  () => props.items,
  (newItems) => {
    innerItems.value = newItems ?? [];
  },
  { deep: true }
);
const reset = () => {
  innerItems.value = props.items ?? [];
};

// Enable animation.
const [parent, enableAnimation] = useAutoAnimate({ duration: AnimationDuration.MEDIUM });
// Set parent element for animating. Getting wrapper ref from draggable is broken.
onMounted(() => {
  if (!currentInstance) {
    return;
  }
  parent.value = (currentInstance.refs.categoryRef as { $el: Element })?.$el;
});
// Disable animation if another Drag component is being dragged.
watch(
  () => appStore.dragging,
  (newDragging, oldDragging) => {
    if (oldDragging?.category === props.category) {
      return;
    }
    enableAnimation(!newDragging);
  }
);

const getItemOrder = (list: Item[], newIndex: number) => {
  const itemAboveDest = newIndex === 0 ? undefined : list[newIndex - 1]?.order;
  const itemBelowDest = newIndex < list.length - 1 ? list[newIndex + 1]?.order : undefined;

  return getOrdersBetween(itemAboveDest, itemBelowDest)[0];
};

const change = (id: string, list: Item[], event: DragChangeEvent<Item>) => {
  if (!event) {
    return;
  }
  if (event.added && event.added.element) {
    const item = event.added.element;
    const newOrder = getItemOrder(list, event.added.newIndex);
    emit("change", id, { ...item, order: newOrder });
  } else if (event.moved && event.moved.element) {
    // Disable animation on move.
    nextTick(() => {
      enableAnimation(false);
      nextTick(() => enableAnimation(true));
    });

    const item = event.moved.element;
    const newOrder = getItemOrder(list, event.moved.newIndex);
    emit("change", id, { ...item, order: newOrder });
  }
};

// Draggable element refs.
const elementRefs = ref<Component[]>([]);
// Item component refs.
const itemRefs = ref<T[]>([]) as Ref<InstanceType<T>[]>;

const dragging = ref(false);
const dragStart = (event: { clone: HTMLElement; originalEvent: DragEvent }) => {
  dragging.value = true;

  setTimeout(() => {
    document.body.classList.add("dragging-item");
  });

  // Set data attribute on element to know which item is being dragged.
  if (event.originalEvent?.target) {
    (event.originalEvent.target as HTMLDivElement)?.setAttribute("data-dragged-item", "true");
  }

  // Disable animation.
  appStore.dragging = { group: props.group, category: props.category };
  enableAnimation(false);
};
const dragEnd = () => {
  dragging.value = false;
  document.body.classList.remove("dragging-item");

  // Remove data attribute on dragged item.
  Array.from(parent.value.querySelectorAll("[data-dragged-item]")).forEach((el) =>
    el.removeAttribute("data-dragged-item")
  );

  // Enable animation.
  appStore.dragging = null;
  enableAnimation(true);
};

const colors = computed(() => ({
  placeholder: props.placeholderColor ?? colorsByTheme[pageStore.theme].bgLt,
}));

const setData = (dataTransfer: DataTransfer, element: HTMLElement) => {
  const item = innerItems.value.find((i) => i.duid === element.id);
  if (!item) {
    return;
  }

  const ghost = element.cloneNode(true) as HTMLDivElement;
  ghost.classList.add("rounded");
  ghost.style.backgroundColor = colors.value.placeholder;
  ghost.style.maxWidth = `${element.offsetWidth}px`;
  ghost.style.maxHeight = `${element.offsetHeight}px`;
  document.body.appendChild(ghost);
  dataTransfer.setDragImage(ghost, 10, 1);
  setTimeout(() => {
    document.body.removeChild(ghost);
  });

  dataTransfer.setData("text/plain", item.title);
  dataTransfer.setData("application/json", JSON.stringify(item));

  emit("dataTransfer", dataTransfer, element);
};

onUnmounted(() => {
  dragEnd();
});

defineExpose({
  elementRefs,
  itemRefs,
  reset,
});
</script>

<template>
  <Draggable
    :id="`${group}-${category}`"
    ref="categoryRef"
    v-model="innerItems"
    :disabled="disabledNorm"
    tag="div"
    class="dart-drag flex size-full transition-colors duration-150"
    :class="[
      appStore.dragging?.group === group && (dropAreaClasses ? dropAreaClasses : 'dart-drag-drop-area'),
      horizontal ? 'flex-wrap' : 'flex-col',
    ]"
    ghost-class="dart-drag-placeholder"
    draggable=".dart-draggable-item"
    :handle="dragHandle ? '.dart-handle' : undefined"
    filter=".dart-no-drag"
    :prevent-on-filter="false"
    direction="vertical"
    :swap-threshold="0.7"
    :inverted-swap-threshold="1"
    :animation="200"
    :group="group"
    :set-data="setData"
    :move="() => !noReorder"
    @start="dragStart"
    @end="dragEnd"
    @change="(e: DragChangeEvent<Item>) => change(category, innerItems, e)">
    <slot v-if="innerItems.length && !dragging" name="before" class="dart-no-drag" />

    <!-- No items state -->
    <div v-if="!innerItems.length" :id="`${group}-${category}-noitems`" class="hidden select-none only:block">
      <slot />
    </div>
    <!-- Items -->
    <div
      v-for="(item, index) in innerItems"
      :id="item.duid"
      ref="elementRefs"
      :key="item.duid"
      :class="[
        {
          '!hover:pointer-events-none': dragging,
          'dart-no-drag': item.undraggable,
          'size-auto grow-0': horizontal,
        },
      ]"
      class="dart-draggable-item outline-none"
      tabindex="-1">
      <component
        :is="component"
        ref="itemRefs"
        :class="dragging && 'pointer-events-none'"
        v-bind="getComponentProps(item, index)" />
    </div>
    <slot v-if="innerItems.length && !dragging" name="after" class="dart-no-drag" />
  </Draggable>
</template>

<style scoped>
/* Drop area styles */
.dart-drag-drop-area {
  @apply rounded !bg-gray-100/50;
}
.dark .dart-drag-drop-area {
  @apply !bg-zinc-800/50;
}

/* Item placeholder styles */
.dart-drag-placeholder {
  background-color: v-bind("colors.placeholder");
  @apply rounded;
}

[data-dragged-item] :deep(*) {
  @apply !opacity-0;
}

[data-dragged-item] {
  background-color: v-bind("colors.placeholder");
  @apply rounded;
}
</style>
