<script setup lang="ts">
import { useLexicalComposer } from "lexical-vue";

import RecommendationActionButtons from "~/components/dumb/RecommendationActionButtons.vue";
import { RecommendationAction } from "~/shared/enums";

import { EVENT_CLEANUP_RECOMMENDATION_NODE } from "../const";

const props = defineProps<{
  nodeKey: string;
  action: RecommendationAction;
  recommendationDuid: string;
}>();

const editor = useLexicalComposer();

const onAction = (discard: boolean) => {
  editor.dispatchCommand(EVENT_CLEANUP_RECOMMENDATION_NODE, {
    key: props.nodeKey,
    action: discard ? RecommendationAction.DISCARD : props.action,
    recommendationDuid: props.recommendationDuid,
  });
};
</script>

<template>
  <RecommendationActionButtons
    :keep-text="action === RecommendationAction.REPLACE ? 'Replace' : undefined"
    :keep-tooltip-text="
      action === RecommendationAction.REPLACE
        ? 'Replace the original description with this AI-generated content'
        : 'Add this AI-generated content to the description'
    "
    discard-tooltip-text="Discard this content"
    class="pr-[3px]"
    @keep="onAction(false)"
    @discard="onAction(true)" />
</template>
