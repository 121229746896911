<script setup lang="ts">
import type { SerializedEditorState } from "lexical";
import { computed, onUnmounted, ref, watch } from "vue";

import TitleEditor from "~/components/text/TitleEditor.vue";
import { convertToTask } from "~/components/text/utils";
import { THROTTLE_MS } from "~/constants/app";
import { EditorMode, TutorialName } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useDataStore, useUserStore } from "~/stores";
import { makeLexicalStateWithText } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  update: [value: string];
  enter: [event: KeyboardEvent];
}>();

const dataStore = useDataStore();
const userStore = useUserStore();

const titleEditor = ref<InstanceType<typeof TitleEditor> | null>(null);
const title = ref<SerializedEditorState>(makeLexicalStateWithText(props.task.title));

const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);

const saveManager = new ThrottleManager((taskDuid: string, newTitle: string) => {
  dataStore.updateTasks([{ duid: taskDuid, title: newTitle }]);
}, THROTTLE_MS);

const setTitle = (newTitle?: string) => {
  const editorState = makeLexicalStateWithText(newTitle ?? "");
  if (JSON.stringify(title.value) === JSON.stringify(editorState)) {
    return;
  }

  title.value = editorState;
  titleEditor.value?.setEditorState(title.value);
};

const finishTitleEntryTutorialStep = () =>
  userStore.updateTutorialStatuses([{ name: TutorialName.CREATE_TASK_WITH_SUBTASK_RECS, status: 3 }]);

// Update title elsewhere when title is edited here
const updateTitle = (newTitle: SerializedEditorState) => {
  title.value = newTitle;

  const partialTask = convertToTask(newTitle);
  const newTitleText = partialTask.title ?? "";
  emit("update", newTitleText);
  // eslint-disable-next-line vue/no-mutating-props
  props.task.title = newTitleText;
  saveManager.run(props.task.duid, newTitleText);

  if (newTitleText.split(" ").filter((e) => e !== "").length >= 4) {
    finishTitleEntryTutorialStep();
  }
};

// Update title to text and apply properties when title editor loses focus
const finalizeTitle = () => {
  if (!isTaskDetailMode.value) {
    finishTitleEntryTutorialStep();
  }

  const partialTask = convertToTask(title.value);
  const newTitleText = partialTask.title ?? "";
  setTitle(newTitleText);

  dataStore.updateTaskFromNlp(props.task, partialTask);
};

// Update title when task changes or when title is edited outside of this editor
watch(
  () => props.task.duid,
  () => {
    setTitle(props.task.title);
  }
);

watch(
  () => props.task.title,
  (newTitle) => {
    if (titleEditor.value?.hasFocus) {
      return;
    }
    setTitle(newTitle);
  }
);

const focus = () => titleEditor.value?.focus();

const reset = () => saveManager.cancel();

onUnmounted(() => {
  saveManager.destroy();
});

defineExpose({
  focus,
  reset,
});
</script>

<template>
  <TitleEditor
    ref="titleEditor"
    :key="task.duid"
    :initial-title="title"
    :editor-mode="editorMode"
    @change="updateTitle"
    @blur="finalizeTitle"
    @enter="(event: KeyboardEvent) => emit('enter', event)" />
</template>
