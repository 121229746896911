<script setup lang="ts">
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { ref, watch } from "vue";

import { PropertyKind } from "~/shared/enums";
import type { PropertyAnyDatesWithRangeMaybe, PropertyConfig, PropertyUpdate } from "~/shared/types";
import { useDataStore } from "~/stores";

const SINGLE_TITLE = "Due date";
const RANGE_TITLE = "Dates";

const props = defineProps<{
  property: PropertyAnyDatesWithRangeMaybe;
  propertyConfig: PropertyConfig;
}>();

const dataStore = useDataStore();

const rangeEnabled = ref(props.property.adtl.isRange);
const durationEnabled = ref(props.property.adtl.showDuration);
watch(
  () => rangeEnabled.value,
  (newRangeValue) => {
    const propertyUpdate: PropertyUpdate = {
      duid: props.property.duid,
      adtl: { ...props.property.adtl, isRange: newRangeValue },
    };
    if (props.property.kind === PropertyKind.DEFAULT_DATES) {
      if (newRangeValue && props.property.title === SINGLE_TITLE) {
        propertyUpdate.title = RANGE_TITLE;
      } else if (!newRangeValue && props.property.title === RANGE_TITLE) {
        propertyUpdate.title = SINGLE_TITLE;
      }
    }
    dataStore.updateProperty(propertyUpdate);
  }
);
watch(
  () => props.property.adtl.isRange,
  (newRangeValue) => {
    rangeEnabled.value = newRangeValue;
  }
);
watch(
  () => durationEnabled.value,
  (newDurationValue) => {
    const propertyUpdate: PropertyUpdate = {
      duid: props.property.duid,
      adtl: { ...props.property.adtl, showDuration: newDurationValue },
    };
    dataStore.updateProperty(propertyUpdate);
  }
);
watch(
  () => props.property.adtl.showDuration,
  (newDurationValue) => {
    durationEnabled.value = newDurationValue;
  }
);
</script>

<template>
  <div class="-mt-0.5 flex w-full flex-col gap-5">
    <SwitchGroup as="div" class="flex items-center justify-between">
      <span class="flex grow select-none flex-col gap-1">
        <SwitchLabel as="span" class="text-sm font-normal text-lt" passive>Date range</SwitchLabel>
        <SwitchDescription as="span" class="text-xs text-vlt">
          This will add a second date to make this property a range rather than a single date
        </SwitchDescription>
      </span>
      <Switch
        v-model="rangeEnabled"
        :class="[rangeEnabled ? 'bg-primary-base' : 'bg-md']"
        class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std">
        <span
          aria-hidden="true"
          :class="[rangeEnabled ? 'translate-x-4' : 'translate-x-0']"
          class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-sm" />
      </Switch>
    </SwitchGroup>
    <SwitchGroup v-if="rangeEnabled" as="div" class="flex items-center justify-between">
      <span class="flex grow select-none flex-col gap-1">
        <SwitchLabel as="span" class="text-sm font-normal text-lt" passive>Show duration</SwitchLabel>
        <SwitchDescription as="span" class="text-xs text-vlt">
          This will display the length of the date range next to the actual dates
        </SwitchDescription>
      </span>
      <Switch
        v-model="durationEnabled"
        :class="[durationEnabled ? 'bg-primary-base' : 'bg-md']"
        class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std">
        <span
          aria-hidden="true"
          :class="[durationEnabled ? 'translate-x-4' : 'translate-x-0']"
          class="pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-sm" />
      </Switch>
    </SwitchGroup>
  </div>
</template>
