import { createVuetify } from "vuetify";
import { VColorPicker } from "vuetify/components";

const vuetify = createVuetify({
  components: {
    VColorPicker,
  },
});

export default vuetify;
