<script setup lang="ts">
import { usePrevious } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import { TrashIcon } from "~/icons";
import { getQueryParam, makeLinkToTaskKindSettingsRef } from "~/router/common";
import { DialogMode } from "~/shared/enums";
import type { TaskKind } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";

const props = defineProps<{
  taskKind: TaskKind;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);
const previousTaskKind = usePrevious(props.taskKind);

const active = computed(() => props.taskKind.duid === getQueryParam("type"));
const link = computed(() => makeLinkToTaskKindSettingsRef(props.taskKind.duid).value);

const onDelete = () => {
  const currTaskKind = props.taskKind ?? previousTaskKind.value;
  if (!currTaskKind) {
    return;
  }

  dataStore.deleteTaskKind(currTaskKind);
  router.replace(makeLinkToTaskKindSettingsRef(dataStore.taskKindList[0]?.duid).value);
};

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, actions.context.taskKind(props.taskKind, dialog.value));
};
</script>

<template>
  <RouterLink
    :to="link"
    :class="active ? 'bg-lt hover:bg-md' : 'hover:bg-lt'"
    class="group/task-kind flex w-full items-center justify-between whitespace-nowrap rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt"
    @contextmenu="onContextMenu">
    <div class="flex overflow-hidden">
      <PageIcon :page="taskKind" class="mr-1.5" :class="[active ? 'text-primary-base' : 'text-lt', 'icon-sm']" />
      <span :title="taskKind.title" class="select-none truncate">{{ taskKind.title }}</span>
    </div>
    <ConfirmationDialog
      ref="dialog"
      :mode="DialogMode.DELETE"
      :title="`Delete ${taskKind.title}`"
      description="Are you sure you want to delete this type? This action cannot be undone."
      confirm-text="Delete"
      cancel-text="Keep"
      :icon="TrashIcon"
      @confirm="onDelete" />
  </RouterLink>
</template>
