<script setup lang="ts">
import { backendOld } from "~/api";
import Toggle from "~/components/dumb/Toggle.vue";
import { useTenantStore } from "~/stores";

type TenantStore = ReturnType<typeof useTenantStore>;

const tenantStore = useTenantStore();

const updateAutomationSetting = (settingName: keyof TenantStore, newValue: boolean) => {
  // TODO fix this type hack
  tenantStore[settingName as "closeParentOnCloseAllSubtasks"] = newValue;
  backendOld.workspace.edit(settingName, newValue);
};
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-auto w-full">
      <div class="mx-10 mb-24 mt-6">
        <Toggle
          :value="tenantStore.closeParentOnCloseAllSubtasks"
          label="Parents complete when all subtasks complete"
          description="When all of the subtasks of a given task complete, the parent will automatically complete"
          @update="(e) => updateAutomationSetting('closeParentOnCloseAllSubtasks', e)" />
      </div>
      <div class="mx-10 my-24">
        <Toggle
          :value="tenantStore.moveSubtasksOnMoveParent"
          label="Subtasks change dartboards with parent"
          description="When a parent task moves between dartboards, all of its subtasks in that dartboard will automatically move"
          @update="(e) => updateAutomationSetting('moveSubtasksOnMoveParent', e)" />
      </div>
      <div class="mx-10 my-24">
        <Toggle
          :value="tenantStore.updateSubtasksStatusOnUpdateParentStatus"
          label="Subtask statuses update with parent"
          description="When a parent status changes, all of its subtasks at or behind that status will automatically update"
          @update="(e) => updateAutomationSetting('updateSubtasksStatusOnUpdateParentStatus', e)" />
      </div>
      <div class="mx-10 my-24">
        <Toggle
          :value="tenantStore.copyParentFieldsOnCreate"
          label="New subtasks replicate parent"
          description="When a subtask is first created, it will automatically inherit most of its parent's properties"
          @update="(e) => updateAutomationSetting('copyParentFieldsOnCreate', e)" />
      </div>
      <div class="mx-10 my-24">
        <Toggle
          :value="tenantStore.updateBlockeeDatesOnUpdateBlockerDueDate"
          label="Blocked task dates update with blocker"
          description="When a blocker's due date changes, all of the tasks that are blocked will automatically update"
          @update="(e) => updateAutomationSetting('updateBlockeeDatesOnUpdateBlockerDueDate', e)" />
      </div>
    </div>
  </div>
</template>
