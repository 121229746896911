<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { computed } from "vue";

import { isGroupContainer } from "~/common/groupBy";
import { getPropertyConfig, getPropertyValueStr } from "~/common/properties";
import CollapsedGroupHeader from "~/components/visualization/list/CollapsedGroupHeader.vue";
import GroupHeader from "~/components/visualization/list/GroupHeader.vue";
import { EditorMode, TaskSourceType } from "~/shared/enums";
import type { GroupByGroup, TaskOrGroup } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

const props = defineProps<{
  params: ICellRendererParams<TaskOrGroup>;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const group = computed(() => props.params.data as GroupByGroup);
const isContainer = computed(() => isGroupContainer(group.value.id));

const collapsedGroups = computed(() =>
  appStore.groupByDefinition.groups.filter((g) => appStore.collapsedGroups.includes(getPropertyValueStr(g.value)))
);

const createTask = async () => {
  const tasks = appStore.groupByValueToTasksMap.get(getPropertyValueStr(group.value.value));
  const dartboard = appStore.currentDartboardOrDefault;
  if (!tasks || !dartboard) {
    return;
  }

  const visualization = appStore.getActiveVisualization();
  const { property } = appStore.groupByDefinition;
  const propertyConfig = getPropertyConfig(property.kind);

  const taskAboveDestOrder = tasks[0]?.order;
  /* Assign the value of the group to the task */
  const partialTask = propertyConfig.getPartialTask(property, {}, group.value.value);
  const task = await dataStore.createTask(
    "",
    dartboard.duid,
    getOrdersBetween(undefined, taskAboveDestOrder)[0],
    TaskSourceType.APP_ENTER,
    partialTask
  );

  setTimeout(() => {
    visualization.selectAndScrollTo(task.duid);
    visualization.editTitle(task.duid);
  });
};

const collapseGroup = () => {
  if (!isContainer.value) {
    props.params.node.setExpanded(false);
  }
  props.params.node.setRowHeight(null);
  props.params.api.onRowHeightChanged();
};
</script>

<template>
  <div class="group/section mt-12 flex h-fit w-full select-none pl-[29px]">
    <GroupHeader
      v-if="!isGroupContainer(group.id)"
      :group="group"
      :editor-mode="EditorMode.LIST"
      @collapse="collapseGroup"
      @create-task="createTask" />
    <div v-else class="flex w-full flex-wrap gap-3">
      <CollapsedGroupHeader
        v-for="collapsedGroup in collapsedGroups"
        :key="collapsedGroup.id"
        :group="collapsedGroup"
        :editor-mode="EditorMode.LIST" />
    </div>
  </div>
</template>
