<script setup lang="ts">
import { computed, ref } from "vue";

import { useAppStore } from "~/stores";

const props = defineProps<{
  group: string[];
  disabled?: boolean;
}>();

const emit = defineEmits<{
  // TODO Make generic
  onDrop: [group: string, item: unknown];
}>();

const appStore = useAppStore();

const groupSet = computed(() => new Set(props.group));
const dragging = computed(() => appStore.dragging && groupSet.value.has(appStore.dragging.group));
const hovering = ref(false);

const onDragEnter = (event: DragEvent) => {
  if (props.disabled || !dragging.value) {
    return;
  }

  event.preventDefault();
  hovering.value = true;
};

const onDragLeave = (event: DragEvent) => {
  if (props.disabled) {
    return;
  }

  event.preventDefault();
  hovering.value = false;
};

const onDrop = (event: DragEvent) => {
  if (props.disabled || !dragging.value || !appStore.dragging) {
    return;
  }

  hovering.value = false;
  const data = event.dataTransfer;
  if (!data || !data.types.includes("application/json")) {
    return;
  }

  emit("onDrop", appStore.dragging.group, JSON.parse(data.getData("application/json")));
};
</script>

<template>
  <div @dragover="onDragEnter" @dragenter="onDragEnter" @dragleave="onDragLeave" @drop="onDrop">
    <slot :dragging="dragging" :hovering="hovering" />
  </div>
</template>
