<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_LOW, PASTE_COMMAND } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const emit = defineEmits<{
  pasteFiles: [value: File[]];
}>();

const editor = useLexicalComposer();

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        const { clipboardData } = event;
        if (
          !clipboardData ||
          clipboardData.types.some((e) => e.startsWith("text")) ||
          clipboardData.files.length === 0
        ) {
          return false;
        }
        emit("pasteFiles", Array.from(clipboardData.files));

        return true;
      },
      COMMAND_PRIORITY_LOW
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
