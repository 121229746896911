<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";

import TaskDropdown from "~/components/docs/TaskDropdown.vue";
import Title from "~/components/docs/Title.vue";
import AiFeedbackButtons from "~/components/dumb/AiFeedbackButtons.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { notify } from "~/components/notifications";
import { DOC_TELEPORT_KEY } from "~/components/text/const";
import DocEditor from "~/components/text/DocEditor.vue";
import { THROTTLE_MS } from "~/constants/app";
import { RecommendationIcon, SlashSquareIcon } from "~/icons";
import { getQueryParam, goHome } from "~/router/common";
import {
  ButtonSize,
  ButtonStyle,
  DropdownMenuItemKind,
  FolderKind,
  IconKind,
  IconSize,
  NotificationType,
} from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getDocLink, getFolderLink, getReportsLink } from "~/utils/common";
import { getEmojiRecommendation } from "~/utils/recommendation";
import { ThrottleManager } from "~/utils/throttleManager";

const props = defineProps<{
  folderDuid: string;
  docDuid: string;
  slugSep: string;
  slug: string;
}>();

const currentInstance = getCurrentInstance();
const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const doc = computed(() => {
  const res = dataStore.getDocByDuid(props.docDuid);
  if (res?.inTrash) {
    return undefined;
  }
  return res;
});

watchEffect(() => {
  if (!doc.value) {
    const folder = dataStore.getFolderByDuid(props.folderDuid);
    nextTick(() => {
      if (folder) {
        if (folder.kind === FolderKind.REPORTS) {
          const space = dataStore.getSpaceByDuid(folder.spaceDuid);
          if (space) {
            router.push(getReportsLink(space));
            return;
          }
          goHome();
          return;
        }
        router.push(getFolderLink(folder));
        return;
      }
      goHome();
    });
    return;
  }
  appStore.setDocOpenInFullscreen(doc.value);

  setTimeout(() => {
    if (doc.value) {
      router.replace({
        params: { slugSep: "-", slug: getDocLink(doc.value).params.slug },
        query: { ...router.currentRoute.value.query },
      });
    }
  });
});

const titleRef = ref<InstanceType<typeof Title> | null>(null);
const editorRef = ref<InstanceType<typeof DocEditor> | null>(null);

const saveManager = new ThrottleManager((duid: string, title: string) => {
  dataStore.updateDocs([{ duid, title }]);
}, THROTTLE_MS);

const updateTitle = (newValue: string) => {
  if (!doc.value) {
    return;
  }

  if (newValue === doc.value.title) {
    return;
  }
  doc.value.title = newValue;
  saveManager.run(doc.value.duid, doc.value.title);
};

const finalizeTitle = async () => {
  if (!doc.value || doc.value.iconKind !== IconKind.NONE) {
    return;
  }

  const title = (saveManager.cancel()?.[1] ?? "").trim();
  if (title.length === 0) {
    return;
  }

  const emojiRecUpdate = await getEmojiRecommendation(doc.value.duid, title);
  dataStore.updateDocs([{ duid: doc.value.duid, title, ...emojiRecUpdate }]);
};

let shouldIgnoreNextClick = false;

const markClickToIgnore = () => {
  shouldIgnoreNextClick = true;
};

const ignoreOrFocus = () => {
  if (shouldIgnoreNextClick) {
    shouldIgnoreNextClick = false;
    return;
  }
  editorRef.value?.focus();
};

const focusAsNeeded = () => {
  if (!doc.value) {
    return;
  }
  if (!doc.value.title) {
    titleRef.value?.focus();
    return;
  }
  editorRef.value?.focus(true);
};

const removeFeedbackButton = () => {
  if (!doc.value?.recommendationDuid) {
    return;
  }
  dataStore.updateDocs([{ duid: doc.value.duid, recommendationDuid: null }]);
};

watch(
  () => props.docDuid,
  () => nextTick(focusAsNeeded)
);

const docAiDropdownSections = computed(() => [
  {
    title: "Doc AI",
    items: [
      {
        title: "Improve content",
        kind: DropdownMenuItemKind.BUTTON,
        icon: SlashSquareIcon,
        onClick: () => appStore.doc?.openRecommendations(),
      },
    ],
  },
]);

const openRecommendations = () => editorRef.value?.openRecommendations();

const setDetailsOnFirstLoad = async () => {
  if (getQueryParam("unsubscribe") !== "1") {
    return;
  }
  const docValue = doc.value;
  const userDuid = userStore.duid;
  if (docValue && docValue.subscriberDuids.includes(userDuid)) {
    dataStore.removeDocSubscribers(docValue.duid, [userDuid]);
    notify({
      message: `Unsubscribed from ${docValue.title}`,
      type: NotificationType.SUCCESS,
    });
  }
  const query = { ...router.currentRoute.value.query };
  delete query.unsubscribe;
  await router.replace({ query });
};

// TODO do this better, without setTimeout
setTimeout(() => {
  setDetailsOnFirstLoad();
});

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.doc = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
  focusAsNeeded();
});

onUnmounted(() => {
  appStore.doc = null;
  removeFeedbackButton();
  appStore.setDocOpenInFullscreen(null);
  saveManager.destroy();
});

defineExpose({
  openRecommendations,
});
</script>

<template>
  <div class="grow overflow-y-scroll" @click="ignoreOrFocus" @keydown.enter="ignoreOrFocus">
    <div :data-toolbar="DOC_TELEPORT_KEY" class="dart-doc-wrapper relative flex grow justify-center">
      <div v-if="doc" class="ml-8 mr-[17px] flex h-fit max-w-3xl grow flex-col gap-6 md:ml-24 md:mr-[81px]">
        <div class="group/doc-title flex flex-col gap-1" :class="pageStore.isMobile ? 'mt-6' : 'mt-12'">
          <div class="mx-1 flex items-center gap-1 opacity-0 transition-opacity group-hover/doc-title:opacity-100">
            <DropdownMenu v-if="pageStore.isOnline" :sections="docAiDropdownSections" :distance="2">
              <Tooltip text="Use AI to improve this doc">
                <Button
                  text="Doc AI"
                  :btn-style="ButtonStyle.SECONDARY_RECOMMENDATION"
                  :size="ButtonSize.CHIP"
                  :icon="RecommendationIcon"
                  :icon-size="IconSize.XS"
                  borderless
                  class="h-6 !px-1" />
              </Tooltip>
            </DropdownMenu>
            <TaskDropdown :doc="doc" />
          </div>
          <Title
            ref="titleRef"
            :title="doc.title"
            borderless
            huge
            class="mx-[-3px] w-full"
            @change="updateTitle"
            @blur="finalizeTitle"
            @enter="editorRef?.focus(true)"
            @click.stop
            @keydown.enter.stop />
        </div>
        <!-- Not using extra-overscroll because :after doesn't support clicks -->
        <div class="mb-[300px]" @click="markClickToIgnore" @keydown.enter="markClickToIgnore">
          <DocEditor ref="editorRef" :doc="doc" />
          <AiFeedbackButtons
            v-if="doc.recommendationDuid"
            :recommendation-duids="[doc.recommendationDuid]"
            @remove="removeFeedbackButton" />
        </div>
      </div>
    </div>
  </div>
</template>
