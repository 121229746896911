<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { TextNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const editor = useLexicalComposer();

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerNodeTransform(TextNode, (node) => {
      // Remove line breaks
      node.setTextContent(node.getTextContent().replace(/\n/g, " "));
      return node;
    })
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
