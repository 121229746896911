<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import { Dropdown, Menu } from "floating-vue";
import moment, { type Moment } from "moment";
import { DatePicker as DatePickerExternal } from "v-calendar";
import { computed, getCurrentInstance, nextTick, onMounted, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import RecurrenceEditor from "~/components/dumb/RecurrenceEditor.vue";
import {
  ArrowRightIcon,
  DueDateFieldIcon,
  RecommendWithAiIcon,
  ReminderIcon,
  StartDateFieldIcon,
  XIcon,
} from "~/icons";
import { ButtonStyle, EditorMode, IconSize, Placement } from "~/shared/enums";
import type { DatePickerDate, Recurrence, SimpleDateRange } from "~/shared/types";
import { usePageStore, useUserStore } from "~/stores";
import {
  getRecurrenceDatesInRange,
  getRelativeTimeForDatesDate,
  getRelativeTimeForReminder,
  isBrowserLocale24h,
} from "~/utils/time";

const props = defineProps<{
  disabled?: boolean;
  value: DatePickerDate;
  recurrence?: Recurrence | null;
  recursNextAt?: string | null;
  remindAt?: string | null;
  placement?: Placement;
  block?: boolean;
  heightBlock?: boolean;
  distance?: number;
  skidding?: number;
  cover?: boolean;
  editorMode: EditorMode;
  isStartAt?: boolean;
  propagateClickClasses?: string[];
  showRecommendationButton?: boolean;
  hideActions?: boolean;
  propertyTitle?: string;
  maxStartDate?: Date;
  preventUnset?: boolean;
  onKeydown?: (event: KeyboardEvent) => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
}>();

const emit = defineEmits<{
  select: [selectedDate: string | null];
  selectRange: [selectedRange: SimpleDateRange];
  changeRecurrence: [recurrence: Recurrence | null];
  changeRemindAt: [];
  recommend: [];
  mouseover: [event: MouseEvent];
  mouseleave: [event: MouseEvent];
  focus: [event: FocusEvent];
  blur: [event: FocusEvent];
}>();

const currentInstance = getCurrentInstance();
const pageStore = usePageStore();
const userStore = useUserStore();

const isDateRange = (value: DatePickerDate): value is SimpleDateRange => !!value && typeof value === "object";
const normalizeDate = (str: string | null | undefined) => (str ? moment(str).toDate() : null);
const areSame = (a: Date | null, b: Date | null) => (!a && !b) || (!!a && !!b && moment(a).isSame(b));

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const hideActionsNorm = computed(() => props.editorMode === EditorMode.FILTER || isFormMode.value || props.hideActions);

const placementDefinite = computed(() => props.placement ?? Placement.BOTTOM_LEFT);
const defaultDistance = computed(() => props.distance ?? (props.cover ? 0 : -8));
const calcDistance = ref(defaultDistance.value);

const dropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);
const buttonRef = ref<HTMLButtonElement | null>(null);
const recalculateDistance = () => {
  calcDistance.value = defaultDistance.value;
  if (!buttonRef.value || !props.cover) {
    return;
  }
  calcDistance.value -= buttonRef.value.getBoundingClientRect().height;
};
const assignButtonRef = (elem: HTMLButtonElement | null) => {
  buttonRef.value = elem;
  recalculateDistance();
};
useResizeObserver(buttonRef, recalculateDistance);
watch(() => props.cover, recalculateDistance);
watch(defaultDistance, recalculateDistance);

const datePicker = ref<InstanceType<typeof DatePickerExternal> | null>(null);

// v-date-picker only works with v-model, so we have to save it like this
const startSelected = ref(true);
const selectedDate = ref(normalizeDate(isDateRange(props.value) ? props.value.start : props.value));
const hasStart = computed(() => !!(typeof props.value === "object" && props.value?.start));
const hasEnd = computed(() => !!(typeof props.value === "object" && props.value?.end));

const setSelectedDate = (newValue: DatePickerDate) => {
  const newDate = normalizeDate(
    isDateRange(newValue) ? (startSelected.value ? newValue.start : newValue.end) : newValue
  );
  if (areSame(newDate, selectedDate.value)) {
    return;
  }
  selectedDate.value = newDate;
};
watch(() => props.value, setSelectedDate);
watch(startSelected, () => setSelectedDate(props.value));
watch(
  () => selectedDate.value,
  (newDate) => {
    const result = newDate ? newDate.toISOString() : null;
    if (!isDateRange(props.value)) {
      const oldValue = normalizeDate(props.value);
      if (areSame(newDate, oldValue)) {
        return;
      }

      emit("select", result);
      return;
    }

    const oldValue = normalizeDate(startSelected.value ? props.value.start : props.value.end);
    if (areSame(newDate, oldValue)) {
      return;
    }
    if (
      props.value.start &&
      props.value.end &&
      (startSelected.value
        ? moment(newDate).isSameOrAfter(props.value.end)
        : moment(newDate).isSameOrBefore(props.value.start))
    ) {
      emit("selectRange", {
        ...props.value,
        [startSelected.value ? "end" : "start"]: result,
      });
      return;
    }

    emit("selectRange", {
      ...props.value,
      [startSelected.value ? "start" : "end"]: result,
    });
    startSelected.value = !startSelected.value;
  }
);

const isOpen = ref(false);

const onKeydown = (event: KeyboardEvent) => {
  if (!isOpen.value) {
    return;
  }
  const { key } = event;

  props.onKeydown?.(event);

  switch (key) {
    case "Enter":
    case "ArrowUp":
    case "ArrowDown":
    case "ArrowLeft":
    case "ArrowRight":
    case "Shift":
    case "Meta":
    case "Control":
    case "Option":
    case "Alt": {
      break;
    }
    case "Tab": {
      event.preventDefault();
      break;
    }
    default: {
      dropdownRef.value?.hide();
      break;
    }
  }
};

const focusDiv = ref<HTMLDivElement | null>(null);

const onShow = async () => {
  isOpen.value = true;
  props.onAfterOpen?.();
  await nextTick();
  let target = selectedDate.value;
  if (!target && isDateRange(props.value)) {
    target = normalizeDate(props.value.end);
  }
  if (!target) {
    focusDiv.value?.focus();
  } else {
    datePicker.value?.moveToValue(target);
  }
};

const onHide = () => {
  isOpen.value = false;
  startSelected.value = true;
  props.onAfterClose?.();
};

const onMouseDown = (event: MouseEvent) => {
  if (!isContextMenuMode.value) {
    return;
  }
  event.preventDefault();
};

const valueButtonStyle =
  "flex h-8 flex-1 items-center justify-center text-left overflow-hidden gap-2 rounded border bg-lt hover:bg-md/80 pl-2 pr-1 py-1 text-sm text-md focus-ring-none";

const selectRangeInput = async (newStartSelected: boolean) => {
  startSelected.value = newStartSelected;
  await nextTick();
  if (!selectedDate.value) {
    return;
  }
  datePicker.value?.moveToValue(selectedDate.value);
};

const onClickRecommendationButton = () => {
  emit("recommend");
  dropdownRef.value?.hide();
};

const open = () => dropdownRef.value?.show();

const close = () => dropdownRef.value?.hide();

const closeAndPropagateMaybe = (event: MouseEvent) => {
  close();
  const { clientX, clientY } = event;
  props.propagateClickClasses
    ?.flatMap((e) => Array.from(document.getElementsByClassName(e)))
    .filter((e): e is HTMLElement => e instanceof HTMLElement)
    .forEach((elem) => {
      const { left, top, width, height } = elem.getBoundingClientRect();
      if (!(clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height)) {
        return;
      }
      elem.click();
    });
};

const currentMonthStart = ref(moment().startOf("month"));

const recurrenceDateAttributes = computed(() => {
  if (!props.recurrence) {
    return {};
  }

  const now = moment().startOf("day");
  const currStart = (currentMonthStart.value as Moment).subtract(1, "month").startOf("month");
  const startDate = moment(now.isAfter(currStart) ? now : currStart);
  const endDate = moment(startDate).add(3, "month").startOf("month");
  const dates = getRecurrenceDatesInRange(props.recurrence, moment(props.recursNextAt), startDate, endDate).map((e) =>
    e.toDate()
  );

  return { key: "recurrence", dot: "green", dates };
});

const onMove = () => {
  const focusedPage = datePicker.value.calendarRef.firstPage;
  currentMonthStart.value = moment()
    .year(focusedPage.year)
    .month(focusedPage.month - 1)
    .startOf("month");
};

const highlights = computed(() => {
  const today = { key: "today", highlight: { color: "indigo", fillMode: "outline" }, dates: new Date() };
  const rangeMaybe =
    isDateRange(props.value) && (props.value.start || props.value.end)
      ? [
          {
            key: "range",
            highlight: "indigo",
            dates: props.value,
          },
        ]
      : [];
  const reminderMaybe = props.remindAt
    ? [
        {
          key: "reminder",
          dot: "yellow",
          dates: moment(props.remindAt).toDate(),
        },
      ]
    : [];
  return [today, ...rangeMaybe, ...reminderMaybe, recurrenceDateAttributes.value];
});

const openReminderModal = () => {
  close();
  emit("changeRemindAt");
};

onMounted(() => {
  if (!currentInstance) {
    return;
  }
  const instanceElem = currentInstance.subTree.el;
  if (!instanceElem?.children) {
    return;
  }

  assignButtonRef(instanceElem.children[0]);
});

defineExpose({
  open,
  close,
});
</script>

<template>
  <Dropdown
    ref="dropdownRef"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :disabled="disabled"
    :triggers="isContextMenuMode ? ['hover', 'focus'] : undefined"
    :popper-triggers="isContextMenuMode ? ['hover', 'focus'] : undefined"
    :placement="placementDefinite"
    :distance="calcDistance"
    :skidding="skidding"
    no-auto-focus
    :theme="`dropdown-${pageStore.theme}`"
    class="flex cursor-pointer"
    :class="{
      'max-w-full grow': block,
      'h-full': heightBlock,
    }"
    @mouseover="emit('mouseover', $event)"
    @mouseleave="emit('mouseleave', $event)"
    @focus="emit('focus', $event)"
    @blur="emit('blur', $event)"
    @apply-show="onShow"
    @apply-hide="onHide">
    <template #default>
      <DropdownMenuItemContent
        v-if="isContextMenuMode"
        :icon="isStartAt ? StartDateFieldIcon : DueDateFieldIcon"
        :title="`Change ${propertyTitle?.toLowerCase() ?? 'date'}`"
        is-submenu />

      <slot v-else />
    </template>

    <template #popper>
      <Menu no-auto-focus @mousedown="onMouseDown">
        <div
          class="w-72 overflow-hidden rounded border app-drag-none bg-lt border-md focus-ring-none"
          @click.stop
          @keydown.stop="onKeydown">
          <div ref="focusDiv" tabindex="0" class="focus:outline-none" />
          <div class="flex select-none items-center gap-2 px-3 pt-3">
            <button v-if="!isDateRange(value)" type="button" class="border-primary-base" :class="valueButtonStyle">
              <span v-if="value && typeof value === 'string'" class="flex-1 truncate">
                {{ getRelativeTimeForDatesDate(value) }}
              </span>
              <span v-else class="flex-1 text-vlt">Select</span>
              <div
                v-if="!preventUnset && value"
                class="cursor-pointer rounded p-0.5 hover:bg-hvy/80"
                @click.stop="emit('select', null)"
                @keydown.enter="emit('select', null)">
                <XIcon class="text-lt icon-xs" />
              </div>
            </button>
            <template v-else>
              <button
                type="button"
                :class="[valueButtonStyle, startSelected ? 'border-primary-base' : 'border-md']"
                @click="selectRangeInput(true)">
                <span v-if="value.start" class="flex-1 truncate">
                  {{ getRelativeTimeForDatesDate(value.start) }}
                </span>
                <span v-else class="flex-1 text-vlt">Start</span>
                <div
                  v-if="!preventUnset && hasStart"
                  class="cursor-pointer rounded p-0.5 hover:bg-hvy/80"
                  @click="emit('selectRange', { start: null, end: value.end })"
                  @keydown.enter="emit('selectRange', { start: null, end: value.end })">
                  <XIcon class="text-lt icon-xs" />
                </div>
              </button>
              <ArrowRightIcon class="size-3 shrink-0 text-lt" />
              <button
                type="button"
                :class="[valueButtonStyle, startSelected ? 'border-md' : 'border-primary-base']"
                @click="selectRangeInput(false)">
                <span v-if="value.end" class="flex-1 truncate">
                  {{ getRelativeTimeForDatesDate(value.end) }}
                </span>
                <span v-else class="flex-1 text-vlt">End</span>
                <div
                  v-if="!preventUnset && hasEnd"
                  class="cursor-pointer rounded p-0.5 hover:bg-hvy/80"
                  @click="emit('selectRange', { start: value.start, end: null })"
                  @keydown.enter="emit('selectRange', { start: value.start, end: null })">
                  <XIcon class="text-lt icon-xs" />
                </div>
              </button>
            </template>
          </div>

          <DatePickerExternal
            ref="datePicker"
            v-model="selectedDate"
            mode="date"
            color="indigo"
            expanded
            :attributes="highlights"
            :is-dark="pageStore.theme === 'dark'"
            :is24hr="isBrowserLocale24h()"
            :model-config="{ timeAdjust: '09:00:00' }"
            :first-day-of-week="(userStore.firstDayOfWeek ?? 1) + 1"
            :max-date="startSelected ? maxStartDate : undefined"
            :style="{ fontFamily: 'Inter var' }"
            @transition-end="onMove" />

          <div v-if="showRecommendationButton && pageStore.isOnline" class="px-3 pb-2">
            <Button
              class="!justify-start py-1.5"
              :btn-style="ButtonStyle.SECONDARY"
              text="Fill out with AI"
              :icon="RecommendWithAiIcon"
              :icon-size="IconSize.S"
              block
              :icon-args="{ class: 'text-recommendation-base' }"
              is-contrast
              @click="onClickRecommendationButton" />
          </div>
          <div v-if="!hideActionsNorm" class="flex flex-col gap-2 px-3 pb-3">
            <Button
              class="!justify-start py-1.5"
              :btn-style="ButtonStyle.SECONDARY"
              :text="remindAt ? `Reminder ${getRelativeTimeForReminder(moment(remindAt)).value}` : 'Set reminder'"
              :icon="ReminderIcon"
              block
              :icon-size="IconSize.S"
              :icon-args="{ class: remindAt && 'text-reminder-base' }"
              is-contrast
              @click="openReminderModal" />
            <RecurrenceEditor
              v-if="recurrence !== undefined && recursNextAt !== undefined"
              :recurrence="recurrence"
              :recurs-next-at="recursNextAt"
              @change-recurrence="(x) => emit('changeRecurrence', x)" />
          </div>
        </div>
      </Menu>
      <Teleport v-if="isOpen" to="body">
        <div class="absolute inset-0" @click="closeAndPropagateMaybe" @keydown.escape="close" />
      </Teleport>
    </template>
  </Dropdown>
</template>

<style>
.v-popper--theme-dropdown .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-dropdown .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-dropdown .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
