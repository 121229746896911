import type { Moment } from "moment";
import moment from "moment";
import Sugar from "sugar";

type DateOption = { dateStr: string; searchTerms: string[] };

// pure
const pureRelations = ["last", "this", "next"];
const pureUnits = ["week", "month", "quarter", "year"] as const;
const pureNowishShortcuts = ["yd", "td", "tm"];
const pureNowish = ["yesterday", "today", "tomorrow"];
const pureWeekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const pureMonths = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

// primitives
const relation = new RegExp(`(${pureRelations.join("|")})`);
const unit = new RegExp(`(${pureUnits.join("|")})`);
const c = /(-|\/|\.)/;
const D = /(0?[1-9]|[1-2]\d|3[0-1])/;
const M = /(0?[1-9]|1[0-2])/;
const YYYY = /(2[0-1]\d{2})/;
const YY = /(\d{2})/;
const processedWeekdays = pureWeekdays.map((e) => {
  if (e.length === 6) {
    return `${e.slice(0, 3)}(\\.|${e.slice(3)})?`;
  }
  return `${e.slice(0, 3)}(\\.|${e.slice(3, e.length - 3)}(\\.|${e.slice(e.length - 3)})?)?`;
});
const weekday = new RegExp(`(${processedWeekdays.join("|")})`);
const month = new RegExp(`(${pureMonths.map((e) => `${e.slice(0, 3)}(${e.slice(3)}|\\.)?`).join("|")})`);
const dayOfMonth = new RegExp(`(${D.source}(st|nd|rd|th)?)`);

// matchers
const nowishShortcut = new RegExp(`(${pureNowishShortcuts.join("|")})`);
const nowish = new RegExp(`(${pureNowish.join("|")})`);
const nextWeekday = weekday;
const nextMonthYearMaybe = new RegExp(`(${month.source}( (of )?${YYYY.source})?)`);
const relative = new RegExp(`(${relation.source} (${unit.source}|${weekday.source}|${month.source}))`);
const americanNumbers = new RegExp(`(${M.source}${c.source}${D.source}(${c.source}(${YYYY.source}|${YY.source}))?)`);
const isoNumbers = new RegExp(`(${YYYY.source}${c.source}${M.source}${c.source}${D.source})`);
const standardLong = new RegExp(`((${weekday.source},? )?${month.source} ${dayOfMonth.source}(,? ${YYYY.source})?)`);
const alternateLong = new RegExp(`((the )?${dayOfMonth.source} (of )?${month.source}(,? ${YYYY.source})?)`);

// combined -- order matters a lot because the matcher returns after the first match it finds
const allDateMatchers = [
  standardLong,
  alternateLong,
  nextWeekday,
  isoNumbers,
  americanNumbers,
  nextMonthYearMaybe,
  relative,
  nowish,
  nowishShortcut,
];

export const dateMatcher = new RegExp(`(${allDateMatchers.map((e) => `(${e.source})`).join("|")})`, "i");

const exactDateMatcher = new RegExp(`^${dateMatcher.source}$`, "i");
const exactNowishShortcutMatcher = new RegExp(`^${nowishShortcut.source}$`, "i");
const exactWeekdayMatcher = new RegExp(`^${weekday.source}$`, "i");
const exactMonthMatcher = new RegExp(`^${month.source}$`, "i");
const exactRelative = new RegExp(`^${relative.source}$`, "i");

const getWeekdayMatching = (weekdayUnnorm: string | undefined, relationNorm = "next"): Moment => {
  if (!weekdayUnnorm) {
    return moment().add(1, "day").startOf("day");
  }

  const weekdayNorm = weekdayUnnorm.toLowerCase().slice(0, 3);
  const targetWeekdayIndex = pureWeekdays.findIndex((e) => e.startsWith(weekdayNorm));

  if (relationNorm === "next") {
    const dayDiff = ((targetWeekdayIndex - moment().day() + 6) % 7) + 1;
    return moment().add(dayDiff, "day").startOf("day");
  }

  return moment()
    .add(relationNorm === "last" ? -1 : 0, "week")
    .day(targetWeekdayIndex)
    .startOf("day");
};

const getMonthMatching = (monthUnnorm: string | undefined, relationNorm = "next"): Moment => {
  if (!monthUnnorm) {
    return moment().add(1, "month").startOf("month");
  }

  const monthNorm = monthUnnorm.toLowerCase().slice(0, 3);
  const targetMonthIndex = pureMonths.findIndex((e) => e.startsWith(monthNorm));

  if (relationNorm === "next") {
    const monthDiff = ((targetMonthIndex - moment().month() + 11) % 12) + 1;
    return moment().add(monthDiff, "month").startOf("month");
  }

  return moment()
    .add(relationNorm === "last" ? -1 : 0, "year")
    .month(targetMonthIndex)
    .startOf("month");
};

/* parseDate state of play
# Introduction
Sugar is very aggressive so we have these handwritten regexes to act as an initial filter and ensure we don't match things we shouldn't.
Once something matches, sugar works pretty well except in a few specific cases, which we handle directly before sending to sugar.

# WE HANDLE
- yd/td/tm (today etc.)
- Monday (sugar will match today or earlier this week, we want next)
- next/last Monday/April (similar)
- next quarter (totally unhandled)

# SUGAR HANDLES
- today
- the 4th of July
- Sunday, January 15th 2012
- June 3rd, 2005
- 1 Dec. 2016

# NOTHING HANDLES (YET)
- the 15th
- in half a year
- five years ago
- next week Thursday
- the end of February
- two weeks from today
- the end of next week
- the first day of 2013
- four days after Monday
- March 15th of last year
- two days after tomorrow
- the last day of February
- the beginning of this month
- the 2nd Tuesday of November
- 5-2002

# SOMETHING HANDLES AND SHOULDN'T
- 1/1-2000
- Jan 1nd
- Apr 30th
- Feb 29th, 2001 (not a leap year)
- Monday, January 1st, 2000 (is actually a Saturday)
*/
export const parseDate = (text: string): Moment | null => {
  if (!exactDateMatcher.test(text)) {
    return null;
  }

  const textNorm = text.toLowerCase().slice(0, 3);

  if (exactNowishShortcutMatcher.test(text)) {
    return moment()
      .add(pureNowishShortcuts.findIndex((e) => e === textNorm) - 1, "day")
      .startOf("day");
  }

  if (exactWeekdayMatcher.test(text)) {
    return getWeekdayMatching(pureWeekdays.find((e) => e.startsWith(textNorm)));
  }

  if (exactMonthMatcher.test(text)) {
    return getMonthMatching(pureMonths.find((e) => e.startsWith(textNorm)));
  }

  const exactRelativeMatch = exactRelative.exec(text);
  if (exactRelativeMatch) {
    const [, , relationUnnorm, unitWeekdayOrMonth] = exactRelativeMatch;
    const relationNorm = relationUnnorm.toLowerCase();
    const unitWeekdayOrMonthNorm = unitWeekdayOrMonth.toLowerCase().slice(0, 3);

    const targetUnit = pureUnits.find((e) => e.startsWith(unitWeekdayOrMonthNorm));
    if (targetUnit) {
      return moment()
        .add(relationNorm === "last" ? -1 : 1, targetUnit)
        .add(relationNorm === "this" ? -1 : 0, "day")
        .startOf(targetUnit);
    }

    if (pureWeekdays.some((e) => e.startsWith(unitWeekdayOrMonthNorm))) {
      return getWeekdayMatching(unitWeekdayOrMonth, relationNorm);
    }

    return getMonthMatching(unitWeekdayOrMonth, relationNorm);
  }

  const sugarDate = Sugar.Date.create(text);
  if (Number.isNaN(sugarDate.getTime())) {
    return null;
  }
  return moment(sugarDate).startOf("day");
};

export const normalizeDateToDate = (date: Moment): Moment => date.startOf("day").add(9, "hours");

export const normalizeDate = (date: Moment): string => normalizeDateToDate(date).toISOString();

export const parseDates = (text: string): string | null => {
  const date = parseDate(text);
  if (!date) {
    return null;
  }
  return normalizeDate(date);
};

const UNCHANGING_OPTION_CANDIDATES = [
  ...pureNowishShortcuts,
  ...pureNowish,
  ...pureWeekdays,
  ...pureMonths,
  ...pureRelations.map((e) => [...pureWeekdays.map((f) => `${e} ${f}`), ...pureMonths.map((f) => `${e} ${f}`)]).flat(),
];

const digitStartMatcher = /^([0-2])/i;
const digitsFormats = [
  "MM/DD/YYYY",
  "M/D/YYYY",
  "MM-DD-YYYY",
  "M-D-YYYY",
  "MM.DD.YYYY",
  "M.D.YYYY",
  "M/D/YY",
  "M-D-YY",
  "M.D.YY",
  "YYYY-MM-DD",
  "YYYY-M-D",
  "Do [of] MMMM",
  "D [of] MMMM",
  "Do MMMM",
  "D MMMM",
];
const remainingFormats = ["MMMM Do", "MMMM D", "dddd MMMM Do", "dddd MMMM D", "dddd, MMMM Do", "dddd, MMMM D"];

/* getBestDateMatches state of play
# Introduction
This function is meant to return a few of the best possible matches for a given query.

It doesn't handle years yet, so a year will only be suggested/accepted if it is an exact match.
*/
export const getBestDateOptions = (query: string): DateOption[] => {
  if (!query) {
    return [0, 1, 2, 4, 7].map((i) => ({
      dateStr: normalizeDate(moment().add(i, "day")),
      searchTerms: [],
    }));
  }

  if (moment(query, moment.ISO_8601, true).isValid()) {
    return [{ dateStr: normalizeDate(moment(query)), searchTerms: [] }];
  }

  const datesToSearchTerms = new Map<string, string[]>();

  const addSearchTerms = (terms: string[]) => {
    terms
      .map((e) => e.toLowerCase())
      .filter((e) => e.startsWith(query.toLowerCase()))
      .forEach((term) => {
        const date = parseDates(term);
        if (!date) {
          return;
        }
        datesToSearchTerms.set(date, [...(datesToSearchTerms.get(date) || []), term]);
      });
  };

  const done = () => datesToSearchTerms.size >= 5;

  const makeResult = () =>
    [...datesToSearchTerms.entries()].map(([dateStr, searchTerms]) => ({ dateStr, searchTerms })).slice(0, 10);

  addSearchTerms([query]);

  // most basic options
  addSearchTerms(UNCHANGING_OPTION_CANDIDATES);
  if (done()) {
    return makeResult();
  }

  // numbers
  if (digitStartMatcher.test(query)) {
    const digitCandidates = [...Array(366).keys()]
      .map((i) => moment().add(i, "day"))
      .map((e) => digitsFormats.map((f) => e.format(f)))
      .flat();
    addSearchTerms(digitCandidates);
    if (done()) {
      return makeResult();
    }
  }

  // remaining
  const remainingCandidates = [...Array(366).keys()]
    .map((i) => moment().add(i, "day"))
    .map((e) => remainingFormats.map((f) => e.format(f)))
    .flat();
  addSearchTerms(remainingCandidates);
  return makeResult();
};
