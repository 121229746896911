<script setup lang="ts">
import { ref } from "vue";

import { ChevronDownIcon, ChevronUpIcon } from "~/icons";
import { makeUuid } from "~/utils/common";

defineProps<{
  value: number;
  label: string;
}>();

const emit = defineEmits<{
  change: [newValue: number];
}>();

const change = (newNumber: number) => {
  emit("change", Math.max(Math.round(newNumber), 1));
};

const onInput = (event: Event) => {
  change(Number((event.target as HTMLInputElement)?.value));
};

const id = ref(`number-${makeUuid()}`);
</script>

<template>
  <div class="relative">
    <label :for="id" class="sr-only">{{ label }}</label>
    <input
      :id="id"
      :value="value"
      class="w-10 rounded border-0 py-1 pl-0 pr-5 text-right text-sm bg-lt focus-ring-none hover:bg-md focus:bg-md"
      type="number"
      @keydown.stop
      @input="onInput" />
    <div class="absolute inset-y-0 right-0 flex flex-col justify-center">
      <div class="cursor-pointer rounded hover:bg-md" @click="change(value + 1)" @keydown.enter="change(value + 1)">
        <ChevronUpIcon class="size-3" />
      </div>
      <div class="cursor-pointer rounded hover:bg-md" @click="change(value - 1)" @keydown.enter="change(value - 1)">
        <ChevronDownIcon class="size-3" />
      </div>
    </div>
  </div>
</template>
