import type { DartboardStats } from "./common";

// spell-checker: disable
const fakeData: DartboardStats = {
  scopeCount: {
    "36Yyx2D0Fpvp": [
      ["2024-05-13T08:08:00.389419+00:00", 0, 1],
      ["2024-05-19T20:20:38.182879+00:00", 0, 3],
    ],
    a1_nIVX9mPmd: [
      ["2024-05-13T08:08:00.414310+00:00", 3, 1],
      ["2024-05-19T20:20:38.218445+00:00", 0, 3],
    ],
    HgJ66tn9MOeY: [
      ["2024-05-13T08:08:00.408147+00:00", 0, 1],
      ["2024-05-19T20:20:38.211677+00:00", 0, 3],
    ],
    HJnMCIfzAPUo: [
      ["2024-05-13T08:08:00.396538+00:00", 11, 1],
      ["2024-05-13T16:30:10.896772+00:00", 13],
      ["2024-05-13T21:45:00.612550+00:00", 15],
      ["2024-05-17T11:15:00.619424+00:00", 16],
      ["2024-05-19T20:20:38.189944+00:00", 6, 3],
    ],
    lAV0wAYI562c: [
      ["2024-05-13T08:08:00.375650+00:00", 7, 1],
      ["2024-05-19T20:20:38.175097+00:00", 2, 3],
    ],
    oKhI9veX1CJ5: [
      ["2024-05-13T08:08:00.368393+00:00", 5, 1],
      ["2024-05-19T20:20:38.168096+00:00", 2, 3],
    ],
    SLotS4xMl2t5: [
      ["2024-05-13T08:08:00.402507+00:00", 19, 1],
      ["2024-05-13T08:30:00.702895+00:00", 10],
      ["2024-05-13T23:15:01.420548+00:00", 12],
      ["2024-05-14T04:15:01.636873+00:00", 13],
      ["2024-05-14T18:00:01.902837+00:00", 14],
      ["2024-05-14T21:30:01.134880+00:00", 15],
      ["2024-05-14T22:15:00.599152+00:00", 16],
      ["2024-05-15T19:30:00.641380+00:00", 17],
      ["2024-05-15T19:45:00.841491+00:00", 18],
      ["2024-05-16T00:00:01.169546+00:00", 19],
      ["2024-05-16T18:45:01.061970+00:00", 20],
      ["2024-05-16T19:15:01.712170+00:00", 21],
      ["2024-05-17T03:00:01.125559+00:00", 22],
      ["2024-05-17T03:15:01.518749+00:00", 23],
      ["2024-05-19T20:15:01.126371+00:00", 24],
      ["2024-05-19T20:20:38.196348+00:00", 12, 3],
    ],
    Y24wBcd0SZ6a: [
      ["2024-05-13T08:08:00.382262+00:00", 0, 1],
      ["2024-05-19T20:20:38.203829+00:00", 0, 3],
    ],
    "@unassigned": [
      ["2024-05-13T08:08:00.361684+00:00", 0, 1],
      ["2024-05-19T20:20:38.161508+00:00", 0, 3],
    ],
    "@total": [
      ["2024-05-13T08:08:00.352200+00:00", 45, 1],
      ["2024-05-13T08:30:00.609378+00:00", 36],
      ["2024-05-13T16:30:10.834585+00:00", 38],
      ["2024-05-13T21:45:00.559635+00:00", 40],
      ["2024-05-13T23:15:01.327784+00:00", 42],
      ["2024-05-14T04:15:01.383247+00:00", 43],
      ["2024-05-14T18:00:01.718845+00:00", 44],
      ["2024-05-14T21:30:00.981670+00:00", 45],
      ["2024-05-14T22:15:00.527273+00:00", 46],
      ["2024-05-15T19:30:00.567083+00:00", 47],
      ["2024-05-15T19:45:00.782464+00:00", 48],
      ["2024-05-16T00:00:01.079390+00:00", 49],
      ["2024-05-16T18:45:00.981603+00:00", 50],
      ["2024-05-16T19:15:01.557782+00:00", 51],
      ["2024-05-17T03:00:01.026068+00:00", 52],
      ["2024-05-17T03:15:01.445615+00:00", 53],
      ["2024-05-17T11:15:00.572547+00:00", 54],
      ["2024-05-19T20:15:01.055095+00:00", 55],
      ["2024-05-19T20:20:38.154222+00:00", 22, 3],
    ],
  },
  scopePoints: {
    "36Yyx2D0Fpvp": [
      ["2024-05-13T08:08:00.389419+00:00", 0, 1],
      ["2024-05-19T20:20:38.182879+00:00", 0, 3],
    ],
    a1_nIVX9mPmd: [
      ["2024-05-13T08:08:00.414310+00:00", 16, 1],
      ["2024-05-19T20:20:38.218445+00:00", 0, 3],
    ],
    HgJ66tn9MOeY: [
      ["2024-05-13T08:08:00.408147+00:00", 0, 1],
      ["2024-05-19T20:20:38.211677+00:00", 0, 3],
    ],
    HJnMCIfzAPUo: [
      ["2024-05-13T08:08:00.396538+00:00", 31, 1],
      ["2024-05-13T16:30:10.896772+00:00", 37],
      ["2024-05-13T20:45:01.815831+00:00", 34],
      ["2024-05-13T21:45:00.612550+00:00", 35],
      ["2024-05-14T21:30:01.116071+00:00", 36],
      ["2024-05-16T20:15:00.480256+00:00", 39],
      ["2024-05-17T11:15:00.619424+00:00", 42],
      ["2024-05-17T11:30:00.601955+00:00", 41],
      ["2024-05-19T20:20:38.189944+00:00", 17, 3],
    ],
    lAV0wAYI562c: [
      ["2024-05-13T08:08:00.375650+00:00", 21, 1],
      ["2024-05-13T16:15:02.844981+00:00", 22],
      ["2024-05-19T20:20:38.175097+00:00", 4, 3],
    ],
    oKhI9veX1CJ5: [
      ["2024-05-13T08:08:00.368393+00:00", 8, 1],
      ["2024-05-15T21:00:01.315692+00:00", 9],
      ["2024-05-19T20:20:38.168096+00:00", 1, 3],
    ],
    SLotS4xMl2t5: [
      ["2024-05-13T08:08:00.402507+00:00", 79, 1],
      ["2024-05-13T08:30:00.702895+00:00", 45],
      ["2024-05-14T04:15:01.636873+00:00", 50],
      ["2024-05-14T18:00:01.902837+00:00", 51],
      ["2024-05-14T21:30:01.134880+00:00", 59],
      ["2024-05-14T22:15:00.599152+00:00", 64],
      ["2024-05-15T19:30:00.641380+00:00", 72],
      ["2024-05-15T19:45:00.841491+00:00", 77],
      ["2024-05-16T00:00:01.169546+00:00", 79],
      ["2024-05-16T19:15:01.712170+00:00", 81],
      ["2024-05-17T03:00:01.125559+00:00", 82],
      ["2024-05-17T03:15:01.518749+00:00", 85],
      ["2024-05-19T20:15:01.126371+00:00", 90],
      ["2024-05-19T20:20:38.196348+00:00", 38, 3],
    ],
    Y24wBcd0SZ6a: [
      ["2024-05-13T08:08:00.382262+00:00", 0, 1],
      ["2024-05-19T20:20:38.203829+00:00", 0, 3],
    ],
    "@unassigned": [
      ["2024-05-13T08:08:00.361684+00:00", 0, 1],
      ["2024-05-19T20:20:38.161508+00:00", 0, 3],
    ],
    "@total": [
      ["2024-05-13T08:08:00.352200+00:00", 155, 1],
      ["2024-05-13T08:30:00.609378+00:00", 121],
      ["2024-05-13T16:15:02.801626+00:00", 122],
      ["2024-05-13T16:30:10.834585+00:00", 128],
      ["2024-05-13T20:45:01.769747+00:00", 125],
      ["2024-05-13T21:45:00.559635+00:00", 126],
      ["2024-05-14T04:15:01.383247+00:00", 131],
      ["2024-05-14T18:00:01.718845+00:00", 132],
      ["2024-05-14T21:30:00.981670+00:00", 141],
      ["2024-05-14T22:15:00.527273+00:00", 146],
      ["2024-05-15T19:30:00.567083+00:00", 154],
      ["2024-05-15T19:45:00.782464+00:00", 159],
      ["2024-05-15T21:00:01.280182+00:00", 160],
      ["2024-05-16T00:00:01.079390+00:00", 162],
      ["2024-05-16T19:15:01.557782+00:00", 164],
      ["2024-05-16T20:15:00.430669+00:00", 167],
      ["2024-05-17T03:00:01.026068+00:00", 168],
      ["2024-05-17T03:15:01.445615+00:00", 171],
      ["2024-05-17T11:15:00.572547+00:00", 174],
      ["2024-05-17T11:30:00.561392+00:00", 173],
      ["2024-05-19T20:15:01.055095+00:00", 178],
      ["2024-05-19T20:20:38.154222+00:00", 60, 3],
    ],
  },
  startedCount: {
    "36Yyx2D0Fpvp": [
      ["2024-05-13T08:08:00.389419+00:00", 0, 1],
      ["2024-05-19T20:20:38.182879+00:00", 0, 3],
    ],
    a1_nIVX9mPmd: [
      ["2024-05-13T08:08:00.414310+00:00", 1, 1],
      ["2024-05-19T20:20:38.218445+00:00", 0, 3],
    ],
    HgJ66tn9MOeY: [
      ["2024-05-13T08:08:00.408147+00:00", 0, 1],
      ["2024-05-19T20:20:38.211677+00:00", 0, 3],
    ],
    HJnMCIfzAPUo: [
      ["2024-05-13T08:08:00.396538+00:00", 4, 1],
      ["2024-05-13T13:45:01.628261+00:00", 5],
      ["2024-05-13T14:30:00.781482+00:00", 6],
      ["2024-05-13T16:30:10.896772+00:00", 7],
      ["2024-05-13T21:45:00.612550+00:00", 8],
      ["2024-05-15T13:30:00.713505+00:00", 9],
      ["2024-05-17T11:15:00.619424+00:00", 10],
      ["2024-05-19T20:20:38.189944+00:00", 6, 3],
    ],
    lAV0wAYI562c: [
      ["2024-05-13T08:08:00.375650+00:00", 2, 1],
      ["2024-05-13T16:15:02.844981+00:00", 3],
      ["2024-05-14T15:00:01.470434+00:00", 4],
      ["2024-05-16T16:15:00.573570+00:00", 5],
      ["2024-05-17T14:15:00.631444+00:00", 6],
      ["2024-05-19T20:20:38.175097+00:00", 2, 3],
    ],
    oKhI9veX1CJ5: [
      ["2024-05-13T08:08:00.368393+00:00", 2, 1],
      ["2024-05-13T14:15:00.780185+00:00", 3],
      ["2024-05-15T18:15:01.546299+00:00", 4],
      ["2024-05-19T20:20:38.168096+00:00", 2, 3],
    ],
    SLotS4xMl2t5: [
      ["2024-05-13T08:08:00.402507+00:00", 4, 1],
      ["2024-05-13T08:30:00.702895+00:00", 5],
      ["2024-05-13T23:15:01.420548+00:00", 7],
      ["2024-05-14T04:15:01.636873+00:00", 8],
      ["2024-05-14T18:00:01.902837+00:00", 9],
      ["2024-05-14T21:30:01.134880+00:00", 10],
      ["2024-05-14T22:15:00.599152+00:00", 11],
      ["2024-05-15T19:30:00.641380+00:00", 12],
      ["2024-05-15T19:45:00.841491+00:00", 13],
      ["2024-05-16T00:00:01.169546+00:00", 14],
      ["2024-05-16T18:45:01.061970+00:00", 15],
      ["2024-05-16T19:15:01.712170+00:00", 16],
      ["2024-05-17T03:15:01.518749+00:00", 18],
      ["2024-05-19T20:15:01.126371+00:00", 19],
      ["2024-05-19T20:20:38.196348+00:00", 12, 3],
    ],
    Y24wBcd0SZ6a: [
      ["2024-05-13T08:08:00.382262+00:00", 0, 1],
      ["2024-05-19T20:20:38.203829+00:00", 0, 3],
    ],
    "@unassigned": [
      ["2024-05-13T08:08:00.361684+00:00", 0, 1],
      ["2024-05-19T20:20:38.161508+00:00", 0, 3],
    ],
    "@total": [
      ["2024-05-13T08:08:00.352200+00:00", 13, 1],
      ["2024-05-13T08:30:00.609378+00:00", 14],
      ["2024-05-13T13:45:01.528247+00:00", 15],
      ["2024-05-13T14:15:00.762698+00:00", 16],
      ["2024-05-13T14:30:00.727507+00:00", 17],
      ["2024-05-13T16:15:02.801626+00:00", 18],
      ["2024-05-13T16:30:10.834585+00:00", 19],
      ["2024-05-13T21:45:00.559635+00:00", 20],
      ["2024-05-13T23:15:01.327784+00:00", 22],
      ["2024-05-14T04:15:01.383247+00:00", 23],
      ["2024-05-14T15:00:01.386642+00:00", 24],
      ["2024-05-14T18:00:01.718845+00:00", 25],
      ["2024-05-14T21:30:00.981670+00:00", 26],
      ["2024-05-14T22:15:00.527273+00:00", 27],
      ["2024-05-15T13:30:00.670856+00:00", 28],
      ["2024-05-15T18:15:01.510310+00:00", 29],
      ["2024-05-15T19:30:00.567083+00:00", 30],
      ["2024-05-15T19:45:00.782464+00:00", 31],
      ["2024-05-16T00:00:01.079390+00:00", 32],
      ["2024-05-16T16:15:00.544806+00:00", 33],
      ["2024-05-16T18:45:00.981603+00:00", 34],
      ["2024-05-16T19:15:01.557782+00:00", 35],
      ["2024-05-17T03:15:01.445615+00:00", 37],
      ["2024-05-17T11:15:00.572547+00:00", 38],
      ["2024-05-17T14:15:00.594826+00:00", 39],
      ["2024-05-19T20:15:01.055095+00:00", 40],
      ["2024-05-19T20:20:38.154222+00:00", 22, 3],
    ],
  },
  startedPoints: {
    "36Yyx2D0Fpvp": [
      ["2024-05-13T08:08:00.389419+00:00", 0, 1],
      ["2024-05-19T20:20:38.182879+00:00", 0, 3],
    ],
    a1_nIVX9mPmd: [
      ["2024-05-13T08:08:00.414310+00:00", 5, 1],
      ["2024-05-19T20:20:38.218445+00:00", 0, 3],
    ],
    HgJ66tn9MOeY: [
      ["2024-05-13T08:08:00.408147+00:00", 0, 1],
      ["2024-05-19T20:20:38.211677+00:00", 0, 3],
    ],
    HJnMCIfzAPUo: [
      ["2024-05-13T08:08:00.396538+00:00", 11, 1],
      ["2024-05-13T13:45:01.628261+00:00", 13],
      ["2024-05-13T14:30:00.781482+00:00", 16],
      ["2024-05-13T16:30:10.896772+00:00", 19],
      ["2024-05-13T20:45:01.815831+00:00", 16],
      ["2024-05-14T21:30:01.116071+00:00", 17],
      ["2024-05-15T13:30:00.713505+00:00", 19],
      ["2024-05-16T20:15:00.480256+00:00", 22],
      ["2024-05-17T11:15:00.619424+00:00", 25],
      ["2024-05-17T11:30:00.601955+00:00", 24],
      ["2024-05-19T20:20:38.189944+00:00", 17, 3],
    ],
    lAV0wAYI562c: [
      ["2024-05-13T08:08:00.375650+00:00", 4, 1],
      ["2024-05-13T16:15:02.844981+00:00", 8],
      ["2024-05-14T15:00:01.470434+00:00", 10],
      ["2024-05-16T16:15:00.573570+00:00", 15],
      ["2024-05-17T14:15:00.631444+00:00", 20],
      ["2024-05-19T20:20:38.175097+00:00", 4, 3],
    ],
    oKhI9veX1CJ5: [
      ["2024-05-13T08:08:00.368393+00:00", 1, 1],
      ["2024-05-13T14:15:00.780185+00:00", 3],
      ["2024-05-15T18:15:01.546299+00:00", 5],
      ["2024-05-15T21:00:01.315692+00:00", 6],
      ["2024-05-19T20:20:38.168096+00:00", 1, 3],
    ],
    SLotS4xMl2t5: [
      ["2024-05-13T08:08:00.402507+00:00", 21, 1],
      ["2024-05-13T08:30:00.702895+00:00", 26],
      ["2024-05-14T04:15:01.636873+00:00", 31],
      ["2024-05-14T18:00:01.902837+00:00", 32],
      ["2024-05-14T21:30:01.134880+00:00", 40],
      ["2024-05-14T22:15:00.599152+00:00", 45],
      ["2024-05-15T19:30:00.641380+00:00", 53],
      ["2024-05-15T19:45:00.841491+00:00", 58],
      ["2024-05-16T00:00:01.169546+00:00", 60],
      ["2024-05-16T19:15:01.712170+00:00", 62],
      ["2024-05-17T03:15:01.518749+00:00", 66],
      ["2024-05-19T20:15:01.126371+00:00", 71],
      ["2024-05-19T20:20:38.196348+00:00", 38, 3],
    ],
    Y24wBcd0SZ6a: [
      ["2024-05-13T08:08:00.382262+00:00", 0, 1],
      ["2024-05-19T20:20:38.203829+00:00", 0, 3],
    ],
    "@unassigned": [
      ["2024-05-13T08:08:00.361684+00:00", 0, 1],
      ["2024-05-19T20:20:38.161508+00:00", 0, 3],
    ],
    "@total": [
      ["2024-05-13T08:08:00.352200+00:00", 42, 1],
      ["2024-05-13T08:30:00.609378+00:00", 47],
      ["2024-05-13T13:45:01.528247+00:00", 49],
      ["2024-05-13T14:15:00.762698+00:00", 51],
      ["2024-05-13T14:30:00.727507+00:00", 54],
      ["2024-05-13T16:15:02.801626+00:00", 58],
      ["2024-05-13T16:30:10.834585+00:00", 61],
      ["2024-05-13T20:45:01.769747+00:00", 58],
      ["2024-05-14T04:15:01.383247+00:00", 63],
      ["2024-05-14T15:00:01.386642+00:00", 65],
      ["2024-05-14T18:00:01.718845+00:00", 66],
      ["2024-05-14T21:30:00.981670+00:00", 75],
      ["2024-05-14T22:15:00.527273+00:00", 80],
      ["2024-05-15T13:30:00.670856+00:00", 82],
      ["2024-05-15T18:15:01.510310+00:00", 84],
      ["2024-05-15T19:30:00.567083+00:00", 92],
      ["2024-05-15T19:45:00.782464+00:00", 97],
      ["2024-05-15T21:00:01.280182+00:00", 98],
      ["2024-05-16T00:00:01.079390+00:00", 100],
      ["2024-05-16T16:15:00.544806+00:00", 105],
      ["2024-05-16T19:15:01.557782+00:00", 107],
      ["2024-05-16T20:15:00.430669+00:00", 110],
      ["2024-05-17T03:15:01.445615+00:00", 114],
      ["2024-05-17T11:15:00.572547+00:00", 117],
      ["2024-05-17T11:30:00.561392+00:00", 116],
      ["2024-05-17T14:15:00.594826+00:00", 121],
      ["2024-05-19T20:15:01.055095+00:00", 126],
      ["2024-05-19T20:20:38.154222+00:00", 60, 3],
    ],
  },
  completedCount: {
    "36Yyx2D0Fpvp": [
      ["2024-05-13T08:08:00.389419+00:00", 0, 1],
      ["2024-05-19T20:20:38.182879+00:00", 0, 3],
    ],
    a1_nIVX9mPmd: [
      ["2024-05-13T08:08:00.414310+00:00", 0, 1],
      ["2024-05-19T20:20:38.218445+00:00", 0, 3],
    ],
    HgJ66tn9MOeY: [
      ["2024-05-13T08:08:00.408147+00:00", 0, 1],
      ["2024-05-19T20:20:38.211677+00:00", 0, 3],
    ],
    HJnMCIfzAPUo: [
      ["2024-05-13T08:08:00.396538+00:00", 0, 1],
      ["2024-05-13T23:00:01.781275+00:00", 1],
      ["2024-05-14T20:15:00.562419+00:00", 2],
      ["2024-05-14T21:30:01.116071+00:00", 3],
      ["2024-05-16T00:30:01.867090+00:00", 4],
      ["2024-05-17T22:15:02.517011+00:00", 6],
      ["2024-05-19T20:20:38.189944+00:00", 6, 3],
    ],
    lAV0wAYI562c: [
      ["2024-05-13T08:08:00.375650+00:00", 0, 1],
      ["2024-05-14T23:00:02.046192+00:00", 1],
      ["2024-05-15T23:30:00.602229+00:00", 2],
      ["2024-05-19T20:20:38.175097+00:00", 2, 3],
    ],
    oKhI9veX1CJ5: [
      ["2024-05-13T08:08:00.368393+00:00", 0, 1],
      ["2024-05-13T19:15:01.051805+00:00", 1],
      ["2024-05-17T02:15:02.065315+00:00", 2],
      ["2024-05-19T20:20:38.168096+00:00", 2, 3],
    ],
    SLotS4xMl2t5: [
      ["2024-05-13T08:08:00.402507+00:00", 0, 1],
      ["2024-05-13T23:15:01.420548+00:00", 2],
      ["2024-05-14T18:00:01.902837+00:00", 3],
      ["2024-05-14T21:30:01.134880+00:00", 4],
      ["2024-05-15T04:00:02.741620+00:00", 5],
      ["2024-05-15T07:45:00.942758+00:00", 6],
      ["2024-05-16T00:15:00.532382+00:00", 7],
      ["2024-05-16T17:45:00.767354+00:00", 8],
      ["2024-05-16T19:15:01.712170+00:00", 9],
      ["2024-05-17T03:15:01.518749+00:00", 11],
      ["2024-05-19T20:20:38.196348+00:00", 12, 3],
    ],
    Y24wBcd0SZ6a: [
      ["2024-05-13T08:08:00.382262+00:00", 0, 1],
      ["2024-05-19T20:20:38.203829+00:00", 0, 3],
    ],
    "@unassigned": [
      ["2024-05-13T08:08:00.361684+00:00", 0, 1],
      ["2024-05-19T20:20:38.161508+00:00", 0, 3],
    ],
    "@total": [
      ["2024-05-13T08:08:00.352200+00:00", 0, 1],
      ["2024-05-13T19:15:01.033167+00:00", 1],
      ["2024-05-13T23:00:01.698419+00:00", 2],
      ["2024-05-13T23:15:01.327784+00:00", 4],
      ["2024-05-14T18:00:01.718845+00:00", 5],
      ["2024-05-14T20:15:00.507134+00:00", 6],
      ["2024-05-14T21:30:00.981670+00:00", 8],
      ["2024-05-14T23:00:01.964848+00:00", 9],
      ["2024-05-15T04:00:02.648277+00:00", 10],
      ["2024-05-15T07:45:00.871810+00:00", 11],
      ["2024-05-15T23:30:00.577084+00:00", 12],
      ["2024-05-16T00:15:00.475624+00:00", 13],
      ["2024-05-16T00:30:01.795334+00:00", 14],
      ["2024-05-16T17:45:00.712917+00:00", 15],
      ["2024-05-16T19:15:01.557782+00:00", 16],
      ["2024-05-17T02:15:02.041556+00:00", 17],
      ["2024-05-17T03:15:01.445615+00:00", 19],
      ["2024-05-17T22:15:02.477566+00:00", 21],
      ["2024-05-19T20:20:38.154222+00:00", 22, 3],
    ],
  },
  completedPoints: {
    "36Yyx2D0Fpvp": [
      ["2024-05-13T08:08:00.389419+00:00", 0, 1],
      ["2024-05-19T20:20:38.182879+00:00", 0, 3],
    ],
    a1_nIVX9mPmd: [
      ["2024-05-13T08:08:00.414310+00:00", 0, 1],
      ["2024-05-19T20:20:38.218445+00:00", 0, 3],
    ],
    HgJ66tn9MOeY: [
      ["2024-05-13T08:08:00.408147+00:00", 0, 1],
      ["2024-05-19T20:20:38.211677+00:00", 0, 3],
    ],
    HJnMCIfzAPUo: [
      ["2024-05-13T08:08:00.396538+00:00", 0, 1],
      ["2024-05-14T21:30:01.116071+00:00", 4],
      ["2024-05-16T00:30:01.867090+00:00", 12],
      ["2024-05-17T22:15:02.517011+00:00", 17],
      ["2024-05-19T20:20:38.189944+00:00", 17, 3],
    ],
    lAV0wAYI562c: [
      ["2024-05-13T08:08:00.375650+00:00", 0, 1],
      ["2024-05-14T23:00:02.046192+00:00", 2],
      ["2024-05-15T23:30:00.602229+00:00", 4],
      ["2024-05-19T20:20:38.175097+00:00", 4, 3],
    ],
    oKhI9veX1CJ5: [
      ["2024-05-13T08:08:00.368393+00:00", 0, 1],
      ["2024-05-13T19:15:01.051805+00:00", 1],
      ["2024-05-19T20:20:38.168096+00:00", 1, 3],
    ],
    SLotS4xMl2t5: [
      ["2024-05-13T08:08:00.402507+00:00", 0, 1],
      ["2024-05-14T18:00:01.902837+00:00", 1],
      ["2024-05-14T21:30:01.134880+00:00", 9],
      ["2024-05-15T04:00:02.741620+00:00", 14],
      ["2024-05-15T07:45:00.942758+00:00", 17],
      ["2024-05-16T00:15:00.532382+00:00", 22],
      ["2024-05-16T17:45:00.767354+00:00", 27],
      ["2024-05-16T19:15:01.712170+00:00", 29],
      ["2024-05-17T03:15:01.518749+00:00", 33],
      ["2024-05-19T20:20:38.196348+00:00", 38, 3],
    ],
    Y24wBcd0SZ6a: [
      ["2024-05-13T08:08:00.382262+00:00", 0, 1],
      ["2024-05-19T20:20:38.203829+00:00", 0, 3],
    ],
    "@unassigned": [
      ["2024-05-13T08:08:00.361684+00:00", 0, 1],
      ["2024-05-19T20:20:38.161508+00:00", 0, 3],
    ],
    "@total": [
      ["2024-05-13T08:08:00.352200+00:00", 0, 1],
      ["2024-05-13T19:15:01.033167+00:00", 1],
      ["2024-05-14T18:00:01.718845+00:00", 2],
      ["2024-05-14T21:30:00.981670+00:00", 14],
      ["2024-05-14T23:00:01.964848+00:00", 16],
      ["2024-05-15T04:00:02.648277+00:00", 21],
      ["2024-05-15T07:45:00.871810+00:00", 24],
      ["2024-05-15T23:30:00.577084+00:00", 26],
      ["2024-05-16T00:15:00.475624+00:00", 31],
      ["2024-05-16T00:30:01.795334+00:00", 39],
      ["2024-05-16T17:45:00.712917+00:00", 44],
      ["2024-05-16T19:15:01.557782+00:00", 46],
      ["2024-05-17T03:15:01.445615+00:00", 50],
      ["2024-05-17T22:15:02.477566+00:00", 55],
      ["2024-05-19T20:20:38.154222+00:00", 60, 3],
    ],
  },
};

export default fakeData;
