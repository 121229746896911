import { CommandId, Priority } from "~/shared/enums";

export const UNPRIORITIZED_PRIORITY_LABEL = "(Unprioritized)";

export const NONNULL_PRIORITY_OPTIONS = Object.values(Priority);
export const PRIORITY_OPTIONS = [null, ...NONNULL_PRIORITY_OPTIONS];

export const PRIORITY_COLOR_MAP = new Map([
  [Priority.CRITICAL, "#e8593d"],
  [Priority.HIGH, "#ee7d48"],
  [Priority.MEDIUM, "#f3b05a"],
  [Priority.LOW, "#f8e990"],
]);

export const PRIORITY_SHORTCUT_MAP = new Map([
  [null, CommandId.CHANGE_PRIORITY_TO_UNPRIORITIZED],
  [Priority.CRITICAL, CommandId.CHANGE_PRIORITY_TO_CRITICAL],
  [Priority.HIGH, CommandId.CHANGE_PRIORITY_TO_HIGH],
  [Priority.MEDIUM, CommandId.CHANGE_PRIORITY_TO_MEDIUM],
  [Priority.LOW, CommandId.CHANGE_PRIORITY_TO_LOW],
]);
