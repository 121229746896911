<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-hidden="true">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.675 12.268C12.675 12.0344 12.675 11.9176 12.6283 11.8618C12.5877 11.8134 12.5266 11.787 12.4636 11.7907C12.391 11.7948 12.3059 11.8748 12.1358 12.0349L11.1269 12.984C11.034 13.0714 10.9876 13.115 10.9544 13.1668C10.9249 13.2126 10.9031 13.263 10.8899 13.3159C10.875 13.3755 10.875 13.4393 10.875 13.5667L10.875 17.5805L9.26427 16.0013C8.90934 15.6533 8.33952 15.6589 7.99154 16.0138C7.64356 16.3688 7.64919 16.9386 8.00412 17.2866L11.1449 20.3659C11.4974 20.7114 12.0624 20.7086 12.4114 20.3596L18.4114 14.3596C18.7629 14.0082 18.7629 13.4383 18.4114 13.0868C18.0599 12.7354 17.4901 12.7354 17.1386 13.0868L12.675 17.5504L12.675 12.268Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5529 2.4768C11.0559 2.4768 10.6529 2.87974 10.6529 3.3768L10.6529 9.46497C10.6529 9.96202 11.0559 10.365 11.5529 10.365C12.05 10.365 12.4529 9.96202 12.4529 9.46497L12.4529 3.3768C12.4529 2.87974 12.05 2.4768 11.5529 2.4768Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4062 2.9485C18.0556 2.59618 17.4858 2.59479 17.1334 2.94541L4.69014 15.3284C4.33782 15.679 4.33643 16.2488 4.68705 16.6012C5.03767 16.9535 5.60751 16.9549 5.95984 16.6043L18.4031 4.22129C18.7554 3.87067 18.7568 3.30083 18.4062 2.9485Z"
      fill="currentColor" />
  </svg>
</template>
