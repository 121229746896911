<script setup lang="ts">
import { ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import Input from "~/components/dumb/Input.vue";
import Modal from "~/components/dumb/Modal.vue";
import { NotionIcon, OpenExternalLinkIcon } from "~/icons";
import { ButtonSize, ButtonStyle, ModalWidth } from "~/shared/enums";
import type { ValidationFunctionResult } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getNotionPageId, getNotionPageUrl, isValidUrl } from "~/utils/common";

const appStore = useAppStore();
const dataStore = useDataStore();

const urlInput = ref<InstanceType<typeof Input> | null>(null);

const initUrl = ref("");
const isEditing = ref(false);

const validateUrl = (value: string): ValidationFunctionResult => {
  if (!isValidUrl(value)) {
    return { isValid: false, error: "Enter a valid URL" };
  }
  if (!getNotionPageId(value)) {
    return { isValid: false, error: "Enter a valid Notion page URL" };
  }
  return { isValid: true };
};

const onClose = () => {
  appStore.setTaskInNotionLinkModalDuid(null);
};

const addLink = () => {
  if (!urlInput.value) {
    return;
  }
  const pageId = getNotionPageId(urlInput.value.value);
  if (!appStore.taskInNotionLinkModal || !pageId) {
    return;
  }
  dataStore.addNotionDocument(appStore.taskInNotionLinkModal.duid, pageId);
};

const updateLink = () => {
  if (!appStore.taskInNotionLinkModal) {
    return;
  }
  const { notionDocument } = appStore.taskInNotionLinkModal;
  if (!notionDocument) {
    return;
  }
  addLink();
};

const addOrUpdateLink = () => {
  if (!urlInput.value?.isValid) {
    return;
  }
  if (isEditing.value) {
    updateLink();
  }
  if (!isEditing.value) {
    addLink();
  }
  onClose();
};

const focusUrlInput = () => {
  setTimeout(() => {
    urlInput.value?.focus();
  });
};

watch(
  () => appStore.taskInNotionLinkModal,
  (linkInModal) => {
    if (!linkInModal) {
      return;
    }
    isEditing.value = !!linkInModal.notionDocument;
    initUrl.value = linkInModal.notionDocument?.pageId ? getNotionPageUrl(linkInModal.notionDocument.pageId) : "";
    focusUrlInput();
  }
);
</script>

<template>
  <Modal
    :entity="appStore.taskInNotionLinkModal"
    :title="`${isEditing ? 'Change' : 'Link'} Notion description`"
    :width="ModalWidth.M"
    :title-icon="NotionIcon"
    @close="onClose">
    <template #default>
      <form class="mb-1 mt-4" @submit.prevent>
        <Input
          ref="urlInput"
          :init-value="initUrl"
          input-type="url"
          required
          placeholder="Paste in Notion page URL"
          label="Notion URL"
          :validate="validateUrl"
          @enter="addOrUpdateLink" />
      </form>
    </template>

    <template #footerLeft>
      <a href="https://www.notion.so" target="_blank" rel="noopener noreferrer" class="flex items-center gap-1">
        <span class="text-sm underline text-vlt">Browse Notion</span>
        <OpenExternalLinkIcon class="text-vlt icon-sm" />
      </a>
    </template>

    <template #actions>
      <div class="flex items-center gap-2">
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          :text="isEditing ? 'Update' : 'Add'"
          :size="ButtonSize.LARGE"
          :disabled="!urlInput?.isValid"
          @click.stop="addOrUpdateLink" />
      </div>
    </template>
  </Modal>
</template>
