<script setup lang="ts">
/* https://raw.githubusercontent.com/facebook/lexical/main/packages/lexical-react/src/LexicalNodeEventPlugin.ts */
import { $findMatchingParent } from "@lexical/utils";
import type { Klass, LexicalEditor, LexicalNode } from "lexical";
import { $getNearestNodeFromDOMNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const props = defineProps<{
  nodeType: Klass<LexicalNode>;
  eventType: string;
  eventListener: (event: Event, editor: LexicalEditor, node: LexicalNode) => void;
}>();

const editor = useLexicalComposer();

let unregisterListeners: () => void;

const capturedEvents = new Set<string>(["mouseenter", "mouseleave"]);

onMounted(() => {
  const isCaptured = capturedEvents.has(props.eventType);

  const onEvent = (event: Event) => {
    editor.update(() => {
      const nearestNode = $getNearestNodeFromDOMNode(event.target as Element);
      if (nearestNode !== null) {
        const targetNode = isCaptured
          ? nearestNode instanceof props.nodeType
            ? nearestNode
            : null
          : $findMatchingParent(nearestNode, (node) => node instanceof props.nodeType);
        if (targetNode !== null) {
          props.eventListener(event, editor, targetNode);
        }
      }
    });
  };

  unregisterListeners = editor.registerRootListener((rootElement, prevRootElement) => {
    if (rootElement) {
      rootElement.addEventListener(props.eventType, onEvent, isCaptured);
    }

    if (prevRootElement) {
      prevRootElement.removeEventListener(props.eventType, onEvent, isCaptured);
    }
  });
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
