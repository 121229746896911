<script setup lang="ts">
import type { ApexOptions } from "apexcharts";
import moment from "moment";
import { computed, ref, watch } from "vue";
import ApexCharts from "vue3-apexcharts";

import { backendOld } from "~/api";
import { getFilterDefinitionList } from "~/common/filter";
import { getPropertyWithConfigList } from "~/common/properties";
import DatePicker from "~/components/dumb/DatePicker.vue";
import Filter from "~/components/filters/Filter.vue";
import { filterHasApplicability } from "~/shared/common";
import { EditorMode, FilterApplicability, FilterConnector } from "~/shared/enums";
import type { DateRange, Filter as IFilter, StatisticConfigBurnUp, Task } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore } from "~/stores";

import StatisticNoData from "../../StatisticNoData.vue";
import getBurnUpChart, { type DartboardStats, type Stat, TOTAL_DUID } from "./common";
import fakeData from "./fakeData";

const props = defineProps<{
  tasks: Task[];
  width: number;
  height: number;
  config: StatisticConfigBurnUp;
}>();

const emit = defineEmits<{
  update: [config: StatisticConfigBurnUp];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const chartRef = ref<ApexCharts | null>(null);
const dartboardStats = ref<DartboardStats | null>(tenantStore.isPremium ? null : fakeData);
const dartboardDuid = computed(
  () => appStore.filters.find((e) => e.propertyDuid === dataStore.defaultDartboardProperty.duid)?.values[0] as string
);
const dartboard = computed(() => dataStore.getDartboardByDuid(dartboardDuid.value));
const filtersAreValid = computed(() => {
  if (appStore.filters.length === 0 || appStore.filters.length > 2) {
    return false;
  }

  return appStore.filters.every((filter) => {
    if (!filterHasApplicability(filter)) {
      return false;
    }

    if (filter.propertyDuid === dataStore.defaultDartboardProperty.duid) {
      return filter.applicability === FilterApplicability.IS && filter.values.length === 1;
    }

    if (filter.propertyDuid === dataStore.defaultAssigneesProperty.duid) {
      return filter.values.length > 0;
    }

    return false;
  });
});

const hasData = computed(
  () => !!dartboard.value && dataStore.getTasksByDartboardDuidOrdered(dartboard.value.duid).length !== 0
);
const isLoading = computed(() => !!dartboard.value && !dartboardStats.value && hasData.value);
const assigneeDuids = computed<string[]>(() => {
  const assigneeFilter =
    appStore.filters.find((e) => e.propertyDuid === dataStore.defaultAssigneesProperty.duid) ?? null;
  if (!assigneeFilter || !assigneeFilter.values) {
    return [TOTAL_DUID];
  }
  if ("connector" in assigneeFilter && assigneeFilter.connector === FilterConnector.AND) {
    return [];
  }
  return assigneeFilter.values as string[];
});
const dates = computed<DateRange>(() => [props.config.adtl.startDate, props.config.adtl.endDate]);
const chart = computed<ApexOptions>(() =>
  getBurnUpChart(dartboardStats.value, assigneeDuids.value, dates.value, isLoading.value)
);
const chartWithoutSeries = computed<ApexOptions>(() => ({ ...chart.value, series: [] }));

const defaultDates = computed<DateRange>(() => {
  const totals = ((dartboardStats.value ?? {}).scopeCount ?? {})[TOTAL_DUID] ?? {};
  let minDate: Date | undefined;
  let maxDate: Date | undefined;
  Object.values(totals).forEach((e) => {
    minDate = minDate ? (minDate < new Date(e[0]) ? minDate : new Date(e[0])) : new Date(e[0]);
    maxDate = maxDate ? (maxDate > new Date(e[0]) ? maxDate : new Date(e[0])) : new Date(e[0]);
  });
  return [
    moment(minDate ?? new Date())
      .startOf("day")
      .set("hour", 9)
      .toISOString(),
    moment(maxDate ?? new Date())
      .startOf("day")
      .set("hour", 9)
      .toISOString(),
  ];
});

/* Date filter */
const filterDefinitions = computed(() => getFilterDefinitionList(getPropertyWithConfigList()));
const datesFilter = computed<IFilter>(() => ({
  id: dataStore.defaultDatesProperty.duid,
  propertyDuid: `${dataStore.defaultDatesProperty.duid}/@end`,
  locked: false,
  applicability: FilterApplicability.IS_BETWEEN,
  connector: FilterConnector.AND,
  values: [[dates.value[0] ?? defaultDates.value[0], dates.value[1] ?? defaultDates.value[1]]],
}));
const onSelectRange = (range: { start?: string | null; end?: string | null }) => {
  if (!range.start && !range.end) {
    return;
  }
  emit("update", {
    ...props.config,
    adtl: { ...props.config.adtl, startDate: range.start ?? null, endDate: range.end ?? null },
  });
};

/* Stat cards */
const stats = computed(() => {
  const fromDate = dates.value[0] ? moment(dates.value[0]).startOf("day") : null;
  const toDate = dates.value[1] ? moment(dates.value[1]).endOf("day") : null;
  const filterPointsByDates = (points: Stat[string]) =>
    points.filter((e) => {
      const currentDate = moment(e[0]);
      return (!fromDate || currentDate.isSameOrAfter(fromDate)) && (!toDate || currentDate.isSameOrBefore(toDate));
    });

  const scopePoints = assigneeDuids.value
    .map((assigneeDuid) => filterPointsByDates((dartboardStats.value?.scopePoints ?? {})[assigneeDuid] ?? []))
    .flat();
  const startedPoints = assigneeDuids.value
    .map((assigneeDuid) => filterPointsByDates((dartboardStats.value?.startedPoints ?? {})[assigneeDuid] ?? []))
    .flat();
  const completedPoints = assigneeDuids.value
    .map((assigneeDuid) => filterPointsByDates((dartboardStats.value?.completedPoints ?? {})[assigneeDuid] ?? []))
    .flat();

  const scope = (scopePoints.length ? scopePoints[scopePoints.length - 1][1] : 0) ?? 0;
  const started = (startedPoints.length ? startedPoints[startedPoints.length - 1][1] : 0) ?? 0;
  const completed = (completedPoints.length ? completedPoints[completedPoints.length - 1][1] : 0) ?? 0;

  const today = new Date().valueOf();
  const rangeStart = new Date(dates.value[0] ?? today).valueOf();
  const rangeEnd = new Date(dates.value[1] ?? today).valueOf();
  const range = rangeEnd - rangeStart;
  const progress = range === 0 ? 0 : Math.round(Math.min(((today - rangeStart) / range) * 100, 100));

  return [
    {
      title: "Scope",
      value: scope,
      unit: "points",
    },
    {
      title: "Started",
      value: started,
      unit: "points",
      difference: `${scope === 0 ? 0 : Math.round((started / scope) * 100)}%`,
      differenceText: "of scope",
    },
    {
      title: "Completed",
      value: completed,
      unit: "points",
      difference: `${scope === 0 ? 0 : Math.round((completed / scope) * 100)}%`,
      differenceText: "of scope",
    },
    {
      title: "Progress",
      value: progress,
      unit: "%",
    },
  ];
});

/* Fetch stats */
watch(
  dartboard,
  async (newDartboard) => {
    if (!tenantStore.isPremium) {
      return;
    }

    dartboardStats.value = null;
    if (!newDartboard || !filtersAreValid.value) {
      return;
    }

    const res = await backendOld.dartboards.getStats(newDartboard.duid);
    dartboardStats.value = res.data;
    setTimeout(() => {
      if (dates.value[0] && dates.value[1]) {
        chartRef.value?.zoomX(
          moment(dates.value[0]).add(6, "hour").valueOf(),
          moment(dates.value[1]).add(6, "hour").valueOf()
        );
      }
    });
  },
  { immediate: true }
);
</script>

<template>
  <StatisticNoData
    :is-loading="isLoading"
    :has-data="hasData"
    :invalid-burn-up="!filtersAreValid"
    class="flex flex-col gap-6 px-3">
    <!-- Stat cards-->
    <div class="flex w-full select-none items-center gap-6">
      <div
        v-for="stat in stats"
        :key="stat.title"
        class="flex h-full flex-1 flex-col gap-3 overflow-hidden rounded-lg border p-4 border-md">
        <span class="text-sm font-medium text-lt">{{ stat.title }}</span>
        <div class="flex items-end gap-2">
          <span class="text-4xl font-semibold leading-6 text-md">{{ stat.value || "–" }}</span>
          <span v-if="stat.value" class="text-sm leading-none text-lt">{{ stat.unit }}</span>
        </div>
        <div v-if="stat.difference" class="flex items-center gap-1">
          <span class="text-sm text-primary-base">{{ stat.difference }}</span>
          <span class="truncate text-sm text-lt" :title="stat.differenceText">
            {{ stat.differenceText }}
          </span>
        </div>
      </div>
    </div>

    <div class="relative size-full rounded-lg border px-4 pb-2 pt-4 border-md">
      <ApexCharts
        ref="chartRef"
        class="dart-burn-up-chart"
        :options="chartWithoutSeries"
        :series="chart.series"
        width="100%"
        height="100%" />
      <!-- Selector -->
      <DatePicker
        :value="{ start: dates[0] ?? defaultDates[0], end: dates[1] ?? defaultDates[1] }"
        :editor-mode="EditorMode.FILTER"
        :distance="-1"
        :skidding="-1"
        :max-start-date="new Date()"
        cover
        prevent-unset
        class="absolute right-2 top-2"
        @select-range="onSelectRange">
        <div>
          <Filter
            :filter="datesFilter"
            :definition="filterDefinitions.find((e) => e.propertyDuid === datesFilter.propertyDuid)"
            :open-new-filter="() => {}"
            class="pointer-events-none select-none" />
        </div>
      </DatePicker>
    </div>
  </StatisticNoData>
</template>

<style scoped>
.dart-burn-up-chart {
  :deep(.apexcharts-gridline) {
    box-shadow: 0 0 1px transparent !important;
  }
  :deep(.apexcharts-tooltip) {
    @apply min-w-52 !rounded-xl border !bg-white p-3 !shadow border-md dark:!bg-zinc-850;
  }
  :deep(.apexcharts-tooltip-title) {
    @apply !mb-2 !border-none !bg-transparent !p-0 text-sm font-semibold text-hvy;
  }
  :deep(.apexcharts-tooltip-series-group) {
    @apply !flex w-full flex-col gap-1 !p-0;
  }
  :deep(.apexcharts-tooltip-y-group) {
    @apply flex w-full items-center gap-1 !py-1;
  }
  :deep(.apexcharts-tooltip-text-y-label) {
    @apply w-full flex-1 text-xs font-medium leading-tight text-md;
  }
  :deep(.apexcharts-tooltip-text-y-value) {
    @apply !m-0 font-semibold leading-tight text-lt;
  }
}
</style>
