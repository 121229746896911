import { type DirectiveBinding, type ObjectDirective } from "vue";

import { makeUuid } from "./common";

type ScrollElement = HTMLElement & {
  _scrollSyncUnbind?: () => void;
};

type ScrollSyncElement = {
  id: string;
  element: ScrollElement;
  group: string;
  syncX: boolean;
  syncY: boolean;
};

const scrollSyncElements: ScrollSyncElement[] = [];

const syncScrolling = (id: string, group: string, syncX: boolean, syncY: boolean, sourceElement: ScrollElement) => {
  scrollSyncElements.forEach((elem) => {
    if (elem.id !== id && elem.group === group) {
      if (syncX && elem.syncX) {
        // eslint-disable-next-line no-param-reassign
        elem.element.scrollLeft = sourceElement.scrollLeft;
      }
      if (syncY && elem.syncY) {
        const maxScrollTop = elem.element.scrollHeight - elem.element.clientHeight;
        const newScrollTop = Math.min(Math.max(0, sourceElement.scrollTop), maxScrollTop);
        // eslint-disable-next-line no-param-reassign
        elem.element.scrollTop = newScrollTop;
      }
    }
  });
};

/**
 * Sync scrolling between elements in a group.
 * @example
 * <div>
 *   <!-- Element 1 with synchronization -->
 *   <div v-scroll-sync:group1.x.y>
 *     <!-- Content 1, group 1 (sync both x and y) -->
 *   </div>
 *
 *   <!-- Element 2 with synchronization -->
 *   <div v-scroll-sync:group1.x>
 *     <!-- Content 2, group 1 (sync only x) -->
 *   </div>
 *
 *   <!-- Element 3 with synchronization -->
 *   <div v-scroll-sync:group2.y>
 *     <!-- Content 3, group 2 (sync only y) -->
 *   </div>
 * </div>
 */
const ScrollSyncDirective: ObjectDirective = {
  mounted(
    elem: ScrollElement,
    binding: DirectiveBinding<{ arg: string; modifiers: { x?: boolean; y?: boolean; list?: boolean } }>
  ) {
    let elemNorm = elem;

    if (binding.modifiers.list) {
      elemNorm = elem.querySelector("[data-ref='eBodyViewport']") ?? elem;
    }

    if (elemNorm instanceof HTMLElement) {
      const id = elemNorm.id || makeUuid();
      const group = binding.arg || "default";
      const syncX = !!binding.modifiers.x;
      const syncY = !!binding.modifiers.y;
      scrollSyncElements.push({ id, element: elemNorm, group, syncX, syncY });

      elemNorm.addEventListener("scroll", () => {
        syncScrolling(id, group, syncX, syncY, elemNorm);
      });

      /* Clean up the event listener when the element is unmounted */
      const unmounted = () => {
        elemNorm.removeEventListener("scroll", () => {
          syncScrolling(id, group, syncX, syncY, elemNorm);
        });
        const index = scrollSyncElements.findIndex((element) => element.id === id);
        if (index !== -1) {
          scrollSyncElements.splice(index, 1);
        }
      };

      elemNorm._scrollSyncUnbind = unmounted;
    }
  },
  beforeUnmount(el: ScrollElement) {
    if (el._scrollSyncUnbind) {
      el._scrollSyncUnbind();
    }
  },
};

export default ScrollSyncDirective;
