<script setup lang="ts">
import { useAnimate } from "@vueuse/core";
import { nextTick, ref, watch } from "vue";

const target = ref<HTMLDivElement | null>(null);
const mover = ref<HTMLDivElement | null>(null);

const moving = ref(false);
const bound = ref({ width: 0, height: 0, left: 0, top: 0 });

const run = (source: HTMLElement) => {
  if (!target.value || !mover.value) {
    return;
  }

  const sourceRect = source.getBoundingClientRect();
  const moverRect = mover.value.getBoundingClientRect();
  const startDx = sourceRect.left - moverRect.left;
  const startDy = sourceRect.top - moverRect.top;
  bound.value = target.value.getBoundingClientRect();
  const endDx = bound.value.left - moverRect.left;
  const endDy = bound.value.top - moverRect.top;
  mover.value.style.width = `${bound.value.width}px`;
  mover.value.style.height = `${bound.value.height}px`;

  const { playState } = useAnimate(
    mover,
    [
      {
        opacity: "0.3",
        transform: `translate(${startDx}px, ${startDy}px)`,
      },
      {
        opacity: "1",
        transform: `translate(${endDx}px, ${endDy}px)`,
      },
    ],
    {
      duration: 500,
      easing: "cubic-bezier(0, 0, 0.2, 1)",
    }
  );

  watch(
    () => playState?.value,
    (newState) => {
      if (newState !== "finished") {
        return;
      }
      moving.value = false;
    }
  );
};

const animate = (source: HTMLElement) => {
  moving.value = true;
  nextTick(() => run(source));
};

defineExpose({
  animate,
});
</script>

<template>
  <div ref="target" :class="moving && 'pointer-events-none opacity-0'">
    <slot />
  </div>
  <div v-if="moving" ref="mover" class="pointer-events-none absolute opacity-100">
    <slot />
  </div>
</template>
