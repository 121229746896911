<script setup lang="ts">
import { computed } from "vue";

import PermissionManager from "~/components/dumb/PermissionManager.vue";
import { SpaceKind } from "~/shared/enums";
import type { Space } from "~/shared/types";

const props = defineProps<{
  space: Space;
}>();

const isGeneralOrPersonalSpace = computed(
  () => props.space.kind === SpaceKind.WORKSPACE || props.space.kind === SpaceKind.PERSONAL
);
</script>

<template>
  <PermissionManager :page="space" embedded :disabled="isGeneralOrPersonalSpace" full-height />
</template>
