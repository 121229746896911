<script setup lang="ts">
import { $createHeadingNode } from "@lexical/rich-text";
import { $dfs } from "@lexical/utils";
import {
  $getRoot,
  $isParagraphNode,
  $isTextNode,
  CLEAR_HISTORY_COMMAND,
  type EditorState,
  type SerializedEditorState,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, onUnmounted, ref, watch } from "vue";

import { THROTTLE_MS } from "~/constants/app";
import { EMOJI_SET } from "~/constants/iconAndEmoji";
import { PropertyKind } from "~/shared/enums";
import type { Comment, Task } from "~/shared/types";
import { useDataStore, useUserStore } from "~/stores";
import { areLexicalStatesSame, isLexicalStateEmpty, makeEmptyLexicalState } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";

import { EVENT_SUBSCRIBE_USER } from "../const";
import { $isEntityNode } from "../nodes/EntityNode";
import { trimLexicalState } from "../utils";
import OnChangePlugin from "./OnChangePlugin.vue";

const props = defineProps<{
  task: Task;
  threadComment?: Comment;
  hasFocus: boolean;
  comment?: Comment;
  editing: boolean;
}>();

const dataStore = useDataStore();
const userStore = useUserStore();
const editor = useLexicalComposer();

const originalLexicalState = ref<SerializedEditorState>(editor.getEditorState().toJSON());
const cancelEdit = () => {
  editor.setEditorState(editor.parseEditorState(originalLexicalState.value));
};
const saveEdit = (): boolean => {
  if (!props.comment) {
    return false;
  }

  editor.update(trimLexicalState, { discrete: true });

  // Stop edit if the comment is empty.
  const state = editor.getEditorState();
  if (isLexicalStateEmpty(state.toJSON())) {
    return false;
  }

  dataStore.updateComment(props.comment.duid, state.toJSON());
  return true;
};
const draft = ref<Comment | undefined>(undefined);
const setDraftAndEditor = (comment: Comment | undefined) => {
  draft.value = comment;
  editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
  const state = editor.parseEditorState(draft.value ? draft.value.text : makeEmptyLexicalState());
  editor.setEditorState(state);
};

/* Live sync draft */
const liveDraft = computed<Comment | undefined>(() =>
  dataStore
    .getCommentsByTaskDuid(props.task.duid)
    .find(
      (e) =>
        e.isDraft &&
        e.authorDuid === userStore.duid &&
        (props.threadComment ? e.rootDuid === props.threadComment.duid : e.rootDuid === null)
    )
);
watch(
  () => liveDraft.value?.text,
  () => {
    if (props.hasFocus) {
      return;
    }
    setDraftAndEditor(liveDraft.value);
  }
);

const saveManager = new ThrottleManager((comment: Comment, value: SerializedEditorState) => {
  if (!draft.value || areLexicalStatesSame(value, draft.value.text)) {
    return;
  }

  if (isLexicalStateEmpty(value)) {
    dataStore.deleteComment(comment);
    const hadFocus = props.hasFocus;
    setDraftAndEditor(undefined);
    if (hadFocus) {
      editor.focus();
    }
    return;
  }

  dataStore.updateComment(comment.duid, value);
}, THROTTLE_MS);

const handleEditorChanges = async (newEditorState: EditorState) => {
  const value = newEditorState.toJSON();
  if (props.comment && areLexicalStatesSame(props.comment.text, value)) {
    return;
  }

  if (!props.editing) {
    if (!draft.value) {
      const partialComment = props.threadComment ? { rootDuid: props.threadComment.duid } : {};
      draft.value = await dataStore.createComment(props.task, newEditorState.toJSON(), partialComment);
      return;
    }

    saveManager.run(draft.value, newEditorState.toJSON());
  }
};

const isEmpty = ref(isLexicalStateEmpty(originalLexicalState.value));
const handleUnfocusedEditorChanges = async (newEditorState: EditorState) => {
  const value = newEditorState.toJSON();
  isEmpty.value = isLexicalStateEmpty(value);
};

watch(
  [() => props.task, () => props.threadComment],
  ([task, threadComment], [previousTask, previousThreadComment]) => {
    if (props.editing || (task.duid === previousTask?.duid && threadComment?.duid === previousThreadComment?.duid)) {
      return;
    }

    saveManager.finish();
    // Find existing draft.
    setDraftAndEditor(
      dataStore
        .getCommentsByTaskDuid(task.duid)
        .find(
          (e) =>
            e.isDraft &&
            e.authorDuid === userStore.duid &&
            (props.threadComment ? e.rootDuid === props.threadComment.duid : e.rootDuid === null)
        )
    );
  },
  { immediate: true }
);

/* Publish comment */
const save = () => {
  if (props.editing || !draft.value) {
    return;
  }

  editor.update(
    () => {
      trimLexicalState();

      // make emoji-only messages big
      const root = $getRoot();
      if (root.getChildrenSize() !== 1) {
        return;
      }
      const paragraph = root.getFirstChild();
      if (!$isParagraphNode(paragraph) || paragraph.getChildrenSize() !== 1) {
        return;
      }
      const text = paragraph.getFirstChild();
      if (!$isTextNode(text)) {
        return;
      }
      const content = text.getTextContent();
      // TODO this doesn't work in multiple ways because emojis can be multiple characters
      if (content.length === 0 || content.length > 3 || [...content].some((e) => !EMOJI_SET.has(e))) {
        return;
      }

      const newHeading = $createHeadingNode("h1");
      newHeading.append(text);
      paragraph.replace(newHeading);
    },
    { discrete: true }
  );

  // Stop save if the comment is empty.
  const state = editor.getEditorState();
  if (isLexicalStateEmpty(state.toJSON())) {
    return;
  }

  /* Subscribe mentions */
  state.read(() => {
    $dfs($getRoot()).forEach(({ node }) => {
      if (!$isEntityNode(node)) {
        return;
      }
      const property = dataStore.getPropertyByDuid(node.getPropertyDuid());
      if (property === undefined || property.kind !== PropertyKind.DEFAULT_ASSIGNEES) {
        return;
      }
      const user = dataStore.getUserByDuid(node.getValue() as string);
      if (user === undefined) {
        return;
      }
      editor.dispatchCommand(EVENT_SUBSCRIBE_USER, user);
    });
  });

  saveManager.cancel();
  dataStore.updateComment(draft.value.duid, state.toJSON(), true);
  setDraftAndEditor(undefined);
};

onUnmounted(() => {
  saveManager.destroy();
});

defineExpose({
  cancelEdit,
  save,
  saveEdit,
  isEmpty,
});
</script>

<template>
  <OnChangePlugin
    :has-focus="hasFocus"
    @change="handleEditorChanges"
    @unfocused-change="handleUnfocusedEditorChanges" />
</template>
