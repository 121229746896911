<script setup lang="ts">
import { capitalize, computed, nextTick, ref } from "vue";
import { useRouter } from "vue-router";

import EntityDropdownItem from "~/components/dumb/EntityDropdownItem.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { TaskIcon } from "~/icons";
import type { Doc, Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getItemCountText, getTaskLink } from "~/utils/common";
import { sign } from "~/utils/comparator";

const props = defineProps<{
  doc: Doc;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const menu = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const editing = ref(false);

const relatedTasks = computed(() => dataStore.getTasksRelatedToDocOrdered(props.doc.duid));
const relatedTasksText = computed(() => ["Linked tasks", ...relatedTasks.value.map((e) => `• ${e.title}`)]);

const onSelect = (taskDuid: string) => {
  const task = dataStore.getTaskByDuid(taskDuid);
  if (!task) {
    return;
  }

  dataStore.createTaskDocRelationship(task.duid, props.doc.duid);
};

const openMenu = () => {
  editing.value = true;
  nextTick(() => {
    menu.value?.open?.();
  });
};

const removeTask = (taskDuid: string) => {
  const task = dataStore.getTaskByDuid(taskDuid);
  if (!task) {
    return;
  }

  dataStore.deleteTaskDocRelationship(task.duid, props.doc.duid);
};

const openTask = (task: Task) => {
  router.push(getTaskLink(task));
};

const taskOptions = computed(() => {
  const recentTaskCount = appStore.recentTaskStack.length;
  return dataStore
    .getTaskList()
    .sort((a, b) => {
      const aIndex = appStore.recentTaskStack.indexOf(a.duid);
      const bIndex = appStore.recentTaskStack.indexOf(b.duid);
      return sign((aIndex > -1 ? aIndex : recentTaskCount) - (bIndex > -1 ? bIndex : recentTaskCount));
    })
    .map((task) => ({
      value: task.duid,
      label: task.title,
      selected: relatedTasks.value.some((e) => e.duid === task.duid),
      disabled: false,
      component: EntityDropdownItem,
      componentArgs: {
        showOpen: true,
        onOpen: () => openTask(task),
      },
    }));
});

defineExpose({
  openMenu,
});
</script>

<template>
  <MultiselectDropdownMenu
    ref="menu"
    :items="taskOptions"
    :distance="2"
    placeholder="Link a task..."
    :width-pixels="448"
    :on-after-close="
      () => {
        editing = false;
      }
    "
    @add="onSelect"
    @remove="removeTask">
    <Tooltip :text="relatedTasks.length > 0 ? relatedTasksText : 'Connect a task to this doc'">
      <div class="flex h-6 items-center justify-center gap-1 rounded px-1.5 py-2 text-vlt focus-ring-none hover:bg-lt">
        <TaskIcon class="icon-xs" />
        <span class="select-none text-sm">
          {{
            relatedTasks.length > 0
              ? capitalize(getItemCountText(relatedTasks.length, "linked task", { noSpecial: true }))
              : "Link tasks"
          }}
        </span>
      </div>
    </Tooltip>
  </MultiselectDropdownMenu>
</template>
