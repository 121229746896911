<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { getPropertyWithConfigList } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import { CheckIcon, DartIcon } from "~/icons";
import { BillingPeriod, ButtonStyle, Entitlement, ModalWidth, TutorialName, UserRole } from "~/shared/enums";
import { useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { checkout } from "~/utils/billing";

const router = useRouter();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const isShown = computed(
  () =>
    userStore.isRoleGreaterOrEqual(UserRole.MEMBER) &&
    !tenantStore.isPremium &&
    tenantStore.getEntitlementValue(Entitlement.TRIAL_DAYS) > 0 &&
    (userStore.getTutorialStatus(TutorialName.TRIAL_ENDED) ?? 2) < 2
);
const keepUsingWorking = ref(false);

const iconBg = computed(() =>
  pageStore.theme === "light"
    ? "linear-gradient(180deg, #ffffff 0%, #d1d5db 100%), #ffffff"
    : "linear-gradient(180deg, #1e1e21 0%, #32323F 100%), #1e1e21"
);

const features = computed(
  () =>
    [
      ["AI roadmap planning", (userStore.getTutorialStatus(TutorialName.PLAN_WITH_AI) ?? 1) > 2],
      ["AI brainstorming", (userStore.getTutorialStatus(TutorialName.BRAINSTORM_WITH_AI) ?? 1) > 2],
      [
        "User roles",
        dataStore.getUserList({ includeSpecialists: true }).filter((e) => e.role !== UserRole.ADMIN).length > 0,
      ],
      ["Custom statuses", dataStore.getStatusList(dataStore.defaultStatusProperty).length > 3],
      ["Custom properties", getPropertyWithConfigList().filter(([, e]) => !e.isDefault).length > 0],
      ["Forms", dataStore.formList.length > 0],
      ["Zapier integration and automations", tenantStore.zapierIntegration?.enabled ?? false],
      ["Slack integration", tenantStore.slackIntegrationEnabled],
      ["Discord integration", tenantStore.discordIntegration?.enabled ?? false],
      ["Github integration and automations", tenantStore.githubIntegrationEnabled],
      ["and more!", false],
    ] as const
);
const featuresToKeep = computed(() => features.value.filter(([, enabled]) => enabled).map(([feature]) => feature));
const featuresToGet = computed(() => features.value.filter(([, enabled]) => !enabled).map(([feature]) => feature));

const keepUsing = () => {
  keepUsingWorking.value = true;
  checkout(router.currentRoute.value.fullPath, BillingPeriod.ANNUALLY);
};

const finish = () => userStore.updateTutorialStatuses([{ name: TutorialName.TRIAL_ENDED, status: 2 }]);

if (!userStore.isRoleGreaterOrEqual(UserRole.ADMIN) || tenantStore.isPremiumWithoutTrial) {
  finish();
}
</script>

<template>
  <Modal
    :entity="isShown"
    title="Your two week trial has ended"
    hide-title
    :width="ModalWidth.M"
    custom-styles="overflow-hidden"
    close-icon-styles="hidden"
    :style="{ '--icon-bg': iconBg }">
    <template #default>
      <div class="mb-10 flex flex-col gap-8">
        <div class="relative flex -translate-y-10 flex-wrap justify-center gap-3">
          <div
            v-for="index in 10"
            :key="index"
            class="dart-icon-grad-bg flex size-20 shrink-0 items-center justify-center rounded-2xl border p-4 border-md"
            :class="index === 8 && 'z-10'">
            <DartIcon v-if="index === 8" class="z-10 icon-xl" />
          </div>
          <div class="absolute inset-x-0 bottom-0 top-6 bg-gradient-to-t from-transparent from-0% to-std" />
          <div class="absolute inset-0 flex">
            <div class="grow bg-gradient-to-l from-transparent from-0% to-std" />
            <div class="grow bg-gradient-to-r from-transparent from-0% to-std" />
          </div>
        </div>

        <div class="flex flex-col gap-2">
          <h3 class="text-center text-3xl font-bold text-md">Your Premium trial has ended</h3>

          <p class="flex flex-col items-center text-center text-lt">
            Don't lose access to abilities you rely on. Keep using Dart to its full potential!
          </p>
        </div>

        <div v-if="featuresToKeep.length > 1" class="flex flex-col gap-4">
          <h3 class="text-xl font-semibold text-md">Upgrade to keep using</h3>
          <div class="flex flex-col gap-2">
            <div v-for="feature in featuresToKeep" :key="feature" class="flex items-center gap-2">
              <CheckIcon class="text-primary-base icon-md" />
              <span class="text-base text-md">{{ feature }}</span>
            </div>
          </div>
        </div>

        <div v-if="featuresToGet.length > 1" class="flex flex-col gap-4">
          <h3 class="text-xl font-semibold text-md">
            When you upgrade, you'll {{ featuresToKeep.length > 1 ? "also" : "" }} get
          </h3>
          <div class="flex flex-col gap-2">
            <div v-for="feature in featuresToGet" :key="feature" class="flex items-center gap-2">
              <CheckIcon class="text-primary-base icon-md" />
              <span class="text-base text-md">{{ feature }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="flex w-full flex-col gap-2">
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          text="Keep using premium"
          block
          :working="keepUsingWorking"
          @click="keepUsing" />
        <Button :btn-style="ButtonStyle.SECONDARY" text="Downgrade" borderless block class="py-2" @click="finish" />
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.dart-icon-grad-bg {
  background: var(--icon-bg);
}
</style>
