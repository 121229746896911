<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Input from "~/components/dumb/Input.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { copyAndNotify } from "~/components/notifications";
import { CopyIcon, PlusIcon, RefreshIcon, XIcon } from "~/icons";
import { ButtonSize, ButtonStyle, DialogMode, Entitlement, IconSize } from "~/shared/enums";
import type { ValidationFunctionResult, Webhook } from "~/shared/types";
import { useDataStore, useEnvironmentStore, useTenantStore } from "~/stores";
import { isValidLocalUrl, makeSecretWebhook, validateUrl } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

const ROTATE_SECRET_DIALOG_DESCRIPTION =
  "Rotating the webhook secret will invalidate the old secret. This can't be undone. Are you sure you want to proceed?";

const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const tenantStore = useTenantStore();

const confirmationDialog = ref<InstanceType<typeof UpgradeConfirmationDialog> | null>(null);
const urlEditorRefs = ref<(InstanceType<typeof Input> | null)[]>([]);
const newUrlEditorRef = ref<InstanceType<typeof Input> | null>(null);
const toggleRef = ref<InstanceType<typeof Toggle> | null>(null);
const upgradeRequired = computed(() => !tenantStore.getEntitlementValue(Entitlement.WEBHOOKS));

const webhooks = computed<Webhook[]>(() => dataStore.webhookList);

const toggleEnabled = (newValue: boolean) => {
  if (upgradeRequired.value && !tenantStore.webhookEnabled) {
    toggleRef.value?.reset();
    confirmationDialog.value?.openModal();
    return;
  }

  tenantStore.webhookEnabled = newValue;
  backendOld.workspace.edit("webhookEnabled", newValue);
};

const validateWebhookUrl = (url: string): ValidationFunctionResult => {
  if (url === "") {
    return { isValid: false, error: "Enter a URL" };
  }
  if (isValidLocalUrl(url)) {
    return { isValid: false, error: "Enter a URL that is not local" };
  }
  if (url.startsWith(environmentStore.urlBase)) {
    return { isValid: false, error: "Enter a URL that is not from Dart" };
  }
  return validateUrl(url);
};

const copySecret = () => {
  copyAndNotify("Webhook secret", tenantStore.webhookSecret);
};

const rotateSecret = () => {
  const newSecret = makeSecretWebhook();
  tenantStore.webhookSecret = newSecret;
  backendOld.workspace.edit("webhookSecret", newSecret);
  copySecret();
};

const creatingWebhook = ref(false);

const startCreatingWebhook = () => {
  creatingWebhook.value = true;
  nextTick(() => {
    newUrlEditorRef.value?.focus();
  });
};

const finishCreatingWebhook = (url: string) => {
  if (!newUrlEditorRef.value?.isValid) {
    return;
  }

  dataStore.createWebhook(url, getOrdersBetween(webhooks.value[webhooks.value.length - 1]?.order, undefined)[0]);
  newUrlEditorRef.value?.clear();
  creatingWebhook.value = false;
};

const deleteWebhook = (index: number) => {
  if (webhooks.value[index].url) {
    dataStore.deleteWebhook(webhooks.value[index]);
  }
};

const changeWebhookUrl = (index: number, newUrl: string) => {
  const webhook = webhooks.value[index];
  if (!urlEditorRefs.value[index]?.isValid || newUrl === webhook.url) {
    return;
  }

  dataStore.updateWebhook({ duid: webhook.duid, url: newUrl });
};
</script>

<template>
  <div class="h-full overflow-y-scroll">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex items-center gap-4">
          <h2 class="select-none text-xl text-md">Webhooks</h2>
          <UpgradeConfirmationDialog v-if="upgradeRequired" ref="confirmationDialog" feature-action="use webhooks" />
        </div>
        <div class="select-none text-sm/relaxed text-lt">
          <p>
            Dart can be configured to send updates to an endpoint of yours to automate some common workflows and build
            other custom integrations or processes.
          </p>
          <br />
          <p>
            Read
            <a href="https://help.itsdart.com/articles/9024895-webhooks" target="_blank" rel="noopener noreferrer">
              <span class="underline text-vlt">this help center article</span>
            </a>
            for more information on how to get this configured.
          </p>
        </div>
      </div>

      <Toggle
        ref="toggleRef"
        :value="tenantStore.webhookEnabled"
        label="Enable"
        description="Receive update notifications via webhooks"
        @update="toggleEnabled" />

      <template v-if="tenantStore.webhookEnabled">
        <div class="flex select-none flex-col gap-1">
          <div class="text-md">Webhook secret</div>
          <div class="flex gap-1 text-lt">
            <pre class="grow rounded px-3 py-[7px] text-xs bg-lt">{{ tenantStore.webhookSecret }}</pre>
            <Tooltip text="Copy webhook secret">
              <Button
                :btn-style="ButtonStyle.CHIP"
                :icon="CopyIcon"
                a11y-label="Copy webhook secret"
                @click="copySecret" />
            </Tooltip>
            <ConfirmationDialog
              :mode="DialogMode.DELETE"
              title="Rotate webhook secret"
              :description="ROTATE_SECRET_DIALOG_DESCRIPTION"
              confirm-text="Proceed"
              cancel-text="Cancel"
              :icon="RefreshIcon"
              @confirm="rotateSecret">
              <Tooltip text="Rotate webhook secret">
                <Button :btn-style="ButtonStyle.CHIP" :icon="RefreshIcon" a11y-label="Rotate webhook secret" />
              </Tooltip>
            </ConfirmationDialog>
          </div>
        </div>

        <div class="flex select-none flex-col gap-1">
          <div class="text-md">Webhook target URLs</div>
          <div v-for="(webhook, index) in webhooks" :key="webhook.duid" class="group/url relative">
            <Input
              ref="urlEditorRefs"
              :init-value="webhook.url"
              placeholder="URL"
              label="URL"
              hide-label
              borderless
              input-classes="truncate"
              :validate="validateWebhookUrl"
              class="w-full"
              @finalize="(newValue) => changeWebhookUrl(index, newValue)" />
            <div class="absolute -top-1.5 right-0 hidden h-1.5 w-3 group-hover/url:flex" />
            <div class="absolute -right-1.5 top-0 hidden h-3 w-1.5 group-hover/url:flex" />
            <div class="absolute -right-1.5 -top-1.5 hidden group-hover/url:flex">
              <Tooltip text="Delete webhook">
                <Button
                  :btn-style="ButtonStyle.SECONDARY"
                  :icon="XIcon"
                  :icon-size="IconSize.XS"
                  a11y-label="Delete webhook"
                  class="size-4 cursor-pointer items-center justify-center rounded-full border bg-lt border-md"
                  @click="deleteWebhook(index)" />
              </Tooltip>
            </div>
          </div>

          <div v-if="creatingWebhook">
            <Input
              ref="newUrlEditorRef"
              placeholder="URL"
              label="URL"
              hide-label
              borderless
              input-classes="truncate"
              :validate="validateWebhookUrl"
              class="w-full"
              @finalize="finishCreatingWebhook" />
          </div>
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :icon="PlusIcon"
            text="Add webhook"
            borderless
            :size="ButtonSize.SMALL"
            class="w-max pl-2"
            @click="startCreatingWebhook" />
        </div>
      </template>
    </div>
  </div>
</template>
