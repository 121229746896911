import app from "./app";
import auth from "./auth";
import commands from "./commands";
import context from "./context";
import doc from "./doc";
import form from "./form";
import task from "./task";
import visualization from "./visualization";
import websockets from "./websockets";

export type Actions = {
  /** Local app */
  app: ReturnType<typeof app>;
  /** Auth actions */
  auth: ReturnType<typeof auth>;
  /** Commands and CommandBar */
  commands: ReturnType<typeof commands>;
  /** Context menus */
  context: ReturnType<typeof context>;
  /** Doc actions */
  doc: ReturnType<typeof doc>;
  /** Form actions */
  form: ReturnType<typeof form>;
  /** Other non-field task */
  task: ReturnType<typeof task>;
  /** Visualization (list, board etc.) */
  visualization: ReturnType<typeof visualization>;
  /** Websockets client */
  websockets: ReturnType<typeof websockets>;
};

const actions: Actions = {} as Actions;

actions.app = app(actions);
actions.auth = auth(actions);
actions.commands = commands(actions);
actions.context = context(actions);
actions.doc = doc(actions);
actions.form = form(actions);
actions.task = task(actions);
actions.visualization = visualization(actions);
actions.websockets = websockets(actions);

export default actions;
