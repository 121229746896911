<script setup lang="ts">
import {
  $isCodeNode,
  CodeNode,
  getCodeLanguages,
  getDefaultCodeLanguage,
  getLanguageFriendlyName,
} from "@lexical/code";
import { mergeRegister } from "@lexical/utils";
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import EntityDropdownItem from "~/components/dumb/EntityDropdownItem.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import { copyAndNotify } from "~/components/notifications";
import { ChevronDownIcon, CopyIcon } from "~/icons";
import { ButtonSize, ButtonStyle, IconSize } from "~/shared/enums";
import { LAST_CODING_LANGUAGE_KEY, lsGet, lsSet } from "~/utils/localStorageManager";

import { getSelectedNode } from "../utils";

const PADDING = 6;
const LANGUAGES = getCodeLanguages();
const FRIENDLY_LANGUAGES = new Set<string>();
const UNIQUE_LANGUAGES: string[] = [];
LANGUAGES.forEach((e) => {
  const friendly = getLanguageFriendlyName(e);
  if (FRIENDLY_LANGUAGES.has(friendly)) {
    return;
  }
  FRIENDLY_LANGUAGES.add(friendly);
  UNIQUE_LANGUAGES.push(e);
});

defineProps<{
  editable: boolean;
}>();

const editor = useLexicalComposer();

const top = ref("0px");
const left = ref("0px");
const width = ref("0px");

const codeNode = ref<CodeNode | null>(null);
const selectedLang = ref<string | null>(null);

const shown = ref(false);

const handleCodeNodeMutations = (mutatedNodes: Map<string, string>) =>
  editor.update(() => {
    [...mutatedNodes.entries()].forEach(([key, action]) => {
      const node = $getNodeByKey(key);
      if (node === null || action !== "created" || !$isCodeNode(node)) {
        return;
      }
      node.setLanguage(lsGet(LAST_CODING_LANGUAGE_KEY) ?? getDefaultCodeLanguage());
    });
  });

const copyCode = () => {
  editor.getEditorState().read(() => {
    const code = codeNode.value?.getTextContent();
    if (!code) {
      return;
    }

    copyAndNotify("Code", code);
  });
};

const setSelectedLang = (lang: string) => {
  selectedLang.value = lang;
  lsSet(LAST_CODING_LANGUAGE_KEY, lang);
  editor.update(() => {
    codeNode.value?.setLanguage(lang);
  });
};

const languageOptions = computed(() =>
  UNIQUE_LANGUAGES.map((lang) => ({
    value: lang,
    label: getLanguageFriendlyName(lang),
    selected: lang === selectedLang.value,
    disabled: false,
    component: EntityDropdownItem,
    componentArgs: { hideClose: true },
  }))
);

const updatePosition = () => {
  shown.value = false;
  codeNode.value = null;

  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    shown.value = false;
    return;
  }

  const node = getSelectedNode(selection);
  const parent = node.getParent();
  if (!$isCodeNode(node) && !$isCodeNode(parent)) {
    shown.value = false;
    return;
  }

  codeNode.value = $isCodeNode(node) ? node : (parent as CodeNode);
  selectedLang.value = codeNode.value.getLanguage() ?? null;

  const element = editor.getElementByKey(codeNode.value.getKey());
  const rootElement = editor.getRootElement();
  if (!element || !rootElement) {
    shown.value = false;
    return;
  }

  shown.value = true;
  const rootRect = rootElement.getBoundingClientRect();
  const rect = element.getBoundingClientRect();

  const newLeft = rect.left - rootRect.left + PADDING + 4;
  left.value = `${newLeft}px`;
  top.value = `${rect.top - rootRect.top + PADDING}px`;
  width.value = `${rect.width - PADDING * 2}px`;
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updatePosition();
        return false;
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerMutationListener(CodeNode, handleCodeNodeMutations)
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <div v-if="shown && selectedLang" :style="{ top, left, width }" class="absolute flex justify-between px-0.5">
    <MultiselectDropdownMenu :items="languageOptions" :disabled="!editable" close-on-select @add="setSelectedLang">
      <Button
        :text="getLanguageFriendlyName(selectedLang)"
        :btn-style="ButtonStyle.SECONDARY"
        :size="ButtonSize.CHIP"
        class="py-0.5 pl-1.5 pr-0.5"
        :class="!editable && 'cursor-default'"
        text-style="text-xs text-vlt"
        :icon-after="editable ? ChevronDownIcon : undefined"
        :icon-size="IconSize.XS"
        :icon-after-args="{ class: 'text-vlt' }"
        borderless
        :disable-hover="!editable"
        is-contrast />
    </MultiselectDropdownMenu>

    <Button
      :btn-style="ButtonStyle.SECONDARY"
      class="!p-0.5"
      :size="ButtonSize.CHIP"
      borderless
      :icon="CopyIcon"
      :icon-size="IconSize.XS"
      :icon-args="{ class: 'text-vlt' }"
      is-contrast
      a11y-label="Copy"
      @click="copyCode" />
  </div>
</template>
