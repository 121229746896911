import { ModelType } from "~/shared/enums";
import { useDataStore, useEnvironmentStore, usePageStore, useTenantStore, useUserStore } from "~/stores";

import type { Actions } from ".";

// TODO we're not deduping these because the tenantStore and userStore don't updated nicely the way that the dataStore does--fix this and remove this var
const NO_DEDUPE_CLASS_NAMES = new Set(["tenant"]);

/** Websockets manager */
const websockets = (actions: Actions) => ({
  getManager: () => {
    class WebsocketManager {
      endpoint!: string;

      socket!: WebSocket;

      closingIntentionally = false;

      constructor() {
        const environmentStore = useEnvironmentStore();
        this.endpoint = `${environmentStore.wsBase}/ws/v0/updates/`;
      }

      connect() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thiz = this;

        const dataStore = useDataStore();
        const tenantStore = useTenantStore();
        const userStore = useUserStore();

        const modelUpdateDispatchMap = {
          "create attachment": dataStore.$createOrUpdateAttachment,
          "update attachment": dataStore.$createOrUpdateAttachment,
          "delete attachment": dataStore.$deleteAttachment,
          "create brainstorm": dataStore.$createOrUpdateBrainstorm,
          "update brainstorm": dataStore.$createOrUpdateBrainstorm,
          "delete brainstorm": dataStore.$deleteBrainstorm,
          "create comment": dataStore.$createOrUpdateComment,
          "update comment": dataStore.$createOrUpdateComment,
          "delete comment": dataStore.$deleteComment,
          "create dartboard": dataStore.$createOrUpdateDartboard,
          "update dartboard": dataStore.$createOrUpdateDartboard,
          "delete dartboard": dataStore.$deleteDartboard,
          "create dashboard": dataStore.$createOrUpdateDashboard,
          "update dashboard": dataStore.$createOrUpdateDashboard,
          "delete dashboard": dataStore.$deleteDashboard,
          "create doc": dataStore.$createOrUpdateDoc,
          "update doc": dataStore.$createOrUpdateDoc,
          "delete doc": dataStore.$deleteDoc,
          "create folder": dataStore.$createOrUpdateFolder,
          "update folder": dataStore.$createOrUpdateFolder,
          "delete folder": dataStore.$deleteFolder,
          "create form": dataStore.$createOrUpdateForm,
          "update form": dataStore.$createOrUpdateForm,
          "delete form": dataStore.$deleteForm,
          "create form field": dataStore.$createOrUpdateFormField,
          "update form field": dataStore.$createOrUpdateFormField,
          "delete form field": dataStore.$deleteFormField,
          "create layout": dataStore.$createOrUpdateLayout,
          "update layout": dataStore.$createOrUpdateLayout,
          "delete layout": dataStore.$deleteLayout,
          "create notification": dataStore.$createOrUpdateNotification,
          "update notification": dataStore.$createOrUpdateNotification,
          "create option": dataStore.$createOrUpdateOption,
          "update option": dataStore.$createOrUpdateOption,
          "delete option": dataStore.$deleteOption,
          "create property": dataStore.$createOrUpdateProperty,
          "update property": dataStore.$createOrUpdateProperty,
          "delete property": dataStore.$deleteProperty,
          "create space": dataStore.$createOrUpdateSpace,
          "update space": dataStore.$createOrUpdateSpace,
          "delete space": dataStore.$deleteSpace,
          "create status": dataStore.$createOrUpdateStatus,
          "update status": dataStore.$createOrUpdateStatus,
          "delete status": dataStore.$deleteStatus,
          "create task": dataStore.$createOrUpdateTask,
          "update task": dataStore.$createOrUpdateTask,
          "delete task": dataStore.$deleteTask,
          "create task doc relationship": dataStore.$createTaskDocRelationship,
          "delete task doc relationship": dataStore.$deleteTaskDocRelationship,
          "create task kind": dataStore.$createOrUpdateTaskKind,
          "update task kind": dataStore.$createOrUpdateTaskKind,
          "delete task kind": dataStore.$deleteTaskKind,
          "create tenant": tenantStore.setTenant,
          "delete tenant": userStore.forceLogout,
          "update tenant": tenantStore.setTenant,
          "create user": dataStore.$createOrUpdateUser,
          "update user": dataStore.$createOrUpdateUser,
          "delete user": dataStore.$deleteUser,
          "create view": dataStore.$createOrUpdateView,
          "update view": dataStore.$createOrUpdateView,
          "delete view": dataStore.$deleteView,
          "create webhook": dataStore.$createOrUpdateWebhook,
          "update webhook": dataStore.$createOrUpdateWebhook,
          "delete webhook": dataStore.$deleteWebhook,
        };

        thiz.closingIntentionally = false;
        thiz.socket = new WebSocket(thiz.endpoint);

        thiz.socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.type !== "model_update") {
            // eslint-disable-next-line no-console
            console.warn("Unexpected websocket message ", message);
            return;
          }

          // console.log(message.action, message.className, "incoming");

          if (
            !NO_DEDUPE_CLASS_NAMES.has(message.className) &&
            message.item.updatedByClientDuid === usePageStore().duid
          ) {
            return;
          }

          // console.log(message.action, message.className, "not deduped");

          // Invalidate undo/redo history.
          if (message.className === "task") {
            // Invalidate all actions.
            if (message.action === "delete") {
              dataStore.invalidateHistoryActions(ModelType.TASK, message.item.duid);
            } else if (message.action === "update") {
              const task = dataStore.getTaskByDuid(message.item.duid);

              if (task) {
                Object.entries(task).forEach(([key, value]) => {
                  if (value !== message.item[key]) {
                    dataStore.invalidateHistoryActions(ModelType.TASK, message.item.duid, key);
                  }
                });
              }
            }
          }

          const updateKey = `${message.action} ${message.className}` as keyof typeof modelUpdateDispatchMap;
          const updateFunc = modelUpdateDispatchMap[updateKey];
          if (!updateFunc) {
            // eslint-disable-next-line no-console
            console.warn("Unexpected websocket update key ", updateKey);
            return;
          }
          updateFunc(message.item);
        };

        thiz.socket.onclose = () => {
          if (thiz.closingIntentionally) {
            return;
          }
          setTimeout(() => thiz.connect(), 5000);
        };

        thiz.socket.onerror = () => {
          thiz.socket.close();
        };
      }

      disconnect() {
        this.closingIntentionally = true;
        this.socket.close();
      }
    }

    return new WebsocketManager();
  },
});

export type WebsocketManager = ReturnType<ReturnType<typeof websockets>["getManager"]>;

export default websockets;
