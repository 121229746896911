import { copyAndNotify } from "~/components/notifications";
import type { Doc } from "~/shared/types";
import { useEnvironmentStore } from "~/stores";

import type { Actions } from ".";

/** Doc actions */
export default (actions: Actions) => ({
  /**
   * Copy the links to docs to the clipboard
   * @param {Doc[]} docs
   */
  copyLinks: (docs: Doc[]): void => {
    const environmentStore = useEnvironmentStore();

    if (docs.length === 0) {
      return;
    }

    const content: string[][] = docs
      .map((e) => [e.title, environmentStore.getDocUrl(e)])
      .map(([title, url]) => [url, `<a href=${url}>${title}</a>`]);

    const plural = content.length > 1 ? "s" : "";
    copyAndNotify(
      `Link${plural} to doc${plural}`,
      content.map((e) => e[0]).join("\n"),
      content.map((e) => e[1]).join("<br />")
    );
  },
});
