<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { ChevronDownIcon, ChevronUpIcon } from "~/icons";
import type { Filter, FilterDefinition, FilterValue } from "~/shared/types";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  filter: Filter;
  definition: FilterDefinition | undefined;
  enabled: boolean;
  readOnly?: boolean;
}>();

const emit = defineEmits<{
  select: [values: FilterValue[]];
  afterClose: [];
  removeFilter: [];
  replaceFilter: [];
}>();

const input = ref<HTMLInputElement | null>(null);

const value = computed(() => (props.filter.values.length ? props.filter.values[0] : ""));

const focus = () => {
  if (props.readOnly) {
    return;
  }
  setTimeout(() => {
    input.value?.focus();
  });
};

const change = (v: string) => {
  emit("select", [v]);
};

const increment = () => {
  change((parseInt((value.value ?? "0").toString(), 10) + 1).toString());
};

const decrement = () => {
  change((parseInt((value.value ?? "0").toString(), 10) - 1).toString());
};

onMounted(() => {
  if (value.value === "") {
    emit("select", [""]);
    focus();
  }
});

defineExpose({
  open: focus,
});

const id = ref(`filter-number-input-${makeUuid()}`);
</script>

<template>
  <div class="group/number-input relative">
    <label :for="id" class="sr-only">Filter number</label>
    <input
      :id="id"
      ref="input"
      v-autowidth="{ minWidth: '20px', comfortZone: '12px' }"
      type="number"
      :disabled="!enabled || filter.locked"
      :value="value"
      class="mr-0.5 appearance-none truncate border-none bg-transparent p-0 text-sm font-normal text-md focus-ring-none"
      @blur="() => value === '' && emit('replaceFilter')"
      @input="(e) => change((e.target as HTMLInputElement).value)" />
    <div
      class="absolute inset-y-0 right-0 flex flex-col justify-center opacity-0 text-md group-focus-within/number-input:opacity-100 group-hover/number-input:opacity-100">
      <div class="cursor-pointer rounded hover:bg-md" @click="increment" @keydown.enter="increment">
        <ChevronUpIcon class="size-3" />
      </div>
      <div class="cursor-pointer rounded hover:bg-md" @click="decrement" @keydown.enter="decrement">
        <ChevronDownIcon class="size-3" />
      </div>
    </div>
  </div>
</template>
