<script setup lang="ts">
import { computed, ref } from "vue";

import { getProperty, getPropertyConfig } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import GenericSubdropdown from "~/components/dumb/GenericSubdropdown.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import columns from "~/components/visualization/list/columns";
import { SortAscendingIcon, SortDescendingIcon, SortIcon, XIcon } from "~/icons";
import { ButtonStyle, DropdownMenuItemKind, IconSize } from "~/shared/enums";
import type { Property } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

const appStore = useAppStore();
const dataStore = useDataStore();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const properties = computed(() => {
  const { columnDefs, titleColDef } = columns(dataStore);
  columnDefs.value.unshift(titleColDef.value);

  return columnDefs.value
    .filter((column) => !column.hide && column.sortable)
    .map((column) => column.headerComponentParams?.property)
    .filter((e): e is Property => !!e);
});

const dropdownSections = computed(() => [
  {
    title: "Sorts",
    items: properties.value.map((property) => {
      const propertyConfig = getPropertyConfig(property.kind);
      const sort = appStore.sorts.find((e) => e.propertyDuid === property.duid);

      return {
        title: property.title,
        kind: DropdownMenuItemKind.COMPONENT,
        component: GenericSubdropdown,
        componentArgs: {
          title: property.title,
          icon: propertyConfig.icon,
          iconArgs: { class: "icon-sm" },
          widthPixels: 160,
          selected: !!sort,
          sections: [
            {
              title: "Direction",
              items: [
                {
                  title: "Ascending",
                  kind: DropdownMenuItemKind.BUTTON,
                  icon: SortAscendingIcon,
                  selected: sort?.isAscending === true,
                  onClick: () => appStore.setAllSorts([{ propertyDuid: property.duid, isAscending: true }]),
                },
                {
                  title: "Descending",
                  kind: DropdownMenuItemKind.BUTTON,
                  icon: SortDescendingIcon,
                  selected: sort?.isAscending === false,
                  onClick: () => appStore.setAllSorts([{ propertyDuid: property.duid, isAscending: false }]),
                },
              ],
            },
          ],
        },
      };
    }),
  },
]);
const activeSort = computed(() => {
  const sort = appStore.sorts?.[0];
  if (!sort) {
    return null;
  }
  const property = getProperty(sort.propertyDuid);
  if (!property) {
    return null;
  }

  const propertyConfig = getPropertyConfig(property.kind);
  return { title: property.title, icon: propertyConfig.icon, isAscending: sort.isAscending };
});

const removeSort = () => {
  appStore.resetSorts();
};

const openDropdown = () => {
  dropdownMenu.value?.open();
};

defineExpose({
  openDropdown,
});
</script>

<template>
  <DropdownMenu ref="dropdownMenu" :sections="dropdownSections" cover :max-height-pixels="960">
    <div>
      <div v-if="activeSort" class="group/sort relative">
        <Tooltip text="Change sort">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :icon="activeSort.icon"
            :icon-after="activeSort.isAscending ? SortAscendingIcon : SortDescendingIcon"
            :icon-size="IconSize.S"
            :text="activeSort.title"
            a11y-label="Change sort"
            class="h-6 !gap-0.5 px-[3px] py-0 !border-md" />
        </Tooltip>

        <!-- dummy elements to help avoid overshooting the x -->
        <div class="absolute -top-1.5 right-0 hidden h-1.5 w-3 group-hover/sort:flex" />
        <div class="absolute -right-1.5 top-0 hidden h-3 w-1.5 group-hover/sort:flex" />
        <button
          type="button"
          class="absolute -right-1.5 -top-1.5 hidden cursor-pointer items-center justify-center rounded-full border bg-lt border-md icon-xs group-hover/sort:flex"
          aria-label="Remove sort"
          @click.stop="removeSort"
          @keydown.enter="removeSort">
          <Tooltip text="Remove sort">
            <XIcon class="size-full text-lt focus:outline-none" />
          </Tooltip>
        </button>
      </div>
      <Tooltip v-else text="Sort tasks">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :icon="SortIcon"
          :icon-size="IconSize.S"
          a11y-label="Sort tasks"
          class="h-6 !gap-0.5 px-[3px] py-0 !border-md" />
      </Tooltip>
    </div>
  </DropdownMenu>
</template>
