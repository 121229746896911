<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";
import { computed, nextTick, ref, watch } from "vue";

import AiFeedbackButtons from "~/components/dumb/AiFeedbackButtons.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { Placement } from "~/shared/enums";
import { useAppStore } from "~/stores";
import { makeDuid } from "~/utils/common";

const appStore = useAppStore();

const tooltip = ref<InstanceType<typeof Tooltip> | null>(null);
const key = ref(makeDuid());
const recommendationDuids = ref<string[]>([]);
const node = ref<InstanceType<typeof Element> | null>(null);

watch(
  () => appStore.feedbackTooltip,
  (feedbackTooltip) => {
    if (!feedbackTooltip) {
      tooltip.value?.hide();
      return;
    }

    key.value = makeDuid();
    node.value = feedbackTooltip.node;

    nextTick(() => {
      recommendationDuids.value = feedbackTooltip.recommendationDuids;
      tooltip.value?.show();
    });
  }
);

const componentArgs = computed(() => ({
  recommendationDuids: recommendationDuids.value,
  isContrast: true,
  onRemove: () => {
    tooltip.value?.hide();
  },
}));
</script>

<template>
  <OnClickOutside @trigger="tooltip?.hide()">
    <Tooltip
      ref="tooltip"
      :key="key"
      :reference-node="node ?? undefined"
      :component="AiFeedbackButtons"
      :component-args="componentArgs"
      :placement="Placement.BOTTOM" />
  </OnClickOutside>
</template>
