<script setup lang="ts">
import TaskOrDocPreviewTooltip from "~/components/dumb/TaskOrDocPreviewTooltip.vue";
import type { Task } from "~/shared/types";

import EntityDropdownItem from "./EntityDropdownItem.vue";

defineProps<{
  label: string;
  selected: boolean;
  disabled: boolean;
  task: Task;
  lineThrough: boolean;
}>();

const emit = defineEmits<{
  remove: [];
}>();
</script>

<template>
  <div class="-my-1 -ml-3 -mr-2 flex grow overflow-hidden">
    <TaskOrDocPreviewTooltip :task="task" :distance="4" block>
      <div class="w-full py-1 pl-3 pr-2">
        <EntityDropdownItem
          :label="label"
          :selected="selected"
          :disabled="disabled"
          :line-through="lineThrough"
          @remove="emit('remove')" />
      </div>
    </TaskOrDocPreviewTooltip>
  </div>
</template>
