<script setup lang="ts">
import { useMediaQuery, watchOnce } from "@vueuse/core";
import { computed } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ZoomInIcon, ZoomOutIcon } from "~/icons";
import { ButtonStyle, CommandId, DropdownMenuItemKind } from "~/shared/enums";

import { getPixelsToNow } from "./common";
import { DEFAULT_BLOCK_WIDTH_PX, NAMED_ZOOM_LEVELS, ZOOM_MULTIPLIER } from "./constants";
import type { RoadmapConfig, RoadmapValues } from "./shared";

const BUTTON_STYLES =
  "flex h-6 cursor-pointer text-sm items-center justify-center rounded bg-lt p-0.5 enabled:hover:bg-md focus-ring-std text-md dark:enabled:hover:bg-zinc-700 disabled:opacity-50 disabled:cursor-not-allowed";

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
}>();

const emit = defineEmits<{
  scrollTo: [x: number];
  scrollToToday: [];
  zoom: [pxPerDay: number];
}>();

/* Scrolling */
const scrollLeft = (): void => {
  emit("scrollTo", props.roadmapValues.scrollX - DEFAULT_BLOCK_WIDTH_PX);
};
const scrollRight = (): void => {
  emit("scrollTo", props.roadmapValues.scrollX + DEFAULT_BLOCK_WIDTH_PX);
};
/* Put today line at 1/3 */
const scrollToToday = (): void => {
  emit("scrollTo", getPixelsToNow(props.roadmapConfig) - Math.ceil(props.roadmapValues.width / 3));
};
/* Scroll to today */
watchOnce(() => props.roadmapValues.width, scrollToToday);

/* Value and range dropdown */
const label = computed(
  () =>
    NAMED_ZOOM_LEVELS.reduce((a, b) => {
      const { pxPerDay } = props.roadmapConfig;
      const aNorm = pxPerDay > a[1] ? pxPerDay / a[1] : a[1] / pxPerDay;
      const bNorm = pxPerDay > b[1] ? pxPerDay / b[1] : b[1] / pxPerDay;
      return bNorm < aNorm ? b : a;
    })[0]
);
const dropdownSections = computed(() => [
  {
    title: "Zoom",
    items: NAMED_ZOOM_LEVELS.map(([title, pxPerDay]) => ({
      title,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: pxPerDay === props.roadmapConfig.pxPerDay,
      onClick: () => emit("zoom", pxPerDay),
    })),
  },
]);

const isPrinting = useMediaQuery("print");
</script>

<template>
  <div class="absolute right-0 top-0 flex h-[34px] items-center">
    <div class="h-full w-2 bg-gradient-to-l from-std" />
    <div v-if="!isPrinting" class="flex items-center justify-center gap-2 pl-1 pr-2.5 pt-2.5 bg-std">
      <!-- Scroll-->
      <div class="flex items-center justify-center gap-1">
        <Tooltip text="Previous month">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :icon="ChevronLeftIcon"
            borderless
            class="!p-0 bg-lt icon-lg hover:bg-md"
            a11y-label="Previous month"
            @click="scrollLeft" />
        </Tooltip>
        <Tooltip text="Jump to today">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            text="Today"
            borderless
            class="h-6 !px-2 !py-0 bg-lt hover:bg-md"
            @click="scrollToToday" />
        </Tooltip>
        <Tooltip text="Next month">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :icon="ChevronRightIcon"
            borderless
            class="!p-0 bg-lt icon-lg hover:bg-md"
            a11y-label="Next month"
            @click="scrollRight" />
        </Tooltip>
      </div>

      <!-- Dropdown -->
      <DropdownMenu :sections="dropdownSections" :width-pixels="120">
        <button type="button" :class="[BUTTON_STYLES, 'gap-1 pl-2 pr-1']">
          {{ label }}
          <ChevronDownIcon class="text-lt icon-xs" />
        </button>
      </DropdownMenu>

      <!-- Zoom -->
      <div class="flex items-center justify-center gap-1">
        <Tooltip :disabled="roadmapValues.zoomOutDisabled" :command-id="CommandId.ZOOM_OUT">
          <Button
            :disabled="roadmapValues.zoomOutDisabled"
            :btn-style="ButtonStyle.SECONDARY"
            :icon="ZoomOutIcon"
            borderless
            class="!p-0 bg-lt icon-lg"
            :class="!roadmapValues.zoomOutDisabled && 'hover:bg-md'"
            a11y-label="Zoom out"
            @click="emit('zoom', roadmapConfig.pxPerDay / ZOOM_MULTIPLIER)" />
        </Tooltip>
        <Tooltip :disabled="roadmapValues.zoomInDisabled" :command-id="CommandId.ZOOM_IN">
          <Button
            :disabled="roadmapValues.zoomInDisabled"
            :btn-style="ButtonStyle.SECONDARY"
            :icon="ZoomInIcon"
            borderless
            class="!p-0 bg-lt icon-lg"
            :class="!roadmapValues.zoomInDisabled && 'hover:bg-md'"
            a11y-label="Zoom in"
            @click="emit('zoom', roadmapConfig.pxPerDay * ZOOM_MULTIPLIER)" />
        </Tooltip>
      </div>
    </div>
  </div>
</template>
