<script setup lang="ts">
import { useTextareaAutosize } from "@vueuse/core";
import { ref, watch } from "vue";

import { PropertyKind } from "~/shared/enums";
import type { Property } from "~/shared/types";

const props = defineProps<{
  property: Property;
  value?: string;
}>();

const emit = defineEmits<{
  update: [value: string];
}>();

const textInner = ref(props.value ?? "");
const { textarea } = useTextareaAutosize({ input: textInner });

watch(
  () => props.value,
  (text) => {
    const newText = text ?? "";
    if (newText === textInner.value) {
      return;
    }

    textInner.value = newText;
  }
);

const updateText = () => {
  emit("update", textInner.value);
};

const onEnterPress = () => textarea.value?.blur();
</script>

<template>
  <textarea
    ref="textarea"
    v-model="textInner"
    :aria-label="property.title"
    :placeholder="property.kind === PropertyKind.DEFAULT_TITLE ? 'Write a task' : `Add text`"
    class="min-h-8 w-full cursor-text select-none resize-none hyphens-auto break-words rounded border px-2 py-[5px] text-sm caret-gray-700 bg-std text-md border-md focus-ring-none placeholder:text-vlt hover:border-hvy focus:border-primary-base dark:caret-zinc-300 dark:focus-ring-none dark:focus:border-primary-base"
    @input="updateText"
    @keydown.esc.stop.prevent="textarea?.blur()"
    @keydown.enter.stop.prevent="onEnterPress"
    @paste="updateText" />
</template>
