<script setup lang="ts">
import { computed, onUnmounted } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import type { Dartboard } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getPageDisplayName } from "~/utils/common";
import { makeRelativeTimeForDatesDateRef } from "~/utils/time";

const props = defineProps<{
  dartboard: Dartboard;
}>();

const dataStore = useDataStore();

const startedAtPretty = makeRelativeTimeForDatesDateRef(
  computed(() => props.dartboard.startedAt ?? new Date().toISOString())
);
const finishedAtPretty = makeRelativeTimeForDatesDateRef(
  computed(() => props.dartboard.finishedAt ?? new Date().toISOString())
);

onUnmounted(() => {
  startedAtPretty.value.destroy();
  finishedAtPretty.value.destroy();
});
</script>

<template>
  <div class="flex select-none flex-col gap-0.5 py-2 pl-3 pr-2 text-xs text-lt">
    <p class="truncate font-semibold text-md">{{ getPageDisplayName(dartboard, dataStore.getSpaceByDuid) }}</p>
    <p>Sprint {{ dartboard.index }}</p>
    <Tooltip v-if="dartboard.startedAt" :text="startedAtPretty.full">
      <p>Started {{ startedAtPretty.short }}</p>
    </Tooltip>
    <Tooltip v-if="dartboard.finishedAt" :text="finishedAtPretty.full">
      <p>Finished {{ finishedAtPretty.short }}</p>
    </Tooltip>
  </div>
</template>
