<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { $getRoot, $isParagraphNode, $isTextNode, type LexicalNode, type RangeSelection } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

import { EVENT_GENERATE_RECOMMENDATION } from "~/components/text/const";
import { getRecommendationTypeaheadOptions } from "~/components/text/utils";
import type { TypeaheadOption } from "~/shared/types";
import { usePageStore } from "~/stores";

import TypeaheadMenuPlugin from "./TypeaheadMenuPlugin.vue";

const pageStore = usePageStore();
const editor = useLexicalComposer();

const isLexicalStateEmptyExceptSlash = (): boolean => {
  const root = $getRoot();
  const rootChildren = root.getChildren();
  if (rootChildren.length !== 1 || !$isParagraphNode(rootChildren[0])) {
    return false;
  }
  const paragraph = rootChildren[0];
  const paragraphChildren = paragraph.getChildren();
  if (paragraphChildren.length === 0) {
    return true;
  }
  if (paragraphChildren.length !== 1 || !$isTextNode(paragraphChildren[0])) {
    return false;
  }
  const textNode = paragraphChildren[0];
  return textNode.getTextContent().startsWith("/");
};

const editorEmpty = ref(false);

const options = computed(() => getRecommendationTypeaheadOptions(editor._config.namespace, true, editorEmpty.value));

const onSelect = (_: RangeSelection, option: TypeaheadOption, parentNode: LexicalNode | null) => {
  editor.dispatchCommand(EVENT_GENERATE_RECOMMENDATION, {
    kind: option.value,
    parentKey: parentNode?.getKey(),
  });
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        editorEmpty.value = isLexicalStateEmptyExceptSlash();
      });
    })
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <TypeaheadMenuPlugin v-if="pageStore.isOnline" trigger="/" :options="options" @select="onSelect" />
</template>
