type Color = {
  red: number;
  green: number;
  blue: number;
  alpha: number;
};

type HslColor = {
  hue: number;
  saturation: number;
  lightness: number;
};

type CielabColor = {
  l: number;
  a: number;
  b: number;
};

export const WHITE_HEX = "#ffffff";
export const BLACK_HEX = "#000000";

export const fromHsl = (hsl: HslColor): Color => {
  const c = (1 - Math.abs(2 * hsl.lightness - 1)) * hsl.saturation;
  const x = c * (1 - Math.abs(((hsl.hue / 60) % 2) - 1));
  const m = hsl.lightness - c / 2;

  let r = 0;
  let g = 0;
  let b = 0;
  if (hsl.hue >= 0 && hsl.hue < 60) {
    r = c;
    g = x;
  } else if (hsl.hue >= 60 && hsl.hue < 120) {
    r = x;
    g = c;
  } else if (hsl.hue >= 120 && hsl.hue < 180) {
    g = c;
    b = x;
  } else if (hsl.hue >= 180 && hsl.hue < 240) {
    g = x;
    b = c;
  } else if (hsl.hue >= 240 && hsl.hue < 300) {
    r = x;
    b = c;
  } else if (hsl.hue >= 300 && hsl.hue < 360) {
    r = c;
    b = x;
  }

  return {
    red: Math.round((r + m) * 255),
    green: Math.round((g + m) * 255),
    blue: Math.round((b + m) * 255),
    alpha: 1,
  };
};

export const fromCielab = (cielab: CielabColor): Color => {
  let y = (cielab.l + 16) / 116;
  let x = cielab.a / 500 + y;
  let z = y - cielab.b / 200;

  x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
  y = 1.0 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
  z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

  let r = x * 3.2406 + y * -1.5372 + z * -0.4986;
  let g = x * -0.9689 + y * 1.8758 + z * 0.0415;
  let b = x * 0.0557 + y * -0.204 + z * 1.057;

  r = r > 0.0031308 ? 1.055 * r ** (1 / 2.4) - 0.055 : 12.92 * r;
  g = g > 0.0031308 ? 1.055 * g ** (1 / 2.4) - 0.055 : 12.92 * g;
  b = b > 0.0031308 ? 1.055 * b ** (1 / 2.4) - 0.055 : 12.92 * b;

  return {
    red: Math.max(0, Math.min(1, r)) * 255,
    green: Math.max(0, Math.min(1, g)) * 255,
    blue: Math.max(0, Math.min(1, b)) * 255,
    alpha: 1,
  };
};

export const fromHex = (hex: string): Color => {
  let hexNorm = hex.trim().replace(/^#/, "");
  if (hexNorm.length === 3 || hexNorm.length === 4) {
    hexNorm = hexNorm
      .split("")
      .map((e) => e + e)
      .join("");
  }
  const red = parseInt(hexNorm.substring(0, 2), 16);
  const green = parseInt(hexNorm.substring(2, 4), 16);
  const blue = parseInt(hexNorm.substring(4, 6), 16);
  const alpha = hexNorm.length === 8 ? parseInt(hexNorm.substring(6, 8), 16) / 255 : 1;
  return { red, green, blue, alpha };
};

export const fromRgbStr = (rgb: string): Color => {
  const [red, green, blue, alpha] = rgb
    .trim()
    .replace(/^rgba?\(|\)$/, "")
    .split(",")
    .map((e) => parseInt(e.trim(), 10));
  return { red, green, blue, alpha: alpha ?? 1 };
};

export const withAlpha = (color: Color, alpha: number): Color => ({ ...color, alpha });

export const toHsl = (color: Color): HslColor => {
  const r = color.red / 255;
  const g = color.green / 255;
  const b = color.blue / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  const lightness = (max + min) / 2;
  if (delta === 0) {
    return { hue: 0, saturation: 0, lightness };
  }

  const saturation = lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min);

  let hue = 0;
  if (max === r) {
    hue = (g - b) / delta + (g < b ? 6 : 0);
  } else if (max === g) {
    hue = (b - r) / delta + 2;
  } else {
    hue = (r - g) / delta + 4;
  }
  return { hue: (hue * 60) % 360, saturation, lightness };
};

export const toHex = (color: Color): string => {
  const red = Math.round(color.red).toString(16).padStart(2, "0");
  const green = Math.round(color.green).toString(16).padStart(2, "0");
  const blue = Math.round(color.blue).toString(16).padStart(2, "0");
  const alpha =
    color.alpha === 1
      ? ""
      : Math.round(color.alpha * 255)
          .toString(16)
          .padStart(2, "0");
  return `#${red}${green}${blue}${alpha}`;
};

export const toRgbStr = (color: Color): string => {
  const red = Math.round(color.red);
  const green = Math.round(color.green);
  const blue = Math.round(color.blue);
  const alpha = +color.alpha.toFixed(2);
  return `rgba(${red},${green},${blue},${alpha})`;
};

export const fromHexToHexWithAlpha = (hex: string, alpha: number): string => toHex(withAlpha(fromHex(hex), alpha));

// TODO this doesn't produce the exact same result as the CSS engine
export const combine = (background: Color, foreground: Color): Color => {
  const alpha = foreground.alpha + background.alpha * (1 - foreground.alpha);
  const red = (foreground.red * foreground.alpha + background.red * background.alpha * (1 - foreground.alpha)) / alpha;
  const green =
    (foreground.green * foreground.alpha + background.green * background.alpha * (1 - foreground.alpha)) / alpha;
  const blue =
    (foreground.blue * foreground.alpha + background.blue * background.alpha * (1 - foreground.alpha)) / alpha;
  return { red, green, blue, alpha };
};

export const getLuminance = (color: Color): number => {
  const r = color.red / 255;
  const g = color.green / 255;
  const b = color.blue / 255;
  const rs = r <= 0.03928 ? r / 12.92 : ((r + 0.055) / 1.055) ** 2.4;
  const gs = g <= 0.03928 ? g / 12.92 : ((g + 0.055) / 1.055) ** 2.4;
  const bs = b <= 0.03928 ? b / 12.92 : ((b + 0.055) / 1.055) ** 2.4;
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
};

export const getChipColorsHex = (
  colorHex: string,
  theme: "light" | "dark"
): { back: string; fore: string; border: string } => {
  const color = fromHex(colorHex);
  const baseColor = toHex({ ...color, alpha: 1 });
  if (theme === "light") {
    const fore = getLuminance(color) > 0.5 ? BLACK_HEX : WHITE_HEX;
    return { back: baseColor, fore, border: baseColor };
  }
  const hslColor = toHsl(color);
  hslColor.lightness = Math.max(hslColor.lightness, 0.8);
  const fore = toHex(fromHsl(hslColor));
  return { back: toHex({ ...color, alpha: 0.2 }), fore, border: baseColor };
};

export const makeRandomColorHex = (): string =>
  toHex(
    fromCielab({
      l: Math.random() * 40 + 30,
      a: Math.random() * 256 - 128,
      b: Math.random() * 256 - 128,
    })
  );
