<script setup lang="ts">
import { ref } from "vue";

import { useEnvironmentStore } from "~/stores";

const PAGES = [
  "https://itsdart.retool.com/apps/b1d181ee-96aa-11ec-bf22-2f909e03618a?_embed=true", // home
  "https://itsdart.retool.com/apps/bf19d232-366a-11ee-84ba-0f75fd23aff3?_embed=true", // historical revenue
  "https://itsdart.retool.com/apps/1250a1b4-366c-11ee-8696-ffae65420c14?_embed=true", // historical retention
  "https://itsdart.retool.com/apps/e1168364-3668-11ee-ad91-0b36426450b7?_embed=true", // historical activity
  "https://itsdart.retool.com/apps/67852c22-373e-11ee-9a8b-c3682749313e?_embed=true", // image
];
const ROTATE_INTERVAL_SEC = 60;
const REFRESH_INTERVAL_MIN = 11;
const CONTENT_WIDTH = 1560;
const CONTENT_HEIGHT = 850;

const rotateIntervalMs = ROTATE_INTERVAL_SEC * 1000;
const refreshIntervalMs = REFRESH_INTERVAL_MIN * 60 * 1000;

if (PAGES.length === 1) {
  PAGES.push(PAGES[0]);
}

const environmentStore = useEnvironmentStore();

let timer: ReturnType<typeof setTimeout> | undefined;
let shouldRefresh = false;

const currentPage = ref(0);
const paused = ref(false);
const panicked = ref(false);
const onAlt = ref(false);
const src = ref("");
const srcAlt = ref("");

const scale = ref(1);
const marginAmount = ref("0%");

const resize = () => {
  const scaleX = window.innerWidth / CONTENT_WIDTH;
  const scaleY = window.innerHeight / CONTENT_HEIGHT;
  scale.value = Math.min(scaleX, scaleY);
  marginAmount.value = `${Math.max(0, scaleX - scaleY) * 50}%`;
};

resize();
window.onresize = resize;

const prepNext = () => {
  const page = PAGES[(currentPage.value + 1) % PAGES.length];
  if (onAlt.value) {
    src.value = page;
  } else {
    srcAlt.value = page;
  }
};

const next = () => {
  onAlt.value = !onAlt.value;
  currentPage.value = (currentPage.value + 1) % PAGES.length;
  if (shouldRefresh && currentPage.value === 0) {
    window.location.reload();
  }
};

const resetTimer = () => {
  clearInterval(timer);
  if (!(paused.value || panicked.value)) {
    timer = setInterval(() => {
      next();
      prepNext();
    }, rotateIntervalMs);
  }
};

const togglePanic = () => {
  panicked.value = !panicked.value;
  paused.value = false;
  resetTimer();
  prepNext();
};

document.body.addEventListener("keydown", (event) => {
  if (event.ctrlKey || event.altKey || event.metaKey) {
    return;
  }

  switch (event.key) {
    case "CapsLock":
    case "Escape":
    case "ContextMenu":
    case "F11":
    case "F15": {
      break;
    }
    case "ArrowRight": {
      resetTimer();
      next();
      prepNext();
      break;
    }
    case " ": {
      if (!panicked.value) {
        paused.value = !paused.value;
        resetTimer();
      }
      break;
    }
    default: {
      togglePanic();
    }
  }
});

src.value = PAGES[currentPage.value];
prepNext();
resetTimer();

setTimeout(() => {
  shouldRefresh = true;
}, refreshIntervalMs);
</script>

<template>
  <div ref="container" class="size-full">
    <div ref="display" class="absolute size-full bg-[#f5f5f5]" :class="onAlt || panicked ? 'z-0 hidden' : 'z-10 flex'">
      <iframe
        :key="src"
        :src="src"
        title="frame"
        class="absolute left-0 top-0 origin-top-left border-0"
        :style="{
          width: `${CONTENT_WIDTH}px`,
          height: `${CONTENT_HEIGHT}px`,
          transform: `scale(${scale}`,
          'margin-left': marginAmount,
        }" />
    </div>
    <div
      ref="display-alt"
      class="absolute size-full bg-[#f5f5f5]"
      :class="!onAlt || panicked ? 'z-0 hidden' : 'z-10 flex'">
      <iframe
        :key="srcAlt"
        :src="srcAlt"
        title="frame-alt"
        class="absolute left-0 top-0 origin-top-left border-0"
        :style="{
          width: `${CONTENT_WIDTH}px`,
          height: `${CONTENT_HEIGHT}px`,
          transform: `scale(${scale}`,
          'margin-left': marginAmount,
        }" />
    </div>
    <div ref="panic" class="absolute z-20 size-full bg-gray-900" :class="panicked ? 'flex' : 'hidden'">
      <img
        :src="`${environmentStore.isLocal ? '' : '/static'}/img/logo_wide_white.png`"
        alt="panic"
        class="absolute top-1/2 ml-[10%] w-4/5 -translate-y-1/2" />
    </div>
    <div
      ref="cover"
      class="absolute z-30 size-full bg-gray-900"
      :class="paused ? 'opacity-20' : 'opacity-0'"
      @click="togglePanic"
      @keydown.enter="togglePanic" />
  </div>
</template>
