import type { PiniaPluginContext } from "pinia";

import * as allStores from "../stores";

/* Make all other stores available inside store. */
export default ({ store }: PiniaPluginContext) => {
  Object.entries(allStores).forEach(([key, value]) => {
    if (store.$id !== value.$id) {
      store[`$${key}`] = value;
    } else {
      store[`$${key}`] = () => {
        throw new Error("Do not use store link inside itself!");
      };
    }
  });
};
