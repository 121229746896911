<script setup lang="ts">
import { computed, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { BrainstormIcon, EditIcon, PauseIcon, PlayIcon, StopIcon } from "~/icons";
import { BrainstormState, ButtonStyle, DropdownMenuItemKind, IconSize, Placement } from "~/shared/enums";
import { useAppStore, useDataStore } from "~/stores";

const appStore = useAppStore();
const dataStore = useDataStore();

const activeBrainstorm = computed(() => appStore.currentActiveBrainstorm);
const brainstormPaused = computed(() => activeBrainstorm.value?.state === BrainstormState.PAUSED);

const getRemainingTimeMs = () =>
  activeBrainstorm.value
    ? Math.max(
        activeBrainstorm.value.afterStartMs -
          (new Date().getTime() - new Date(activeBrainstorm.value.startedAt).getTime()) +
          1000,
        0
      )
    : 0;
const remainingTimeMs = ref(getRemainingTimeMs());
const remainingTimeStr = ref<string>("0:00");

let timeout: ReturnType<typeof setTimeout> | undefined;
const resetValuesAndTimeout = () => {
  clearTimeout(timeout);
  if (!activeBrainstorm.value) {
    return;
  }

  remainingTimeMs.value = getRemainingTimeMs();
  timeout = setTimeout(resetValuesAndTimeout, remainingTimeMs.value % 1000);

  const hours = Math.floor(remainingTimeMs.value / 3600000);
  const minutes = Math.floor((remainingTimeMs.value % 3600000) / 60000);
  const seconds = Math.floor((remainingTimeMs.value % 60000) / 1000);

  if (hours > 0) {
    remainingTimeStr.value = `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  } else {
    remainingTimeStr.value = `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }
};
resetValuesAndTimeout();

watch([() => activeBrainstorm?.value?.afterStartMs, () => activeBrainstorm?.value?.state], ([afterStartMs]) => {
  remainingTimeMs.value = afterStartMs ?? 0;
  resetValuesAndTimeout();
});

const dropdownSections = computed(() => [
  {
    title: "Brainstorm",
    items: [
      brainstormPaused.value
        ? {
            title: "Continue brainstorm",
            kind: DropdownMenuItemKind.BUTTON,
            icon: PlayIcon,
            onClick: () =>
              appStore.currentActiveBrainstorm && dataStore.resumeBrainstorm(appStore.currentActiveBrainstorm),
          }
        : {
            title: "Pause brainstorm",
            kind: DropdownMenuItemKind.BUTTON,
            icon: PauseIcon,
            onClick: () =>
              appStore.currentActiveBrainstorm && dataStore.pauseBrainstorm(appStore.currentActiveBrainstorm),
          },
      {
        title: "Stop brainstorm",
        kind: DropdownMenuItemKind.BUTTON,
        icon: StopIcon,
        onClick: () => appStore.currentActiveBrainstorm && dataStore.stopBrainstorm(appStore.currentActiveBrainstorm),
      },
      {
        title: "Configure brainstorm",
        kind: DropdownMenuItemKind.BUTTON,
        icon: EditIcon,
        onClick: () => {
          if (!appStore.currentActiveBrainstorm) {
            return;
          }

          dataStore.pauseBrainstorm(appStore.currentActiveBrainstorm);
          appStore.setBrainstormModalOpen(true);
        },
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu
    v-if="activeBrainstorm"
    :sections="dropdownSections"
    :placement="Placement.BOTTOM_RIGHT"
    :distance="2"
    is-contrast
    :width-pixels="240">
    <Tooltip text="Configure brainstorm">
      <Button
        class="!pl-1 !pr-1.5 leading-[14px]"
        :class="!brainstormPaused && 'tabular-nums'"
        :text="brainstormPaused ? 'Paused' : remainingTimeStr"
        :icon="BrainstormIcon"
        :icon-args="{ class: activeBrainstorm.ai && 'text-recommendation-base' }"
        :icon-size="IconSize.XS"
        :btn-style="ButtonStyle.SECONDARY" />
    </Tooltip>
  </DropdownMenu>
</template>
