import type { SerializedEditorState, SerializedParagraphNode, SerializedRootNode, SerializedTextNode } from "lexical";

export const SERIALIZED_ROOT_NODE: SerializedRootNode = {
  children: [],
  direction: null,
  format: "",
  indent: 0,
  type: "root",
  version: 1,
};

export const SERIALIZED_PARAGRAPH_NODE: SerializedParagraphNode = {
  children: [],
  direction: null,
  format: "",
  indent: 0,
  type: "paragraph",
  textFormat: 0,
  version: 1,
};

export const SERIALIZED_TEXT_NODE: SerializedTextNode = {
  detail: 0,
  format: 0,
  mode: "normal",
  style: "",
  text: "",
  type: "text",
  version: 1,
};

export const EMPTY_SERIALIZED_EDITOR_STATE: SerializedEditorState = {
  root: {
    ...SERIALIZED_ROOT_NODE,
    children: [
      {
        ...SERIALIZED_PARAGRAPH_NODE,
      },
    ],
  },
};
