<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import Input from "~/components/dumb/Input.vue";
import Modal from "~/components/dumb/Modal.vue";
import ShortcutStrokes from "~/components/dumb/ShortcutStrokes.vue";
import { COMMANDS_LIST, getStrokesForPlatform } from "~/constants/command";
import { colorsByTheme } from "~/constants/style";
import { SearchIcon } from "~/icons";
import { ModalPosition, ModalWidth } from "~/shared/enums";
import { useAppStore, usePageStore } from "~/stores";
import { getAvailabilityFilterMap } from "~/utils/availabilityFilterManager";

const appStore = useAppStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);
const availabilityFilterMap = getAvailabilityFilterMap();

// focus the search input when the component is mounted
const searchInput = ref<InstanceType<typeof Input> | null>(null);
watch(
  () => searchInput.value,
  (newSearchInput) => {
    if (!newSearchInput) {
      return;
    }
    nextTick(() => {
      newSearchInput?.focus();
    });
  }
);

// set platform-specific strokes and filter based on which shortcuts apply
const sections = computed(() =>
  COMMANDS_LIST.map((section) => ({
    ...section,
    shortcuts: section.commands
      .filter(
        (command) =>
          !command.shortcutsIgnore &&
          (command.filters === undefined || command.filters.every((filter) => availabilityFilterMap.value.get(filter)))
      )
      .map((shortcut) => ({
        ...shortcut,
        strokes: getStrokesForPlatform(shortcut.id, pageStore.isMac),
      })),
  }))
);

// filter shortcuts on search
const search = ref("");
const filteredSections = computed(() =>
  search.value === ""
    ? sections.value
    : sections.value
        .map((section) => ({
          ...section,
          shortcuts: section.shortcuts.filter((shortcut) => {
            const searchLower = search.value.toLowerCase();
            return (
              section.title.toLowerCase().includes(searchLower) ||
              shortcut.title.toLowerCase().includes(searchLower) ||
              shortcut.aliases?.some((e) => e.toLowerCase().includes(searchLower)) ||
              shortcut.strokes?.some((strokeWrapped) => {
                const stroke =
                  typeof strokeWrapped === "object" && !Array.isArray(strokeWrapped)
                    ? strokeWrapped.content
                    : (strokeWrapped as string);
                return stroke.toLowerCase().includes(searchLower);
              })
            );
          }),
        }))
        .filter((section) => section.shortcuts.length !== 0)
);
</script>

<template>
  <Modal
    :entity="appStore.shortcutsModalOpen"
    title="Shortcuts"
    :width="ModalWidth.S"
    custom-styles="overflow-hidden h-full"
    :position="ModalPosition.RIGHT"
    @close="appStore.setShortcutsModalOpen(false)">
    <template #default>
      <div class="mt-5 flex h-full flex-col pb-10">
        <form class="flex items-center" autocomplete="off" @submit.prevent>
          <Input
            ref="searchInput"
            :init-value="search"
            placeholder="Search shortcuts"
            label="Search shortcuts"
            class="w-full"
            hide-label
            :icon="SearchIcon"
            @change="(newValue) => (search = newValue)" />
        </form>

        <div
          class="mr-[-15px] mt-5 flex flex-col gap-10 overflow-y-scroll px-1 extra-overscroll"
          :style="{ '--background': colors.bgStd, '--highlight': colors.highlight }">
          <div v-for="section in filteredSections" :key="section.title" class="">
            <div class="text-sm text-hvy">{{ section.title }}</div>
            <ul class="mt-2 divide-y border-y border-lt divide-lt">
              <li
                v-for="shortcut in section.shortcuts"
                :key="shortcut.id"
                class="flex items-center justify-between py-[6px]">
                <div class="text-sm text-lt">{{ shortcut.title }}</div>
                <ShortcutStrokes :strokes="shortcut.strokes" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
