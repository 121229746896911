<script setup lang="ts">
import { computed } from "vue";

import type { Task, TaskLink } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";

import DragArea from "./DragArea.vue";
import LinkComponent from "./LinkComponent.vue";

const props = defineProps<{
  task: Task;
  links: TaskLink[];
}>();

const dataStore = useDataStore();

const items = computed(() =>
  props.links
    .map((link) => ({
      ...link,
      title: link.title || link.url,
    }))
    .sort(makeStringComparator((e) => e.order))
);

const getComponentProps = (link: TaskLink) => ({
  task: props.task,
  link,
});

const moveLink = (_: string, link: TaskLink) => {
  dataStore.updateLink(props.task.duid, {
    duid: link.duid,
    order: link.order,
  });
};
</script>

<template>
  <DragArea
    group="link-editor"
    category="links"
    class="!h-auto w-full gap-1.5 px-3 py-1"
    :items="items"
    :component="LinkComponent"
    :get-component-props="getComponentProps"
    @change="moveLink" />
</template>
