<script setup lang="ts">
import { computed } from "vue";

import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import OptionComponent from "~/components/options/Option.vue";
import { EditorMode } from "~/shared/enums";
import type { Option, Property } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  value: string[];
  property?: Property;
  editorMode: EditorMode;
}>();

const dataStore = useDataStore();

const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);

const options = computed(() =>
  props.value.map((optionDuid) => dataStore.getOptionByDuid(optionDuid)).filter((option): option is Option => !!option)
);
</script>

<template>
  <component
    :is="isBoardMode ? Tooltip : Template"
    v-for="option in options"
    :key="option.duid"
    class="max-w-full"
    :text="property?.title">
    <OptionComponent :option="option" :truncate="!isBoardMode" show-ancestors :in-chip-group="isBoardMode">
      <slot />
    </OptionComponent>
  </component>
</template>
