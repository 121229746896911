<script setup lang="ts">
import { $createHeadingNode, $isHeadingNode, HeadingNode, type HeadingTagType } from "@lexical/rich-text";
import { mergeRegister } from "@lexical/utils";
import { $getNodeByKey, $isElementNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const EXCLUDED_TAG_TYPES = new Set<HeadingTagType>(["h4", "h5", "h6"]);

const editor = useLexicalComposer();

const handleHeadingNodeMutations = (mutatedNodes: Map<string, string>) =>
  editor.update(() => {
    const createdHeadings = [...mutatedNodes.entries()]
      .filter((e) => e[1] === "created")
      .map((e) => e[0])
      .map((e) => $getNodeByKey(e))
      .filter($isHeadingNode);
    createdHeadings.forEach((oldHeading) => {
      oldHeading.getChildren().forEach((child) => {
        if (!$isElementNode(child)) {
          return;
        }
        child.getChildren().forEach((grandchild) => {
          child.insertBefore(grandchild);
        });
        child.remove();
      });
      if (!EXCLUDED_TAG_TYPES.has(oldHeading.getTag())) {
        return;
      }
      const newHeading = $createHeadingNode("h3");
      newHeading.append(...oldHeading.getChildren());
      oldHeading.replace(newHeading);
    });
  });

let unregisterListeners: () => void;

onMounted(() => {
  if (!editor.hasNodes([HeadingNode])) {
    return;
  }
  unregisterListeners = mergeRegister(editor.registerMutationListener(HeadingNode, handleHeadingNodeMutations));
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
