<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";

import Button from "~/components/dumb/Button.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import PageCard from "~/components/PageCard.vue";
import { DASHBOARDS_PSEUDO_PAGE } from "~/constants/page";
import { PlusIcon } from "~/icons";
import { ButtonStyle, CommandId } from "~/shared/enums";
import type { Dashboard } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

appStore.setCurrentPage(DASHBOARDS_PSEUDO_PAGE);

const dragArea = ref<ComponentExposed<typeof DragArea<Dashboard, typeof PageCard>> | null>(null);
const dashboards = computed(() => dataStore.dashboardList);

const createDashboardAndStartEditing = () => {
  const order = getOrdersBetween(undefined, dashboards.value[0]?.order)[0];
  dataStore.createDashboard(order);

  nextTick(() => {
    const itemRefs = dragArea.value?.itemRefs ?? [];
    if (itemRefs.length === 0) {
      return;
    }

    itemRefs[itemRefs.length - 1].startEditingTitle();
  });
};

const getComponentProps = (dashboard: Dashboard) => ({
  page: dashboard,
});

const moveDashboard = (_: string, dashboard: Dashboard) => {
  dataStore.updateDashboard({
    duid: dashboard.duid,
    order: dashboard.order,
  });
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.dashboardsRoot = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.dashboardsRoot = null;
});

defineExpose({
  createDashboard: createDashboardAndStartEditing,
});
</script>

<template>
  <div class="relative flex size-full justify-center overflow-y-scroll">
    <div
      v-if="dashboards.length > 0"
      class="mx-6 flex h-fit max-w-xl grow flex-col extra-overscroll"
      :class="pageStore.isMobile ? 'mt-6' : 'mt-28'">
      <div class="flex items-end justify-between border-b px-2 py-3 border-md">
        <div class="select-none text-2xl font-semibold text-hvy">Dashboards</div>
        <Tooltip :command-id="CommandId.CREATE_DASHBOARD">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :icon="PlusIcon"
            borderless
            a11y-label="Create a dashboard"
            class="!p-0.5"
            @click="createDashboardAndStartEditing" />
        </Tooltip>
      </div>
      <DragArea
        ref="dragArea"
        group="dashboard-editor"
        category="dashboards"
        class="!h-auto w-full divide-y divide-lt"
        :items="dashboards"
        :component="PageCard"
        :get-component-props="getComponentProps"
        @change="moveDashboard" />
    </div>
    <PageEmptyState v-else />
  </div>
</template>
