<script lang="ts" setup>
import { computed } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import { FullscreenIcon, OverlayIcon, RightbarIcon } from "~/icons";
import { DropdownMenuItemKind, Placement, TaskDetailMode } from "~/shared/enums";
import { useAppStore, useUserStore } from "~/stores";

const appStore = useAppStore();
const userStore = useUserStore();

const makeSelect = (mode: TaskDetailMode) => () => {
  userStore.setTaskDetailMode(mode);
  appStore.setTaskDetailOpen(true, mode);
};

const sections = computed(() => [
  {
    title: "Change default",
    items: [
      {
        title: "Side panel",
        kind: DropdownMenuItemKind.BUTTON,
        icon: RightbarIcon,
        disabled: userStore.taskDetailMode === TaskDetailMode.RIGHTBAR,
        onClick: makeSelect(TaskDetailMode.RIGHTBAR),
      },
      {
        title: "Fullscreen",
        kind: DropdownMenuItemKind.BUTTON,
        icon: FullscreenIcon,
        disabled: userStore.taskDetailMode === TaskDetailMode.FULLSCREEN,
        onClick: makeSelect(TaskDetailMode.FULLSCREEN),
      },
      {
        title: "Overlay",
        kind: DropdownMenuItemKind.BUTTON,
        icon: OverlayIcon,
        disabled: userStore.taskDetailMode === TaskDetailMode.OVERLAY,
        onClick: makeSelect(TaskDetailMode.OVERLAY),
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu :sections="sections" :placement="Placement.RIGHT_TOP" show-on-hover>
    <DropdownMenuItemContent title="Change default" is-submenu />
  </DropdownMenu>
</template>
