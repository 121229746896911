import { $createCodeNode, $isCodeHighlightNode, CodeHighlightNode, CodeNode as CodeBlockNode } from "@lexical/code";
import { $isLinkNode, AutoLinkNode, LinkNode } from "@lexical/link";
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListItemNode,
  ListNode,
  type ListType,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, $createQuoteNode, HeadingNode, QuoteNode } from "@lexical/rich-text";
import { $isAtNodeEnd, $setBlocksType } from "@lexical/selection";
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $isDecoratorNode,
  $isElementNode,
  $isLineBreakNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  type EditorState,
  ElementNode,
  type LexicalEditor,
  type LexicalNode,
  type RangeSelection,
  type SerializedEditorState,
  type SerializedLexicalNode,
  type SerializedTextNode,
} from "lexical";
import pdfMake from "pdfmake/build/pdfmake";
import { merge } from "ts-deepmerge";

import { getProperty, getPropertyPartialTask } from "~/common/properties";
import * as fonts from "~/constants/font";
import { LANGUAGE_TO_DISPLAY_NAME } from "~/constants/language";
import { RecommendTranslateIcon } from "~/icons";
import { EditorMode, RecommendationKind } from "~/shared/enums";
import type { Task, TextBlockType, TypeaheadOption } from "~/shared/types";
import { usePageStore } from "~/stores";

import { RECOMMENDATION_KIND_TO_ICON_MAP } from "./const";
import { AiCursorNode } from "./nodes/AiCursorNode";
import { AttachmentNode } from "./nodes/AttachmentNode";
import { DartLinkNode } from "./nodes/DartLinkNode";
import type { SerializedEntityNode } from "./nodes/EntityNode";
import { EntityNode } from "./nodes/EntityNode";
import { $isHorizontalRuleNode, HorizontalRuleNode } from "./nodes/HorizontalRuleNode";
import { RecommendationButtonsNode } from "./nodes/RecommendationButtonsNode";
import { RecommendationLoadingNode } from "./nodes/RecommendationLoadingNode";
import { RecommendationWrapperNode } from "./nodes/RecommendationWrapperNode";
import type { SerializedSmartMatchNode } from "./nodes/SmartMatchNode";
import { ToggleButtonNode } from "./nodes/ToggleButtonNode";
import { ToggleContentNode } from "./nodes/ToggleContentNode";
import { $isToggleDetailsNode, ToggleDetailsNode } from "./nodes/ToggleDetailsNode";
import { $isToggleTitleNode, ToggleTitleNode } from "./nodes/ToggleTitleNode";
import { ToggleWrapperNode } from "./nodes/ToggleWrapperNode";

type CreateElementNodeFunc = () => ElementNode;

type UpdateListenerArg = {
  dirtyElements: Map<string, boolean>;
  dirtyLeaves: Set<string>;
  editorState: EditorState;
  normalizedNodes: Set<string>;
  prevEditorState: EditorState;
  tags: Set<string>;
};

type InputResult = {
  normal: boolean;
  atEnd: boolean | undefined;
  anchorNode: LexicalNode | undefined;
  anchorOffset: number | undefined;
};

pdfMake.vfs = fonts.default;

const BLOCK_TYPE_TO_CREATE_FUNCTION_MAP = new Map<TextBlockType, CreateElementNodeFunc>([
  ["paragraph", $createParagraphNode],
  ["h1", () => $createHeadingNode("h1")],
  ["h2", () => $createHeadingNode("h2")],
  ["h3", () => $createHeadingNode("h3")],
  ["quote", $createQuoteNode],
  ["code", $createCodeNode],
]);

export const GENERIC_EDITOR_NODES = [
  AutoLinkNode,
  CodeBlockNode,
  CodeHighlightNode,
  HeadingNode,
  LinkNode,
  ListItemNode,
  ListNode,
  QuoteNode,
  // custom nodes
  ToggleWrapperNode,
  ToggleButtonNode,
  ToggleContentNode,
  ToggleTitleNode,
  ToggleDetailsNode,
  HorizontalRuleNode,
];

export const PARTIAL_DART_EDITOR_NODES = [...GENERIC_EDITOR_NODES, AttachmentNode, DartLinkNode, EntityNode];

export const FULL_DART_EDITOR_NODES = [
  ...PARTIAL_DART_EDITOR_NODES,
  AiCursorNode,
  RecommendationButtonsNode,
  RecommendationLoadingNode,
  RecommendationWrapperNode,
];

// Copied from: https://github.com/wobsoriano/lexical-vue/blob/main/playground/src/utils.ts
export const getSelectedNode = (selection: RangeSelection) => {
  const { anchor, focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();

  if (anchorNode === focusNode) {
    return anchorNode;
  }

  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }

  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
};

export const isEmpty = (node: LexicalNode) => {
  if (!$isElementNode(node)) {
    return node.getTextContentSize() === 0;
  }
  const numChildren = node.getChildrenSize();
  if (numChildren !== 1) {
    return numChildren === 0;
  }

  const child = node.getFirstChild();
  if (!$isTextNode(child) && !$isHorizontalRuleNode(child)) {
    return false;
  }
  return child.getTextContentSize() === 0;
};

export const $isBlock = (node: LexicalNode): node is ElementNode => {
  if ($isDecoratorNode(node)) {
    return false;
  }
  if (!$isElementNode(node) || $isRootOrShadowRoot(node)) {
    return false;
  }
  const firstChild = node.getFirstChild();
  const isLeafElement =
    firstChild === null || $isLineBreakNode(firstChild) || $isTextNode(firstChild) || firstChild.isInline();
  return !node.isInline() && node.canBeEmpty() !== false && isLeafElement;
};

export const createTextBlockNode = (blockType: TextBlockType) => {
  const createNodeFunc = BLOCK_TYPE_TO_CREATE_FUNCTION_MAP.get(blockType);
  if (!createNodeFunc) {
    return;
  }
  const selection = $getSelection() as RangeSelection;
  if (!$isRangeSelection(selection)) {
    return;
  }
  $setBlocksType(selection, createNodeFunc);
};

export const formatBulletList = (type: ListType | null, editor: LexicalEditor) => {
  if (type !== "bullet") {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    return;
  }
  editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
};

export const formatNumberedList = (type: ListType | null, editor: LexicalEditor) => {
  if (type !== "number") {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    return;
  }
  editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
};

export const formatCheckList = (type: ListType | null, editor: LexicalEditor) => {
  if (type !== "check") {
    editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    return;
  }
  editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
};

export const isLineLevelNode = (node: LexicalNode) =>
  !($isTextNode(node) || $isCodeHighlightNode(node) || $isLinkNode(node));

export const getUpdateDetails = (arg: UpdateListenerArg): InputResult => {
  const { dirtyLeaves, editorState, prevEditorState, tags } = arg;
  const response: InputResult = { normal: false, atEnd: undefined, anchorNode: undefined, anchorOffset: undefined };

  if (tags.has("historic")) {
    return response;
  }

  const selection = editorState.read($getSelection);
  const prevSelection = prevEditorState.read($getSelection);
  if (!$isRangeSelection(prevSelection) || !$isRangeSelection(selection) || !selection.isCollapsed()) {
    return response;
  }

  const anchorKey = selection.anchor.key;
  const anchorNode = editorState._nodeMap.get(anchorKey);
  if (!$isTextNode(anchorNode) || !dirtyLeaves.has(anchorKey)) {
    return response;
  }

  const anchorOffset = selection.anchor.offset;
  const atEnd = anchorOffset === 1 || anchorOffset === prevSelection.anchor.offset + 1;
  return { normal: true, atEnd, anchorNode, anchorOffset };
};

export const trimLexicalState = () => {
  const root = $getRoot();

  // Trim the starting children and starting text
  let stack: LexicalNode[] = [root];
  for (let i = 0; i < 10000; i += 1) {
    const curr = stack[stack.length - 1];

    if ($isDecoratorNode(curr)) {
      break;
    }

    if ($isTextNode(curr)) {
      const trim = curr.getTextContent().trimStart();
      if (trim.length !== 0) {
        curr.setTextContent(trim);
        break;
      }
      curr.remove(true);
      stack.pop();
      continue;
    }

    const child = $isElementNode(curr) ? curr.getFirstChild() : null;
    if (child === null) {
      curr.remove(true);
      stack.pop();
      continue;
    }

    stack.push(child);
  }

  // Trim the ending children and ending text
  stack = [root];
  for (let i = 0; i < 10000; i += 1) {
    const curr = stack[stack.length - 1];

    if ($isDecoratorNode(curr)) {
      break;
    }

    if ($isTextNode(curr)) {
      const trim = curr.getTextContent().trimEnd();
      if (trim.length !== 0) {
        curr.setTextContent(trim);
        break;
      }
      curr.remove(true);
      stack.pop();
      continue;
    }

    const child = $isElementNode(curr) ? curr.getLastChild() : null;
    if (child === null) {
      const parent = stack[stack.length - 1];
      if (parent === undefined || (!$isToggleTitleNode(parent) && !$isToggleDetailsNode(parent))) {
        curr.remove(true);
      }
      stack.pop();
      continue;
    }

    stack.push(child);
  }

  // Children can't be empty, so add a paragraph if there are no children
  if (root.getChildrenSize() === 0) {
    root.append($createParagraphNode());
  }
};

const convertToTaskRecursive = (
  node: SerializedLexicalNode
): { titleBuilder: string[]; partialTask: Partial<Task> } => {
  const res: { titleBuilder: string[]; partialTask: Partial<Task> } = {
    titleBuilder: [],
    partialTask: {},
  };

  if (node.type === "text") {
    return {
      ...res,
      titleBuilder: [(node as SerializedTextNode).text.trim()],
    };
  }

  if (node.type === "entity" || node.type === "smart-match") {
    const { propertyDuid, value } = node as SerializedEntityNode | SerializedSmartMatchNode;
    const property = getProperty(propertyDuid);
    if (!property) {
      return res;
    }

    return {
      ...res,
      partialTask: getPropertyPartialTask(property, res.partialTask, value),
    };
  }

  if (!("children" in node)) {
    return res;
  }

  return (node.children as SerializedLexicalNode[]).map(convertToTaskRecursive).reduce(
    (acc, e) => ({
      titleBuilder: [...acc.titleBuilder, ...e.titleBuilder],
      partialTask: merge(acc.partialTask, e.partialTask),
    }),
    res
  );
};

export const convertToTask = (state: SerializedEditorState): Partial<Task> => {
  const { root } = state;

  const { titleBuilder, partialTask } = convertToTaskRecursive(root);
  const title = titleBuilder
    .filter((e) => !!e)
    .join(" ")
    .trim();

  return { ...partialTask, title };
};

export const getRecommendationTypeaheadOptions = (
  namespace: string,
  isTypeahead: boolean,
  isEditorEmpty?: boolean
): TypeaheadOption[] => {
  const pageStore = usePageStore();
  const isOffline = !pageStore.isOnline;

  const isDoc = namespace.startsWith("doc");
  const editorMode = isDoc
    ? EditorMode.DOC
    : EditorMode[namespace.split("-")[1].toUpperCase() as keyof typeof EditorMode];
  const entityName = isDoc ? "doc" : "description";

  const res: TypeaheadOption[] = [
    {
      value: RecommendationKind.SPLIT,
      label: "Split into new task",
      hide: isDoc || isTypeahead,
      iconArgs: {
        class: "icon-sm",
      },
    },
    {
      value: RecommendationKind.CONVERT_TO_TASKS,
      label: `Convert to ${isDoc ? "" : "sub"}tasks`,
      hide: isTypeahead,
      iconArgs: {
        class: "icon-sm",
      },
    },
    {
      value: RecommendationKind.PROPERTIES,
      label: "Fill out properties",
      hide: editorMode !== EditorMode.TCM || isOffline,
    },
    {
      value: RecommendationKind.SUBTASKS,
      label: "Break into subtasks",
      hide: editorMode === EditorMode.DOC || isOffline,
    },
    {
      value: RecommendationKind.BRAINSTORM,
      label: "Brainstorm methods",
      hide: editorMode === EditorMode.DOC || isOffline,
    },
    {
      value: RecommendationKind.WRITE,
      label: isEditorEmpty ? `Write ${entityName}` : "Continue writing",
      hide: isOffline,
    },
    {
      value: RecommendationKind.ACTION_ITEMS,
      label: "Extract action items",
      hide: isEditorEmpty || isOffline,
    },
    {
      value: RecommendationKind.IMPROVE,
      label: `Improve ${entityName}`,
      hide: isEditorEmpty || isOffline,
    },
    {
      value: RecommendationKind.SIMPLIFY,
      label: `Simplify ${entityName}`,
      hide: isEditorEmpty || isOffline,
    },
  ]
    .filter((e) => !e.hide)
    .map((e) => ({
      value: e.value as string,
      label: e.label,
      icon: RECOMMENDATION_KIND_TO_ICON_MAP.get(e.value),
      iconArgs: e.iconArgs ?? {
        class: "icon-sm text-recommendation-base",
      },
    }));

  if (!isEditorEmpty && !isOffline) {
    res.push({
      value: RecommendationKind.TRANSLATE,
      label: `Translate ${entityName}`,
      adtlSearchTerms: Object.entries(LANGUAGE_TO_DISPLAY_NAME).flatMap((e) => e),
      icon: RecommendTranslateIcon,
      iconArgs: {
        class: "icon-sm text-recommendation-base",
      },
      options: Object.keys(LANGUAGE_TO_DISPLAY_NAME).map((key) => ({
        value: key,
        label: `Translate to ${key}`,
        icon: RecommendTranslateIcon,
        iconArgs: {
          class: "icon-sm text-recommendation-base",
        },
      })),
    });
  }

  return res;
};
