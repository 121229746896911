<script setup lang="ts">
import equal from "deep-equal";
import type { SerializedEditorState } from "lexical";
import {
  LexicalAutoLinkPlugin,
  LexicalCheckListPlugin,
  LexicalLinkPlugin,
  LexicalListPlugin,
  LexicalMarkdownShortcutPlugin,
  LexicalTabIndentationPlugin,
} from "lexical-vue";
import { computed, ref } from "vue";

import type { Doc, DocUpdate } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";

import { DOC_TELEPORT_KEY, URL_MATCHERS } from "./const";
import AttachmentPlugin from "./plugins/AttachmentPlugin.vue";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin.vue";
import CodeSelectorPlugin from "./plugins/CodeSelectorPlugin.vue";
import FloatingToolbar from "./plugins/FloatingToolbar.vue";
import LinkPlugin from "./plugins/LinkPlugin.vue";
import ManageTextFormatPlugin from "./plugins/ManageTextFormatPlugin.vue";
import PasteUrlPlugin from "./plugins/PasteUrlPlugin.vue";
import PlaceholderPlugin from "./plugins/PlaceholderPlugin.vue";
import RecommendationPlugin from "./plugins/RecommendationPlugin.vue";
import RelationshipPlugin from "./plugins/RelationshipPlugin.vue";
import ReportGenerationPlugin from "./plugins/ReportGenerationPlugin.vue";
import ShortcutsPlugin from "./plugins/ShortcutsPlugin.vue";
import SplitPlugin from "./plugins/SplitPlugin.vue";
import SubscribeEventPlugin from "./plugins/SubscribeEventPlugin.vue";
import TableOfContentsPlugin from "./plugins/TableOfContentsPlugin.vue";
import TextSavePlugin from "./plugins/TextSavePlugin.vue";
import { EntityTypeaheadPlugin, RecommendationTypeaheadPlugin } from "./plugins/typeahead";
import TextEditor from "./TextEditor.vue";
import { TRANSFORMERS } from "./transformers";
import { FULL_DART_EDITOR_NODES } from "./utils";

const props = defineProps<{
  doc: Doc;
  mini?: boolean;
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const textEditor = ref<InstanceType<typeof TextEditor> | null>(null);
const recommendationPlugin = ref<InstanceType<typeof RecommendationPlugin> | null>(null);

const initialState = computed(() => props.doc.text);

const hasFocus = computed(() => !!textEditor.value?.hasFocus);

const saveLocally = async (duid: string, content: SerializedEditorState) => {
  const oldEditorDuids = props.doc.editorDuids;
  const oldSubscriberDuids = props.doc.subscriberDuids;
  await dataStore.updateDocs([{ duid, text: content }], { noBackend: true });
  const sameEditors = equal(props.doc.editorDuids, oldEditorDuids, { strict: true });
  const sameSubscribers = equal(props.doc.subscriberDuids, oldSubscriberDuids, { strict: true });
  if (sameEditors && sameSubscribers) {
    return;
  }

  const update: DocUpdate = { duid };
  if (!sameEditors) {
    update.editorDuids = props.doc.editorDuids;
  }
  if (!sameSubscribers) {
    update.subscriberDuids = props.doc.subscriberDuids;
  }
  dataStore.updateDocs([update]);
};

const focus = (start?: boolean) => textEditor.value?.focus(start);

const openRecommendations = () => recommendationPlugin.value?.openRecommendations();

defineExpose({
  focus,
  openRecommendations,
});
</script>

<template>
  <TextEditor
    ref="textEditor"
    :key="doc.duid"
    namespace="doc"
    :nodes="FULL_DART_EDITOR_NODES"
    :initial-state="initialState"
    :small-leading="mini"
    :disabled="mini"
    :placeholder="`Write anything${pageStore.isOnline ? ', / for AI' : ''}`"
    always-borderless
    :collaboration="mini ? undefined : { namespace: 'doc', id: doc.duid }">
    <template #default="{ history }">
      <CodeHighlightPlugin />
      <template v-if="!mini">
        <!-- lexical-vue plugins -->
        <LexicalAutoLinkPlugin :matchers="URL_MATCHERS" />
        <LexicalCheckListPlugin />
        <LexicalLinkPlugin />
        <LexicalListPlugin />
        <LexicalMarkdownShortcutPlugin :transformers="TRANSFORMERS" />
        <LexicalTabIndentationPlugin />
        <!-- custom plugins -->
        <AttachmentPlugin />
        <CodeSelectorPlugin editable />
        <EntityTypeaheadPlugin :has-focus="hasFocus" mentions-only />
        <LinkPlugin :history="history" @link-changes="() => {}" />
        <ManageTextFormatPlugin />
        <PasteUrlPlugin />
        <PlaceholderPlugin />
        <RecommendationPlugin ref="recommendationPlugin" :duid="doc.duid" />
        <RecommendationTypeaheadPlugin />
        <RelationshipPlugin :doc="doc" />
        <ReportGenerationPlugin v-if="!!doc.reportKind" :doc="doc" />
        <ShortcutsPlugin />
        <SplitPlugin :doc="doc" />
        <SubscribeEventPlugin :doc="doc" />
        <TableOfContentsPlugin />
        <TextSavePlugin :duid="doc.duid" :content="doc.text" :has-focus="hasFocus" immediate @save="saveLocally" />
        <!-- toolbars -->
        <FloatingToolbar :teleport-key="DOC_TELEPORT_KEY" show-on-scroll />
      </template>
    </template>
  </TextEditor>
</template>
