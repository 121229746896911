<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import Avatar from "~/components/dumb/Avatar.vue";
import { PlusIcon } from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import { UserRole } from "~/shared/enums";
import { useDataStore, useUserStore } from "~/stores";

const router = useRouter();
const dataStore = useDataStore();
const userStore = useUserStore();

const inviteUsers = () => router.push(makeLinkToSettingsRef("teammates").value);

const allUsers = computed(() => dataStore.getUserList().filter((user) => user.duid !== userStore.duid));
</script>

<template>
  <div class="h-80 w-full rounded-xl py-4 pl-4 bg-lt">
    <div class="flex max-h-full flex-wrap gap-5 overflow-y-scroll pr-px">
      <!-- Add button -->
      <div
        v-if="userStore.isRoleGreaterOrEqual(UserRole.MEMBER)"
        class="flex cursor-pointer flex-col items-center gap-2 rounded-lg p-3 hover:bg-md"
        @click="inviteUsers"
        @keydown.enter="inviteUsers">
        <div class="flex size-20 items-center justify-center rounded-full border-2 border-hvy">
          <PlusIcon class="text-md icon-md" />
        </div>
        <span class="select-none text-xs font-semibold text-lt">Invite</span>
      </div>

      <!-- Users -->
      <div v-for="user in allUsers" :key="user.duid" class="flex flex-col items-center gap-2 p-3">
        <Avatar
          :abrev="user.abrev"
          :color-hex="user.colorHex"
          :image-url="user.imageUrl"
          img-border
          circle
          class="size-20" />
        <span :title="user.name || user.email" class="max-w-20 select-none truncate text-xs font-semibold text-md">
          {{ user.name || user.email }}
        </span>
      </div>
    </div>
  </div>
</template>
