/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `count` - COUNT
 * * `pct` - PCT
 */
export enum PieChartDisplayMetric {
  COUNT = 'count',
  PCT = 'pct',
}
