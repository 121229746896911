<script setup lang="ts">
import { type Component, computed } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { SwapIcon, XIcon } from "~/icons";
import { CommandId } from "~/shared/enums";
import { usePageStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";

const props = defineProps<{
  label: string;
  id?: string;
  selected?: boolean;
  colorHex?: string;
  icon?: Component;
  iconArgs?: object;
  replaceEnabled?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  remove: [];
  replace: [];
}>();

const pageStore = usePageStore();

// Get colors or fallback to default
const colorSet = computed(() => getChipColorsHex(props.colorHex ?? DEFAULT_CHIP_COLOR, pageStore.theme));
</script>

<template>
  <div :id="id" class="flex grow items-center justify-between gap-2 overflow-hidden text-sm">
    <div
      class="flex min-h-5 select-none items-center justify-center overflow-hidden rounded border pr-1 text-xs"
      :class="icon ? 'px-1' : 'px-1.5'"
      :style="{ color: colorSet.fore, backgroundColor: colorSet.back, borderColor: colorSet.border }">
      <component :is="icon" v-if="icon" v-bind="iconArgs" class="mr-1 icon-sm" />
      <div :title="label" class="mr-0.5 truncate">
        {{ label }}
      </div>
      <div
        v-if="selected"
        class="flex cursor-pointer items-center justify-center rounded hover:bg-gray-400/40 dark:hover:bg-zinc-600/30"
        @click.stop="() => emit('remove')"
        @keydown.enter.stop="() => emit('remove')">
        <XIcon class="icon-xs" />
      </div>
    </div>

    <Tooltip v-if="replaceEnabled && !selected" :command-id="CommandId.SWAP">
      <div
        class="flex items-center justify-center rounded icon-md hover:bg-hvy"
        @click.stop="emit('replace')"
        @keydown.enter.stop="emit('replace')">
        <SwapIcon class="cursor-pointer text-md icon-xs" />
      </div>
    </Tooltip>
  </div>
</template>
