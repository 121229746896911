<script setup lang="ts">
import { computed, ref } from "vue";

import actions from "~/actions";
import TaskCard from "~/components/dumb/TaskCard.vue";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { EditorMode, PageKind, PropertyKind } from "~/shared/enums";
import type { GroupByGroup, TaskDraggable } from "~/shared/types";
import { useAppStore, usePageStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";

const props = defineProps<{
  column: GroupByGroup;
  task: TaskDraggable;
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const taskCard = ref<InstanceType<typeof TaskCard> | null>(null);

const groupByDefinition = computed(() => appStore.groupByDefinition);
const isView = computed(() => appStore.currentPage?.pageKind === PageKind.VIEW);
const groupByDartboard = computed(() => groupByDefinition.value.property.kind === PropertyKind.DEFAULT_DARTBOARD);

const openTaskInDetail = async (event: MouseEvent | KeyboardEvent) => {
  if (taskCard.value?.isEditingTitle) {
    return;
  }

  // TODO handle shift, box dragging, and then make it so that we work with the selected tasks when we should
  const { duid } = props.task;
  if (pageStore.isMac ? event.metaKey : event.ctrlKey) {
    const isSelected = appStore.selectedTaskDuids.has(duid);
    if (isSelected) {
      appStore.selectedTaskDuids.delete(duid);
    } else {
      appStore.selectedTaskDuids.add(duid);
    }
    return;
  }

  appStore.selectedTaskDuids = new Set([duid]);
  appStore.setTaskDetailOpen(true);
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
};

const modeAlphaMod = computed(() => (pageStore.theme === "light" ? 0 : -0.05));
const selectedBgColor = computed(() => fromHexToHexWithAlpha(props.column.colorHex, 0.3 + modeAlphaMod.value));
const selected = computed(() => appStore.selectedTaskDuids.has(props.task.duid) && !props.task.absentee);

const startEditingTitle = () => {
  taskCard.value?.startEditingTitle();
};

defineExpose({
  startEditingTitle,
});
</script>

<!-- eslint-disable tailwindcss/migration-from-tailwind-2 -->
<template>
  <div class="flex w-full flex-col gap-1">
    <TaskClickWrapper :task="task" :editor-mode="EditorMode.BOARD" :disabled="taskCard?.isEditingTitle">
      <div
        tabindex="-1"
        :class="[
          selected
            ? 'border-[--selectedBgColor] bg-[--selectedBgColor] dark:bg-[--selectedBgColor]'
            : 'border-transparent bg-std',
          taskCard?.isEditingTitle ? 'cursor-text' : 'cursor-pointer',
        ]"
        class="flex w-full select-none gap-2 rounded border p-2 transition-colors hover:bg-opacity-60 focus:outline-none dark:hover:bg-opacity-60"
        :style="{
          '--selectedBgColor': selectedBgColor,
        }"
        @click.stop="openTaskInDetail"
        @keydown.enter="openTaskInDetail">
        <TaskCard
          ref="taskCard"
          class="dart-board-task-card"
          :task="task"
          :has-shown-parent="!!task.parentDuid"
          :include-dartboard="isView && !groupByDartboard"
          :exclude-property="groupByDefinition.property" />
      </div>
    </TaskClickWrapper>
  </div>
</template>
