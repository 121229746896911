<script setup lang="ts">
import { computed } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { extractSkinTone, getEmojiWithSkinTone, normalizeEmoji, removeSkinTone } from "~/constants/iconAndEmoji";
import type { EmojiSkinTone } from "~/shared/enums";
import type { Comment, CommentReaction } from "~/shared/types";
import { useAppStore, useDataStore, useUserStore } from "~/stores";

import AddReaction from "./AddReaction.vue";

const props = defineProps<{
  comment: Comment;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const userStore = useUserStore();

type GroupedReaction = {
  emoji: string;
  skinTones: EmojiSkinTone[];
  reactorMonikers: string[];
  personalReactionDuid: string | null;
};

const groupedReactions = computed<GroupedReaction[]>(() =>
  Object.values(
    props.comment.reactions.reduce((acc: Record<string, GroupedReaction>, reaction: CommentReaction) => {
      const emoji = normalizeEmoji(reaction.emoji);
      const baseEmoji = removeSkinTone(emoji);
      const skinTone = extractSkinTone(emoji);
      const author = dataStore.getUserByDuid(reaction.authorDuid);
      if (acc[baseEmoji]) {
        if (author) {
          acc[baseEmoji].reactorMonikers.push(author.name || author.email);
        }
        if (reaction.authorDuid === userStore.duid) {
          // eslint-disable-next-line no-param-reassign
          acc[baseEmoji].personalReactionDuid = reaction.duid;
        }
        if (!acc[baseEmoji].skinTones.includes(skinTone)) {
          acc[baseEmoji].skinTones.push(skinTone);
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[baseEmoji] = {
          emoji: baseEmoji,
          skinTones: [skinTone],
          personalReactionDuid: reaction.authorDuid === userStore.duid ? reaction.duid : null,
          reactorMonikers: author ? [author.name || author.email] : [],
        };
      }
      return acc;
    }, {})
  )
);

const toggleReaction = (groupedReaction: GroupedReaction) => {
  if (groupedReaction.personalReactionDuid !== null) {
    dataStore.deleteCommentReaction(props.comment.duid, groupedReaction.personalReactionDuid);
  } else {
    dataStore.createCommentReaction(props.comment.duid, getEmojiWithSkinTone(groupedReaction.emoji, appStore.skinTone));
  }
};
</script>

<template>
  <div v-if="comment.reactions.length" class="flex w-full items-center gap-1.5 pb-1.5">
    <Tooltip
      v-for="groupedReaction in groupedReactions"
      :key="groupedReaction.emoji"
      :text="groupedReaction.reactorMonikers">
      <button
        type="button"
        :class="groupedReaction.personalReactionDuid ? 'bg-primary-base/40 hover:bg-primary-base/50' : 'hover:bg-md'"
        class="flex h-5 items-center justify-center gap-1 rounded-full py-1 pl-1 pr-1.5 text-vlt focus-ring-none"
        @click="toggleReaction(groupedReaction)">
        <div
          v-for="(skinTone, index) in groupedReaction.skinTones"
          :key="skinTone"
          :class="index > 0 && '-ml-2'"
          class="flex size-4 shrink-0 items-center justify-center text-sm/3">
          {{ getEmojiWithSkinTone(groupedReaction.emoji, skinTone) }}
        </div>
        <span class="mb-px text-[10px] text-md">
          {{ groupedReaction.reactorMonikers.length }}
        </span>
      </button>
    </Tooltip>
    <AddReaction :comment="comment" />
  </div>
</template>
