<script setup lang="ts">
import { computed } from "vue";

import { getPropertyValueStr } from "~/common/properties";
import TaskStatisticsChip from "~/components/dumb/TaskStatisticsChip.vue";
import { useStatistics } from "~/components/visualization/board/common";
import HeaderTitle from "~/components/visualization/components/HeaderTitle.vue";
import { EditorMode } from "~/shared/enums";
import type { GroupByGroup } from "~/shared/types";
import { useAppStore, usePageStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";

import { DROP_HOVER_STYLE } from "../constants";

const props = defineProps<{
  group: GroupByGroup;
  editorMode: EditorMode;
  hovering?: boolean;
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const hideEmptyGroup = computed(
  () =>
    appStore.hideEmptyGroups &&
    appStore.groupByValueToTasksMap.get(getPropertyValueStr(props.group.value))?.length === 0
);
const modeAlphaMod = computed(() => (pageStore.theme === "light" ? 0 : -0.05));
const backgroundColor = computed(() => fromHexToHexWithAlpha(props.group.colorHex, 0.2 + modeAlphaMod.value));
const hoverBgColor = computed(() => fromHexToHexWithAlpha(props.group.colorHex, 0.3 + modeAlphaMod.value));

const statistics = useStatistics(
  () => appStore.groupByValueToTasksMap.get(getPropertyValueStr(props.group.value)) ?? []
);

const uncollapseColumn = () => {
  const id = props.editorMode === EditorMode.BOARD ? props.group.id : getPropertyValueStr(props.group.value);

  appStore.setGroupCollapsed(id, false);
};
</script>

<template>
  <button
    v-if="!hideEmptyGroup"
    :data-group="group.id"
    type="button"
    class="dart-collapsed-header flex h-fit w-[200px] items-center rounded-lg bg-[--backgroundColor] px-4 py-7 focus-ring-std hover:bg-[--hoverBgColor]"
    :class="hovering && DROP_HOVER_STYLE"
    :style="{
      '--backgroundColor': backgroundColor,
      '--hoverBgColor': hoverBgColor,
    }"
    @click="uncollapseColumn"
    @keydown.enter="uncollapseColumn">
    <div class="flex h-6 min-w-0 select-none items-center gap-2">
      <component :is="group.icon" v-if="group.icon" v-bind="group.iconArgs" />
      <HeaderTitle :title="group.title" />
      <TaskStatisticsChip :statistics="statistics" :tooltip-show-delay="300" on-colored-background />
    </div>
  </button>
</template>
