<script setup lang="ts">
import { Tooltip } from "floating-vue";
import { computed, nextTick, ref, watch } from "vue";

import TaskCard from "~/components/dumb/TaskCard.vue";
import { Placement } from "~/shared/enums";
import { useAppStore, usePageStore } from "~/stores";
import { makeDuid } from "~/utils/common";

const DEFAULT_DISTANCE = 4;

const appStore = useAppStore();
const pageStore = usePageStore();

const tooltip = ref<InstanceType<typeof Tooltip> | null>(null);
const key = ref(makeDuid());

watch(
  () => appStore.taskPreviewTooltip,
  (taskPreviewTooltip, oldTaskPreviewTooltip) => {
    if (!taskPreviewTooltip || taskPreviewTooltip.node === oldTaskPreviewTooltip?.node) {
      return;
    }
    key.value = makeDuid();
    tooltip.value?.hide();
    nextTick(() => {
      tooltip.value?.show();
    });
  }
);

const listeners = new Map();

watch(
  () => appStore.taskPreviewTooltip?.node,
  (node, oldNode) => {
    if (node) {
      const listener = () => {
        tooltip.value?.hide();
        if (appStore.taskPreviewTooltip?.node === node) {
          appStore.taskPreviewTooltip = null;
        }
      };
      node.addEventListener("mouseleave", listener);
      listeners.set(node, listener);
    }
    if (oldNode) {
      const listener = listeners.get(oldNode);
      if (listener) {
        oldNode.removeEventListener("mouseleave", listener);
      }
    }
  }
);

const task = computed(() => appStore.taskPreviewTooltip?.task);
const distance = computed(() => {
  if (!appStore.taskPreviewTooltip) {
    return DEFAULT_DISTANCE;
  }
  const { node, scrollableParent } = appStore.taskPreviewTooltip;
  return 4 - (node.clientWidth - scrollableParent.clientWidth) + scrollableParent.scrollLeft;
});
</script>

<template>
  <Tooltip
    v-if="appStore.taskPreviewTooltip"
    ref="tooltip"
    :key="key"
    no-auto-focus
    :disabled="pageStore.isMobile"
    :placement="appStore.taskPreviewTooltip.placement ?? Placement.RIGHT"
    :triggers="['hover', 'focus']"
    :popper-triggers="['hover', 'focus']"
    :distance="distance"
    :skidding="4"
    :reference-node="() => appStore.taskPreviewTooltip?.node as Element"
    :auto-hide="task ? task.title !== '' : false"
    :theme="`tooltip-arrowless-${pageStore.theme}`">
    <template #popper>
      <div class="pointer-events-none w-64 rounded border p-2 bg-std border-md">
        <TaskCard v-if="task" :task="task" include-dartboard />
      </div>
    </template>
  </Tooltip>
</template>

<style>
.v-popper--theme-tooltip-arrowless-light .v-popper__arrow-container,
.v-popper--theme-tooltip-arrowless-dark .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-tooltip-arrowless-light .v-popper__wrapper,
.v-popper--theme-tooltip-arrowless-dark .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-tooltip-arrowless-light .v-popper__inner,
.v-popper--theme-tooltip-arrowless-dark .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
