import type { PiniaPluginContext } from "pinia";

import { notify } from "~/components/notifications";
import { dismiss } from "~/components/notifications/notification";
import { EventKind } from "~/shared/enums";
import { makeUuid } from "~/utils/common";

import createStack from "./stack";

const notifyStackEmpty = (action: string) => {
  const id = `no${action}-notification`;
  dismiss(id);
  notify({
    id,
    message: `Nothing to ${action}`,
  });
};

export default ({ store, options }: PiniaPluginContext) => {
  if (!options.undo) {
    return;
  }

  const stack = createStack();

  store.clearHistoryStack = stack.clearStack;

  store.undo = () => {
    const action = stack.undo();

    if (!action) {
      notifyStackEmpty("undo");
      return false;
    }
    notify({
      message: `Undid ${action.message[1]}`,
    });
    dismiss(action.id);
    action.undo();
    store.$backend.event.create(EventKind.USAGE_UNDO, { action: action.message[1] });
    return true;
  };

  store.redo = () => {
    const action = stack.redo();

    if (!action) {
      notifyStackEmpty("redo");
      return false;
    }
    notify({
      message: `Redid ${action.message[1]}`,
    });
    dismiss(action.id);
    action.redo();
    store.$backend.event.create(EventKind.USAGE_REDO, { action: action.message[1] });
    return true;
  };

  store.invalidateHistoryActions = stack.invalidateActions;

  store.saveToUndo = ({ modelType, duid, field }, message, undo, redo) => {
    const action = {
      id: makeUuid(),
      message,
      modelType,
      duid,
      field,
      undo,
      redo,
    };
    stack.push(action);
  };
};
