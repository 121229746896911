/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Running` - RUNNING
 * * `Paused` - PAUSED
 * * `Stopped` - STOPPED
 */
export enum BrainstormState {
  RUNNING = 'Running',
  PAUSED = 'Paused',
  STOPPED = 'Stopped',
}
