<script setup lang="ts">
import { getCurrentInstance } from "vue";

import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import { TrashIcon } from "~/icons";
import { useAppStore } from "~/stores";
import { getComponentAncestors } from "~/utils/common";

const props = defineProps<{
  dialog?: InstanceType<typeof ConfirmationDialog>;
  title?: string;
  description?: string;
  onDelete?: () => void;
}>();

const appStore = useAppStore();

const onClick = () => {
  if (props.dialog) {
    props.dialog?.openModal();
    return;
  }

  if (!props.title || !props.description || !props.onDelete) {
    // eslint-disable-next-line no-console
    console.error(
      "DropdownDeleteMenuItem must have either dialog or title, description, and onDelete",
      getComponentAncestors(getCurrentInstance())
    );
    return;
  }

  appStore.setDeleteConfirmationModalOpen({
    title: props.title,
    description: props.description,
    onDelete: props.onDelete,
  });
};
</script>

<template>
  <DropdownMenuItemContent title="Delete" :icon="TrashIcon" @click="onClick" />
</template>
