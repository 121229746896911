<script setup lang="ts">
import { type Component, computed } from "vue";

import UpdateTime from "~/components/dumb/UpdateTime.vue";
import { CreatedFieldIcon, LastUpdatedFieldIcon } from "~/icons";
import { ActivityKind } from "~/shared/enums";
import type { User } from "~/shared/types";

const props = defineProps<{
  activityKind: ActivityKind;
  at: string;
  user?: User;
}>();

const ACTIVITY_KIND_ICON_MAP: Map<ActivityKind, Component> = new Map([
  [ActivityKind.CREATED, CreatedFieldIcon],
  [ActivityKind.UPDATED, LastUpdatedFieldIcon],
]);

const ACTIVITY_KIND_TEXT_MAP: Map<ActivityKind, string> = new Map([
  [ActivityKind.CREATED, "Created"],
  [ActivityKind.UPDATED, "Last updated"],
]);

const updatedBy = computed(() => props.user?.name);
</script>

<template>
  <div class="flex select-none items-center gap-2.5 py-1.5 pl-2.5 pr-[30px]">
    <div class="flex items-center justify-center icon-lg">
      <component :is="ACTIVITY_KIND_ICON_MAP.get(activityKind)" class="text-lt icon-md" />
    </div>
    <span v-if="updatedBy" class="text-sm text-md">
      {{ updatedBy }}
    </span>
    <span class="text-sm text-md">
      {{ ACTIVITY_KIND_TEXT_MAP.get(activityKind) }}
    </span>
    <UpdateTime :at="at" tooltip-top />
  </div>
</template>
