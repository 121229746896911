<script setup lang="ts">
import { Dropdown } from "floating-vue";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  type LexicalNode,
  type RangeSelection,
  type TextNode,
} from "lexical";
import { LexicalTypeaheadMenuPlugin, MenuOption, useLexicalComposer } from "lexical-vue";
import { computed, ref } from "vue";
import { DynamicScroller, DynamicScrollerItem, RecycleScroller } from "vue-virtual-scroller";

import { backend } from "~/api";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import TaskOrDocPreviewTooltip from "~/components/dumb/TaskOrDocPreviewTooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { EventKind, Placement, TypeaheadSpecialEntity } from "~/shared/enums";
import type { TypeaheadOption, UnrealizedTypeaheadOptions } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";

import { LEXICAL_OUTSIDE_CLASS } from "../../const";
import { usePrefixTypeaheadTriggerMatch } from "./common";
import TypeaheadSubmenu from "./TypeaheadSubmenu.vue";

const props = defineProps<{
  trigger: string;
  options: UnrealizedTypeaheadOptions;
  inModal?: boolean;
  emojiGrid?: boolean;
  large?: boolean;
}>();

const emit = defineEmits<{
  select: [selection: RangeSelection, option: TypeaheadOption, parentNode: LexicalNode | null];
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();
const editor = useLexicalComposer();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const query = ref<string | null>(null);

const filteredOptions = computed(() => {
  const queryNorm = query.value?.toLowerCase() ?? "";
  const optionsRealized = typeof props.options === "function" ? props.options(queryNorm) : props.options;
  return optionsRealized
    .filter(
      (e) =>
        e.label.toLowerCase().includes(queryNorm) || e.adtlSearchTerms?.some((t) => t.toLowerCase().includes(queryNorm))
    )
    .map((e) => {
      const propertyLabel = e.property?.duid ?? e.specialEntity;
      // TODO fix this hack
      const search = e.adtlSearchTerms ?? [];
      const propertySuffix = search.length > 3 && search[0] === "date" ? `/@${search[2]}` : "";
      return { ...e, id: `${propertyLabel}${propertySuffix}-${e.value}` };
    });
});

const logOpen = () => {
  backend.event.create(EventKind.USAGE_NLP_TYPEAHEAD_OPEN, { via: props.trigger });
};

const onQueryChange = (newQuery: string | null) => {
  query.value = newQuery;
};

const onSelectOption = (payload: {
  option: MenuOption;
  textNodeContainingQuery: TextNode | null;
  closeMenu: () => void;
  matchingString: string;
}) => {
  const { closeMenu, textNodeContainingQuery } = payload;
  const option = payload.option as unknown as TypeaheadOption;
  editor.update(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection) || option == null) {
      return;
    }

    const parentNode = textNodeContainingQuery?.getParent() ?? null;
    if (textNodeContainingQuery) {
      textNodeContainingQuery.remove();
    }

    emit("select", selection, option, parentNode);

    closeMenu();

    backend.event.create(EventKind.USAGE_NLP_TYPEAHEAD_ACCEPT, {
      via: props.trigger,
      propertyKind: option.property?.kind,
      value: option.value,
    });
  });
};
</script>

<template>
  <LexicalTypeaheadMenuPlugin
    :anchor-class-name="`${inModal ? 'z-[10020]' : 'z-50'} pointer-events-none`"
    :trigger-fn="usePrefixTypeaheadTriggerMatch(trigger)"
    :options="filteredOptions as any"
    :command-priority="COMMAND_PRIORITY_HIGH"
    @query-change="onQueryChange"
    @select-option="onSelectOption">
    <template
      #default="{ anchorElementRef, listItemProps: { selectOptionAndCleanUp, selectedIndex, setHighlightedIndex } }">
      <Dropdown
        v-if="anchorElementRef && filteredOptions.length !== 0"
        :reference-node="() => anchorElementRef"
        :placement="Placement.BOTTOM_LEFT"
        :distance="0"
        :skidding="0"
        :popper-class="LEXICAL_OUTSIDE_CLASS"
        shown
        no-auto-focus
        @apply-show="logOpen">
        <template #popper>
          <component
            :is="emojiGrid ? RecycleScroller : DynamicScroller"
            :items="filteredOptions"
            :min-item-size="20"
            :item-size="emojiGrid ? 30 : undefined"
            :grid-items="emojiGrid ? 12 : undefined"
            key-field="id"
            class="max-h-96 overflow-y-auto rounded border bg-lt border-md focus-ring-none"
            :item-class="emojiGrid ? 'flex items-center justify-center' : undefined"
            :style="{
              '--background': colors.bgStd,
              '--highlight': colors.borderLt,
              width: `${emojiGrid || large ? 383 : 224}px`,
              'max-width': `${emojiGrid || large ? 383 : 224}px`,
            }"
            :class="emojiGrid ? 'justify-items-center py-1 pl-2' : 'py-1'">
            <!-- TODO the casts several lines down are incorrect and dangerous -->
            <template #default="{ item, index, active }">
              <component
                :is="emojiGrid ? 'div' : DynamicScrollerItem"
                :item="item"
                :active="active"
                :size-dependencies="[]"
                :tabindex="-1"
                :aria-selected="selectedIndex === index"
                class="flex cursor-pointer text-md focus-ring-none focus:bg-md focus-visible:bg-md"
                :class="[
                  selectedIndex === index && 'bg-md',
                  emojiGrid ? 'items-center justify-center rounded font-emoji text-xl/6 icon-lg' : 'w-full text-sm',
                  item.strikeThrough && 'line-through',
                ]"
                @click="selectOptionAndCleanUp(item as unknown as MenuOption)"
                @keydown.enter="selectOptionAndCleanUp(item as unknown as MenuOption)"
                @mouseenter="() => setHighlightedIndex(index)"
                @focus="() => setHighlightedIndex(index)">
                <template v-if="emojiGrid">{{ item.label }}</template>
                <TaskOrDocPreviewTooltip
                  v-else-if="
                    item.specialEntity === TypeaheadSpecialEntity.TASK ||
                    item.specialEntity === TypeaheadSpecialEntity.DOC
                  "
                  :task="
                    item.specialEntity === TypeaheadSpecialEntity.TASK
                      ? dataStore.getTaskByDuid(item.value.toString())
                      : undefined
                  "
                  :doc="
                    item.specialEntity === TypeaheadSpecialEntity.DOC
                      ? dataStore.getDocByDuid(item.value.toString())
                      : undefined
                  "
                  block>
                  <DropdownMenuItemContent :title="item.label" :icon="item.icon" :icon-args="item.iconArgs" />
                </TaskOrDocPreviewTooltip>
                <TypeaheadSubmenu
                  v-else-if="!!item.options"
                  :item="item"
                  @select="(option) => selectOptionAndCleanUp(option as unknown as MenuOption)" />
                <DropdownMenuItemContent v-else :title="item.label" :icon="item.icon" :icon-args="item.iconArgs" />
              </component>
            </template>
          </component>
        </template>
      </Dropdown>
    </template>
  </LexicalTypeaheadMenuPlugin>
</template>

<style>
.v-popper--theme-dropdown .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-dropdown .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-dropdown .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
