<script setup lang="ts">
import { ref } from "vue";

import { useDataStore } from "~/stores";

import FormsContent from "./FormsContent.vue";
import FormsSidebar from "./FormsSidebar.vue";

const dataStore = useDataStore();

const content = ref<InstanceType<typeof FormsContent> | null>(null);

const focusNewForm = () => {
  content.value?.focusTitle();
};
</script>

<template>
  <div
    class="!m-0 flex size-full overflow-hidden !p-0"
    @click="() => content?.collapseFormFields()"
    @keydown.enter="() => content?.collapseFormFields()">
    <FormsSidebar v-if="dataStore.formList.length > 0" @create-form="focusNewForm" />
    <FormsContent ref="content" />
  </div>
</template>
