<script setup lang="ts">
import { createCSVImporter } from "csv-import-js";
import { computed, onMounted, ref } from "vue";

import { backendOld } from "~/api";
import { colorsByTheme } from "~/constants/style";
import { usePageStore } from "~/stores";

// TODO include sample CSV from https://docs.google.com/spreadsheets/d/1Xp2FVWsi3ItAj6vCsb-85QpZzyPTEjrQadcY6KSEQ7Y/edit and stored in static/data/import_template.csv

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const wrapper = ref<HTMLElement | null>(null);
onMounted(() => {
  if (!wrapper.value) {
    return;
  }

  createCSVImporter({
    isModal: false,
    domElement: wrapper.value,
    onComplete: (data) => backendOld.workspace.importV2(data),
    showDownloadTemplateButton: true,
    // Selects the first row as the header row
    skipHeaderRowSelection: true,
    customStyles: {
      "font-family": "Inter var",
      "border-radius": "4px",
    },
    // TODO Improve text as needed https://github.com/tableflowhq/csv-import/blob/main/src/i18n/es.ts
    customTranslations: {
      en: {
        "- Select one -": "Select one",
      },
    },
    template: {
      columns: [
        {
          name: "Title",
          key: "title",
          required: true,
          suggested_mappings: ["Summary"],
          description: "The title of the task",
        },
        {
          name: "ID",
          key: "id",
          description: "The ID of the task",
        },
        {
          name: "Parent ID",
          key: "parentId",
          suggested_mappings: ["Parent"],
          description: "ID of the parent task",
        },
        {
          name: "Dartboard",
          key: "dartboard",
          suggested_mappings: ["List", "Project"],
          description: "ID of the dartboard",
        },
        {
          name: "Status",
          key: "status",
          description: "The status of the task",
        },
        {
          name: "Assignees",
          key: "assigneeEmails",
          description: "Any users that are assigned to the task",
        },
        {
          name: "Description",
          key: "description",
          suggested_mappings: ["Details", "Notes"],
          description: "The description of the task",
        },
        {
          name: "Tags",
          key: "tags",
          suggested_mappings: ["Labels", "Categories"],
          description: "Any tags the task has",
        },
        {
          name: "Priority",
          key: "priority",
          suggested_mappings: ["Importance"],
          description: "The priority of the task",
        },
        {
          name: "Size",
          key: "size",
          suggested_mappings: ["Estimate", "Story points"],
          description: "The size of the task",
        },
        {
          name: "Start date",
          key: "startAt",
          suggested_mappings: ["Start at"],
          description: "The datetime that the task is due",
        },
        {
          name: "Due date",
          key: "dueAt",
          suggested_mappings: ["Due at", "Deadline"],
          description: "The datetime that the task is due",
        },
        {
          name: "Created",
          key: "createdAt",
          suggested_mappings: ["Created", "Created Date"],
          description: "The datetime that the task was created",
        },
        {
          name: "Last updated",
          key: "lastUpdatedAt",
          suggested_mappings: ["Updated at"],
          description: "The datetime that the task was last updated",
        },
      ],
    },
  });
});
</script>

<template>
  <div ref="wrapper" class="!m-0 size-full !p-0" />
</template>

<style>
/* https://github.com/tableflowhq/csv-import/blob/main/src/importer/style/themes/light.scss */
.csv-importer.CSVImporter-div {
  --color-background: v-bind("colors.bgStd");
  --color-background-modal: v-bind("colors.bgStd");

  --color-text: v-bind("colors.textLt");
  --color-primary: v-bind("colors.primary");
  --color-primary-hover: v-bind("colors.primaryHover");

  --color-input-background: v-bind("colors.bgLt");
  --color-input-background-soft: v-bind("colors.bgMd");
  --color-input-border: v-bind("colors.borderVlt");
  --color-input-placeholder: v-bind("colors.textMd");
  --color-input-text-disabled: v-bind("colors.textMd");

  --color-border: v-bind("colors.borderMd");

  --color-background-small-button-selected: v-bind("colors.bgLt");
  --color-background-small-button-hover: v-bind("colors.bgMd");
  --color-text-small-button: v-bind("colors.textLt");

  /* Icons */
  --color-icon: v-bind("colors.textVlt");

  height: 100%;
}
</style>
