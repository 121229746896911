import type { SerializedEditorState } from "lexical";
import moment from "moment";
import { reactive } from "vue";

import { StoreDataPreservationMode } from "~/shared/enums";
import type { Comment, CommentReaction, CommentUpdate, Task } from "~/shared/types";
import { isLexicalStateEmpty, makeDuid } from "~/utils/common";
import { makeStringComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  commentList: Comment[];
  getCommentByDuid: (duid: string) => Comment | undefined;
  getCommentsByTaskDuid: (taskDuid: string) => Comment[];
};

export type Actions = {
  /* Create a new comment. */
  createComment: (
    task: Task,
    text: SerializedEditorState,
    partialComment?: Partial<Comment>,
    options?: { awaitBackend?: boolean }
  ) => Promise<Comment | undefined>;
  /* Update a comment. */
  updateComment: (
    duid: string,
    text: SerializedEditorState,
    publish?: boolean,
    options?: { awaitBackend?: boolean }
  ) => Promise<Comment | undefined>;
  /** Delete a comment. */
  deleteComment: (comment: Comment, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Add a reaction to a comment. */
  createCommentReaction: (duid: string, emoji: string) => void;
  /** Remove a reaction from a comment. */
  deleteCommentReaction: (commentDuid: string, duid: string) => void;
  /** Sort comments.
   * @PRIVATE */
  $sortComments: () => void;
  /** Set comments internally.
   * @PRIVATE */
  $setComments: (comments: Comment[], preservationMode: StoreDataPreservationMode) => void;
  /** Create or update comment from WS.
   * @PRIVATE */
  $createOrUpdateComment: (comment: Comment) => void;
  /** Delete comment from WS.
   * @PRIVATE */
  $deleteComment: (comment: Comment) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  commentList() {
    return Array.from(this._duidsToComments.values());
  },
  getCommentByDuid() {
    return (duid) => this._duidsToComments.get(duid);
  },
  getCommentsByTaskDuid() {
    return (taskDuid) => this.commentList.filter((e) => e.taskDuid === taskDuid);
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createComment(task, text, partialComment, options = {}) {
    const { awaitBackend = false } = options;
    if (isLexicalStateEmpty(text)) {
      return undefined;
    }

    const userStore = this.$useUserStore();

    const now = new Date().toISOString();

    const comment: Comment = {
      duid: makeDuid(),
      createdAt: now,
      updatedAt: now,
      taskDuid: task.duid,
      rootDuid: null,
      authorDuid: userStore.duid,
      text,
      authoredByAi: false,
      publishedAt: null,
      reactions: [],
      isDraft: true,
      edited: false,
      ...partialComment,
    };

    this.$createOrUpdateComment(comment);

    const backendAction = this.$backend.comment.create(comment);
    if (awaitBackend) {
      await backendAction;
    }

    // TODO transactionify
    this.addSubscribers([task.duid], [userStore.duid], { noUndo: true });

    return comment;
  },
  async updateComment(duid, text, publish = false, options = {}) {
    const { awaitBackend = false } = options;
    const comment = this.getCommentByDuid(duid);
    if (!comment) {
      return undefined;
    }

    if (isLexicalStateEmpty(text)) {
      return undefined;
    }

    comment.text = text;
    const update: CommentUpdate = { duid, text };
    if (publish) {
      comment.publishedAt = new Date().toISOString();
      comment.isDraft = false;
      update.publishedAt = comment.publishedAt;
    } else if (!comment.isDraft) {
      comment.edited = true;
    }
    comment.updatedAt = new Date().toISOString();

    this.$sortComments();

    const backendAction = this.$backend.comment.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return comment;
  },
  async deleteComment(comment, options = {}) {
    const { awaitBackend = false } = options;

    this.$deleteComment(comment);

    const backendAction = this.$backend.comment.delete(comment.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  createCommentReaction(duid, emoji) {
    const comment = this.getCommentByDuid(duid);
    if (!comment) {
      return;
    }

    const userStore = this.$useUserStore();

    const reaction = reactive<CommentReaction>({
      duid: makeDuid(),
      createdAt: new Date().toISOString(),
      authorDuid: userStore.duid,
      emoji,
    });
    comment.reactions.push(reaction);

    this.$backend.commentReaction.create({ ...reaction, commentDuid: duid });

    // TODO transactionify
    this.addSubscribers([comment.taskDuid], [userStore.duid], { noUndo: true });
  },
  deleteCommentReaction(commentDuid, duid) {
    const comment = this.getCommentByDuid(commentDuid);
    if (!comment) {
      return;
    }

    comment.reactions = comment.reactions.filter((e) => e.duid !== duid);

    this.$backend.commentReaction.delete(duid);
  },
  $sortComments() {
    this._duidsToComments = new Map(
      [...this._duidsToComments].sort(makeStringComparator((e) => e[1].publishedAt ?? e[1].updatedAt))
    );
  },
  $setComments(comments, preservationMode) {
    const recentCutoff = moment().subtract(30, "second");

    const newComments: Comment[] = [];
    comments.forEach((newComment) => {
      const oldComment = this.getCommentByDuid(newComment.duid);
      if (!oldComment) {
        newComments.push(newComment);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.NONE) {
        Object.assign(oldComment, newComment);
        newComments.push(oldComment);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.ALL) {
        newComments.push(oldComment);
        return;
      }
      if (moment(oldComment.updatedAt).isAfter(recentCutoff)) {
        newComments.push(oldComment);
        return;
      }
      Object.assign(oldComment, newComment);
      newComments.push(oldComment);
    });
    const newCommentDuids = new Set(newComments.map((e) => e.duid));
    newComments.push(
      ...[...this._duidsToComments.values()].filter(
        (e) => !newCommentDuids.has(e.duid) && moment(e.createdAt).isAfter(recentCutoff)
      )
    );
    this._duidsToComments = new Map(newComments.map((e) => [e.duid, e]));

    this.$sortComments();
  },
  $createOrUpdateComment(comment) {
    const currentComment = this.getCommentByDuid(comment.duid);

    if (!currentComment) {
      this._duidsToComments.set(comment.duid, comment);
    } else {
      Object.assign(currentComment, comment);
    }

    this.$sortComments();
  },
  $deleteComment(comment) {
    this._duidsToComments.delete(comment.duid);
  },
};

export { actions, getters };
