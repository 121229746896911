<script setup lang="ts">
import { computed } from "vue";

import { getPropertyValueStr } from "~/common/properties";
import TaskStatisticsChip from "~/components/dumb/TaskStatisticsChip.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { COLUMN_WIDTH, useStatistics } from "~/components/visualization/board/common";
import HeaderTitle from "~/components/visualization/components/HeaderTitle.vue";
import { CollapseIcon, PlusIcon } from "~/icons";
import { CommandId, EditorMode, ViewKind } from "~/shared/enums";
import type { GroupByGroup } from "~/shared/types";
import { useAppStore, usePageStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";

const COLUMN_WIDTH_STYLE = {
  minWidth: COLUMN_WIDTH,
  maxWidth: COLUMN_WIDTH,
};

const props = defineProps<{
  group: GroupByGroup;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  collapse: [];
  createTask: [];
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);

const modeAlphaMod = computed(() => (pageStore.theme === "light" ? 0 : -0.05));
const backgroundColor = computed(() => fromHexToHexWithAlpha(props.group.colorHex, 0.2 + modeAlphaMod.value));
const hoverBgColor = computed(() => fromHexToHexWithAlpha(props.group.colorHex, 0.3 + modeAlphaMod.value));

const canAddTasks = computed(() => !pageStore.isPublicView && appStore.currentPage?.kind !== ViewKind.MY_TASKS);

const hideEmptyGroup = computed(
  () =>
    appStore.hideEmptyGroups &&
    appStore.groupByValueToTasksMap.get(getPropertyValueStr(props.group.value))?.length === 0
);

const statistics = useStatistics(
  () => appStore.groupByValueToTasksMap.get(getPropertyValueStr(props.group.value)) ?? []
);

const collapseColumn = () => {
  const id = isBoardMode.value ? props.group.id : getPropertyValueStr(props.group.value);
  appStore.setGroupCollapsed(id, true);
  emit("collapse");
};

const createTask = () => emit("createTask");
</script>

<template>
  <div
    v-if="!hideEmptyGroup"
    :data-group="group.id"
    class="flex w-full items-center justify-between gap-1"
    :class="isListMode ? 'h-[53px] rounded-t-lg bg-[--backgroundColor] px-5' : 'p-1'"
    :style="{
      '--backgroundColor': backgroundColor,
      '--hoverBgColor': hoverBgColor,
      ...(isBoardMode ? COLUMN_WIDTH_STYLE : {}),
    }">
    <div class="flex h-6 items-center gap-6 truncate" :class="isListMode && 'w-[300px]'">
      <div class="flex items-center gap-2 truncate">
        <component :is="group.icon" v-if="group.icon" class="shrink-0" v-bind="group.iconArgs" />
        <HeaderTitle :title="group.title" />
        <TaskStatisticsChip :statistics="statistics" :tooltip-show-delay="300" on-colored-background />
      </div>

      <Tooltip
        v-if="!pageStore.isPublicView"
        :text="`Collapse ${isListMode ? 'section' : 'column'}`"
        class="pointer-events-auto flex justify-end opacity-0 group-hover/section:opacity-100 print:hidden">
        <button
          type="button"
          class="flex items-center justify-center rounded p-0.5 focus-ring-std hover:bg-opposite/10"
          aria-label="Collapse section"
          @click="collapseColumn"
          @keydown.enter="collapseColumn">
          <CollapseIcon class="text-gray-900/30 icon-sm dark:text-white/20" />
        </button>
      </Tooltip>
    </div>
    <Tooltip v-if="canAddTasks" :command-id="CommandId.CREATE_TASK">
      <button
        type="button"
        class="flex items-center justify-center rounded p-0.5 opacity-0 focus-ring-std hover:bg-opposite/10 group-hover/section:opacity-100 print:hidden"
        aria-label="Create a task"
        @click="createTask"
        @keydown.enter="createTask">
        <PlusIcon class="text-gray-900/30 icon-sm dark:text-white/20" />
      </button>
    </Tooltip>
  </div>
</template>
