<script setup lang="ts">
import { computed } from "vue";

import { UnknownPageIcon } from "~/icons";
import ICON_MAP from "~/page-icons";

const props = defineProps<{
  name: string;
}>();

const icon = computed(() => ICON_MAP.get(props.name));
</script>

<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <svg v-if="icon" v-bind="icon.args" v-html="icon.content" />
  <UnknownPageIcon v-else />
</template>
