<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import { DartAi } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import { usePageStore } from "~/stores";
import { BLACK_HEX, getChipColorsHex, WHITE_HEX } from "~/utils/color";

const props = defineProps<{
  abrev: string;
  isAi?: boolean;
  isAgent?: boolean;
  showAiWorking?: boolean;
  circle?: boolean;
  colorHex?: string;
  hover?: boolean;
  imageUrl?: string | null;
  imgBorder?: boolean;
  isContrast?: boolean;
}>();

const pageStore = usePageStore();

const logoLoadFailed = ref(false);
const hasLogo = computed(() => !logoLoadFailed.value && props.imageUrl);

const hasColor = computed(() => !!props.colorHex);
const borderWidth = ref(1);
const wrapperStyle = computed(() => {
  const result = {
    borderWidth: `${borderWidth.value}px`,
  };
  if (!props.colorHex) {
    return result;
  }
  const color = getChipColorsHex(props.colorHex, pageStore.theme);
  const colorWithContrast = props.isContrast
    ? {
        backgroundColor: WHITE_HEX,
        color: BLACK_HEX,
        borderColor: BLACK_HEX,
      }
    : {
        backgroundColor: color.back,
        color: color.fore,
        borderColor: color.border,
      };
  return { ...result, ...colorWithContrast };
});

// Resize text to fit wrapper.
const text = ref<HTMLSpanElement | null>(null);
const wrapper = ref<HTMLDivElement | null>(null);
const resize = () => {
  if (!text.value) {
    return;
  }
  text.value.style.transform = "";
  nextTick(() => {
    let wrapperDiam = wrapper.value?.clientWidth;
    if (!wrapperDiam || !text.value) {
      return;
    }

    borderWidth.value = hasColor.value ? 1 : Math.max(Math.floor(wrapperDiam * 0.1), 1);
    wrapperDiam -= borderWidth.value * 2;

    const textBound = text.value.getBoundingClientRect();
    const textWidth = textBound.width;
    const textHeight = textBound.height;
    const textDiam = (textWidth ** 2 + textHeight ** 2) ** 0.5;
    const scale = wrapperDiam / textDiam;
    text.value.style.transform = `matrix(${scale},0,0,${scale},0,0)`;
  });
};

// Resize if wrapper or abbreviation change.
useResizeObserver(wrapper, resize);
watch(() => props.abrev, resize);
</script>

<template>
  <div class="group/avatar relative bg-std" :class="circle && !isAgent ? 'rounded-full' : 'rounded-[25%]'">
    <template v-if="hasLogo">
      <img
        :alt="abrev"
        :src="imageUrl ?? undefined"
        :class="[
          circle && !isAgent ? 'rounded-full' : 'rounded-[25%]',
          imgBorder && !isAgent && 'border border-md',
          imgBorder && isAgent && 'border-2',
          imgBorder && isAgent && showAiWorking && 'border-transparent',
          imgBorder && isAgent && !showAiWorking && 'border-recommendation-base',
        ]"
        class="pointer-events-none size-full select-none object-cover"
        @error="logoLoadFailed = true" />
      <Button
        v-if="isAgent && showAiWorking"
        :btn-style="ButtonStyle.SECONDARY_RECOMMENDATION"
        borderless
        working
        a11y-label="Avatar"
        class="!absolute !inset-0" />
      <div v-if="hover" class="absolute inset-0 hidden rounded-full bg-md/20 group-hover/avatar:flex" />
    </template>
    <div
      v-else-if="isAi"
      ref="wrapper"
      :class="[
        circle && !isAgent ? 'rounded-full' : 'rounded-[25%]',
        hover && !hasColor && 'hover:border-hvy',
        isAi && showAiWorking && 'animate-pulse',
      ]"
      class="flex size-full items-center justify-center border border-md"
      :style="wrapperStyle">
      <DartAi class="size-full" />
      <div
        v-if="hover && hasColor"
        class="absolute left-0 top-0 hidden size-full rounded-full bg-md/20 group-hover/avatar:flex" />
    </div>
    <div
      v-else
      ref="wrapper"
      :class="[circle && !isAgent ? 'rounded-full' : 'rounded-[25%]', hover && !hasColor && 'hover:border-hvy']"
      class="flex size-full items-center justify-center border border-md"
      :style="wrapperStyle">
      <span
        ref="text"
        :class="[hover && !hasColor && 'hover:text-vlt', !hasColor && 'text-vlt']"
        class="pointer-events-none select-none whitespace-nowrap text-sm font-normal">
        {{ abrev }}
      </span>
      <div
        v-if="hover && hasColor"
        class="absolute left-0 top-0 hidden size-full rounded-full bg-md/20 group-hover/avatar:flex" />
    </div>
  </div>
</template>
