<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { CommandId, DropdownMenuItemKind, FilterConnector } from "~/shared/enums";
import type { DropdownMenuSection, FilterDefinition } from "~/shared/types";

const props = defineProps<{
  definition: FilterDefinition;
  connector: FilterConnector;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  select: [value: FilterConnector];
}>();

const dropdownSections = computed<DropdownMenuSection[]>(() => [
  {
    title: "Connector",
    items: Object.entries(FilterConnector).map(({ 1: connector }) => ({
      title: connector,
      kind: DropdownMenuItemKind.BUTTON,
      iconArgs: { class: "icon-md" },
      disabled: connector === props.connector,
      hidden: connector === FilterConnector.AND && props.definition.config.singleton,
      onClick: () => emit("select", connector),
    })),
  },
]);
</script>

<template>
  <DropdownMenu :sections="dropdownSections" :distance="-1" cover height-block :width-pixels="80">
    <Tooltip :command-id="CommandId.CHANGE_FILTER_CONNECTOR" height-block>
      <button
        type="button"
        :disabled="disabled"
        class="dart-menu-connector flex h-full items-center border-x px-[3px] text-xs text-md border-md"
        :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer border-x border-md hover:bg-md']">
        {{ props.connector }}
      </button>
    </Tooltip>
  </DropdownMenu>
</template>
