<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import type { SerializedEditorState } from "lexical";
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";

import DraggableDivider from "~/components/dumb/DraggableDivider.vue";
import DropTarget from "~/components/dumb/DropTarget.vue";
import TaskDetailFooter from "~/components/task/taskDetail/TaskDetailFooter.vue";
import TaskDetailHeader from "~/components/task/taskDetail/TaskDetailHeader.vue";
import TaskDetailPeriphAndAct from "~/components/task/taskDetail/TaskDetailPeriphAndAct.vue";
import TaskDetailPropsAndDesc from "~/components/task/taskDetail/TaskDetailPropsAndDesc.vue";
import Title from "~/components/task/Title.vue";
import { TASK_DETAIL_TITLE_WRAPPER_ID } from "~/components/text/const";
import { DropFilesIllustration } from "~/illustrations";
import router from "~/router/router";
import { EditorMode, RelationshipKindKind, TaskDetailMode } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const header = ref<InstanceType<typeof TaskDetailHeader> | null>(null);
const propsAndDesc = ref<InstanceType<typeof TaskDetailPropsAndDesc> | null>(null);
const periphAndAct = ref<InstanceType<typeof TaskDetailPeriphAndAct> | null>(null);
const footer = ref<InstanceType<typeof TaskDetailFooter> | null>(null);

const wrapper = ref<HTMLElement | null>(null);

const id = computed(() => currentInstance?.uid);
const isFullscreen = computed(() => appStore.taskDetailMode === TaskDetailMode.FULLSCREEN);
const isOverlay = computed(() => appStore.taskDetailMode === TaskDetailMode.OVERLAY);

const onSavePaneSize = (width: number) => {
  userStore.setFullscreenRightbarWidthPx(width);
};

const canScrollToBottom = ref(false);
const recalculateCanScroll = () => {
  if (!wrapper.value) {
    return;
  }
  canScrollToBottom.value = wrapper.value.scrollTop + wrapper.value.clientHeight < wrapper.value.scrollHeight;
};
const scrollToBottom = () => {
  if (!wrapper.value) {
    return;
  }
  wrapper.value.scrollTop = wrapper.value.scrollHeight;
};
useResizeObserver(wrapper, recalculateCanScroll);

const addAttachments = (files: File[]) => periphAndAct.value?.addAttachments(files);

const createSubtask = () => periphAndAct.value?.createSubtask();

const deselectSubtasks = () => periphAndAct.value?.deselectSubtasks();

const editLastComment = () => periphAndAct.value?.editLastComment();

const generatePropertyRecommendations = () =>
  header.value?.recommendationButton &&
  propsAndDesc.value?.generatePropertyRecommendations(header.value?.recommendationButton);

const generateSubtaskRecommendations = () => periphAndAct.value?.generateSubtaskRecommendations();

const improveDescription = () => propsAndDesc.value?.improveDescription();

const jumpToDescription = () => propsAndDesc.value?.jumpToDescription();

const setDescription = (newDescription: SerializedEditorState) => propsAndDesc.value?.setDescription(newDescription);

const trimDescription = () => propsAndDesc.value?.trimDescription();

const jumpToComments = (selectLast: boolean = true) => {
  if (selectLast) {
    nextTick(() => {
      if (!appStore.taskOpenInDetail) {
        return;
      }
      const lastRealRoot = dataStore
        .getCommentsByTaskDuid(appStore.taskOpenInDetail.duid)
        .findLast((e) => !e.rootDuid && !e.isDraft);
      if (lastRealRoot) {
        router.push({ query: { c: lastRealRoot.duid } });
      }
    });
  }
  footer.value?.focusNewComment();
};

const openFilePicker = () => header.value?.openFilePicker();

const setRelationship = (kind: RelationshipKindKind, isForward?: boolean) =>
  periphAndAct.value?.setRelationship(kind, isForward);

const setSubtask = () => periphAndAct.value?.setSubtask();

const setDocRelationship = () => periphAndAct.value?.setDocRelationship();

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.taskDetail = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  if (id.value !== appStore.taskDetail?.id) {
    return;
  }
  appStore.taskDetail = null;
});

defineExpose({
  createSubtask,
  generatePropertyRecommendations,
  generateSubtaskRecommendations,
  id,
  jumpToComments,
  jumpToDescription,
  isDescriptionReady: computed(() => propsAndDesc.value?.isDescriptionReady ?? false),
  openFilePicker,
  scrollToBottom,
  setDescription,
  setDocRelationship,
  setRelationship,
  setSubtask,
  trimDescription,
});
</script>

<template>
  <div
    v-if="appStore.taskOpenInDetail"
    :id="appStore.taskOpenInDetail.inTrash ? 'trash-rightbar' : undefined"
    class="flex size-full justify-center bg-std"
    @click="deselectSubtasks"
    @keydown.enter="deselectSubtasks">
    <DropTarget :disabled="appStore.taskOpenInDetail.inTrash || pageStore.isPublicView" @drop="addAttachments">
      <template #main>
        <DraggableDivider
          v-if="isFullscreen || isOverlay"
          :pane-width-px="userStore.fullscreenRightbarWidthPx"
          :pane-min-px="400"
          :content-min-px="400"
          styles="flex"
          @save="onSavePaneSize">
          <template #default>
            <div class="flex size-full flex-col gap-3.5 pt-12">
              <div :id="TASK_DETAIL_TITLE_WRAPPER_ID" :class="isFullscreen ? 'px-[73px]' : 'px-[57px]'">
                <Title :task="appStore.taskOpenInDetail" :editor-mode="EditorMode.DETAIL" @enter="jumpToDescription" />
              </div>

              <TaskDetailPropsAndDesc
                ref="propsAndDesc"
                class="overflow-y-auto extra-overscroll print:overflow-hidden"
                :class="isFullscreen ? 'pl-16 pr-[49px]' : 'pl-12 pr-[33px]'"
                :task="appStore.taskOpenInDetail" />
            </div>
          </template>

          <template #pane>
            <div class="flex size-full flex-col items-center gap-3 border-l pt-1 border-lt">
              <TaskDetailHeader
                ref="header"
                :task="appStore.taskOpenInDetail"
                :generating-recommendations="
                  propsAndDesc?.generatingRecommendations || periphAndAct?.generatingRecommendations
                "
                class="px-1"
                @add-attachments="addAttachments"
                @create-subtask="createSubtask"
                @improve-description="improveDescription"
                @recommend-properties="generatePropertyRecommendations"
                @recommend-subtasks="generateSubtaskRecommendations"
                @set-doc-relationship="setDocRelationship"
                @set-relationship="setRelationship"
                @set-subtask="setSubtask" />
              <div class="flex size-full max-w-3xl flex-col justify-between overflow-hidden">
                <div
                  ref="wrapper"
                  class="flex grow flex-col overflow-y-auto print:overflow-hidden"
                  @scroll="recalculateCanScroll">
                  <TaskDetailPeriphAndAct ref="periphAndAct" :task="appStore.taskOpenInDetail" />
                </div>

                <TaskDetailFooter
                  ref="footer"
                  :task="appStore.taskOpenInDetail"
                  :can-scroll-to-bottom="canScrollToBottom"
                  @edit-last-comment="editLastComment"
                  @scroll-to-bottom="scrollToBottom" />
              </div>
            </div>
          </template>
        </DraggableDivider>
        <div v-else class="flex h-full flex-col items-center">
          <TaskDetailHeader
            ref="header"
            :task="appStore.taskOpenInDetail"
            :generating-recommendations="
              propsAndDesc?.generatingRecommendations || periphAndAct?.generatingRecommendations
            "
            class="w-full"
            @add-attachments="addAttachments"
            @create-subtask="createSubtask"
            @improve-description="improveDescription"
            @recommend-properties="generatePropertyRecommendations"
            @recommend-subtasks="generateSubtaskRecommendations"
            @set-doc-relationship="setDocRelationship"
            @set-relationship="setRelationship"
            @set-subtask="setSubtask" />

          <div class="flex w-full max-w-3xl grow flex-col overflow-y-hidden">
            <div :id="TASK_DETAIL_TITLE_WRAPPER_ID" class="mt-2 w-full px-2.5">
              <Title :task="appStore.taskOpenInDetail" :editor-mode="EditorMode.DETAIL" @enter="jumpToDescription" />
            </div>

            <div
              ref="wrapper"
              class="relative mt-3.5 flex grow flex-col gap-6 overflow-y-auto print:overflow-hidden"
              @scroll="recalculateCanScroll">
              <TaskDetailPropsAndDesc ref="propsAndDesc" :task="appStore.taskOpenInDetail" />

              <TaskDetailPeriphAndAct ref="periphAndAct" :task="appStore.taskOpenInDetail" />
            </div>

            <TaskDetailFooter
              ref="footer"
              :task="appStore.taskOpenInDetail"
              :can-scroll-to-bottom="canScrollToBottom"
              @edit-last-comment="editLastComment"
              @scroll-to-bottom="scrollToBottom" />
          </div>
        </div>
      </template>
      <template #dragOverlay>
        <div class="flex w-full flex-col items-center justify-evenly">
          <div class="flex flex-col items-center">
            <DropFilesIllustration />
            <span class="mt-6 text-center text-2xl text-md">Drop to attach</span>
          </div>
          <div />
        </div>
      </template>
    </DropTarget>
  </div>
</template>

<style>
#trash-rightbar .ag-root .ag-cell,
#trash-rightbar .group\/text-editor,
#trash-rightbar .group\/title,
#trash-rightbar .dart-large-chips {
  @apply !pointer-events-none !cursor-default !select-none;
}
</style>
