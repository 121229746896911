/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `or` - OR
 * * `and` - AND
 */
export enum FilterConnector {
  OR = 'or',
  AND = 'and',
}
