<script setup lang="ts">
import { type Component, computed } from "vue";

import PageIcon from "~/components/dumb/PageIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ExpandIcon } from "~/icons";
import { IconSize } from "~/shared/enums";
import type { Page } from "~/shared/types";

const props = defineProps<{
  title: string;
  page?: Page;
  icon?: Component;
  iconArgs?: object;
  strikeThrough?: boolean;
  showExpandIcon?: boolean;
  isNotificationMode?: boolean;
}>();

const emit = defineEmits<{
  expand: [];
  click: [event: MouseEvent];
  keydown: [event: KeyboardEvent];
}>();

const truncatedTitle = computed(() => (props.title.length > 30 ? `${props.title.slice(0, 30)}…` : props.title));
const titleSplit = computed(() => props.title.split("\n"));
</script>

<template>
  <button
    type="button"
    class="inline-flex min-h-6 cursor-pointer items-center gap-1.5 rounded px-1 decoration-gray-700 text-md dark:decoration-zinc-300"
    :class="[isNotificationMode ? 'hover:bg-opposite/10' : 'bg-lt hover:bg-md']"
    @click="emit('click', $event)"
    @keydown="emit('keydown', $event)">
    <div class="flex items-center gap-1.5">
      <PageIcon v-if="page" :page="page" :size="IconSize.S" />
      <component :is="icon" v-else-if="icon" v-bind="iconArgs" class="icon-sm" />
      <span class="text-left" :class="strikeThrough && 'line-through'">
        <template v-if="isNotificationMode">
          <template v-for="(line, index) in titleSplit" :key="index">
            {{ line }}
            <br v-if="index < titleSplit.length - 1" />
          </template>
        </template>
        <template v-else>
          {{ truncatedTitle }}
        </template>
      </span>
    </div>
    <Tooltip v-if="showExpandIcon" text="Show">
      <span class="rounded p-0.5 hover:bg-hvy" @click.stop="emit('expand')" @keydown.stop.enter="emit('expand')">
        <ExpandIcon class="icon-sm" />
      </span>
    </Tooltip>
  </button>
</template>
