import { DecoratorNode, type LexicalNode, type SerializedLexicalNode } from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import { RecommendationAction, type RecommendationKind } from "~/shared/enums";

import { RECOMMENDATION_KINDS_THAT_REPLACE } from "../const";
import RecommendationButtons from "./RecommendationButtons.vue";

type SerializedRecommendationButtonsNode = SerializedLexicalNode & {
  kind: RecommendationKind;
  recommendationDuid: string;
};

export class RecommendationButtonsNode extends DecoratorNode<Component> {
  __kind: RecommendationKind;

  __recommendationDuid: string;

  static getType(): string {
    return "recommendation-buttons";
  }

  static clone(node: RecommendationButtonsNode): RecommendationButtonsNode {
    return new RecommendationButtonsNode(node.__kind, node.__key);
  }

  constructor(kind: RecommendationKind, recommendationDuid: string, key?: string) {
    super(key);
    this.__kind = kind;
    this.__recommendationDuid = recommendationDuid;
  }

  getKind(): RecommendationKind {
    return this.__kind;
  }

  getAction(): RecommendationAction {
    return RECOMMENDATION_KINDS_THAT_REPLACE.has(this.getKind())
      ? RecommendationAction.REPLACE
      : RecommendationAction.KEEP;
  }

  getRecommendationDuid(): string {
    return this.__recommendationDuid;
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.classList.add("contents");
    return div;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedRecommendationButtonsNode): LexicalNode {
    return new RecommendationButtonsNode(serializedNode.kind, serializedNode.recommendationDuid);
  }

  exportJSON(): SerializedRecommendationButtonsNode {
    return {
      type: RecommendationButtonsNode.getType(),
      kind: this.getKind(),
      recommendationDuid: this.getRecommendationDuid(),
      version: 1,
    };
  }

  decorate(): Component {
    return h(RecommendationButtons, {
      nodeKey: this.getKey(),
      action: this.getAction(),
      recommendationDuid: this.getRecommendationDuid(),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): false {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }
}

export function $createRecommendationButtonsNode(
  kind: RecommendationKind,
  recommendationDuid: string
): RecommendationButtonsNode {
  return new RecommendationButtonsNode(kind, recommendationDuid);
}

export function $isRecommendationButtonsNode(node?: LexicalNode): node is RecommendationButtonsNode {
  return node instanceof RecommendationButtonsNode;
}
