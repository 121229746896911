<script setup lang="ts">
import { $dfs, $findMatchingParent, $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  DELETE_CHARACTER_COMMAND,
  ElementNode,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ENTER_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  TextNode,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import { EVENT_INSERT_TOGGLE_COMMAND, EVENT_TOGGLE_TOGGLE_COMMAND } from "../const";
import { $isToggleContentNode } from "../nodes/ToggleContentNode";
import { $isToggleDetailsNode, ToggleDetailsNode } from "../nodes/ToggleDetailsNode";
import { $isToggleTitleNode, ToggleTitleNode } from "../nodes/ToggleTitleNode";
import { $isToggleWrapperNode, createToggleBlock, ToggleWrapperNode } from "../nodes/ToggleWrapperNode";
import { $isBlock, isEmpty } from "../utils";

const editor = useLexicalComposer();

const handleInsertToggleCommand = () => {
  editor.update(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    const anchor = selection.anchor.getNode();
    const parentNode = $findMatchingParent(anchor, $isBlock);
    if (!parentNode || $isToggleTitleNode(parentNode.getParent())) {
      $setSelection(selection);
      return;
    }

    const [node, title] = createToggleBlock();
    title.append(...parentNode.getChildren());
    parentNode.replace(node);
    title.selectEnd();
  });

  return true;
};

const handleToggleToggleCommand = (nodeKey: string) => {
  editor.update(() => {
    const node = $getNodeByKey(nodeKey);
    if (!node) {
      return;
    }

    const wrapper = $getNearestNodeOfType(node, ToggleWrapperNode);
    if (!wrapper) {
      return;
    }
    wrapper.toggleOpen();
  });
  return true;
};

const outdentMaybe = (anchor: TextNode | ElementNode, wrapper: ToggleWrapperNode, event?: KeyboardEvent) => {
  const details = $getNearestNodeOfType(anchor, ToggleDetailsNode);
  if (!details) {
    return false;
  }

  const lastChild = details.getLastChild();
  if (!lastChild || anchor !== lastChild || lastChild.getTextContentSize() > 0) {
    return false;
  }

  event?.preventDefault();

  if (details.getChildren().length > 1) {
    lastChild.remove();
  }

  const paragraph = $createParagraphNode();
  wrapper.insertAfter(paragraph);
  paragraph.selectStart();
  return true;
};

const handleEnter = (event: KeyboardEvent) => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return false;
  }

  const anchor = selection.anchor.getNode();

  const wrapper = $getNearestNodeOfType(anchor, ToggleWrapperNode);
  if (!wrapper) {
    return false;
  }

  // If at the end of details, insert a new paragraph after
  if (outdentMaybe(anchor, wrapper, event)) {
    return true;
  }

  const title = $getNearestNodeOfType(anchor, ToggleTitleNode);
  if (!title) {
    return false;
  }

  // Toggle the container being open if the user presses alt + enter
  if (event.altKey) {
    event.preventDefault();
    wrapper.toggleOpen();
    return true;
  }

  const isOpen = wrapper.getOpen();

  // If the section is closed, insert a new toggle section
  if (!isOpen) {
    event.preventDefault();
    const [newWrapper, newTitle] = createToggleBlock(false);
    wrapper.insertAfter(newWrapper);
    newTitle.select();
    return true;
  }

  // If open, go to the content, potentially inserting a new node there
  const content = wrapper.getLastChild();
  if (!content || !$isToggleContentNode(content)) {
    return false;
  }
  const details = content.getLastChild();
  if (!details || !$isToggleDetailsNode(details)) {
    return false;
  }
  const detailsChild = details.getFirstChild();
  if (!detailsChild) {
    return false;
  }

  event.preventDefault();

  if (detailsChild.getTextContentSize() > 0) {
    const newParagraph = $createParagraphNode();
    detailsChild.insertBefore(newParagraph);
    newParagraph.selectStart();
  } else {
    detailsChild.selectStart();
  }
  return true;
};

const handleOutdent = () => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return false;
  }

  const anchor = selection.anchor.getNode();

  const wrapper = $getNearestNodeOfType(anchor, ToggleWrapperNode);
  if (!wrapper) {
    return false;
  }

  return outdentMaybe(anchor, wrapper);
};

const handleArrowDown = () => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return false;
  }

  const anchor = selection.anchor.getNode();

  const wrapper = $getNearestNodeOfType(anchor, ToggleWrapperNode);
  if (!wrapper || wrapper.getNextSibling() !== null) {
    return false;
  }

  const details = $getNearestNodeOfType(anchor, ToggleDetailsNode);
  if (!details) {
    return false;
  }

  const lastChild = details.getLastChild();
  if (!lastChild || (anchor !== lastChild && anchor.getParent() !== lastChild)) {
    return false;
  }

  const paragraph = $createParagraphNode();
  wrapper.insertAfter(paragraph);
  paragraph.selectStart();
  return true;
};

const handleDelete = () => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || !selection.isCollapsed() || selection.anchor.offset !== 0) {
    return false;
  }

  const anchorNode = selection.anchor.getNode();
  const anchorKey = anchorNode.getKey();

  const wrapper = $getNearestNodeOfType(anchorNode, ToggleWrapperNode);
  if (!wrapper) {
    return false;
  }

  const content = wrapper.getLastChild();
  if (!content || !$isToggleContentNode(content)) {
    return false;
  }

  const title = content.getFirstChild();
  const details = content.getLastChild();
  if (!title || !$isToggleTitleNode(title) || !details || !$isToggleDetailsNode(details)) {
    return false;
  }

  const titleChild = title.getFirstChild();
  const detailsChild = details.getFirstChild();
  if (!titleChild || !detailsChild) {
    return false;
  }

  editor.update(() => {
    // If the selection is in the content node and it's at the start of the first child, select the title and return
    const detailsChildKey = detailsChild.getKey();
    if (anchorKey === detailsChildKey || anchorNode.getParent()?.getKey() === detailsChildKey) {
      title.select();
      return;
    }

    if (anchorKey === titleChild.getKey()) {
      // Otherwise, delete the whole thing
      let addedContent = false;
      if (title.getTextContentSize() > 0) {
        wrapper.insertBefore(titleChild);
        addedContent = true;
      }
      if (wrapper.getHasContent()) {
        details.getChildren().forEach((e) => wrapper.insertBefore(e));
        addedContent = true;
      }
      if (!addedContent) {
        wrapper.insertBefore($createParagraphNode());
      }
      wrapper.remove();
    }
  });

  return false;
};

const handleDetailsUpdates = () =>
  editor.update(() => {
    $dfs()
      .map(({ node }) => node)
      .filter($isToggleDetailsNode)
      .forEach((node) => {
        const wrapper = node.getParent()?.getParent();
        if (!wrapper || !$isToggleWrapperNode(wrapper)) {
          return;
        }

        const firstChild = node.getFirstChild();
        wrapper.setHasContent(node.getChildrenSize() > 1 || (!!firstChild && !isEmpty(firstChild)));
      });
  });

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(EVENT_INSERT_TOGGLE_COMMAND, handleInsertToggleCommand, COMMAND_PRIORITY_LOW),
    editor.registerCommand(EVENT_TOGGLE_TOGGLE_COMMAND, handleToggleToggleCommand, COMMAND_PRIORITY_LOW),
    editor.registerCommand(KEY_ENTER_COMMAND, handleEnter, COMMAND_PRIORITY_LOW),
    editor.registerCommand(OUTDENT_CONTENT_COMMAND, handleOutdent, COMMAND_PRIORITY_LOW),
    editor.registerCommand(KEY_ARROW_DOWN_COMMAND, handleArrowDown, COMMAND_PRIORITY_LOW),
    editor.registerCommand(DELETE_CHARACTER_COMMAND, handleDelete, COMMAND_PRIORITY_LOW),
    editor.registerUpdateListener(handleDetailsUpdates)
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
