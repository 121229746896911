<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-hidden="true">
    <path
      d="M 3.9999983,1.3333312 V 22.666673 M 20.000002,1.3333312 V 22.666673 M 6.6666653,1.3333312 H 1.3333312 M 20.000002,12.000002 H 3.9999983 m 2.666667,10.666671 H 1.3333312 m 21.3333368,0 H 17.333331 M 22.666668,1.3333312 h -5.333337"
      stroke="currentColor"
      stroke-width="2.66666"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
