/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `System Default` - SYSTEM_DEFAULT
 * * `Light` - LIGHT
 * * `Dark` - DARK
 */
export enum Theme {
  SYSTEM_DEFAULT = 'System Default',
  LIGHT = 'Light',
  DARK = 'Dark',
}
