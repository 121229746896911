import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { StatusKind } from "~/shared/enums";
import type { Property, Status, StatusUpdate, TaskKind } from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";
import { statusComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  statusList: Status[];
  getStatusList: (property: Property, taskKinds?: TaskKind[]) => Status[];
  getStatusByDuid: (duid: string) => Status | undefined;
  getStatusByTitle: (title: string, property: Property) => Status | undefined;
  getStatusesByKinds: (kinds: StatusKind[], property: Property) => Status[];
  defaultUnstartedStatus: (property: Property) => Status;
  defaultDefaultUnstartedStatus: Status;
  defaultDefaultFinishedStatus: Status;
};

export type Actions = {
  /* Create a new status. */
  createStatus: (
    kind: StatusKind,
    property: Property,
    order: string,
    partialStatus?: Partial<Status>,
    options?: { awaitBackend?: boolean }
  ) => Promise<Status>;
  /* Update an existing status. */
  updateStatus: (update: StatusUpdate, options?: { awaitBackend?: boolean }) => Promise<Status | undefined>;
  /** Delete a status. */
  deleteStatus: (status: Status, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort statuses.
   * @PRIVATE */
  $sortStatuses: () => void;
  /** Set statuses internally.
   * @PRIVATE */
  $setStatuses: (statuses: Status[]) => void;
  /** Create or update status from WS.
   * @PRIVATE */
  $createOrUpdateStatus: (status: Status) => void;
  /** Delete status from WS.
   * @PRIVATE */
  $deleteStatus: (status: Status) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  statusList() {
    return Array.from(this._duidsToStatuses.values());
  },
  getStatusList() {
    return (property, taskKinds) => {
      const hiddenStatusDuids = new Set(taskKinds ? taskKinds.map((e) => e.hiddenStatusDuids).flat() : []);
      return this.statusList.filter((e) => e.propertyDuid === property.duid && !hiddenStatusDuids.has(e.duid));
    };
  },
  getStatusByDuid() {
    return (duid) => this._duidsToStatuses.get(duid);
  },
  getStatusByTitle() {
    return (title, property) => this.getStatusList(property).find((e) => title === e.title);
  },
  getStatusesByKinds() {
    return (kinds, property) => this.getStatusList(property).filter((e) => kinds.includes(e.kind));
  },
  defaultUnstartedStatus() {
    return (property) => {
      const status = this.getStatusList(property).filter((e) => e.locked && e.kind === StatusKind.UNSTARTED);
      if (!status) {
        throw new Error(`Expected exactly one default unstarted status for property ${property.title}`);
      }
      return status[0];
    };
  },
  defaultDefaultUnstartedStatus() {
    return this.defaultUnstartedStatus(this.defaultStatusProperty);
  },
  defaultDefaultFinishedStatus() {
    const status = this.getStatusList(this.defaultStatusProperty).filter(
      (e) => e.locked && e.kind === StatusKind.FINISHED
    );
    if (!status) {
      throw new Error(`Expected exactly one default finished status for property ${this.defaultStatusProperty.title}`);
    }
    return status[0];
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createStatus(kind, property, order, partialStatus, options = {}) {
    const { awaitBackend = false } = options;
    const status: Status = {
      duid: makeDuid(),
      propertyDuid: property.duid,
      kind,
      locked: false,
      order,
      title: "",
      colorHex: makeRandomColorHex(),
      description: "",
      ...partialStatus,
    };

    this.$createOrUpdateStatus(status);

    const backendAction = this.$backend.status.create(status);
    if (awaitBackend) {
      await backendAction;
    }
    return status;
  },
  async updateStatus(update, options = {}) {
    const { awaitBackend = false } = options;
    const status = this.getStatusByDuid(update.duid);
    if (!status) {
      return undefined;
    }

    Object.assign(status, update);
    this.$sortStatuses();

    const backendAction = this.$backend.status.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return status;
  },
  async deleteStatus(status, options = {}) {
    const { awaitBackend = false } = options;

    const tasks = this.getTasksByStatusDuid(status.duid);
    const newStatusDuid = COMPLETED_STATUS_KINDS.has(status.kind)
      ? this.defaultDefaultFinishedStatus.duid
      : this.defaultDefaultUnstartedStatus.duid;
    this.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        statusDuid: newStatusDuid,
      })),
      { noUndo: true, noCelebrate: true }
    );

    const layoutUpdates = this.$updateLayoutsRemoveEntityDuidOrFiltersNoBackend([status.duid], [status.propertyDuid]);
    this.$deleteStatus(status);

    const backendAction = this.$backend.status.delete(status.duid, layoutUpdates);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortStatuses() {
    this._duidsToStatuses = new Map([...this._duidsToStatuses.entries()].sort((a, b) => statusComparator(a[1], b[1])));
  },
  $setStatuses(statuses) {
    this._duidsToStatuses = new Map(statuses.map((e) => [e.duid, e]));
    this.$sortStatuses();
  },
  $createOrUpdateStatus(status) {
    const currentStatus = this.getStatusByDuid(status.duid);

    if (!currentStatus) {
      this._duidsToStatuses.set(status.duid, status);
    } else {
      Object.assign(currentStatus, status);
    }

    this.$sortStatuses();
  },
  $deleteStatus(status) {
    this._duidsToStatuses.delete(status.duid);
  },
};

export { actions, getters };
