<script lang="ts" setup>
import { computed, nextTick } from "vue";

import actions from "~/actions";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import TaskMergeDropdown from "~/components/dumb/TaskMergeDropdown.vue";
import { RELATIONSHIP_DROPDOWN_KIND_TO_DROPDOWN_OPTION_WITH_SUBTASKS } from "~/constants/relationship";
import { AttachmentFieldIcon, DocIcon, LinkFieldIcon, NotionIcon, RelationshipsIcon } from "~/icons";
import {
  CommandId,
  DropdownMenuItemKind,
  Placement,
  RelationshipDropdownKind,
  RelationshipKindKind,
} from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore } from "~/stores";
import { openFilePicker } from "~/utils/common";

const props = defineProps<{
  targetTask: Task;
  selectedTasks: Task[];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const isMultipleTasks = computed(() => props.selectedTasks.length > 1);

const notionDescriptionEnabled = computed(
  () => tenantStore.notionIntegration?.enabled && !props.targetTask?.notionDocument
);

const setRelationship = (kind: RelationshipKindKind, isForward?: boolean) => {
  appStore.setTaskDetailOpen(true);

  nextTick(() => {
    appStore.taskDetail?.setRelationship(kind, isForward);
  });
};

const setSubtask = () => {
  appStore.setTaskDetailOpen(true);

  nextTick(() => {
    appStore.taskDetail?.setSubtask();
  });
};

const handleOptionClick = (
  relationshipDropdownKind: RelationshipDropdownKind,
  relationshipKindKind: RelationshipKindKind,
  isForward?: boolean
) => {
  switch (relationshipDropdownKind) {
    case RelationshipDropdownKind.CREATE_SUBTASK: {
      actions.visualization.createSubtask();
      break;
    }
    case RelationshipDropdownKind.SET_SUBTASK: {
      setSubtask();
      break;
    }
    default: {
      setRelationship(relationshipKindKind, isForward);
      break;
    }
  }
};

const sections = computed(() => {
  const taskItems = [...RELATIONSHIP_DROPDOWN_KIND_TO_DROPDOWN_OPTION_WITH_SUBTASKS.entries()].map(([key, value]) => {
    if (key === RelationshipDropdownKind.MERGED_INTO) {
      return {
        title: "Merge into other task",
        kind: DropdownMenuItemKind.COMPONENT,
        component: TaskMergeDropdown,
        relationshipKindKind: RelationshipKindKind.DUPLICATES,
        componentArgs: {
          targetTask: props.targetTask,
          selectedTasks: props.selectedTasks,
        },
      };
    }
    return {
      ...value,
      onClick: () => handleOptionClick(key, value.relationshipKindKind, value.isForward),
    };
  });

  const docAndMoreItems = [
    {
      title: "Connect a doc",
      kind: DropdownMenuItemKind.BUTTON,
      icon: DocIcon,
      disabled: isMultipleTasks.value,
      onClick: () => {
        appStore.setTaskDetailOpen(true);
        nextTick(() => {
          appStore.taskDetail?.setDocRelationship();
        });
      },
    },
    {
      title: "Connect a Notion page",
      kind: DropdownMenuItemKind.BUTTON,
      icon: NotionIcon,
      disabled: isMultipleTasks.value,
      hidden: !notionDescriptionEnabled.value,
      onClick: () => {
        appStore.setTaskInNotionLinkModalDuid(props.targetTask);
      },
    },
    {
      title: "Add attachment",
      disabled: isMultipleTasks.value,
      kind: DropdownMenuItemKind.BUTTON,
      icon: AttachmentFieldIcon,
      commandId: CommandId.ADD_ATTACHMENT,
      onClick: () => {
        openFilePicker((fileList: File[]) => dataStore.addAttachments(props.targetTask.duid, fileList));
      },
    },
    {
      title: "Add link",
      disabled: isMultipleTasks.value,
      kind: DropdownMenuItemKind.BUTTON,
      icon: LinkFieldIcon,
      commandId: CommandId.ADD_LINK,
      onClick: () => {
        appStore.setLinkOpenInModal({ taskDuid: props.targetTask.duid });
      },
    },
  ];

  return [
    { title: "Task", items: taskItems },
    { title: "Doc and more", items: docAndMoreItems },
  ];
});
</script>

<template>
  <DropdownMenu :sections="sections" :placement="Placement.RIGHT_TOP" show-on-hover>
    <DropdownMenuItemContent
      :icon="RelationshipsIcon"
      title="Connect a task, doc, and more"
      is-submenu
      :disabled="isMultipleTasks" />
  </DropdownMenu>
</template>
