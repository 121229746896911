import type { StoreDefinition } from "pinia";
import { acceptHMRUpdate } from "pinia";

/* Enable store hot-reload. */
const HotModuleReload = (store: StoreDefinition) => {
  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
  }
};

export default HotModuleReload;
