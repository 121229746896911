<script setup lang="ts">
import { computed } from "vue";

import Modal from "~/components/dumb/Modal.vue";
import TaskDetail from "~/components/task/TaskDetail.vue";
import { ModalWidth, TaskDetailMode } from "~/shared/enums";
import { useAppStore } from "~/stores";

import { TASK_DETAIL_TELEPORT_KEY } from "../text/const";

const appStore = useAppStore();

const isOverlay = computed(() => appStore.taskDetailMode === TaskDetailMode.OVERLAY);

const close = () => {
  appStore.setTaskDetailOpen(false);
};
</script>

<template>
  <Modal
    :entity="isOverlay"
    title="Task detail"
    hide-title
    :width="ModalWidth.XXXL"
    custom-styles="h-full w-full overflow-hidden !p-0"
    close-icon-styles="hidden"
    h-full
    overflow-clip
    no-focus
    @close="close">
    <TaskDetail :data-toolbar="TASK_DETAIL_TELEPORT_KEY" />
  </Modal>
</template>
