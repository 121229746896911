<script setup lang="ts">
import type { EditorState, SerializedEditorState } from "lexical";
import {
  LexicalAutoLinkPlugin,
  LexicalCheckListPlugin,
  LexicalLinkPlugin,
  LexicalListPlugin,
  LexicalMarkdownShortcutPlugin,
  LexicalTabIndentationPlugin,
} from "lexical-vue";
import { computed, ref } from "vue";

import type { Property } from "~/shared/types";

import { FORM_TELEPORT_KEY, URL_MATCHERS } from "./const";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin.vue";
import CodeSelectorPlugin from "./plugins/CodeSelectorPlugin.vue";
import FloatingToolbar from "./plugins/FloatingToolbar.vue";
import ManageTextFormatPlugin from "./plugins/ManageTextFormatPlugin.vue";
import OnChangePlugin from "./plugins/OnChangePlugin.vue";
import PasteUrlPlugin from "./plugins/PasteUrlPlugin.vue";
import PlaceholderPlugin from "./plugins/PlaceholderPlugin.vue";
import ShortcutsPlugin from "./plugins/ShortcutsPlugin.vue";
import TextEditor from "./TextEditor.vue";
import { TRANSFORMERS } from "./transformers";
import { GENERIC_EDITOR_NODES } from "./utils";

const props = defineProps<{
  property: Property;
  value?: SerializedEditorState;
}>();

const emit = defineEmits<{
  update: [text: SerializedEditorState];
}>();

const textEditor = ref<InstanceType<typeof TextEditor> | null>(null);

const namespace = computed(() => `default-${props.property.duid}`);

const onEditorChange = (newEditorState: EditorState) => {
  emit("update", newEditorState.toJSON());
};

const focus = () => {
  textEditor.value?.focus();
};

defineExpose({
  focus,
});
</script>

<template>
  <TextEditor
    ref="textEditor"
    :namespace="namespace"
    :nodes="GENERIC_EDITOR_NODES"
    :initial-state="value"
    placeholder="Add a description"
    :left-margin-px="8"
    high-contrast-border
    :min-height-px="100">
    <template #default>
      <!-- lexical-vue plugins -->
      <LexicalAutoLinkPlugin :matchers="URL_MATCHERS" />
      <LexicalCheckListPlugin />
      <LexicalLinkPlugin />
      <LexicalListPlugin />
      <LexicalMarkdownShortcutPlugin :transformers="TRANSFORMERS" />
      <LexicalTabIndentationPlugin />
      <!-- custom plugins -->
      <CodeHighlightPlugin />
      <CodeSelectorPlugin editable />
      <FloatingToolbar hide-ai-action-button :teleport-key="FORM_TELEPORT_KEY" />
      <ManageTextFormatPlugin />
      <OnChangePlugin :has-focus="!!textEditor?.hasFocus" @change="onEditorChange" />
      <PasteUrlPlugin />
      <PlaceholderPlugin no-ai />
      <ShortcutsPlugin />
    </template>
  </TextEditor>
</template>
