import { UNASSIGNED_PSEUDO_ASSIGNEE_KEY } from "~/components/visualization/constants";
import { NONNULL_PRIORITY_OPTIONS } from "~/constants/priority";
import { TSHIRT_SIZE_ORDER_FOR_NLP, TSHIRT_SIZE_TO_LABEL, TSHIRT_SIZE_TO_NUMBER_SIZE } from "~/constants/size";
import { DART_AI_PSEUDO_USER_KEY } from "~/constants/user";
import { PropertyKind } from "~/shared/enums";
import type { PropertyConfigSmartMatch, SmartMatchToken } from "~/shared/types";
import type { DataStore } from "~/stores/DataStore";
import { escapeRegex, getPageDisplayName } from "~/utils/common";
import { dateMatcher, parseDates } from "~/utils/date";

/* Initialize stores */
let dataStore: DataStore;

export const init = (newDataStore: DataStore) => {
  dataStore = newDataStore;
};

const normalize = (title: string) => escapeRegex(title.toLowerCase());

export const KIND_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_KIND> = (property) => {
  const tokens: SmartMatchToken[] = [];

  dataStore.taskKindList.forEach((kind) => {
    tokens.push({
      match: `${kind} type`,
      propertyDuid: property.duid,
      value: kind.duid,
    });
  });

  return { isMultiple: false, getOptions: () => tokens };
};

export const DARTBOARD_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_DARTBOARD> = (property) => {
  const tokens: SmartMatchToken[] = [];

  dataStore.getDartboardList({ excludeFinished: true }).forEach((dartboard) => {
    // e.g. in active
    const name = normalize(getPageDisplayName(dartboard, dataStore.getSpaceByDuid));
    if (!name) {
      return;
    }
    tokens.push({
      match: `in ${name}`,
      propertyDuid: property.duid,
      value: dartboard.duid,
    });
  });

  return { isMultiple: false, getOptions: () => tokens };
};

export const STATUS_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_STATUS | PropertyKind.STATUS> = (
  property
) => {
  const tokens: SmartMatchToken[] = [];

  dataStore.getStatusList(property).forEach((status) => {
    const title = normalize(status.title);
    if (!title) {
      return;
    }
    tokens.push({
      match: `${title} ${normalize(property.title)}`,
      propertyDuid: property.duid,
      value: status.duid,
    });
  });

  return { isMultiple: false, getOptions: () => tokens };
};

export const USER_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_ASSIGNEES | PropertyKind.USER> = (
  property
) => {
  const tokens: SmartMatchToken[] = [];

  dataStore.getUserList().forEach((user) => {
    // e.g. for Test User, tester@itsdart.com
    tokens.push({
      match: `(for )?(${normalize(user.email)}${user.name ? `|${normalize(user.name)}` : ""})`,
      propertyDuid: property.duid,
      value: user.duid,
    });
  });

  tokens.push({
    match: "dart ai",
    propertyDuid: property.duid,
    value: DART_AI_PSEUDO_USER_KEY,
  });
  tokens.push({
    match: /\(?unassigned\)?/.source,
    propertyDuid: property.duid,
    value: UNASSIGNED_PSEUDO_ASSIGNEE_KEY,
  });

  return { isMultiple: property.adtl.isMultiple, getOptions: () => tokens };
};

export const DATES_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_DATES | PropertyKind.DATES> = (
  property
) => ({
  isMultiple: false,
  getOptions: (query) => [
    {
      match: dateMatcher.source,
      propertyDuid: property.duid,
      value: parseDates(query.toString()),
    },
  ],
});

export const PRIORITY_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_PRIORITY> = (property) => {
  const tokens: SmartMatchToken[] = [];

  NONNULL_PRIORITY_OPTIONS.forEach((priority, index) => {
    // e.g. critical priority
    tokens.push({
      match: `p${index}|${priority} priority`,
      propertyDuid: property.duid,
      value: priority,
    });
  });

  return { isMultiple: false, getOptions: () => tokens };
};

export const SIZE_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_SIZE> = (property) => {
  const tokens: SmartMatchToken[] = [];

  TSHIRT_SIZE_ORDER_FOR_NLP.forEach((size) => {
    const strSize = TSHIRT_SIZE_TO_LABEL.get(size);
    const numberSize = TSHIRT_SIZE_TO_NUMBER_SIZE.get(size);
    if (!strSize || !numberSize) {
      return;
    }
    // e.g. small size
    tokens.push({
      match: `(${size}|${strSize.replace("_", " ")}) size`,
      propertyDuid: property.duid,
      value: numberSize,
    });
  });

  return { isMultiple: false, getOptions: () => tokens };
};

export const SELECT_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.SELECT> = (property) => {
  const tokens: SmartMatchToken[] = [];

  dataStore.getOptionList(property).forEach((option) => {
    const title = normalize(option.title);
    if (!title) {
      return;
    }
    tokens.push({
      match: `${title} ${normalize(property.title)}`,
      propertyDuid: property.duid,
      value: option.duid,
    });
  });

  return { isMultiple: false, getOptions: () => tokens };
};

export const MULTISELECT_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.DEFAULT_TAGS | PropertyKind.MULTISELECT> = (
  property
) => {
  const tokens: SmartMatchToken[] = [];

  dataStore.getOptionList(property).forEach((option) => {
    const title = normalize(option.title);
    if (!title) {
      return;
    }
    tokens.push({
      match: `${title} ${normalize(property.title)}`,
      propertyDuid: property.duid,
      value: option.duid,
    });
  });

  return { isMultiple: true, getOptions: () => tokens };
};

export const CHECKBOX_SMART_MATCH: PropertyConfigSmartMatch<PropertyKind.CHECKBOX> = (property) => ({
  isMultiple: false,
  getOptions: () => [
    {
      match: `(t|true|checked) ${normalize(property.title)}`,
      propertyDuid: property.duid,
      value: true,
    },
    {
      match: `(f|false|unchecked) ${normalize(property.title)}`,
      propertyDuid: property.duid,
      value: false,
    },
  ],
});
