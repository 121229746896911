<script setup lang="ts">
import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import { CopyIcon, PlusIcon, RefreshIcon } from "~/icons";
import { ButtonStyle, DialogMode } from "~/shared/enums";
import { useUserStore } from "~/stores";

const ROTATE_AUTH_TOKEN_DIALOG_DESCRIPTION =
  "Rotating the authentication token will invalidate the old token. This can't be undone. Are you sure you want to proceed?";

const userStore = useUserStore();

const copyAuthToken = () => {
  if (userStore.authToken === null) {
    return;
  }
  copyAndNotify("Authentication token", userStore.authToken);
};

const rotateAuthToken = async () => {
  const { data } = await backendOld.profile.rotateAuthToken();
  userStore.authToken = data.authToken;
  copyAuthToken();
};
</script>

<template>
  <div class="h-full overflow-auto">
    <div class="flex gap-1 text-lt">
      <template v-if="userStore.authToken">
        <pre
          class="grow truncate rounded px-3 py-[7px] text-xs bg-lt"
          @click="copyAuthToken"
          @keydown.enter="copyAuthToken"
          >{{ userStore.authToken }}</pre
        >
        <Tooltip text="Copy authentication token">
          <Button
            :btn-style="ButtonStyle.CHIP"
            :icon="CopyIcon"
            a11y-label="Copy authentication token"
            @click="copyAuthToken" />
        </Tooltip>
        <ConfirmationDialog
          :mode="DialogMode.DELETE"
          title="Rotate authentication token"
          :description="ROTATE_AUTH_TOKEN_DIALOG_DESCRIPTION"
          confirm-text="Proceed"
          cancel-text="Cancel"
          :icon="RefreshIcon"
          @confirm="rotateAuthToken">
          <Tooltip text="Rotate authentication token">
            <Button :btn-style="ButtonStyle.CHIP" :icon="RefreshIcon" a11y-label="Rotate authentication token" />
          </Tooltip>
        </ConfirmationDialog>
      </template>
      <Tooltip v-else text="Create authentication token">
        <Button
          :btn-style="ButtonStyle.CHIP"
          :icon="PlusIcon"
          a11y-label="Create authentication token"
          @click="rotateAuthToken" />
      </Tooltip>
    </div>
  </div>
</template>
