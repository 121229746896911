/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Disabled` - DISABLED
 * * `Enabled` - ENABLED
 */
export enum SamlConfigTenantExtensionStatus {
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
}
