/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Personal` - PERSONAL
 * * `Premium` - PREMIUM
 */
export enum Subscription {
  PERSONAL = 'Personal',
  PREMIUM = 'Premium',
}
