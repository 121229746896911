import equal from "deep-equal";

import { backendOld } from "~/api";
import { notify } from "~/components/notifications";
import { getEmojiWithSkinTone, normalizeEmoji, removeSkinTone } from "~/constants/iconAndEmoji";
import { EventActor, IconKind } from "~/shared/enums";
import type { Task, TaskField, TaskFieldValue, TaskUpdate } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";

const FIELD_TO_NOTIFY_MESSAGE_MAP = new Map<TaskField, string>([
  ["assigneeDuids", "assignee"],
  ["assignedToAi", "assignee"],
  ["tagDuids", "tag"],
  ["dueAt", "due date"],
]);

export const generateFieldRecommendation = async (
  tasks: Task[],
  field: TaskField,
  defaultValue: TaskFieldValue
): Promise<[TaskUpdate[], string[]]> => {
  // TODO convert this endpoint to bulk
  const responses = await Promise.all(
    tasks.map(({ duid }) => backendOld.recommendations.getTaskProperties(duid, [field]))
  );
  const items = responses.map((res) => res.data.items[0]);
  const updates = items
    .filter((item) => item && !equal(item.value, defaultValue), { strict: true })
    .map(({ duid, value, field: taskRecField }) => ({
      duid,
      [taskRecField]: value,
    }));
  const recommendationDuids = items.map(({ recommendationDuid }) => recommendationDuid);

  if (updates.length === 0) {
    notify({
      message: `${EventActor.DART_AI} has no ${FIELD_TO_NOTIFY_MESSAGE_MAP.get(field) ?? field} recommendation${
        tasks.length === 1 ? " for this task" : "s for these tasks"
      }. Add more details and try again.`,
    });
  }
  return [updates, recommendationDuids];
};

export const getRecommendationDefaultValuesForProperties = () => {
  const dataStore = useDataStore();
  const userStore = useUserStore();
  return new Map<string, [TaskField, TaskFieldValue]>([
    ["assignedToAi", ["assignedToAi", false]],
    [dataStore.defaultAssigneesProperty.duid, ["assigneeDuids", [userStore.duid]]],
    [dataStore.defaultTagsProperty.duid, ["tagDuids", []]],
    [dataStore.defaultPriorityProperty.duid, ["priority", null]],
    [dataStore.defaultSizeProperty.duid, ["size", null]],
    [dataStore.defaultDatesProperty.duid, ["dueAt", null]],
  ]);
};

export const getEmojiRecommendation = async (
  duid: string,
  newTitle?: string | null
): Promise<{ iconKind: IconKind.EMOJI; iconNameOrEmoji: string } | undefined> => {
  const appStore = useAppStore();
  const pageStore = usePageStore();

  if (!pageStore.isOnline) {
    return undefined;
  }

  const res = await backendOld.recommendations.getPageEmoji(duid, newTitle);

  const emoji = normalizeEmoji(removeSkinTone(res.data.item.value));
  appStore.addToRecentEmojiStack(emoji);
  return { iconKind: IconKind.EMOJI, iconNameOrEmoji: getEmojiWithSkinTone(emoji, appStore.skinTone) };
};
