<script setup lang="ts">
import { $getRoot } from "lexical";
import { useLexicalComposer } from "lexical-vue";

const editor = useLexicalComposer();

const getTextContent = () => editor.getEditorState().read(() => $getRoot().getTextContent());

defineExpose({
  getTextContent,
});
</script>

<template>
  <slot />
</template>
