<script setup lang="ts">
import { useLexicalComposer } from "lexical-vue";
import { watch } from "vue";

const props = defineProps<{
  editable: boolean;
}>();

const editor = useLexicalComposer();

watch(
  () => props.editable,
  (newEditable) => {
    editor.setEditable(newEditable);
    if (newEditable) {
      editor.focus();
    }
  }
);
</script>

<template>
  <slot />
</template>
