<script setup lang="ts">
import Modal from "~/components/dumb/Modal.vue";
import PermissionManager from "~/components/dumb/PermissionManager.vue";
import { ModalWidth } from "~/shared/enums";
import { useAppStore, useDataStore } from "~/stores";
import { getPageDisplayName } from "~/utils/common";

const appStore = useAppStore();
const dataStore = useDataStore();
</script>

<template>
  <Modal
    :entity="appStore.pageInPermissionsModal"
    :title="(page) => `Manage sharing for ${getPageDisplayName(page ?? undefined, dataStore.getSpaceByDuid)}`"
    :width="ModalWidth.L"
    @close="appStore.setPageInPermissionsModal(null)">
    <template #default="{ entity: page }">
      <PermissionManager v-if="page" :page="page" />
    </template>
  </Modal>
</template>
