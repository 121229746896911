<script setup lang="ts">
import { type Component, computed, getCurrentInstance, onMounted, ref } from "vue";

import { colorsByTheme } from "~/constants/style";
import { ButtonSize, ButtonStyle, IconSize } from "~/shared/enums";
import { usePageStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";
import { getComponentAncestors } from "~/utils/common";

const STANDARD_FOCUS = "focus-ring-std";
const STYLE_TO_DEFAULT_SIZE_MAP = new Map([
  [ButtonStyle.PRIMARY, ButtonSize.LARGE],
  [ButtonStyle.SECONDARY, ButtonSize.SMALL],
  [ButtonStyle.DANGER, ButtonSize.LARGE],
  [ButtonStyle.SECONDARY_DANGER, ButtonSize.SMALL],
  [ButtonStyle.RECOMMENDATION, ButtonSize.SMALL],
  [ButtonStyle.SECONDARY_RECOMMENDATION, ButtonSize.SMALL],
  [ButtonStyle.CHIP, ButtonSize.CHIP],
]);

const props = defineProps<{
  btnStyle?: ButtonStyle;
  text?: string;
  icon?: Component;
  iconAfter?: Component;
  disabled?: boolean;
  working?: boolean;
  block?: boolean;
  heightBlock?: boolean;
  size?: ButtonSize;
  textStyle?: string;
  iconSize?: IconSize;
  iconArgs?: object;
  iconAfterArgs?: object;
  isContrast?: boolean;
  borderless?: boolean;
  disableHover?: boolean;
  breakWords?: boolean;
  a11yLabel?: string;
}>();

const emit = defineEmits<{
  click: [event: Event];
}>();

const pageStore = usePageStore();
const buttonRef = ref<HTMLButtonElement | null>(null);

const disabledOrWorking = computed(() => props.disabled || props.working);

const a11yLabelNorm = computed(() => props.a11yLabel || props.text);

onMounted(() => {
  if (props.text || props.a11yLabel) {
    return;
  }
  // eslint-disable-next-line no-console
  console.error("Button must have either text or a11yLabel prop", getComponentAncestors(getCurrentInstance()));
});

const onClick = (event: Event) => {
  if (disabledOrWorking.value) {
    return;
  }

  emit("click", event);
};

const baseStyles = computed(() => {
  switch (props.btnStyle) {
    case ButtonStyle.PRIMARY: {
      return "bg-primary-base text-oncolor focus-visible:ring-primary-base";
    }
    case ButtonStyle.SECONDARY: {
      return "text-lt";
    }
    case ButtonStyle.DANGER: {
      return "bg-danger-base focus-visible:ring-danger-base text-oncolor";
    }
    case ButtonStyle.SECONDARY_DANGER: {
      return "text-danger-base focus-visible:ring-danger-base";
    }
    case ButtonStyle.RECOMMENDATION: {
      return "bg-recommendation-base focus-visible:ring-recommendation-base text-oncolor";
    }
    case ButtonStyle.SECONDARY_RECOMMENDATION: {
      return "text-recommendation-base focus-visible:ring-recommendation-base";
    }
    case ButtonStyle.CHIP: {
      return "focus-ring-none";
    }
    default: {
      return "";
    }
  }
});

const sizeStyles = computed(() => {
  const res: string[] = [];
  const size =
    props.size ??
    (props.btnStyle !== undefined ? STYLE_TO_DEFAULT_SIZE_MAP.get(props.btnStyle) : ButtonSize.SMALL) ??
    ButtonSize.SMALL;

  if (!props.text) {
    switch (size) {
      case ButtonSize.CHIP:
      case ButtonSize.SMALL: {
        res.push("p-1");
        break;
      }
      case ButtonSize.LARGE: {
        res.push("p-[7px]");
        break;
      }
      default: {
        break;
      }
    }
  } else {
    switch (size) {
      case ButtonSize.CHIP: {
        res.push("p-1");
        break;
      }
      case ButtonSize.SMALL: {
        res.push("px-3 py-1");
        break;
      }
      case ButtonSize.LARGE: {
        res.push("px-4 py-2");
        break;
      }
      default: {
        break;
      }
    }
  }
  switch (size) {
    case ButtonSize.CHIP: {
      res.push("gap-1");
      break;
    }
    case ButtonSize.SMALL:
    case ButtonSize.LARGE: {
      res.push("gap-2");
      break;
    }
    default: {
      break;
    }
  }

  if (props.block) {
    res.push("w-full");
  }
  if (props.heightBlock) {
    res.push("h-full");
  }
  return res.join(" ");
});

const iconSizeStyle = computed(() => {
  const res: string[] = [];
  const size = props.iconSize ?? IconSize.M;
  switch (size) {
    case IconSize.XS: {
      res.push("icon-xs");
      break;
    }
    case IconSize.S: {
      res.push("icon-sm");
      break;
    }
    case IconSize.M: {
      res.push("icon-md");
      break;
    }
    case IconSize.L: {
      res.push("icon-lg");
      break;
    }
    case IconSize.XL: {
      res.push("icon-xl");
      break;
    }
    default: {
      break;
    }
  }
  return res.join(" ");
});

const contrastHoverStyle = computed(() => (props.isContrast ? "hover:bg-md" : "hover:bg-lt"));

const hoverStyles = computed(() => {
  if (disabledOrWorking.value || props.disableHover) {
    return "";
  }

  switch (props.btnStyle) {
    case ButtonStyle.PRIMARY: {
      return "hover-bg-primary";
    }
    case ButtonStyle.SECONDARY:
    case ButtonStyle.SECONDARY_RECOMMENDATION: {
      return contrastHoverStyle.value;
    }
    case ButtonStyle.DANGER: {
      return "hover-bg-danger";
    }
    case ButtonStyle.SECONDARY_DANGER: {
      return "hover:bg-danger-base hover:text-oncolor";
    }
    case ButtonStyle.RECOMMENDATION: {
      return "hover-bg-recommendation";
    }
    case ButtonStyle.CHIP: {
      return "hover:bg-opposite/10";
    }
    default: {
      return "";
    }
  }
});

const borderStyles = computed(() => {
  if (props.borderless || props.working) {
    return "border border-transparent";
  }

  switch (props.btnStyle) {
    case ButtonStyle.PRIMARY: {
      return "border border-transparent";
    }
    case ButtonStyle.SECONDARY: {
      return "border border-hvy";
    }
    case ButtonStyle.DANGER: {
      return "border border-transparent";
    }
    case ButtonStyle.SECONDARY_DANGER: {
      return "border border-danger-base";
    }
    case ButtonStyle.RECOMMENDATION: {
      return "border border-transparent";
    }
    case ButtonStyle.SECONDARY_RECOMMENDATION: {
      return "border border-recommendation-base";
    }
    case ButtonStyle.CHIP: {
      return "border border-oncolor";
    }
    default: {
      return "";
    }
  }
});

const baseTextStyles = computed(() => {
  if (props.textStyle) {
    return "";
  }
  if (props.btnStyle === ButtonStyle.SECONDARY) {
    return "text-md";
  }
  return "";
});

const disabledStyles = computed(() => {
  if (props.disabled) {
    return "opacity-50 cursor-not-allowed";
  }
  return "";
});

const workingOuterStyles = computed(() => {
  if (props.working) {
    return "cursor-not-allowed bg-opacity-50";
  }
  return "";
});

const workingInnerStyles = computed(() => {
  if (props.working) {
    return "opacity-50";
  }
  return "";
});

const workingBorderColorBase = computed(() => {
  const colors = colorsByTheme[pageStore.theme];
  switch (props.btnStyle) {
    case ButtonStyle.PRIMARY: {
      return colors.primaryHighContrast;
    }
    case ButtonStyle.DANGER: {
      return colors.dangerHighContrast;
    }
    case ButtonStyle.SECONDARY_DANGER: {
      return colors.danger;
    }
    case ButtonStyle.RECOMMENDATION: {
      return colors.recommendationHighContrast;
    }
    case ButtonStyle.SECONDARY_RECOMMENDATION: {
      return colors.recommendation;
    }
    default: {
      return colors.opposite;
    }
  }
});
const workingBorderColorPrimary = computed(() => fromHexToHexWithAlpha(workingBorderColorBase.value, 0.8));
const workingBorderColorSecondary = computed(() => fromHexToHexWithAlpha(workingBorderColorBase.value, 0.1));
</script>

<template>
  <button
    ref="buttonRef"
    type="button"
    class="relative flex items-center justify-center rounded text-sm font-normal"
    :class="[
      baseStyles,
      sizeStyles,
      hoverStyles,
      borderStyles,
      disabledStyles,
      workingOuterStyles,
      btnStyle !== ButtonStyle.CHIP && STANDARD_FOCUS,
    ]"
    :disabled="disabledOrWorking"
    :aria-label="a11yLabelNorm"
    @click="(event) => onClick(event)"
    @keydown.enter="(event) => onClick(event)">
    <component :is="icon" v-if="icon" class="shrink-0" :class="[iconSizeStyle, workingInnerStyles]" v-bind="iconArgs" />
    <div
      v-if="text"
      :title="text"
      class="max-w-full select-none"
      :class="[baseTextStyles, textStyle, workingInnerStyles, breakWords ? 'hyphens-auto break-words' : 'truncate']">
      {{ text }}
    </div>
    <component
      :is="iconAfter"
      v-if="iconAfter"
      class="shrink-0"
      :class="[iconSizeStyle, workingInnerStyles]"
      v-bind="iconAfterArgs" />
    <div v-if="working" class="dart-working-button-border absolute -inset-px rounded p-[3px]" />
  </button>
</template>

<style scoped>
@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

.dart-working-button-border {
  background: conic-gradient(
    from var(--angle),
    v-bind("workingBorderColorSecondary"),
    v-bind("workingBorderColorPrimary") 0.3turn,
    v-bind("workingBorderColorPrimary") 0.6turn,
    v-bind("workingBorderColorSecondary") 0.5turn
  );
  animation: dart-working-button-border-rotate 2000ms linear infinite;
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

@keyframes dart-working-button-border-rotate {
  100% {
    --angle: 450deg;
  }
}
</style>
