<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";
import { computed } from "vue";

import DraggableDivider from "~/components/dumb/DraggableDivider.vue";
import TaskDetail from "~/components/task/TaskDetail.vue";
import { TASK_DETAIL_TELEPORT_KEY } from "~/components/text/const";
import { TaskDetailMode, ViewKind } from "~/shared/enums";
import { useAppStore, usePageStore, useUserStore } from "~/stores";

defineProps<{
  hideContent?: boolean;
}>();

const emit = defineEmits<{
  afterResize: [];
}>();

const appStore = useAppStore();
const userStore = useUserStore();
const pageStore = usePageStore();

const { width: screenWidth } = useWindowSize();

const isFullscreen = computed(() => appStore.taskDetailMode === TaskDetailMode.FULLSCREEN);
const isOverlay = computed(() => appStore.taskDetailMode === TaskDetailMode.OVERLAY);

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);

const showPaneOnly = computed(() => appStore.taskDetailOpen && !isFullscreen.value && screenWidth.value <= 768);
const onSavePaneSize = (width: number) => {
  if (pageStore.isPublicView) {
    return;
  }

  userStore.setRightbarWidthPx(width);
};

const onAfterPaneResize = () => {
  emit("afterResize");
};

const onEdge = (left: boolean) => {
  if (left) {
    appStore.setTaskDetailOpen(true, TaskDetailMode.FULLSCREEN);
    return;
  }
  appStore.setTaskDetailOpen(false);
};
</script>

<template>
  <main :id="isTrash ? 'trash-page' : undefined" class="relative flex grow overflow-hidden">
    <DraggableDivider
      v-show="!showPaneOnly && !hideContent"
      :hide-pane="!appStore.taskDetailOpen || showPaneOnly || isFullscreen || isOverlay"
      :pane-width-px="userStore.rightbarWidthPx"
      :pane-min-px="400"
      :content-min-px="400"
      styles="h-full"
      @after-resize="onAfterPaneResize"
      @save="onSavePaneSize"
      @edge="onEdge">
      <template #default>
        <slot name="default" />
      </template>
      <template #pane>
        <TaskDetail class="border-l border-md" :data-toolbar="isOverlay ? undefined : TASK_DETAIL_TELEPORT_KEY" />
      </template>
    </DraggableDivider>
    <TaskDetail
      v-if="showPaneOnly || isFullscreen"
      :class="isFullscreen && 'absolute inset-0 h-full w-full'"
      :data-toolbar="isOverlay ? undefined : TASK_DETAIL_TELEPORT_KEY" />
    <slot name="extra" />
  </main>
</template>

<style>
#trash-page .ag-root .ag-header,
#trash-page .ag-root .ag-cell {
  @apply !pointer-events-none !cursor-default !select-none;
}

#trash-page .ag-root .ag-cell {
  @apply !cursor-pointer;
}

#trash-page .dart-open-rightbar,
#trash-page .dart-subtask-expand {
  @apply !pointer-events-auto;
}
</style>
