<script setup lang="ts">
import { ref, watch } from "vue";

import { getProperty } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Modal from "~/components/dumb/Modal.vue";
import { ArrowRightIcon } from "~/icons";
import { ButtonSize, ButtonStyle, DropdownMenuItemKind, ModalWidth } from "~/shared/enums";
import type { RemapModalConfig, RemapModalConfigRemappedItem } from "~/shared/types";
import { useAppStore } from "~/stores";

const appStore = useAppStore();

const remappedValues = ref<RemapModalConfigRemappedItem[]>([]);

const getItems = (remapModal: RemapModalConfig | null) =>
  (remapModal?.items ?? []).map(({ propertyDuid, oldOption, options }, i) => {
    const property = getProperty(propertyDuid);
    const selectedValue = remappedValues.value[i].newValue;
    const selectedValueOption = options.find((e) => e.value === selectedValue);

    return {
      duid: propertyDuid,
      title: property?.title ?? "Unknown",
      oldOption,
      selectedValue: {
        value: selectedValue,
        title: selectedValueOption?.title,
        icon: selectedValueOption?.icon,
        iconArgs: selectedValueOption?.iconArgs,
      },
      sections: [
        {
          title: "New value",
          items: options.map((e) => ({
            title: e.title,
            kind: DropdownMenuItemKind.BUTTON,
            icon: e.icon,
            iconArgs: e.iconArgs,
            disabled: e.value === selectedValue,
            onClick: () => {
              remappedValues.value.splice(i, 1, { propertyDuid, oldValue: oldOption.value, newValue: e.value });
            },
          })),
        },
      ],
    };
  });

const onCancel = () => {
  if (!appStore.remapModal) {
    return;
  }

  appStore.remapModal.cancel();
  appStore.setRemapModal(null);
};

const onContinue = () => {
  if (!appStore.remapModal) {
    return;
  }

  appStore.remapModal.remap(remappedValues.value);
  appStore.setRemapModal(null);
};

watch(
  () => appStore.remapModal,
  (remapModal) => {
    if (!remapModal) {
      return;
    }
    remappedValues.value = remapModal.items.map((item) => ({
      propertyDuid: item.propertyDuid,
      oldValue: item.oldOption.value,
      newValue: item.proposedValue,
    }));
  }
);
</script>

<template>
  <Modal
    :entity="appStore.remapModal"
    title="Reassign properties"
    description="This change has incompatible properties, please reassign them"
    :width="ModalWidth.S"
    @close="onCancel">
    <template #default="{ entity: remapModal }">
      <div class="mt-4 flex flex-col gap-4">
        <div v-for="item in getItems(remapModal)" :key="item.duid" class="flex w-full items-center gap-3">
          <!-- Old value -->
          <div class="flex flex-1 flex-col gap-1">
            <span class="text-sm text-lt">Old {{ item.title.toLowerCase() }}</span>
            <div class="flex items-center gap-2 py-1">
              <component :is="item.oldOption.icon" v-bind="item.oldOption.iconArgs" class="icon-md" />
              <span class="text-sm text-md">{{ item.oldOption.title }}</span>
            </div>
          </div>
          <ArrowRightIcon class="mt-5 text-vlt icon-md" />
          <!-- New value -->
          <div class="flex flex-1 flex-col gap-1">
            <span class="text-sm text-lt">New {{ item.title.toLowerCase() }}</span>
            <DropdownMenu :sections="item.sections" :width-pixels="160" cover block>
              <button type="button" class="flex w-full items-center gap-2 rounded border px-2 py-1 border-md">
                <component :is="item.selectedValue.icon" v-bind="item.selectedValue.iconArgs" class="icon-md" />
                <span class="text-sm text-md">{{ item.selectedValue.title }}</span>
              </button>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <div class="mt-4 flex items-center gap-2">
        <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" :size="ButtonSize.SMALL" @click="onCancel" />
        <Button :btn-style="ButtonStyle.PRIMARY" text="Continue" :size="ButtonSize.SMALL" @click="onContinue" />
      </div>
    </template>
  </Modal>
</template>
