<script setup lang="ts">
import equal from "deep-equal";
import moment from "moment";
import { computed, onUnmounted, ref } from "vue";

import actions from "~/actions";
import { getPropertyConfig, getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import DatePicker from "~/components/dumb/DatePicker.vue";
import DatesChip from "~/components/dumb/DatesChip.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { notify } from "~/components/notifications";
import { DatesFieldIcon, DueDateFieldIcon } from "~/icons";
import { CommandId, EditorMode, NotificationType, Placement } from "~/shared/enums";
import type { DateRange, PropertyAnyDates, Recurrence, Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { normalizeDate, normalizeDateToDate } from "~/utils/date";
import { generateFieldRecommendation } from "~/utils/recommendation";
import { getFullDate, getNextRecursNextAt } from "~/utils/time";

const props = defineProps<{
  property: PropertyAnyDates;
  tasks: Task[];
  showIcon?: boolean;
  editorMode: EditorMode;
  value?: DateRange;
  defaultValue?: DateRange;
}>();

const emit = defineEmits<{
  update: [range?: DateRange];
  reject: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const feedbackTooltipNode = ref<HTMLDivElement | null>(null);

const propertyConfig = computed(() => getPropertyConfig(props.property.kind));
const isDefault = computed(() => propertyConfig.value.isDefault);
const isReadOnly = computed(() => propertyConfig.value.readOnly);

const loading = computed(() => isDefault.value && appStore.isLoadingTasksProperty(props.tasks, props.property.duid));

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isListMiniRoadmapMode = computed(() => props.editorMode === EditorMode.LIST_MINI_ROADMAP);
const isListMode = computed(() => props.editorMode === EditorMode.LIST || isListMiniRoadmapMode.value);
const isTaskDetailMode = computed(
  () => props.editorMode === EditorMode.DETAIL || props.editorMode === EditorMode.DETAIL_RECOMMENDATION
);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const isRange = computed(
  () =>
    ("adtl" in props.property && props.property.adtl.isRange) ||
    propertyConfig.value.alwaysShowForLayouts?.includes(appStore.layoutKind)
);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);
const selectedTaskDuids = computed(() => selectedTasks.value.map((e) => e.duid));

const selectedDateRange = computed(() => {
  if (isFormMode.value) {
    return {
      start: props.value?.[0] ?? null,
      end: props.value?.[1] ?? null,
    };
  }

  const firstTaskValue = getPropertyValueFromTask(props.property, props.tasks[0]);
  if (typeof firstTaskValue === "string") {
    if (props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskValue)) {
      return {
        start: null,
        end: firstTaskValue,
      };
    }
    return { start: null, end: null };
  }

  if (
    (!isRange.value ||
      props.tasks.every((task) => getPropertyValueFromTask(props.property, task)[0] === firstTaskValue[0])) &&
    props.tasks.every((task) => getPropertyValueFromTask(props.property, task)[1] === firstTaskValue[1])
  ) {
    return {
      start: firstTaskValue[0],
      end: firstTaskValue[1],
    };
  }
  return { start: null, end: null };
});
const selectedRecurrence = computed(() => {
  if (isFormMode.value) {
    return { recurrence: null, recursNextAt: null };
  }

  if (
    isDefault.value &&
    props.tasks.every((task) => equal(task.recurrence, props.tasks[0].recurrence, { strict: true })) &&
    props.tasks.every((task) => task.recursNextAt === props.tasks[0].recursNextAt)
  ) {
    return {
      recurrence: props.tasks[0].recurrence,
      recursNextAt: props.tasks[0].recursNextAt,
    };
  }
  return { recurrence: null, recursNextAt: null };
});
const selectedRemindAt = computed(() => {
  if (!isDefault.value || isReadOnly.value || isFormMode.value) {
    return null;
  }
  return props.tasks.every((task) => task.remindAt === props.tasks[0].remindAt) ? props.tasks[0].remindAt : null;
});
const selectedStatusDuid = computed(() => {
  if (!isDefault.value || isReadOnly.value || isFormMode.value) {
    return null;
  }
  return props.tasks.every((task) => task.statusDuid === props.tasks[0].statusDuid) ? props.tasks[0].statusDuid : null;
});

const hasValue = computed(
  () =>
    (isRange.value && selectedDateRange.value.start) ||
    selectedDateRange.value.end ||
    selectedRecurrence.value.recurrence
);

const updateRecurrence = (recurrence: Recurrence | null) => {
  const recursNextAt = recurrence
    ? normalizeDate(getNextRecursNextAt(recurrence, normalizeDateToDate(moment())))
    : null;

  dataStore.updateTasks(
    selectedTaskDuids.value.map((duid) => ({
      duid,
      recurrence,
      recursNextAt,
    }))
  );
};

const generateRecommendation = async () => {
  if (selectedTaskDuids.value.length > 100) {
    notify({ message: "Dart AI cannot fill out more than 100 tasks at once", type: NotificationType.ERROR });
    return;
  }

  appStore.startLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);
  const [taskUpdates, recDuids] = await generateFieldRecommendation(selectedTasks.value, "dueAt", null);

  dataStore.updateTasks(taskUpdates);
  appStore.finishLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);

  actions.visualization.showFeedbackTooltipForTask(
    selectedTaskDuids.value,
    recDuids,
    props.editorMode,
    feedbackTooltipNode.value ?? null
  );
};

const updateTaskDates = (startAt: string | null, dueAt: string | null) => {
  emit("update", [startAt, dueAt]);
  if (isFormMode.value) {
    return;
  }

  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, [isRange.value ? startAt : selectedDateRange.value.start, dueAt]),
    }))
  );
};

const rejectRecommendation = () => {
  updateTaskDates(null, null);
  emit("update");
  emit("reject");
};

const openReminderModal = () => {
  appStore.setTasksOpenInReminderModal(selectedTasks.value);
};

onUnmounted(() => {
  appStore.showFeedbackTooltip(null, []);
});
</script>

<template>
  <DatePicker
    :disabled="isReadOnly"
    :value="isRange ? selectedDateRange : (selectedDateRange.end ?? null)"
    :recurrence="selectedRecurrence.recurrence"
    :recurs-next-at="selectedRecurrence.recursNextAt"
    :remind-at="selectedRemindAt"
    :block="isListMode || isFormMode"
    :height-block="isListMode || isFormMode"
    :skidding="isFormMode ? -1 : undefined"
    :distance="isFormMode ? 2 : undefined"
    :editor-mode="editorMode"
    :show-recommendation-button="propertyConfig.isDefault && !isFormMode"
    :hide-actions="!propertyConfig.isDefault"
    :property-title="property.title"
    :cover="!isContextMenuMode && !isFormMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    @select="(date) => updateTaskDates(null, date)"
    @select-range="(dateRange) => updateTaskDates(dateRange.start ?? null, dateRange.end ?? null)"
    @change-recurrence="updateRecurrence"
    @change-remind-at="openReminderModal"
    @recommend="generateRecommendation">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="DatesFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isFormMode"
      :command-ids="
        isDefault && !isReadOnly
          ? [...(isRange ? [CommandId.CHANGE_START_DATE] : []), CommandId.CHANGE_DUE_DATE]
          : undefined
      "
      :text="
        isReadOnly
          ? `${property.title}${selectedDateRange.end !== null ? ` ${getFullDate(moment(selectedDateRange.end))}` : ''}`
          : isDefault
            ? undefined
            : `Change ${property.title.toLowerCase()}`
      "
      :block="isListMode || isFormMode"
      :height-block="isListMode || isFormMode"
      :class="isTaskDetailMode && 'w-full'">
      <div
        ref="feedbackTooltipNode"
        :class="['group/dates relative flex size-full items-center', !isTaskDetailMode && !isChipMode && 'px-2.5']">
        <DatesChip
          :property="property"
          :value="[selectedDateRange.start, selectedDateRange.end]"
          :status-duid="selectedStatusDuid"
          :recurrence="selectedRecurrence.recurrence"
          :loading="loading"
          :show-if-empty="isTaskDetailMode || isChipMode || isFormMode || loading"
          :show-icon="showIcon"
          :editor-mode="editorMode"
          @accept="emit('update')"
          @reject="rejectRecommendation" />
        <div
          v-if="isListMode && !hasValue"
          class="flex size-full items-center justify-center text-transparent group-hover/dates:text-vlt">
          <component :is="isRange ? DatesFieldIcon : DueDateFieldIcon" class="icon-xs" />
        </div>
      </div>
    </Tooltip>
  </DatePicker>
</template>
