<script setup lang="ts">
import { OnLongPress } from "@vueuse/components";

import actions from "~/actions";
import { EditorMode } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, usePageStore, useTenantStore } from "~/stores";

import { TASK_CONTEXT_MENU_OPTIONS } from "../constants";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
  disabled?: boolean;
}>();

const appStore = useAppStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const onClick = () => {
  if (props.disabled) {
    return;
  }

  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  appStore.setTaskDetailOpen(true);
};

const onContextMenu = (event: MouseEvent) => {
  if (pageStore.isPublicView || (tenantStore.isDart && !pageStore.adminHidden && event.altKey)) {
    return;
  }

  event.preventDefault();
  appStore.openContextMenu(event, actions.context.task(props.task, props.editorMode), TASK_CONTEXT_MENU_OPTIONS);
};

const stopIfAlreadySelected = (event: MouseEvent) => {
  if (event.shiftKey || (pageStore.isMac ? event.metaKey : event.ctrlKey)) {
    return;
  }
  if (appStore.selectedTaskDuids.has(props.task.duid)) {
    event.stopPropagation();
  }
};

let longPressEvent: MouseEvent | undefined;

const onLongPress = (event: MouseEvent) => {
  longPressEvent = event;
};

const onTouchEnd = (event: MouseEvent) => {
  if (!longPressEvent) {
    return;
  }

  event.preventDefault();
  onContextMenu(longPressEvent);
  longPressEvent = undefined;
};
</script>

<template>
  <slot
    v-if="editorMode === EditorMode.LIST && !pageStore.isMobile"
    v-bind="{ onclick: stopIfAlreadySelected, oncontextmenu: onContextMenu }" />

  <OnLongPress
    v-else
    as="div"
    class="relative size-full"
   
    @click="stopIfAlreadySelected"
    @contextmenu="onContextMenu"
    @trigger="onLongPress"
    @touchend="onTouchEnd">
    <slot v-bind="{ onclick: () => {}, oncontextmenu: () => {} }" />
    <div v-if="pageStore.isMobile" class="absolute inset-0" @click.stop="onClick" @keydown.enter.stop="onClick" />
  </OnLongPress>
</template>
