<script setup lang="ts">
import { computed, ref } from "vue";

import { backendOld } from "~/api";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import IconOrEmojiPicker from "~/components/dumb/IconOrEmojiPicker.vue";
import { emojiAllowsSkinTone, getEmojiWithSkinTone } from "~/constants/iconAndEmoji";
import { DropdownMenuItemKind, EmojiSkinTone, IconKind } from "~/shared/enums";
import type { Page, PageUpdate } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

const props = defineProps<{
  page: Page;
  disabled?: boolean;
  isContrast?: boolean;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const iconPicker = ref<InstanceType<typeof DropdownMenu> | null>(null);
const iconPickerSections = computed(() => [
  {
    title: "Modify",
    items: [
      {
        title: "Icons",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: IconOrEmojiPicker,
        componentArgs: {
          iconKind: props.page.iconKind,
          iconNameOrEmoji: props.page.iconNameOrEmoji,
          colorHex: props.page.colorHex,
          isContrast: props.isContrast,
          recommendFn: async (currentTab: IconKind) => {
            const getFn =
              currentTab === IconKind.ICON
                ? backendOld.recommendations.getPageIcon
                : backendOld.recommendations.getPageEmoji;
            const res = await getFn(props.page.duid);
            return res.data.item.value;
          },
          onSelectColor: (colorHex: string) => {
            dataStore.updatePage({ duid: props.page.duid, colorHex }, props.page.pageKind);
          },
          onSelectSkinTone: (skinTone: EmojiSkinTone) => {
            appStore.setSkinTone(skinTone);

            if (!emojiAllowsSkinTone(props.page.iconNameOrEmoji)) {
              return;
            }

            dataStore.updatePage(
              { duid: props.page.duid, iconNameOrEmoji: getEmojiWithSkinTone(props.page.iconNameOrEmoji, skinTone) },
              props.page.pageKind
            );
          },
          onSelectIconOrEmoji: (iconKind: IconKind, iconNameOrEmoji: string) => {
            const { duid } = props.page;
            const update: PageUpdate = { duid, iconNameOrEmoji };

            if (iconKind !== props.page.iconKind) {
              update.iconKind = iconKind;
            }

            dataStore.updatePage(update, props.page.pageKind);
          },
          onAfterSelect: () => {
            iconPicker.value?.close();
          },
        },
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu
    ref="iconPicker"
    :disabled="disabled"
    :sections="iconPickerSections"
    :distance="0"
    :width-pixels="383"
    :is-contrast="isContrast">
    <slot />
  </DropdownMenu>
</template>
