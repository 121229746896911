<script setup lang="ts">
import { computed, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";

import { backendOld } from "~/api";
import Animated from "~/components/dumb/Animated.vue";
import Button from "~/components/dumb/Button.vue";
import FormError from "~/components/dumb/FormError.vue";
import Input from "~/components/dumb/Input.vue";
import { finishLogin, getQueryParam } from "~/router/common";
import { AnimationDuration, ButtonStyle } from "~/shared/enums";
import { usePageStore } from "~/stores";
import { run, validateEmail, validatePassword } from "~/utils/common";

import AuthBaseView from "./AuthBaseView.vue";
import OpenEmail from "./OpenEmail.vue";

const router = useRouter();
const pageStore = usePageStore();

const tokenDuid = ref(getQueryParam("t") ?? "");
const query = { ...router.currentRoute.value.query };
delete query.t;
router.replace({ query });

const isEmailRequest = computed(() => !tokenDuid.value);

const isValidToken = ref(true);
if (tokenDuid.value) {
  run(async () => {
    const { data } = await backendOld.auth.checkResetPasswordToken(tokenDuid.value);
    pageStore.pageLoaded = true;
    isValidToken.value = data.valid;
  });
} else {
  pageStore.pageLoaded = true;
}

const emailInput = ref<InstanceType<typeof Input> | null>(null);
const passwordInput = ref<InstanceType<typeof Input> | null>(null);

const resetRequestSent = ref(false);
const emailCached = ref<string | undefined>();
const errorMsg = ref<string | null>(null);

const submitting = ref(false);
const submitButtonEnabled = computed(() => !submitting.value && emailInput.value?.isValid);

const requestPasswordReset = async () => {
  if (!submitButtonEnabled.value || !emailInput.value) {
    return;
  }

  submitting.value = true;
  const { data } = await backendOld.auth.requestPasswordReset(emailInput.value.value);
  submitting.value = false;

  if (data && !data.valid) {
    errorMsg.value = data.errors?.[0] ?? "Invalid email";
  } else {
    emailCached.value = emailInput.value.value;
    resetRequestSent.value = true;
  }
};

const password = ref("");

const resetPassword = async () => {
  if (passwordInput.value?.isValid !== true) {
    return;
  }

  submitting.value = true;

  const { data } = await backendOld.auth.changePassword(tokenDuid.value, passwordInput.value.value);
  finishLogin(data);
};
</script>

<template>
  <AuthBaseView>
    <template #heading>{{ resetRequestSent ? "Check your email" : "Reset Dart password" }}</template>

    <template #default>
      <Animated v-if="isEmailRequest" :duration="AnimationDuration.LONG">
        <form v-if="!resetRequestSent" @submit.prevent>
          <Input
            ref="emailInput"
            :init-value="emailCached"
            input-type="email"
            required
            placeholder="Enter your email"
            label="Email"
            :disabled="submitting"
            :validate="validateEmail"
            @change="errorMsg = null"
            @enter="requestPasswordReset" />
          <div class="-mb-5 mt-7">
            <Button
              :btn-style="ButtonStyle.PRIMARY"
              text="Continue"
              :disabled="!submitButtonEnabled"
              :working="submitting"
              block
              @click="requestPasswordReset" />
            <FormError :show="!!errorMsg" :msg="errorMsg" />
          </div>
        </form>

        <div v-else class="flex w-full flex-col items-center justify-center gap-10 text-base font-normal text-md">
          <div class="flex select-none flex-col hyphens-auto break-words text-center text-lt">
            Check {{ emailCached ?? "your email" }} and click the link
          </div>
          <OpenEmail :email="emailCached" />
        </div>
      </Animated>

      <div v-else>
        <div v-if="!isValidToken">
          <p class="py-16 text-center text-5xl text-md">Invalid or expired link, try again</p>
        </div>
        <form v-else @submit.prevent>
          <Input
            ref="passwordInput"
            :init-value="password"
            input-type="password"
            required
            placeholder="New password"
            label="Password"
            :disabled="submitting"
            :validate="validatePassword"
            @enter="resetPassword" />
          <Button
            :btn-style="ButtonStyle.PRIMARY"
            text="Reset password"
            :disabled="passwordInput?.isValid !== true"
            :working="submitting"
            block
            class="mt-7"
            @click="resetPassword" />
        </form>
      </div>
    </template>

    <template #footer>
      <RouterLink :to="{ name: 'login' }" class="select-none">← Back</RouterLink>
    </template>
  </AuthBaseView>
</template>
