export const COLLECT_ALL_DATA_KEY = "collectAllData";
export const CREATE_TASK_CREATE_MORE_ON_KEY = "createTaskCreateMoreOn";
export const HAS_TOUCH_OVERRIDE_KEY = "hasTouchOverride";
export const LAST_BANNER_CLEARED_AT_KEY = "lastBannerClearedAt";
export const LAST_CODING_LANGUAGE_KEY = "lastCodingLanguage";
export const LAST_LOCAL_DATA_RESET_AT_KEY = "lastLocalDataResetAt";
export const RECENT_DOC_STACK_KEY = "recentDocStack";
export const RECENT_EMOJI_STACK_KEY = "recentEmojiStack";
export const RECENT_ICON_STACK_KEY = "recentIconStack";
export const RECENT_SEARCH_STACK_KEY = "recentSearchStack";
export const RECENT_SKIN_TONE_KEY = "skinTone";
export const RECENT_TASK_STACK_KEY = "recentTaskStack";
export const REQUEST_QUEUE_KEY = "requestQueueKey";
export const SHOW_DEBUG_INFO_KEY = "showDebugInfo";
export const USER_DUID_LOCAL_STORAGE_KEY = "userDuid";

export const lsSet = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const lsGet = (key: string) => localStorage.getItem(key);

export const lsRemove = (key: string) => {
  localStorage.removeItem(key);
};

export const lsClearAll = () => {
  localStorage.clear();
};

type Callback = (value: string | null) => void;
const callbackMap: Map<string, Callback> = new Map();

window.addEventListener("storage", (event) => {
  if (!event.key || event.storageArea !== localStorage || !callbackMap.has(event.key)) {
    return;
  }
  callbackMap.get(event.key)?.(event.newValue);
});

export const lsListen = (key: string, callback: Callback) => {
  callbackMap.set(key, callback);
};
