import type { EditorThemeClasses } from "lexical";

const LIST = "py-1 pl-7 [&_ol]:py-0 [&_ol]:pl-4 [&_ul]:py-0 [&_ul]:pl-4";
const CHECKED_AND_UNCHECKED = "relative -ml-[22px] list-none pl-6 outline-none";
const CHECKED_AND_UNCHECKED_BEFORE =
  "before:absolute before:top-[7px] before:left-0 before:block before:h-4 before:w-4 before:cursor-pointer before:rounded-sm before:border before:bg-cover first:before:top-1";
const UNCHECKED_BEFORE = "before:border-hvy";
const CHECKED_BEFORE = "bg-no-repeat before:border-primary-base before:bg-primary-base";
const CHECKED_AFTER =
  "after:absolute after:top-[9px] after:left-[5px] after:block after:h-2.5 after:w-1.5 after:rotate-45 after:cursor-pointer after:border-r-2 after:border-b-2 after:border-white dark:after:border-zinc-850 first:after:top-1.5";

const EDITOR_THEME: EditorThemeClasses = {
  indent: "indent",
  paragraph: "py-1",
  heading: {
    h1: "m-0 px-0 pb-[7px] pt-[35px] text-3xl/[39px] font-semibold first:pt-0",
    h2: "m-0 px-0 pb-1 pt-[25px] text-2xl/[31px] font-semibold first:pt-0",
    h3: "m-0 px-0 pb-1 pt-[19px] text-xl/[26px] font-semibold first:pt-0",
  },
  code: "my-1 block w-full rounded bg-lt py-8 px-8 font-mono",
  codeHighlight: {
    atrule: "text-sky-500",
    attr: "text-sky-500",
    boolean: "text-purple-500",
    builtin: "text-yellow-500",
    cdata: "text-slate-500",
    char: "text-yellow-500",
    class: "text-green-500",
    "class-name": "text-green-500",
    comment: "text-slate-500",
    constant: "text-purple-500",
    deleted: "text-purple-500",
    doctype: "text-slate-500",
    entity: "text-red-500",
    function: "text-green-500",
    important: "text-orange-500",
    inserted: "text-yellow-500",
    keyword: "text-sky-500",
    namespace: "text-orange-500",
    number: "text-purple-500",
    operator: "text-red-500",
    prolog: "text-slate-500",
    property: "text-purple-500",
    punctuation: "text-lt",
    regex: "text-orange-500",
    selector: "text-yellow-500",
    string: "text-yellow-500",
    symbol: "text-purple-500",
    tag: "text-purple-500",
    url: "text-red-500",
    variable: "text-orange-500",
  },
  quote: "border-l-4 border-hvy py-1 pl-3",
  text: {
    bold: "font-semibold",
    italic: "italic",
    underline: "underline",
    strikethrough: "line-through",
    underlineStrikethrough: "line-through",
    code: "rounded bg-md px-1 py-0.5 font-mono text-sm/6 text-md",
  },
  smartMatch: "rounded px-px py-px -mx-0.5 border bg-primary-base/20 border-primary-base/40",
  link: "cursor-pointer text-lt underline decoration-gray-300 dark:decoration-zinc-600",
  list: {
    ul: LIST,
    ulDepth: ["list-disc", "list-[circle]", "list-[square]"],
    ol: LIST,
    olDepth: ["list-decimal", "list-[lower-alpha]", "list-[lower-roman]"],
    listitem: "pl-0.5 pt-[3px] first:pt-0",
    listitemChecked: `${CHECKED_AND_UNCHECKED} ${CHECKED_AND_UNCHECKED_BEFORE} ${CHECKED_BEFORE} ${CHECKED_AFTER}`,
    listitemUnchecked: `${CHECKED_AND_UNCHECKED} ${CHECKED_AND_UNCHECKED_BEFORE} ${UNCHECKED_BEFORE}`,
    nested: {
      listitem: "list-none before:hidden",
    },
  },
};

export default EDITOR_THEME;
