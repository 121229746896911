<script setup lang="ts">
import moment, { type Moment } from "moment";
import { capitalize, onUnmounted, ref } from "vue";

import { getItemCountText } from "~/utils/common";

const props = defineProps<{
  targetDate?: Moment;
  milestone: string;
}>();

const MS_PER_MIN = 1000 * 60;

const message = ref("");

const updateMessage = () => {
  message.value = "";
  if (!props.targetDate) {
    return;
  }
  const date = moment(props.targetDate).startOf("day");
  const now = moment();
  if (now > moment(date).add(1, "day")) {
    return;
  }
  const days = Math.ceil(date.diff(now, "day"));
  if (Number.isNaN(days)) {
    return;
  }
  if (days === 0) {
    message.value = `Today is ${props.milestone}!`;
    return;
  }
  const dayMessage = getItemCountText(days, "day");
  message.value = `${capitalize(dayMessage)} until ${props.milestone}!`;
};

updateMessage();

const updateMessageInterval = setInterval(updateMessage, MS_PER_MIN);

onUnmounted(() => {
  clearInterval(updateMessageInterval);
});
</script>

<template>
  <span v-if="message" class="ml-2 text-xs text-lt">{{ message }}</span>
</template>
