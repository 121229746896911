import type { IRowNode } from "ag-grid-community";
import type { Moment } from "moment";
import moment from "moment";

import { TaskDetailMode } from "~/shared/enums";
import type { CreateModalRows, PseudoRows, Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

import type { Actions } from "..";

let rangeIncreaseDirectionUpCache: boolean | null = null;
let lastToggleTaskDetail: Moment;

/** Selection related actions */
export default (actions: Actions) => ({
  resetRangeIncreaseDirection: (isUp: boolean | null = null) => {
    rangeIncreaseDirectionUpCache = isUp;
  },
  getHoverRow: (): IRowNode<Task> | null => {
    const appStore = useAppStore();

    if (appStore.hoverClosedWithBarOpen && !window.CommandBar.isOpen()) {
      appStore.hoverClosedWithBarOpen = false;
      const res = appStore.hoverRow;
      appStore.hoverRow = null;
      return res as IRowNode<Task> | null;
    }
    return appStore.hoverRow as IRowNode<Task> | null;
  },
  currentRowIsCreateModal: (rows: PseudoRows): rows is CreateModalRows => rows.length === 1 && "createModal" in rows[0],
  getCurrentRows: (selectIfHover = false): PseudoRows => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getActiveVisualization();

    const selecteds = visualization.getSelectedRows();
    if (appStore.tcmOpen && dataStore.taskDraft && (!appStore.tcmSubtasksList || selecteds.length === 0)) {
      return [{ createModal: true, data: dataStore.taskDraft as Task }];
    }

    if (selecteds.length !== 0 && !appStore.isNonTaskModalOpen) {
      return selecteds;
    }
    const hover = actions.visualization.getHoverRow();
    if (hover) {
      if (selectIfHover) {
        actions.visualization.resetRangeIncreaseDirection();
        hover.setSelected(true, true);
      }
      return [hover];
    }
    return [];
  },
  getCurrentBlock: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();
    const rowCount = visualization.count();
    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0 || actions.visualization.currentRowIsCreateModal(currents)) {
      return { min: undefined, max: undefined };
    }
    if (currents.length === rowCount) {
      return { min: 0, max: rowCount };
    }

    const currentsIndices = new Set(currents.map((e) => e.rowIndex));
    const current = currents[currents.length - 1];
    const currentIndex = current.rowIndex as number;
    let min = currentIndex;
    while (currentsIndices.has(min - 1)) {
      min -= 1;
    }
    let max = currentIndex;
    while (currentsIndices.has(max + 1)) {
      max += 1;
    }
    return { min, max };
  },
  isRangeIncreaseDirectionUp: (initiativeUp: boolean | null = null) => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    const updateCache = (value: boolean | null) => {
      rangeIncreaseDirectionUpCache = value;
      return value;
    };

    // if no selection, undefined
    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0 || actions.visualization.currentRowIsCreateModal(currents)) {
      return updateCache(null);
    }
    const { min, max } = actions.visualization.getCurrentBlock();
    // if it is a block of one, go with initiative
    if (min === max) {
      return updateCache(initiativeUp);
    }
    // if there's a cache, use it
    if (rangeIncreaseDirectionUpCache !== null) {
      return rangeIncreaseDirectionUpCache;
    }
    // if it is at the top or the bottom of the range, it's obvious
    const current = currents[currents.length - 1];
    const currIndex = current.rowIndex as number;
    if (currIndex === min) {
      return updateCache(true);
    }
    if (currIndex === max) {
      return updateCache(false);
    }
    // if it is in the middle of the range, compare with the ones around it
    const rowBefore = visualization.getRowByIndex(currIndex - 1) as IRowNode;
    const rowAfter = visualization.getRowByIndex(currIndex + 1) as IRowNode;
    return updateCache(currents.indexOf(rowBefore) < currents.indexOf(rowAfter));
  },
  selectRowByIndex: (index: number, clearSelection = true, select = true) => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();
    visualization.getRowByIndex(index)?.setSelected(select, clearSelection);
  },
  selectRowByIndexAndScroll: (index: number, clearSelection = true, select = true) => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    if (index < 0 || index >= visualization.count()) {
      return;
    }

    actions.visualization.selectRowByIndex(index, clearSelection, select);
    const row = visualization.getRowByIndex(index);
    if (!row?.data) {
      return;
    }

    visualization.scrollTo(row.data.duid);
  },
  selectRowsByIndicesAndScrollToLast: (indices: number[], clearSelection = true) => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();
    if (indices.length === 0) {
      return;
    }
    actions.visualization.selectRowByIndex(indices[0], clearSelection);
    indices.slice(1).forEach((index) => actions.visualization.selectRowByIndex(index, false));
    const row = visualization.getRowByIndex(indices[indices.length - 1]);
    if (!row?.data) {
      return;
    }

    visualization.scrollTo(row.data.duid);
  },
  selectRowByIdAndScroll: (duid: string, clearSelection = true, select = true) => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    const row = visualization.getRow(duid);
    if (row === undefined || row.rowIndex === null) {
      return;
    }

    actions.visualization.selectRowByIndexAndScroll(row.rowIndex, clearSelection, select);
  },
  selectWithClick: (row: IRowNode<Task>, newValue: boolean, rangeKey: boolean) => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();
    actions.visualization.resetRangeIncreaseDirection();

    // select the range of rows if the user is holding shift
    const selecteds = visualization.getSelectedRows();
    if (rangeKey) {
      // if no selection, select
      if (selecteds.length === 0) {
        actions.visualization.resetRangeIncreaseDirection();
        row.setSelected(newValue, false);
        return;
      }
      const currIndex = row.rowIndex as number;
      const { min, max } = actions.visualization.getCurrentBlock() as { min: number; max: number };
      // click in the range
      if (currIndex >= min && currIndex <= max) {
        const isUp = actions.visualization.isRangeIncreaseDirectionUp();
        if (isUp) {
          for (let i = min; i < currIndex; i += 1) {
            actions.visualization.selectRowByIndex(i, false, false);
          }
        } else {
          for (let i = currIndex + 1; i <= max; i += 1) {
            actions.visualization.selectRowByIndex(i, false, false);
          }
        }
        row.setSelected(newValue, false);
        return;
      }

      // normal case, click above or below the range
      const aboveTop = currIndex < min;
      const isUp = actions.visualization.isRangeIncreaseDirectionUp(aboveTop);
      actions.visualization.resetRangeIncreaseDirection(aboveTop);
      if (aboveTop) {
        for (let i = min - 1; i >= currIndex; i -= 1) {
          actions.visualization.selectRowByIndex(i, false);
        }
        if (!isUp) {
          for (let i = min + 1; i <= max; i += 1) {
            actions.visualization.selectRowByIndex(i, false, false);
          }
        }
      } else {
        for (let i = max + 1; i <= currIndex; i += 1) {
          actions.visualization.selectRowByIndex(i, false);
        }
        if (isUp) {
          for (let i = min; i < max; i += 1) {
            actions.visualization.selectRowByIndex(i, false, false);
          }
        }
      }
      row.setSelected(newValue, false);
      return;
    }

    actions.visualization.resetRangeIncreaseDirection();
    row.setSelected(newValue, false);
  },
  moveSelectionUp: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    actions.visualization.resetRangeIncreaseDirection();

    const currents = actions.visualization.getCurrentRows(true);
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }

    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }

    const defaultNext = rowCount - 1;
    if (currents.length === 0) {
      actions.visualization.selectRowByIndexAndScroll(defaultNext);
      return;
    }
    const current = currents[currents.length - 1];
    const currentIndex = current.rowIndex ?? defaultNext;
    const nextIndex = currentIndex === 0 ? 0 : currentIndex - 1;
    actions.visualization.selectRowByIndexAndScroll(nextIndex);
  },
  moveSelectionDown: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    actions.visualization.resetRangeIncreaseDirection();

    const currents = actions.visualization.getCurrentRows(true);
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }

    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }

    const defaultNext = 0;
    if (currents.length === 0) {
      actions.visualization.selectRowByIndexAndScroll(defaultNext);
      return;
    }
    const current = currents[currents.length - 1];
    const currentIndex = current.rowIndex ?? defaultNext;
    const nextIndex = currentIndex === rowCount - 1 ? rowCount - 1 : currentIndex + 1;
    actions.visualization.selectRowByIndexAndScroll(nextIndex);
  },
  moveSelectionRight: () => {
    const appStore = useAppStore();

    if (appStore.taskDetailMode === TaskDetailMode.FULLSCREEN) {
      actions.visualization.moveSelectionDown();
    }

    actions.visualization.resetRangeIncreaseDirection();
    const currents = actions.visualization.getCurrentRows(true);
    if (actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }

    const collapsed = currents.filter((e) => e.allChildrenCount !== null && !e.expanded);
    if (collapsed.length === 0) {
      actions.visualization.moveSelectionDown();
      return;
    }
    collapsed.forEach((current) => current.setExpanded(true));
  },
  moveSelectionLeft: () => {
    const appStore = useAppStore();

    if (appStore.taskDetailMode === TaskDetailMode.FULLSCREEN) {
      actions.visualization.moveSelectionUp();
    }

    actions.visualization.resetRangeIncreaseDirection();
    const currents = actions.visualization.getCurrentRows(true);
    if (currents.length === 0 || actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }

    const expanded = currents.filter((e) => e.allChildrenCount !== null && e.expanded);
    if (expanded.length !== 0) {
      expanded.forEach((current) => current.setExpanded(false));
      return;
    }

    const parentIndices = [...new Set(currents.map((e) => e.parent?.rowIndex).filter((e): e is number => e !== null))];
    if (parentIndices.length > 0) {
      actions.visualization.selectRowsByIndicesAndScrollToLast(parentIndices);
      return;
    }

    const root = currents[0].parent;
    const currentDuids = currents.map((e) => e.data?.duid).filter((e): e is string => e !== undefined);
    const siblingsAfterSort = root?.childrenAfterSort;
    const siblingsAfterFilter = root?.childrenAfterFilter;
    if (!siblingsAfterFilter || !siblingsAfterSort) {
      return;
    }
    const siblingsAfterFilterDuids = siblingsAfterFilter
      .map((e) => e.data?.duid)
      .filter((e): e is string => e !== undefined);
    const siblingsAfterSortAndFilter = siblingsAfterSort.filter(
      (e) => e.data !== undefined && siblingsAfterFilterDuids.includes(e.data?.duid)
    );
    const previousSiblingsIndices = siblingsAfterSortAndFilter
      .filter((e, i) => {
        if (!e.data) {
          return false;
        }
        if (i === 0 && currentDuids.includes(e.data.duid)) {
          return true;
        }
        if (i === siblingsAfterSortAndFilter.length - 1) {
          return false;
        }
        const nextSibling = siblingsAfterSortAndFilter[i + 1].data;
        if (!nextSibling) {
          return false;
        }
        return currentDuids.includes(nextSibling.duid);
      })
      .map((e) => e.rowIndex)
      .filter((e): e is number => e !== null);
    actions.visualization.selectRowsByIndicesAndScrollToLast(previousSiblingsIndices);
  },
  moveSelectionToTop: () => {
    actions.visualization.resetRangeIncreaseDirection();
    actions.visualization.selectRowByIndexAndScroll(0);
  },
  moveSelectionToBottom: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    actions.visualization.resetRangeIncreaseDirection();

    actions.visualization.selectRowByIndexAndScroll(visualization.count() - 1);
  },
  selectRangeUp: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();
    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }

    const selecteds = visualization.getSelectedRows();
    if (selecteds.length === 0) {
      const hover = actions.visualization.getHoverRow();
      if (hover && hover.rowIndex !== null) {
        actions.visualization.selectRowByIndex(hover.rowIndex);
      } else {
        actions.visualization.selectRowByIndex(rowCount - 1);
        return;
      }
    }
    const { min, max } = actions.visualization.getCurrentBlock();
    if (actions.visualization.isRangeIncreaseDirectionUp(true)) {
      if (min !== 0) {
        actions.visualization.selectRowByIndexAndScroll((min as number) - 1, false);
      }
      return;
    }
    actions.visualization.selectRowByIndexAndScroll(max as number, false, false);
  },
  selectRangeDown: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();
    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }

    const selecteds = visualization.getSelectedRows();
    if (selecteds.length === 0) {
      const hover = actions.visualization.getHoverRow();
      if (hover && hover.rowIndex !== null) {
        actions.visualization.selectRowByIndex(hover.rowIndex);
      } else {
        actions.visualization.selectRowByIndex(0);
        return;
      }
    }
    const { min, max } = actions.visualization.getCurrentBlock();
    if (!actions.visualization.isRangeIncreaseDirectionUp(false)) {
      if (max !== rowCount - 1) {
        actions.visualization.selectRowByIndexAndScroll((max as number) + 1, false);
      }
      return;
    }
    actions.visualization.selectRowByIndexAndScroll(min as number, false, false);
  },
  selectAllAbove: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    actions.visualization.resetRangeIncreaseDirection(true);

    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }
    const currents = actions.visualization.getCurrentRows();
    if (currents.length === rowCount || actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }
    let nextIndex = rowCount - 1;
    if (currents.length !== 0) {
      nextIndex = currents[currents.length - 1].rowIndex ?? nextIndex;
    }
    for (let i = nextIndex; i > 0; i -= 1) {
      actions.visualization.selectRowByIndex(i, false);
    }
    actions.visualization.selectRowByIndexAndScroll(0, false);
  },
  selectAllBelow: () => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    actions.visualization.resetRangeIncreaseDirection(false);

    const rowCount = visualization.count();
    if (rowCount === 0) {
      return;
    }
    const currents = actions.visualization.getCurrentRows();
    if (currents.length === rowCount || actions.visualization.currentRowIsCreateModal(currents)) {
      return;
    }
    let nextIndex = 0;
    if (currents.length !== 0) {
      nextIndex = currents[currents.length - 1].rowIndex ?? nextIndex;
    }
    for (let i = nextIndex; i < rowCount - 1; i += 1) {
      actions.visualization.selectRowByIndex(i, false);
    }
    actions.visualization.selectRowByIndexAndScroll(rowCount - 1, false);
  },
  selectCurrent: () => {
    actions.visualization.resetRangeIncreaseDirection();

    const hover = actions.visualization.getHoverRow();
    if (hover === null || hover.rowIndex === null) {
      return;
    }
    if (hover.isSelected()) {
      actions.visualization.selectRowByIndex(hover.rowIndex, false, false);
      return;
    }
    actions.visualization.selectRowByIndexAndScroll(hover.rowIndex, false);
  },
  selectAndToggleTaskDetail: () => {
    const now = moment();
    if (lastToggleTaskDetail && now.diff(lastToggleTaskDetail) < 500) {
      return;
    }
    lastToggleTaskDetail = now;

    const appStore = useAppStore();

    actions.visualization.resetRangeIncreaseDirection();

    const hover = actions.visualization.getHoverRow();
    if (hover?.data && hover.data.duid !== appStore.taskOpenInDetail?.duid) {
      actions.visualization.selectRowByIdAndScroll(hover.data.duid);
      appStore.setTaskDetailOpen(true);
      return;
    }

    if (appStore.taskDetailOpen) {
      appStore.setTaskDetailOpen(false);
    }
  },
});
