<script setup lang="ts">
import { useDropZone } from "@vueuse/core";
import { ref } from "vue";

import { usePageStore } from "~/stores";

const props = defineProps<{
  styles?: string;
  disabled?: boolean;
  dataTypes?: string[] | ((types: readonly string[]) => boolean);
}>();

const emit = defineEmits<{
  drop: [value: File[]];
}>();

const pageStore = usePageStore();

const wrapper = ref<HTMLDivElement | null>(null);

const onDrop = (files: File[] | null) => {
  if (!files || files.length === 0 || pageStore.isPublicView || props.disabled) {
    return;
  }
  emit("drop", files);
};

const { isOverDropZone } = useDropZone(wrapper, {
  dataTypes: props.dataTypes,
  onDrop,
});
</script>

<template>
  <div ref="wrapper" class="relative size-full">
    <slot name="main" />
    <div
      class="absolute left-0 top-0 z-20 size-full bg-md/80"
      :class="[isOverDropZone && !disabled ? 'flex' : 'hidden', !disabled && 'bg-md/80', styles]">
      <slot name="dragOverlay" />
    </div>
  </div>
</template>
