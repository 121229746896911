<script setup lang="ts">
import { AnimationDuration } from "~/shared/enums";

const props = defineProps<{
  element?: keyof HTMLElementTagNameMap;
  duration?: AnimationDuration | number;
}>();
</script>

<template>
  <component :is="element ?? 'div'" v-auto-animate="{ duration: props.duration ?? AnimationDuration.SHORT }">
    <slot />
  </component>
</template>
