<script setup lang="ts">
import moment from "moment-timezone";
import { computed } from "vue";

import { backendOld } from "~/api";
import AvatarUpload from "~/components/AvatarUpload.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import EditText from "~/components/dumb/EditText.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import SettingSection from "~/components/dumb/SettingSection.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import { CopyIcon } from "~/icons";
import { ButtonStyle, Placement } from "~/shared/enums";
import type { MultiselectDropdownMenuItem } from "~/shared/types";
import { useTenantStore } from "~/stores";
import { makeValidateTenantName } from "~/utils/common";
import { isBrowserLocale24h, prettifyTimezone } from "~/utils/time";

type TenantStore = ReturnType<typeof useTenantStore>;

const tenantStore = useTenantStore();

const validateTenantName = computed(() => makeValidateTenantName(tenantStore.isDart));

const timezoneOptions = computed(() => {
  const userTimezone = moment.tz.guess();
  const timezones = [userTimezone, ...moment.tz.names().filter((name) => name !== userTimezone)];

  return timezones.map((name) => {
    const zone = moment.tz.zone(name) as moment.MomentZone;
    const zoneCurrentTime = moment.tz(name).format(isBrowserLocale24h() ? "HH:mm" : "h:mm A");

    return {
      value: name,
      label: name,
      selected: tenantStore.timezone === name,
      adtlSearchTerms: zone?.abbrs,
      component: DropdownMenuItemContent,
      componentArgs: {
        title: prettifyTimezone(name),
        subtitle: `${moment.tz(name).format("z")} (${moment.tz(name).format("Z")}) ${zoneCurrentTime}`,
        isMultiSelect: true,
      },
    } satisfies MultiselectDropdownMenuItem;
  });
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateWorkspaceSetting = (settingName: keyof TenantStore, newValue: any) => {
  // TODO fix this type hack
  tenantStore[settingName as "name"] = newValue;
  backendOld.workspace.edit(settingName, newValue);
};

const copyId = () => copyAndNotify("Workspace ID", tenantStore.duid);
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-auto w-full">
      <SettingSection title="Name" subtitle="The name of your company or team">
        <EditText
          :value="tenantStore.name"
          label="Workspace name"
          :validate="validateTenantName"
          @save="(e) => updateWorkspaceSetting('name', e)">
          <div
            :title="tenantStore.name"
            class="h-full w-48 select-none truncate rounded border px-3 py-2 text-left text-base shadow-sm text-md border-md hover:bg-lt">
            {{ tenantStore.name }}
          </div>
        </EditText>
      </SettingSection>
      <SettingSection
        title="Image"
        subtitle="A square logo for the workspace"
        :styles="{
          alignItems: 'items-start',
        }">
        <AvatarUpload kind="workspace" />
      </SettingSection>
      <SettingSection title="Timezone" subtitle="The primary timezone of the workspace for events">
        <MultiselectDropdownMenu
          ref="menu"
          :items="timezoneOptions"
          :placement="Placement.BOTTOM_RIGHT"
          cover
          :width-pixels="300"
          close-on-select
          @add="(e) => updateWorkspaceSetting('timezone', e)">
          <div
            class="w-48 select-none rounded border px-3 py-2 text-left text-base shadow-sm text-md border-md hover:bg-lt">
            {{ prettifyTimezone(tenantStore.timezone) }}
          </div>
        </MultiselectDropdownMenu>
      </SettingSection>
      <div class="mx-10 my-24">
        <Toggle
          :value="!tenantStore.backlogEnabled"
          label="Disable backlogs"
          description="This will turn all backlogs off--they can always be reenabled"
          @update="(e) => updateWorkspaceSetting('backlogEnabled', !e)" />
      </div>
      <SettingSection title="Workspace ID">
        <div class="flex gap-1 text-lt">
          <pre class="grow rounded px-3 py-[7px] text-xs bg-lt" @click="copyId" @keydown.enter="copyId">{{
            tenantStore.duid
          }}</pre>
          <Tooltip text="Copy workspace ID">
            <Button :btn-style="ButtonStyle.CHIP" :icon="CopyIcon" a11y-label="Copy workspace ID" @click="copyId" />
          </Tooltip>
        </div>
      </SettingSection>
    </div>
  </div>
</template>
