import { $dfs } from "@lexical/utils";
import { $getRoot, type LexicalEditor } from "lexical";
import { computed } from "vue";

import { getShownPropertyWithConfigList } from "~/common/properties";

import { $isEntityNode, type EntityNode } from "../../nodes/EntityNode";
import { $isSmartMatchNode, type SmartMatchNode } from "../../nodes/SmartMatchNode";

export type MenuTextMatch = {
  leadOffset: number;
  matchingString: string;
  replaceableString: string;
};

const makeFullMatchRegex = (trigger: string, options: RegExp) =>
  new RegExp(
    "(?<whitespace>^|\\s|\\()(?<replaceable>" + // Match space, start of string, or open paren
      `${trigger}` + // Match the full trigger string
      `${trigger.length !== 1 ? "\\s" : ""}` + // Match a space after the trigger, if the trigger is more than one character
      `(?<query>${options.source})?` + // Match possible options
      ")$", // Match end of string
    "i"
  );

/** Prefix Typeahead Trigger Match
 * This is a strategy for matching a trigger string and the value after, separated by a space.
 * @example // "hey assign Ivan" -> will match "assign Ivan"
 * @example // "hey assign Ivan Zvonar" -> will match "assign Ivan Zvonar"
 * @example // "hey assign Ivan Zvonar " -> will not match
 * @param {string} trigger The trigger string to match
 */
export const usePrefixTypeaheadTriggerMatch = (trigger: string) => {
  // Split the options into a set of possible options by letters for only words after the first
  // e.g. Ivan Zvonar -> \S* Z, \S* Zv, ..., \S* Zvonar
  const matchRegex = makeFullMatchRegex(trigger, /.{0,15}/);

  return (text: string): MenuTextMatch | null => {
    const match = matchRegex.exec(text);
    if (!match?.groups) {
      return null;
    }
    const { whitespace, query, replaceable } = match.groups;
    return {
      leadOffset: match.index + whitespace.length,
      matchingString: query ?? "",
      replaceableString: replaceable,
    };
  };
};

export const propertiesDuidsWithMultiple = computed(() =>
  getShownPropertyWithConfigList()
    .filter(([property, config]) => config.typeahead?.(property).some((typeahead) => typeahead.isMultiple) ?? false)
    .map(([property]) => property.duid)
);

export const removeConflictingEntitiesAndSmartMatches = (
  editor: LexicalEditor,
  newNode: EntityNode | SmartMatchNode
) => {
  const newKey = newNode.getKey();
  const newPropertyDuid = newNode.getPropertyDuid();
  editor.update(() => {
    $dfs($getRoot()).forEach(({ node }) => {
      if (node.getKey() === newKey || !($isEntityNode(node) || $isSmartMatchNode(node))) {
        return;
      }
      const currPropertyDuid = node.getPropertyDuid();
      if (currPropertyDuid !== newPropertyDuid || propertiesDuidsWithMultiple.value.includes(currPropertyDuid)) {
        return;
      }

      node.remove();
    });
  });
};
