<script setup lang="ts">
import type { EditorState, SerializedEditorState } from "lexical";
import {
  LexicalAutoLinkPlugin,
  LexicalCheckListPlugin,
  LexicalLinkPlugin,
  LexicalListPlugin,
  LexicalMarkdownShortcutPlugin,
  LexicalTabIndentationPlugin,
} from "lexical-vue";
import { computed, ref } from "vue";

import { usePageStore } from "~/stores";

import { URL_MATCHERS } from "./const";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin.vue";
import CodeSelectorPlugin from "./plugins/CodeSelectorPlugin.vue";
import EnterPlugin from "./plugins/EnterPlugin.vue";
import FloatingToolbar from "./plugins/FloatingToolbar.vue";
import ManageTextFormatPlugin from "./plugins/ManageTextFormatPlugin.vue";
import OnChangePlugin from "./plugins/OnChangePlugin.vue";
import PasteUrlPlugin from "./plugins/PasteUrlPlugin.vue";
import PlaceholderPlugin from "./plugins/PlaceholderPlugin.vue";
import ShortcutsPlugin from "./plugins/ShortcutsPlugin.vue";
import TextEditor from "./TextEditor.vue";
import { TRANSFORMERS } from "./transformers";
import { GENERIC_EDITOR_NODES } from "./utils";

const props = defineProps<{
  namespace: string;
  toolbarTeleportKey: string;
  initialState?: SerializedEditorState;
  placeholder?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  change: [text: SerializedEditorState];
  actionEnter: [];
}>();

const pageStore = usePageStore();

const textEditor = ref<InstanceType<typeof TextEditor> | null>(null);

const namespace = computed(() => `default-${props.namespace}`);

const onEditorChange = (newEditorState: EditorState) => {
  emit("change", newEditorState.toJSON());
};

const enterFilter = (event: KeyboardEvent) => (pageStore.isMac ? event.metaKey : event.ctrlKey);

const focus = () => {
  textEditor.value?.focus();
};

defineExpose({
  focus,
});
</script>

<template>
  <TextEditor
    ref="textEditor"
    :namespace="namespace"
    :nodes="GENERIC_EDITOR_NODES"
    :initial-state="initialState"
    :placeholder="placeholder ?? 'Default editor'"
    :disabled="disabled"
    :min-height-px="200">
    <template #default>
      <!-- lexical-vue plugins -->
      <LexicalAutoLinkPlugin :matchers="URL_MATCHERS" />
      <LexicalCheckListPlugin />
      <LexicalLinkPlugin />
      <LexicalListPlugin />
      <LexicalMarkdownShortcutPlugin :transformers="TRANSFORMERS" />
      <LexicalTabIndentationPlugin />
      <!-- custom plugins -->
      <CodeHighlightPlugin />
      <CodeSelectorPlugin editable />
      <EnterPlugin :filter="enterFilter" @enter="emit('actionEnter')" />
      <FloatingToolbar hide-ai-action-button :teleport-key="toolbarTeleportKey" />
      <ManageTextFormatPlugin />
      <OnChangePlugin :has-focus="!!textEditor?.hasFocus" @change="onEditorChange" />
      <PasteUrlPlugin />
      <PlaceholderPlugin no-ai />
      <ShortcutsPlugin />
    </template>
  </TextEditor>
</template>
