<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from "~/icons";
import { ButtonStyle, CommandId, ModalWidth } from "~/shared/enums";
import { useAppStore, useDataStore } from "~/stores";
import { downloadFile } from "~/utils/api";

const appStore = useAppStore();
const dataStore = useDataStore();

const focusDiv = ref<HTMLDivElement | null>(null);

const task = computed(() => appStore.taskOpenInDetail);

const isImage = (kind: string) => kind.startsWith("image/") && kind !== "image/svg+xml";
const isVideo = (kind: string) => kind.startsWith("video/");
const isMedia = (kind: string) => isImage(kind) || isVideo(kind);

const attachments = computed(() =>
  dataStore
    .getAttachmentsByDuidsOrdered(task.value?.attachmentDuids ?? [])
    .filter((attachment) => isMedia(attachment.kind))
);

const currentAttachmentIndex = ref(0);

const updateAttachmentIndex = (index: number) => {
  if (index < 0 || index >= attachments.value.length) {
    return;
  }
  currentAttachmentIndex.value = index;
  const attachment = attachments.value[index];

  appStore.setFullscreenMediaModalOpen({
    attachmentFileUrl: attachment.fileUrl,
    attachmentName: attachment.name,
    isImage: isImage(attachment.kind),
    entityName: appStore.taskOpenInDetail?.title ?? "",
  });
};

const goToPrevious = () => updateAttachmentIndex(currentAttachmentIndex.value - 1);
const goToNext = () => updateAttachmentIndex(currentAttachmentIndex.value + 1);
const onKeydown = (event: KeyboardEvent) => {
  if (event.key === "ArrowLeft") {
    goToPrevious();
  } else if (event.key === "ArrowRight") {
    goToNext();
  }
};

const isLeftButtonDisabled = computed(() => currentAttachmentIndex.value === 0);
const isRightButtonDisabled = computed(() => currentAttachmentIndex.value === attachments.value.length - 1);

const downloadMedia = () => {
  if (!appStore.mediaOpenInFullscreen) {
    return;
  }

  downloadFile(appStore.mediaOpenInFullscreen?.attachmentFileUrl, appStore.mediaOpenInFullscreen?.attachmentName);
};

const closeModal = () => appStore.setFullscreenMediaModalOpen(null);

watch(
  () => appStore.mediaOpenInFullscreen,
  (newMedia) => {
    if (newMedia) {
      currentAttachmentIndex.value = attachments.value.findIndex((a) => a.fileUrl === newMedia.attachmentFileUrl);
      nextTick(() => focusDiv.value?.focus());
    }
  }
);
</script>

<template>
  <Modal
    :entity="appStore.mediaOpenInFullscreen"
    :title="(e) => e?.attachmentName ?? (e?.isImage ? 'Image' : 'Video')"
    hide-title
    h-full
    :width="ModalWidth.FULL"
    custom-styles="h-full w-full overflow-hidden !p-0"
    close-icon-styles="-mt-1 -mr-1 p-1"
    @close="closeModal">
    <template #default="{ entity: mediaOpenInFullscreen }">
      <template v-if="mediaOpenInFullscreen">
        <div ref="focusDiv" tabindex="0" class="flex size-full flex-col focus:outline-none" @keydown.stop="onKeydown">
          <!-- Topbar -->
          <div class="flex w-full items-center gap-2 p-2 bg-std">
            <div class="w-1/3 truncate pl-2 text-xs text-lt">{{ mediaOpenInFullscreen.entityName }}</div>
            <div class="grow items-center truncate text-center text-xs text-lt">
              {{ mediaOpenInFullscreen.attachmentName }}
            </div>
            <div class="flex h-full w-1/3 items-center justify-end pr-[26px]">
              <Tooltip text="Download">
                <button
                  type="button"
                  class="flex items-center rounded p-1 text-vlt focus-ring-std hover:bg-md"
                  @click="downloadMedia">
                  <span class="sr-only">Download</span>
                  <DownloadIcon class="icon-sm" />
                </button>
              </Tooltip>
            </div>
          </div>

          <div class="relative w-full grow overflow-hidden">
            <!-- BG Image -->
            <div class="absolute -inset-6">
              <img
                v-if="mediaOpenInFullscreen.isImage"
                :src="mediaOpenInFullscreen.attachmentFileUrl"
                :alt="mediaOpenInFullscreen.attachmentName"
                class="size-full rounded-lg object-cover opacity-80 blur-lg" />
              <video
                v-else
                class="size-full rounded-lg object-cover opacity-80 blur-lg"
                :src="mediaOpenInFullscreen.attachmentFileUrl"
                :title="mediaOpenInFullscreen.attachmentName"
                :controls="false"
                playsinline
                muted
                preload="metadata"
                :disablePictureInPicture="true">
                <track kind="captions" />
              </video>
            </div>

            <!-- Media -->
            <div class="absolute inset-0 flex size-full items-center justify-center">
              <img
                v-if="mediaOpenInFullscreen.isImage"
                :src="mediaOpenInFullscreen.attachmentFileUrl"
                :alt="mediaOpenInFullscreen.attachmentName"
                class="size-full rounded-lg object-contain" />
              <video
                v-else
                class="size-full rounded-lg object-contain"
                :src="mediaOpenInFullscreen.attachmentFileUrl"
                :title="mediaOpenInFullscreen.attachmentName"
                autoplay
                controls
                playsinline="false"
                :muted="false"
                :loop="false"
                preload="auto"
                :disablePictureInPicture="false">
                <track kind="captions" />
              </video>
              <div class="pointer-events-none absolute inset-0 flex items-center justify-between px-2">
                <Tooltip :command-id="CommandId.GO_TO_MEDIA_PREVIOUS" :disabled="isLeftButtonDisabled">
                  <Button
                    :btn-style="ButtonStyle.SECONDARY"
                    :icon="ChevronLeftIcon"
                    borderless
                    :disabled="isLeftButtonDisabled"
                    class="pointer-events-auto items-center bg-std icon-lg"
                    :class="{ 'hover:bg-md': !isLeftButtonDisabled }"
                    a11y-label="Go to previous"
                    @click="goToPrevious" />
                </Tooltip>
                <Tooltip :command-id="CommandId.GO_TO_MEDIA_NEXT" :disabled="isRightButtonDisabled">
                  <Button
                    :btn-style="ButtonStyle.SECONDARY"
                    :icon="ChevronRightIcon"
                    borderless
                    :disabled="isRightButtonDisabled"
                    :class="{ 'hover:bg-md': !isRightButtonDisabled }"
                    class="pointer-events-auto items-center bg-std icon-lg"
                    a11y-label="Go to next"
                    @click="goToNext" />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </Modal>
</template>
