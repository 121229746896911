/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `1` - INDENTED
 * * `2` - COLLAPSED
 * * `3` - FLAT
 */
export enum SubtaskDisplayMode {
  INDENTED = 1,
  COLLAPSED = 2,
  FLAT = 3,
}
