<script setup lang="ts">
import { type Component, computed, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ChevronDownIcon, DotsHorizontalIcon, PlusIcon } from "~/icons";
import { ButtonSize, ButtonStyle, IconSize, Placement, SectionKind } from "~/shared/enums";
import type { DropdownMenuSection } from "~/shared/types";

const props = defineProps<{
  title: string;
  uncollapsible?: boolean;
  kind?: SectionKind;
  tooltip?: string;
  dropdownSections?: DropdownMenuSection[];
  component?: Component;
  componentArgs?: object;
  initCollapsed?: boolean;
}>();

const emit = defineEmits<{
  create: [];
}>();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);
const expanded = ref(!props.initCollapsed);
const sectionKind = computed(() => props.kind ?? SectionKind.DEFAULT);

watch(
  () => props.initCollapsed,
  (collapsed) => {
    expanded.value = !collapsed;
  }
);

const toggleExpanded = () => {
  expanded.value = !expanded.value;
};

const onClick = () => {
  if (sectionKind.value === SectionKind.DROPDOWN || sectionKind.value === SectionKind.DROPDOWN_CREATE) {
    dropdownMenu.value?.open();
    return;
  }

  emit("create");
};

const expand = () => {
  expanded.value = true;
};

defineExpose({
  expand,
});
</script>

<template>
  <slot v-if="sectionKind === SectionKind.DISABLED && expanded" />
  <div v-else-if="sectionKind === SectionKind.DISABLED" />
  <div v-else class="flex flex-col">
    <div class="ml-3.5 mr-4 flex items-center justify-between">
      <button
        v-if="!uncollapsible"
        type="button"
        class="flex items-center gap-1 rounded py-0.5 pl-1 pr-1.5 text-md hover:bg-lt"
        @click="toggleExpanded">
        <ChevronDownIcon class="transition-transform icon-sm" :class="!expanded && '-rotate-90'" />
        <span class="select-none text-base font-semibold">{{ title }}</span>
      </button>
      <span v-else class="select-none pl-[7px] text-base font-semibold text-md">{{ title }}</span>
      <template v-if="expanded">
        <Tooltip v-if="sectionKind === SectionKind.CREATE" :text="tooltip">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :size="ButtonSize.SMALL"
            :icon="PlusIcon"
            :icon-size="IconSize.S"
            a11y-label="Create"
            class="h-6 border-0 !p-1 hover:bg-md print:hidden"
            @click="onClick" />
        </Tooltip>
        <DropdownMenu
          v-else-if="
            dropdownSections && (sectionKind === SectionKind.DROPDOWN_CREATE || sectionKind === SectionKind.DROPDOWN)
          "
          ref="dropdownMenu"
          :sections="dropdownSections"
          :distance="-1"
          :placement="Placement.BOTTOM_RIGHT">
          <Tooltip :text="tooltip">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.SMALL"
              :icon="sectionKind === SectionKind.DROPDOWN ? DotsHorizontalIcon : PlusIcon"
              :icon-size="IconSize.S"
              a11y-label="Create"
              class="h-6 border-0 !p-1 hover:bg-md print:hidden"
              @click="onClick" />
          </Tooltip>
        </DropdownMenu>
        <component
          :is="component"
          v-else-if="sectionKind === SectionKind.COMPONENT"
          v-bind="componentArgs"
          @click="onClick" />
      </template>
    </div>

    <div v-show="expanded">
      <slot />
    </div>
  </div>
</template>
