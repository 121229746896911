<script setup lang="ts">
import { $isListNode } from "@lexical/list";
import { useEventBus } from "@vueuse/core";
import type { AxiosProgressEvent } from "axios";
import { $getRoot, $isElementNode, type ElementNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { nextTick, onUnmounted } from "vue";

import { backendOld } from "~/api";
import type { Doc } from "~/shared/types";
import { useDataStore } from "~/stores";

import { EVENT_BUS_GENERATE_REPORT } from "../const";
import { $createAiCursorNode } from "../nodes/AiCursorNode";
import { fillNodeWithMarkdown } from "../transformers";

const props = defineProps<{
  doc: Doc;
}>();

const emit = defineEmits<{
  afterCreate: [];
}>();

const dataStore = useDataStore();
const editor = useLexicalComposer();

const fillNodeWithReport = async (wrapperNode: ElementNode, startDate: string, endDate: string) => {
  let recommendationDuid: string | null = null;
  let ended = false;
  const setText = (text: string | undefined, end: boolean) => {
    if (text === undefined || ended) {
      return;
    }
    if (end) {
      ended = true;
    }

    if (!recommendationDuid) {
      recommendationDuid = text.substring(0, 12);
    }

    const normalizedText = text.substring(12);

    editor.update(() => {
      fillNodeWithMarkdown(wrapperNode, normalizedText);

      if (!end) {
        // AI cursor
        const lastChild = wrapperNode.getLastChild();
        const targetNode = $isListNode(lastChild) ? lastChild.getLastChild() : lastChild;
        if (!targetNode || !$isElementNode(targetNode)) {
          return;
        }
        targetNode.append($createAiCursorNode());
      }
    });
  };
  const handlePartialResponse = (e: AxiosProgressEvent) => {
    const { currentTarget } = e.event;
    if (!currentTarget || currentTarget.status < 200 || currentTarget.status >= 300) {
      return;
    }
    setText(currentTarget.response, false);
  };

  try {
    const response = await backendOld.recommendations.streamReport(
      props.doc.duid,
      startDate,
      endDate,
      handlePartialResponse
    );
    setText(response.data, true);
  } catch (error) {
    editor.update(() => {
      wrapperNode.remove();
    });
  }

  nextTick(() => {
    emit("afterCreate");
    dataStore.updateDocs([{ duid: props.doc.duid, recommendationDuid }]);
  });
};

const generateReportBus = useEventBus(EVENT_BUS_GENERATE_REPORT);
const unsubscribe = generateReportBus.on((event) => {
  const { docDuid, startDate, endDate } = event;
  if (docDuid !== props.doc.duid) {
    return;
  }

  editor.update(() => {
    const rootNode = $getRoot();
    rootNode.clear();
    fillNodeWithReport(rootNode, startDate, endDate);
    editor.focus();
  });
});

onUnmounted(unsubscribe);
</script>

<template>
  <slot />
</template>
