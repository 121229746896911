<script setup lang="ts">
import { computed } from "vue";

import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { StatusKind } from "~/shared/enums";
import { BlockedIcon, CanceledIcon, FinishedIcon, StartedIcon, UnstartedIcon } from "~/status-icons";
import { useDataStore, usePageStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";

const STATUS_ICON_MAP = new Map([
  [StatusKind.UNSTARTED, UnstartedIcon],
  [StatusKind.STARTED, StartedIcon],
  [StatusKind.BLOCKED, BlockedIcon],
  [StatusKind.FINISHED, FinishedIcon],
  [StatusKind.CANCELED, CanceledIcon],
]);

const props = defineProps<{
  duid: string;
  colorHex?: string;
  isContrast?: boolean;
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const statusIconObject = computed(() => {
  const status = dataStore.getStatusByDuid(props.duid);

  return {
    icon: status ? (STATUS_ICON_MAP.get(status.kind) ?? UnstartedIcon) : UnstartedIcon,
    color:
      props.colorHex ??
      (status
        ? props.isContrast
          ? getChipColorsHex(status.colorHex, pageStore.theme).fore
          : status.colorHex
        : DEFAULT_CHIP_COLOR),
  };
});
</script>

<template>
  <component :is="statusIconObject.icon" :style="{ color: statusIconObject.color }" />
</template>
