<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { onMounted } from "vue";

import NumberEditor from "~/components/dumb/NumberEditor.vue";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { EditorMode } from "~/shared/enums";
import type { PropertyNumber, TaskAbsenteeMaybe } from "~/shared/types";
import { usePageStore } from "~/stores";

defineProps<{
  params: ICellRendererParams<TaskAbsenteeMaybe> & {
    property: PropertyNumber;
  };
}>();

const pageStore = usePageStore();

onMounted(() => {
  pageStore.incrementCellRenderCount();
});
</script>

<template>
  <TaskClickWrapper v-if="params.data" :task="params.data" :editor-mode="EditorMode.LIST">
    <template #default="args">
      <NumberEditor
        v-if="!params.data.absentee"
        v-bind="args"
        :property="params.property"
        :tasks="[params.data]"
        :editor-mode="EditorMode.LIST"
        class="h-full" />
    </template>
  </TaskClickWrapper>
</template>
