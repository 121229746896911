import { $createCodeNode } from "@lexical/code";
import {
  CODE,
  type ElementTransformer,
  HEADING as HEADING_ORIG,
  ORDERED_LIST as ORDERED_LIST_ORIG,
  QUOTE as QUOTE_ORIG,
  type TextFormatTransformer,
} from "@lexical/markdown";
import { $isElementNode, type ElementNode, type LexicalNode } from "lexical";

// Copied from @lexical/markdown/LexicalMarkdownDev.js
export const createBlockNode =
  (createNode: (match: string[]) => LexicalNode) =>
  (parentNode: ElementNode, children: LexicalNode[], match: string[]) => {
    const node = createNode(match);
    if ($isElementNode(node)) {
      node.append(...children);
    }
    parentNode.replace(node);
    if ($isElementNode(node)) {
      node.select(0, 0);
    }
  };

// These are the generic lexical transformers, for custom node transformers they should go in the custom node's file
export const CODE_BLOCK: ElementTransformer = {
  ...CODE,
  regExp: /^```/,
  replace: createBlockNode(() => $createCodeNode(undefined)),
};

export const HEADING: ElementTransformer = {
  ...HEADING_ORIG,
  regExp: /^(#{1,3})\s/,
};

export const QUOTE: ElementTransformer = {
  ...QUOTE_ORIG,
  regExp: /^\|\s/,
};

export const ORDERED_LIST = {
  ...ORDERED_LIST_ORIG,
  regExp: /^(\s*)(\d{1,})(\.|\)|\/){1,2}\s/,
};

export const BOLD_STAR_SINGLE: TextFormatTransformer = {
  format: ["bold"],
  tag: "*",
  type: "text-format",
};

export const ITALICS_HYPHEN: TextFormatTransformer = {
  format: ["italic"],
  tag: "-",
  intraword: false,
  type: "text-format",
};

export const UNDERLINE_UNDERSCORE: TextFormatTransformer = {
  format: ["underline"],
  tag: "_",
  intraword: false,
  type: "text-format",
};

export const STRIKE_THROUGH_TILDE: TextFormatTransformer = {
  format: ["strikethrough"],
  tag: "~",
  type: "text-format",
};

export const CODE_BACKTICK: TextFormatTransformer = {
  format: ["code"],
  tag: "`",
  type: "text-format",
};
