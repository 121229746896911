<script setup lang="ts">
import { RouterView } from "vue-router";

((d, s, id) => {
  const tjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  const js = d.createElement(s) as HTMLIFrameElement;
  js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode?.insertBefore(js, tjs);
})(document, "script", "termly-jssdk");
</script>

<template>
  <div class="flex h-screen w-screen justify-center overflow-y-scroll bg-white">
    <div class="mx-6 mt-12 max-w-3xl grow">
      <RouterView />
    </div>
  </div>
</template>

<style scoped>
::-webkit-scrollbar-thumb {
  border: 4px solid white;
  background: #e5e7eb;
}
</style>
