import { copyAndNotify } from "~/components/notifications";
import type { Form } from "~/shared/types";
import { useEnvironmentStore } from "~/stores";

import type { Actions } from ".";

/** Form actions */
export default (actions: Actions) => ({
  /**
   * Copy the link to a form to the clipboard
   * @param {Form} form
   */
  copyLink: (form: Form): void => {
    const environmentStore = useEnvironmentStore();
    const url = environmentStore.getFormUrl(form);
    copyAndNotify("Link to form", url, `<a href=${url}>${form.title}</a>`);
  },
});
