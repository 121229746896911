import "v-calendar/dist/style.css";
import "floating-vue/dist/style.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "~/index.css";

import { datadogRum } from "@datadog/browser-rum";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import * as Sentry from "@sentry/vue";
import { LicenseManager } from "ag-grid-enterprise";
import { init as initCommandBar } from "commandbar";
import FloatingVue from "floating-vue";
import { UAParser } from "ua-parser-js";
import { createApp } from "vue";
import { plugin as VueInputAutowidth } from "vue-input-autowidth";
import type { Router } from "vue-router";

import actions from "~/actions";
import { backendOld, requestManager } from "~/api";
import App from "~/App.vue";
import { checkNavigationRules, finalizeNavigation, getUserDataMode, initRouterForUtils } from "~/router/common";
import router from "~/router/router";
import { EventKind, UserDataMode } from "~/shared/enums";
import { pinia, useAppStore, useEnvironmentStore, usePageStore, useUserStore } from "~/stores";
import ApiPlugin from "~/stores/plugins/ApiPlugin";
import RouterPlugin from "~/stores/plugins/RouterPlugin";
import vuetify from "~/stores/plugins/VuetifyPlugin";
import { openPathInDesktop, run } from "~/utils/common";
import { callDesktop, listenDesktop } from "~/utils/desktop";
import registerGlobalEventListeners from "~/utils/globalEventManager";
import { COLLECT_ALL_DATA_KEY, lsGet, lsListen, USER_DUID_LOCAL_STORAGE_KEY } from "~/utils/localStorageManager";
import { provideComponents } from "~/utils/provide";

import DesktopSearch from "./components/DesktopSearch.vue";
import { AG_GRID_LICENSE_KEY } from "./constants/app";
import ScrollSyncDirective from "./utils/ScrollSyncDirective";

const RUN_DEBUG_TOOLS_LOCALLY = false;

(() => {
  // Open app as desktop search box
  const isDesktopApp = UAParser(navigator.userAgent).browser.name === "Electron";
  if (isDesktopApp && window.location.pathname === "/desktop-window-search") {
    const app = createApp(DesktopSearch);
    app.mount("#app");
    return;
  }

  const app = createApp(App);

  // Set up core elements of app
  pinia.use(RouterPlugin);
  pinia.use(ApiPlugin);
  app.use(pinia);

  // Add components
  app.use(VueInputAutowidth);
  app.use(autoAnimatePlugin);
  app.directive("scroll-sync", ScrollSyncDirective);
  app.use(FloatingVue, {
    themes: {
      "tooltip-light": { $extend: "tooltip" },
      "tooltip-dark": { $extend: "tooltip" },
      "tooltip-arrowless-light": { $extend: "tooltip" },
      "tooltip-arrowless-dark": { $extend: "tooltip" },
      "tutorial-light": { $extend: "tooltip" },
      "tutorial-dark": { $extend: "tooltip" },
      "dropdown-light": { $extend: "dropdown" },
      "dropdown-dark": { $extend: "dropdown" },
    },
  });
  app.use(vuetify);

  // Print errors to console
  // eslint-disable-next-line no-console
  app.config.errorHandler = console.error;
  app.config.warnHandler = (msg, vm, trace) => {
    // TODO popper remove when we switch
    if (msg.startsWith("Invalid prop") && trace.startsWith("at <DropdownMenu>")) {
      return;
    }
    // eslint-disable-next-line no-console
    console.warn(msg, vm, trace);
  };

  const appStore = useAppStore();
  const environmentStore = useEnvironmentStore();
  const pageStore = usePageStore();
  const userStore = useUserStore();

  provideComponents(app);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/serviceWorker.js");
  }

  environmentStore.setEnvironment(window.location);
  pageStore.setManualConsoleLogging(pageStore.isMobile);
  pageStore.updateTheme();
  pageStore.runTrackingTools = RUN_DEBUG_TOOLS_LOCALLY || !environmentStore.isLocal;

  // Warn about inherited attributes
  if (!environmentStore.isProd) {
    const ALLOWED_INHERITED_ATTRS = new Set([
      "class",
      "style",
      "dataTestid",
      "data-testid",
      "dataToolbar",
      "data-toolbar",
      "dataGroup",
      "data-group",
      "onClick",
      "onKeydown",
      "onContextmenu",
      // TODO Remove these two below, used by TaskClickWrapper.vue
      "oncontextmenu",
      "onclick",
      // Used by Chips.vue
      "show-text",
      // Provided by MultiselectDropdown.vue
      "onSelect",
    ]);
    app.mixin({
      created() {
        const inheritedAttrsEntries = Object.entries(this.$attrs).filter(
          ([key, value]) => value !== undefined && !ALLOWED_INHERITED_ATTRS.has(key)
        );

        if (
          Object.keys(inheritedAttrsEntries).length === 0 ||
          this.$options.__file === undefined ||
          this.$options.__name === undefined
        ) {
          return;
        }

        // eslint-disable-next-line no-console
        console.warn(
          "Inherited attributes",
          inheritedAttrsEntries.map(([key, value]) => `${key}=${value}`).join(", "),
          `are not supported in the ${this.$options.__name} (${this.$options.__file}) component`
        );
      },
    });
  }

  lsListen(USER_DUID_LOCAL_STORAGE_KEY, (newDuid) => {
    if (newDuid === userStore.duid) {
      return;
    }
    // TODO just update user rather than hard refreshing whole app
    window.location.reload();
  });

  const collectAllData = lsGet(COLLECT_ALL_DATA_KEY) === "true";

  const initSentry = (appInner: typeof app, innerRouter: Router) => {
    if (!pageStore.runTrackingTools) {
      return;
    }

    Sentry.init({
      app: appInner,
      dsn: "https://d7e102a310f442f296aad9df905d1c30@o1178417.ingest.sentry.io/6290355",
      tunnel: "/api/v0/tracking/sentry",
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      release: environmentStore.backendEnvironment?.hash,
      environment: environmentStore.name,
      tracePropagationTargets: [
        /^\/api/,
        /https:\/\/app\.itsdart\.com\/api/,
        /https:\/\/stag\.itsdart\.com\/api/,
        "localhost",
      ],
      integrations: [
        Sentry.browserTracingIntegration({ router: innerRouter }),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
        Sentry.captureConsoleIntegration({
          levels: ["warning", "error"],
        }),
      ],
      attachStacktrace: true,
      tracesSampleRate: collectAllData ? 1.0 : 0.1,
      replaysSessionSampleRate: collectAllData ? 1.0 : 0.2,
      replaysOnErrorSampleRate: 1.0,
      beforeSend: (event) => ({
        ...event,
        release: environmentStore.backendEnvironment?.hash,
      }),
    });
  };

  const initDatadog = () => {
    if (!pageStore.runTrackingTools) {
      return;
    }

    datadogRum.init({
      applicationId: "b7a5ca08-e39c-4481-b242-1af238c7baa7",
      clientToken: "pub255232d4b468b83f00f2054ea5279c20",
      site: "us3.datadoghq.com",
      proxy: `${environmentStore.urlBase}/api/v0/tracking/datadog`,
      service: "dart",
      env: environmentStore.name,
      version: environmentStore.backendEnvironment?.hash,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
      sessionReplaySampleRate: collectAllData ? 100 : 20,
    });
    datadogRum.startSessionReplayRecording();
  };

  LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
  initCommandBar("01713826");
  window.CommandBar.addRouter((path: string) => router.push({ path }));

  registerGlobalEventListeners(userStore);

  initSentry(app, router);
  initDatadog();

  app.use(router);
  initRouterForUtils(router);

  app.mount("#app");

  const initUserData = async (mode: UserDataMode, path?: string) => {
    const { data } = await backendOld.getUserData(mode, path);
    if (mode === UserDataMode.EXTRA) {
      actions.app.setExtraUserData(data);
      return;
    }
    actions.app.setUserData(data);
  };

  const initData = async () => {
    await requestManager.init();

    await router.isReady();

    backendOld.createEvent(EventKind.LOAD_APP, {
      path: router.currentRoute.value.fullPath,
      agent: pageStore.agentToDict,
    });

    const userDataMode = getUserDataMode();
    const initialPromises = [backendOld.setCsrf()];
    if (!pageStore.isPublic) {
      initialPromises.push(initUserData(userDataMode, router.currentRoute.value.path));
    }
    await Promise.all(initialPromises);

    actions.app.watchForIdle();
    appStore.connectWs(actions.websockets.getManager());

    // Get all extra data and set it once page is loaded initially with only base data
    if (pageStore.isLoggedIn && !pageStore.isPublic && userDataMode !== UserDataMode.ALL) {
      initUserData(UserDataMode.EXTRA);
    }

    window.addEventListener("beforeunload", (event: BeforeUnloadEvent) => {
      if (!appStore.getWorking()) {
        return undefined;
      }

      const message = "Changes you made may not be saved.";
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;
      return message;
    });

    const currRoute = router.currentRoute.value;
    const navGoal = checkNavigationRules(currRoute);
    if (navGoal === true) {
      pageStore.userDataLoaded = true;
      return;
    }
    run(async () => {
      await router.push(navGoal);
      pageStore.userDataLoaded = true;
    });
    if (!("name" in navGoal)) {
      return;
    }
    const destRoute = router.getRoutes().find((e) => e.name === navGoal.name);
    if (!destRoute) {
      return;
    }
    finalizeNavigation(destRoute.meta);
  };

  initData();

  /* Set up desktop window search */
  if (pageStore.isDesktopApp) {
    window.addEventListener("keydown", (event) => {
      if ((pageStore.isMac ? event.metaKey : event.ctrlKey) && event.key === "f") {
        DESKTOP.toggleSearch(true, pageStore.theme === "dark");
      }
    });
  }

  const redirectToNativeMaybe = async () => {
    if (!pageStore.isNativeApp && userStore.openInNativeApp) {
      const currentPath = window.location.href.replace(window.location.origin, "");
      if (currentPath !== "/") {
        const openedInNative = await openPathInDesktop(currentPath);
        pageStore.redirectedToNative = openedInNative;
      }
    }
  };

  // TODO Figure out the right place to put this. It gets overwritten by other route changes. https://github.com/its-dart/dart/pull/1284#discussion_r1178626949 https://app.itsdart.com/t/-ySdjFb9i7wD
  setTimeout(() => {
    redirectToNativeMaybe();
  }, 100);

  callDesktop(() => {
    listenDesktop(DESKTOP.onChangeRoute, (path) => {
      router.push(path);
    });

    router.beforeEach((to) => {
      DESKTOP.onRouteChanged(to.path);
    });
  });
})();
