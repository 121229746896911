<script setup lang="ts">
import moment from "moment";
import { DatePicker as DatePickerExternal } from "v-calendar";
import { ref, watch } from "vue";

import { usePageStore, useUserStore } from "~/stores";
import { isBrowserLocale24h } from "~/utils/time";

const pageStore = usePageStore();
const userStore = useUserStore();

const props = defineProps<{
  value: string;
  intervalMin?: number;
}>();

const emit = defineEmits<{
  select: [selectedDate: string];
}>();

const model = ref(moment(props.value).toDate());
watch(
  () => props.value,
  (newValue) => {
    model.value = moment(newValue).toDate();
  }
);
</script>

<template>
  <DatePickerExternal
    v-model="model"
    mode="time"
    color="indigo"
    hide-time-header
    :rules="intervalMin ? { minutes: { interval: intervalMin } } : undefined"
    :is-dark="pageStore.theme === 'dark'"
    :is24hr="isBrowserLocale24h()"
    :first-day-of-week="(userStore.firstDayOfWeek ?? 1) + 1"
    :style="{ fontFamily: 'Inter var' }"
    @update:model-value="emit('select', model.toISOString())" />
</template>

<style>
.vc-time-picker {
  @apply py-0;
}

.vc-time-picker svg {
  @apply hidden;
}

.vc-time-select-group {
  @apply border-0 bg-transparent px-0;
}

.vc-base-select select {
  @apply rounded border-0 px-0.5 text-center text-sm tabular-nums text-lt focus-ring-none hover:bg-hvy;
}

.vc-time-colon {
  @apply text-vlt;
}
</style>
