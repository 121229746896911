<script setup lang="ts">
import { useDocumentVisibility, useWindowFocus } from "@vueuse/core";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import { TrialStartedIllustration } from "~/illustrations";
import { BillingPeriod, ButtonStyle, ModalWidth, TutorialName, UserRole } from "~/shared/enums";
import { useAppStore, useTenantStore, useUserStore } from "~/stores";
import { checkout } from "~/utils/billing";

const router = useRouter();
const appStore = useAppStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const visibility = useDocumentVisibility();
const windowFocus = useWindowFocus();

const onUnidle = () => {
  if (!appStore.trialStartedSuppressed) {
    return;
  }
  appStore.setTrialStartedSuppressed(false);
};

watch(
  () => visibility.value,
  (newVisibility) => {
    if (newVisibility === "hidden") {
      return;
    }
    onUnidle();
  }
);
watch(
  () => windowFocus.value,
  (newFocus) => {
    if (!newFocus) {
      return;
    }
    onUnidle();
  }
);

const upgradeWorking = ref(false);

const upgrade = () => {
  upgradeWorking.value = true;
  checkout(router.currentRoute.value.fullPath, BillingPeriod.ANNUALLY);
};

const finish = () => userStore.updateTutorialStatuses([{ name: TutorialName.TRIAL_STARTED, status: 2 }]);

if (!userStore.isRoleGreaterOrEqual(UserRole.ADMIN) || tenantStore.isPremiumWithoutTrial) {
  finish();
}
</script>

<template>
  <Modal
    :entity="appStore.trialStartedModalOpen"
    title="You got Dart Premium, including Dart AI—free for 14 days! 🎁"
    emphasize-title
    :width="ModalWidth.M"
    @close="finish">
    <template #default>
      <div class="flex flex-col gap-6 py-10 text-md">
        <TrialStartedIllustration class="size-60 self-center" />
        <p>
          We've upgraded you to a free two week trial of the Premium plan. Try out all of Dart's features before
          deciding which plan is right for you.
        </p>
        <p>At the end of your trial, we'll automatically move you to the Free plan unless you choose to upgrade.</p>
      </div>
    </template>
    <template #actions>
      <div class="flex w-full gap-2">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          text="Upgrade now"
          borderless
          block
          :working="upgradeWorking"
          @click="upgrade" />
        <Button :btn-style="ButtonStyle.PRIMARY" text="Get started!" block @click="finish" />
      </div>
    </template>
  </Modal>
</template>
