<script setup lang="ts">
import equal from "deep-equal";
import type { SerializedEditorState } from "lexical";
import {
  LexicalAutoLinkPlugin,
  LexicalLinkPlugin,
  LexicalListPlugin,
  LexicalMarkdownShortcutPlugin,
  LexicalTabIndentationPlugin,
} from "lexical-vue";
import { computed, ref } from "vue";

import { EditorMode, TaskDetailMode } from "~/shared/enums";
import type { Task, TaskUpdate } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";

import { TASK_DETAIL_TELEPORT_KEY, TCM_TELEPORT_KEY, URL_MATCHERS } from "./const";
import AttachmentPlugin from "./plugins/AttachmentPlugin.vue";
import CheckListPlugin from "./plugins/CheckListPlugin.vue";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin.vue";
import CodeSelectorPlugin from "./plugins/CodeSelectorPlugin.vue";
import EnterPlugin from "./plugins/EnterPlugin.vue";
import FloatingToolbar from "./plugins/FloatingToolbar.vue";
import LinkPlugin from "./plugins/LinkPlugin.vue";
import ManageTextFormatPlugin from "./plugins/ManageTextFormatPlugin.vue";
import PasteFilesEventPlugin from "./plugins/PasteFilesEventPlugin.vue";
import PasteUrlPlugin from "./plugins/PasteUrlPlugin.vue";
import PlaceholderPlugin from "./plugins/PlaceholderPlugin.vue";
import RecommendationPlugin from "./plugins/RecommendationPlugin.vue";
import RelationshipPlugin from "./plugins/RelationshipPlugin.vue";
import ShortcutsPlugin from "./plugins/ShortcutsPlugin.vue";
import SplitPlugin from "./plugins/SplitPlugin.vue";
import SubscribeEventPlugin from "./plugins/SubscribeEventPlugin.vue";
import TextSavePlugin from "./plugins/TextSavePlugin.vue";
import { EntityTypeaheadPlugin, RecommendationTypeaheadPlugin } from "./plugins/typeahead";
import TextEditor from "./TextEditor.vue";
import { TRANSFORMERS } from "./transformers";
import { FULL_DART_EDITOR_NODES } from "./utils";

type Update = { old: string; new: string };

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  actionEnter: [event: KeyboardEvent];
  pasteFiles: [value: File[]];
  linkChanges: [creates: string[], destroys: string[], updates: Update[]];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const textEditor = ref<InstanceType<typeof TextEditor> | null>(null);
const recommendationPlugin = ref<InstanceType<typeof RecommendationPlugin> | null>(null);
const splitPlugin = ref<InstanceType<typeof SplitPlugin> | null>(null);

const namespace = computed(() => `description-${EditorMode[props.editorMode].toLowerCase()}`);

const initialState = computed(() => props.task.description);

const hasFocus = computed(() => !!textEditor.value?.hasFocus);

const saveLocally = async (duid: string, content: SerializedEditorState) => {
  const oldSubscriberDuids = props.task.subscriberDuids;
  await dataStore.updateTasks([{ duid, description: content }], { noUndo: true, noBackend: true });
  const sameSubscribers = equal(props.task.subscriberDuids, oldSubscriberDuids, { strict: true });
  if (sameSubscribers) {
    return;
  }

  const update: TaskUpdate = { duid };
  if (!sameSubscribers) {
    update.subscriberDuids = props.task.subscriberDuids;
  }
  dataStore.updateTasks([update], { noUndo: true });
};

const enterFilter = (event: KeyboardEvent) => (pageStore.isMac ? event.metaKey : event.ctrlKey);

const openRecommendations = () => recommendationPlugin.value?.openRecommendations();

const focus = () => textEditor.value?.focus();

const getTextContent = () => textEditor.value?.getTextContent();

const setLexicalContent = (newDescription: SerializedEditorState) => textEditor.value?.setEditorState(newDescription);

const trim = () => splitPlugin.value?.trim();

defineExpose({
  openRecommendations,
  focus,
  isReady: computed(() => textEditor.value?.isReady ?? false),
  getTextContent,
  setLexicalContent,
  trim,
});
</script>

<template>
  <TextEditor
    ref="textEditor"
    :namespace="namespace"
    :nodes="FULL_DART_EDITOR_NODES"
    :initial-state="initialState"
    :placeholder="`Add a description${pageStore.isOnline ? ', / for AI' : ''}`"
    extra-rounded
    :min-height-px="200"
    always-borderless
    :collaboration="{ namespace: 'description', id: task.duid }">
    <template #default="{ history }">
      <!-- lexical-vue plugins -->
      <LexicalAutoLinkPlugin :matchers="URL_MATCHERS" />
      <LexicalLinkPlugin />
      <LexicalListPlugin />
      <LexicalMarkdownShortcutPlugin :transformers="TRANSFORMERS" />
      <LexicalTabIndentationPlugin />
      <!-- custom plugins -->
      <AttachmentPlugin />
      <CheckListPlugin
        :handle-checkbox-clicks="
          editorMode === EditorMode.TCM ||
          (editorMode === EditorMode.DETAIL && appStore.taskDetailMode === TaskDetailMode.OVERLAY) ||
          appStore.feedbackModalOpen
        " />
      <CodeHighlightPlugin />
      <CodeSelectorPlugin editable />
      <EnterPlugin :filter="enterFilter" @enter="(event) => emit('actionEnter', event)" />
      <EntityTypeaheadPlugin :has-focus="hasFocus" mentions-only />
      <LinkPlugin
        :history="history"
        @link-changes="
          (creates: string[], destroys: string[], updates: Update[]) => emit('linkChanges', creates, destroys, updates)
        " />
      <ManageTextFormatPlugin />
      <PasteUrlPlugin />
      <PlaceholderPlugin />
      <SplitPlugin ref="splitPlugin" :task="task" />
      <RelationshipPlugin :task="task" />
      <SubscribeEventPlugin :task="task" />
      <PasteFilesEventPlugin @paste-files="(files) => emit('pasteFiles', files)" />
      <RecommendationPlugin ref="recommendationPlugin" :duid="task.duid" />
      <RecommendationTypeaheadPlugin />
      <ShortcutsPlugin />
      <TextSavePlugin
        :duid="task.duid"
        :content="task.description"
        :has-focus="hasFocus"
        immediate
        @save="saveLocally" />
      <!-- toolbars -->
      <FloatingToolbar
        :teleport-key="editorMode === EditorMode.TCM ? TCM_TELEPORT_KEY : TASK_DETAIL_TELEPORT_KEY"
        show-on-scroll />
    </template>
  </TextEditor>
</template>
