<script setup lang="ts">
import { computed } from "vue";

import { ArrowRightIcon, CalendarIcon } from "~/icons";
import { getRelativeTimeForDatesDate } from "~/utils/time";

const props = defineProps<{
  values: (string | null)[];
}>();

const start = computed(() => (props.values[0] ? getRelativeTimeForDatesDate(props.values[0]) : null));
const isRange = computed(() => props.values.length === 2);
const end = computed(() => (props.values[1] ? getRelativeTimeForDatesDate(props.values[1]) : null));
</script>

<template>
  <div
    class="flex h-5 min-h-5 select-none items-center justify-center rounded border border-primary-base bg-primary-base/30 px-1 py-0.5 text-xs text-md">
    <CalendarIcon class="mr-1 shrink-0 text-primary-base icon-sm" />
    <div class="mr-0.5 flex items-center gap-1">
      <span v-if="start">{{ start }}</span>
      <ArrowRightIcon v-if="isRange && (start || end)" class="size-3 shrink-0" />
      <span v-if="end">{{ end }}</span>
    </div>
  </div>
</template>
