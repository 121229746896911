<script setup lang="ts">
import type { SerializedEditorState } from "lexical";
import moment from "moment";
import { computed, nextTick, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import { notify } from "~/components/notifications";
import { DART_AGENT_HANDLE, DEVIN_AGENT_HANDLE, FIGMA_AGENT_HANDLE, GITHUB_AGENT_HANDLE } from "~/constants/user";
import { RecommendWithAiIcon } from "~/icons";
import {
  ButtonStyle,
  IconMode,
  LayoutKind,
  ModalWidth,
  NumberFormat,
  PropertyKind,
  RelationshipKindKind,
  StatusKind,
  SubtaskDisplayMode,
  TaskDetailMode,
  TaskLinkKind,
  TaskSourceType,
} from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore, useUserStore } from "~/stores";
import { conditionMet, makeDuid, timeout } from "~/utils/common";
import { downTheLine } from "~/utils/fun";
import { getOrdersBetween } from "~/utils/orderManager";

const DESIGN_DESCRIPTION = {
  root: {
    children: [
      {
        children: [
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Here are the designs in Figma",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "link",
            version: 1,
            rel: null,
            target: null,
            title: null,
            url: "https://www.figma.com/design/OmxGOeXzaRfA6qpTcTuLDw/Notifications?node-id=0-1&t=5iu1Wefgpj579ffY-0",
          },
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: ".",
            type: "text",
            version: 1,
          },
        ],
        direction: null,
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
      },
    ],
    direction: null,
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
} as unknown as SerializedEditorState;
const makeDesignComment = (assigneesPropertyDuid: string, userDuid: string) =>
  ({
    root: {
      children: [
        {
          children: [
            {
              type: "entity",
              propertyDuid: assigneesPropertyDuid,
              value: userDuid,
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: " what do you think about the designs?",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
          textFormat: 0,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  }) as unknown as SerializedEditorState;
const APPROVE_DESCRIPTION = {
  root: {
    children: [
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "Take a look at ",
            type: "text",
            version: 1,
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "the PR in GitHub",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "link",
            version: 1,
            rel: null,
            target: null,
            title: null,
            url: "https://github.com/its-dart/dart/pull/1063",
          },
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: " and make sure everything looks good.",
            type: "text",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
      },
      {
        children: [],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
      },
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "Typically we're looking for:",
            type: "text",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
      },
      {
        children: [
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Does the feature work as expected?",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "listitem",
            version: 1,
            value: 1,
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Does the code look good?",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "listitem",
            version: 1,
            value: 2,
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Does the design look good?",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "listitem",
            version: 1,
            value: 3,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "list",
        version: 1,
        listType: "check",
        start: 1,
        tag: "ul",
      },
      {
        children: [],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
      },
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "When you're happy with it, mark this task as Done and we'll finish up!",
            type: "text",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
      },
    ],
    direction: "ltr",
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
} as unknown as SerializedEditorState;

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const subjectRef = ref<HTMLTextAreaElement | null>(null);
const subject = ref("");
const actionDisabled = computed(() => subject.value === "");

const currDemoId = ref<string | null>();

const onClose = () => {
  appStore.setAgentDemoModalOpen(false);
};

const onStart = async () => {
  const title = subject.value;
  onClose();

  // SETUP VARIABLES
  const demoId = makeDuid();
  currDemoId.value = demoId;

  // hack because users don't live sync
  const newUsers = await backendOld.agentDemo.ensureAgentsAdmin();
  dataStore.$setUsers(newUsers.data.users);

  const tenantDuid = tenantStore.duid;
  const dartboardDuid = appStore.currentDartboardDuid;

  const getAgent = (handle: string) => dataStore.getUserByEmail(`${handle}.agent+${tenantDuid}@itsdart.com`)?.duid;
  const dartAgentDuid = getAgent(DART_AGENT_HANDLE);
  const figmaAgentDuid = getAgent(FIGMA_AGENT_HANDLE);
  const devinAgentDuid = getAgent(DEVIN_AGENT_HANDLE);
  const githubAgentDuid = getAgent(GITHUB_AGENT_HANDLE);

  if (!dartboardDuid || !dartAgentDuid || !figmaAgentDuid || !devinAgentDuid || !githubAgentDuid) {
    throw new Error("Demo error");
  }

  const parentKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
  const blockerKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.BLOCKS).duid;

  const assigneesPropertyDuid = dataStore.defaultAssigneesProperty.duid;

  const startedKind = StatusKind.STARTED;
  const blockedKind = StatusKind.BLOCKED;
  const statusProperty = dataStore.defaultStatusProperty;
  let inProgressDuid = dataStore.getStatusesByKinds([startedKind], statusProperty)[0]?.duid;
  if (!inProgressDuid) {
    inProgressDuid = (await dataStore.createStatus(startedKind, statusProperty, "1", { title: "In progress" })).duid;
  }
  let inReviewDuid = dataStore.getStatusesByKinds([blockedKind], statusProperty)[0]?.duid;
  if (!inReviewDuid) {
    inReviewDuid = (await dataStore.createStatus(blockedKind, statusProperty, "1", { title: "In review" })).duid;
  }
  const doneDuid = dataStore.defaultDefaultFinishedStatus.duid;

  const { propertyList } = dataStore;
  let progressPropertyDuid = propertyList.filter(
    (e) => e.kind === PropertyKind.NUMBER && e.adtl.format === NumberFormat.PERCENTAGE
  )[0]?.duid;
  if (!progressPropertyDuid) {
    const order = getOrdersBetween(propertyList[propertyList.length - 1]?.order, undefined)[0];
    progressPropertyDuid = (
      await dataStore.createProperty(PropertyKind.NUMBER, order, {
        title: "Progress",
        hidden: true,
        adtl: { format: NumberFormat.PERCENTAGE },
      })
    ).duid;
  }

  const totalDays = 8;
  const day0 = moment().startOf("day").add(9, "hours").toISOString();
  const day1 = moment().startOf("day").add(9, "hours").add(1, "day").toISOString();
  const day3 = moment().startOf("day").add(9, "hours").add(3, "day").toISOString();
  const day6 = moment().startOf("day").add(9, "hours").add(6, "day").toISOString();
  const day7 = moment().startOf("day").add(9, "hours").add(7, "day").toISOString();
  const day8 = moment().startOf("day").add(9, "hours").add(8, "day").toISOString();

  // SETUP VIEW
  const oldTasks = dataStore.getTasksByDartboardDuidOrdered(dartboardDuid);
  if (oldTasks.length > 0) {
    dataStore.updateTasks(oldTasks.map((e) => ({ duid: e.duid, inTrash: true })));
  }
  appStore.setLayoutKind(LayoutKind.ROADMAP);
  appStore.setTaskDetailOpen(false);
  userStore.setTaskDetailMode(TaskDetailMode.OVERLAY);
  const INCLUDED_PROPERTY_KINDS = new Set([
    PropertyKind.DEFAULT_TITLE,
    PropertyKind.DEFAULT_STATUS,
    PropertyKind.DEFAULT_ASSIGNEES,
  ]);
  dataStore.propertyList.forEach((e) => {
    appStore.setPropertyDuidVisibility(e.duid, INCLUDED_PROPERTY_KINDS.has(e.kind) || e.duid === progressPropertyDuid);
  });
  appStore.setRoadmapZoom(62);
  appStore.setRoadmapTaskListWidthPx(670);
  appStore.resetFilters();
  appStore.resetSorts();
  userStore.setShowFilters(false);
  appStore.setSubtaskDisplayMode(SubtaskDisplayMode.INDENTED);
  appStore.setColorBy(statusProperty.duid);

  await nextTick();
  const visualization = appStore.getActiveVisualization();

  // DEFINE FUNCTIONS
  const randTimeout = (minS: number, maxS: number) =>
    timeout(Math.floor(Math.random() * (maxS * 1000 - minS * 1000 + 1) + minS * 1000));

  const createTask = async (
    taskTitle: string,
    order: string,
    startAt: string,
    dueAt: string,
    assigneeDuid: string | undefined,
    statusDuid: string | undefined,
    parentDuid: string | undefined,
    blockerDuid: string | undefined,
    progress: number | undefined
  ) => {
    if (currDemoId.value !== demoId) {
      throw new Error("Demo finished early");
    }
    const partial: Partial<Task> = { startAt, dueAt, relationships: [] };
    if (assigneeDuid !== undefined) {
      partial.assigneeDuids = [assigneeDuid];
    }
    if (statusDuid !== undefined) {
      partial.statusDuid = statusDuid;
    }
    if (parentDuid !== undefined) {
      partial.relationships?.push({
        duid: makeDuid(),
        kindDuid: parentKindDuid,
        targetDuid: parentDuid,
        isForward: false,
      });
    }
    if (blockerDuid !== undefined) {
      partial.relationships?.push({
        duid: makeDuid(),
        kindDuid: blockerKindDuid,
        targetDuid: blockerDuid,
        isForward: false,
      });
    }
    if (progress !== undefined) {
      partial.properties = { [progressPropertyDuid]: progress };
    }
    return dataStore.createTask(taskTitle, dartboardDuid, order, TaskSourceType.RECOMMENDATION, partial);
  };

  const updateTaskStatus = (duid: string, statusDuid: string) => {
    if (currDemoId.value !== demoId) {
      throw new Error("Demo finished early");
    }
    dataStore.updateTasks([{ duid, statusDuid }], { noCelebrate: true });
  };

  const updateTaskProgress = (
    duid: string,
    rootDuid: string,
    progress: number,
    multiplier: number,
    initRootProgress: number
  ) =>
    dataStore.updateTasks([
      { duid, properties: { [progressPropertyDuid]: progress } },
      {
        duid: rootDuid,
        properties: {
          [progressPropertyDuid]: Math.min(initRootProgress + Math.round((progress * multiplier) / totalDays), 99),
        },
      },
    ]);

  const workThroughTaskProgress = async (duid: string, rootDuid: string, multiplier: number) => {
    if (currDemoId.value !== demoId) {
      throw new Error("Demo finished early");
    }
    const initRootProgress = (dataStore.getTaskByDuid(rootDuid)?.properties[progressPropertyDuid] ?? 0) as number;
    let progress = 0;
    dataStore.updateTasks([{ duid, properties: { [progressPropertyDuid]: progress } }]);
    while (progress !== 100) {
      // eslint-disable-next-line no-await-in-loop
      await randTimeout(0.5 * multiplier, 2 * multiplier);
      progress = Math.min(progress + Math.floor(Math.random() * 9) * 5 + 10, 100);
      updateTaskProgress(duid, rootDuid, progress, multiplier, initRootProgress);
    }
  };

  // RUN PROCESS
  const rootDuid = (await createTask(title, "1", day0, day8, undefined, inProgressDuid, undefined, undefined, 0)).duid;
  const planDuid = (
    await createTask("Plan and spec", "a", day0, day1, dartAgentDuid, inProgressDuid, rootDuid, undefined, 20)
  ).duid;
  await randTimeout(1, 2);
  const designTask = await createTask(
    "Create the designs",
    "b",
    day1,
    day3,
    figmaAgentDuid,
    undefined,
    rootDuid,
    planDuid,
    undefined
  );
  const designDuid = designTask.duid;
  updateTaskProgress(planDuid, rootDuid, 40, 1, 0);
  await randTimeout(1, 2);
  const implementDuid = (
    await createTask("Build it!", "c", day3, day6, devinAgentDuid, undefined, rootDuid, designDuid, undefined)
  ).duid;
  updateTaskProgress(planDuid, rootDuid, 60, 1, 0);
  await randTimeout(1, 2);
  const approveTask = await createTask(
    "Approve the updates",
    "d",
    day6,
    day7,
    undefined,
    undefined,
    rootDuid,
    implementDuid,
    undefined
  );
  const approveDuid = approveTask.duid;
  updateTaskProgress(planDuid, rootDuid, 80, 1, 0);
  await randTimeout(1, 2);
  const releaseDuid = (
    await createTask("Release", "e", day7, day8, githubAgentDuid, undefined, rootDuid, approveDuid, undefined)
  ).duid;
  updateTaskProgress(planDuid, rootDuid, 100, 1, 0);
  updateTaskStatus(planDuid, doneDuid);

  updateTaskStatus(designDuid, inProgressDuid);
  dataStore.updateTasks([
    {
      duid: designDuid,
      description: DESIGN_DESCRIPTION,
    },
  ]);
  dataStore.addLink(
    designDuid,
    TaskLinkKind.STANDARD,
    "https://www.figma.com/design/OmxGOeXzaRfA6qpTcTuLDw/Notifications?node-id=0-1&t=5iu1Wefgpj579ffY-0",
    "Notifications designs"
  );
  const designCommentContent = makeDesignComment(assigneesPropertyDuid, userStore.duid);
  const designComment = await dataStore.createComment(designTask, designCommentContent, undefined, {
    awaitBackend: true,
  });
  if (!designComment) {
    throw new Error("Demo error");
  }
  designComment.authorDuid = figmaAgentDuid;
  dataStore.updateComment(designComment.duid, designCommentContent, true);
  await workThroughTaskProgress(designDuid, rootDuid, 2);
  updateTaskStatus(designDuid, inReviewDuid);
  notify({
    message: "The designs are ready. Take a look!",
    indefinite: true,
    actions: [
      {
        label: "Review the designs",
        onClick: async (dismiss) => {
          dismiss();
          visualization.selectAndScrollTo(designDuid);
          appStore.setTaskDetailOpen(true);
          await nextTick();
          router.replace({ query: { ...router.currentRoute.value.query, c: designComment.duid } });
        },
      },
    ],
  });
  const designComments = computed(() => dataStore.getCommentsByTaskDuid(designDuid).filter((e) => !e.isDraft));
  await conditionMet(() => designTask.statusDuid === doneDuid || designComments.value.length > 1);
  appStore.setTaskDetailOpen(false);
  updateTaskStatus(designDuid, doneDuid);

  updateTaskStatus(implementDuid, inProgressDuid);
  await workThroughTaskProgress(implementDuid, rootDuid, 3);
  updateTaskStatus(implementDuid, doneDuid);
  dataStore.updateTasks([
    {
      duid: approveDuid,
      description: APPROVE_DESCRIPTION,
    },
  ]);
  dataStore.addLink(
    approveDuid,
    TaskLinkKind.STANDARD,
    "https://github.com/its-dart/dart/pull/1063",
    "Notifications GitHub PR"
  );
  updateTaskStatus(approveDuid, inProgressDuid);
  notify({
    message: "The code is finished—check it out!",
    indefinite: true,
    actions: [
      {
        label: "Review the code",
        onClick: (dismiss) => {
          dismiss();
          visualization.selectAndScrollTo(approveDuid);
          appStore.setTaskDetailOpen(true);
        },
      },
    ],
  });
  const approveComments = computed(() => dataStore.getCommentsByTaskDuid(approveDuid).filter((e) => !e.isDraft));
  await conditionMet(() => approveTask.statusDuid === doneDuid || approveComments.value.length > 0);
  appStore.setTaskDetailOpen(false);
  const rootProgressBeforeApproval = (dataStore.getTaskByDuid(rootDuid)?.properties[progressPropertyDuid] ??
    0) as number;
  updateTaskProgress(approveDuid, rootDuid, 100, 1, rootProgressBeforeApproval);
  updateTaskStatus(approveDuid, doneDuid);

  updateTaskStatus(releaseDuid, inProgressDuid);
  await workThroughTaskProgress(releaseDuid, rootDuid, 1);
  updateTaskStatus(releaseDuid, doneDuid);
  // update the parent JIC the automation is off
  updateTaskStatus(rootDuid, doneDuid);
  dataStore.updateTasks([{ duid: rootDuid, properties: { [progressPropertyDuid]: 100 } }]);

  downTheLine();
};

watch(
  () => appStore.agentDemoModalOpen,
  (open) => {
    if (open) {
      currDemoId.value = null;

      setTimeout(() => {
        subjectRef.value?.focus();
      }, 100);
      return;
    }
    subject.value = "";
  }
);
</script>

<template>
  <Modal
    :entity="appStore.agentDemoModalOpen"
    title="Create a project"
    description="AI agents will do this entire project, then you can approve their work."
    :title-icon="RecommendWithAiIcon"
    :icon-mode="IconMode.PRIMARY"
    :width="ModalWidth.M"
    @close="onClose">
    <template #default>
      <textarea
        ref="subjectRef"
        v-model="subject"
        aria-label="Subject"
        class="mt-4 min-h-20 w-full rounded border text-sm bg-std text-md border-md placeholder:text-vlt hover:border-hvy"
        placeholder="Write any engineering task or project"
        @keydown.enter.prevent="onStart" />
    </template>

    <template #actions>
      <Button
        :btn-style="ButtonStyle.RECOMMENDATION"
        text="Get started!"
        is-contrast
        :disabled="actionDisabled"
        @click="onStart" />
    </template>
  </Modal>
</template>
