import { DecoratorNode, type LexicalNode, type SerializedLexicalNode } from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import Attachment from "./Attachment.vue";

export type SerializedAttachmentNode = SerializedLexicalNode & {
  attachmentDuid: string;
  collapsed: boolean;
};

export class AttachmentNode extends DecoratorNode<Component> {
  __attachmentDuid: string;

  __collapsed: boolean;

  static getType(): string {
    return "attachment";
  }

  static clone(node: AttachmentNode): AttachmentNode {
    return new AttachmentNode(node.__attachmentDuid, node.__collapsed, node.__key);
  }

  constructor(attachmentDuid: string, collapsed: boolean, key?: string) {
    super(key);
    this.__attachmentDuid = attachmentDuid;
    this.__collapsed = collapsed;
  }

  getAttachmentDuid(): string {
    return this.__attachmentDuid;
  }

  getCollapsed(): boolean {
    return this.__collapsed;
  }

  setCollapsed(collapsed: boolean): void {
    const writable = this.getWritable();
    writable.__collapsed = collapsed;
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const element = document.createElement("div");
    element.classList.add("contents");
    return element;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedAttachmentNode): LexicalNode {
    return new AttachmentNode(serializedNode.attachmentDuid, serializedNode.collapsed);
  }

  exportJSON(): SerializedAttachmentNode {
    return {
      type: AttachmentNode.getType(),
      attachmentDuid: this.getAttachmentDuid(),
      collapsed: this.getCollapsed(),
      version: 1,
    };
  }

  decorate(): Component {
    return h(Attachment, {
      attachmentDuid: this.getAttachmentDuid(),
      collapsed: this.getCollapsed(),
      nodeKey: this.getKey(),
      // eslint-disable-next-line no-use-before-define
      isAttachmentNode: $isAttachmentNode,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): true {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }
}

export function $createAttachmentNode(attachmentDuid: string, collapsed: boolean): AttachmentNode {
  return new AttachmentNode(attachmentDuid, collapsed);
}

export function $isAttachmentNode(node?: LexicalNode): node is AttachmentNode {
  return node instanceof AttachmentNode;
}
