<script setup lang="ts">
import { useLexicalComposer } from "lexical-vue";
import { watch } from "vue";

import { EVENT_BLUR_OVERALL } from "../const";

const props = defineProps<{
  hasFocus: boolean;
}>();

const editor = useLexicalComposer();

const focus = (start?: boolean) => editor.focus(undefined, { defaultSelection: start ? "rootStart" : "rootEnd" });

watch(
  () => props.hasFocus,
  (newHasFocus) => {
    if (newHasFocus) {
      return;
    }
    editor.dispatchCommand(EVENT_BLUR_OVERALL, {});
  }
);

defineExpose({
  focus,
});
</script>

<template>
  <slot />
</template>
