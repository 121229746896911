/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Critical` - CRITICAL
 * * `High` - HIGH
 * * `Medium` - MEDIUM
 * * `Low` - LOW
 */
export enum Priority {
  CRITICAL = 'Critical',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}
