import { computed } from "vue";

import { UNASSIGNED_ASSIGNEE_LABEL } from "~/components/visualization/constants";
import { PRIORITY_OPTIONS, UNPRIORITIZED_PRIORITY_LABEL } from "~/constants/priority";
import { TSHIRT_SIZES, UNSIZED_SIZE_LABEL } from "~/constants/size";
import type { Priority, TshirtSize } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getPageDisplayName, intersectionOfAllSets, unionOfAllSets } from "~/utils/common";

import type { Actions } from "..";

type KeyLabel<T = string> = { key: T; label: string };

const UNASSIGNED_ASSIGNEE = { key: null, label: UNASSIGNED_ASSIGNEE_LABEL };

/** Getter related actions */
export default (actions: Actions) => ({
  // TODO for these, should we not include the currently selected options?
  getPossibleDartboards: (): KeyLabel[] => {
    const dataStore = useDataStore();
    return dataStore
      .getDartboardList()
      .map((e) => ({ key: e.duid, label: getPageDisplayName(e, dataStore.getSpaceByDuid) }));
  },
  getPossiblePriorities: (): KeyLabel<Priority | null>[] =>
    PRIORITY_OPTIONS.map((key) => ({ key, label: key?.toString() ?? UNPRIORITIZED_PRIORITY_LABEL })),
  getPossibleStatuses: (): string[] => {
    const dataStore = useDataStore();

    let currents;
    try {
      currents = actions.visualization.getCurrentRows();
    } catch (error) {
      return [];
    }
    if (currents.length === 0) {
      return [];
    }
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    const taskKinds = computed(() =>
      dataStore.getTaskKindsByDuidsOrdered([...new Set(tasks.map((task) => task.kindDuid))])
    );
    return dataStore.getStatusList(dataStore.defaultStatusProperty, taskKinds.value).map((e) => e.title);
  },
  getPossibleSizes: (): KeyLabel<TshirtSize | null>[] =>
    TSHIRT_SIZES.map((key) => ({ key, label: key?.toString() ?? UNSIZED_SIZE_LABEL })),
  copyTaskBranchNames: () => {
    actions.task.copyBranchNames(
      actions.visualization
        .getCurrentRows()
        .map((r) => r.data)
        .filter((e): e is Task => !!e)
    );
  },
  copyTaskLinks: () =>
    actions.task.copyLinks(
      actions.visualization
        .getCurrentRows()
        .map((e) => e.data)
        .filter((e): e is Task => !!e)
    ),
  getCurrentAssignees: () => {
    const dataStore = useDataStore();

    const userItems = computed(() => dataStore.getUserList().map((e) => ({ key: e.duid, label: e.name || e.email })));

    let currents;
    try {
      currents = actions.visualization.getCurrentRows();
    } catch (error) {
      return [];
    }
    if (currents.length === 0) {
      return [];
    }
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    const sharedAssigneeDuids = unionOfAllSets(tasks.map((e) => e.assigneeDuids).map((e) => new Set(e)));
    return userItems.value.filter((e) => sharedAssigneeDuids.has(e.key));
  },
  getCurrentTags: () => {
    const dataStore = useDataStore();

    let currents;
    try {
      currents = actions.visualization.getCurrentRows();
    } catch (error) {
      return [];
    }
    if (currents.length === 0) {
      return [];
    }
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    const sharedTags = unionOfAllSets(tasks.map((e) => e.tagDuids).map((e) => new Set(e)));
    return dataStore.getOptionsByDuidsOrdered([...sharedTags]).map((e) => e.title);
  },
  getPossibleAssignees: () => {
    const dataStore = useDataStore();
    const tenantStore = useTenantStore();

    let currents;
    try {
      currents = actions.visualization.getCurrentRows();
    } catch (error) {
      return [];
    }
    if (currents.length === 0) {
      return [];
    }
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    const sharedAssigneeDuids = intersectionOfAllSets(
      tasks
        .map((e) => (tenantStore.multipleAssigneesEnabled ? e.assigneeDuids : e.assigneeDuids?.slice(0, 1)))
        .map((e) => new Set(e))
    );

    const userItems = computed(() =>
      dataStore.getRelevantUsersByTasks(tasks).map((e) => ({ key: e.duid, label: e.name || e.email }))
    );

    // filter out only assignee(s) that are shared by all tasks
    const result: { key: string | null; label: string }[] = userItems.value.filter(
      (e) => e.key !== null && !sharedAssigneeDuids.has(e.key)
    );
    if (!tenantStore.multipleAssigneesEnabled && !tasks.every((e) => !e.assigneeDuids?.length)) {
      result.unshift(UNASSIGNED_ASSIGNEE);
    }
    return result;
  },
  getPossibleTags: () => {
    const dataStore = useDataStore();

    let currents;
    try {
      currents = actions.visualization.getCurrentRows();
    } catch (error) {
      return [];
    }
    if (currents.length === 0) {
      return [];
    }
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    const sharedTags = intersectionOfAllSets(tasks.map((e) => e.tagDuids).map((e) => new Set(e)));
    // filter out only tags that are shared by all tasks
    return dataStore
      .getOptionList(dataStore.defaultTagsProperty)
      .filter((e) => !sharedTags.has(e.duid))
      .map((e) => e.title);
  },
});
