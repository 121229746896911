<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-hidden="true">
    <path
      d="m 8.5,6 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z M 15.5,6 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z m 0,7 c 1.333,0 1.333,-2 0,-2 -1.333,0 -1.333,2 0,2 z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
