<script setup lang="ts">
import { $isCodeNode } from "@lexical/code";
import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  BLUR_COMMAND,
  COMMAND_PRIORITY_LOW,
  type LexicalNode,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

import { usePageStore } from "~/stores";
import { isLexicalStateEmpty } from "~/utils/common";

import { $isToggleDetailsNode } from "../nodes/ToggleDetailsNode";
import { $isToggleTitleNode } from "../nodes/ToggleTitleNode";
import { isEmpty } from "../utils";

const STANDARD_PLACEHOLDER_CLASSES = [
  "before:float-left",
  "before:text-vlt",
  "before:pointer-events-none",
  "before:h-0",
  "before:content-[attr(data-placeholder)]",
];

const props = defineProps<{
  noAi?: boolean;
}>();

const pageStore = usePageStore();

const aiAvailable = computed(() => pageStore.isOnline && !props.noAi);

const editor = useLexicalComposer();

const hasFocus = ref(false);
const htmlElementRef = ref<HTMLElement | null>(null);

const getPlaceholderTextForNode = (node: LexicalNode) => {
  const parent = node.getParent();

  if ($isToggleTitleNode(parent)) {
    return "Title for toggle section";
  }

  if ($isToggleDetailsNode(parent)) {
    return `Content for toggle section${aiAvailable.value ? ", type / for AI" : ""}`;
  }

  if ($isParagraphNode(node)) {
    return aiAvailable.value ? "Type / for AI" : "Write anything";
  }

  if ($isCodeNode(node)) {
    return "Code";
  }

  if ($isQuoteNode(node)) {
    return "Quote";
  }

  if ($isHeadingNode(node)) {
    return "Header";
  }

  return "";
};

const removePlaceholder = () => {
  if (!htmlElementRef.value) {
    return;
  }

  htmlElementRef.value?.removeAttribute("data-placeholder");
  htmlElementRef.value?.classList.remove(...STANDARD_PLACEHOLDER_CLASSES);
  htmlElementRef.value = null;
};

const managePlaceholder = () => {
  removePlaceholder();

  const nativeSelection = window.getSelection();
  const selection = $getSelection();

  if (!nativeSelection || !selection || !$isRangeSelection(selection)) {
    return;
  }

  // Prevent from showing when editor is empty
  if (isLexicalStateEmpty(editor.getEditorState().toJSON())) {
    return;
  }

  const node = selection.anchor.getNode();
  if (!isEmpty(node)) {
    return;
  }

  // Now switch over to the native selection to get the DOM element
  const htmlDomElement = nativeSelection.anchorNode;
  const placeholderText = getPlaceholderTextForNode(node);

  if (!htmlDomElement || !(htmlDomElement instanceof HTMLElement) || placeholderText === "") {
    return;
  }

  htmlElementRef.value = htmlDomElement;
  htmlElementRef.value.setAttribute("data-placeholder", placeholderText);
  htmlElementRef.value.classList.add(...STANDARD_PLACEHOLDER_CLASSES);
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerUpdateListener(({ editorState }) => {
      hasFocus.value = true;
      editorState.read(() => {
        managePlaceholder();
      });
    }),
    editor.registerCommand(
      BLUR_COMMAND,
      () => {
        hasFocus.value = false;
        removePlaceholder();
        return false;
      },
      COMMAND_PRIORITY_LOW
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
