<script setup lang="ts">
import moment from "moment";
import { capitalize, computed, ref } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import ProgressBar from "~/components/dumb/ProgressBar.vue";
import { DartUncolored } from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import { ButtonSize, ButtonStyle, Entitlement, TutorialName, UserRole } from "~/shared/enums";
import { useTenantStore, useUserStore } from "~/stores";
import { getItemCountText } from "~/utils/common";

const MS_PER_SEC = 1000;
const MS_PER_HOUR = MS_PER_SEC * 60 * 60;
const MS_PER_DAY = MS_PER_HOUR * 24;

defineProps<{
  isMobileLeftbar?: boolean;
}>();

const router = useRouter();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const openBilling = () => router.push(makeLinkToSettingsRef("billing").value);

const trialStarted = computed(
  () =>
    tenantStore.getEntitlementValue(Entitlement.TRIAL_DAYS) === 0 ||
    (userStore.getTutorialStatus(TutorialName.TRIAL_STARTED) ?? 2) > 1
);

const trialDurationDays = computed(() => tenantStore.getEntitlementValue(Entitlement.TRIAL_DAYS));
const trialDurationMs = computed(() => trialDurationDays.value * MS_PER_DAY);
const getTrialRemainingMs = () =>
  moment(tenantStore.createdAt).add(trialDurationDays.value, "days").diff(moment(), "milliseconds");
const trialRemainingMs = ref(getTrialRemainingMs());

const trialRemainingTextDays = computed(() => {
  const num = Math.ceil(trialRemainingMs.value / MS_PER_DAY);
  const text = getItemCountText(num, "day", { noSpecial: true });
  return capitalize(text);
});
const trialProgressPercentage = computed(() => 100 - Math.round((trialRemainingMs.value / trialDurationMs.value) * 94));

let timeout: ReturnType<typeof setTimeout> | undefined;
const resetValuesAndTimeout = () => {
  clearTimeout(timeout);
  if (!tenantStore.trialActive) {
    return;
  }

  trialRemainingMs.value = getTrialRemainingMs();
  tenantStore.updateClock();
  // pad the time a bit so it's into the next hour
  timeout = setTimeout(resetValuesAndTimeout, (trialRemainingMs.value % MS_PER_HOUR) + MS_PER_SEC);
};
resetValuesAndTimeout();
</script>

<template>
  <div
    v-if="
      userStore.isRoleGreaterOrEqual(UserRole.ADMIN) &&
      !tenantStore.isPremiumWithoutTrial &&
      trialStarted &&
      tenantStore.trialActive
    "
    class="mx-3 flex select-none flex-col rounded px-3 pb-2 pt-3"
    :class="isMobileLeftbar ? 'bg-lt' : 'bg-std'">
    <span class="mb-3 text-sm text-md">{{ trialRemainingTextDays }} left in trial</span>
    <ProgressBar :value="trialProgressPercentage" no-content class="h-2" />
    <div class="mt-1 flex w-full justify-end">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        text="Upgrade now!"
        borderless
        :is-contrast="isMobileLeftbar"
        :size="ButtonSize.SMALL"
        text-style="text-primary-base font-semibold"
        class="-mr-2 !px-2"
        @click="openBilling" />
    </div>
  </div>
  <Button
    v-else-if="trialStarted && !tenantStore.isPremium"
    :btn-style="ButtonStyle.SECONDARY"
    :icon="DartUncolored"
    :icon-args="{ class: 'text-hvy' }"
    text="Upgrade"
    borderless
    is-contrast
    :size="ButtonSize.LARGE"
    text-style="text-primary-base font-semibold"
    class="mx-3 !mt-2 grow"
    :class="isMobileLeftbar ? 'bg-lt' : 'bg-std'"
    @click="openBilling" />
</template>
