<script setup lang="ts">
import { Switch } from "@headlessui/vue";
import { ref, watch } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { XIcon } from "~/icons";
import { goBackOrHome } from "~/router/common";
import { CommandId, Placement } from "~/shared/enums";
import { usePageStore } from "~/stores";
import { isString } from "~/utils/common";

const TEXT_STYLE_BY_DATUM_TYPE = new Map(
  Object.entries({
    debug: "text-lt",
    log: "text-hvy",
    warn: "text-yellow-500",
    error: "text-danger-base",
  })
);

const pageStore = usePageStore();

(() => {
  const {
    isWindows,
    isUbuntu,
    isDesktop,
    isDesktopApp,
    isIos,
    isAndroid,
    isMobile,
    isMobileApp,
    isNativeApp,
    version,
  } = pageStore;
  const calculatedPlatformVars = {
    isWindows,
    isUbuntu,
    isDesktop,
    isDesktopApp,
    isIos,
    isAndroid,
    isMobile,
    isMobileApp,
    isNativeApp,
    version,
  };
  // eslint-disable-next-line no-console
  console.log(`Platform: ${JSON.stringify(pageStore.parsedUserAgent, null, 2)}`);
  // eslint-disable-next-line no-console
  console.log(`Calculated platform vars: ${JSON.stringify(calculatedPlatformVars, null, 2)}`);
})();

const manualConsoleLogging = ref(pageStore.manualConsoleLogging);
watch(
  () => pageStore.manualConsoleLogging,
  (newValue) => {
    manualConsoleLogging.value = newValue;
  }
);
watch(
  () => manualConsoleLogging.value,
  (newValue) => {
    pageStore.setManualConsoleLogging(newValue);
  }
);

const close = () => {
  goBackOrHome();
};
</script>

<template>
  <div class="flex h-screen w-screen flex-col bg-std">
    <div class="flex justify-between app-drag">
      <div
        class="flex items-center app-drag-none text-lt"
        :class="pageStore.isDesktopApp && pageStore.isMac && 'pl-20'">
        <div class="ml-5 mr-2">Log here</div>
        <Switch
          v-model="manualConsoleLogging"
          :class="[
            manualConsoleLogging ? 'bg-primary-base' : 'bg-md',
            'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-ring-std',
          ]"
          aria-label="Log here toggle">
          <span
            aria-hidden="true"
            :class="[
              manualConsoleLogging ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block rounded-full shadow ring-0 transition duration-200 ease-in-out bg-std icon-md',
            ]" />
        </Switch>
      </div>
      <div class="m-5 flex flex-col app-drag-none">
        <Tooltip :placement="Placement.LEFT" :command-id="CommandId.RESET_VIEW_CLOSE">
          <button
            type="button"
            class="flex max-w-lg items-center rounded-full text-sm text-vlt focus-ring-std hover:text-vlt"
            @click="close">
            <span class="sr-only">Close logs page</span>
            <XIcon class="icon-lg" />
          </button>
        </Tooltip>
      </div>
    </div>
    <div class="w-full flex-1 overflow-hidden font-mono text-xs">
      <div class="flex h-full flex-col-reverse overflow-y-auto border-t pb-10 border-md">
        <div
          v-for="(consoleDatum, index) in pageStore.consoleData.slice().reverse()"
          :key="index"
          class="flex border-b border-lt">
          <div class="mx-2 text-lt">
            {{ consoleDatum.datetime.toISOString().split("T")[1] }}
          </div>
          <div class="mx-2" :class="TEXT_STYLE_BY_DATUM_TYPE.get(consoleDatum.kind)">
            {{ consoleDatum.content.map((e: any) => (isString(e) ? e : JSON.stringify(e))).join(" ") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
