<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-hidden="true">
    <path
      d="M4.72137 10.092C4.08227 9.37801 3.76272 9.02099 3.75067 8.71757C3.7402 8.45399 3.85351 8.20065 4.05699 8.0327C4.29123 7.83936 4.77046 7.83936 5.72893 7.83936H18.2711C19.2295 7.83936 19.7088 7.83936 19.943 8.0327C20.1465 8.20065 20.2598 8.45399 20.2493 8.71757C20.2373 9.02099 19.9177 9.37801 19.2786 10.092L14.4569 15.4791C14.3295 15.6215 14.2658 15.6926 14.2204 15.7736C14.1801 15.8455 14.1506 15.9228 14.1327 16.0032C14.1125 16.0938 14.1125 16.1893 14.1125 16.3803V20.897C14.1125 21.0622 14.1125 21.1447 14.0858 21.2162C14.0623 21.2793 14.024 21.3358 13.9741 21.3811C13.9177 21.4324 13.841 21.463 13.6875 21.5244L10.8146 22.6732C10.504 22.7974 10.3487 22.8595 10.2241 22.8336C10.115 22.8109 10.0194 22.7462 9.95787 22.6534C9.88752 22.5473 9.88752 22.3801 9.88752 22.0457V16.3803C9.88752 16.1893 9.88752 16.0938 9.86735 16.0032C9.84945 15.9228 9.81989 15.8455 9.77961 15.7736C9.73419 15.6926 9.67049 15.6215 9.54309 15.4791L4.72137 10.092Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M14.9245 2.94124C14.8719 2.83222 14.7623 2.76228 14.6412 2.76056C14.5202 2.75885 14.4086 2.82567 14.3529 2.93316L14.0952 3.43094C14.0024 3.61019 13.9741 3.66175 13.9401 3.70464C13.906 3.74765 13.8664 3.78609 13.8225 3.819C13.7786 3.8518 13.7263 3.87858 13.5445 3.96629L13.0396 4.20986C12.9306 4.26246 12.8607 4.37214 12.8589 4.49317C12.8572 4.6142 12.924 4.72581 13.0315 4.78147L13.5293 5.03922C13.7086 5.13203 13.7601 5.16027 13.803 5.19431C13.846 5.22844 13.8845 5.26798 13.9174 5.31194C13.9502 5.35577 13.977 5.40811 14.0647 5.58992L14.3082 6.09479C14.3608 6.20381 14.4705 6.27375 14.5915 6.27546C14.7126 6.27717 14.8242 6.21036 14.8798 6.10287L15.1376 5.60509C15.2304 5.42584 15.2587 5.37427 15.2927 5.33139C15.3268 5.28837 15.3664 5.24994 15.4103 5.21703C15.4542 5.18422 15.5065 5.15745 15.6883 5.06974L16.1932 4.82617C16.3022 4.77357 16.3721 4.66389 16.3738 4.54286C16.3756 4.42183 16.3087 4.31022 16.2012 4.25456L15.7035 3.99681C15.5242 3.90399 15.4727 3.87575 15.4298 3.84172C15.3868 3.80758 15.3483 3.76805 15.3154 3.72408C15.2826 3.68025 15.2558 3.62791 15.1681 3.44611L14.9245 2.94124Z"
      fill="currentColor" />
    <path
      d="M10.6432 0.769501C10.5627 0.603959 10.3979 0.49939 10.2178 0.49939C10.0376 0.49939 9.87289 0.603959 9.79232 0.769501L9.41917 1.53613C9.2848 1.81219 9.24385 1.89164 9.1941 1.95794C9.1442 2.02444 9.08616 2.08406 9.02142 2.13532C8.95688 2.18643 8.87954 2.22849 8.6108 2.36652L7.86451 2.74984C7.70336 2.83261 7.60156 3.0018 7.60156 3.18688C7.60156 3.37197 7.70336 3.54116 7.86451 3.62393L8.6108 4.00725C8.87954 4.14528 8.95688 4.18734 9.02142 4.23845C9.08616 4.28971 9.1442 4.34933 9.1941 4.41583C9.24385 4.48213 9.2848 4.56158 9.41917 4.83764L9.79232 5.60427C9.87289 5.76981 10.0376 5.87438 10.2178 5.87438C10.3979 5.87438 10.5627 5.76981 10.6432 5.60427L11.0164 4.83764C11.1507 4.56158 11.1917 4.48213 11.2414 4.41583C11.2913 4.34933 11.3494 4.28971 11.4141 4.23845C11.4787 4.18734 11.556 4.14528 11.8247 4.00725L12.571 3.62393C12.7322 3.54116 12.834 3.37197 12.834 3.18688C12.834 3.0018 12.7322 2.83261 12.571 2.74984L11.8247 2.36652C11.556 2.22849 11.4787 2.18643 11.4141 2.13532C11.3494 2.08406 11.2913 2.02444 11.2414 1.95794C11.1917 1.89164 11.1507 1.81219 11.0164 1.53613L10.6432 0.769501Z"
      fill="currentColor" />
  </svg>
</template>
