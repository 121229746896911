<script setup lang="ts">
import { ref } from "vue";

import NewComment from "~/components/comments/NewComment.vue";
import Button from "~/components/dumb/Button.vue";
import { ChevronDownIcon } from "~/icons";
import { ButtonStyle, IconSize } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { usePageStore } from "~/stores";

defineProps<{
  task: Task;
  canScrollToBottom: boolean;
}>();

const emit = defineEmits<{
  editLastComment: [];
  scrollToBottom: [];
}>();

const pageStore = usePageStore();

const newComment = ref<InstanceType<typeof NewComment> | null>(null);

const focusNewComment = () => newComment.value?.focus();

defineExpose({
  focusNewComment,
});
</script>

<template>
  <div class="relative">
    <div
      class="absolute -top-11 right-5 transition-all duration-300"
      :class="[canScrollToBottom ? 'opacity-100' : 'pointer-events-none opacity-0']">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :icon="ChevronDownIcon"
        :icon-size="IconSize.L"
        class="rounded-full !p-1.5 bg-lt hover:bg-hvy dark:hover:bg-md print:hidden"
        a11y-label="Scroll to bottom"
        @click="emit('scrollToBottom')" />
    </div>
    <NewComment
      v-if="!pageStore.isPublicView && !task.inTrash"
      ref="newComment"
      :task="task"
      class="mb-2"
      @edit-last="emit('editLastComment')"
      @after-create="emit('scrollToBottom')" />
    <div v-else class="mb-2" />
  </div>
</template>
