<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import type { IHeaderParams } from "ag-grid-community";
import { computed, nextTick, onUnmounted, ref, watch } from "vue";

import { getFilterDefinitionList } from "~/common/filter";
import { getPropertyConfig, getPropertyWithConfigList } from "~/common/properties";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import { notify } from "~/components/notifications";
import {
  DuidFieldIcon,
  FilterIcon,
  HideIcon,
  OrderFieldIcon,
  PropertiesIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  UnsortAscendingIcon,
  UnsortDescendingIcon,
} from "~/icons";
import { makeLinkToPropertySettingsRef } from "~/router/common";
import { DropdownMenuItemKind, EditorMode, NotificationType, PropertyKind, UserRole, ViewKind } from "~/shared/enums";
import type { Property, Task, TaskField } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { generateFieldRecommendation, getRecommendationDefaultValuesForProperties } from "~/utils/recommendation";

const NO_SORT = "";
const ASCENDING = "asc";
const DESCENDING = "desc";

type SortDirection = typeof NO_SORT | typeof ASCENDING | typeof DESCENDING;

const SORT_TO_NEXT_SORT_MAP: Map<SortDirection, SortDirection> = new Map([
  [NO_SORT, ASCENDING],
  [ASCENDING, DESCENDING],
  [DESCENDING, NO_SORT],
]);

const COLUMN_ICON_MAP = new Map([
  ["duid", DuidFieldIcon],
  ["order", OrderFieldIcon],
]);

const props = defineProps<{
  params: IHeaderParams<Task> & { property?: Property; editorMode?: EditorMode };
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const propertyConfig = computed(() => props.params.property && getPropertyConfig(props.params.property.kind));
const isListMiniRoadmap = computed(() => props.params.editorMode === EditorMode.LIST_MINI_ROADMAP);
const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);

const filterDefinitions = computed(() => getFilterDefinitionList(getPropertyWithConfigList()));
const fieldOrDuid = computed(() => (props.params.column.getDefinition() as { field: TaskField }).field);
const isSortEnabled = computed(() => props.params.enableSorting);
const isFilterEnabled = computed(() => filterDefinitions.value.find((e) => e.propertyDuid === fieldOrDuid.value));
const leftPaddingWidth = computed(() =>
  propertyConfig.value?.kind === PropertyKind.DEFAULT_TITLE ? (pageStore.isPublicView ? 31 : 51) : 8
);
const columnIcon = computed(() =>
  propertyConfig.value ? propertyConfig.value.icon : COLUMN_ICON_MAP.get(fieldOrDuid.value)
);

const isFilterActive = computed(() => appStore.filters.some((filter) => filter.propertyDuid === fieldOrDuid.value));
const activeSortDirection = computed(() => {
  const sort = appStore.sorts.find((e) => e.propertyDuid === fieldOrDuid.value);
  if (!sort) {
    return NO_SORT;
  }
  return sort.isAscending ? ASCENDING : DESCENDING;
});
const isSortActive = computed(() => activeSortDirection.value !== NO_SORT);

const setSort = (newSort: SortDirection) => {
  if (newSort === NO_SORT) {
    appStore.resetSorts();
    return;
  }
  appStore.setAllSorts([
    {
      propertyDuid: fieldOrDuid.value,
      isAscending: newSort === ASCENDING,
    },
  ]);
};

const handleSortClick = () => {
  setSort(SORT_TO_NEXT_SORT_MAP.get(activeSortDirection.value) ?? NO_SORT);
};

const handleFilterClick = () => {
  if (!userStore.getShowFilters()) {
    userStore.setShowFilters(true);
  }
  nextTick(() => {
    if (!appStore.filterArea) {
      return;
    }
    if (isFilterActive.value) {
      appStore.filterArea.openFilter(fieldOrDuid.value);
    } else {
      appStore.filterArea.addFilter(fieldOrDuid.value);
    }
  });
};

const wrapper = ref<HTMLDivElement | null>(null);
const rightContent = ref<HTMLDivElement | null>(null);
const wrapperWidth = ref(props.params.column.getMaxWidth() ?? 100);
const showIcon = ref(true);

const resize = () => {
  if (!wrapper.value) {
    return;
  }
  wrapperWidth.value = props.params.column.getMaxWidth() ?? 100;
  const rightContentWidth = rightContent.value?.getBoundingClientRect()?.width ?? 0;
  const padding = 8 + (rightContentWidth > 0 ? 4 : 0) + leftPaddingWidth.value;
  showIcon.value = wrapperWidth.value - rightContentWidth - padding >= 50;
};

useResizeObserver(rightContent, resize);
watch([() => wrapper.value, () => rightContent.value, () => leftPaddingWidth.value], resize);

const defaultValues = getRecommendationDefaultValuesForProperties();

const showRecommendationButton = computed(() => [...defaultValues.keys()].includes(fieldOrDuid.value));

const generateRecommendation = async () => {
  const visualization = appStore.getActiveVisualization();
  const tasks = visualization.getAll();

  if (tasks.length > 100) {
    notify({ message: "Dart AI cannot fill out more than 100 tasks at once", type: NotificationType.ERROR });
    return;
  }

  const taskDuids = tasks.map((e) => e.duid);

  const value = defaultValues.get(fieldOrDuid.value);

  appStore.startLoadingAiTaskProperties(taskDuids, fieldOrDuid.value);
  const [taskUpdates, recDuids] = await generateFieldRecommendation(
    tasks,
    value?.[0] ?? fieldOrDuid.value,
    value?.[1] ?? null
  );
  dataStore.updateTasks(taskUpdates);
  appStore.finishLoadingAiTaskProperties(taskDuids, fieldOrDuid.value);

  appStore.showFeedbackTooltip(wrapper.value, recDuids);
};

const dropdownSections = computed(() => {
  const result = [];
  if (isFilterEnabled.value) {
    result.push({
      title: "Filter",
      items: [
        {
          title: "Filter",
          kind: DropdownMenuItemKind.BUTTON,
          icon: FilterIcon,
          onClick: handleFilterClick,
        },
      ],
    });
  }
  if (isSortEnabled.value) {
    result.push({
      title: "Sort",
      items: [
        {
          title: `${activeSortDirection.value === ASCENDING ? "Unsort" : "Sort ascending"}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: activeSortDirection.value === ASCENDING ? UnsortAscendingIcon : SortAscendingIcon,
          onClick: () => {
            setSort(activeSortDirection.value === ASCENDING ? NO_SORT : ASCENDING);
          },
        },
        {
          title: `${activeSortDirection.value === DESCENDING ? "Unsort" : "Sort descending"}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: activeSortDirection.value === DESCENDING ? UnsortDescendingIcon : SortDescendingIcon,
          onClick: () => {
            setSort(activeSortDirection.value === DESCENDING ? NO_SORT : DESCENDING);
          },
        },
      ],
    });
  }
  const { property } = props.params;
  if (!property || property.kind === PropertyKind.DEFAULT_TITLE) {
    return result;
  }

  const metaItems = [];
  if (
    property.kind !== PropertyKind.DEFAULT_STATUS &&
    property.kind !== PropertyKind.DEFAULT_DARTBOARD &&
    !(isListMiniRoadmap.value && property.kind === PropertyKind.DEFAULT_DATES)
  ) {
    metaItems.push({
      title: "Hide column",
      kind: DropdownMenuItemKind.BUTTON,
      icon: HideIcon,
      hidden: isTrash.value,
      onClick: () => {
        appStore.setPropertyDuidVisibility(property.duid, false);
      },
    });
  }
  metaItems.push({
    title: "Property settings",
    kind: DropdownMenuItemKind.INTERNAL_LINK,
    icon: PropertiesIcon,
    hidden: !userStore.isRoleGreaterOrEqual(UserRole.ADMIN) || isTrash.value,
    navigate: { to: makeLinkToPropertySettingsRef(props.params.property?.duid).value },
  });
  result.push({
    title: "Meta",
    items: metaItems,
  });

  return result;
});

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  // TODO pass the recommendation generation through
  appStore.openContextMenu(event as PointerEvent, dropdownSections.value);
};

onUnmounted(() => {
  appStore.showFeedbackTooltip(null, []);
});
</script>

<template>
  <DropdownMenu
    ref="dropdownMenu"
    :sections="dropdownSections"
    block
    height-block
    :show-recommendation-button="showRecommendationButton"
    @recommend="generateRecommendation">
    <div
      ref="wrapper"
      class="flex size-full cursor-pointer items-center justify-between gap-1 pr-2 hover:bg-lt"
      :class="{ '!pointer-events-auto': isTrash }"
      :style="{ 'max-width': `${wrapperWidth}px`, 'padding-left': `${leftPaddingWidth}px` }"
      @contextmenu="onContextMenu">
      <div class="flex min-w-0 shrink items-center gap-2">
        <component :is="columnIcon" v-if="showIcon && columnIcon" class="text-md icon-sm" />
        <span :title="props.params.displayName" class="shrink select-none truncate text-xs font-normal text-md">
          {{ props.params.displayName }}
        </span>
      </div>
      <div
        v-if="isFilterActive || isSortActive"
        ref="rightContent"
        class="flex shrink-0 items-center"
        @click.stop
        @keydown.enter.stop>
        <FilterIcon
          v-if="isFilterActive"
          class="rounded text-vlt icon-sm hover:text-lt"
          @click="handleFilterClick"
          @keydown.enter="handleFilterClick" />
        <component
          :is="activeSortDirection === ASCENDING ? SortAscendingIcon : SortDescendingIcon"
          v-if="isSortActive"
          class="rounded text-vlt icon-sm hover:text-lt"
          @click="handleSortClick"
          @keydown.enter="handleSortClick" />
      </div>
    </div>
  </DropdownMenu>
</template>
