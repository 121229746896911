<script setup lang="ts">
import { computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import { AttachmentFieldIcon } from "~/icons";
import { ButtonSize, ButtonStyle, EditorMode, IconSize } from "~/shared/enums";
import { checkFileSize } from "~/utils/api";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  numOfAttachments?: number;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  input: [value: File[]];
}>();

const inputEl = ref<HTMLInputElement | null>(null);

const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const onInput = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (!input.files) {
    return;
  }
  const filteredFiles = Array.from(input.files).filter((file) => checkFileSize(file));
  emit("input", filteredFiles);
  input.value = "";
};

const inputId = ref(`input-${makeUuid()}`);
const num = computed(() => props.numOfAttachments ?? 0);

const open = () => inputEl.value?.click();

defineExpose({
  open,
});
</script>

<template>
  <label
    :for="inputId"
    class="flex cursor-pointer"
    :class="isFormMode && 'w-full items-center !justify-start text-left'">
    <span class="sr-only">Add attachment</span>
    <input :id="inputId" ref="inputEl" type="file" class="sr-only" accept="*" multiple @input="onInput" />

    <Button
      v-if="isChipMode"
      :btn-style="ButtonStyle.CHIP"
      :text="num === 0 ? 'Attachments' : `${num} attachment${num === 1 ? '' : 's'}`"
      :icon="AttachmentFieldIcon"
      :icon-size="IconSize.S"
      :icon-args="{ class: num > 0 && 'text-primary-base' }"
      class="py-0.5"
      @click="open" />

    <Button
      v-else-if="isFormMode"
      :btn-style="ButtonStyle.SECONDARY"
      :size="ButtonSize.CHIP"
      :text="num === 0 ? 'Add attachment' : `${num} attachment${num === 1 ? '' : 's'}`"
      :text-style="num === 0 ? 'text-vlt' : 'text-md'"
      borderless
      class="mx-[3px]"
      @click="open" />
    <AttachmentFieldIcon v-else class="icon-sm" />
  </label>
</template>
