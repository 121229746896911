/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `None` - NONE
 * * `Icon` - ICON
 * * `Emoji` - EMOJI
 */
export enum IconKind {
  NONE = 'None',
  ICON = 'Icon',
  EMOJI = 'Emoji',
}
