<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import type { Doc, Task } from "~/shared/types";
import { useDataStore } from "~/stores";

import { EVENT_SUBSCRIBE_USER } from "../const";

const props = defineProps<{
  task?: Task;
  doc?: Doc;
}>();

const dataStore = useDataStore();
const editor = useLexicalComposer();

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(
      EVENT_SUBSCRIBE_USER,
      (user) => {
        const { task, doc } = props;

        if (task) {
          if (!task.subscriberDuids.includes(user.duid)) {
            dataStore.addSubscribers([task.duid], [user.duid]);
          }
          return true;
        }

        if (doc) {
          if (!doc.subscriberDuids.includes(user.duid)) {
            dataStore.addDocSubscribers(doc.duid, [user.duid]);
          }
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_EDITOR
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
