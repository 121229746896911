<script setup lang="ts">
import { computed } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { SummaryStatisticKind } from "~/shared/enums";
import type { TaskGroupSummaryStatistics } from "~/shared/types";
import { useAppStore } from "~/stores";

const POINTS_STATISTICS = new Set([
  SummaryStatisticKind.TOTAL_COUNT_POINTS,
  SummaryStatisticKind.INCOMPLETE_COUNT_POINTS,
  SummaryStatisticKind.COMPLETED_PERCENT_POINTS,
]);

const props = defineProps<{
  statistics: TaskGroupSummaryStatistics;
  tooltipShowDelay?: number;
  onColoredBackground?: boolean;
  detailed?: boolean;
}>();

const appStore = useAppStore();

const content = computed(() => {
  const {
    hasSelection,
    visibleCount,
    visibleCompletedCount,
    visiblePoints,
    visibleCompletedPoints,
    selectedCount,
    selectedCompletedCount,
    selectedPoints,
    selectedCompletedPoints,
  } = props.statistics;

  const makeResult = (title: string, suffix: string) => ({
    title,
    tooltip: `${title} ${suffix}`,
  });
  const defaultResult = makeResult("", "");

  const { summaryStatisticKind } = appStore;
  if (summaryStatisticKind === SummaryStatisticKind.NONE) {
    return props.detailed ? makeResult("None", "") : defaultResult;
  }

  const isTasks = !POINTS_STATISTICS.has(summaryStatisticKind);
  const entity = isTasks ? "task" : "point";
  const visible = isTasks ? visibleCount : visiblePoints;
  const visibleCompleted = isTasks ? visibleCompletedCount : visibleCompletedPoints;
  const selected = (isTasks ? selectedCount : selectedPoints) ?? 0;
  const selectedCompleted = (isTasks ? selectedCompletedCount : selectedCompletedPoints) ?? 0;

  const total = hasSelection ? selected : visible;
  const completed = hasSelection ? selectedCompleted : visibleCompleted;

  if (
    summaryStatisticKind === SummaryStatisticKind.TOTAL_COUNT ||
    summaryStatisticKind === SummaryStatisticKind.TOTAL_COUNT_POINTS
  ) {
    return makeResult(`${total}`, `${hasSelection ? "selected" : ""} ${entity}${total !== 1 ? "s" : ""}`);
  }
  if (
    summaryStatisticKind === SummaryStatisticKind.INCOMPLETE_COUNT ||
    summaryStatisticKind === SummaryStatisticKind.INCOMPLETE_COUNT_POINTS
  ) {
    const incomplete = total - completed;
    return makeResult(`${incomplete}`, `${hasSelection ? "of the selected" : ""} ${entity}s are incomplete`);
  }
  if (
    summaryStatisticKind === SummaryStatisticKind.COMPLETED_PERCENT ||
    summaryStatisticKind === SummaryStatisticKind.COMPLETED_PERCENT_POINTS
  ) {
    const percentage = total === 0 ? 0 : Math.round((completed / total) * 100);
    return makeResult(`${percentage}%`, `of ${hasSelection ? "the selected" : ""} ${entity}s are finished`);
  }
  return defaultResult;
});
</script>

<template>
  <span v-if="detailed" class="flex h-4 min-w-4 px-1.5 text-xs/normal text-md">
    {{ content.tooltip }}
  </span>
  <Tooltip
    v-else-if="appStore.layout?.summaryStatisticKind !== SummaryStatisticKind.NONE"
    :text="content.tooltip"
    :show-delay="tooltipShowDelay">
    <span
      class="pointer-events-auto m-1 flex h-4 min-w-4 items-center justify-center rounded px-0.5 text-xs/normal uppercase"
      :class="onColoredBackground ? 'text-gray-900/60 dark:text-white/50' : 'border text-lt border-md'">
      {{ content.title }}
    </span>
  </Tooltip>
</template>
