import { DecoratorNode, type LexicalNode, type SerializedLexicalNode } from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import type { TypeaheadOption } from "~/shared/types";

import Entity from "./Entity.vue";

type Value = TypeaheadOption["value"];
export type SerializedEntityNode = SerializedLexicalNode & { propertyDuid: string; value: Value };

export class EntityNode extends DecoratorNode<Component> {
  __propertyDuid: string;

  __value: Value;

  static getType(): string {
    return "entity";
  }

  static clone(node: EntityNode): EntityNode {
    return new EntityNode(node.__propertyDuid, node.__value, node.__key);
  }

  constructor(propertyDuid: string, value: Value, key?: string) {
    super(key);
    this.__propertyDuid = propertyDuid;
    this.__value = value;
  }

  getPropertyDuid(): string {
    return this.__propertyDuid;
  }

  getValue(): Value {
    return this.__value;
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const element = document.createElement("div");
    element.classList.add("contents");
    return element;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedEntityNode): LexicalNode {
    return new EntityNode(serializedNode.propertyDuid, serializedNode.value);
  }

  exportJSON(): SerializedEntityNode {
    return {
      type: EntityNode.getType(),
      propertyDuid: this.getPropertyDuid(),
      value: this.getValue(),
      version: 1,
    };
  }

  decorate(): Component {
    return h(Entity, {
      nodeKey: this.getKey(),
      propertyDuid: this.getPropertyDuid(),
      value: this.getValue(),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): true {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }
}

export function $createEntityNode(propertyDuid: string, value: Value): EntityNode {
  return new EntityNode(propertyDuid, value);
}

export function $isEntityNode(node?: LexicalNode): node is EntityNode {
  return node instanceof EntityNode;
}
