<script setup lang="ts">
import { computed } from "vue";
import { RouterLink, useRouter } from "vue-router";

import { backend } from "~/api";
import AdminDropdown from "~/components/AdminDropdown.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import GenericSubdropdown from "~/components/dumb/GenericSubdropdown.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import OnboardingChecklist from "~/components/leftbar/OnboardingChecklist.vue";
import TrialProgressOrCta from "~/components/leftbar/TrialProgressOrCta.vue";
import {
  AdminIcon,
  BlogIcon,
  CalendarIcon,
  ChangelogIcon,
  ChatgptIcon,
  ChatIcon,
  CommandCenterIcon,
  DesktopOrWebAppIcon,
  DiscordIcon,
  HelpAndResourcesIcon,
  HelpCenterIcon,
  ImprovementIcon,
  InstagramIcon,
  KeyboardShortcutsIcon,
  LinkedinIcon,
  MailIcon,
  MobileAppIcon,
  PomodoroIcon,
  PrivacyPolicyIcon,
  SettingsIcon,
  SocialsIcon,
  StatusWebsiteIcon,
  TermsOfUseIcon,
  ThreadsIcon,
  UsersInviteIcon,
  WebsiteIcon,
  XCompanyIcon,
} from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import { CommandId, DropdownMenuItemKind, EventKind, Placement, UserRole } from "~/shared/enums";
import { useAppStore, usePageStore, useTenantStore, useUserStore } from "~/stores";

defineProps<{
  isMobileLeftbar?: boolean;
}>();

const router = useRouter();
const appStore = useAppStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const teammatesSettingsLink = makeLinkToSettingsRef("teammates");
const workspaceSettingsLink = makeLinkToSettingsRef("workspace");

const clickResource = (resource: string) => {
  backend.event.create(EventKind.HELP_RESOURCE_CLICK, { resource });
};

const openCommandCenter = () => {
  window.CommandBar.open();
  clickResource("commandCenter");
};

const openKeyboardShortcuts = () => {
  appStore.setShortcutsModalOpen(true);
  clickResource("keyboardShortcuts");
};

const openFeedbackModal = () => {
  appStore.setFeedbackModalOpen(true);
  clickResource("shareFeedback");
};

const dropdownOptions = computed(() => {
  const getMobileAppBase = {
    title: "Get the mobile app",
    icon: MobileAppIcon,
    onClick: () => clickResource("getMobileApp"),
  };
  const getOrOpenInMobileApp = pageStore.isMobileApp
    ? []
    : pageStore.isMobile
      ? [
          {
            ...getMobileAppBase,
            kind: DropdownMenuItemKind.EXTERNAL_LINK,
            navigate: {
              to: router.resolve({ name: "redirect_mobile_app" }).fullPath, // TODO split the name into get/open in; actually try to open in mobile app--if that fails, do this
              newTab: true,
            },
          },
        ]
      : [
          {
            ...getMobileAppBase,
            kind: DropdownMenuItemKind.BUTTON,
            onClick: () => {
              appStore.setAppDownloadModalOpen(true);
            },
          },
        ];
  const downloadDesktopAppOrOpenWebAppMaybe = pageStore.isMobile
    ? []
    : [
        {
          title: pageStore.isDesktopApp ? "Open in browser" : `Download the ${pageStore.isMac ? "Mac" : "Windows"} app`,
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: DesktopOrWebAppIcon,
          onClick: () => clickResource(pageStore.isDesktopApp ? "openInBrowser" : "getDesktopApp"),
          navigate: {
            to: pageStore.isDesktopApp
              ? router.currentRoute.value.fullPath
              : router.resolve({ name: "redirect_desktop_app" }).fullPath, // TODO get this to happen immediately and get the tab to close automatically when done
            newTab: true,
          },
        },
      ];
  return [
    {
      title: "Support",
      showTitle: true,
      items: [
        {
          title: "Share feedback",
          kind: DropdownMenuItemKind.BUTTON,
          icon: ChatIcon,
          onClick: openFeedbackModal,
        },
        {
          title: "Read the help center",
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: HelpCenterIcon,
          onClick: () => clickResource("helpCenter"),
          navigate: {
            to: "https://help.itsdart.com",
            newTab: true,
          },
        },
        {
          title: "Report a bug or improvement",
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: ImprovementIcon,
          onClick: () => clickResource("openBugOrImprovement"),
          navigate: {
            to: "https://app.itsdart.com/p/r/JFyPnhL9En61",
            newTab: true,
          },
        },
        {
          title: "Email support",
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: MailIcon,
          subtitle: "support@itsdart.com",
          subtitleCopyDescription: "Support email",
          onClick: () => clickResource("emailSupport"),
          navigate: {
            to: "mailto:support@itsdart.com?subject=Support%20request",
            newTab: true,
          },
        },
        {
          title: "Chat on Discord",
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: DiscordIcon,
          onClick: () => clickResource("openDiscord"),
          navigate: {
            to: "https://discord.gg/RExv8jEkSh",
            newTab: true,
          },
        },
        {
          title: "Schedule a call",
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: CalendarIcon,
          onClick: () => clickResource("scheduleCall"),
          navigate: {
            to: "https://book.vimcal.com/p/zackswafford/onboarding",
            newTab: true,
          },
        },
      ],
    },
    {
      title: "Resources",
      showTitle: true,
      items: [
        {
          title: "Website",
          kind: DropdownMenuItemKind.COMPONENT,
          component: GenericSubdropdown,
          icon: WebsiteIcon,
          componentArgs: {
            title: "Website",
            icon: WebsiteIcon,
            widthPixels: 160,
            sections: [
              {
                title: "Website",
                items: [
                  {
                    title: "Homepage",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: WebsiteIcon,
                    onClick: () => clickResource("website"),
                    navigate: {
                      to: "https://www.itsdart.com?nr=1",
                      newTab: true,
                    },
                  },
                  {
                    title: "Changelog",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: ChangelogIcon,
                    onClick: () => clickResource("changelog"),
                    navigate: {
                      to: "https://changelog.itsdart.com",
                      newTab: true,
                    },
                  },
                  {
                    title: "Status",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: StatusWebsiteIcon,
                    onClick: () => clickResource("status"),
                    navigate: {
                      to: "https://status.itsdart.com",
                      newTab: true,
                    },
                  },
                  {
                    title: "Blog",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: BlogIcon,
                    onClick: () => clickResource("blog"),
                    navigate: {
                      to: "https://itsdart.com/blog?nr=1",
                      newTab: true,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          title: "Socials",
          kind: DropdownMenuItemKind.COMPONENT,
          component: GenericSubdropdown,
          icon: SocialsIcon,
          componentArgs: {
            title: "Socials",
            icon: SocialsIcon,
            widthPixels: 160,
            sections: [
              {
                title: "Socials",
                items: [
                  {
                    title: "LinkedIn",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: LinkedinIcon,
                    onClick: () => clickResource("openLinkedin"),
                    navigate: {
                      to: "https://www.linkedin.com/company/dart-ai",
                      newTab: true,
                    },
                  },
                  {
                    title: "Threads",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: ThreadsIcon,
                    subtitle: "@dartappofficial",
                    subtitleCopyDescription: "Threads handle",
                    onClick: () => clickResource("openThreads"),
                    navigate: {
                      to: "https://www.threads.net/@dartappofficial",
                      newTab: true,
                    },
                  },
                  {
                    title: "X",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: XCompanyIcon,
                    subtitle: "@its_dart",
                    subtitleCopyDescription: "X handle",
                    onClick: () => clickResource("openX"),
                    navigate: {
                      to: "https://x.com/its_dart",
                      newTab: true,
                    },
                  },
                  {
                    title: "Instagram",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: InstagramIcon,
                    onClick: () => clickResource("openInstagram"),
                    navigate: {
                      to: "https://www.instagram.com/dartappofficial",
                      newTab: true,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          title: "Legal",
          kind: DropdownMenuItemKind.COMPONENT,
          component: GenericSubdropdown,
          icon: PrivacyPolicyIcon,
          componentArgs: {
            title: "Legal",
            icon: PrivacyPolicyIcon,
            widthPixels: 160,
            sections: [
              {
                title: "Legal",
                items: [
                  {
                    title: "Terms of use",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: TermsOfUseIcon,
                    onClick: () => clickResource("termsOfUse"),
                    navigate: {
                      to: "https://app.itsdart.com/legal/terms-of-use",
                      newTab: true,
                    },
                  },
                  {
                    title: "Privacy policy",
                    kind: DropdownMenuItemKind.EXTERNAL_LINK,
                    icon: PrivacyPolicyIcon,
                    onClick: () => clickResource("privacyPolicy"),
                    navigate: {
                      to: "https://app.itsdart.com/legal/privacy-policy",
                      newTab: true,
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      title: "Download",
      showTitle: true,
      items: [...getOrOpenInMobileApp, ...downloadDesktopAppOrOpenWebAppMaybe],
    },
    {
      title: "Tools",
      showTitle: true,
      items: [
        {
          title: "Pomodoro timer",
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: PomodoroIcon,
          onClick: () => clickResource("pomodoro"),
          navigate: {
            to: "https://itsdart.com/timer?nr=1",
            newTab: true,
          },
        },
        {
          title: "Start an AI chat",
          kind: DropdownMenuItemKind.EXTERNAL_LINK,
          icon: ChatgptIcon,
          onClick: () => clickResource("aiChat"),
          navigate: {
            to: "https://chat.openai.com/g/g-eKfjMUXwr-dart",
            newTab: true,
          },
        },
        {
          title: "Command center",
          kind: DropdownMenuItemKind.BUTTON,
          commandId: CommandId.TOGGLE_COMMAND_CENTER,
          icon: CommandCenterIcon,
          onClick: openCommandCenter,
        },
        {
          title: "Shortcuts",
          hidden: pageStore.isMobile,
          commandId: CommandId.TOGGLE_KEYBOARD_SHORTCUTS,
          kind: DropdownMenuItemKind.BUTTON,
          icon: KeyboardShortcutsIcon,
          onClick: openKeyboardShortcuts,
        },
      ],
    },
  ];
});
</script>

<template>
  <div class="my-2 flex flex-col space-y-px p-1">
    <OnboardingChecklist class="mb-3" />

    <AdminDropdown v-if="tenantStore.isDart && !pageStore.adminHidden">
      <div
        class="flex h-7 w-full select-none items-center rounded pl-3.5 pr-3 text-sm font-medium drag-none text-lt hover:bg-md"
        @dblclick="pageStore.adminHidden = true">
        <AdminIcon class="mr-2 text-lt icon-md" />
        Admin
      </div>
    </AdminDropdown>

    <RouterLink
      v-if="userStore.isRoleGreaterOrEqual(UserRole.MEMBER) && appStore.onboardingChecklistCompleted"
      :to="teammatesSettingsLink"
      class="flex h-7 w-full select-none items-center rounded pl-3.5 pr-3 text-sm font-medium drag-none text-lt focus-ring-lt hover:bg-md">
      <UsersInviteIcon class="mr-2 text-lt icon-md" />
      Invite teammates
    </RouterLink>

    <Tooltip :command-id="CommandId.GO_TO_SETTINGS" :placement="Placement.TOP" block>
      <RouterLink
        :to="workspaceSettingsLink"
        class="flex h-7 w-full select-none items-center rounded pl-3.5 pr-3 text-sm font-medium drag-none text-lt focus-ring-lt hover:bg-md">
        <SettingsIcon class="mr-2 text-lt icon-md" />
        Settings
      </RouterLink>
    </Tooltip>

    <DropdownMenu
      :sections="dropdownOptions"
      :placement="Placement.RIGHT_BOTTOM"
      block
      :distance="0"
      :width-pixels="260"
      :max-height-pixels="653">
      <div
        class="flex h-7 w-full select-none items-center truncate rounded pl-3.5 pr-3 text-sm font-medium drag-none text-lt hover:bg-md"
        @click="clickResource('helpAndResourcesButton')"
        @keydown.enter="clickResource('helpAndResourcesButton')">
        <HelpAndResourcesIcon class="mr-2 text-lt icon-md" />
        Help and resources
      </div>
    </DropdownMenu>

    <TrialProgressOrCta :is-mobile-leftbar="isMobileLeftbar" class="!mt-3" />
  </div>
</template>
