<script setup lang="ts">
import type { HistoryState } from "@lexical/history";
import { $isAutoLinkNode, $isLinkNode, AutoLinkNode, LinkNode } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";
import { $getNodeByKey, type EditorState } from "lexical";
import { LexicalAutoLinkPlugin, useLexicalComposer } from "lexical-vue";
import { nextTick, onMounted, onUnmounted } from "vue";

import {
  EVENT_CREATE_DOC_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP,
  URL_MATCHERS,
} from "~/components/text/const";
import { $createDartLinkNode } from "~/components/text/nodes/DartLinkNode";
import { EntityName, TypeaheadSpecialEntity } from "~/shared/enums";
import { useEnvironmentStore } from "~/stores";
import { getEntityKindAndDuidFromUrl, isString, isValidDartUrl } from "~/utils/common";

const props = defineProps<{
  history: HistoryState;
}>();

const emit = defineEmits<{
  linkChanges: [creates: string[], destroys: string[], updates: { old: string; new: string }[]];
}>();

const environmentStore = useEnvironmentStore();
const editor = useLexicalComposer();

const isDoc = editor._config.namespace.startsWith("doc");

const getNodeUrlByKey = (key: string, editorState?: EditorState) => {
  const node = $getNodeByKey(key, editorState);
  if (node === null || !($isLinkNode(node) || $isAutoLinkNode(node))) {
    return undefined;
  }

  return node.__url;
};

const handleLinkNodeMutations = (mutatedNodes: Map<string, string>) =>
  nextTick(() =>
    editor.update(() => {
      const oldHistoryEntries = props.history.undoStack;
      const previousEditorState = oldHistoryEntries[oldHistoryEntries.length - 1]?.editorState;

      const mutatedNodeEntries = [...mutatedNodes.entries()];
      const keysOfNodesToReplace = new Set<string>();

      mutatedNodeEntries.forEach(([key, action]) => {
        if (keysOfNodesToReplace.has(key)) {
          return;
        }

        const node = $getNodeByKey(key);
        if (node === null || action === "destroyed" || !($isLinkNode(node) || $isAutoLinkNode(node))) {
          return;
        }
        const url = node.getURL();

        if (!isValidDartUrl(url, environmentStore.isLocal)) {
          return;
        }

        keysOfNodesToReplace.add(key);

        const { kind, duid } = getEntityKindAndDuidFromUrl(url);
        const entityLinkNode = $createDartLinkNode(url, kind, duid);
        node.insertBefore(entityLinkNode);
        node.remove();

        if (kind === EntityName.TASK) {
          if (!isDoc) {
            editor.dispatchCommand(EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP, duid);
          } else {
            editor.dispatchCommand(EVENT_CREATE_TASK_DOC_RELATIONSHIP, {
              specialEntity: TypeaheadSpecialEntity.TASK,
              entityDuid: duid,
            });
          }
        }

        if (kind === EntityName.DOC) {
          if (!isDoc) {
            editor.dispatchCommand(EVENT_CREATE_TASK_DOC_RELATIONSHIP, {
              specialEntity: TypeaheadSpecialEntity.DOC,
              entityDuid: duid,
            });
          } else {
            editor.dispatchCommand(EVENT_CREATE_DOC_DOC_RELATIONSHIP, duid);
          }
        }
      });

      const filteredMutatedNodeEntries = mutatedNodeEntries.filter((e) => !keysOfNodesToReplace.has(e[0]));
      const updatedNodes = filteredMutatedNodeEntries.filter((e) => e[1] === "updated").map((e) => e[0]);
      const createdNodes = filteredMutatedNodeEntries.filter((e) => e[1] === "created").map((e) => e[0]);
      const destroyedNodes = filteredMutatedNodeEntries.filter((e) => e[1] === "destroyed").map((e) => e[0]);

      const pairLength = Math.min(createdNodes.length, destroyedNodes.length);
      const updates = updatedNodes
        .map((e) => [e, e])
        .concat(
          Array.from(Array(pairLength).keys())
            .map((i) => i)
            .map((i) => [destroyedNodes[i], createdNodes[i]])
        )
        .map(([oldNodeKey, newNodeKey]) => ({
          old: getNodeUrlByKey(oldNodeKey, previousEditorState),
          new: getNodeUrlByKey(newNodeKey),
        }))
        .filter((e): e is { old: string; new: string } => isString(e.old) && isString(e.new));
      const creates = createdNodes
        .slice(pairLength)
        .map((e) => getNodeUrlByKey(e))
        .filter(isString);
      const destroys = destroyedNodes
        .slice(pairLength)
        .map((e) => getNodeUrlByKey(e, previousEditorState))
        .filter(isString);
      emit("linkChanges", creates, destroys, updates);
    })
  );

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    // TODO this line doesn't work and never fires for mutations to AutoLinkNodes
    editor.registerMutationListener(AutoLinkNode, handleLinkNodeMutations),
    editor.registerMutationListener(LinkNode, handleLinkNodeMutations)
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <LexicalAutoLinkPlugin :matchers="URL_MATCHERS" />
</template>
