import { RecurrenceKind } from "~/shared/enums";
import type { DayOfWeek } from "~/shared/types";

export const SHORT_WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const RECURRENCE_KIND_TO_VALUE_MAP: Map<RecurrenceKind, string> = new Map([
  [RecurrenceKind.DAILY, "day"],
  [RecurrenceKind.WEEKLY, "week"],
  [RecurrenceKind.MONTHLY, "month"],
  [RecurrenceKind.YEARLY, "year"],
]);

export const DAY_OF_WEEK_LETTERS_AND_NUMBERS: [string, DayOfWeek][] = [
  ["S", 6],
  ["M", 0],
  ["T", 1],
  ["W", 2],
  ["T", 3],
  ["F", 4],
  ["S", 5],
];
