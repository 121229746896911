<script setup lang="ts">
import { computed } from "vue";

import { getDefaultPropertiesToFieldsMap, getShownPropertyWithConfigList } from "~/common/properties";
import DartboardEditor from "~/components/dumb/DartboardEditor.vue";
import StatusEditor from "~/components/dumb/StatusEditor.vue";
import UserEditor from "~/components/dumb/UserEditor.vue";
import AnimatedChip from "~/components/task/properties/AnimatedChip.vue";
import LargeChipLabel from "~/components/task/properties/LargeChipLabel.vue";
import { EditorMode, PropertyKind } from "~/shared/enums";
import type { Task, TaskField, TaskFieldRecommendation, TaskFieldValue } from "~/shared/types";
import { useDataStore, usePageStore, useTenantStore } from "~/stores";

type IAnimatedChip = InstanceType<typeof AnimatedChip>;

const MANUALLY_SHOWN_PROPERTY_KINDS = new Set([PropertyKind.DEFAULT_ASSIGNEES, PropertyKind.DEFAULT_STATUS]);

const props = defineProps<{
  task: Task;
  recommendations: TaskFieldRecommendation[];
  defaultValues: Map<TaskField, TaskFieldValue>;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  update: [field: keyof Task];
  rejectRecommendation: [field: keyof Task];
  acceptRecommendations: [];
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const DEFAULT_PROPERTIES_TO_FIELDS_MAP = getDefaultPropertiesToFieldsMap();

const propertyItems = computed(() =>
  getShownPropertyWithConfigList()
    .filter((e) => !MANUALLY_SHOWN_PROPERTY_KINDS.has(e[0].kind))
    .filter(
      (e) =>
        // Always show due date in roadmap and calendar
        !(
          (props.editorMode === EditorMode.ROADMAP || props.editorMode === EditorMode.CALENDAR) &&
          e[0].kind === PropertyKind.DEFAULT_DATES
        ) || !e[0].hidden
    )
    .map(([property, config]) => {
      const propertiesView = config.editor();
      const field: keyof Task | undefined = DEFAULT_PROPERTIES_TO_FIELDS_MAP.get(property);
      return {
        property,
        duid: property.duid,
        title: property.title,
        field,
        defaultValue: field ? props.defaultValues.get(field) : null,
        component: propertiesView.component,
        values: propertiesView.getValues(property, props.task),
      };
    })
);

const animationChipRefs = new Map<string, IAnimatedChip | null>();
const addAnimationChipRef = (field: string | undefined, elem: IAnimatedChip | null) => {
  if (field === undefined) {
    return;
  }
  animationChipRefs.set(field, elem);
};

const recommendedPropertiesMap = computed(
  () => new Map(props.recommendations.map(({ field, value }) => [field, value]))
);

const getEditorMode = (field?: keyof Task) => {
  if (field === "assigneeDuids") {
    return recommendedPropertiesMap.value.has("assignedToAi") || recommendedPropertiesMap.value.has("assigneeDuids")
      ? EditorMode.DETAIL_RECOMMENDATION
      : EditorMode.DETAIL;
  }

  return field && recommendedPropertiesMap.value.has(field) ? EditorMode.DETAIL_RECOMMENDATION : EditorMode.DETAIL;
};

const assigneeEditorMode = computed(() =>
  recommendedPropertiesMap.value.has("assignedToAi") || recommendedPropertiesMap.value.has("assigneeDuids")
    ? EditorMode.DETAIL_RECOMMENDATION
    : EditorMode.DETAIL
);

const animateChipRecommendations = (source: HTMLElement, fields: Set<string>) => {
  animationChipRefs.forEach((v, k) => {
    if (!fields.has(k)) {
      return;
    }
    v?.animate(source);
  });
};

const onUpdate = (field: keyof Task | undefined) => {
  if (!field) {
    return;
  }

  emit("update", field);
  if (field === "dueAt" && tenantStore.startDateEnabled) {
    emit("update", "startAt");
  }
};

const onReject = (field: keyof Task | undefined) => {
  if (!field) {
    return;
  }

  emit("rejectRecommendation", field);
};

const rejectAssigneeRecommendation = () => {
  emit("rejectRecommendation", "assigneeDuids");
  emit("rejectRecommendation", "assignedToAi");
};

defineExpose({
  animateChipRecommendations,
});
</script>

<template>
  <div class="dart-large-chips container mt-1 w-full px-[13px] text-sm text-md">
    <div
      class="grid grid-flow-row auto-rows-max gap-4"
      :style="{ 'grid-template-columns': 'repeat(auto-fit, minmax(9rem, 1fr))' }">
      <div class="flex flex-col gap-0.5">
        <LargeChipLabel :property="dataStore.defaultStatusProperty" />
        <StatusEditor
          :property="dataStore.defaultStatusProperty"
          :tasks="[task]"
          show-text
          :editor-mode="EditorMode.DETAIL" />
      </div>

      <div v-if="tenantStore.assigneeEnabled" class="flex flex-col gap-0.5">
        <LargeChipLabel :property="dataStore.defaultAssigneesProperty" />
        <AnimatedChip :ref="(elem) => addAnimationChipRef('assigneeDuids', elem as IAnimatedChip)">
          <UserEditor
            :property="dataStore.defaultAssigneesProperty"
            :tasks="[task]"
            :default-value="defaultValues.get('assigneeDuids') as string[]"
            :editor-mode="assigneeEditorMode"
            show-text
            @update="onUpdate('assigneeDuids')"
            @reject="rejectAssigneeRecommendation" />
        </AnimatedChip>
      </div>

      <div v-if="!pageStore.isPublicView" class="flex flex-col gap-0.5">
        <LargeChipLabel :property="dataStore.defaultDartboardProperty" />
        <DartboardEditor :tasks="[task]" :editor-mode="EditorMode.DETAIL" @update="emit('update', 'dartboardDuid')" />
      </div>

      <div v-for="propertyItem in propertyItems" :key="propertyItem.duid" class="flex flex-col gap-0.5">
        <LargeChipLabel :property="propertyItem.property" />
        <AnimatedChip :ref="(elem) => addAnimationChipRef(propertyItem.field, elem as IAnimatedChip)">
          <component
            :is="propertyItem.component"
            v-bind="propertyItem.values"
            :editor-mode="getEditorMode(propertyItem.field)"
            :default-value="propertyItem.defaultValue"
            @update="onUpdate(propertyItem.field)"
            @reject="onReject(propertyItem.field)" />
        </AnimatedChip>
      </div>
    </div>
  </div>
</template>
