<script setup lang="ts">
import Button from "~/components/dumb/Button.vue";
import { NOT_FOUND_PSEUDO_PAGE } from "~/constants/page";
import { PageNotFoundIllustration } from "~/illustrations";
import { goHome } from "~/router/common";
import { ButtonStyle } from "~/shared/enums";
import { useAppStore } from "~/stores";

const appStore = useAppStore();

appStore.setCurrentPage(NOT_FOUND_PSEUDO_PAGE);
</script>

<template>
  <main class="flex size-full justify-center overflow-y-scroll bg-std">
    <div class="mx-6 mt-24 grid grid-cols-1 lg:mt-48 lg:grid-cols-2">
      <div class="flex select-none flex-col gap-6 lg:mt-40">
        <div class="text-4xl font-extrabold text-md">Huh! We can't find that page</div>
        <div class="mb-2 max-w-[460px] text-xl text-lt">
          It looks like the page you're looking for doesn't exist or you don't have access to it
        </div>
        <Button text="Go home" :btn-style="ButtonStyle.PRIMARY" class="w-max" @click="goHome" />
      </div>
      <div class="flex justify-center">
        <PageNotFoundIllustration />
      </div>
    </div>
  </main>
</template>
