<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ColorHex, DropdownMenuItemKind, Placement } from "~/shared/enums";

import ColorPicker from "./ColorPicker.vue";

const props = defineProps<{
  value: string;
  isContrast?: boolean;
}>();

const emit = defineEmits<{
  select: [color: string];
}>();

const colors = Object.values(ColorHex);

const selectedColor = computed(() => props.value ?? colors[0]);

const colorDropdownSections = computed(() => [
  {
    title: "Change color",
    items: [
      {
        title: "Color Picker",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: ColorPicker,
        componentArgs: {
          value: props.value,
          isContrast: props.isContrast,
          onSelect: (color: string): void => {
            emit("select", color);
          },
        },
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu :sections="colorDropdownSections" :placement="Placement.RIGHT" :max-height-pixels="460">
    <Tooltip text="Change color">
      <button
        type="button"
        class="flex size-8 shrink-0 cursor-pointer items-center justify-center rounded-full border-2 border-transparent hover:border-gray-500 dark:hover:border-zinc-400"
        aria-label="Change color">
        <div class="rounded-full icon-lg" :style="{ backgroundColor: selectedColor }" />
      </button>
    </Tooltip>
  </DropdownMenu>
</template>
