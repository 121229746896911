import type { Component } from "vue";

import {
  BoardViewIcon,
  CalendarViewIcon,
  ListViewIcon,
  RoadmapViewIcon,
  SubtasksCollapsedIcon,
  SubtasksFlatIcon,
  SubtasksIndentedIcon,
} from "~/icons";
import { LayoutKind, SubtaskDisplayMode } from "~/shared/enums";

export const LAYOUT_KIND_TO_NAME_AND_ICON = new Map<LayoutKind, [string, Component]>([
  [LayoutKind.LIST, ["List", ListViewIcon]],
  [LayoutKind.BOARD, ["Board", BoardViewIcon]],
  [LayoutKind.CALENDAR, ["Calendar", CalendarViewIcon]],
  [LayoutKind.ROADMAP, ["Roadmap", RoadmapViewIcon]],
]);

export const LAYOUT_KIND_TO_NEXT_LAYOUT_KIND = new Map([
  [LayoutKind.LIST, LayoutKind.BOARD],
  [LayoutKind.BOARD, LayoutKind.CALENDAR],
  [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  [LayoutKind.ROADMAP, LayoutKind.LIST],
]);

export const LAYOUT_KIND_TO_PREVIOUS_LAYOUT_KIND = new Map(
  [...LAYOUT_KIND_TO_NEXT_LAYOUT_KIND.entries()].map(([k, v]) => [v, k])
);

export const SUBTASK_DISPLAY_MODES_TO_NAME_AND_ICON = new Map<SubtaskDisplayMode, [string, Component]>([
  [SubtaskDisplayMode.INDENTED, ["Indented", SubtasksIndentedIcon]],
  [SubtaskDisplayMode.COLLAPSED, ["Collapsed", SubtasksCollapsedIcon]],
  [SubtaskDisplayMode.FLAT, ["Flat", SubtasksFlatIcon]],
]);

export const GROUP_BY_LAYOUT_KINDS = new Set([LayoutKind.BOARD, LayoutKind.LIST]);
export const COLOR_BY_LAYOUT_KINDS = new Set([LayoutKind.CALENDAR, LayoutKind.ROADMAP]);
