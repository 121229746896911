<script setup lang="ts">
import { registerCodeHighlighting } from "@lexical/code";
import { mergeRegister } from "@lexical/utils";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const editor = useLexicalComposer();

let unregisterListener: () => void;

onMounted(() => {
  unregisterListener = mergeRegister(registerCodeHighlighting(editor));
});

onUnmounted(() => {
  unregisterListener?.();
});
</script>

<template>
  <slot />
</template>
