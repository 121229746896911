import confetti from "canvas-confetti";

const randInRange = (min: number, max: number) => Math.random() * (max - min) + min;

export const firework = () =>
  confetti({
    particleCount: 100,
    startVelocity: 30,
    spread: 360,
    ticks: 80,
    origin: {
      x: randInRange(0.1, 0.9),
      y: randInRange(0.1, 0.6),
    },
    zIndex: 998,
    disableForReducedMotion: true,
  });

export const downTheLine = () => {
  const totalMs = 1000;
  const sourceCount = 7;
  const extraMargin = 0.1;
  const xDelta = (1 - 2 * extraMargin) / sourceCount;
  let counter = 0;
  const interval = setInterval(() => {
    confetti({
      particleCount: 50,
      startVelocity: 50,
      spread: 30,
      ticks: 80,
      origin: {
        x: extraMargin + xDelta * counter,
        y: 1.1,
      },
      zIndex: 998,
      disableForReducedMotion: true,
    });
    counter += 1;
    if (counter > sourceCount) {
      clearInterval(interval);
    }
  }, totalMs / sourceCount);
};

export const afterBrainstorm = downTheLine;
