import { ElementNode, type LexicalNode, type SerializedElementNode } from "lexical";

type SerializedToggleTitleNode = SerializedElementNode;

export class ToggleTitleNode extends ElementNode {
  static getType(): string {
    return "toggle-title";
  }

  static clone(node: ToggleTitleNode): ToggleTitleNode {
    return new ToggleTitleNode(node.__key);
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const summary = document.createElement("summary");
    summary.classList.add(
      ..."list-none font-semibold border-0 outline-none focus-within:outline-none focus:outline-none focus-ring-none".split(
        " "
      )
    );
    summary.addEventListener("click", (event: Event) => {
      event.preventDefault();
    });
    return summary;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(): LexicalNode {
    return new ToggleTitleNode();
  }

  // eslint-disable-next-line class-methods-use-this
  exportJSON(): SerializedToggleTitleNode {
    return {
      type: ToggleTitleNode.getType(),
      indent: 0,
      direction: "ltr",
      format: "",
      children: [],
      version: 1,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  canBeEmpty(): false {
    return false;
  }
}

export function $createToggleTitleNode(): ToggleTitleNode {
  return new ToggleTitleNode();
}

export function $isToggleTitleNode(node?: LexicalNode | null): node is ToggleTitleNode {
  return node instanceof ToggleTitleNode;
}
