/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Other` - OTHER
 * * `Workspace` - WORKSPACE
 * * `Personal` - PERSONAL
 */
export enum SpaceKind {
  OTHER = 'Other',
  WORKSPACE = 'Workspace',
  PERSONAL = 'Personal',
}
