<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from "vue";

import TutorialMessage from "~/components/dumb/TutorialMessage.vue";
import Description from "~/components/task/description/Description.vue";
import Peripherals from "~/components/task/Peripherals.vue";
import Properties from "~/components/task/properties/Properties.vue";
import Title from "~/components/task/Title.vue";
import { colorsByTheme } from "~/constants/style";
import { EditorMode, TutorialName } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { usePageStore, useUserStore } from "~/stores";

defineProps<{
  task?: Task;
}>();

const emit = defineEmits<{
  enter: [event: KeyboardEvent];
  updateTitle: [value: string];
  update: [field: keyof Task];
}>();

const pageStore = usePageStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const titleEditor = ref<InstanceType<typeof Title> | null>(null);
const descriptionRef = ref<InstanceType<typeof Description> | null>(null);
const propertiesEditor = ref<InstanceType<typeof Properties> | null>(null);
const peripheralsEditor = ref<InstanceType<typeof Peripherals> | null>(null);

const generatingPropertyRecommendations = computed(() => propertiesEditor.value?.generatingPropertyRecommendations);
const generatingSubtaskRecommendations = computed(() => peripheralsEditor.value?.generatingSubtaskRecommendations);

const focusTitle = () => {
  titleEditor.value?.focus();
  if (pageStore.isMobile) {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }
};

const onTitleEnter = (event: KeyboardEvent) => {
  descriptionRef.value?.focus();
  emit("enter", event);
};

const toggleDescription = () => descriptionRef.value?.toggleShown();

const createSubtask = () => peripheralsEditor.value?.createSubtask();

const setExistingSubtasks = () => peripheralsEditor.value?.setSubtask();

const deselectSubtasks = () => peripheralsEditor.value?.deselectSubtasks();

const addAttachments = (files: File[]) => peripheralsEditor.value?.addAttachments(files);

const generatePropertyRecommendations = (recommendationButton: HTMLElement) =>
  propertiesEditor.value?.generatePropertyRecommendations(recommendationButton);

const generateSubtaskRecommendations = () => peripheralsEditor.value?.generateSubtaskRecommendations();

const reset = () => {
  titleEditor.value?.reset();
  propertiesEditor.value?.reset();
};

const openFilePicker = () => propertiesEditor.value?.openFilePicker();

const getDescription = () => descriptionRef.value?.getTextContent();

const improveDescription = () => descriptionRef.value?.improveDescription();

onMounted(() => {
  userStore.updateTutorialStatuses([{ name: TutorialName.CREATE_TASK_WITH_SUBTASK_RECS, status: 2 }]);
  nextTick(focusTitle);
});

defineExpose({
  addAttachments,
  createSubtask,
  deselectSubtasks,
  focusTitle,
  generatePropertyRecommendations,
  generatingPropertyRecommendations,
  generateSubtaskRecommendations,
  generatingSubtaskRecommendations,
  getDescription,
  improveDescription,
  openFilePicker,
  reset,
});
</script>

<template>
  <div
    class="max-w-2xl space-y-4"
    :style="{
      '--background': colors.bgStd,
      '--highlight': colors.highlight,
    }">
    <div class="ml-3.5 mr-9">
      <TutorialMessage
        :name="TutorialName.CREATE_TASK_WITH_SUBTASK_RECS"
        :step="2"
        :arrow-padding="145"
        shadow-styles="rounded-lg">
        <Title
          v-if="task"
          ref="titleEditor"
          :task="task"
          :editor-mode="EditorMode.TCM"
          @enter="onTitleEnter"
          @update="(value) => emit('updateTitle', value)" />
      </TutorialMessage>
    </div>
    <!-- TODO solve the fact that there are extra px when peripherals are empty and the spacing is all off-->
    <div
      class="!mt-0 flex h-auto max-h-[calc(min(560px,100vh-222px))] flex-col space-y-2 overflow-y-auto"
      @keydown.enter="(event) => emit('enter', event)">
      <div class="mx-1 flex flex-col space-y-4 pb-1">
        <Description
          v-if="task"
          ref="descriptionRef"
          :task="task"
          :editor-mode="EditorMode.TCM"
          @add-attachments="addAttachments"
          @enter="(event: KeyboardEvent) => emit('enter', event)" />

        <Properties
          v-if="task"
          ref="propertiesEditor"
          :task="task"
          :editor-mode="EditorMode.TCM"
          @update="(field) => emit('update', field)"
          @toggle-description="toggleDescription"
          @add-subtask="createSubtask"
          @set-subtask="setExistingSubtasks"
          @add-attachments="addAttachments" />
      </div>
      <Peripherals v-if="task" ref="peripheralsEditor" :task="task" :editor-mode="EditorMode.TCM" />
    </div>
  </div>
</template>
