<script setup lang="ts">
import { computed, watch } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { PlusIcon } from "~/icons";
import { makeLinkToSpaceSettingsPageRef } from "~/router/common";
import { ButtonStyle, CommandId, IconSize, SpaceKind, UserRole } from "~/shared/enums";
import type { Space } from "~/shared/types";
import { useDataStore, usePageStore, useUserStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

import SpaceItem from "./SpaceItem.vue";

const router = useRouter();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const customSpaces = computed(() => dataStore.getSpaceList().filter((e) => e.kind === SpaceKind.OTHER));
const workspaceSpace = computed(() => dataStore.getSpaceList().find((e) => e.kind === SpaceKind.WORKSPACE));
const personalSpace = computed(() => dataStore.getSpaceList().find((e) => e.kind === SpaceKind.PERSONAL));

const userIsMemberOrGreater = computed(() => userStore.isRoleGreaterOrEqual(UserRole.MEMBER));

const placeholderColor = computed(() => colorsByTheme[pageStore.theme].highlight);

const rollover = (spaceDuid: string) => {
  dataStore.rollover(spaceDuid);
};

const editSpace = (space: Space) => {
  router.replace(makeLinkToSpaceSettingsPageRef(space.duid, "basics").value);
};

const moveSpace = (_: string, space: Space) => {
  dataStore.updateSpace(space);
};

const getComponentProps = (space: Space) => ({
  space,
  onRollover: () => rollover(space.duid),
  onEditSpace: editSpace,
});

const createSpace = async () => {
  const topSpaceOrder = customSpaces.value.length === 0 ? undefined : customSpaces.value[0].order;
  const order = getOrdersBetween(undefined, topSpaceOrder)[0];

  const space = await dataStore.createSpace(order, { order });

  router.replace(makeLinkToSpaceSettingsPageRef(space.duid, "basics").value);
};

watch(
  () => router.currentRoute.value.query.space,
  () => {
    const currentSpace = router.currentRoute.value.query.space;
    dataStore.getSpaceList().forEach((space) => {
      if (space.duid !== currentSpace && space.title.trim() === "") {
        dataStore.deleteSpace(space);
      }
    });
  }
);
</script>

<template>
  <div class="hidden items-center justify-between sm:flex">
    <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">Spaces</span>
    <Tooltip v-if="userIsMemberOrGreater" :command-id="CommandId.CREATE_SPACE">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :icon="PlusIcon"
        :icon-size="IconSize.S"
        borderless
        class="!p-px bg-lt hover:bg-md"
       
        a11y-label="Create a space"
        @click="createSpace" />
    </Tooltip>
  </div>
  <div class="mt-1 space-y-px pb-2 pl-1.5 sm:pb-0 sm:pl-0">
    <SpaceItem v-if="workspaceSpace" :space="workspaceSpace" />

    <DragArea
      v-if="customSpaces.length > 0"
      ref="dragArea"
      group="leftbar"
      category="spaces"
      class="!h-auto min-h-[20px] flex-1 gap-0.5 rounded"
      drop-area-classes="bg-md/50"
      :placeholder-color="placeholderColor"
      :items="customSpaces"
      :component="SpaceItem"
      :get-component-props="getComponentProps"
      @change="moveSpace" />

    <SpaceItem v-if="personalSpace" :space="personalSpace" />
  </div>
</template>
