import { TaskLinkKind } from "~/shared/enums";

export const MAC_APP_DOWNLOAD_LINK = "https://www.dropbox.com/s/jxu0fbny0tg37xt/Dart.dmg?dl=1";
export const WINDOWS_APP_DOWNLOAD_LINK = "https://www.dropbox.com/s/8lscbnr01xrg0dw/Dart%20Setup.exe?dl=1";

export const IOS_APP_STORE_URL = "https://apps.apple.com/us/app/dart-task-management/id1614157527";
export const ANDROID_APP_STORE_URL = "https://play.google.com/store/apps/details?id=com.itsdart.app.dart";

export const GITHUB_LINK_KINDS = new Set([
  TaskLinkKind.GITHUB_BRANCH,
  TaskLinkKind.GITHUB_PULL_REQUEST,
  TaskLinkKind.GITHUB_EXPANSION,
]);
