<script lang="ts" setup>
import moment from "moment";
import { computed } from "vue";

import Button from "~/components/dumb/Button.vue";
import DatePicker from "~/components/dumb/DatePicker.vue";
import TimePicker from "~/components/dumb/TimePicker.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ArrowRightIcon, XIcon } from "~/icons";
import { ButtonStyle, EditorMode } from "~/shared/enums";
import type { TimeTrackingEntry as ITimeTrackingEntry } from "~/shared/types";
import { getDurationShortText } from "~/utils/time";

const props = defineProps<{
  entry: ITimeTrackingEntry;
}>();

const emit = defineEmits<{
  update: [current: ITimeTrackingEntry, updated: ITimeTrackingEntry];
  delete: [entry: ITimeTrackingEntry];
}>();

const selectedStartDate = computed(() => moment(props.entry.startedAt).toISOString());
const selectedFinishDate = computed(() =>
  (props.entry.finishedAt ? moment(props.entry.finishedAt) : moment()).toISOString()
);

const showFinishedDate = computed(
  () =>
    props.entry.finishedAt &&
    moment(props.entry.startedAt).format("YYYY-MM-DD") !== moment(props.entry.finishedAt).format("YYYY-MM-DD")
);

const changeDate = (date: string | null, isStart: boolean, bumpEnd = false) => {
  if (!date) {
    return;
  }

  const entry = { ...props.entry };
  if (isStart) {
    entry.startedAt = moment(date).toISOString();
  } else {
    entry.finishedAt = moment(date).toISOString();
  }

  const newDiff = moment(entry.finishedAt).diff(moment(entry.startedAt));
  if (newDiff > 0) {
    emit("update", props.entry, entry);
    return;
  }

  const oldDiff = moment(props.entry.finishedAt).diff(moment(props.entry.startedAt));
  if (isStart) {
    entry.finishedAt = moment(entry.startedAt).add(oldDiff, "milliseconds").toISOString();
  } else if (bumpEnd) {
    entry.finishedAt = moment(entry.finishedAt).add(1, "day").toISOString();
  } else {
    entry.startedAt = moment(entry.finishedAt).subtract(oldDiff, "milliseconds").toISOString();
  }
  emit("update", props.entry, entry);
};
</script>

<template>
  <tr
    class="group/time-tracking relative grid grid-cols-[60px,1fr,auto] py-0.5 pl-2 pr-3 text-lt border-md hover:bg-md">
    <td class="flex items-center truncate">
      <DatePicker
        block
        :value="entry.startedAt"
        :editor-mode="EditorMode.DETAIL"
        :max-start-date="new Date()"
        hide-actions
        prevent-unset
        @select="(newDate) => changeDate(newDate, true)">
        <Button
          :text="moment(entry.startedAt).format('MMM D')"
          :btn-style="ButtonStyle.CHIP"
          text-style="text-sm justify-start !text-left grow"
          is-contrast
          borderless />
      </DatePicker>
    </td>

    <td class="flex items-center">
      <TimePicker :value="selectedStartDate" @select="(newValue: string) => changeDate(newValue, true)" />
      <ArrowRightIcon class="size-3 shrink-0" />
      <TimePicker :value="selectedFinishDate" @select="(newValue: string) => changeDate(newValue, false, true)" />
      <div v-if="showFinishedDate" class="ml-2">
        <DatePicker
          block
          :value="entry.finishedAt"
          :editor-mode="EditorMode.DETAIL"
          :max-start-date="new Date()"
          hide-actions
          @select="(newDate) => changeDate(newDate, false)">
          <Button
            :text="moment(entry.finishedAt).format('MMM D')"
            :btn-style="ButtonStyle.CHIP"
            text-style="text-sm justify-start !text-left grow"
            is-contrast
            borderless />
        </DatePicker>
      </div>
    </td>

    <td class="flex items-center gap-1">
      <span class="select-none text-sm tabular-nums">
        {{ getDurationShortText(entry.startedAt, entry.finishedAt ?? new Date().toISOString()) }}
      </span>

      <div class="absolute -top-1.5 right-0 hidden h-1.5 w-3 group-hover/time-tracking:flex" />
      <button
        type="button"
        class="absolute -top-1.5 right-0 hidden cursor-pointer items-center justify-center rounded-full border bg-lt border-md icon-xs group-hover/time-tracking:flex"
        aria-label="Delete entry"
        @click.stop="emit('delete', entry)"
        @keydown.enter.stop="emit('delete', entry)">
        <Tooltip text="Delete entry">
          <XIcon class="size-full text-lt focus:outline-none" />
        </Tooltip>
      </button>
    </td>
  </tr>
</template>
