<script setup lang="ts">
import { type Component, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import { makeLinkToSpaceSettingsPageRef } from "~/router/common";
import { useDataStore } from "~/stores";

import SpacesSidebar from "./SpacesSidebar.vue";
import BasicsView from "./subpages/BasicsView.vue";
import ReportsView from "./subpages/ReportsView.vue";
import SharingView from "./subpages/SharingView.vue";
import SprintsView from "./subpages/SprintsView.vue";

const router = useRouter();
const dataStore = useDataStore();

const views: Record<string, Component> = {
  basics: BasicsView,
  reports: ReportsView,
  sharing: SharingView,
  sprints: SprintsView,
};

const page = computed(() => router.currentRoute.value.query.page as string);
const space = computed(() => dataStore.getSpaceList().find((e) => e.duid === router.currentRoute.value.query.space));

onMounted(() => {
  if (!space.value && dataStore.getSpaceList().length > 0) {
    router.replace(makeLinkToSpaceSettingsPageRef(dataStore.getSpaceList()[0].duid, "basics").value);
  }
});

onUnmounted(() => {
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      space: undefined,
      page: undefined,
    },
  });
});
</script>

<template>
  <div class="!m-0 flex size-full overflow-hidden !p-0">
    <SpacesSidebar />
    <div class="mt-10 flex flex-1 flex-col overflow-y-auto px-8 pb-72 lg:px-16">
      <component :is="views[page]" v-if="space" :key="page" :space="space" />
    </div>
  </div>
</template>
