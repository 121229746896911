<script setup lang="ts">
import { computed } from "vue";

import { DEFAULT_SKIN_TONE_EMOJI, emojiAllowsSkinTone, removeSkinTone } from "~/constants/iconAndEmoji";
import { EmojiSkinTone } from "~/shared/enums";

const EMOJI_SKIN_TONES = Object.values(EmojiSkinTone);

const props = defineProps<{
  currentEmoji: string;
  isContrast?: boolean;
}>();

const emit = defineEmits<{
  select: [skinTone: EmojiSkinTone];
}>();

const baseEmoji = computed(() =>
  emojiAllowsSkinTone(props.currentEmoji) ? removeSkinTone(props.currentEmoji) : DEFAULT_SKIN_TONE_EMOJI
);
</script>

<template>
  <div class="grid grid-cols-6 justify-items-center gap-1.5 px-3 py-2 text-center font-emoji text-xl/6 text-lt">
    <div
      v-for="skinTone in EMOJI_SKIN_TONES"
      :key="skinTone"
      class="cursor-pointer rounded icon-lg"
      :class="isContrast ? 'hover:bg-md' : 'hover:bg-hvy'"
      @click="emit('select', skinTone)"
      @keydown.enter="emit('select', skinTone)">
      {{ baseEmoji + skinTone }}
    </div>
  </div>
</template>
