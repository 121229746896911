<script setup lang="ts">
import { usePrevious } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { InternalIcon, PublicIcon, TrashIcon } from "~/icons";
import { getQueryParam, makeLinkToFormsSettingsRef } from "~/router/common";
import { DialogMode } from "~/shared/enums";
import type { Form } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";

const props = defineProps<{
  form: Form;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);
const previousForm = usePrevious(props.form);

const active = computed(() => props.form.duid === getQueryParam("form"));
const link = computed(() => makeLinkToFormsSettingsRef(props.form.duid).value);

const onDelete = () => {
  const currForm = props.form ?? previousForm.value;
  if (!currForm) {
    return;
  }

  dataStore.deleteForm(currForm);
  router.replace(makeLinkToFormsSettingsRef(dataStore.formList[0]?.duid).value);
};

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, actions.context.form(props.form, dialog.value));
};
</script>

<template>
  <RouterLink
    :to="link"
    :class="active ? 'bg-lt hover:bg-md' : 'hover:bg-lt'"
    class="group/form flex w-full items-center justify-between whitespace-nowrap rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt"
    @contextmenu="onContextMenu">
    <div class="flex overflow-hidden">
      <PageIcon :page="form" class="mr-1.5" :class="[active ? 'text-primary-base' : 'text-lt', 'icon-sm']" />
      <span :title="form.title" class="select-none truncate">{{ form.title }}</span>
    </div>
    <Tooltip
      :text="
        form.hidden
          ? 'This form is hidden and cannot be used yet'
          : form.public
            ? `This form is internal and everyone at ${tenantStore.name} can access it`
            : 'This form is public and anyone with the link can access it'
      ">
      <ConfirmationDialog
        ref="dialog"
        :mode="DialogMode.DELETE"
        :title="`Delete ${form.title}`"
        description="Are you sure you want to delete this form? This action cannot be undone."
        confirm-text="Delete"
        cancel-text="Keep"
        :icon="TrashIcon"
        @confirm="onDelete" />
      <component
        :is="form.public ? PublicIcon : InternalIcon"
        v-if="!form.hidden"
        class="shrink-0 text-vlt icon-sm focus:outline-none" />
    </Tooltip>
  </RouterLink>
</template>
