<script setup lang="ts">
import { backend } from "~/api";
import { CliIcon, OpenExternalLinkIcon } from "~/icons";
import { EventKind } from "~/shared/enums";

const onClick = () => {
  backend.event.create(EventKind.HELP_RESOURCE_CLICK, { resource: "cli" });
};
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex justify-between">
          <div class="flex gap-x-4">
            <h2 class="flex select-none items-center text-xl text-md">API</h2>
          </div>
        </div>
        <div class="select-none text-sm/relaxed text-lt">
          <p>
            Dart has an
            <a href="https://pypi.org/project/dart-tools/" target="_blank" rel="noopener noreferrer">
              <span class="underline text-vlt">open source CLI and Python library</span>
            </a>
            that can be used to automate some common workflows and build other custom integrations or processes.
          </p>
          <br />
          <p>
            The documentation for the API can be found in this library or in our
            <a href="/api/v0/docs/" target="_blank" rel="noopener noreferrer">
              <span class="underline text-vlt">OpenAPI spec</span>
            </a>
            documentation.
          </p>
        </div>
        <div class="select-none text-sm/relaxed text-lt">
          <h3 class="mb-2 flex select-none items-center text-lg text-md">CLI</h3>
          <p>To get started with the CLI, use the terminal and install the library with</p>
          <pre class="select-text rounded py-0.5 pl-1.5 pr-1 bg-lt">pip install dart-tools</pre>
          <br />
          <p>Then, login with</p>
          <pre class="select-text rounded py-0.5 pl-1.5 pr-1 bg-lt">dart login</pre>
          <br />
          <p>As an example of what you can do, create a task with</p>
          <pre class="select-text rounded py-0.5 pl-1.5 pr-1 bg-lt">dart create "[new task title]"</pre>
          <br />
          <p>See the link below for more documentation and information on setup, usage, and what else is possible.</p>
        </div>
      </div>

      <a
        href="https://pypi.org/project/dart-tools/"
        target="_blank"
        rel="noopener noreferrer"
        class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center shadow-sm text-md border-md focus-ring-std hover:bg-lt"
        @click="onClick"
        @keydown.enter="onClick">
        <CliIcon class="icon-md" />
        Start using the Dart API
        <OpenExternalLinkIcon class="icon-md" />
      </a>
    </div>
  </div>
</template>
