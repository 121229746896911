import type { EntityMatch } from "@lexical/text";
import { mergeRegister } from "@lexical/utils";
import {
  $createTextNode,
  $getNodeByKey,
  $isTextNode,
  type Klass,
  type LexicalEditor,
  type LexicalNode,
  TextNode,
} from "lexical";
import { useLexicalComposer, useMounted } from "lexical-vue";

/**
 * COPY-PATCH OF https://raw.githubusercontent.com/facebook/lexical/main/packages/lexical-text/src/index.ts
 * 
 * Returns a tuple that can be rested (...) into mergeRegister to clean up
 * node transforms listeners that transforms text into another node, eg. a HashtagNode.
 * @example
 * ```ts
 *   useEffect(() => {
    return mergeRegister(
      ...registerLexicalTextEntity(editor, getMatch, targetNode, createNode),
    );
  }, [createNode, editor, getMatch, targetNode]);
 * ```
 * Where targetNode is the type of node containing the text you want to transform (like a text input),
 * then getMatch uses a regex to find a matching text and creates the proper node to include the matching text.
 * @param editor - The lexical editor.
 * @param getMatch - Finds a matching string that satisfies a regex expression.
 * @param targetNode - The node type that contains text to match with. eg. HashtagNode
 * @param createNode - A function that creates a new node to contain the matched text. eg createHashtagNode
 * @returns An array containing the plain text and reverse node transform listeners.
 */
export function registerLexicalTextEntity<T extends TextNode>(
  editor: LexicalEditor,
  getMatch: (text: string) => null | EntityMatch,
  targetNode: Klass<T>,
  createNode: (textNode: TextNode) => T,
  onReverse: (text: string) => void
): Array<() => void> {
  const isTargetNode = (node: LexicalNode | null | undefined): node is T => node instanceof targetNode;

  // This is different from the original.
  const replaceWithSimpleText = (node: TextNode): void => {
    editor.getEditorState().read(() => {
      // The node here has already been updated with the new text. We need to get the old text.
      const realText = $getNodeByKey(node.getKey())?.getTextContent() ?? "";
      editor.update(() => {
        if (node.getParent() === null) {
          return;
        }

        const currentText = node.getTextContent();
        const backspaced = currentText.length === realText.length - 1 && realText.startsWith(currentText);

        // Revert to the previous text.
        const textNode = $createTextNode(backspaced ? realText : currentText);
        node.replace(textNode);

        if (!backspaced) {
          return;
        }

        onReverse(realText);
        textNode.select();
      });
    });
  };

  const getMode = (node: TextNode): number => node.getLatest().__mode;

  const textNodeTransform = (node: TextNode) => {
    if (!node.isSimpleText()) {
      return;
    }

    const prevSibling = node.getPreviousSibling();
    let text = node.getTextContent();
    let currentNode = node;
    let match;

    if ($isTextNode(prevSibling)) {
      const previousText = prevSibling.getTextContent();
      const combinedText = previousText + text;
      const prevMatch = getMatch(combinedText);

      if (isTargetNode(prevSibling)) {
        if (prevMatch === null || getMode(prevSibling) !== 0) {
          replaceWithSimpleText(prevSibling);

          return;
        }
        const diff = prevMatch.end - previousText.length;

        if (diff > 0) {
          const concatText = text.slice(0, diff);
          const newTextContent = previousText + concatText;
          // These two lines are different from the original.
          prevSibling.setTextContent(newTextContent);
          prevSibling.select();

          if (diff === text.length) {
            node.remove();
          } else {
            const remainingText = text.slice(diff);
            node.setTextContent(remainingText);
          }

          return;
        }
      } else if (prevMatch === null || prevMatch.start < previousText.length) {
        return;
      }
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      match = getMatch(text);
      let nextText = match === null ? "" : text.slice(match.end);
      text = nextText;

      if (nextText === "") {
        const nextSibling = currentNode.getNextSibling();

        if ($isTextNode(nextSibling)) {
          nextText = currentNode.getTextContent() + nextSibling.getTextContent();
          const nextMatch = getMatch(nextText);

          if (nextMatch === null) {
            if (isTargetNode(nextSibling)) {
              replaceWithSimpleText(nextSibling);
            } else {
              nextSibling.markDirty();
            }

            return;
          }
          if (nextMatch.start !== 0) {
            return;
          }
        }
      } else {
        const nextMatch = getMatch(nextText);

        if (nextMatch !== null && nextMatch.start === 0) {
          return;
        }
      }

      if (match === null) {
        return;
      }

      if (match.start === 0 && $isTextNode(prevSibling) && prevSibling.isTextEntity()) {
        continue;
      }

      let nodeToReplace;

      if (match.start === 0) {
        [nodeToReplace, currentNode] = currentNode.splitText(match.end);
      } else {
        [, nodeToReplace, currentNode] = currentNode.splitText(match.start, match.end);
      }

      const replacementNode = createNode(nodeToReplace);
      replacementNode.setFormat(nodeToReplace.getFormat());
      nodeToReplace.replace(replacementNode);

      if (currentNode == null) {
        return;
      }
    }
  };

  const reverseNodeTransform = (node: T) => {
    const text = node.getTextContent();
    const match = getMatch(text);

    if (match === null || match.start !== 0) {
      replaceWithSimpleText(node);

      return;
    }

    if (text.length > match.end) {
      // This will split out the rest of the text as simple text
      node.splitText(match.end);

      return;
    }

    const prevSibling = node.getPreviousSibling();

    if ($isTextNode(prevSibling) && prevSibling.isTextEntity()) {
      replaceWithSimpleText(prevSibling);
      replaceWithSimpleText(node);
    }

    const nextSibling = node.getNextSibling();

    if ($isTextNode(nextSibling) && nextSibling.isTextEntity()) {
      replaceWithSimpleText(nextSibling);

      // This may have already been converted in the previous block
      if (isTargetNode(node)) {
        replaceWithSimpleText(node);
      }
    }
  };

  const removePlainTextTransform = editor.registerNodeTransform(TextNode, textNodeTransform);
  const removeReverseNodeTransform = editor.registerNodeTransform<T>(targetNode, reverseNodeTransform);

  return [removePlainTextTransform, removeReverseNodeTransform];
}

const useLexicalTextEntity = <N extends TextNode>(
  getMatch: (text: string) => null | EntityMatch,
  targetNode: Klass<N>,
  createNode: (textNode: TextNode) => N,
  onReverse: (text: string) => void
): void => {
  const editor = useLexicalComposer();

  useMounted(() => mergeRegister(...registerLexicalTextEntity(editor, getMatch, targetNode, createNode, onReverse)));
};

export default useLexicalTextEntity;
