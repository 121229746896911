<script setup lang="ts">
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";
import { $getSelection, COMMAND_PRIORITY_LOW, PASTE_COMMAND } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import { isValidUrlIncludingLocal } from "~/utils/common";

const editor = useLexicalComposer();

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        const selection = $getSelection();
        const textContent = selection?.getTextContent().trim();
        if (!event.clipboardData?.types.includes("text/plain") || textContent?.length === 0) {
          return false;
        }

        const pastedText = event.clipboardData.getData("text").trim();
        if (!isValidUrlIncludingLocal(pastedText)) {
          return false;
        }

        editor.dispatchCommand(TOGGLE_LINK_COMMAND, pastedText);
        return true;
      },
      COMMAND_PRIORITY_LOW
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
