import RAW_ICON_MAP from "./all";

type IconSvg = {
  args: Record<string, string>;
  content: string;
};

const SVG_REGEX = /^<svg(?<argsStr>.*?)>(?<content>.*)<\/svg>$/;
const ARGS_REGEX = / (?<key>.*?)="(?<value>.*?)"/g;

export default new Map(
  Array.from(RAW_ICON_MAP.entries())
    .map(([iconName, svgStr]) => {
      const match = svgStr.match(SVG_REGEX);
      if (!match) {
        return undefined;
      }
      const argsStr = match.groups?.argsStr;
      const content = match.groups?.content;
      if (!argsStr || !content) {
        return undefined;
      }
      const args = Object.fromEntries(
        Array.from(argsStr.matchAll(ARGS_REGEX))
          .map((e: RegExpMatchArray) => {
            const key = e.groups?.key;
            const value = e.groups?.value;
            if (!key || !value) {
              return undefined;
            }
            return [key, value];
          })
          .filter((e): e is [string, string] => e !== undefined)
      );
      return [iconName, { args, content }];
    })
    .filter((e): e is [string, IconSvg] => e !== undefined)
);
