import type { InjectionKey } from "vue";

import type DocPreview from "~/components/docs/DocPreview.vue";
import type DartboardCellEditor from "~/components/visualization/list/cellEditors/DartboardCellEditor.vue";
import type DuidCellEditor from "~/components/visualization/list/cellEditors/DuidCellEditor.vue";
import type OrderCellEditor from "~/components/visualization/list/cellEditors/OrderCellEditor.vue";
import type PriorityCellEditor from "~/components/visualization/list/cellEditors/PriorityCellEditor.vue";
import type SelectCellEditor from "~/components/visualization/list/cellEditors/SelectCellEditor.vue";
import type SizeCellEditor from "~/components/visualization/list/cellEditors/SizeCellEditor.vue";
import type TaskKindCellEditor from "~/components/visualization/list/cellEditors/TaskKindCellEditor.vue";
import type TimeTrackingCellEditor from "~/components/visualization/list/cellEditors/TimeTrackingCellEditor.vue";
import type TitleCellEditor from "~/components/visualization/list/cellEditors/TitleCellEditor.vue";
import type ConfigHeaderRenderer from "~/components/visualization/list/headerRenderers/ConfigHeaderRenderer.vue";
import type NewTaskHeaderRenderer from "~/components/visualization/list/headerRenderers/NewTaskHeaderRenderer.vue";
import type StandardHeaderRenderer from "~/components/visualization/list/headerRenderers/StandardHeaderRenderer.vue";

export const CONFIG_HEADER_RENDERER = Symbol("ConfigHeaderRenderer") as InjectionKey<typeof ConfigHeaderRenderer>;
export const DARTBOARD_CELL_EDITOR = Symbol("DartboardCellEditor") as InjectionKey<typeof DartboardCellEditor>;
export const DUID_CELL_EDITOR = Symbol("DuidCellEditor") as InjectionKey<typeof DuidCellEditor>;
export const NEW_TASK_HEADER_RENDERER = Symbol("NewTaskHeaderRenderer") as InjectionKey<typeof NewTaskHeaderRenderer>;
export const ORDER_CELL_EDITOR = Symbol("OrderCellEditor") as InjectionKey<typeof OrderCellEditor>;
export const PRIORITY_CELL_EDITOR = Symbol("PriorityCellEditor") as InjectionKey<typeof PriorityCellEditor>;
export const SELECT_CELL_EDITOR = Symbol("SelectCellEditor") as InjectionKey<typeof SelectCellEditor>;
export const SIZE_CELL_EDITOR = Symbol("SizeCellEditor") as InjectionKey<typeof SizeCellEditor>;
export const STANDARD_HEADER_RENDERER = Symbol("StandardHeaderRenderer") as InjectionKey<typeof StandardHeaderRenderer>;
export const TASK_KIND_CELL_EDITOR = Symbol("TaskKindCellEditor") as InjectionKey<typeof TaskKindCellEditor>;
export const TIME_TRACKING_CELL_EDITOR = Symbol("TimeTrackingCellEditor") as InjectionKey<
  typeof TimeTrackingCellEditor
>;
export const TITLE_CELL_EDITOR = Symbol("TitleCellEditor") as InjectionKey<typeof TitleCellEditor>;

export const DOC_PREVIEW = Symbol("DocPreview") as InjectionKey<typeof DocPreview>;
