<script setup lang="ts">
import { computed, ref, watch } from "vue";

import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import { NumberFormat } from "~/shared/enums";
import type { PropertyConfig, PropertyNumber } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  property: PropertyNumber;
  propertyConfig: PropertyConfig;
}>();

const dataStore = useDataStore();

const format = ref(props.property.adtl.format);
watch(
  () => format.value,
  (newValue) => {
    dataStore.updateProperty({ duid: props.property.duid, adtl: { format: newValue } });
  }
);
watch(
  () => props.property.adtl.format,
  (newValue) => {
    format.value = newValue;
  }
);

const formatOptions = computed(() =>
  Object.values(NumberFormat).map((value) => ({
    title: value,
    selected: value === format.value,
    onClick: () => {
      format.value = value;
    },
  }))
);
</script>

<template>
  <div class="flex items-center justify-between">
    <span class="flex grow select-none flex-col gap-1">
      <span class="text-sm font-normal text-lt">Number format</span>
      <span class="text-xs text-vlt">This changes the display format of the numbers for this property</span>
    </span>
    <DropdownMenuWithSelection :options="formatOptions" border>
      <div class="w-full select-none rounded px-3 py-1 text-left shadow-sm focus-ring-std hover:bg-lt">
        {{ format }}
      </div>
    </DropdownMenuWithSelection>
  </div>
</template>
