<script setup lang="ts">
import { computed, nextTick, onUnmounted, ref } from "vue";

import actions from "~/actions";
import { getPropertyConfig, getPropertyValueFromTask } from "~/common/properties";
import AiSpinner from "~/components/dumb/AiSpinner.vue";
import Avatar from "~/components/dumb/Avatar.vue";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UserDropdownItem from "~/components/dumb/UserDropdownItem.vue";
import { notify } from "~/components/notifications";
import { UNASSIGNED_ASSIGNEE_LABEL, UNKNOWN_USER_LABEL, UNSET_USER_LABEL } from "~/components/visualization/constants";
import { colorsByTheme } from "~/constants/style";
import { DART_AI_DISPLAY_VALUE, DART_AI_PSEUDO_USER_KEY } from "~/constants/user";
import { UserFieldIcon, UsersInviteIcon, XIcon } from "~/icons";
import {
  CommandId,
  EditorMode,
  NotificationType,
  Placement,
  PropertyKind,
  StatusKind,
  TaskSourceType,
  UserRole,
} from "~/shared/enums";
import type { MultiselectDropdownMenuItem, PropertyAnyUser, PropertyValueForKind, Task, User } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { intersectionOfAllSets, isAgentEmail, unionOfAllSets, validateEmail } from "~/utils/common";
import { generateFieldRecommendation } from "~/utils/recommendation";

const STANDARD_USER_ITEM_ICON_ARGS = {
  class: "icon-lg",
  hover: false,
  circle: true,
  imgBorder: true,
};

const props = defineProps<{
  property: PropertyAnyUser;
  tasks: Task[];
  defaultValue?: PropertyValueForKind<PropertyAnyUser>;
  showText?: boolean;
  showTextSingle?: boolean;
  editorMode: EditorMode;
  values?: string[];
}>();

const emit = defineEmits<{
  update: [userDuids?: string[]];
  accept: [];
  reject: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const feedbackTooltipNode = ref<HTMLDivElement | null>(null);

const propertyConfig = computed(() => getPropertyConfig(props.property.kind));
const isDefault = computed(() => propertyConfig.value.isDefault);
const isReadOnly = computed(() => propertyConfig.value.readOnly);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const isPropertyNameShown = computed(() => "adtl" in props.property && props.property.adtl.isNameShown);

const aiEnabledAndAssigned = computed(() => {
  if (isFormMode.value) {
    return false;
  }
  if (props.property.kind === PropertyKind.DEFAULT_ASSIGNEES) {
    return tenantStore.aiAssignmentEnabled && props.tasks.every((e) => e.assignedToAi);
  }
  if (props.property.kind === PropertyKind.DEFAULT_CREATED_BY) {
    return props.tasks.every((e) => e.sourceType === TaskSourceType.RECOMMENDATION);
  }
  return false;
});
const multipleUsersEnabled = computed(
  () => "adtl" in props.property && "isMultiple" in props.property.adtl && props.property.adtl.isMultiple
);

const isListMiniMode = computed(() => props.editorMode === EditorMode.LIST_MINI);
const isListMiniTcmMode = computed(() => props.editorMode === EditorMode.LIST_MINI_TCM);
const isListMiniNonRoadmapMode = computed(() => isListMiniMode.value || isListMiniTcmMode.value);
const isListMode = computed(
  () =>
    props.editorMode === EditorMode.LIST ||
    isListMiniNonRoadmapMode.value ||
    props.editorMode === EditorMode.LIST_MINI_ROADMAP
);
const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isBaseChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isChipMode = computed(() => isBaseChipMode.value || isChipRecommendationMode.value);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isDetailBaseMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => isDetailBaseMode.value || isDetailRecommendationMode.value);
const isRecommendationMode = computed(() => isChipRecommendationMode.value || isDetailRecommendationMode.value);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);

const selectedTasks = computed(() =>
  isChipMode.value || isListMiniNonRoadmapMode.value
    ? props.tasks
    : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);
const selectedTaskDuids = computed(() => selectedTasks.value.map((e) => e.duid));

const userDuids = computed(() => {
  if (isFormMode.value) {
    return props.values ?? [];
  }

  if (isReadOnly.value) {
    const firstTaskValue = getPropertyValueFromTask(props.property, props.tasks[0]) as string | null;
    return firstTaskValue !== null &&
      props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskValue)
      ? [firstTaskValue]
      : [];
  }
  return [...unionOfAllSets(props.tasks.map((e) => new Set(getPropertyValueFromTask(props.property, e) as string[])))];
});

const isAgent = computed(() =>
  userDuids.value.some((duid) => {
    const user = dataStore.getUserByDuid(duid);
    return user && isAgentEmail(user.email);
  })
);

const showAiWorking = computed(() => {
  const startedStatusDuids = new Set(
    dataStore.getStatusesByKinds([StatusKind.STARTED], dataStore.defaultStatusProperty).map((e) => e.duid)
  );
  return (
    props.property.kind === PropertyKind.DEFAULT_ASSIGNEES &&
    !(isChipMode.value || isChipRecommendationMode.value || isListMiniTcmMode.value) &&
    (!isAgent.value || props.tasks.some((e) => startedStatusDuids.has(e.statusDuid)))
  );
});

const loading = computed(
  () => isDefault.value && !isReadOnly.value && appStore.isLoadingTasksProperty(props.tasks, props.property.duid)
);

const menu = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const updateAssignedToAi = (assignedToAi: boolean) => {
  dataStore.updateTasks(selectedTaskDuids.value.map((duid) => ({ duid, assignedToAi })));
  emit("update");
};

const replaceUsers = (duids: string[]) => {
  if (
    !("adtl" in props.property) ||
    (props.property.kind !== PropertyKind.DEFAULT_ASSIGNEES && props.property.kind !== PropertyKind.USER)
  ) {
    return;
  }

  if (isFormMode.value) {
    emit("update", duids);
    return;
  }

  dataStore.replaceUsers(props.property, selectedTaskDuids.value, duids);
  emit("update");
};

const onReplace = (duid?: string) => {
  if (duid === DART_AI_PSEUDO_USER_KEY) {
    updateAssignedToAi(true);
    return;
  }

  replaceUsers(duid ? [duid] : []);
  menu.value?.close();
};

const onAdd = (duid: string) => {
  if (
    !("adtl" in props.property) ||
    (props.property.kind !== PropertyKind.DEFAULT_ASSIGNEES && props.property.kind !== PropertyKind.USER)
  ) {
    return;
  }

  if (!multipleUsersEnabled.value) {
    onReplace(duid);
    return;
  }

  if (isFormMode.value) {
    emit("update", [...new Set([...userDuids.value, duid])]);
    return;
  }

  if (duid === DART_AI_PSEUDO_USER_KEY) {
    updateAssignedToAi(true);
    return;
  }

  dataStore.addUsers(props.property, selectedTaskDuids.value, [duid]);
  emit("update");
};

const onRemove = (duid: string) => {
  if (
    !("adtl" in props.property) ||
    (props.property.kind !== PropertyKind.DEFAULT_ASSIGNEES && props.property.kind !== PropertyKind.USER)
  ) {
    return;
  }

  if (isFormMode.value) {
    emit(
      "update",
      userDuids.value.filter((e) => e !== duid)
    );
    return;
  }

  if (duid === DART_AI_PSEUDO_USER_KEY) {
    updateAssignedToAi(false);
    return;
  }
  if (!multipleUsersEnabled.value) {
    onReplace();
    return;
  }

  dataStore.removeUsers(props.property, selectedTaskDuids.value, [duid]);
  emit("update");
};

const inviteAndAdd = async (email: string) => {
  const invitedEmails = await actions.auth.inviteUsers(UserRole.MEMBER, [email]);
  if (!invitedEmails.includes(email)) {
    return;
  }

  const user = dataStore.getUserByEmail(email);
  if (!user) {
    return;
  }

  onAdd(user.duid);
};

const generateRecommendation = async () => {
  if (selectedTaskDuids.value.length > 100) {
    notify({ message: "Dart AI cannot fill out more than 100 tasks at once", type: NotificationType.ERROR });
    return;
  }

  appStore.startLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);
  const [taskUpdates, recDuids] = await generateFieldRecommendation(
    selectedTasks.value,
    "assigneeDuids",
    props.defaultValue ?? []
  );
  dataStore.updateTasks(taskUpdates);
  appStore.finishLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);

  nextTick(() => {
    actions.visualization.showFeedbackTooltipForTask(
      selectedTaskDuids.value,
      recDuids,
      props.editorMode,
      feedbackTooltipNode.value ?? null
    );
  });
};

const rejectRecommendation = () => {
  replaceUsers(Array.isArray(props.defaultValue) ? props.defaultValue : []);
  emit("reject");
};

const generateItemForUser = (user: User): MultiselectDropdownMenuItem => {
  const userIconArgs = {
    ...STANDARD_USER_ITEM_ICON_ARGS,
    abrev: user.abrev,
    isAgent: isAgentEmail(user.email),
    colorHex: user.colorHex,
    imageUrl: user.imageUrl,
  };
  const value = user?.duid;

  const selectedUserDuids = isFormMode.value
    ? userDuids.value
    : [
        ...intersectionOfAllSets(
          selectedTasks.value.map((e) => getPropertyValueFromTask(props.property, e)).map((e) => new Set(e))
        ),
      ];

  return {
    value,
    label: user.name || user.email,
    adtlSearchTerms: [user.email],
    selected: multipleUsersEnabled.value
      ? selectedUserDuids.includes(value)
      : !aiEnabledAndAssigned.value && selectedUserDuids[0] === value,
    component: UserDropdownItem,
    componentArgs: {
      replaceEnabled: multipleUsersEnabled.value,
      icon: Avatar,
      iconArgs: userIconArgs,
    },
  };
};

const label = computed(() => {
  if (aiEnabledAndAssigned.value && (!multipleUsersEnabled.value || userDuids.value.length === 0)) {
    return DART_AI_DISPLAY_VALUE;
  }

  if (userDuids.value.length === 0) {
    return isFormMode.value ? "Set users" : isDefault.value && !isReadOnly.value ? UNASSIGNED_ASSIGNEE_LABEL : "None";
  }

  const user = dataStore.getUserByDuid(userDuids.value[0]);
  if (!user) {
    return isDefault.value && !isReadOnly.value ? UNASSIGNED_ASSIGNEE_LABEL : props.property.title;
  }

  const singleUserMultiEnabledShowPropertyName =
    multipleUsersEnabled.value && isPropertyNameShown.value && userDuids.value.length === 1;

  return singleUserMultiEnabledShowPropertyName || !multipleUsersEnabled.value || userDuids.value.length === 1
    ? user.name || user.email
    : isDefault.value
      ? ""
      : "None";
});

const dartAiUserItem = computed<MultiselectDropdownMenuItem>(() => ({
  value: DART_AI_PSEUDO_USER_KEY,
  label: DART_AI_DISPLAY_VALUE,
  selected: aiEnabledAndAssigned.value,
  component: UserDropdownItem,
  componentArgs: {
    replaceEnabled: multipleUsersEnabled.value,
    icon: Avatar,
    iconArgs: {
      ...STANDARD_USER_ITEM_ICON_ARGS,
      abrev: "DA",
      isAi: true,
    },
  },
}));

const items = computed(() => {
  const users = isFormMode.value ? dataStore.getUserList() : dataStore.getRelevantUsersByTasks(props.tasks);
  const res = users.map((user) => generateItemForUser(user));
  if (isDefault.value && !isReadOnly.value && tenantStore.aiAssignmentEnabled && !isFormMode.value) {
    res.push(dartAiUserItem.value);
  }
  return res;
});

onUnmounted(() => {
  appStore.showFeedbackTooltip(null, []);
});
</script>

<template>
  <MultiselectDropdownMenu
    ref="menu"
    :disabled="isReadOnly"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :items="items"
    :placeholder="`${isDefault && !isFormMode ? 'Assign a teammate' : 'Add a user'}...`"
    :new-entry-icon="UsersInviteIcon"
    :new-entry-icon-args="{ class: 'icon-lg mr-0.5' }"
    :make-new-entry-text="(search) => `Invite ${search} by email`"
    allow-members-to-make-new-entries
    :block="isFormMode"
    :height-block="isFormMode"
    :show-on-hover="isContextMenuMode"
    :distance="isFormMode ? 2 : undefined"
    :cover="!isContextMenuMode && (!isTaskDetailMode || (isDefault && !isReadOnly)) && !isFormMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :close-on-select="!multipleUsersEnabled"
    :validate="validateEmail"
    :show-recommendation-button="isDefault && !isReadOnly && !isFormMode"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    @add="onAdd"
    @create="inviteAndAdd"
    @remove="onRemove"
    @replace="onReplace"
    @recommend="generateRecommendation">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="UserFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isFormMode"
      :class="isFormMode ? 'overflow-hidden' : ''"
      :command-id="!multipleUsersEnabled && isDefault && !isReadOnly ? CommandId.CHANGE_ASSIGNEE : undefined"
      :command-ids="
        multipleUsersEnabled && isDefault && !isReadOnly
          ? [CommandId.ADD_ASSIGNEES, CommandId.REMOVE_ASSIGNEES]
          : undefined
      "
      :text="isReadOnly ? property.title : isDefault ? undefined : `Change ${property.title.toLowerCase()}`"
      :block="isListMode || isTaskDetailMode || isFormMode"
      :height-block="isListMode || isFormMode">
      <div
        ref="feedbackTooltipNode"
        class="flex size-full select-none items-center"
        :class="{
          'pl-1.5 pr-2': isTaskDetailMode,
          'pl-2 pr-7': isFormMode,
          'hover:bg-lt': (isFormMode || isTaskDetailMode) && !isReadOnly,
          'whitespace-nowrap rounded': !isListMode && !isTaskDetailMode,
          'hover:bg-md': !isListMode && !isRecommendationMode && !isFormMode && !isTaskDetailMode,
          'border border-oncolor': isBaseChipMode,
          'border border-recommendation-base/50 bg-recommendation-base/30 hover:bg-recommendation-base/40 dark:hover:bg-recommendation-base/40':
            isChipRecommendationMode,
          'px-1 py-0.5': (!isListMode || isAgent) && !isTaskDetailMode && !isFormMode,
          'justify-start rounded px-2 py-1': isTaskDetailMode,
          'justify-start': isListMode && isPropertyNameShown,
          'justify-center': !isTaskDetailMode && !(isListMode && isPropertyNameShown) && !isFormMode && !isAgent,
          'items-end gap-2 bg-recommendation-base/10 hover:bg-recommendation-base/20 dark:hover:hover:bg-recommendation-base/20':
            isDetailRecommendationMode,
          'cursor-default': isReadOnly && !isBoardMode,
        }">
        <div v-if="!isListMode && !isTaskDetailMode && !showText && userDuids.length === 0" class="text-vlt">
          {{ isReadOnly ? "None" : `Set ${property.title.toLowerCase()}` }}
        </div>
        <div v-if="loading" class="flex size-full items-center justify-center px-4 py-0.5">
          <AiSpinner class="icon-sm" />
        </div>
        <AvatarGroup
          v-else
          :ai="aiEnabledAndAssigned"
          :show-ai-working="showAiWorking"
          :duids="userDuids"
          :limit="isListMode ? 3 : undefined"
          :first-user-only="!multipleUsersEnabled"
          :large="isListMode"
          :editor-mode="editorMode"
          :unset-label="isReadOnly ? UNKNOWN_USER_LABEL : !isDefault ? UNSET_USER_LABEL : undefined"
          :hide-unassigned-icon="isFormMode" />
        <span
          v-if="
            !loading &&
            (showText ||
              isPropertyNameShown ||
              isTaskDetailMode ||
              isAgent ||
              (userDuids.length === 1 && !aiEnabledAndAssigned && showTextSingle) ||
              (userDuids.length === 0 && aiEnabledAndAssigned && showTextSingle))
          "
          class="text-sm"
          :title="label"
          :class="{
            'ml-1 max-w-xs truncate': isChipMode,
            truncate: isListMode && isPropertyNameShown,
            'min-w-0 hyphens-auto break-words text-left': isTaskDetailMode,
            'truncate pr-5': isFormMode,
            'ml-2': !isChipMode && !(isFormMode && userDuids.length === 0),
            'text-vlt': (isFormMode || isTaskDetailMode) && userDuids.length === 0,
          }">
          {{ label }}
        </span>
        <Tooltip v-if="isRecommendationMode" text="Clear AI recommendation" class="ml-1">
          <span
            class="rounded hover:bg-recommendation-base/30 dark:hover:bg-recommendation-base/40"
            @click.stop="rejectRecommendation"
            @keydown.enter.stop="rejectRecommendation">
            <XIcon class="icon-xs" />
          </span>
        </Tooltip>
      </div>
    </Tooltip>
  </MultiselectDropdownMenu>
</template>
