<script setup lang="ts">
import { $isListNode, ListNode } from "@lexical/list";
import { mergeRegister } from "@lexical/utils";
import { $getNodeByKey } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const editor = useLexicalComposer();

const handleListNodeMutations = (mutatedNodes: Map<string, string>) =>
  editor.update(() => {
    const mutatedLists = [...mutatedNodes.keys()].map((e) => $getNodeByKey(e)).filter($isListNode);
    mutatedLists.forEach((oldList) => {
      const listType = oldList.getListType();
      let curr = oldList;
      const prev = oldList.getPreviousSibling();
      if (prev && $isListNode(prev) && prev.getListType() === listType) {
        prev.append(...oldList.getChildren());
        oldList.remove();
        curr = prev;
      }
      const next = oldList.getNextSibling();
      if (next && $isListNode(next) && next.getListType() === listType) {
        curr.append(...next.getChildren());
        next.remove();
      }
    });
  });

let unregisterListeners: () => void;

onMounted(() => {
  if (!editor.hasNodes([ListNode])) {
    return;
  }
  unregisterListeners = mergeRegister(editor.registerMutationListener(ListNode, handleListNodeMutations));
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
