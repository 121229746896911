<script setup lang="ts">
import { computed, type ComputedRef, ref } from "vue";

import { backendOld } from "~/api";
import Avatar from "~/components/dumb/Avatar.vue";
import DropTarget from "~/components/dumb/DropTarget.vue";
import FileUploadProgress from "~/components/dumb/FileUploadProgress.vue";
import { XIcon } from "~/icons";
import { TutorialName } from "~/shared/enums";
import { useDataStore, useTenantStore, useUserStore } from "~/stores";
import { checkFileSize } from "~/utils/api";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  kind: string;
}>();

const dataStore = useDataStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

let circle = false;
let uploadFile: typeof backendOld.workspace.uploadImage | typeof backendOld.profile.uploadImage =
  backendOld.workspace.uploadImage;
let removeFile: typeof backendOld.workspace.removeImage | typeof backendOld.profile.removeImage =
  backendOld.workspace.removeImage;

let setCurrUrl: (url: string) => void;
// TODO these are really bad
const colorHex = ref<ComputedRef<string>>();
const currUrl = ref<ComputedRef<string | null>>();
const abrev = ref<ComputedRef<string>>();

switch (props.kind) {
  case "workspace": {
    circle = false;
    uploadFile = backendOld.workspace.uploadImage;
    removeFile = backendOld.workspace.removeImage;
    setCurrUrl = (url) => {
      tenantStore.imageUrl = url;
    };
    colorHex.value = undefined;
    currUrl.value = computed(() => tenantStore.imageUrl);
    abrev.value = computed(() => tenantStore.name[0]);
    break;
  }
  default: {
    circle = true;
    colorHex.value = undefined;
    uploadFile = backendOld.profile.uploadImage;
    removeFile = backendOld.profile.removeImage;
    setCurrUrl = (url) => {
      userStore.imageUrl = url;
      const user = dataStore.getUserByDuid(userStore.duid);
      if (user) {
        user.imageUrl = url;
      }
      userStore.updateTutorialStatuses([{ name: TutorialName.SET_A_PROFILE_PICTURE, status: 2 }]);
    };
    colorHex.value = computed(() => userStore.colorHex);
    currUrl.value = computed(() => userStore.imageUrl);
    abrev.value = computed(() => userStore.abrev);
    break;
  }
}

const handleUpload = async (file: File | null) => {
  if (!file) {
    return;
  }
  if (!checkFileSize(file)) {
    return;
  }
  setCurrUrl(window.URL.createObjectURL(file));
  const { data } = await uploadFile(file);
  setCurrUrl(data.fileUrl);
};

const handleRemove = () => {
  setCurrUrl("");
  removeFile();
};

const onInput = (e: Event) => {
  const input = e.target as HTMLInputElement;
  handleUpload(input.files?.item(0) ?? null);
};

const onDrop = (receivedFiles: File[]) => handleUpload(receivedFiles[0] || null);
const checkDataTypes = (types: readonly string[]) => types.every((e) => e.startsWith("image/"));

const inputId = ref(`input-${makeUuid()}`);
</script>

<template>
  <div class="flex items-center">
    <DropTarget styles="rounded" :data-types="checkDataTypes" @drop="onDrop">
      <template #main>
        <div class="group/avatar-upload relative flex items-center">
          <FileUploadProgress duid="avatar-upload">
            <template #default="{ previewFileUrl, uploading }">
              <label
                :for="inputId"
                class="relative cursor-pointer rounded border p-2 text-center shadow-sm text-md border-md focus-ring-std">
                <span class="sr-only">Select image</span>
                <Avatar
                  :abrev="abrev?.value ?? 'U'"
                  :circle="circle"
                  :color-hex="colorHex?.value"
                  :image-url="previewFileUrl ?? currUrl?.value"
                  :img-border="circle"
                  :class="uploading && 'grayscale'"
                  class="size-24" />
                <input :id="inputId" type="file" class="sr-only" accept="image/*" @input="onInput" />
                <div v-if="uploading" class="absolute inset-0 rounded bg-gray-400/80 dark:bg-md/80" />
              </label>
            </template>
            <template #actions>
              <div
                class="pointer-events-none absolute left-0 top-0 hidden size-full cursor-pointer items-center justify-center rounded bg-md/80 group-hover/avatar-upload:flex">
                <span class="select-none text-hvy">Change</span>
                <div
                  v-if="currUrl?.value"
                  class="pointer-events-auto absolute right-1 top-1 rounded text-md hover:bg-hvy/80"
                  @click.prevent="handleRemove"
                  @keydown.enter.prevent="handleRemove">
                  <XIcon class="icon-sm" />
                </div>
              </div>
            </template>
          </FileUploadProgress>
        </div>
      </template>

      <template #dragOverlay>
        <div class="flex w-full items-center justify-center rounded">
          <span class="text-center text-hvy">Drop</span>
        </div>
      </template>
    </DropTarget>
  </div>
</template>
