import {
  BlockedByRelationshipIcon,
  BlockingRelationshipIcon,
  ChildRelationshipIcon,
  DuplicatesRelationshipIcon,
  ParentRelationshipIcon,
  RelatesToRelationshipIcon,
} from "~/icons";
import { CommandId, DropdownMenuItemKind, RelationshipDropdownKind, RelationshipKindKind } from "~/shared/enums";
import type { DropdownMenuItem } from "~/shared/types";

export type RelationshipDropdownItem = DropdownMenuItem & {
  relationshipKindKind: RelationshipKindKind;
  isForward?: boolean;
};

export const ORDERED_RELATIONSHIP_CONFIGURATION = [
  {
    kind: RelationshipKindKind.PARENT_OF,
    isForward: false,
    label: "Parent",
    icon: ParentRelationshipIcon,
    singleselect: true,
  },
  {
    kind: RelationshipKindKind.BLOCKS,
    isForward: false,
    label: "Blocked by",
    icon: BlockedByRelationshipIcon,
  },
  {
    kind: RelationshipKindKind.BLOCKS,
    isForward: true,
    label: "Blocks",
    icon: BlockingRelationshipIcon,
  },
  {
    kind: RelationshipKindKind.DUPLICATES,
    label: "Duplicates",
    icon: DuplicatesRelationshipIcon,
  },
  {
    kind: RelationshipKindKind.RELATES_TO,
    label: "Relates to",
    icon: RelatesToRelationshipIcon,
  },
];

export const ORDERED_RELATIONSHIP_CONFIGURATION_WITH_SUBTASKS = [
  {
    kind: RelationshipKindKind.PARENT_OF,
    isForward: true,
    label: "Subtask",
    icon: ChildRelationshipIcon,
  },
  ...ORDERED_RELATIONSHIP_CONFIGURATION,
];

export const EXCLUDE_CURRENT_TASK_REASON = "This is the current task";

export const RELATIONSHIP_DROPDOWN_KIND_TO_DROPDOWN_OPTION = new Map<
  RelationshipDropdownKind,
  RelationshipDropdownItem
>([
  [
    RelationshipDropdownKind.SET_PARENT,
    {
      title: "Set parent",
      kind: DropdownMenuItemKind.BUTTON,
      icon: ParentRelationshipIcon,
      relationshipKindKind: RelationshipKindKind.PARENT_OF,
      isForward: false,
    },
  ],
  [
    RelationshipDropdownKind.BLOCKED,
    {
      title: "Mark blocked",
      kind: DropdownMenuItemKind.BUTTON,
      icon: BlockedByRelationshipIcon,
      relationshipKindKind: RelationshipKindKind.BLOCKS,
      isForward: false,
    },
  ],
  [
    RelationshipDropdownKind.BLOCKING,
    {
      title: "Mark blocking",
      kind: DropdownMenuItemKind.BUTTON,
      icon: BlockingRelationshipIcon,
      relationshipKindKind: RelationshipKindKind.BLOCKS,
      isForward: true,
    },
  ],
  [
    RelationshipDropdownKind.DUPLICATE,
    {
      title: "Mark duplicated",
      kind: DropdownMenuItemKind.BUTTON,
      icon: DuplicatesRelationshipIcon,
      relationshipKindKind: RelationshipKindKind.DUPLICATES,
    },
  ],
  [
    RelationshipDropdownKind.MERGED_INTO,
    {
      title: "Merge into other task",
      kind: DropdownMenuItemKind.COMPONENT,
      relationshipKindKind: RelationshipKindKind.DUPLICATES,
    },
  ],
  [
    RelationshipDropdownKind.RELATES_TO,
    {
      title: "Reference another task",
      kind: DropdownMenuItemKind.BUTTON,
      icon: RelatesToRelationshipIcon,
      relationshipKindKind: RelationshipKindKind.RELATES_TO,
    },
  ],
]);

export const RELATIONSHIP_DROPDOWN_KIND_TO_DROPDOWN_OPTION_WITH_SUBTASKS = new Map([
  [
    RelationshipDropdownKind.CREATE_SUBTASK,
    {
      title: "Add subtask",
      kind: DropdownMenuItemKind.BUTTON,
      icon: ChildRelationshipIcon,
      commandId: CommandId.CREATE_SUBTASKS,
      relationshipKindKind: RelationshipKindKind.PARENT_OF,
      isForward: true,
    },
  ],
  [
    RelationshipDropdownKind.SET_SUBTASK,
    {
      title: "Set existing subtask",
      kind: DropdownMenuItemKind.BUTTON,
      icon: ChildRelationshipIcon,
      relationshipKindKind: RelationshipKindKind.PARENT_OF,
      isForward: true,
    },
  ],
  ...RELATIONSHIP_DROPDOWN_KIND_TO_DROPDOWN_OPTION,
]);
