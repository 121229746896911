<template>
  <svg class="animate-spin" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="M18.1667 10C18.1667 14.2342 14.7342 17.6667 10.5 17.6667C6.26583 17.6667 2.83334 14.2342 2.83334 10C2.83334 5.76586 6.26583 2.33337 10.5 2.33337C14.7342 2.33337 18.1667 5.76586 18.1667 10Z"
      stroke="url(#paint0_linear_705_49134)"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <defs>
      <linearGradient
        id="paint0_linear_705_49134"
        x1="17.0001"
        y1="4.00037"
        x2="12.0001"
        y2="1.50036"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#A855F7" />
        <stop offset="1" stop-color="#A855F7" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
