<script setup lang="ts">
import { nextTick } from "vue";
import { useRouter } from "vue-router";

import { ViewKind } from "~/shared/enums";
import { useDataStore } from "~/stores";
import { getPageLink } from "~/utils/common";

const router = useRouter();
const dataStore = useDataStore();

const newRoute = getPageLink(
  dataStore.defaultDartboard ?? dataStore.getViewByKind(ViewKind.MY_TASKS),
  dataStore.getSpaceByDuid
);

nextTick(() => {
  router.push({ ...newRoute, query: router.currentRoute.value.query });
});
</script>

<template>
  <slot />
</template>
