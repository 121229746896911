<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { $createParagraphNode, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import { EVENT_INSERT_HORIZONTAL_RULE } from "../const";
import { $createHorizontalRuleNode } from "../nodes/HorizontalRuleNode";

const editor = useLexicalComposer();

const handleInsertHorizontalRule = () => {
  editor.update(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    const anchor = selection.anchor.getNode();
    const hrNode = $createHorizontalRuleNode();
    const paragraphNode = $createParagraphNode();

    anchor.insertAfter(hrNode);
    hrNode.insertAfter(paragraphNode);
    paragraphNode.selectStart();
  });

  return true;
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(EVENT_INSERT_HORIZONTAL_RULE, handleInsertHorizontalRule, COMMAND_PRIORITY_LOW)
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
