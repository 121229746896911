<script setup lang="ts">
import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ButtonSize, ButtonStyle } from "~/shared/enums";

defineProps<{
  keepText?: string;
  discardText?: string;
  keepTooltipText: string;
  discardTooltipText: string;
}>();

const emit = defineEmits<{
  keep: [];
  discard: [];
}>();
</script>

<template>
  <div class="flex items-center justify-end gap-2">
    <Tooltip :text="discardTooltipText">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :text="discardText ?? 'Discard'"
        :size="ButtonSize.SMALL"
        @click="emit('discard')" />
    </Tooltip>
    <Tooltip :text="keepTooltipText">
      <Button :btn-style="ButtonStyle.RECOMMENDATION" :text="keepText ?? 'Keep'" @click="emit('keep')" />
    </Tooltip>
  </div>
</template>
