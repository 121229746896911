<script setup lang="ts">
import { usePrevious } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { getPropertyConfig, getShownPropertyWithConfigList } from "~/common/properties";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import { HideIcon, PremiumFeatureIcon, TrashIcon } from "~/icons";
import { getQueryParam, makeLinkToPropertySettingsRef } from "~/router/common";
import { DialogMode } from "~/shared/enums";
import type { Property } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";

const props = defineProps<{
  property: Property;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const active = computed(() => props.property.duid === getQueryParam("property"));
const propertyConfig = computed(() => getPropertyConfig(props.property.kind));

const icon = computed(() => propertyConfig.value.icon);
const link = computed(() => makeLinkToPropertySettingsRef(props.property.duid).value);
const upgradeRequired = computed(
  () => !!propertyConfig.value?.entitlement && !tenantStore.getEntitlementValue(propertyConfig.value.entitlement)
);
const previousProperty = usePrevious(props.property);

const onDelete = () => {
  const currProperty = props.property ?? previousProperty.value;
  if (!currProperty) {
    return;
  }

  dataStore.deleteProperty(currProperty);
  router.replace(makeLinkToPropertySettingsRef(getShownPropertyWithConfigList()[0][0].duid).value);
};

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, actions.context.property(props.property, dialog.value));
};
</script>

<template>
  <RouterLink
    :to="link"
    :class="active ? 'bg-lt hover:bg-md' : 'hover:bg-lt'"
    class="flex items-center whitespace-nowrap rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt"
    @contextmenu="onContextMenu">
    <ConfirmationDialog
      ref="dialog"
      :mode="DialogMode.DELETE"
      :title="`Delete ${property.title}`"
      description="Are you sure you want to delete this property? This action cannot be undone."
      confirm-text="Delete"
      cancel-text="Keep"
      :icon="TrashIcon"
      @confirm="onDelete" />
    <component :is="icon" :class="[active ? 'text-primary-base' : 'text-lt', 'icon-sm']" class="mr-1.5" />
    <span :title="property.title" class="select-none truncate">{{ property.title }}</span>
    <PremiumFeatureIcon v-if="upgradeRequired" class="ml-1 shrink-0 text-vlt icon-sm" />
    <HideIcon v-if="property.hidden" class="ml-auto shrink-0 text-vlt icon-sm" />
  </RouterLink>
</template>
