import { $createParagraphNode, ElementNode, type LexicalNode, type SerializedElementNode } from "lexical";

import type { RecommendationKind } from "~/shared/enums";

import { $isRecommendationButtonsNode } from "./RecommendationButtonsNode";

type SerializedRecommendationWrapperNode = SerializedElementNode & { kind: RecommendationKind };

export class RecommendationWrapperNode extends ElementNode {
  __kind: RecommendationKind;

  static getType(): string {
    return "recommendation-wrapper";
  }

  static clone(node: RecommendationWrapperNode): RecommendationWrapperNode {
    return new RecommendationWrapperNode(node.__kind, node.__key);
  }

  constructor(kind: RecommendationKind, key?: string) {
    super(key);
    this.__kind = kind;
  }

  getKind(): RecommendationKind {
    return this.__kind;
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.classList.add(
      ..."-mx-1.5 -mt-px -mb-0.5 rounded border border-recommendation-base/30 bg-recommendation-base/10 dark:bg-recommendation-base/5 px-[5px] pb-2".split(
        " "
      )
    );
    return div;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedRecommendationWrapperNode): LexicalNode {
    return new RecommendationWrapperNode(serializedNode.kind);
  }

  exportJSON(): SerializedRecommendationWrapperNode {
    return {
      type: RecommendationWrapperNode.getType(),
      kind: this.getKind(),
      version: 1,
      indent: 0,
      direction: "ltr",
      format: "",
      children: [],
    };
  }

  remove() {
    if (this.getPreviousSibling() === null && this.getNextSibling() === null) {
      this.insertBefore($createParagraphNode());
    }
    super.remove();
  }

  // eslint-disable-next-line class-methods-use-this
  isShadowRoot(): true {
    return true;
  }

  isEmpty(): boolean {
    return this.getChildren().filter((e) => !$isRecommendationButtonsNode(e)).length === 0;
  }

  // eslint-disable-next-line class-methods-use-this
  canBeEmpty(): false {
    return false;
  }
}

export function $createRecommendationWrapperNode(kind: RecommendationKind): RecommendationWrapperNode {
  return new RecommendationWrapperNode(kind);
}

export function $isRecommendationWrapperNode(node?: LexicalNode): node is RecommendationWrapperNode {
  return node instanceof RecommendationWrapperNode;
}
