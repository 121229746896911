<script setup lang="ts">
import { $isListItemNode } from "@lexical/list";
import { mergeRegister } from "@lexical/utils";
import { $getNearestNodeFromDOMNode } from "lexical";
import { LexicalCheckListPlugin, useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const props = defineProps<{
  handleCheckboxClicks?: boolean;
}>();

const editor = useLexicalComposer();

// adapted from https://github.com/wobsoriano/lexical-vue/blob/main/src/components/LexicalCheckListPlugin.vue
// TODO I think this is just a better way to do it, so should probably PR lexical-vue
// right now we still have bugs with losing focus because of it
const clickListener = (event: MouseEvent) => {
  const { target } = event;
  if (!(target instanceof HTMLElement)) {
    return;
  }
  const { parentNode, firstChild } = target;
  if (
    firstChild !== null &&
    firstChild instanceof HTMLElement &&
    (firstChild.tagName === "UL" || firstChild.tagName === "OL")
  ) {
    return;
  }
  if (!parentNode || ("__lexicalListType" in parentNode && parentNode.__lexicalListType !== "check")) {
    return;
  }
  const { pageX } = event;
  const rect = target.getBoundingClientRect();
  if (
    !(target.dir === "rtl"
      ? pageX < rect.right && pageX > rect.right - 20
      : pageX > rect.left && pageX < rect.left + 20)
  ) {
    return;
  }

  editor.update(() => {
    const node = $getNearestNodeFromDOMNode(target);
    if (!$isListItemNode(node)) {
      return;
    }
    node.select();
    if (!props.handleCheckboxClicks) {
      return;
    }
    node.toggleChecked();
  });
};

let unregisterListener: () => void;

onMounted(() => {
  unregisterListener = mergeRegister(
    editor.registerRootListener((newRoot: HTMLElement | null, oldRoot: HTMLElement | null) => {
      oldRoot?.removeEventListener("click", clickListener);
      newRoot?.addEventListener("click", clickListener);
    })
  );
});

onUnmounted(() => {
  unregisterListener?.();
});
</script>

<template>
  <LexicalCheckListPlugin />
</template>
