import { DartboardKind } from "~/shared/enums";

export const UNSET_DARTBOARD_PSUEDO_DUID = "@unset";
export const UNSET_DARTBOARD_LABEL = "(Unset)";
export const DEFAULT_DARTBOARD_LABEL = "(Default)";

export const DARTBOARD_KIND_TO_ICON_INFO_MAP = {
  [DartboardKind.ACTIVE]: { iconNameOrEmoji: "DirectNormalIcon", colorHex: "#4da56b" },
  [DartboardKind.NEXT]: { iconNameOrEmoji: "NextIcon", colorHex: "#5885e0" },
  [DartboardKind.BACKLOG]: { iconNameOrEmoji: "LayerIcon", colorHex: "#b49bf8" },
  [DartboardKind.FINISHED]: { iconNameOrEmoji: "ArchiveIcon", colorHex: "#c6a7ac" },
};
