<script setup lang="ts">
import { computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import EntityDropdownItem from "~/components/dumb/EntityDropdownItem.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { EXCLUDE_CURRENT_TASK_REASON } from "~/constants/relationship";
import { PlusIcon } from "~/icons";
import { ButtonSize, ButtonStyle, CommandId, IconSize, RelationshipKindKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { validateIsNotEmpty } from "~/utils/common";
import { makeTaskComparatorWithDisabledAndCurrentDartboard } from "~/utils/comparator";

const props = defineProps<{
  task: Task;
  hidden?: boolean;
}>();

const emit = defineEmits<{
  select: [duid: string];
  create: [title?: string];
  afterClose: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const menu = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const ancestorDuidSet = computed(() => new Set(dataStore.getAncestorDuids(props.task)));

const subtasksDuidSet = computed(
  () =>
    new Set(
      dataStore
        .getRelationshipsByKindKind(props.task, RelationshipKindKind.PARENT_OF, true)
        .map((relationship) => relationship.targetDuid)
    )
);

const open = () => menu.value?.open();

const getTaskDisabledAndReason = (task: Task) => {
  if (task.duid === props.task.duid) {
    return { disabled: true, reason: EXCLUDE_CURRENT_TASK_REASON };
  }
  if (ancestorDuidSet.value.has(task.duid)) {
    return { disabled: true, reason: "This task is an ancestor of the current task" };
  }
  if (subtasksDuidSet.value.has(task.duid)) {
    return { disabled: true, reason: "This task is already a subtask of the current task" };
  }
  return { disabled: false, reason: undefined };
};

const comparator = makeTaskComparatorWithDisabledAndCurrentDartboard(
  dataStore.getSpaceByDuid,
  dataStore.getDartboardByDuid,
  (task) => getTaskDisabledAndReason(task).disabled,
  appStore.currentDartboardOrDefault
);

const taskOptions = computed(() =>
  dataStore
    .getTaskList()
    .sort(comparator)
    .map((task) => {
      const { disabled, reason } = getTaskDisabledAndReason(task);
      return {
        value: task.duid,
        label: task.title,
        selected: false,
        disabled,
        disabledReason: reason,
        component: EntityDropdownItem,
      };
    })
);

defineExpose({
  open,
});
</script>

<template>
  <MultiselectDropdownMenu
    ref="menu"
    :items="taskOptions"
    placeholder="Link a task..."
    :validate="validateIsNotEmpty"
    block
    cover
    :on-after-close="() => emit('afterClose')"
    close-on-select
    @add="(duid) => emit('select', duid)"
    @create="(title) => emit('create', title)">
    <div v-if="!hidden" class="flex grow cursor-default justify-end" @click.stop @keydown.enter.stop>
      <Tooltip :command-id="CommandId.CREATE_SUBTASKS">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :size="ButtonSize.SMALL"
          :icon="PlusIcon"
          :icon-size="IconSize.S"
          class="h-5 border-0 !p-0.5 hover:bg-md"
          a11y-label="Create a subtask"
          @click="emit('create')" />
      </Tooltip>
    </div>
  </MultiselectDropdownMenu>
</template>
