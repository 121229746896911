<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, ref } from "vue";

import { XIcon } from "~/icons";
import { usePageStore } from "~/stores";
import { LAST_BANNER_CLEARED_AT_KEY, lsGet, lsListen, lsSet } from "~/utils/localStorageManager";
import { getMsUntilNext } from "~/utils/time";

// remember that JS has months 0-indexed
const BROADCASTS = [
  {
    date: new Date(2023, 3, 10),
    message: [
      { text: "Dart is launching on Product Hunt today." },
      { text: "Check it out here!", link: "https://www.producthunt.com/posts/dart-3" },
    ],
  },
  {
    date: new Date(2024, 1, 20),
    message: [
      { text: "Dart" },
      { text: "posted on Hacker News", link: "https://news.ycombinator.com/item?id=39442368" },
      { text: "and" },
      {
        text: "is live on Launch YC",
        link: "https://www.ycombinator.com/launches/KR4-dart-ai-powered-project-management",
      },
      { text: "today only—check it out!" },
    ],
  },
];

const pageStore = usePageStore();

const isTall = pageStore.isDesktopApp && pageStore.isMac && pageStore.isVersionGreaterOrEqual("1.0.2");

const startDatetime = ref(moment());
const lastBannerClearedAt = ref(lsGet(LAST_BANNER_CLEARED_AT_KEY));
lsListen(LAST_BANNER_CLEARED_AT_KEY, (newLastBannerClearedAt) => {
  lastBannerClearedAt.value = newLastBannerClearedAt;
});

const startDate = computed(() => moment(startDatetime.value).startOf("day"));
const currentBroadcast = computed(() => {
  if (lastBannerClearedAt.value && moment(lastBannerClearedAt.value).isAfter(startDate.value)) {
    return undefined;
  }
  return BROADCASTS.find((broadcast) => moment(broadcast.date).isSame(startDate.value));
});

// schedule update
let nextUpdateTimeout: ReturnType<typeof setTimeout> | undefined;
if (currentBroadcast.value) {
  nextUpdateTimeout = setTimeout(() => {
    startDatetime.value = moment();
  }, getMsUntilNext("day"));
} else {
  const limitDate = moment().add(3, "week");
  const nextBroadcast = BROADCASTS.find(
    (broadcast) => moment(broadcast.date).isAfter(startDate.value) && moment(broadcast.date).isSameOrBefore(limitDate)
  );
  if (nextBroadcast) {
    nextUpdateTimeout = setTimeout(() => {
      startDatetime.value = moment();
    }, moment(nextBroadcast.date).diff(startDatetime.value));
  }
}

const close = () => {
  if (!currentBroadcast.value) {
    return;
  }
  const newLastBannerClearedAt = moment().toISOString();
  lastBannerClearedAt.value = newLastBannerClearedAt;
  lsSet(LAST_BANNER_CLEARED_AT_KEY, newLastBannerClearedAt);
};

onUnmounted(() => {
  clearInterval(nextUpdateTimeout);
});
</script>

<template>
  <div
    v-if="currentBroadcast"
    class="relative flex w-full items-center justify-center bg-primary-base text-sm font-normal text-oncolor"
    :class="isTall ? 'min-h-[50px]' : 'min-h-[36px]'">
    <template v-for="(block, index) in currentBroadcast.message" :key="index">
      <span v-if="block.link" class="underline hover:text-white/80">
        <a :href="block.link" target="_blank" rel="noopener noreferrer">{{ block.text }}</a>
      </span>
      <span v-else>{{ block.text }}</span>
      <span v-if="index < currentBroadcast.message.length - 1">&nbsp;</span>
    </template>
    <div
      class="absolute right-2 flex cursor-pointer items-center justify-center rounded hover-bg-primary"
      @click="close"
      @keydown.enter="close">
      <XIcon class="icon-md" />
    </div>
  </div>
</template>
