<script setup lang="ts">
import { useElementBounding, useParentElement } from "@vueuse/core";
import { computed, ref, watch } from "vue";

import Avatar from "~/components/dumb/Avatar.vue";
import { colorsByTheme, DEFAULT_CHIP_COLOR } from "~/constants/style";
import type { User } from "~/shared/types";
import { usePageStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";

const DEFAULT_LEFT_PX = -2;

const props = defineProps<{
  user?: User;
}>();

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const cursor = ref<HTMLDivElement | null>(null);
const { width: cursorWidth } = useElementBounding(cursor);
const highlight = ref(useParentElement(useParentElement(cursor)).value);
const { x: highlightX, width: highlightWidth } = useElementBounding(highlight);
watch([cursor, highlightX], () => {
  highlight.value = useParentElement(useParentElement(cursor)).value;
});

const wrapper = useParentElement(highlight);
const { x: wrapperX, width: wrapperWidth } = useElementBounding(wrapper);

const leftPx = computed(() => {
  const cursorRight = highlightX.value + highlightWidth.value - DEFAULT_LEFT_PX + cursorWidth.value;
  const wrapperRight = wrapperX.value + wrapperWidth.value;
  return cursorRight > wrapperRight ? wrapperRight - cursorRight : DEFAULT_LEFT_PX;
});

const name = computed(() => (props.user ? props.user.name || props.user.email : "Unknown"));
const colorSet = computed(() => getChipColorsHex(props.user?.colorHex ?? DEFAULT_CHIP_COLOR, pageStore.theme));
</script>

<template>
  <div
    ref="cursor"
    class="pointer-events-none absolute top-full flex min-h-5 max-w-xs items-center justify-center gap-1 overflow-hidden rounded border px-1"
    :title="name"
    :style="{
      left: `${leftPx}px`,
      color: colorSet.fore,
      background: `linear-gradient(${colorSet.back}, ${colorSet.back}), ${colors.bgStd}`,
      borderColor: colorSet.border,
    }">
    <Avatar
      v-if="props.user"
      circle
      img-border
      :abrev="props.user.abrev"
      :color-hex="props.user.colorHex"
      :image-url="props.user.imageUrl"
      class="icon-sm" />
    <span class="truncate text-xs">{{ name }}</span>
  </div>
</template>
