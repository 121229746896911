<script setup lang="ts">
import { type Component, computed, nextTick, ref } from "vue";

import RelationshipEditor from "~/components/dumb/RelationshipEditor.vue";
import { ORDERED_RELATIONSHIP_CONFIGURATION } from "~/constants/relationship";
import { EditorMode, RelationshipKindKind, ViewKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  open: [isOpen: boolean];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const isTcmMode = computed(() => props.editorMode === EditorMode.TCM);

const editingRelationshipKey = ref<string | undefined>();
const relationshipKeyToRefMap = new Map();

const makeRelationshipKey = (kind: RelationshipKindKind, isForward: boolean | undefined): string =>
  `${kind} ${isForward}`;
const assignRelationshipItemRef = (
  kind: RelationshipKindKind,
  isForward: boolean | undefined,
  elem: Component | null
) => {
  if (!elem) {
    return;
  }
  relationshipKeyToRefMap.set(makeRelationshipKey(kind, isForward), elem);
};

const doesRelationshipExist = (kind: RelationshipKindKind, isForward?: boolean) =>
  dataStore.getTasksByDuids(
    dataStore
      .getRelationshipsByKindKind(props.task, kind, isForward, { includeTrashed: isTrash.value })
      .map((e) => e.targetDuid),
    { includeTrashed: isTrash.value }
  ).length > 0;

const onAfterOpen = (kind: RelationshipKindKind, isForward?: boolean) => {
  editingRelationshipKey.value = makeRelationshipKey(kind, isForward);
};

const onAfterClose = () => {
  editingRelationshipKey.value = undefined;
  emit("open", false);
};

const openRelationshipMenu = (kind: RelationshipKindKind, isForward?: boolean) => {
  editingRelationshipKey.value = makeRelationshipKey(kind, isForward);
  nextTick(() => {
    const element = relationshipKeyToRefMap.get(editingRelationshipKey.value);
    if (element) {
      element.$el.scrollIntoView();
      element.open();
    }
    emit("open", true);
  });
};

const relationshipSections = computed(() =>
  ORDERED_RELATIONSHIP_CONFIGURATION.filter(
    (e) =>
      doesRelationshipExist(e.kind, e.isForward) ||
      makeRelationshipKey(e.kind, e.isForward) === editingRelationshipKey.value
  )
);

defineExpose({
  openRelationshipMenu,
});
</script>

<template>
  <div :class="isTcmMode ? '-mx-1 -mt-2' : '-mt-0.5'">
    <table :class="isTcmMode ? 'ml-5 mr-4' : 'ml-4 mr-3'" class="text-sm text-lt">
      <colgroup>
        <col span="1" />
        <col span="1" />
      </colgroup>

      <!-- Parent and other relationships -->
      <tr v-for="section in relationshipSections" :key="section.label">
        <td class="flex select-none items-center gap-2 px-1 py-2" style="width: 120px">
          <component :is="section.icon" class="icon-sm" />
          <span>{{ section.label }}</span>
        </td>
        <td class="w-full">
          <RelationshipEditor
            :ref="(elem) => assignRelationshipItemRef(section.kind, section.isForward, elem)"
            :task="task"
            :relationship-kind-kind="section.kind"
            :is-forward="section.isForward"
            :singleselect="section.singleselect"
            :exclude-duids="
              section.kind === RelationshipKindKind.PARENT_OF && !section.isForward
                ? new Set(dataStore.getDescendantDuids(task))
                : undefined
            "
            exclude-reason="This task is a descendant of the main task"
            :editor-mode="EditorMode.DETAIL"
            :on-before-open="() => onAfterOpen(section.kind)"
            :on-after-close="onAfterClose" />
        </td>
      </tr>
    </table>
  </div>
</template>
