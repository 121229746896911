<script setup lang="ts">
import { computed } from "vue";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";

import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { DartboardKind } from "~/shared/enums";
import { useDataStore, useUserStore } from "~/stores";

import HomeTaskRow from "./HomeTaskRow.vue";

const dataStore = useDataStore();
const userStore = useUserStore();

const tasks = computed(() => {
  const userDuid = userStore.duid;
  const completedStatusDuids = new Set(
    dataStore.getStatusesByKinds([...COMPLETED_STATUS_KINDS], dataStore.defaultStatusProperty).map((e) => e.duid)
  );
  const ignoredDartboardDuids = new Set(
    [
      ...dataStore.getDartboardsByKindOrdered(DartboardKind.NEXT),
      ...dataStore.getDartboardsByKindOrdered(DartboardKind.BACKLOG),
    ].map((e) => e.duid)
  );
  return dataStore
    .getTaskList()
    .filter(
      (e) =>
        e.assigneeDuids.includes(userDuid) &&
        !completedStatusDuids.has(e.statusDuid) &&
        !ignoredDartboardDuids.has(e.dartboardDuid)
    );
});
</script>

<template>
  <div class="flex h-80 w-full flex-col gap-4 rounded-xl py-4 pl-4 pr-2 bg-lt">
    <!-- Tasks -->
    <DynamicScroller
      v-if="tasks.length > 0"
      :items="tasks"
      :min-item-size="52"
      key-field="duid"
      class="h-full flex-1 overflow-y-auto">
      <template #default="{ item: task, active }">
        <DynamicScrollerItem
          :item="task"
          :active="active"
          :size-dependencies="[task.title]"
          class="select-none pb-2 pr-px">
          <HomeTaskRow :task="task" />
        </DynamicScrollerItem>
      </template>
      <template #after>
        <div class="min-h-12 w-full" />
      </template>
    </DynamicScroller>
    <div v-else class="flex h-full select-none items-center justify-center px-4 pb-6 text-center text-lg text-vlt">
      There are no tasks assigned to you
    </div>
  </div>
</template>
