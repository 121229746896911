import type { ElementTransformer } from "@lexical/markdown";
import { DecoratorNode, type LexicalNode, type SerializedLexicalNode } from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import { EntityName } from "~/shared/enums";

import { createBlockNode } from "../markdownRules";
import DartLink from "./DartLink.vue";

export type SerializedDartLinkNode = SerializedLexicalNode & {
  link: string;
  kind: EntityName;
  duid: string | undefined;
};

export class DartLinkNode extends DecoratorNode<Component> {
  __link: string;

  __kind: EntityName;

  __duid: string | undefined;

  static getType(): string {
    return "dart-link";
  }

  static clone(node: DartLinkNode): DartLinkNode {
    return new DartLinkNode(node.__link, node.__kind, node.__duid, node.__key);
  }

  constructor(link: string, kind: EntityName, duid?: string, key?: string) {
    super(key);
    this.__link = link;
    this.__kind = kind;
    this.__duid = duid;
  }

  getLink(): string {
    return this.__link;
  }

  getKind(): EntityName {
    return this.__kind;
  }

  getDuid(): string | undefined {
    return this.__duid;
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const element = document.createElement("div");
    element.classList.add("contents");
    return element;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedDartLinkNode): LexicalNode {
    return new DartLinkNode(serializedNode.link, serializedNode.kind, serializedNode.duid);
  }

  exportJSON(): SerializedDartLinkNode {
    return {
      type: DartLinkNode.getType(),
      link: this.getLink(),
      kind: this.getKind(),
      duid: this.getDuid(),
      version: 1,
    };
  }

  decorate(): Component {
    return h(DartLink, {
      link: this.getLink(),
      name: this.getKind(),
      duid: this.getDuid(),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): true {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }
}

export function $createDartLinkNode(link: string, kind: EntityName, duid: string | undefined): DartLinkNode {
  return new DartLinkNode(link, kind, duid);
}

export function $isDartLinkNode(node?: LexicalNode): node is DartLinkNode {
  return node instanceof DartLinkNode;
}

// TODO improve this to have good markdown content, make alternate versions for other custom nodes, and then start using them
export const DART_LINK_TRANSFORMER: ElementTransformer = {
  dependencies: [DartLinkNode],
  export: (node: LexicalNode) => {
    if (!$isDartLinkNode(node)) {
      return null;
    }
    return `<${node.getKind}: ${node.getDuid}>`;
  },
  regExp: /^(?!)\s/,
  replace: createBlockNode((match: string[]) => $createDartLinkNode(match[0], match[1] as EntityName, match[2])),
  type: "element",
};
