<script setup lang="ts">
import { usePrevious } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { getPropertyConfig } from "~/common/properties";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import IconOrEmojiPicker from "~/components/dumb/IconOrEmojiPicker.vue";
import Input from "~/components/dumb/Input.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import StatusWorkflow from "~/components/StatusWorkflow.vue";
import { emojiAllowsSkinTone, getEmojiWithSkinTone } from "~/constants/iconAndEmoji";
import { DotsHorizontalIcon, TrashIcon } from "~/icons";
import { getQueryParam, makeLinkToTaskKindSettingsRef } from "~/router/common";
import {
  ButtonSize,
  DialogMode,
  DropdownMenuItemKind,
  EmojiSkinTone,
  IconKind,
  Placement,
  PropertyKind,
  TaskKindKind,
} from "~/shared/enums";
import { type TaskKind, type TaskKindUpdate } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getEmojiRecommendation } from "~/utils/recommendation";

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const taskKind = computed<TaskKind | undefined>(() => dataStore.getTaskKindByDuid(getQueryParam("type") ?? ""));
const previousTaskKind = usePrevious(taskKind);
const statusPropertyConfig = computed(() => getPropertyConfig(PropertyKind.DEFAULT_STATUS));

const title = ref(taskKind.value?.title ?? "");
const titleInput = ref<InstanceType<typeof Input> | null>(null);

const isValidName = (value: string) => {
  const normValue = value.trim();
  if (normValue === "") {
    return { isValid: false, error: "Type name cannot be empty" };
  }

  const existingTaskKind = dataStore.getTaskKindByTitle(normValue);
  return {
    isValid: !existingTaskKind || existingTaskKind.duid === taskKind.value?.duid,
    error: "Type name already exists",
  };
};

const finalizeTitleAndEmoji = async () => {
  if (!taskKind.value || !titleInput.value) {
    return;
  }

  const taskKindDuid = taskKind.value.duid;

  if (!isValidName(titleInput.value.value).isValid) {
    title.value = taskKind.value?.title ?? "";
    return;
  }

  if (taskKind.value.iconKind !== IconKind.NONE) {
    dataStore.updateTaskKind({ duid: taskKindDuid, title: titleInput.value.value });
    return;
  }

  const emojiRecUpdate = await getEmojiRecommendation(taskKindDuid, titleInput.value.value);
  dataStore.updateTaskKind({
    duid: taskKindDuid,
    title: titleInput.value.value,
    ...emojiRecUpdate,
  });
};

const focusTitle = () => {
  if (!titleInput.value) {
    return;
  }
  titleInput.value.focus();
  titleInput.value.select();
};

const onDelete = () => {
  const currTaskKind = taskKind.value ?? previousTaskKind.value;
  if (!currTaskKind) {
    return;
  }

  dataStore.deleteTaskKind(currTaskKind);
  router.replace(makeLinkToTaskKindSettingsRef(dataStore.taskKindList[0]?.duid).value);
};

const toggleMilestone = (isMilestone: boolean) => {
  if (!taskKind.value) {
    return;
  }

  dataStore.updateTaskKind({
    duid: taskKind.value.duid,
    kind: isMilestone ? TaskKindKind.MILESTONE : TaskKindKind.DEFAULT,
  });
};

const iconPicker = ref<InstanceType<typeof DropdownMenu> | null>(null);
const iconPickerSections = computed(() =>
  !taskKind.value
    ? []
    : [
        {
          title: "Modify",
          items: [
            {
              title: "Icons",
              kind: DropdownMenuItemKind.COMPONENT,
              noFocus: true,
              component: IconOrEmojiPicker,
              componentArgs: {
                iconKind: taskKind.value.iconKind,
                iconNameOrEmoji: taskKind.value.iconNameOrEmoji,
                colorHex: taskKind.value.colorHex,
                onSelectColor: (colorHex: string) => {
                  if (!taskKind.value) {
                    return;
                  }
                  dataStore.updateTaskKind({ duid: taskKind.value.duid, colorHex });
                },
                onSelectSkinTone: (skinTone: EmojiSkinTone) => {
                  if (!taskKind.value) {
                    return;
                  }
                  appStore.setSkinTone(skinTone);

                  if (!emojiAllowsSkinTone(taskKind.value.iconNameOrEmoji)) {
                    return;
                  }

                  dataStore.updateTaskKind({
                    duid: taskKind.value.duid,
                    iconNameOrEmoji: getEmojiWithSkinTone(taskKind.value.iconNameOrEmoji, skinTone),
                  });
                },
                onSelectIconOrEmoji: (iconKind: IconKind, iconNameOrEmoji: string) => {
                  if (!taskKind.value) {
                    return;
                  }
                  const { duid } = taskKind.value;
                  const update: TaskKindUpdate = { duid, iconNameOrEmoji };

                  if (iconKind !== taskKind.value.iconKind) {
                    update.iconKind = iconKind;
                  }

                  dataStore.updateTaskKind(update);
                },
                onAfterSelect: () => {
                  iconPicker.value?.close();
                },
              },
            },
          ],
        },
      ]
);

const taskKindSections = computed(() => (taskKind.value ? actions.context.taskKind(taskKind.value, dialog.value) : []));

watch(
  () => taskKind.value,
  (newTaskKind) => {
    title.value = newTaskKind?.title ?? "";
  }
);

defineExpose({
  focusTitle,
});
</script>

<template>
  <div v-if="taskKind" class="mt-10 flex flex-1 flex-col gap-10 overflow-y-auto px-8 pb-72 lg:px-16">
    <div class="flex items-center gap-2">
      <span class="flex-1 select-none text-base text-md">Edit type</span>

      <ConfirmationDialog
        ref="dialog"
        :mode="DialogMode.DELETE"
        :title="`Delete ${taskKind.title}`"
        description="Are you sure you want to delete this type? This action cannot be undone."
        confirm-text="Delete"
        cancel-text="Keep"
        :icon="TrashIcon"
        @confirm="onDelete" />
      <DropdownMenu :sections="taskKindSections" :placement="Placement.BOTTOM_RIGHT" :distance="0" :width-pixels="180">
        <div class="rounded text-lt hover:bg-lt">
          <span class="sr-only">Manage type</span>
          <DotsHorizontalIcon class="icon-md" />
        </div>
      </DropdownMenu>
    </div>

    <!-- Type name -->
    <div class="flex flex-col text-sm text-md">
      <div class="flex w-full items-center gap-1">
        <DropdownMenu ref="iconPicker" :sections="iconPickerSections" :distance="0" :width-pixels="383">
          <Tooltip text="Change icon">
            <span
              class="mb-5 flex items-center justify-center rounded border p-1 bg-std border-hvy hover:bg-opposite/10">
              <PageIcon :page="taskKind" />
            </span>
          </Tooltip>
        </DropdownMenu>
        <Input
          ref="titleInput"
          :init-value="title"
          class="w-full"
          is-form
          placeholder="Type name"
          label="Name"
          :validate="isValidName"
          @finalize="finalizeTitleAndEmoji" />
      </div>

      <Toggle
        class="w-full"
        :size="ButtonSize.SMALL"
        :value="taskKind.kind === TaskKindKind.MILESTONE"
        text-sm
        text-lt
        label="Milestone"
        description="Whether this type is shown as a milestone in roadmaps"
        @update="(e) => toggleMilestone(e)" />
    </div>

    <StatusWorkflow
      :property="dataStore.defaultStatusProperty"
      :property-config="statusPropertyConfig"
      :task-kind="taskKind" />
  </div>
</template>
