<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-hidden="true">
    <path d="M21 12L9 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21 6L9 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21 18L9 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M2.19629 6.00005L3.39752 7.20128L5.79997 4.79883"
      stroke="currentColor"
      stroke-width="1.20123"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M2.19629 12.0001L3.39752 13.2013L5.79997 10.7988"
      stroke="currentColor"
      stroke-width="1.20123"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M2.19629 18.0001L3.39752 19.2013L5.79997 16.7988"
      stroke="currentColor"
      stroke-width="1.20123"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
