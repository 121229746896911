<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $createTextNode,
  $getNearestNodeFromDOMNode,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootNode,
  COMMAND_PRIORITY_LOW,
  DROP_COMMAND,
  PASTE_COMMAND,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

import { useDataStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

import { $createAttachmentNode } from "../nodes/AttachmentNode";
import { isEmpty } from "../utils";

const dataStore = useDataStore();
const editor = useLexicalComposer();

const createAttachments = async (fileList: File[], target?: HTMLElement) => {
  const selection = $getSelection();
  if (!selection || !$isRangeSelection(selection)) {
    return;
  }

  const anchorNode = target ? $getNearestNodeFromDOMNode(target) : selection.anchor.getNode();
  const targetNode = !anchorNode || $isElementNode(anchorNode) ? anchorNode : anchorNode.getParent();
  if (!anchorNode || !targetNode) {
    return;
  }
  const parentIsRoot = $isRootNode(targetNode);

  const orders = getOrdersBetween(dataStore.attachmentList.at(-1)?.order, undefined, fileList.length);
  const attachmentCreates = Array.from(fileList).map((file, i) => ({
    file,
    order: orders[i],
    partialAttachment: { kind: file.type },
  }));

  const creates = await dataStore.createAttachments(attachmentCreates);
  editor.update(() => {
    creates.forEach((create) => {
      const attachmentNode = $createAttachmentNode(
        create.attachment.duid,
        ["image", "video", "audio"].every((kind) => !create.attachment.kind.startsWith(kind))
      );
      if (parentIsRoot) {
        const paragraphNode = $createParagraphNode();
        paragraphNode.append(attachmentNode);
        targetNode.append(paragraphNode);
      } else {
        targetNode.append(attachmentNode);
      }
    });
    if (parentIsRoot) {
      const newNode = $createParagraphNode();
      targetNode.append(newNode);
      newNode.select();
    } else {
      const newNode = $createTextNode("");
      targetNode.append(newNode);
      newNode.select();
    }
    if (isEmpty(anchorNode)) {
      anchorNode.remove();
    }
  });
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand<DragEvent>(
      DROP_COMMAND,
      (event) => {
        event.preventDefault();
        if (!event.dataTransfer || event.dataTransfer.files.length === 0) {
          return false;
        }
        createAttachments(Array.from(event.dataTransfer.files), event.target as HTMLElement);
        return true;
      },
      COMMAND_PRIORITY_LOW
    ),
    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        event.preventDefault();
        if (!event.clipboardData || event.clipboardData.files.length === 0) {
          return false;
        }

        createAttachments(Array.from(event.clipboardData.files));
        return false;
      },
      COMMAND_PRIORITY_LOW
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
