<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";

import DragArea from "~/components/dumb/DragArea.vue";
import StatusSettingsItem from "~/components/dumb/StatusSettingsItem.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { PlusIcon } from "~/icons";
import { Entitlement, PropertyKind, type StatusKind } from "~/shared/enums";
import type { PropertyAnyStatus, PropertyConfig, Status, StatusUpdate, TaskKind } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { makeStringComparator, STATUS_SORT_ORDER } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";

type IDragArea = ComponentExposed<typeof DragArea<Status, typeof StatusSettingsItem>>;

const props = defineProps<{
  property: PropertyAnyStatus;
  propertyConfig: PropertyConfig<PropertyKind.DEFAULT_STATUS> | PropertyConfig<PropertyKind.STATUS>;
  taskKind?: TaskKind;
}>();

const dataStore = useDataStore();
const tenantStore = useTenantStore();

const confirmationDialog = ref<InstanceType<typeof UpgradeConfirmationDialog> | null>(null);
const upgradeRequired = computed(() => !tenantStore.getEntitlementValue(Entitlement.STATUSES));

const hiddenStatusDuids = computed(() => new Set(props.taskKind?.hiddenStatusDuids ?? []));

const categoriesRef = new Map<StatusKind, IDragArea>();
const assignCategoriesRef = (category: StatusKind, e: IDragArea | null) => {
  if (!e) {
    return;
  }
  categoriesRef.set(category, e);
};

const statusItemsByKind = computed(
  () =>
    new Map<StatusKind, Status[]>(
      STATUS_SORT_ORDER.map((category) => {
        const statuses = dataStore
          .getStatusesByKinds([category], props.property)
          .sort(makeStringComparator((e) => e.order));
        return [category, statuses];
      })
    )
);

const stopEditingStatusesExcept = (allowEditStatusDuid?: string) => {
  if (upgradeRequired.value) {
    confirmationDialog.value?.openModal();
    return;
  }

  // Stop editing all statuses except the one that is being edited
  categoriesRef.forEach((category) => {
    category.itemRefs.forEach((item) => {
      if (item.duid !== allowEditStatusDuid) {
        item.cancelEditing();
      }
    });
  });
};

const createStatus = async (kind: StatusKind) => {
  if (upgradeRequired.value) {
    confirmationDialog.value?.openModal();
    return;
  }

  const statuses = statusItemsByKind.value.get(kind);
  if (!statuses) {
    return;
  }

  const newOrder = getOrdersBetween(statuses[statuses.length - 1]?.order, undefined)[0];
  const status = await dataStore.createStatus(kind, props.property, newOrder);

  /* Hide the new status in all task kinds except the current one */
  if (props.taskKind) {
    dataStore.updateTaskKinds(
      dataStore.taskKindList
        .filter((e) => e.duid !== props.taskKind?.duid)
        .map((e) => ({ duid: e.duid, hiddenStatusDuids: e.hiddenStatusDuids.concat([status.duid]) }))
    );
  }

  nextTick(() => {
    stopEditingStatusesExcept(status.duid);
    const itemRefs = categoriesRef.get(kind)?.itemRefs ?? [];
    itemRefs[itemRefs.length - 1].startEditing();
  });
};

const onDelete = (status: Status) => {
  if (upgradeRequired.value) {
    confirmationDialog.value?.openModal();
    return;
  }

  dataStore.deleteStatus(status);
};

const updateStatus = (update: StatusUpdate) => {
  if (upgradeRequired.value) {
    confirmationDialog.value?.openModal();
    return;
  }

  dataStore.updateStatus(update);
};

const moveStatus = (category: string, status: Status) => {
  if (upgradeRequired.value) {
    categoriesRef.forEach((cat) => cat.reset());
    confirmationDialog.value?.openModal();
    return;
  }

  const statusKind = statusItemsByKind.value.get(category as StatusKind);
  if (!statusKind) {
    return;
  }

  dataStore.updateStatus({
    duid: status.duid,
    order: status.order,
    kind: category as StatusKind,
  });
};

const toggleStatusHidden = (duid: string) => {
  if (!props.taskKind) {
    return;
  }

  const hidden = new Set([...hiddenStatusDuids.value]);
  if (hidden.has(duid)) {
    hidden.delete(duid);
  } else {
    hidden.add(duid);
  }

  dataStore.updateTaskKind({ duid: props.taskKind.duid, hiddenStatusDuids: [...hidden] });

  if (hidden.has(duid)) {
    // Remap all tasks with the hidden status
    const tasksWithStatus = dataStore
      .getTaskList({ includeDraft: true, includeTrashed: true })
      .filter((e) => e.kindDuid === props.taskKind?.duid && e.statusDuid === duid);
    dataStore.updateTasks(
      tasksWithStatus.map((e) => ({
        duid: e.duid,
        kindDuid: e.kindDuid,
      })),
      {
        onRemapFailed: () => {
          toggleStatusHidden(duid);
        },
      }
    );
  }
};

const getComponentProps = (status: Status) => ({
  status,
  preventChanges: upgradeRequired.value,
  onEdit: updateStatus,
  hidden: hiddenStatusDuids.value.has(status.duid),
  taskKind: props.taskKind,
  onDelete: () => onDelete(status),
  onFocus: () => stopEditingStatusesExcept(status.duid),
  onChangePrevented: () => confirmationDialog.value?.openModal(),
  onToggleHidden: () => toggleStatusHidden(status.duid),
});
</script>

<template>
  <div class="flex flex-col gap-3">
    <div class="flex items-center gap-1">
      <span class="select-none text-sm text-lt">Workflow</span>
      <UpgradeConfirmationDialog v-if="upgradeRequired" ref="confirmationDialog" feature-action="use custom statuses" />
    </div>

    <div class="space-y-2">
      <div v-for="[category, items] in statusItemsByKind" :key="category">
        <div class="mb-2 flex items-center justify-between text-vlt">
          <span class="select-none text-sm">{{ category }}</span>
          <span
            class="cursor-pointer rounded p-0.5 hover:bg-lt dark:hover:bg-zinc-700"
            @click="createStatus(category)"
            @keydown.enter="createStatus(category)">
            <Tooltip text="Create a status">
              <PlusIcon class="icon-sm focus:outline-none" />
            </Tooltip>
          </span>
        </div>
        <div class="flex w-full flex-col gap-2 pb-1">
          <StatusSettingsItem
            v-for="item in items.filter((i) => i.locked)"
            :key="item.duid"
            :status="item"
            :prevent-changes="upgradeRequired"
            :task-kind="taskKind"
            :hidden="hiddenStatusDuids.has(item.duid)"
            @edit="updateStatus"
            @focus="() => stopEditingStatusesExcept(item.duid)"
            @change-prevented="confirmationDialog?.openModal()"
            @toggle-hidden="() => toggleStatusHidden(item.duid)" />
          <DragArea
            :ref="(elem) => assignCategoriesRef(category, elem as never)"
            group="statuses"
            drag-handle
            :category="category"
            class="min-h-[20px] gap-2"
            :items="items.filter((i) => !i.locked)"
            :component="StatusSettingsItem"
            :get-component-props="getComponentProps"
            @change="moveStatus" />
        </div>
      </div>
    </div>
  </div>
</template>
