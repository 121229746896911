<script lang="ts" setup>
import { computed, type ComputedRef } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { RelationshipKindKind } from "~/shared/enums";
import type { Status, Task } from "~/shared/types";
import { useDataStore } from "~/stores";

type StatusWithPercents = Status & { percent: number; count: number };

const props = defineProps<{
  task: Task;
}>();

const dataStore = useDataStore();

const subtasks = computed(() =>
  dataStore.getTasksByDuidsOrdered(
    dataStore.getRelationshipsByKindKind(props.task, RelationshipKindKind.PARENT_OF, true).map((e) => e.targetDuid)
  )
);

const statusList = computed(() => dataStore.getStatusList(dataStore.defaultStatusProperty).reverse());

const statusDuidToCount = computed(() => {
  const duidToCountMap = new Map<string, number>();

  subtasks.value.forEach((subtask) => {
    const status = dataStore.getStatusByDuid(subtask.statusDuid);
    if (!status) {
      return;
    }
    duidToCountMap.set(status.duid, (duidToCountMap.get(status.duid) ?? 0) + 1);
  });

  return duidToCountMap;
});

const subtaskStatusWithPercents: ComputedRef<StatusWithPercents[]> = computed(() =>
  statusList.value
    .map((status) => {
      const count = statusDuidToCount.value.get(status.duid) ?? 0;
      const percent = (count / subtasks.value.length) * 100;
      return { ...status, percent, count };
    })
    .filter((e) => e.count > 0)
);
const percentCompleted = computed(() =>
  subtaskStatusWithPercents.value
    .filter((e) => COMPLETED_STATUS_KINDS.has(e.kind))
    .map((e) => e.percent)
    .reduce((a, b) => a + b, 0)
);
</script>

<template>
  <div class="mx-1 flex h-2 items-center justify-center gap-2 rounded">
    <div class="flex size-full gap-1">
      <Tooltip
        v-for="(status, index) in subtaskStatusWithPercents"
        :key="status.duid"
        class="size-full"
        :style="{
          width: `${status.percent}%`,
        }"
        :text="`${status.count}/${subtasks.length} ${status.title}`">
        <div
          class="size-full"
          :class="{
            'rounded-l': index === 0,
            'rounded-r': index === subtaskStatusWithPercents.length - 1,
          }"
          :style="{ backgroundColor: status.colorHex }" />
      </Tooltip>
    </div>
    <div class="shrink-0 select-none px-2 text-sm text-lt">{{ `${Math.round(percentCompleted)}% Completed` }}</div>
  </div>
</template>
