<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";
import { computed, nextTick, ref } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";

import Button from "~/components/dumb/Button.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import Loading from "~/components/dumb/Loading.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import PageCard from "~/components/PageCard.vue";
import { PlusIcon } from "~/icons";
import { ButtonStyle, CommandId, IconSize } from "~/shared/enums";
import type { Doc } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";

enum SortBy {
  ORDER,
  UPDATED,
  TITLE,
}

const props = defineProps<{
  folderDuid: string;
  slugSep?: string;
  slug?: string;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const isLoaded = computed(() => pageStore.isFolderLoaded(props.folderDuid));
const transparentBg = pageStore.isDesktopApp && pageStore.isMac && pageStore.isVersionGreaterOrEqual("1.0.2");
const folder = computed(() => dataStore.getFolderByDuid(props.folderDuid));

const sortBy = ref(SortBy.UPDATED);

const changeSort = (newSortBy: SortBy) => {
  sortBy.value = newSortBy;
};

const docs = computed(() => dataStore.getDocsByFolderDuidOrdered(props.folderDuid));
const docsSorted = computed(() => {
  const res = [...docs.value];
  switch (sortBy.value) {
    case SortBy.ORDER: {
      break;
    }
    case SortBy.UPDATED: {
      res.sort(makeStringComparator((doc) => doc.updatedAt)).reverse();
      break;
    }
    case SortBy.TITLE: {
      res.sort(makeStringComparator((doc) => doc.title.toLowerCase()));
      break;
    }
    default: {
      throw new Error(`Unknown sort by: ${sortBy.value}`);
    }
  }
  return res;
});

const dragArea = ref<ComponentExposed<typeof DragArea<Doc, typeof PageCard>> | null>(null);

const createDocAndStartEditing = () => {
  if (!appStore.spaceOrFolder) {
    return;
  }
  appStore.spaceOrFolder.createDoc(false);

  nextTick(() => {
    const itemRefs = dragArea.value?.itemRefs ?? [];
    if (itemRefs.length === 0) {
      return;
    }

    itemRefs[itemRefs.length - 1].startEditingTitle();
  });
};

const getComponentProps = (doc: Doc) => ({
  page: doc,
});

const moveDoc = (_: string, doc: Doc) => {
  dataStore.updateDocs([
    {
      duid: doc.duid,
      order: doc.order,
    },
  ]);
};
</script>

<template>
  <div class="relative flex size-full">
    <div v-if="folder && isLoaded" class="flex size-full justify-center overflow-y-scroll">
      <div
        v-if="docs.length > 0"
        class="mx-6 flex h-fit max-w-xl grow flex-col extra-overscroll"
        :class="pageStore.isMobile ? 'mt-6' : 'mt-28'">
        <div class="select-none text-2xl font-semibold text-hvy">Docs</div>
        <div class="flex items-end justify-between border-b px-2 pt-2 border-md">
          <div class="flex gap-1">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              text="Recent"
              :text-style="sortBy === SortBy.UPDATED ? undefined : 'text-vlt'"
              borderless
              class="rounded-b-none border-b-2"
              :class="sortBy === SortBy.UPDATED && 'border-b-primary-base'"
              @click="changeSort(SortBy.UPDATED)" />
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              text="A → Z"
              :text-style="sortBy === SortBy.TITLE ? undefined : 'text-vlt'"
              borderless
              class="rounded-b-none border-b-2"
              :class="sortBy === SortBy.TITLE && 'border-b-primary-base'"
              @click="changeSort(SortBy.TITLE)" />
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              text="All"
              :text-style="sortBy === SortBy.ORDER ? undefined : 'text-vlt'"
              borderless
              class="rounded-b-none border-b-2"
              :class="sortBy === SortBy.ORDER && 'border-b-primary-base'"
              @click="changeSort(SortBy.ORDER)" />
          </div>
          <Tooltip :command-id="CommandId.CREATE_DOC" class="mb-3">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :icon="PlusIcon"
              :icon-size="IconSize.M"
              borderless
              class="!p-0.5"
              a11y-label="Create a doc"
              @click="createDocAndStartEditing" />
          </Tooltip>
        </div>
        <DragArea
          ref="dragArea"
          :no-reorder="sortBy !== SortBy.ORDER"
          group="doc-editor"
          category="docs"
          class="!h-auto w-full divide-y divide-lt"
          :items="docsSorted"
          :component="PageCard"
          :get-component-props="getComponentProps"
          @change="moveDoc" />
      </div>
      <PageEmptyState v-else />
    </div>
    <TransitionRoot
      :show="!isLoaded"
      class="absolute inset-0"
      leave="ease-in duration-300"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <Loading :show-timing="pageStore.showDebugInfo" :transparent-bg="transparentBg" />
    </TransitionRoot>
  </div>
</template>
