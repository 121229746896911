/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Default` - DEFAULT
 * * `Milestone` - MILESTONE
 */
export enum TaskKindKind {
  DEFAULT = 'Default',
  MILESTONE = 'Milestone',
}
