import { markRaw, ref } from "vue";
import type { Router } from "vue-router";

import { NotificationType } from "~/shared/enums";
import type { Dartboard, INotification, Space, Task } from "~/shared/types";
import {
  copyToClipboard,
  getDartboardLink,
  getItemCountText,
  getPageDisplayName,
  isString,
  makeUuid,
} from "~/utils/common";

export const queue = ref<(INotification & { id: string })[]>([]);

/** Get the count of temporary notifications currently displayed.
 * @returns {number}
 */
export const getTemporaryCount = (): number => queue.value.filter((e) => !e.indefinite).length;

/** Dismiss notification.
 * @param {string} id - Notification to dismiss.
 * @returns {void}
 */
export const dismiss = (id: string): void => {
  queue.value = queue.value.filter((e) => e.id !== id);
};

/** Dismiss all temporary notifications.
 * @returns {void}
 */
export const dismissAllTemporary = (): void => {
  queue.value = queue.value.filter((e) => e.indefinite);
};

/** Create new notification.
 * @param {INotification} notification - Notification to create.
 * @returns {string} Notification ID.
 */
export const notify = (notification: INotification): string => {
  const id = notification.id ?? makeUuid();

  // need to mark raw because of the component inside of it, which vue doesn't want to be made reactive because it adds overhead
  queue.value = [
    ...queue.value.filter((e) => e.id !== id),
    {
      id,
      ...notification,
      actions: notification.actions?.map((e) => ({
        ...e,
        component: !e.component || isString(e.component) ? e.component : markRaw(e.component),
      })),
    },
  ];

  return queue.value[queue.value.length - 1].id;
};

/** Write text to the clipboard and then notify about it.
 * @param {string} description - The description of the type of text.
 * @param {string} text - The text to write to the clipboard.
 * @param {string} html - The html to write to the clipboard.
 * @returns {void}
 */
export const copyAndNotify = (description: string, text: string, html?: string): void => {
  copyToClipboard(text, html);
  notify({
    message: `${description} copied to clipboard`,
    type: NotificationType.SUCCESS,
  });
};

export const createNewTaskNotification = (
  tasks: Task[],
  dartboard: Dartboard,
  getSpaceFn: (spaceDuid: string) => Space | undefined,
  navigateToTask: (duid: string) => void,
  router: Router
) => {
  const text = tasks.length === 1 ? "View task" : "Open dartboard";
  notify({
    message: `Created ${tasks.length === 1 ? tasks[0].title : getItemCountText(tasks.length, "task")} in ${getPageDisplayName(dartboard, getSpaceFn)}`,
    actions: [
      {
        label: text,
        onClick: (dismissFn) => {
          if (tasks.length === 1) {
            navigateToTask(tasks[0].duid);
          } else {
            router.push(getDartboardLink(dartboard, getSpaceFn));
          }
          dismissFn();
        },
      },
    ],
  });
};
