<script setup lang="ts">
import QRCode from "qrcode";
import { computed, onMounted, ref, watch } from "vue";

import Modal from "~/components/dumb/Modal.vue";
import { colorsByTheme } from "~/constants/style";
import { ModalWidth } from "~/shared/enums";
import { useAppStore, useEnvironmentStore, usePageStore } from "~/stores";

const appStore = useAppStore();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const qrCodeImageData = ref("");
const generateQrCode = async () => {
  try {
    qrCodeImageData.value = await QRCode.toDataURL(`${environmentStore.urlBase}/redirect/mobile-app`, {
      color: {
        dark: colors.value.textHvy,
        light: colors.value.bgStd,
      },
    });
  } catch (error) {
    qrCodeImageData.value = "";
  }
};

watch(() => pageStore.theme, generateQrCode);

onMounted(generateQrCode);
</script>

<template>
  <Modal
    :entity="appStore.appDownloadModalOpen"
    title="Scan to get the mobile app"
    :width="ModalWidth.S"
    @close="appStore.setAppDownloadModalOpen(false)">
    <div class="flex flex-col items-center">
      <img :src="qrCodeImageData" alt="Mobile app QR code" class="size-80" />
    </div>
  </Modal>
</template>
