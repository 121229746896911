/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `None` - NONE
 * * `ANBA` - ANBA
 */
export enum SprintMode {
  NONE = 'None',
  ANBA = 'ANBA',
}
