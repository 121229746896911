<script setup lang="ts">
import { computed } from "vue";

import PageIcon from "~/components/dumb/PageIcon.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import type { Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getTaskLink } from "~/utils/common";

const props = defineProps<{
  task: Task;
}>();

const dataStore = useDataStore();

const dartboard = computed(() => dataStore.getDartboardByDuid(props.task.dartboardDuid));
</script>

<template>
  <RouterLink
    class="flex h-9 w-full cursor-pointer items-center gap-3 truncate rounded px-3 bg-std hover:bg-md"
    :to="getTaskLink(task)">
    <span class="grow select-none truncate text-sm font-medium text-md">
      {{ task.title }}
    </span>

    <div class="flex shrink-0 items-center gap-2">
      <PageIcon v-if="dartboard" :page="dartboard" class="icon-md" />
      <StatusIcon class="icon-md" :duid="task.statusDuid" />
    </div>
  </RouterLink>
</template>
