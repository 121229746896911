<script setup lang="ts">
import { capitalize, computed, ref } from "vue";

import actions from "~/actions";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { SprintRolloverIcon, UndoIcon } from "~/icons";
import { Placement } from "~/shared/enums";
import type { Dartboard, Page } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { downTheLine } from "~/utils/fun";

const props = defineProps<{
  page: Page;
  placement?: Placement;
  showDescriptionOption?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  startEditingTitle: [];
  startEditingDescription: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const rolloverDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);
const isOpen = computed(() => !!dropdownMenu.value?.isOpen);

// TODO a lot of this is duplicate from PagesSection
const resetToActive = ref(false);
const dialogTitle = computed(() => (resetToActive.value ? "Reset to Active" : "Start next sprint"));
const dialogDescription = computed(() =>
  resetToActive.value
    ? "Resetting this dartboard to active will move its tasks back to active and the current active tasks back to next. This can't be undone."
    : "Starting the next sprint will move next tasks to active and active tasks into archive, but tasks that are not finished or canceled stay in active. This can't be undone."
);

const openRolloverDialog = (newResetToActive: boolean) => {
  resetToActive.value = newResetToActive;
  rolloverDialog.value?.openModal();
};

const rollover = () => {
  if (resetToActive.value) {
    dataStore.reverseRollover((props.page as Dartboard).spaceDuid);
    return;
  }
  downTheLine();
  dataStore.rollover(props.page.duid);
};

const sections = computed(() =>
  actions.context.page(props.page, props.showDescriptionOption, (eventKind: string) => {
    switch (eventKind) {
      case "startEditingTitle": {
        emit("startEditingTitle");
        break;
      }
      case "startEditingDescription": {
        emit("startEditingDescription");
        break;
      }
      case "startNextSprint": {
        openRolloverDialog(false);
        break;
      }
      case "resetToActive": {
        openRolloverDialog(true);
        break;
      }
      default: {
        throw new Error(`Unknown event kind: ${eventKind}`);
      }
    }
  })
);

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, sections.value);
};

defineExpose({
  isOpen,
});
</script>

<template>
  <DropdownMenu
    ref="dropdownMenu"
    :sections="sections"
    :disabled="disabled"
    :placement="placement"
    :distance="0"
    @contextmenu="onContextMenu"
    @click.prevent
    @keydown.enter.prevent>
    <Tooltip :disabled="disabled" :text="`${capitalize(page.pageKind)} options`" class="truncate">
      <slot />
    </Tooltip>
    <ConfirmationDialog
      ref="rolloverDialog"
      cancel-text="Cancel"
      confirm-text="Proceed"
      :icon="resetToActive ? UndoIcon : SprintRolloverIcon"
      :title="dialogTitle"
      :description="dialogDescription"
      @confirm="rollover" />
  </DropdownMenu>
</template>
