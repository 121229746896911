<script setup lang="ts">
import { computed, ref } from "vue";

import { backendOld } from "~/api";
import EditText from "~/components/dumb/EditText.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { Entitlement } from "~/shared/enums";
import type { ValidationFunctionResult } from "~/shared/types";
import { useTenantStore } from "~/stores";

const tenantStore = useTenantStore();

const confirmationDialog = ref<InstanceType<typeof UpgradeConfirmationDialog> | null>(null);
const urlEditorRef = ref<InstanceType<typeof EditText> | null>(null);
const toggleRef = ref<InstanceType<typeof Toggle> | null>(null);
const upgradeRequired = computed(() => !tenantStore.getEntitlementValue(Entitlement.DISCORD));

const discordEnabled = computed(() => !!tenantStore.discordIntegration && tenantStore.discordIntegration.enabled);
const discordWebhookUrl = computed(() => tenantStore.discordIntegration?.webhookUrl ?? null);

const toggleEnabled = (newValue: boolean) => {
  if (newValue === discordEnabled.value) {
    return;
  }

  if (upgradeRequired.value && !discordEnabled.value) {
    toggleRef.value?.reset();
    confirmationDialog.value?.openModal();
    return;
  }

  urlEditorRef.value?.stopEditing();
  if (tenantStore.discordIntegration) {
    tenantStore.discordIntegration.enabled = newValue;
  } else {
    tenantStore.discordIntegration = {
      enabled: true,
      webhookUrl: null,
    };
  }
  backendOld.discord.edit("enabled", newValue);
};

const validateDiscordUrl = (url: string): ValidationFunctionResult => {
  if (url === "") {
    return { isValid: true };
  }

  if (!url.startsWith("https://discord.com/api/webhooks/")) {
    return { isValid: false, error: "Enter a valid Discord webhook URL" };
  }

  return { isValid: true };
};

const changeUrl = (newUrl: string) => {
  if (!tenantStore.discordIntegration) {
    return;
  }

  const workingNewUrl = newUrl === "" ? null : newUrl;
  tenantStore.discordIntegration.webhookUrl = workingNewUrl;
  backendOld.discord.edit("webhookUrl", workingNewUrl);
};
</script>

<template>
  <div class="h-full overflow-y-scroll">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex items-center gap-4">
          <h2 class="select-none text-xl text-md">Discord integration</h2>
          <UpgradeConfirmationDialog
            v-if="upgradeRequired"
            ref="confirmationDialog"
            feature-action="use the Discord integration" />
        </div>
        <div class="select-none text-sm/relaxed text-lt">
          <p>Integrate Discord with Dart to enable notifications in Discord.</p>
          <br />
          <p>
            To set up Discord notifications, enable the integration below. Then, in Discord, find 'Server Settings' →
            'Integrations' → 'Webhooks'. Create a new webhook, set the name to 'Dart', and choose the channel it should
            post to. Then click 'Copy Webhook URL', paste the result below, and save.
          </p>
        </div>
      </div>

      <Toggle
        ref="toggleRef"
        :value="discordEnabled"
        label="Enable"
        description="Receive update notifications in Discord"
        @update="toggleEnabled" />

      <template v-if="discordEnabled">
        <div class="flex select-none flex-col gap-1">
          <div class="text-md">Discord webhook target URL</div>
          <EditText
            ref="urlEditorRef"
            :value="discordWebhookUrl ?? ''"
            label="Discord webhook target URL"
            block
            :validate="validateDiscordUrl"
            @save="changeUrl">
            <div
              class="size-full rounded border px-3 py-2 text-left text-base shadow-sm border-md hover:bg-lt"
              :class="[discordWebhookUrl === null ? 'text-lt' : 'text-md']">
              {{ discordWebhookUrl ?? "Discord webhook target URL" }}
            </div>
          </EditText>
        </div>
      </template>
    </div>
  </div>
</template>
