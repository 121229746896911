<script setup lang="ts">
import { backendOld } from "~/api";
import { ThumbsDownIcon, ThumbsUpIcon } from "~/icons";
import { ButtonStyle, FeedbackRating, IconSize } from "~/shared/enums";

import Button from "./Button.vue";

const props = defineProps<{
  recommendationDuids: string[];
  isContrast?: boolean;
}>();

const emit = defineEmits<{
  remove: [];
  reject: [];
}>();

const recordRating = (rating: FeedbackRating) => {
  // TODO Do this in bulk. ie. Single transaction.
  props.recommendationDuids.forEach((duid) => backendOld.recommendations.provideFeedback(duid, rating));
  emit("remove");

  if (rating === FeedbackRating.BAD) {
    emit("reject");
  }
};
</script>

<template>
  <div class="flex items-center">
    <Button
      :btn-style="ButtonStyle.SECONDARY_RECOMMENDATION"
      :icon="ThumbsUpIcon"
      :icon-size="IconSize.XS"
      borderless
      :is-contrast="isContrast"
      a11y-label="Good"
      @click="recordRating(FeedbackRating.GOOD)" />
    <Button
      :btn-style="ButtonStyle.SECONDARY_RECOMMENDATION"
      :icon="ThumbsDownIcon"
      :icon-size="IconSize.XS"
      borderless
      :is-contrast="isContrast"
      a11y-label="Bad"
      @click="recordRating(FeedbackRating.BAD)" />
  </div>
</template>
