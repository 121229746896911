<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import ColorPicker from "~/components/dumb/ColorPicker.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Input from "~/components/dumb/Input.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { EditIcon, HideIcon, LockIcon, ShowIcon, TrashIcon, VerticalDragHandleIcon } from "~/icons";
import { ButtonSize, ButtonStyle, DialogMode, DropdownMenuItemKind } from "~/shared/enums";
import type { Status, StatusUpdate, TaskKind } from "~/shared/types";
import { useDataStore } from "~/stores";

const DELETE_STATUS_DIALOG_DESCRIPTION =
  "Permanently deleting a status will move all its tasks to the default status. This can't be undone. Are you sure you want to proceed?";

const props = defineProps<{
  status: Status;
  preventChanges?: boolean;
  hidden: boolean;
  taskKind?: TaskKind;
}>();

const emit = defineEmits<{
  edit: [status: StatusUpdate];
  delete: [];
  focus: [];
  changePrevented: [];
  toggleHidden: [];
}>();

const dataStore = useDataStore();

const statusTitle = ref(props.status.title);
const description = ref(props.status.description);
const color = ref(props.status.colorHex);

const isEditing = ref(false);
const titleInput = ref<InstanceType<typeof Input> | null>(null);
const descriptionInput = ref<InstanceType<typeof Input> | null>(null);

const statusTitleExists = computed(() => {
  const property = dataStore.getPropertyByDuid(props.status.propertyDuid);
  if (!property) {
    return true;
  }

  return dataStore
    .getStatusList(property)
    .some(
      (status) =>
        status.title.toLowerCase() === titleInput.value?.value?.trim()?.toLowerCase() &&
        status.duid !== props.status.duid
    );
});

const titleIsInvalid = computed(() => {
  const title = titleInput.value?.value.trim();
  return (title === "" && props.status.title !== title) || statusTitleExists.value;
});

const validateTitle = (value: string) => {
  const isValid = value.trim() !== "" && !titleIsInvalid.value;
  if (!isValid) {
    return { isValid: false, error: "" };
  }
  return { isValid };
};

watch(
  () => isEditing.value,
  (value) => {
    if (!value) {
      // Auto-delete if the status is new and the title is empty.
      if (titleInput.value?.value.trim() === "" && props.status.title.trim() === "") {
        emit("delete");
      }
      return;
    }
    // Auto-focus the input
    nextTick(() => titleInput.value?.focus());
  }
);

const startEditing = () => {
  isEditing.value = true;
};

const focusAndStartEditing = () => {
  if (props.hidden) {
    return false;
  }
  if (props.preventChanges) {
    emit("changePrevented");
    return false;
  }

  startEditing();
  emit("focus");
  return true;
};

const focusStartEditingAndFocusDescription = async () => {
  if (!focusAndStartEditing()) {
    return;
  }
  // two next ticks required to beat default focus on title
  await nextTick();
  await nextTick();
  descriptionInput.value?.focus();
};

const stopEditing = () => {
  isEditing.value = false;
};

const onSave = () => {
  if (titleIsInvalid.value) {
    return;
  }
  const updatedStatus = {
    duid: props.status.duid,
    title: titleInput.value?.value,
    description: descriptionInput.value?.value,
    colorHex: color.value,
  };
  emit("edit", updatedStatus);
  stopEditing();
};

const cancelEditing = () => {
  if (titleInput.value) {
    titleInput.value.value = props.status.title;
  }
  if (descriptionInput.value) {
    descriptionInput.value.value = props.status.description;
  }
  color.value = props.status.colorHex;
  stopEditing();
};

const preventMaybe = () => {
  if (!props.preventChanges) {
    return;
  }

  emit("changePrevented");
};

const dropdownSections = computed(() => [
  {
    title: "Modify",
    items: [
      {
        title: "Edit status",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: ColorPicker,
        componentArgs: {
          value: color.value,
          onSelect: (colorHex: string) => {
            color.value = colorHex;
          },
        },
      },
    ],
  },
]);

defineExpose({
  duid: computed(() => props.status.duid),
  startEditing,
  cancelEditing,
});
</script>

<template>
  <div class="group/status-card relative flex h-10 w-full select-none items-center rounded px-3 bg-lt text-lt">
    <span
      v-if="!status.locked && !hidden"
      class="dart-handle absolute -left-7 top-0 flex h-10 w-7 items-center justify-center opacity-0 group-hover/status-card:opacity-100">
      <VerticalDragHandleIcon class="cursor-move text-vlt icon-md" />
    </span>

    <!-- Edit state -->
    <div v-if="isEditing" class="flex w-full items-center gap-2">
      <DropdownMenu :sections="dropdownSections" :max-height-pixels="460">
        <div class="-mx-0.5 flex items-center justify-center rounded icon-lg hover:bg-md">
          <StatusIcon class="icon-md" :duid="status.duid" :color-hex="color" />
        </div>
      </DropdownMenu>

      <Input
        ref="titleInput"
        :init-value="statusTitle"
        label="Change title"
        hide-label
        hide-error
        :validate="validateTitle"
        input-classes="!px-2 !py-0.5"
        placeholder="Name"
        @enter="onSave" />

      <Input
        ref="descriptionInput"
        :init-value="description"
        label="Change description"
        hide-label
        input-classes="!px-2 !py-0.5"
        placeholder="Description"
        @enter="onSave" />

      <div class="flex items-center gap-1">
        <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" is-contrast @click="cancelEditing" />
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          text="Save"
          :disabled="titleIsInvalid"
          :size="ButtonSize.SMALL"
          @click="onSave" />
      </div>
    </div>

    <!-- Display state -->
    <div v-else class="flex w-full items-center gap-2">
      <StatusIcon class="icon-md" :duid="status.duid" :class="hidden && 'opacity-50 grayscale'" />
      <div
        :title="status.title"
        class="w-40 shrink-0 truncate border border-transparent px-2 text-sm"
        :class="hidden && 'opacity-50 grayscale'"
        @dblclick="focusAndStartEditing">
        {{ status.title }}
      </div>
      <div
        :title="status.description"
        class="truncate border border-transparent px-2 text-sm text-vlt"
        :class="hidden && 'opacity-50 grayscale'"
        @dblclick="focusStartEditingAndFocusDescription">
        {{ status.description }}
      </div>
      <div class="flex flex-1 items-center justify-end gap-2 text-vlt">
        <Tooltip v-if="!hidden" class="hidden group-hover/status-card:flex" text="Modify status">
          <EditIcon class="cursor-pointer outline-none icon-sm hover:text-primary-base" @click="focusAndStartEditing" />
        </Tooltip>
        <Tooltip v-else class="hidden group-hover/status-card:flex" text="Enable status">
          <ShowIcon class="cursor-pointer outline-none icon-sm hover:text-primary-base" @click="emit('toggleHidden')" />
        </Tooltip>

        <Tooltip
          v-if="status.locked"
          :text="`This is a default status so it can be edited but not moved or ${taskKind ? 'disabled' : 'deleted'}`">
          <LockIcon class="outline-none text-vlt icon-sm" />
        </Tooltip>
        <Tooltip v-else-if="taskKind && !hidden" class="hidden group-hover/status-card:flex" text="Disable status">
          <HideIcon class="cursor-pointer outline-none icon-sm hover:text-primary-base" @click="emit('toggleHidden')" />
        </Tooltip>
        <ConfirmationDialog
          v-else-if="!hidden"
          :mode="DialogMode.DELETE"
          :title="`Delete ${status.title}`"
          :disabled="preventChanges"
          :description="DELETE_STATUS_DIALOG_DESCRIPTION"
          confirm-text="Delete"
          cancel-text="Keep"
          :icon="TrashIcon"
          @confirm="emit('delete')">
          <Tooltip class="hidden group-hover/status-card:flex" text="Delete status">
            <TrashIcon class="cursor-pointer outline-none icon-sm hover:text-danger-base" @click="preventMaybe" />
          </Tooltip>
        </ConfirmationDialog>
      </div>
    </div>
  </div>
</template>
